// Validation logic is loaded as an external script
// and appended to the window, unfortunately.
// Ideally we move this logic to plm-client,
// and/or type the Window object via a declaration file.
export const getWindow = () => window as any;

export const getLastURLPath = (): string =>
  getWindow().location.pathname.split("/").at(-1);

// Mongodb uses 12 byte ObjectIds, which are 24 characters long.
export const isIdPath = (path: string) => /^[a-f\d]{24}$/i.test(path);