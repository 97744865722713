import { FC, useEffect } from "react";
import { Snackbar, Paper, Typography } from "@mui/material";
import { sdk } from "features/changeorders";
import InlineIcon from "v1/components/ui/icon/inline-icon";
import ToastIcon from "v1/assets/icons/toast-modal-icon";
import { styled } from "@mui/material";
import { capitalize } from "lodash";

export const MandatoryApproversPopOver: FC = () => {
  const [{ type, name, action, status, id }] =
    sdk.storage.currentMandatoryTemplate.useStore();

  const [open, setDisplay] =
    sdk.storage.displayMandatoryApproversModal.useStore();

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={() => setDisplay(false)}
      autoHideDuration={3000} // Automatically hide after 5 seconds
      ClickAwayListenerProps={{ mouseEvent: false }} // Disable close on click outside
      sx={{ zIndex: 9999 }}
    >
      <ToastPaper sx={{ minWidth: 330, paddingBottom: "22px" }}>
        <div className="popover-content-header">
          <InlineIcon className="rule-defined-icon">
            <ToastIcon />
          </InlineIcon>
          <Typography variant="h6">{`Mandatory Approvers ${action}`}</Typography>
        </div>
        <div className="popover-content-body">
          <Typography variant="body1">
            <span className="bold-text">Change Order Type:</span> <i>{type}</i>
          </Typography>
          <Typography variant="body1">
            <span className="bold-text">Status:</span>{" "}
            <i>{capitalize(status)}</i>
          </Typography>
          <Typography variant="body1">
            <span className="bold-text">Approver Template:</span> <i>{name}</i>
          </Typography>
          {action !== "Added" && (
            <Typography variant="body2">
              <i>The approver list has been reset.</i>
            </Typography>
          )}
        </div>
      </ToastPaper>
    </Snackbar>
  );
};

// Custom styled Paper component for the toast
const ToastPaper = styled(Paper)`
  padding: 16px;
  border-radius: 8px;
  background-color: #fff; // Customize the background color
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

  * {
    color: black;
    font-size: 16px;
  }

  .popover-content-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    h4.popover-heading {
      margin-bottom: 0;
      font-family: sans-serif;
    }

    .ui-icon {
      position: unset;
      margin-right: 5px;
      width: 18px;
      height: 18px;
    }
  }

  .popover-content-body {
    div {
      margin-bottom: 5px;
    }

    .bold-text {
      font-weight: bold;
      font-family: sans-serif;
    }
  }
`;
