import "../index.scss"
import React, { useState, useEffect, Fragment } from "react"
import ItemSpecsIcon from "../../../../../assets/icons/item-specs-icon"
import AlertErrorIcon from "../../../../../assets/icons/alert-error"
import WarningTriangleIcon from "../../../../../assets/icons/warning-triangle"
import InlineIcon from "../../../../ui/icon/inline-icon.js"
import Schemas from "../../../../../modules/schemas"
import Utils from "../../../../../modules/utils"
import TileItem from "../../tile-item"
import MassField from "./../../../common/mass-field"
import { DuroTextOverflow } from "@duro/overlay";

const ItemSpecs = (
  { item, massPrecision }
) => {
  const [specs, setSpecs] = useState([])
  const [isAssembly, setIsAssembly] = useState(null)

  const isMass = (text) => {
    return text.toLowerCase() === 'mass' ? true : false
  }
  
  useEffect(() => {
    let specSourceArr = [...item.specs]
    let assembly = item && item.category && Schemas.component.category.getType(item.category).toLowerCase() === "assembly"

    specSourceArr.push({key: 'MASS', value: Utils.getRoundedMass(item.mass, massPrecision)})
    specSourceArr.push({key: 'PROCUREMENT', value: item.procurement})
    specSourceArr.push({key: 'UNIT OF MEASURE', value: item.unitOfMeasure})
    
    setSpecs(specSourceArr)
    setIsAssembly(assembly)
  }, [item, massPrecision])

  if (specs.length === 0) return null

  return (
    <TileItem title="Specs" icon={ <ItemSpecsIcon /> }>
      {
        specs.map((spec, i) => (
          Schemas.component.specs.isSpecAllowedForCompany(spec.key) &&
          <div key={`specs-info-${i}`} className={`inner-info values-to-right ${`diff-${spec.key.replace(/[^a-zA-Z ]/g, "").split(' ').join('-').toLowerCase()}-section`}`}>
            {/* Spec label */}
            <span className={`inner-attribute specs${isMass(spec.key) ? ' no-cl' : ''}`}>
              {
                isMass(spec.key)
                ? `MASS (${Utils.getCompanyMassUnit()})`
                : <DuroTextOverflow text={Schemas.component.specs.getKey(spec.key)} />
              }
            </span>
            {/* Spec value */}
            <span className="inner-value">
              {
                isAssembly && isMass(spec.key) &&
                <Fragment>
                  {
                    (item.massStatus && item.massStatus.toUpperCase() === "ERROR") &&
                    <InlineIcon
                      className="inline-icon"
                      tooltip="Missing mass values for one or more children"
                      tooltipPlace="top"
                    >
                      <AlertErrorIcon />
                    </InlineIcon>
                  }

                  {
                    (item.massStatus && item.massStatus.toUpperCase() === "WARNING") &&
                    <InlineIcon
                      className="inline-icon"
                      tooltip="Manually entered value"
                      tooltipPlace="top"
                    >
                      <WarningTriangleIcon />
                    </InlineIcon>
                  }
                  <MassField
                    mass={item.mass}
                    massPrecision={massPrecision}
                  />
                </Fragment>
              }
              {
                isMass(spec.key) && !isAssembly &&
                <MassField
                  mass={spec.value}
                  massPrecision={massPrecision}
                />
              }
              {
                !isMass(spec.key) && <DuroTextOverflow text={spec.value} />
              }
            </span>
          </div>
        ))
      }
    </TileItem>
  )
}

export default ItemSpecs
