import { Component } from "design/models";
import { restClient } from "utils/api";

export type ComponentUpdatePayload = {
  status: Component["status"];
  revision: Component["revisionValue"];
  modified: boolean;
  nextCoRevision: Component["legacyNextRevision"];
  lastModified: number;
};

const getAliasedPath = (alias?: string) =>
  `/${alias?.toLowerCase() === "cmp" ? "components" : "products"}`;

export function postComponentUpdate(
  id: string,
  payload: ComponentUpdatePayload,
  alias?: string
) {
  return restClient.post<ComponentUpdatePayload>(
    `${getAliasedPath(alias)}/${id}`,
    payload
  );
}

export async function postComponentNextRevisionUpdate(
  id: string,
  nextCoRevision: string,
  alias: string
) {
  return restClient.post(`${getAliasedPath(alias)}/${id}/set-next-revision`, {
    nextCoRevision,
  });
}
