import "./index.scss"
import React, {Component}   from "react"
import { connect }          from "react-redux"
import COMPONENT            from "../../../../action-types/component"
import CO                   from "../../../../action-types/changeorder"
import buildAction          from "../../../../helpers/buildAction"
import Utils                from "../../../../modules/utils"
import Schemas              from "../../../../modules/schemas"
import Actions              from "./actions"
import Tabs                 from "../../common/tabs/view"
import Loading              from "../../../ui/inner-loading"
import {Helmet}             from "react-helmet"
import { ItemCustomSpecs } from "../../common/specifications/item-custom-specs"
import ItemOverView         from "../../common/specifications/item-overview"
import ItemDetails          from "../../common/specifications/item-details"
import ItemSpecs            from "../../common/specifications/item-specs"
import ItemERP              from "../../common/specifications/item-erp-view"

export class ComponentRevision extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.state =
        {
            showLoading: false,
        }
        this.resetPageStates        = this.resetPageStates.bind(this);
        this.getRevisionFromApi     = this.getRevisionFromApi.bind(this);
        this.getNestedData          = this.getNestedData.bind(this);
        this.compareRevisions       = this.compareRevisions.bind(this);
        this.refreshRevision        = this.refreshRevision.bind(this);
        this.deleteComponent        = this.deleteComponent.bind(this);
        this.addToChangeOrder       = this.addToChangeOrder.bind(this);
        this.updateComponent        = this.updateComponent.bind(this);
        this.resetPageStates();
    }

    compareRevisions(sourceRevId, targetRevId)
    {
        let component = this.props.components.revisionPage.component
        this.props.history.push({pathname: `/component/diff/${component._id}?sourceRevId=${sourceRevId}&targetRevId=${targetRevId}`});
    }

    componentWillMount()
    {
        const id = this.props.match.params.id
        this.getRevisionFromApi(id)
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.match.params.id !== this.props.match.params.id)
        {
            this.resetPageStates()
            this.getRevisionFromApi(nextProps.match.params.id)
        }
    }

    getNestedData(name){
        const {dispatch} = this.props;
        let id = this.props.components.revisionPage.revision._id;
        let payload = { id:`${id}?include=${name}`, name };
        dispatch(buildAction(COMPONENT.GET_REVISION_NESTED_DATA, payload));
    }

    getRevisionFromApi(id)
    {
        const {dispatch} = this.props;
        const user       = this.props.user.data;
        let query_params =  'include=creator,images';
        let payload = { id:`${id}?${query_params}`, history: this.props.history, user };
        dispatch(buildAction(COMPONENT.GET_REVISION_IN_REVISION_PAGE, payload ));
    }

    resetPageStates()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(COMPONENT.RESET_STATES_IN_REVISION_PAGE, {} ))
    }

    updateComponent()
    {
        this.resetPageStates();
        this.getRevisionFromApi(this.props.match.params.id);
        this.setState({openVariantModal: true});
    }

    updateVariantsTab(forcefulyGetData=false)
    {
        this.setState({getRevisionFromApi: forcefulyGetData});
    }

    refreshRevision(props)
    {
        const id = this.props.match.params.id;
        this.resetPageStates();
        this.getRevisionFromApi(id);
    }

    deleteComponent()
    {
        if (this.props.components && this.props.components.revisionPage )
        {
            this.setState({showLoading: true})
            let component = this.props.components.revisionPage.component;
            const {dispatch, history} = this.props;
            let payload = { component, history, viewRoute: true }
            dispatch(buildAction(COMPONENT.DELETE_COMPONENT, payload))
        }
    }

    addToChangeOrder(data)
    {
        const {dispatch} = this.props;
        let children = data.children
        data.children = {}
        let childId = children[0]._id
        data.children.components = [childId]
        let payload = {history: this.props.history, data: data, addChildren: true, authorId: this.props.user.data._id}
        dispatch(buildAction(CO.CREATE_CO_WITH_DEFAULT_DATA, payload))
    }

    render()
    {
        let component, revision, backgroundStyle;

        if (this.props.components && this.props.components.revisionPage ){
            component = this.props.components.revisionPage.component
            revision = this.props.components.revisionPage.revision
            backgroundStyle = this.props.components.revisionPage.backgroundStyle
        }

        let cpnVariant = ""
        let { company } = this.props;
        let user = this.props.user.data
        let massPrecision = Utils.getCompanyMassPrecision(company);
        if(revision && Utils.haveVariantScheme(company.data, user.currentEnv))
        {
            cpnVariant = "-"+revision.cpnVariant
        }

        if (!component || !revision || this.state.showLoading){
            return <Loading />
        }
        let isAssembly = revision && revision.category && Schemas.component.category.getType(revision.category).toLowerCase() === "assembly";

        let markup =

            <div className="component-revision-route">
                <Helmet>
                    <title>{Utils.makeTitle(`[${Utils.getCpn(component)}] (Revision) ${component.name}`)}</title>
                </Helmet>
                <div className="background-holder">
                    <div className="background" style={backgroundStyle} />
                </div>
                <div className="top-block">
                    <Actions
                        {...this.props}
                        revision={revision}
                        component={component}
                        history={this.props.history}
                        compareRevisions={this.compareRevisions}
                        updateComponent={this.updateComponent}
                        updateVariantsTab={this.updateVariantsTab}
                        refreshItemViewRoute={this.refreshRevision}
                        deleteComponent={this.deleteComponent}
                        addToChangeOrder={this.addToChangeOrder}
                    />
                    <div className="tiles-wrapper">
                        <div className="tiles-section-scroll">
                            <ItemOverView item={revision} alias="cmp" isPrdOrCmpViewPage={false} />
                            <ItemDetails item={revision} defaultCurrency={revision.defaultCurrency} massPrecision={massPrecision} isPrdOrCmpViewPage={false}/>
                            <ItemSpecs item={revision} massPrecision={massPrecision} />
                            <ItemCustomSpecs item={revision} title={user?.activeLibrary?.customTileTitle ?? "Custom Specs"}  />
                            <ItemERP item={revision}/>
                        </div>
                    </div>
                </div>

                <Tabs
                    tabsType="cmp"
                    component={revision}
                    documents={revision.documents}
                    manufacturers={revision.manufacturers}
                    assemblyData={revision.children}
                    componentCategory={revision.category}
                    location={this.props.location}
                    history={this.props.history}
                    mode="revision"
                    getNestedData={this.getNestedData}
                    documents_loaded={this.props.components.revisionPage.documents_loaded}
                    children_loaded={this.props.components.revisionPage.children_loaded}
                    defaultCurrency={revision.defaultCurrency}
                    paramId={this.props.match.params.id}
                    user={this.props.user}
                />

            </div>

        return markup
    }
}

export default connect((store) => store)(ComponentRevision)
