import { IconButton, styled } from "@mui/material";
import { SerializationModal } from "build/components/serializationModal";
import { useDuroFlag } from 'common/components/launchDarkly';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { colorPalette } from "@duro/themes";
import { DuroMenu } from "../../../../../common/components/duroMenu";
import CoIcon from '../../../../assets/icons/co';
import CoAlertIcon from '../../../../assets/icons/co-alert';
import CoRevertIcon from '../../../../assets/icons/co-revert';
import DeleteIcon from '../../../../assets/icons/delete';
import DotsIcon from '../../../../assets/icons/dots';
import DuplicateIcon from '../../../../assets/icons/duplicate';
import EditIcon from '../../../../assets/icons/edit';
import ExportIcon from '../../../../assets/icons/export';
import FavoriteIcon from '../../../../assets/icons/favorites';
import RevsIcon from '../../../../assets/icons/revs';
import SerializationIcon from '../../../../assets/icons/serialization';
import { SerializationIcon as Serialization } from '../../../../assets/icons/serializationIcon';
import VariantIcon from '../../../../assets/icons/variant-icon';
import WhereUsedSrc from '../../../../assets/icons/where-used';
import Permissions from '../../../../modules/schemas/permissions';
import Utils from '../../../../modules/utils';
import ImportFromValispace from '../../../page/component/new/valispace';
import CustomBlueToolTip from '../../../ui/custom-blue-tooltips-for-on-boarding';
import InlineIcon from '../../../ui/icon/inline-icon';
import Link from '../../../ui/link';
import RevertBackModal from '../../../ui/revert-back-modal';
import WarningModal from '../../../ui/warning-modal';
import ValispaceIcon from '../valispace-icon';
import ItemDeleteModal from '../view-action-items/prod-co-cpn-delete-modal';
import ProductionBuildModal from '../view-action-items/production-build-modal';
import RevisionHistoryTable from '../view-action-items/revision-history-table';
import VariantModal from '../view-action-items/variant-modal';
import WhereUsedModal from '../view-action-items/where-used-modal';
import './index.scss';
import checkCoExist from "v1/helpers/CoHelpers";
import Schemas from "v1/modules/schemas";

export const DESIGN_SERIALIZATION_MODAL_TRANSITION = "DESIGN_SERIALIZATION_MODAL_TRANSITION";


const CTAIconToolbar = (props) => {
  const {
    // ---- inheried props
    history,
    openVariantModal,
    duplicate,
    makeDuplicate,
    toggleFavorite,
    openVariantModalFlag,
    closeVariantModal,
    updateProduct,
    updateComponent,
    updateVariantsTab,
    createVariant,
    getDataFromApi,
    refreshItemViewRoute,
    search,
    addToChangeOrder,
    revertChanges,
    deleteProduct,
    deleteComponent,
    revision,
    refreshSerializationTab,
    isProductionBuildExist,
    setIsProductionBuildExist,
    dispatch,
    // ----
    user,
    item,
    itemName,
    itemEditLink,
  } = props
  const [showNavigation, setShowNavigation] = useState(false)
  const [revModal, setRevModal] = useState(false)
  const [revertModal, setRevertModal] = useState({open: false})
  const [deleteModal, setDeleteModal] = useState({open: false})
  const [modifiedRevision, setModifiedRevision] = useState(false)
  const [showEditWarningWhileInCO, setShowEditWarningWhileInCO] = useState(false)
  const [favs, setFavs] = useState([])
  const [shouldShowItem, setShouldShowItem] = useState(false)
  const [whereUsedModal, setWhereUsedModal] = useState({open: false})
  const [whereUsedParents, setWhereUsedParents] = useState([])
  const [revList, setRevList] = useState([]);
  const [displayValispaceImport, setDisplayValispaceImport] = useState(false)
  const [productionBuildModal, setProductionBuildModal] = useState({oepn: false})
  const productionBuildModalNodeRef = useRef(null)
  const dotsRef = useRef(null)
  const [displaySerializationModal, setDisplaySerializationModal] = useState(false);
  const categories = Schemas.categories.list();
  const catObj = useMemo(() => {
    return categories.find(category => category.name === item.category && category.archived);
   }, [categories, item.category]);
  const redirect = (path, state) => {
    history.push({pathname: path, state: state})
  }

  const xport = (event, view) => {
    event.preventDefault()
    let data

    if (view === 'product' || view === 'component') {
      data = {
        data: [ item ]
      }
    } else {
      data = {
        data: [ revision ],
        mode: 'revision'
      }
    }

    redirect("/export", data)
  }

  const threeDotsClickHandler = (event) => {
    event.preventDefault()
    setShowNavigation(!showNavigation)
  }

  const openRevModal = (event) => {
    let userRole = window.__userRole
    let isRestrictedRole = Utils.checkForRestrictedRole(userRole)

    if (revModal) {
      closeRevModal()
    } else {
      if (event && event.target.name === 'modified-revision' && isRestrictedRole) setModifiedRevision(true)
      setRevModal(true)
    }
  }

  const closeRevModal = () => {
    setRevModal(false)
    setModifiedRevision(false)
  }

  const openWhereUsedModal = () => {
    setWhereUsedModal({open: !whereUsedModal.open})
  }

  const closeWhereUsedModal = () => {
    setWhereUsedModal({open: false})
  }

  const getParentsForWhereUsed = useCallback(() => {
    Utils.getParentsForWhereUsed(
      revision ? [revision._id] : [item._id],
      revision ? 'revisionView' : 'componentView',
      (err, response) => {
      if(!err) {
        setWhereUsedParents(response.parents)
      }
    })
  }, [item, revision]);

  const addingChangeOrder = (event) => {
    event.preventDefault()

    let data = {
      children: [ item ]
    }

    addToChangeOrder(data)
  }

  const revertBack = (event) => {
    event.preventDefault()

    setRevModal({open: false})
    setRevertModal({open: true})
  }

  const closeRevertModal = () => {
    setRevertModal({open: false})
  }

  const revertingChanges = () => {
    closeRevertModal()
    revertChanges()
  }

  const toggleModal = (modalName, modalValue) => {
      setDisplayValispaceImport(modalValue);
  }

  const renderVariantModal = () => {
    return (
      Permissions.can("create", itemName, user) && openVariantModalFlag &&
      <VariantModal
          closeVariantModal={closeVariantModal}
          objectData={item}
          updateObjectData={itemName === 'product' ? updateProduct : updateComponent}
          updateVariantsTab={updateVariantsTab}
          createVariant={createVariant}
          getDataFromApi={getDataFromApi}
          location={location}
          search={search}
          history={history}
          refreshItemViewRoute={refreshItemViewRoute}
      />
    )
  }

  const openProductionBuildModal = event => {
    document.body.addEventListener("click", onProductionBuildModalExternalClick)
    setProductionBuildModal({ open: true })
  }

  const closeProductionBuildModal = () => {
    document.body.removeEventListener("click", onProductionBuildModalExternalClick)
    setProductionBuildModal({ open: false })
  }

  const onProductionBuildModalExternalClick = event => {
    let target = event.target
    if (productionBuildModalNodeRef) {
      let parent = productionBuildModalNodeRef.current.refs.productionBuildFormNode
      let isExternal = target !== parent && !Utils.isDescendant(parent, target)
      if(isExternal) closeProductionBuildModal()
    }
  }

  const getSerializationIcon = () => {
    let isSerializationEnabled = window.__isSerializationEnabled && window.__libraryType === "GENERAL"
    let isSerializationActive = ["PROTOTYPE", "PRODUCTION"].includes(item.status) && !item.modified

    if (isSerializationEnabled && Permissions.can("edit", "product", user)) {
      return (
        <InlineIcon
          className={isSerializationActive ? `serialization${productionBuildModal.open ? ' active' : ''}` : "disabled"}
          onClick={isSerializationActive ? openProductionBuildModal : null}
          tooltip={isSerializationActive ? "Create a Production Build" : "Product must not be in Design status or modified"}
        >
          <SerializationIcon />
          <span className="iconname">Serialization</span>
        </InlineIcon>
      )
    }
  }

  const renderChangeOrderActions = () => {
    let options = null
    let className = ''
    let actionsList = []
    let addTochangeOrderAction = {
      label: "Add to Change Order",
      icon: CoIcon,
      onClick: e => addingChangeOrder(e)
    }

    let revertBackAction = {
      label: "Revert Back",
      icon: CoRevertIcon,
      to: item._id,
      onClick: revertBack
    };

    actionsList.push(addTochangeOrderAction)

    if (checkCoExist(item)) {
      options = [revertBackAction]
      className = 'editable-while-in-CO'
    } else if (item.modified) {
      actionsList.push(revertBackAction)
      options = actionsList
    } else {
      options = actionsList
    }

    return (
      <div className="changeorder-actions-block">
        <DuroMenu className={`changeorder-actions ${className}`}
                  anchorComponent=
                      {<InlineIcon tooltip={`${Utils.capitalizeString(itemName)} has been modified`}
                                   customStyles={{ width: "100%" }}>
                        <CoAlertIcon />
                        <span className="iconname">Change Order</span>
                      </InlineIcon>}
                  name="changeorder"
                  options={options}
                  customButtonStyles={{ display: "inline-block" }}>
        </DuroMenu>
      </div>
    )
  }

  const onDeleteClickHandler = (event) => {
    event.preventDefault()

    setDeleteModal({open: true})
  }

  const closeDeleteModal = () => {
    setDeleteModal({open: false})
  }

  const deletingItem = () => {
    closeDeleteModal()
    itemName === 'product' ? deleteProduct() : deleteComponent()
  }

  const renderDeleteModal = () => {
    return (
      Permissions.can("delete", itemName, user) && deleteModal.open &&
      <ItemDeleteModal
        onCloseDeleteModal={closeDeleteModal}
        onDeleteBtnClick={deletingItem}
        headingTxt={`Delete ${Utils.capitalizeString(itemName)}?`}
        bodyTxt={`Deleting a ${Utils.capitalizeString(itemName)} permanently removes all data including attachments. This can’t be undone.`}
      />
    )
  }

  const showingEditWarningWhileInCO = () => {
    setShowEditWarningWhileInCO(true)
  }

  const onRejectEditWhileInCO = () =>{
    setShowEditWarningWhileInCO(false)
  }

  const toggleSerializationModal = useCallback(() => {
    setDisplaySerializationModal((displaySerializationModal) => !displaySerializationModal)
  }, []);

  const onSubmitSerializationModal = useCallback(() => {
    setDisplaySerializationModal(false);
  }, []);

  const currentItem = revision ?? item;

  const getPayloadForSerialization = useMemo(() => {
    return {
      id: revision?._id ?? item.lastReleaseCmpRev ?? item.lastReleasePrdRev,
      alias: (revision?.alias ?? item.alias)?.toUpperCase(),
    };
  }, [revision, item]);

  const coExist = useMemo(() => checkCoExist(item), [item]);

  const { build: isBuildEnabled } = useDuroFlag();

  useEffect(() => {
    let releasedRev = Utils.getLastReleasedRevision(item.revisions)
    let isReleasedRev = revision && revision._id === releasedRev._id
    let isSupplier = user && user.role === "SUPPLIER"
    let isRevisionView = history?.location?.pathname?.includes('revision')

    // Validation to know if it has to be shown in the reviewView
    setShouldShowItem(!isReleasedRev && !isRevisionView ? true : isSupplier)
    setFavs(user.preferences.favorite[`${itemName}s`])

    function handleClickOutside(e) {
      let iconName = e.target.farthestViewportElement ? e.target.farthestViewportElement.className.baseVal : ''
      if (showNavigation && dotsRef.current && !dotsRef.current.contains(e.target) &&
        e.target.className !== 'iconname' && e.target.className !== 'ui-icon ' &&
        iconName !== 'svg coAlert'
      ) {
        setShowNavigation(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    if(itemName === 'component' && !whereUsedParents.length) {
      getParentsForWhereUsed()
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [getParentsForWhereUsed, history?.location?.pathname, item.revisions, itemName, revision, user,
    whereUsedParents.length, showNavigation])
  const displayExportToVsIcon = item && Utils.getVendor(item) === "duro" ? true : false;

  const cmpRes = item.co?.resolution;
  const banner = (() => {
    if (cmpRes === "NONE") {
      return "an UNRESOLVED";
    } else if (cmpRes === "REJECTED") {
      return "a REJECTED";
    } else if (cmpRes === "APPROVED") {
      return "an APPROVED";
    }
    return "an UNRESOLVED";
  })();

  return (
    <div className="cta-icon-toolbar">
      <span className="dots" ref={dotsRef} onClick={threeDotsClickHandler}>
        <DotsIcon />
      </span>
      <nav className="navigation" style={{display: showNavigation ? 'flex' : null}}>
        {
          shouldShowItem &&
          coExist && Permissions.can("edit", itemName, user) &&
          item.isEditableWhileInCo &&
          <InlineIcon
            tooltip={`Edit ${itemName.replace(/^./, itemName[0].toUpperCase())} Information`}
            onClick={showingEditWarningWhileInCO}
          >
            <EditIcon />
            <span className="iconname">Edit</span>
          </InlineIcon>
        }
        {
          shouldShowItem &&
          !coExist && Permissions.can("edit", itemName, user) &&
          <Link to={itemEditLink}>
            <InlineIcon
              tooltip={`Edit ${itemName.replace(/^./, itemName[0].toUpperCase())} Information`}
            >
              <EditIcon />
              <span className="iconname">Edit</span>
            </InlineIcon>
          </Link>
        }
        {
          shouldShowItem &&
          Permissions.can("create", itemName, user) &&
          <InlineIcon
            className={`variant-stroke${openVariantModalFlag ? ' active' : ''}`}
            onClick={openVariantModal}
            tooltip={Utils.capitalizeString(`${itemName} variants`)}
            stopPropagation={true}
          >
            <VariantIcon />
            <span className="iconname">Variant</span>
          </InlineIcon>
        }
        {
          shouldShowItem &&
          Permissions.can("edit", itemName, user) &&
          <InlineIcon
            className={!catObj?.archived ? "" : "disabled"}
            onClick={(!catObj?.archived) ? itemName === 'product' ? duplicate : makeDuplicate : null}
            tooltip={(!catObj?.archived) ? `Duplicate a ${itemName.replace(/^./, itemName[0].toUpperCase())}` : "Duplicate disabled. Component category has been archived"}
          >
            <DuplicateIcon />
            <span className="iconname">Duplicate</span>
          </InlineIcon>
        }
        {
          shouldShowItem &&
          Permissions.can("delete", itemName, user) &&
          <InlineIcon
              tooltip={item.status === "DESIGN" ? Utils.capitalizeString(`delete ${itemName}`) : "Cannot Delete in " + item.status + " Status"}
            className={item.status === "DESIGN" ? "" : "disabled"}
            onClick={item.status === "DESIGN" ? onDeleteClickHandler : null}
          >
            <DeleteIcon />
            <span className="iconname">Delete</span>
          </InlineIcon>
        }
        {
          item.integrations &&
          <ValispaceIcon
            displayExportToVsIcon={displayExportToVsIcon}
            toggleModal={toggleModal}
          />
        }
        {
          displayValispaceImport &&
          <ImportFromValispace
            toggleModalOnComponentViewPage={true}
            component={item}
            onClose={toggleModal}
          />
        }
        {
          itemName === 'component' &&
          <Fragment>
            <InlineIcon
              onClick={openWhereUsedModal}
              className={whereUsedModal.open ? "active" : ""}
              tooltip="Where used"
            >
              <WhereUsedSrc/>
              <span className="iconname">Where used</span>
            </InlineIcon>

            <WhereUsedModal
              showLoading={false}
              component={revision ? revision : item}
              openWhereUsedModal={whereUsedModal.open}
              objectIds={revision ? [revision._id] : [item._id]}
              closeWhereUsedModal={closeWhereUsedModal}
              history={history}
              mode={revision ? 'revisionView' : 'componentView'}
              parents={whereUsedParents}
            />
          </Fragment>
        }

        {
          user?.currentEnv === "LIVE" && isBuildEnabled &&
          <CustomIconButton disableRipple={true} disabled={currentItem.modified} onClick={toggleSerializationModal}>
            <Serialization />
            <span className="icon-title">Serialize</span>
          </CustomIconButton>
        }

        {
          shouldShowItem &&
          <InlineIcon
            className={"fav-icon" + (favs.indexOf(item._id) > -1 ? " faved" : "")}
            onClick={toggleFavorite}
            tooltip={`Save ${itemName.replace(/^./, itemName[0].toUpperCase())} to Favorites`}
          >
            <FavoriteIcon />
            <span className="iconname">Favorite</span>
          </InlineIcon>
        }

        {/* Serialization Icon & modal to Serialization Icon */}
        {
          !coExist &&
          itemName === 'product' &&
          !revision && // PENDING this icon for revision view
          getSerializationIcon()
        }
        {
          shouldShowItem &&
          itemName === 'product' &&
          !coExist && Permissions.can("edit", "product", user) && productionBuildModal.open &&
          <ProductionBuildModal
            isOpen={productionBuildModal.open}
            onCancel={closeProductionBuildModal}
            object={item}
            dispatch={dispatch}
            user={user}
            ref={productionBuildModalNodeRef}
            refreshSerializationTab={refreshSerializationTab}
            isProductionBuildExist={isProductionBuildExist}
            setIsProductionBuildExist={setIsProductionBuildExist}
          />
        }

        {
          Permissions.can("view", "export_cmp_prd", user) &&
          <CustomBlueToolTip customClazz="right-arrow-tooltip" notAllowedForStep={0}>
            <InlineIcon
              onClick={e => xport(e, history.location.pathname.includes('revision') ? 'revision' : itemName)}
              tooltip={`Export this ${revision ? 'Revision' : itemName.replace(/^./, itemName[0].toUpperCase())}`}
            >
              <ExportIcon />
              <span className="iconname">Export</span>
            </InlineIcon>
          </CustomBlueToolTip>
        }
        {
          Permissions.can("view", "revision", user) &&
          <InlineIcon
              className={revModal && !modifiedRevision ? "active" : ""}
            onClick={e => openRevModal(e)}
            tooltip={`View ${itemName.replace(/^./, itemName[0].toUpperCase())} Revisions`}
          >
            <RevsIcon />
            <span className="iconname">History</span>
          </InlineIcon>
        }

        {/* Modal appears when click on 'Item Variant' */}
        { renderVariantModal() }

        {/* Modal appears when click on 'History' --------' */}
        {
          revModal && 
          <RevisionHistoryTable
            componentId={item._id}
            sourceRevId={item.modified || coExist ? item._id : null} // not used
            userRole={user.role}
            viewType={revision ? "revision" : ""}
            mode={modifiedRevision ? "modified" : ""}
            addToChangeOrder={addingChangeOrder}
            alias={item.alias}
            revertBack={revertBack}
            revModal={revModal}
            closeRevModal={closeRevModal}
            revList={revList}
            setRevList={setRevList}
            currentRevId={window.location.pathname.split("/")[3]}
            isParentRevId={history.location.state}
          />
        }
        {/* end 'History Modal' --------------' */}

        {
          shouldShowItem &&
          coExist && item.modified &&
          Permissions.can("create", "change_order", user) &&
          item.status !== 'DESIGN' &&
          item.isEditableWhileInCo &&
          renderChangeOrderActions()
        }

        {
          shouldShowItem &&
          item.modified && !coExist && item.status !== 'DESIGN' &&
          <div className="changeorder-actions-block">
            {
              user && user.role === "SUPPLIER" ?
                  <InlineIcon
                    tooltip={`${Utils.capitalizeString(itemName)} has been modified`}
                    name="modified-revision"
                    onClick={e => openRevModal(e)}
                  >
                    <CoAlertIcon />
                    <span className="iconname">Change Order</span>
                  </InlineIcon> :
                  Permissions.can("create", "change_order", user) &&
                  renderChangeOrderActions()
            }
          </div>
        }

        {
          shouldShowItem &&
          !coExist && !item.modified &&
          Permissions.can("create", "change_order", user) && item.status !== 'DESIGN' &&
          <InlineIcon
            onClick={e => addingChangeOrder(e)}
            tooltip="Add to change order"
          >
            <CoIcon />
            <span className="iconname">Change Order</span>
          </InlineIcon>
        }
      </nav>

      {/* Modal appears when click on 'delete' icon */}
      { shouldShowItem && renderDeleteModal() }

      {/* Modal appears when click on 'edit' icon (only unresolved change) */}
      {
        shouldShowItem &&
        coExist && showEditWarningWhileInCO &&
        <WarningModal
          onCancel={onRejectEditWhileInCO}
          linkTo={itemEditLink}
          isWarningIcon={true}
          title={"Warning"}
          description={`This ${itemName} is currently in ${banner} Change Order. Any edits could impact the intention of the Change Order.`}
        />
      }

      {/* Modal appears when click on 'Rever Back' submenu-item from 'Change Order' */}
      {
        shouldShowItem &&
        (coExist || item.modified) &&
        Permissions.can("edit", itemName, user) && revertModal.open &&
        <RevertBackModal
          onCancel={closeRevertModal}
          onConfirm={revertingChanges}
        />
      }
      {/* Modal appears when click on 'serialization' icon */}
      {displaySerializationModal && (
        <SerializationModal
          itemToBeSerialized={getPayloadForSerialization}
          onCancel={toggleSerializationModal}
          onClose={toggleSerializationModal}
          onSubmitCallback={onSubmitSerializationModal}
          open={displaySerializationModal}
          title="Serialize and Create New Lot"
        />
      )}
    </div>
  )
}

const CustomIconButton = styled(IconButton)(() => ({
  padding: "0.438rem 0.5rem",
  "svg": {
    width: "1.5rem",
    height: "1.25rem",
  },
  "&:hover": {
    "svg": {
      "g": {
        "path:nth-child(2)": {
          fill: colorPalette.lightGreen,
        },
      },
    },
  },
  "&:disabled": {
    "svg": {
      "g": {
        "path:nth-child(2)": {
          opacity: 0.4,
        },
      },
    },
  },
  "& .icon-title": {
    display: "none",
    fontSize: "0.875rem",
  },
  "@media (max-width: 53.75rem)": {
    justifyContent: "flex-start",
    padding: "0.625rem 1.25rem",
    "& .icon-title": {
      display: "inline-block",
    },
    "svg": {
      marginRight: "0.5rem",
    },
  },
}));


export default CTAIconToolbar
