import { SvgIcon } from "@mui/material";

export const ReportsIcon = () => (
  <SvgIcon fontSize="inherit" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path d={`M4.833 5.389a.533.533 0 0 0 .39-.167.533.533 0 0 0 .166-.389.533.533 0 0 
      0-.167-.389.533.533 0 0 0-.389-.166.533.533 0 0 0-.389.166.533.533 0 0 0-.166.39c0 
      .147.055.277.166.388.112.111.241.167.39.167zm0 3.167a.533.533 0 0 0 .39-.167A.533.533 
      0 0 0 5.388 8a.533.533 0 0 0-.167-.389.533.533 0 0 0-.389-.167.533.533 0 0 0-.389.167.533.533 
      0 0 0-.166.389c0 .148.055.278.166.389.112.111.241.167.39.167zm0 3.166a.533.533 0 0 
      0 .39-.166.533.533 0 0 0 .166-.39.533.533 0 0 0-.167-.388.533.533 0 0 0-.389-.167.533.533 
      0 0 0-.389.167.533.533 0 0 0-.166.389c0 .148.055.277.166.389.112.11.241.166.39.166zm-2.389 
      2.945c-.296 0-.555-.111-.777-.334a1.066 1.066 0 0 
      1-.334-.777V2.444c0-.296.111-.555.334-.777.222-.223.481-.334.777-.334H11L14.667 
      5v8.556c0 .296-.111.555-.334.777a1.066 1.066 0 0 
      1-.777.334H2.444zm0-1.111h11.112V5.619h-3.167V2.444H2.444v11.112zm.041-11.112V5.62 
      2.444v11.112V2.444z" fill="#CBCBCB`} fill-rule="evenodd"/>
  </SvgIcon>
);
