import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";

export async function createUser(
  client: ApolloClient<NormalizedCacheObject>,
  variables = {},
) {
  const response = await client.mutate({
    mutation: CREATE_USER,
    variables,
  });
  return response;
}

export const CREATE_USER = gql`
  mutation ($input: CreateUserInput) {
    createUser(input: $input) {
      id
    }
  }
`;

export async function updateUser(
  client: ApolloClient<NormalizedCacheObject>,
  variables = {},
) {
  const response = await client.mutate({
    mutation: UPDATE_USER,
    variables,
  });
  return response;
}

export const UPDATE_USER = gql`
  mutation ($input: UpdateUserInput) {
    updateUser(input: $input) {
      id
    }
  }
`;

export async function deleteUser(
  client: ApolloClient<NormalizedCacheObject>,
  variables = {},
) {
  const response = await client.mutate({
    mutation: DELETE_USER,
    variables,
  });
  return response;
}

export const DELETE_USER = gql`
  mutation ($input: DeleteInput) {
    deleteUser(input: $input) {
      id
    }
  }
`;
