import { ApolloError, FetchPolicy, gql } from "@apollo/client";
import { client } from "graphql/apolloClient";
import { CompanyCategoriesFragment } from "graphql/fragment/categoryFragment";

export const GET_COMPANY_CATEGORY = gql`
  query companyCategories($id: ID) {
    companyCategories(id: $id) {
      ...companyCategoriesFragment
    }
  }
  ${CompanyCategoriesFragment}
`;

interface Category {
    name: string;
    shortName: string;
    code: string;
    type: string;
    isDefaultCategory: Boolean;
    isAllowedInPartAndAssembly: Boolean;
}

interface ICompanyCategoriesListArgs {
    fetchPolicy?: FetchPolicy,
    companyId?: string,
  }

export interface ICompanyCategoriesList {
    categories: Category[],
    error?: ApolloError,
    loading?: boolean,
  }

export async function getCategory(args: ICompanyCategoriesListArgs) {
  const { companyId = null, fetchPolicy = "cache-first" } = args;
  const { data, error, loading } = await client.query({
    query: GET_COMPANY_CATEGORY,
    variables: { id: companyId },
    fetchPolicy,
  });
  const categories = data?.companyCategories ?? [];
  return { categories, loading, error };
}
