import "./index.scss"
import React            from "react"
import Utils            from "../../../modules/utils"
import ReactTooltip     from 'react-tooltip'

class InlineIcon extends React.PureComponent
{
    constructor(props)
    {
        super(props)
        this.onClick = this.onClick.bind(this)
    }

    onClick(e)
    {
        if (this.props.stopPropagation)
        {
            e.preventDefault()
            e.stopPropagation()
        }
        let event = Utils.getEvent(this.refs.node)
        this.props.onClick && this.props.onClick(event)
    }

    componentDidUpdate()
    {
        ReactTooltip.rebuild()
    }

    render()
    {
        let markup =
            <div
                key={Math.random()}
                className={"ui-icon " + (this.props.className || '')}
                onClick={this.onClick}
                name={this.props.name}
                data-tip={this.props.tooltip}
                data-type={this.props.tooltipType}
                data-place={this.props.tooltipPlace}
                data-for={this.props.tooltipDataFor}
                data-delay-hide={this.props.tooltipDataFor}
                ref="node"
                style={this.props.customStyles}
                >
                {this.props.children}
            </div>

        return markup
    }
}

InlineIcon.defaultProps =
{
    className : "",
    src       : "",
    onClick   : null,
    name      : ""
}

export default InlineIcon
