export const rootPath = "/"

export const publicPaths =
{
    noAccount:
    {
        pathname: '/no-account',
        state: {},
    },
    invite:
    {
        pathname : "/invite/:token",
        state    : {}
    },
    reset:
    {
        pathname : "/reset",
        state    : {}
    },
    resetWithToken:
    {
        pathname : "/reset/:token",
        state    : {}
    },
    emailConfirm:
    {
        pathname : "/confirm/:token",
        state    : {}
    },
    success:
    {
        pathname : "/success",
        state    : {}
    },
    legal:
    {
        policy:
        {
            pathname : "/legal/policy",
            state    : {}
        },
        terms:
        {
            pathname : "/legal/terms",
            state    : {}
        }
    },
    docs:
    {
        help:
        {
            pathname : "https://duro.zendesk.com/hc/en-us",
            state    : {}
        },
        quickstart:
        {
            pathname : "https://duro.zendesk.com/hc/en-us/articles/360029931451-Quick-Start-Guide",
            state    : {}
        },
        documentation:
        {
            pathname : "https://duro.zendesk.com/hc/en-us",
        },
        changeOrderHelp:
        {
            pathname: 'https://duro.zendesk.com/hc/en-us/articles/360044348651-Change-Order-Validations',
        },
        compareRevisionsHelp:
        {
            pathname: "https://duro.zendesk.com/hc/en-us/articles/360043894472-Creating-Revisions",
        },
        createManualHelp:
        {
            pathname: 'https://duro.zendesk.com/hc/en-us/articles/7731098628116-Create-Components-Manually',
        },
    },
    totpVerify:
    {
        pathname : "/totp-verify",
        state    : {}
    }
}

export const privatePaths = {
  settings: {
    pathname: "/settings",
    state: {},
  },
  dashboard: {
    pathname: "/dashboard",
    state: {},
  },
  datarooms: {
    pathname: "/datarooms",
    state: {},
  },
  changeOrderEditWithMui: {
    pathname: "/changeOrder/new",
  },
  export: {
    pathname: "/export",
    state: {},
  },
  productNew: {
    pathname: "/product/new",
    state: {},
  },
  productEdit: {
    pathname: "/product/edit/:id",
    state: {},
  },
  productView: {
    pathname: "/product/view/:id",
    state: { query: "type:prd" },
  },
  productDiff: {
    pathname: "/product/diff/:id",
    state: { query: "type:prd" },
  },
  productRevision: {
    pathname: "/product/revision/:id",
    state: {},
  },
  search: {
    pathname: "/search",
    state: { query: "type:all" },
  },
  productSearch: {
    pathname: "/search",
    state: { query: "type:prd" },
  },
  products: {
    pathname: "/products",
    state: { query: "type:prd" },
  },
  componentView: {
    pathname: "/component/view/:id",
    state: {},
  },
  componentDiff: {
    pathname: "/component/diff/:id",
    state: { query: "type:cmp" },
  },
  componentEdit: {
    pathname: "/component/edit/:id",
  },
  componentRevision: {
    pathname: "/component/revision/:id",
    state: {},
  },
  componentSearch: {
    pathname: "/search",
    state: { query: "type:cmp" },
  },
  // TODO: Testing route for WIP
  components: {
    pathname: "/components",
    state: { query: "type:cmp" },
  },
  releaseSearch: {
    pathname: "/search",
    state: { query: "type:rel" },
  },
  releaseView: {
    pathname: "/release/view/:id",
    state: {},
  },
  changeOrderNew: {
    pathname: "/changeorder/new/:id",
    state: {},
  },
  changeOrderShow: {
    pathname: "/changeorder/view/:id",
    state: {},
  },
  changeOrderView: {
    pathname: "/changeorder/view/:id",
    state: {},
  },
  changeOrderSearch: {
    pathname: "/search",
    state: { query: "type:co" },
  },
  editChangeOrder: {
    pathname: "/changeorder/edit/:id",
    state: {},
  },
  releaseShow: {
    pathname: "/release/view/:id",
    state: {},
  },
  signOut: {
    pathname: "",
    state: {},
  },
  webhookView: {
    pathname: "/webhook/view/:id",
    state: {},
  },
  docs: {
    help: {
      pathname: "https://duro.zendesk.com/hc/en-us",
    },
    quickstart: {
      pathname:
        "https://duro.zendesk.com/hc/en-us/articles/360029931451-Quick-Start-Guide",
    },
    documentation: {
      pathname: "https://duro.zendesk.com/hc/en-us",
    },
    import: {
      pathname:
        "https://duro.zendesk.com/hc/en-us/articles/360030713392-Importing-Spreadsheet-Files",
    },
  },
  submit_ticket: {
    pathname: "https://duro.zendesk.com/hc/en-us/requests/new",
  },
  integrations: {
    pathname: "https://www.durolabs.co/integrations",
  },
  productionInstanceView: {
    pathname: "/production/instance/view/:id",
    state: {},
  },
  productionInstanceEdit: {
    pathname: "/production/instance/edit/:id",
    state: {},
  },
  buildProductions: {
    pathname: "/build/productions",
    state: {},
  },
  buildProduction: {
    pathname: "/build/production/:productionId",
    state: {},
  },
  buildLots: {
    pathname: "/build/lots",
    state: {},
  },
  buildLot: {
    pathname: "/build/lot/:lotId",
    state: {},
  },
  buildInstances: {
    pathname: "/build/instances",
    state: {},
  },
  buildInstance: {
    pathname: "/build/instance/:instanceId",
    state: {},
  },
  buildExport: {
    pathname: "/build/export",
    state: {},
  },
};
