import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Divider, IconButton, InputAdornment, styled } from "@mui/material";
import { GridRowId, useGridApiRef } from "@mui/x-data-grid-pro";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  CustomSelect,
  DateTimePicker,
  FieldTitle,
  InputField,
  Switch,
  TextArea,
} from "common/components/inputs";
import { FormModal } from "common/components/modals";
import { InputFieldVariants } from "common/constants";
import { ModelType } from "@duro/utils";
import React, { FC, useCallback, useMemo, useState } from "react";
import { colorPalette } from "@duro/themes";
import { Table } from "./table";
import { useOnSubmit } from "./useOnSubmit";
import { useSerializationData } from "./useSerializationData";

type ActionBtnCallBack = (e: MouseEvent | React.MouseEvent) => void;

export interface SerializableItemProps {
  alias: ModelType,
  id: string | undefined,
}

export interface SerializationModalPropTypes {
  itemToBeSerialized?: SerializableItemProps,
  onCancel?: ActionBtnCallBack,
  onClose: ActionBtnCallBack,
  onSubmitCallback: (e: any) => void,
  open: boolean,
  title: string | JSX.Element,
}

export interface MemoTypes {
  _buildDashboardToggleLabel: string,
  _childrenToggleLabel: string,
}

const listStyles = {
  minWidth: "21.875rem",
};
const datePickerProps = {
  calendarStyle: { placement: "bottom-start" },
  customStyle: { display: "block", alignItems: "flex-start" },
  renderInputProps: {
    placeholder: "Enter Date",
    style: { height: "1.25rem", paddingTop: "0.5rem" },
    variant: InputFieldVariants.OUTLINED,
  },
};

const formModalStyle = { width: "94.5rem", overflowY: "inherit" };
const formModalContentStyle = { overflowY: "inherit" };
export const notesStyle = { border: `1px solid ${colorPalette.doveGray}` };
const toggleLabelStyle = {
  fontSize: "0.75rem",
  marginBottom: "1rem",
  marginLeft: "0.3rem",
};

// TODO: Use real options here in the future
const options = Array(25).fill({}).map((_, index) => ({
  label: `991-0000${index + 1}-Component ${index + 1}`,
  value: index + 1,
}));

function getToggleLabel(enabled: boolean): string {
  return enabled ? "Enabled" : "Disabled";
}

export const SerializationModal: FC<SerializationModalPropTypes> = (({
  itemToBeSerialized,
  onClose,
  onSubmitCallback,
  open,
  title,
}) => {
  const apiRef = useGridApiRef();
  const [addToDashboard, setAddToDashboard] = useState<boolean>(false);
  const [alias] = useState<ModelType>(ModelType.CMP);
  const [autoAssemble, setAutoAssemble] = useState<boolean>(false);
  const [dueDate, setDueDate] = useState<number | null>(null);
  const [id] = useState<string>("");
  const [includeChildren, setIncludeChildren] = useState<boolean>(false);
  const [lotLabels, setLotLabels] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [productionRun, setProductionRun] = useState<number | "">(1);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const onSubmit = useOnSubmit(onSubmitCallback);
  const { currentItem, loading, rows, setRows } = useSerializationData({
    alias,
    id,
    apiRef,
    productionRun: productionRun || 0,
    ...itemToBeSerialized,
  });

  const onChangeLotLabels = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLotLabels(e.target.value);
  }, []);

  const onChangeNotes = useCallback((value: string, e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(value);
  }, []);

  const onChangeDatePicker = useCallback((value: number | null) => {
    setDueDate(value);
  }, []);

  const handleOnSubmit = useCallback(() => {
    if (!dueDate || !productionRun || !currentItem) return;

    onSubmit({
      addToDashboard,
      autoAssemble,
      dueDate,
      includeChildren,
      labels: lotLabels,
      notes,
      productionRun,
      currentItem,
      rows,
      selectionModel,
    });
  }, [
    addToDashboard,
    autoAssemble,
    currentItem,
    dueDate,
    includeChildren,
    lotLabels,
    notes,
    onSubmit,
    productionRun,
    rows,
    selectionModel,
  ]);

  const handleOnIncrement = useCallback(() => setProductionRun((productionRun || 0) + 1), [productionRun]);
  const handleOnDecrement = useCallback(() => {
    if (productionRun && productionRun > 1) {
      setProductionRun(productionRun - 1);
    }
  }, [productionRun]);
  const onChangeProductionRun = useCallback(e => {
    const { value } = e.target;
    setProductionRun(value ? Number.parseInt(e.target.value, 10) : "");
  }, []);
  const handleBuildDashboardToggle = useCallback(() => setAddToDashboard(old => !old), []);
  const handleAutoAssemblyToggle = useCallback(() => setAutoAssemble(old => !old), []);
  const handlePopulateChildrenToggle = useCallback(() => {
    setIncludeChildren(old => !old);
  }, []);

  const onCpnChange = useCallback(e => {
    // Select specific production that need to be serialized
  }, []);

  const disableSubmit = useMemo(() => (
    !dueDate || !productionRun || productionRun < 1
  ), [dueDate, productionRun]);

  const productionRunInputProps = useMemo(() => ({
    endAdornment:
      <InputAdornment position="end">
        <IconButton
          aria-label="Decrement"
          disabled={!productionRun || productionRun <= 1}
          edge="end"
          onClick={handleOnDecrement}
        >
          <RemoveIcon fontSize="small" />
        </IconButton>
        <CustomDivider orientation="vertical" variant="middle" />
        <IconButton
          aria-label="Increment"
          edge="end"
          onClick={handleOnIncrement}
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </InputAdornment>,
  }), [handleOnDecrement, handleOnIncrement, productionRun]);

  const value = useMemo(() => (
    itemToBeSerialized && currentItem ? {
      label: currentItem.cpn?.displayValue,
      value: currentItem.id,
    } : {
      label: "",
      value: "",
    }
  ), [currentItem, itemToBeSerialized]);

  return (
    <FormModal
      contentProps={formModalContentStyle}
      disableSubmit={disableSubmit}
      modalProps={formModalStyle}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      open={open}
      title={title}
    >
      <FieldsWrapper>
        <CpnColumn>
          <CommonFieldWrapper>
          <CustomSelect
            componentStyles={listStyles}
            isRequired={true}
            label={"CPN*"}
            onChange={onCpnChange}
            options={options}
            value={value}
            />
          </CommonFieldWrapper>
          <ToggleButton>
            <FieldTitle
              label={"Include Children"}
              sx={toggleLabelStyle}
            />
            <Switch
              defaultValue={includeChildren}
              label={getToggleLabel(includeChildren)}
              onChange={handlePopulateChildrenToggle}
            />
          </ToggleButton>
        </CpnColumn>
        <ProductionRunColumn>
          <CommonFieldWrapper>
          <InputField
            InputProps={productionRunInputProps}
            onChange={onChangeProductionRun}
            type="number"
            isRequired
            label={"Production Run"}
            value={productionRun}
            />
          </CommonFieldWrapper>
          <ToggleButton>
            <FieldTitle
              label={"Add to Build Dashboard"}
              sx={toggleLabelStyle}
            />
            <Switch
              defaultValue={addToDashboard}
              label={getToggleLabel(addToDashboard)}
              onChange={handleBuildDashboardToggle}
            />
          </ToggleButton>
        </ProductionRunColumn>
        <DatePickerColumn>
          <CommonFieldWrapper>
          <DateTimePicker
            {...datePickerProps}
            EnclosingTag={MuiDatePicker}
            inputFormat={"MMM D, YYYY"}
            isRequired
            label={"Due By"}
            onChange={onChangeDatePicker}
            openPickerIcon={CalendarMonthIcon}
            />
          </CommonFieldWrapper>
          <ToggleButton>
            <FieldTitle
              label={"Auto Assemble"}
              sx={toggleLabelStyle}
            />
            <Switch
              defaultValue={autoAssemble}
              label={getToggleLabel(autoAssemble)}
              onChange={handleAutoAssemblyToggle}
            />
          </ToggleButton>
        </DatePickerColumn>
        <LotLabels>
          <CommonFieldWrapper>
          <InputField
            label={"Lot Labels"}
            onChange={onChangeLotLabels}
            placeholder={"Enter Lot Labels (separate by commas)"}
            />
          </CommonFieldWrapper>
        </LotLabels>
        <Notes>
          <TextArea
            characterLimit={500}
            label={"Lot Notes"}
            onChange={onChangeNotes}
            placeholder={"Enter Lot Notes"}
            styleProps={notesStyle}
            />
        </Notes>
      </FieldsWrapper>
      <TableWrapper>
        <Table
          apiRef={apiRef}
          currentItem={currentItem}
          includeChildren={includeChildren}
          loading={loading}
          rows={rows}
          selectionModel={selectionModel}
          setRows={setRows}
          setSelectionModel={setSelectionModel}
        />
      </TableWrapper>
    </FormModal>
  );
});

const CpnColumn = styled(Box)({
  paddingRight: "0.5rem",
  width: "12.5%",
});

const CustomDivider = styled(Divider)({
  borderColor: colorPalette.dark,
  padding: "0.438rem",
});

const CommonFieldWrapper = styled(Box)({
  margin: "0 0 2rem 0.3rem",
});

const DatePickerColumn = styled(Box)({
  padding: "0 0.5rem",
  width: "12.5%",
});

const FieldsWrapper = styled(Box)({
  alignItems: "flex-start",
  display: "flex",
  marginBottom: "1.563rem",
  position: "relative",
});

const LotLabels = styled(Box)({
  padding: "0 0.5rem",
  width: "40%",
});

const Notes = styled(Box)({
  paddingLeft: "0.5rem",
  width: "22%",
});

const ProductionRunColumn = styled(Box)({
  padding: "0 0.5rem",
  width: "13%",
});

const ToggleButton = styled(Box)({
  marginRight: "0.938rem",
});

const TableWrapper = styled(Box)({
  display: "flex",
  maxWidth: "92.5rem",
});
