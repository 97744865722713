export enum ChangeOrderComponentStatuses {
  "DESIGN" = "DESIGN",
  "PROTOTYPE" = "PROTOTYPE",
  "PRODUCTION" = "PRODUCTION",
  "OBSOLETE" = "OBSOLETE",
}

export const changeOrderComponentStatuses = [
  ChangeOrderComponentStatuses.DESIGN,
  ChangeOrderComponentStatuses.PROTOTYPE,
  ChangeOrderComponentStatuses.PRODUCTION,
  ChangeOrderComponentStatuses.OBSOLETE,
];

export function isValidChangeOrderComponentStatus(
  status?: string
): status is ChangeOrderComponentStatuses {
  if (!status) return false;
  return status in ChangeOrderComponentStatuses;
}

export enum ChangeOrderSidebarClasses {
  COMPONENTS_SIDEBAR = "components-sidebar",
  APPROVERS_SIDEBAR = "approvers-sidebar",
  NOTIFIERS_SIDEBAR = "notifiers-sidebar",
}

export enum ChangeOrderClasses {
  SIDEBAR = "changeorder-sidebar",
  TOOLBAR = "changeorder-toolbar",
  TOOLBAR_BUTTON_GROUP = "changeorder-toolbar-button-group",
}

export const ChangeOrderLayoutConfig = {
  sidebarWidth: 310,
  withSidebar: true,
  withToolbar: true,
  toolbarHeight: 50,
};

export enum ChangeOrderDocumentTypes {
  GENERIC = "GENERIC",
  DATASHEET = "DATASHEET",
  PRODUCT_LITERATURE = "PRODUCT LITERATURE",
  GERBER = "GERBER",
  SCHEMATIC_DRAWING = "SCHEMATIC DRAWING",
  BOARD_FILE = "BOARD FILE",
  FAB_DRAWING = "FAB DRAWING",
  CAD = "CAD",
  STP = "STP",
  DIMENSIONS_DRAWING = "DIMENSIONS DRAWING",
  SPECIFICATION = "SPECIFICATION",
  CERTIFICATION = "CERTIFICATION",
  PROCEDURE = "PROCEDURE",
  ASSEMBLY_INSTRUCTIONS = "ASSEMBLY INSTRUCTIONS",
  TEST_PLAN = "TEST PLAN",
  TEST_RESULTS = "TEST RESULTS",
  QUOTE = "QUOTE",
  ARTWORK = "ARTWORK",
  DRAWING = "DRAWING",
  FORM = "FORM",
  INSTRUCTIONS = "INSTRUCTIONS",
  DWG = "DWG",
  QUALITY_DOCUMENT = "QUALITY DOCUMENT",
}

export const changeOrderDocumentTypes = [
  ChangeOrderDocumentTypes.GENERIC,
  ChangeOrderDocumentTypes.DATASHEET,
  ChangeOrderDocumentTypes.PRODUCT_LITERATURE,
  ChangeOrderDocumentTypes.GERBER,
  ChangeOrderDocumentTypes.SCHEMATIC_DRAWING,
  ChangeOrderDocumentTypes.BOARD_FILE,
  ChangeOrderDocumentTypes.FAB_DRAWING,
  ChangeOrderDocumentTypes.CAD,
  ChangeOrderDocumentTypes.STP,
  ChangeOrderDocumentTypes.DIMENSIONS_DRAWING,
  ChangeOrderDocumentTypes.SPECIFICATION,
  ChangeOrderDocumentTypes.CERTIFICATION,
  ChangeOrderDocumentTypes.PROCEDURE,
  ChangeOrderDocumentTypes.ASSEMBLY_INSTRUCTIONS,
  ChangeOrderDocumentTypes.TEST_PLAN,
  ChangeOrderDocumentTypes.TEST_RESULTS,
  ChangeOrderDocumentTypes.QUOTE,
  ChangeOrderDocumentTypes.ARTWORK,
  ChangeOrderDocumentTypes.DRAWING,
  ChangeOrderDocumentTypes.FORM,
  ChangeOrderDocumentTypes.INSTRUCTIONS,
  ChangeOrderDocumentTypes.DWG,
  ChangeOrderDocumentTypes.QUALITY_DOCUMENT,
];
