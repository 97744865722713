import { Pane, TooltipText } from "./tooltip.styles";
import { ValidationTooltipType } from "./types";
import { DuroButton } from "common/components/button";

export const NoPrimarySourceWarning: ValidationTooltipType = ({ row }) => {
  const cmpType =
    row.alias?.toLowerCase() === "prd"
      ? {
          type: "Product",
          link: "/product",
        }
      : {
          type: "Component",
          link: "/component",
        };
  const alias = cmpType.type;
  return (
    <Pane>
      <TooltipText>
        This {alias} is missing a Primary Source selection.
      </TooltipText>
      <TooltipText>
        {alias}s with a Status value of Prototype or Production should have a
        selected source to procure it.
      </TooltipText>
      <DuroButton>
        <a
          href={`${cmpType.link}/edit/${row.id}`}
          target="_blank"
          style={{ color: "black" }}
        >
          VIEW {cmpType.type.toUpperCase()}
        </a>
      </DuroButton>
    </Pane>
  );
};
