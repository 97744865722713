import { styled } from "@mui/material/styles";

const OverlayContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  padding: theme.spacing(2),
  textAlign: "center",
}));

interface EmptyOverlayProps {
  message?: string;
}

export const EmptyOverlay: React.FC<EmptyOverlayProps> = ({
  message = "No rows to display",
}) => {
  return (
    <OverlayContainer>
      <div>{message}</div>
    </OverlayContainer>
  );
};
