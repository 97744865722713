import "v1/styles/index.scss";
import {
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  PaletteMode,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import { PlatformNotifierProvider } from "common/components/platformNotifier/provider";
import { ToastsProvider } from "common/components/toasts";
import { AppContext, AppSection } from "context";
import { client, setGraphqlHeaders } from "graphql/apolloClient";
import { getUser } from "graphql/query/userQueries";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useDuroTheme } from "@duro/themes";
import USER from "v1/action-types/user";
import Routes from "v1/components/routes";
import buildAction from "v1/helpers/buildAction";
import config from "v1/modules/config";
import Utils from "v1/modules/utils";
import { setAxiosToken } from "utils/api";
import { setGatewayHeaders } from "graphql/gatewayClient";

export default function App() {
  const { redirectToLoginPage } = useRedirectFunctions();
  const { isLoggedIn, accessToken, orgHelper } = useAuthInfo() as any;
  const [userId, setUserId] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [section, setSection] = React.useState(AppSection.DESIGN);
  const theme = useDuroTheme(mode, section);
  const dispatch = useDispatch();

  if (isLoggedIn === false) {
    redirectToLoginPage({ postLoginRedirectUrl: window.location.href });
  }

  // Check if the user should be forwarded to a different domain
  useEffect(() => {
    if (!isLoggedIn) return;

    const org = orgHelper?.getOrgs()?.[0];
    const environment =
      org?.org_metadata?.environment ??
      window.DURO_ENV?.defaultCompanyEnv ??
      "";

    if (environment && environment !== window.location.host) {
      window.location.replace(
        `${window.location.protocol}//${environment}${window.location.pathname}`
      );
    }
  }, [accessToken, isLoggedIn, orgHelper]);

  // Save the access token for use is subsequent API calls
  useEffect(() => {
    if (isLoggedIn && accessToken) {
      // Calling core-api first ensures it has created a new user (if necessary) before calling plm-api
      setGraphqlHeaders(accessToken);
      setGatewayHeaders(accessToken);

      // For plm-api
      Utils.headers = {
        authorization: `Bearer ${accessToken}`,
      };

      // For plm-api without legacy utils
      setAxiosToken(accessToken);
      localStorage.setItem("__uid", userId);

      // Load the user's information if it hasn't already been loaded.
      // This is done here to make sure that the access token is set before making the call.
      if (!userId) {
        getUser(client).then(({ data: _user, errors }) => {
          // Check for errors
          if (errors?.find((e) => e?.message.includes("Error"))) {
            setLoginError(true);
            return;
          }

          if (_user) {
            // Save the User ID
            setUserId(_user.id);

            // Check if this is the user's first login
            const userKey = `firstLogin-${_user.id}`;
            localStorage.setItem("_uid", userId);
            if (
              _user.logins?.length <= 1 &&
              JSON.parse(localStorage.getItem(userKey) as any) === null
            ) {
              localStorage.setItem(userKey, "true");
            }

            // Save the user to the Redux store
            dispatch(buildAction(USER.SETUP_USER, _user));
          }
        });
      }
    } else {
      // Logged out
      setGraphqlHeaders("");
      setUserId("");
      Utils.headers = {};
    }
  }, [accessToken, dispatch, isLoggedIn, userId]);

  const appContext = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
      setUserId: (_userId: string) => {
        setUserId(_userId ?? "");
      },
      userId,
      setSection,
      section,
    }),
    [userId, section]
  );

  const navigateAccount = useCallback(() => {
    window.location.href = `${config.REACT_APP_AUTH_URL}/account`;
  }, []);

  const render = useMemo(
    () => (
      <AppContext.Provider value={appContext}>
        <ThemeProvider theme={theme}>
          <ToastsProvider>
            <PlatformNotifierProvider>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              {userId && <Routes />}
              <Dialog open={loginError}>
                <Box sx={{ margin: "1rem" }}>
                  <DialogTitle>Unable to Load Your Information</DialogTitle>
                  <DialogContent>
                    We encountered a problem while loading your account details.
                    Please check your account setting. If the issue persists,
                    please contact our support team for assistance.
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={navigateAccount}>Account</Button>
                  </DialogActions>
                </Box>
              </Dialog>
            </PlatformNotifierProvider>
          </ToastsProvider>
        </ThemeProvider>
      </AppContext.Provider>
    ),
    [appContext, loginError, navigateAccount, theme, userId]
  );

  return render;
}
