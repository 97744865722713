import "v1/styles/index.scss";
import React from "react";
import "common/analytics";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "@propelauth/react";
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/apolloClient";
import App from "./App";
import store from "v1/app/store";
import config from "./v1/modules/config";

require("v1/scroll-hax");

ReactDOM.render(
  <AuthProvider authUrl={config.REACT_APP_AUTH_URL as string}>
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReduxProvider>
    </ApolloProvider>
  </AuthProvider>,
  document.getElementById("root") as HTMLElement,
);
