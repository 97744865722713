import "./index.scss";
import React, {Component} from "react";
import ModalBox           from "../../../../ui/modal-box";
import { FormModal, TransactionalModal } from "common/components/modals";
import { Typography,Box } from "@mui/material";

class DeleteWarningModal extends Component
{
    constructor(props)
    {
        super(props);
        this.onClose = this.onClose.bind(this);
    }

    onClose()
    {
         this.props.closeDeleteWarningModal("showDeleteWarningModal", false);
    }

    render()
    {
        let markup = <div>
                       <TransactionalModal
                            acceptLabel={"Delete Template"}
                            onClose={this.onClose}
                            onCancel={this.onClose}
                            onAccept={this.props.remove}
                            open={true}
                            title="Delete Template?"
                            variant="danger"
                        >
                            <Box className="modal-body">
                                <Typography>Are you sure you want to delete {this.props.templateName}? <br/>
                                This action cannot be undone.</Typography>
                            </Box>
                        </TransactionalModal>
                     </div>
        return markup;
    }
}

export default DeleteWarningModal;
