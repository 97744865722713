import Utils              from "../../../../../modules/utils"
import DeleteIcon          from "../../../../../assets/icons/cross-icon"
import ExtendedTable      from "../../../common/extended-table"
import {Component} from "react"
import Link               from "../../../../ui/link"
import EditIcon            from "../../../../../assets/icons/edit"
import Permissions        from "../../../../../modules/schemas/permissions"
import InlineIcon         from "../../../../ui/icon/inline-icon.js"
import Avatar from "@duro/base/avatar";
import API from "@duro/v1api";

class CoUsersNotificationList extends Component
{
    constructor(props)
    {
        super(props)
        let viewStyles;
        let externalUserviewStyles;

        try
        {
            viewStyles = window.__userStyles.styles.changeOrderInternalUserNoficationTable || {}
            externalUserviewStyles = window.__userStyles.styles.changeOrderExternalUserNoficationTable || {}
        }
        catch(error) {
            viewStyles = {}
            externalUserviewStyles = {}
        }
        this.state =
        {
            coInternalNotifyUsers : this.props.coInternalNotifyUsers,
            coExternalNotifyUsers : this.props.coExternalNotifyUsers,
            syncWithParentState: false,
            current : "users",
            currentSortItemAscending: true,
            headings :
            [
                {
                    key         : "users",
                    displayName : "USER",
                    tooltip     : "USER",
                    sortable    : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "users", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "users", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "users", "visibility", true),
                    headingClass: "user-col",
                    disabled    : true

                },
                {
                    key         : "groups",
                    displayName : "GROUPS",
                    tooltip     : "GROUPS",
                    sortable    : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "groups", "width", 490),
                    position    : Utils.getStyleValue(viewStyles, "groups", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "groups", "visibility", true),
                    disabled    : true
                }
            ],

            externalUserTableHeadings :
            [
                {
                    key         : "emails",
                    displayName : "EMAILS",
                    tooltip     : "EMAILS",
                    sortable    : true,
                    minWidth    : 215,
                    width       : Utils.getStyleValue(externalUserviewStyles, "emails", "width", 690),
                    position    : Utils.getStyleValue(externalUserviewStyles, "emails", "position", 0),
                    visibility  : Utils.getStyleValue(externalUserviewStyles, "emails", "visibility", true),
                    disabled    : true,
                    // dragable    : false
                }
            ]
        }

        if (this.props.mode !== "view")
        {
            let removeColHeading =
            {
                  key          : "remove",
                  displayName  : "",
                  tooltip      : "",
                  sortable     : false,
                  minWidth     : 30,
                  width        : 30,
                  visibility   : true,
                  headingClass : "remove-col",
                  disabled     : true,
                  // dragable     : false,
                  expandAble   : false,
                  hideFromSettings : true
            }
            this.state.headings.push(removeColHeading)
            this.state.externalUserTableHeadings.push(removeColHeading)
        }
        this.getInternalUserTableRows = this.getInternalUserTableRows.bind(this);
        this.getExternalUserTableRows = this.getExternalUserTableRows.bind(this);
        this.removeInternalUser = this.removeInternalUser.bind(this);
        this.removeExternalUser = this.removeExternalUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.areNotifiersChanged = this.areNotifiersChanged.bind(this);

    }

    areNotifiersChanged(nextProps)
    {
        let { coInternalNotifyUsers } = this.state;
        for(let notifier of nextProps.coInternalNotifyUsers)
        {
            let exists = coInternalNotifyUsers.find((notify) => notify._id === notifier._id && notify.irremovable === notifier.irremovable)
            if(!exists) return true;
        }
    }
    areExternalNotifiersChanged(nextProps) {
        const { coExternalNotifyUsers } = this.state;

        if (nextProps.coExternalNotifyUsers.length !== coExternalNotifyUsers.length) {
            return true;
        }

        for (let i = 0; i < nextProps.coExternalNotifyUsers.length; i++) {
            if (nextProps.coExternalNotifyUsers[i] !== coExternalNotifyUsers[i]) {
                return true;
            }
        }

        return false;
    }

    componentWillReceiveProps(nextProps) {
        if (this.areNotifiersChanged(nextProps)) {
            this.setState({
                coInternalNotifyUsers: nextProps.coInternalNotifyUsers,
                syncWithParentState: true
            }, this.onChange);
        }

        if (this.areExternalNotifiersChanged(nextProps)) {
            debugger
            this.setState({
                coExternalNotifyUsers: nextProps.coExternalNotifyUsers,
                syncWithParentState: true
            }, () => {
                this.props.onRemoveExternalUsers(this.state.coExternalNotifyUsers);
            });
        }
    }
    removeInternalUser(e, itemId)
    {
        e.stopPropagation();
        e.preventDefault();
        let coInternalNotifyUsers = this.state.coInternalNotifyUsers
        let deletedItemIndex;
        coInternalNotifyUsers.forEach((item, i) => {
            if (itemId === item._id)
            {
                deletedItemIndex = i
            }
        })

        coInternalNotifyUsers.splice(deletedItemIndex, 1)
        this.props.enableSaveButton(true)
        this.setState(this.state, this.onChange)
    }

    removeExternalUser(e, removeEmail)
    {
        e.stopPropagation();
        e.preventDefault();
        let coExternalNotifyUsers = this.state.coExternalNotifyUsers
        let deletedItemIndex;
        coExternalNotifyUsers.forEach((email, i) => {
            if (removeEmail === email)
            {
                deletedItemIndex = i
            }
        })

        coExternalNotifyUsers.splice(deletedItemIndex, 1)
        this.props.enableSaveButton(true)
        this.setState(this.state, () => { this.props.onRemoveExternalUsers(coExternalNotifyUsers) })
    }

    onChange()
    {
        this.props.onRemoveUsers(this.state.coInternalNotifyUsers)
    }

    getInternalUserTableRows()
    {
        let coInternalNotifyUsers = this.props.coInternalNotifyUsers
        let _this = this
        let rows = coInternalNotifyUsers.map((user, i) =>
            {
                let cells =
                {
                    "users":
                    {
                        value        : user.firstName + " " + user.lastName,
                        tooltip      : user.firstName + " " + user.lastName,
                        cellClass    : "user-info-col",
                        displayValue : [
                        <Avatar
                            findById={API.images.findById}
                            imageId={user.avatar}
                            index={i}
                            imagesWithSrc={this.props.imagesWithSrc}
                            key={Utils.generateUniqueId()}
                            />
                            ,
                            <span
                                className="user-name"
                                data-tip=""
                                key={i + '-name'}
                                onMouseOver={(e) => Utils.checkToolTipAppearance(e, user.name)}
                            >{user.firstName + " " + user.lastName}</span>
                        ]

                    },
                    "groups":
                    {
                        value        : user.groups.join(', '),
                        displayValue : user.groups.join(', '),
                        tooltip      : user.groups.join(', ')
                    },
                }
                if (this.props.mode !== "view")
                {
                    let removColValue = {
                      value: "",
                      cellClass: "remove-col",
                      displayValue: !user.irremovable && (
                        <div
                          onClick={(e, i) =>
                            _this.removeInternalUser(e, user._id)
                          }
                        >
                          <InlineIcon
                            tooltip="Remove from Change Order"
                            tooltipPlace="right"
                          >
                            <DeleteIcon />
                          </InlineIcon>
                        </div>
                      ),
                    };
                    cells.remove = removColValue
                }
                return cells
            })
        return rows

    }

    getExternalUserTableRows()
    {
        let coExternalNotifyUsers = this.props.coExternalNotifyUsers
        let _this = this
        let rows = coExternalNotifyUsers.map((email, i) =>
            {
                let cells =
                {
                    "emails":
                    {
                        value        : email,
                        displayValue : email,
                        tooltip      : email
                    }
                }
                if (this.props.mode !== "view")
                {
                    let removColValue = {
                      value: "",
                      cellClass: "remove-col",
                      displayValue: !(this.props.isMandatoryTemplate &&
                        this.props.mandatoryExternalUsers.includes(email)) && (
                          <div
                            onClick={(e, i) =>
                              _this.removeExternalUser(e, email)
                            }
                          >
                          <InlineIcon
                            tooltip="Remove from Change Order"
                            tooltipPlace="right"
                          >
                            <DeleteIcon />
                          </InlineIcon>
                        </div>
                      ),
                    };
                    cells.remove = removColValue
                }
                return cells
            })
        return rows

    }
    render()
    {
        let headings                  = this.state.headings
        let internalUserRows          = this.getInternalUserTableRows()

        let externalUserTableHeadings = this.state.externalUserTableHeadings
        let externalUserrows          = this.getExternalUserTableRows()
        let co                        = this.props.co

        let markup  =
            <div>
                <ExtendedTable
                    wrapperClassName="co-internal-user-notification-table"
                    wrapperSelectorClass="co-internal-user-notification-table"
                    stylesName="changeOrderInternalUserNoficationTable"
                    themeDataTableId={"coListTable"}
                    headings={headings}
                    rows={internalUserRows || []}
                    endStaticColumns={this.props.mode !== "view" ? 1 : 0}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    afterSyncWithParentState={()=> {}}
                    syncWithParentState={this.state.syncWithParentState}
                    includeToolBar={true}
                    resultText={`${internalUserRows.length} Users`}
                    footerRow={ this.props.mode === "view" ?
                    co.status === 'DRAFT' && Permissions.can("edit", "change_order", this.props.user.data) ? {
                            bordered: true,
                            dataCellEl: <p>Edit to add</p>,
                            indexCellEl:<div className="add-more-actions">
                                            <Link to={"/changeorder/edit/" + co._id}>
                                                <button className='add-trigger'
                                                        data-tip='Edit'
                                                    >
                                                    <InlineIcon><EditIcon /></InlineIcon>
                                                </button>
                                            </Link>
                                         </div>
                        }
                        :
                        internalUserRows.length === 0 &&
                        {
                            dataCellEl: <p>No Users added</p>
                        }
                    :
                    internalUserRows.length === 0 && {
                        dataCellEl: <p>No Users added</p>
                    }
                }
                />

                <ExtendedTable
                    key={this.state.coExternalNotifyUsers.join('-')}
                    wrapperClassName="co-external-user-notification-table"
                    wrapperSelectorClass="co-external-user-notification-table"
                    stylesName="changeOrderExternalUserNoficationTable"
                    themeDataTableId={"coExternalListTable"}
                    headings={externalUserTableHeadings}
                    rows={externalUserrows || []}
                    // startStaticColumns={1}
                    endStaticColumns={1}
                    currentSortItem="emails"
                    currentSortItemAscending={true}
                    includeToolBar={true}
                    afterSyncWithParentState={()=> {}}
                    resultText={`${externalUserrows.length} Emails`}
                    footerRow={ this.props.mode === "view" ?
                    co.status === 'DRAFT' && Permissions.can("edit", "change_order", this.props.user.data) ? {
                            bordered: true,
                            dataCellEl: <p>Edit to add</p>,
                            indexCellEl:<div className="add-more-actions">
                                            <Link to={"/changeorder/edit/" + co._id}>
                                                <button className='add-trigger'
                                                        data-tip='Edit'
                                                    >
                                                    <InlineIcon><EditIcon /></InlineIcon>
                                                </button>
                                            </Link>
                                         </div>
                        }
                        :
                        externalUserrows.length === 0 &&
                        {
                            dataCellEl: <p>No Emails added</p>
                        }
                    :
                    externalUserrows.length === 0 && {
                        dataCellEl: <p>No Emails added</p>
                    }
                }

                />

            </div>
        return markup
    }
}

export default CoUsersNotificationList
