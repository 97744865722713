import { SuperComponent } from "design/models/changeorder";
import {
  ChangeOrderComponentStatuses,
  isValidChangeOrderComponentStatus,
} from "features/changeorders/constants";
import { ClientModulesSchemaValidationResult, ValidationErrorType } from "../validation.types";

export const validStatuses = [
  ChangeOrderComponentStatuses.PROTOTYPE,
  ChangeOrderComponentStatuses.PRODUCTION,
  ChangeOrderComponentStatuses.OBSOLETE,
];

export function validateStatus(
  item: SuperComponent
): ClientModulesSchemaValidationResult {
  const valid =
    isValidChangeOrderComponentStatus(item.status) &&
    item.status != null &&
    validStatuses.includes(item.status);

  return {
    value: item.id,
    valid,
    type: ValidationErrorType.Status,
    children: [],
  };
}
