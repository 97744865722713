import Link                         from "../../../../../ui/link";
import Utils                        from "../../../../../../modules/utils";
import {Component}           from "react";
import ExtendedTable                from "../../../../common/extended-table";
import InlineImageViewer            from "../../../../common/inline-image-viewer/view";
import Config                       from "../../../../../../modules/config";
import { RevisionField } from "@duro/base/revision-field/";
import CpnField from "../../../../common/cpn-field";
import { StatusField } from "@duro/base/status-field";

class Children extends Component
{
    constructor(props)
    {
        super(props)

        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.releaseAssemblyTableView || {};
        }
        catch(error) {
            viewStyles = {};
        }

        this.state =
        {
            current : "cpn",
            currentSortItemAscending: true,
            headings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "Customer Part Number",
                    sortable    : true,
                    minWidth    : 120,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 120),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    disabled    : true

                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true

                },
                {
                    key         : "images",
                    displayName : "Images",
                    tooltip     : "Images",
                    sortable    : false,
                    ascending   : true,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "images", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "images", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "images", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "category",
                    displayName : "Category",
                    tooltip     : "Category",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "category", "width", 140),
                    position    : Utils.getStyleValue(viewStyles, "category", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "category", "visibility", true),
                    disabled    : false

                },
                {
                    key         : "cmpState",
                    displayName : "Workflow State",
                    tooltip     : "Workflow State",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "cmpState", "width", 180),
                    position    : Utils.getStyleValue(viewStyles, "cmpState", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "cmpState", "visibility", true),
                    disabled    : false

                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", 120),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : false

                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 140),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "procurement",
                    displayName : "PROCUREMENT",
                    tooltip     : "PROCUREMENT",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "procurement", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "procurement", "position", 7),
                    visibility  : Utils.getStyleValue(viewStyles, "procurement", "visibility", true),
                    disabled    : false
                }
            ]
        }

        this.getTableRows                   = this.getTableRows.bind(this);

    }

    getTableRows()
    {
        let state = this.state;
        let children = [];
        let list = [];
        list = this.props.list;
        let rows = list.map((item, i) =>
            {
                let to  = "/component/revision/" + item._id;
                let cpn = item.cpn;
                let lastModified        = item.lastModified ? item.lastModified : undefined;
                let lastModifiedTooltip = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;
                cpn = Utils.getCpn(item);

                let cells =
                {
                    "cpn":
                    {
                        value        : cpn,
                        displayValue :
                            <div className="cpn-holder">
                                <Link
                                    to={to}
                                    className="link">
                                    <CpnField item={item} cpn={cpn}/>
                                </Link>
                            </div>,
                        notLink     : true,
                        tooltip     : cpn
                    },
                    "name":
                    {
                        value        : item.name,
                        displayValue : item.name,
                        tooltip      : item.name,
                    },
                    "category":
                    {
                        value        : item.category,
                        displayValue : item.category,
                        tooltip      : item.category,
                    },
                    "cmpState":
                    {
                        value        : item.cmpState,
                        displayValue : item.cmpState,
                        tooltip      : item.cmpState,
                    },
                    "revision":
                    {
                       value        : item.revision,
                       displayValue : <RevisionField item={item} />,
                       tooltip      : item.revision,
                    },
                    "status":
                    {
                        value       : item.status,
                        tooltip     : item.status,
                        displayValue :
                            <span className="status-holder">
                                <StatusField item={item} />
                            </span>
                    },
                    "procurement":
                    {
                       value        : item.procurement,
                       displayValue : item.procurement,
                       tooltip      : item.procurement,
                    },
                    "images" :
                    {
                        value       : 0,
                        displayValue: <InlineImageViewer key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={item.images} imagesWithSrc={this.props.imagesWithSrc}/>,
                        notLink     : true,
                        cellClass   : "inline-image-viewer"
                    },
                    rowLink         : to
                }
                return cells
            })
        return rows

    }

    render()
    {
        let headings  = this.state.headings;
        let rows      = this.getTableRows();

        let markup  =
            <div className="release-children">
                <ExtendedTable
                    wrapperClassName="release-list"
                    wrapperSelectorClass="release-list"
                    themeDataTableId={"releaseListTable"}
                    stylesName="releaseAssemblyTableView"
                    headings={headings}
                    allowRowSelect={false}
                    includeToolBar={true}
                    rows={rows || []}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    scrollPagination={true}
                    paginationSize={25}
                    resultText={`${rows.length} results`}
                    footerRow={ rows.length === 0 && {dataCellEl: <p>No Components added</p> }}
                />
            </div>
        return markup
    }
}

export default Children
