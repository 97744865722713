import { sdk } from "features/changeorders/sdk";
import { CellParams } from "../cell.types";
import { RevisionFieldValue } from "../fields";
import { Box, styled } from "@mui/material";
import { colorPalette } from "components/themes";

const RevisionRenderCell = ({ row }: CellParams) => {

  const type = sdk.storage.form.type.getState();
  const resolution = sdk.storage.form.resolution.getState();
  
  return (
    <RevisionContainer>
      <RevisionFieldValue coType={type} resolution={resolution} item={row} />
    </RevisionContainer>
  );
};

export const ModalRevisionCell = {
  renderCell: RevisionRenderCell,
  headerName: "revision",
  field: "lastReleaseRevision",
  width: 90,
};

const RevisionContainer = styled(Box)({
  height: 22,
  display: "flex",
  color: colorPalette.taupeGray,
  position: "relative",
});
