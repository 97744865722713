import { styled, Tooltip } from "@mui/material";
import { SuperComponent } from "design/models/changeorder";
import { FC } from "react";
import CoAlertSrc from "v1/assets/icons/co-alert";

type RevisionFieldValueProps = {
  item: SuperComponent;
  vendor?: boolean;
  resolution?: string;
  coType?: string;
};

export const getPreviousRevisionValue = (item: SuperComponent) => {
  if (item.previousStatus === "DESIGN" && item.previousRevisionValue === "") {
    return "1";
  }
  return item.previousRevisionValue
    ? item.previousRevisionValue
    : item.revisionValue;
};

export const RevisionFieldValue: FC<RevisionFieldValueProps> = ({
  item,
  vendor = false,
  resolution,
  coType,
}) => {
  if (!item) {
    return <RevisionValue />;
  }

  const alias = item.alias === "prd" ? "Product" : "Component";

  const shouldDisplayIcon =
    coType === "DCO" && item.previousStatus !== "DESIGN" && item.modified;

  const isModifiedChangeOrder = item.modified;

  const displayValue =
    coType === "DCO"
      ? getPreviousRevisionValue(item)
      : item.previousRevisionValue;

  return (
    <RevisionValue>
      {displayValue !== "" ? displayValue : "—"}

      {(!vendor && shouldDisplayIcon) || isModifiedChangeOrder ? (
        <Tooltip placement={"right"} title={`${alias} has been modified`}>
          <ModifiedIcon>
            <CoAlertSrc />
          </ModifiedIcon>
        </Tooltip>
      ) : (
        ""
      )}
    </RevisionValue>
  );
};

const ModifiedIcon = styled("div")({
  "& svg": {
    height: 6,
    width: 6,
    verticalAlign: "top",
    marginLeft: 1,
  },
});

const RevisionValue = styled("span")({
  minWidth: 25,
  display: "flex",
});
