import { useCallback, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { PaletteMode, SxProps } from "@mui/material";
import { AppSection } from "../../context";
import { StatusValue } from "@duro/utils";

export const colorPalette = {
  black: "#000",
  blackRussian: "#212127",
  blueNavy: "#262633",
  dark: "#2a2a36",
  dark2: "#111",
  dark3: "#222",
  dark4: "#41414b",
  darkGrey: "#353540",
  disabled: "#FFFFFF61",
  doveGray: "#6F6F6F",
  errorRed: "#ED5F63",
  gray: "#262626",
  graySeventy: "#B3B3B3",
  green: "#3cd1b6",
  greenDark: "rgb(60,209,182,.6)",
  grey: "#41414A",
  hover: "#35354A",
  jaguar: "#18171D",
  lightGreen: "#3CD1BF",
  lightGrey: "#CBCBCB",
  purple: "#bf8aff",
  purpleDark: "#ac7ee6",
  silver: "#C6C6C6",
  spanishGray: "#979797",
  taupeGray: "#888888",
  taupeGrayShade: "#88888826",
  white: "#fff",
  yellow: "#FFFD91",
  grey89: "#e3e3e3",
  status: {
    [StatusValue.DESIGN]: "#9ACCFF",
    [StatusValue.PROTOTYPE]: "#FFFD91",
    [StatusValue.PRODUCTION]: "#7AE173",
    [StatusValue.OBSOLETE]: "#888888",
  },
};

declare module "@mui/material/styles" {
  interface DuroTheme {
    component: {
      topBlock: {
        backgroundColor: string;
      };
    };
    complexTable: {
      backgroundColor: string;
      borderColor: string;
      toolbar: {
        disabledButtonColor: string;
      };
    };
    dialogModal: {
      backdropColor: string;
      backgroundColor: string;
    };
    eventModal: {
      attachmentsSections: {
        borderColor: string;
        content: {
          color: string;
        };
      };
      sectionDivider: {
        borderColor: string;
      };
      selectOptions: {
        backgroundColor: string;
      };
      textArea: {
        backgroundColor: string;
        color: string;
      };
    };
    fields: {
      name: {
        variantButton: {
          background: string;
        };
      };
      timestamp: {
        dateColor: string;
        timeColor: string;
      };
    };
    filledInput: {
      backgroundColor: string;
    };
    grid: {
      background: string;
      border: string;
      column: {
        pinned: {
          background: string;
        };
      };
      error: {
        background: string;
      };
      header: {
        background: string;
      };
      row: {
        highlight: string;
        count: {
          color: string;
        };
      };
    };
    gridSimple: {
      background: string;
      border: string;
      column: {
        pinned: {
          background: string;
        };
      };
      error: {
        background: string;
      };
      header: {
        background: string;
        textColor: string;
      };
      row: {
        highlight: string;
        selected: string;
        count: {
          color: string;
        };
      };
    };
    rightSideBar: {
      backgroundColor: string;
      borderColor: string;
    };
    status: Record<StatusValue, SxProps<Theme>>;
    tabs: {
      tabButton: {
        baseTextColor: string;
        error: {
          badgeColor: string;
        };
      };
    };
    toasts: {
      backgroundColor: string;
    };
  }

  interface Theme {
    duro: DuroTheme;
  }

  interface ThemeOptions {
    duro: DuroTheme;
  }
}

// Duro theme. App-wide styling should be defined here instead of individual components.
export function useDuroTheme(mode: PaletteMode, section: AppSection) {
  const modeOverrides = useMemo(() => {
    switch (mode) {
      case "light":
        return {
          background: {
            paper: "#ccc",
          },
          text: {
            primary: "#000",
            secondary: "#333",
          },
          action: {
            active: "#444",
          },
        };
      default:
        return {
          background: {
            paper: "#444",
          },
          text: {
            primary: "#fff",
            secondary: "#ddd",
          },
          action: {
            active: colorPalette.lightGrey,
          },
        };
    }
  }, [mode]);

  const overrides = useMemo(() => {
    const buttonCommon = {
      disabledFontColor: "rgba(42, 42, 54, .75)",
      error: {
        disabledColor: "rgba(245, 74, 80, 0.42)",
        hoverBackground: "rgba(245, 74, 80, 0.62)",
        disabledFontColor: "rgba(42, 42, 54, 0.42)",
        main: "#F54A50",
      },
      info: {
        disabledColor: "rgba(136, 136, 136, 0.42)",
        hoverColor: "rgba(136, 136, 136, 0.32)",
        main: colorPalette.taupeGray,
      },
    };

    switch (section) {
      case AppSection.BUILD:
        return {
          palette: {
            ...modeOverrides,
            primary: {
              main: "#BF8AFF",
              dark: "#A06EDC",
            },
            secondary: {
              main: "#3CD1B6",
              dark: "#00C3A0",
            },
          },
          button: {
            ...buttonCommon,
            primary: {
              hoverBackground: "#D6B4FF",
              disabledBackground: "rgba(60, 209, 181, 0.62)",
            },
          },
          iconButton: {
            hoverColor: "#BF8AFF",
          },
          Switch: {
            switchBase: {
              color: "#CCC",
            },
            colorPrimary: {
              "&.Mui-checked": {
                color: "#BF8AFF",
              },
            },
            track: {
              backgroundColor: "#CCC",
              ".Mui-checked.Mui-checked + &": {
                backgroundColor: "#BF8AFF",
              },
            },
          },
          baseButtonSelectedState: {
            backgroundColor: colorPalette.purple,
          },
          filledInput: {
            borderColor: colorPalette.purple,
          },
          muiPaper: {
            "& button.Mui-selected": {
              backgroundColor: colorPalette.purple,
            },
          },
          textArea: {
            "&:focus": {
              border: `0.125rem solid ${colorPalette.purple} !important`,
            },
          },
          ListItemIcon: {
            color: colorPalette.purple,
          },
        };
      default:
        return {
          palette: {
            ...modeOverrides,
            primary: {
              main: "#3CD1B6",
              dark: "#00C3A0",
            },
            secondary: {
              main: "#BF8AFF",
              dark: "#A06EDC",
            },
          },
          button: {
            ...buttonCommon,
            primary: {
              hoverBackground: "#00FFD1",
              disabledBackground: "rgba(60, 209, 182, .4)",
            },
          },
          iconButton: {
            hoverColor: "#3CD1B6",
          },
          Switch: {
            switchBase: {
              color: "#CCC",
            },
            colorPrimary: {
              "&.Mui-checked": {
                color: "##3CD1B6",
              },
            },
            track: {
              backgroundColor: "#CCC",
              ".Mui-checked.Mui-checked + &": {
                backgroundColor: "##3CD1B6",
              },
            },
          },
          baseButtonSelectedState: {
            backgroundColor: colorPalette.green,
          },
          filledInput: {
            borderColor: colorPalette.green,
          },
          muiPaper: {
            "& button.Mui-selected": {
              backgroundColor: `${colorPalette.green} !important`,
            },
          },
          textArea: {
            "&:focus": {
              border: `0.125rem solid ${colorPalette.green} !important`,
            },
          },
          ListItemIcon: {
            color: colorPalette.green,
          },
        };
    }
  }, [modeOverrides, section]);

  const toggleButtonOverrides = useCallback((value, type) => {
    const lookup: any = {
      PASS: "green",
      FAIL: "red",
      DNF: "blue",
      ECO: colorPalette.green, // Placeholder
      DCO: colorPalette.green, // Placeholder
      MCO: colorPalette.green, // Placeholder
      "N/A": "orange",
    };
    return { [type]: lookup[value] };
  }, []);

  const duroTheme = useMemo(
    () =>
      createTheme({
        palette: {
          common: {
            ...colorPalette,
          },
          mode,
          error: {
            dark: red.A400,
            main: red.A100,
          },
          ...overrides.palette,
        },
        zIndex: {
          // Since for most of the existing modals, value of z-index is 10000
          modal: 10000,
        },
        duro: {
          component: {
            topBlock: {
              backgroundColor: colorPalette.dark,
            },
          },
          complexTable: {
            backgroundColor: colorPalette.taupeGrayShade,
            borderColor: "#979797",
            toolbar: {
              disabledButtonColor: "rgba(255, 255, 255, 0.3)",
            },
          },
          dialogModal: {
            backdropColor: "rgba(0, 0, 0, 0.62)",
            backgroundColor: colorPalette.dark,
          },
          eventModal: {
            attachmentsSections: {
              borderColor: "#8C8C8C",
              content: {
                color: "#C6C6C6",
              },
            },
            sectionDivider: {
              borderColor: "#979797",
            },
            selectOptions: {
              backgroundColor: colorPalette.black,
            },
            textArea: {
              backgroundColor: colorPalette.grey89,
              color: colorPalette.dark,
            },
          },
          fields: {
            name: {
              variantButton: {
                background: "rgba(216, 216, 216, 0.1)",
              },
            },
            timestamp: {
              dateColor: colorPalette.white,
              timeColor: colorPalette.taupeGray,
            },
          },
          filledInput: {
            backgroundColor: colorPalette.black,
          },
          grid: {
            background: colorPalette.taupeGrayShade,
            border: colorPalette.spanishGray,
            column: {
              pinned: {
                background: colorPalette.grey,
              },
            },
            error: {
              background: colorPalette.errorRed,
            },
            header: {
              background: colorPalette.taupeGray,
            },
            row: {
              highlight: colorPalette.hover,
              count: {
                color: colorPalette.taupeGray,
              },
            },
          },
          gridSimple: {
            background: colorPalette.darkGrey,
            border: "transparent",
            column: {
              pinned: {
                background: colorPalette.dark4,
              },
            },
            error: {
              background: colorPalette.errorRed,
            },
            header: {
              background: colorPalette.dark4,
              textColor: colorPalette.taupeGray,
            },
            row: {
              highlight: "transparent",
              selected: "transparent",
              count: {
                color: colorPalette.taupeGray,
              },
            },
          },
          rightSideBar: {
            backgroundColor: colorPalette.darkGrey,
            borderColor: "#17171F",
          },
          status: {
            APPROVED: {
              border: "1px solid #7AE173",
              color: "#7AE173",
            },
            CLOSED: {
              backgroundColor: "unset",
              border: `1px solid ${colorPalette.taupeGray}`,
              color: colorPalette.taupeGray,
            },
            DESIGN: {
              backgroundColor: "#9ACCFF",
              color: "#18171D",
            },
            DRAFT: {
              backgroundColor: "unset",
              border: "1px solid #FFFD91",
              color: "#FFFD91",
            },
            LOCKED: {
              backgroundColor: colorPalette.taupeGray,
              color: "#18171D",
            },
            OBSOLETE: {
              backgroundColor: colorPalette.taupeGray,
              color: "#18171D",
            },
            OPEN: {
              backgroundColor: "unset",
              border: "1px solid #9ACCFF",
              color: "#9ACCFF",
            },
            PRODUCTION: {
              backgroundColor: "#7AE173",
              color: "#18171D",
            },
            PROTOTYPE: {
              backgroundColor: "#FFFD91",
              color: "#18171D",
            },
            REJECTED: {
              border: "1px solid #F54A4F",
              color: "#F54A4F",
            },
            REVIEW: {
              backgroundColor: "unset",
              border: "1px solid #9ACCFF",
              color: "#9ACCFF",
            },
            SERIALIZED: {
              backgroundColor: "#7AE173",
              color: "#18171D",
            },
            SERIALIZING: {
              backgroundColor: "#FFFD91",
              color: "#18171D",
            },
          },
          tabs: {
            tabButton: {
              baseTextColor: "#b3b3b3",
              error: {
                badgeColor: "#f54a4f",
              },
            },
          },
          toasts: {
            backgroundColor: colorPalette.black,
          },
        },
        components: {
          MuiLink: {
            styleOverrides: {
              root: {
                color: overrides.palette.primary.dark,
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                width: "17.5rem",
                display: "inline-block",
                margin: ".5rem",
                minHeight: "20.5rem",
                maxHeight: "20.5rem",
                height: "20.5rem",
                backgroundColor: colorPalette.darkGrey,
                borderRadius: "0.375rem",
              },
            },
          },
          MuiCardHeader: {
            styleOverrides: {
              root: {
                backgroundColor: overrides.palette.primary.dark,
                height: "2rem",
                padding: ".5rem 0.813rem",
                textTransform: "uppercase",
                color: colorPalette.darkGrey,
                "& .MuiCardHeader-title": {
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                },
              },
            },
          },
          MuiCardContent: {
            styleOverrides: {
              root: {
                maxHeight: "18rem",
                overflow: "auto",
                paddingBottom: "0 !important",
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                height: "1.25rem",
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: {
              "*": {
                scrollbarColor: `${colorPalette.taupeGray} transparent`,
              },
              "*::-webkit-scrollbar": {
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: "transparent",
              },
              "*::-webkit-scrollbar-corner": {
                backgroundColor: "transparent",
              },
              "*::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
              "*::-webkit-scrollbar-thumb": {
                backgroundColor: colorPalette.taupeGray,
                borderRadius: "0.25rem",
              },
              "*::-webkit-scrollbar-thumb:hover": {
                backgroundColor: overrides.palette.primary.main,
              },
              body: {
                fontSize: "0.875rem",
              },
              textarea: {
                ...overrides.textArea,
                outline: "none",
              },
            },
          },
          MuiList: {
            styleOverrides: {
              root: {
                backgroundColor: "#17171F",
              },
            },
          },
          MuiListItemButton: {
            styleOverrides: {
              root: {
                "&.Mui-selected": {
                  "& .MuiListItemIcon-root": {
                    color: overrides.ListItemIcon.color,
                  },
                },
                "&:hover": {
                  backgroundColor: colorPalette.dark,
                },
              },
            },
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                "&:hover": {
                  color: overrides.ListItemIcon.color,
                },
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                marginBottom: "1rem",
                padding: "0",
                ".MuiTypography-root": {
                  fontSize: "1.25rem",
                  fontWeight: "500",
                  lineHeight: "1.2",
                },
              },
            },
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                marginBottom: "3rem",
                padding: "0",
                ".MuiTypography-root": {
                  fontSize: "0.875rem",
                },
              },
            },
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                padding: "0",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: "0.375rem",
                boxShadow: "none",
                color: colorPalette.dark,
                height: "1.75rem",
                lineHeight: "1",
                minHeight: "1.75rem",
                minWidth: "4.813rem",
                padding: "0.375rem 1rem",
                textTransform: "none",
              },
            },
            variants: [
              {
                props: { variant: "contained", color: "primary" },
                style: {
                  border: `1px solid ${overrides.palette.primary.main}`,
                  "&:hover": {
                    backgroundColor: overrides.button.primary.hoverBackground,
                  },
                  "&:disabled": {
                    border: "1px solid transparent",
                    backgroundColor:
                      overrides.button.primary.disabledBackground,
                    color: overrides.button.disabledFontColor,
                  },
                },
              },
              {
                props: { variant: "outlined", color: "primary" },
                style: {
                  border: `1px solid ${overrides.palette.primary.main}`,
                  color: overrides.palette.primary.main,
                  "&:hover": {
                    backgroundColor:
                      overrides.button.primary.disabledBackground,
                    border: `1px solid ${overrides.palette.primary.main}`,
                  },
                  "&:disabled": {
                    border: "0.063rem solid transparent",
                    backgroundColor:
                      overrides.button.primary.disabledBackground,
                    color: colorPalette.jaguar,
                  },
                },
              },
              {
                props: { variant: "text", color: "primary" },
                style: {
                  color: overrides.palette.primary.main,
                  "&:hover": {
                    color: overrides.button.primary.hoverBackground,
                  },
                  "&:disabled": {
                    color: overrides.button.primary.disabledBackground,
                  },
                },
              },
              {
                props: { variant: "outlined", color: "info" },
                style: {
                  border: `0.063rem solid ${overrides.button.info.main}`,
                  color: overrides.button.info.main,
                  "&:hover": {
                    backgroundColor: overrides.button.info.hoverColor,
                    borderColor: overrides.button.info.main,
                  },
                  "&:disabled": {
                    backgroundColor: overrides.button.info.disabledColor,
                    border: overrides.button.info.disabledColor,
                    color: colorPalette.jaguar,
                  },
                },
              },
              {
                props: { variant: "contained", color: "secondary" }, // For Header Bars
                style: {
                  border: "none",
                  backgroundColor: "#000000",
                  color: colorPalette.white,
                  "&:hover": {
                    backgroundColor: "#0000009e",
                    border: "none",
                    color: colorPalette.white,
                  },
                  "&:disabled": {
                    backgroundColor: "#0000008e",
                    border: "none",
                    color: "#FFFFFF80",
                  },
                },
              },
              {
                props: { variant: "outlined", color: "secondary" }, // For CANCEL BUTTON in the Header Bars
                style: {
                  border: `1px solid ${colorPalette.black} `,
                  backgroundColor: "transparent",
                  color: colorPalette.black,
                  "&:hover": {
                    backgroundColor: "#00000028",
                    borderColor: colorPalette.black,
                    color: colorPalette.black,
                  },
                  "&:disabled": {
                    background: "transparent",
                    borderColor: "#00000080",
                    color: "#00000080",
                  },
                },
              },
              {
                props: { variant: "outlined", color: "error" },
                style: {
                  border: `1px solid ${overrides.button.error.main}`,
                  color: overrides.button.error.main,
                  "&:hover": {
                    backgroundColor: overrides.button.error.hoverBackground,
                    borderColor: overrides.button.error.main,
                  },
                  "&:disabled": {
                    backgroundColor: overrides.button.error.disabledColor,
                    border: "1px solid transparent",
                  },
                },
              },
            ],
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                "&:hover": {
                  backgroundColor: "transparent",
                  color: overrides.iconButton.hoverColor,
                },
                "&:disabled": {
                  color: colorPalette.disabled,
                },
              },
            },
          },
          MuiToolbar: {
            styleOverrides: {
              root: {
                width: "fit-content",
                padding: "0.5rem 1.375rem 0.5rem 1.5rem",
                borderRadius: "0.375rem",
                backgroundColor: "#353540",
                "@media (min-width: 37.5rem)": {
                  minHeight: "2.5rem",
                },
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: `${colorPalette.white} !important`,
                borderRadius: "0 !important",
                color: colorPalette.dark,
                fontSize: "0.875rem",
                maxHeight: "2.375rem",
                "&.Mui-error": {
                  backgroundColor: `${colorPalette.errorRed} !important`,
                  color: colorPalette.white,
                  ".MuiSvgIcon-root": {
                    color: colorPalette.white,
                  },
                },
                "&.Mui-disabled": {
                  backgroundColor: `${colorPalette.taupeGray} !important`,
                  color: colorPalette.lightGrey,
                },
                ".MuiSvgIcon-root": {
                  color: colorPalette.dark,
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: colorPalette.taupeGray,
                  },
                },
                ".MuiBaseButton, .Mui-disabled": {
                  ".MuiSvgIcon-root": {
                    color: colorPalette.taupeGray,
                  },
                },
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontSize: "0.875rem",
              },
            },
          },
          MuiToggleButton: {
            styleOverrides: {
              root: ({ ownerState }) => {
                const { value } = ownerState;
                return {
                  "&.Mui-selected": {
                    backgroundColor: "transparent",
                    ...toggleButtonOverrides(value, "color"),
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    svg: {
                      path: toggleButtonOverrides(value, "fill"),
                    },
                  },
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                };
              },
            },
          },
          MuiSwitch: {
            styleOverrides: overrides.Switch,
          },
          MuiFilledInput: {
            styleOverrides: {
              root: {
                "&:after": {
                  ...overrides.filledInput,
                },
                "&:before": {
                  borderBottomStyle: "solid !important",
                  borderColor: colorPalette.doveGray,
                },
                "&.Mui-disabled": {
                  borderColor: colorPalette.taupeGray,
                  "&:before": {
                    borderColor: colorPalette.taupeGray,
                  },
                },
              },
            },
          },
          MuiButtonBase: {
            styleOverrides: {
              root: {
                "&.Mui-selected": overrides.baseButtonSelectedState,
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: overrides.muiPaper,
            },
          },
          MuiTooltip: {
            styleOverrides: {
              popper: {
                zIndex: "15000",
              },
            },
          },
          MuiCheckbox: {
            styleOverrides: {
              root: {
                padding: "0",
              },
            },
          },
        },
        typography: {
          // eslint-disable-next-line quotes
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
          fontSize: 14,
        },
      }),
    [mode, overrides, toggleButtonOverrides]
  );

  return duroTheme;
}
