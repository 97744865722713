import { EditFieldState, GridDataType, GridDataTypeValidations } from "design/components/grid";
import { PageItemType } from "../../../../components/utils/constants";
import { AssemblyChild } from "design/models";
import { useMemo } from "react";
import validations from "v1/modules/validations";

export const REF_DES_DELIMITER_REGEX = /,| |:|;/;

function getRefDesMap(children: GridDataType[]) {
  const refDesMap = {} as Record<string, number[]>;
  children.forEach((child, index) => {
    const { refDes } = child;
    if (!refDes) return;

    const refDesValues = refDes.split(REF_DES_DELIMITER_REGEX);
    refDesValues.forEach(value => {
      if (value === "") return;
      if (!refDesMap[value]) refDesMap[value] = [];
      refDesMap[value].push(index);
    });
  });
  return refDesMap;
}

function getItemNumberMap(children: GridDataType[], isAllowedBlankItemNumber?: boolean) {
  const itemNumberMap = {} as Record<string, (number | "")[]>;
  children.forEach((child, index) => {
    const { itemNumber } = child;
    if (isAllowedBlankItemNumber && !itemNumber) return;

    const value = itemNumber ?? "";

    if (!itemNumberMap[value]) {
      itemNumberMap[value] = [index];
    }
    else {
      itemNumberMap[value].push(index);
    }
  });
  return itemNumberMap;
}

interface ValidateRefDesArgs {
  children: GridDataType[];
  updatedIndexes: number[];
  validateErrors: (config: any, value?: string) => Error[]
  values: string[];
}

function validateRefDes({ children, updatedIndexes, validateErrors, values }: ValidateRefDesArgs) {
  const refDesMap = getRefDesMap(children);

  const affectedChildIndexes = [] as number[];
  const updatedChildren = children.map((child, index) => {
    const { refDes } = child;
    const refDesValues = refDes?.split(REF_DES_DELIMITER_REGEX).filter(Boolean) ?? [];

    // No need to update children that are not the one updated and have no ref des or no
    // conflicting ref des.
    if (
      !updatedIndexes.includes(index)
      && values.length
      && (!refDes || !refDesValues.find(value => values.includes(value)))
    ) {
      return child;
    }

    affectedChildIndexes.push(index);

    let message = "";

    const duplicateRefDes = refDesValues.filter(rdv => refDesMap[rdv]?.length > 1);
    if (duplicateRefDes.length) {
      message = `Duplicate Ref Des value: ${[...(new Set(duplicateRefDes))].join()}`;
    }
    else {
      const { quantity } = child;
      const error = validateErrors({
        quantity: Number(quantity) ? Number(quantity) : quantity,
      }, refDes);
      message = error ? error[0].message : "";
    }

    const _validations: GridDataTypeValidations = {
      ...child._validations,
      refDes: {
        state: message ? EditFieldState.ERROR : EditFieldState.NORMAL,
        message,
      },
      valid: !message,
    };
    _validations.valid = isValid(_validations);

    return {
      ...child,
      _validations,
    };
  });

  return { affectedChildIndexes, updatedChildren };
}

interface ValidateItemNumberArgs {
  children: GridDataType[];
  isAllowedBlankItemNumber?: boolean;
  updatedIndexes: number[];
  validateErrors: (config: any, value?: number | "") => Error[]
  values: (number | undefined | null)[];
}

function validateItemNumber(args: ValidateItemNumberArgs) {
  const { children, isAllowedBlankItemNumber, updatedIndexes, validateErrors, values } = args;
  const itemNumberMap = getItemNumberMap(children, isAllowedBlankItemNumber);

  const affectedChildIndexes = [] as number[];
  const updatedChildren = children.map((child, index) => {
    const { itemNumber } = child;

    if (
      !updatedIndexes.includes(index)
      && values.length
      && ((isAllowedBlankItemNumber && itemNumber == null) || !values.includes(itemNumber))
    ) {
      return child;
    }

    affectedChildIndexes.push(index);

    let message = "";

    if (itemNumber != null && itemNumberMap[itemNumber]?.length > 1) {
      // Duplicate item numbers is the primary error to display.
      message = `Value must be unique within an assembly. Value ${itemNumber} is already used in this assembly`;
    }
    else {
      // If not effected by duplicate error message, check for other errors.
      const error = validateErrors({ isAllowedBlankItemNumber }, itemNumber ?? "");
      message = error ? error[0].message : "";
    }

    const _validations: GridDataTypeValidations = {
      ...child._validations,
      itemNumber: {
        state: message ? EditFieldState.ERROR : EditFieldState.NORMAL,
        message,
      },
      valid: !message,
    };
    _validations.valid = isValid(_validations);

    return {
      ...child,
      _validations,
    };
  });

  return { affectedChildIndexes, updatedChildren };
}

function isValid(toCheck: GridDataTypeValidations) {
  return !Object.values(toCheck).find(val => (
    typeof val !== "boolean" && val.state === EditFieldState.ERROR
  ));
}

function getAssemblyValidators(pageItemType: PageItemType) {
  switch (pageItemType) {
    case PageItemType.COMPONENT: return validations.component.children;
    default: return validations.product.children;
  }
}

export function useAssemblyValidators(pageItemType: PageItemType) {
  return useMemo(() => getAssemblyValidators(pageItemType), [pageItemType]);
}

export interface ValidateChangesArgs {
  adds?: GridDataType[];
  changes: Record<string, AssemblyChild>;
  children: GridDataType[];
  hasRefDes: boolean;
  initialItemNumberValues?: number[];
  initialRefDesValues?: string[];
  isAllowedBlankItemNumber?: boolean;
  pageItemType: PageItemType;
}

export function validateChanges(args: ValidateChangesArgs) {
  const {
    adds,
    changes,
    children,
    hasRefDes,
    initialItemNumberValues = [],
    initialRefDesValues = [],
    isAllowedBlankItemNumber,
    pageItemType,
  } = args;

  const affectedChildIndexes = [];

  const validators = getAssemblyValidators(pageItemType);

  const itemNumberIndexes = [] as number[];
  const itemNumberValues = [...initialItemNumberValues];
  const refDesIndexes = [] as number[];
  const refDesValues = [...initialRefDesValues];

  const localChanges = { ...changes };
  let updatedChildren = children.concat(adds?.map(({ itemNumber, notes, quantity, refDes, waste, ...rest }) => {
    localChanges[rest.item?.id ?? ""] = { itemNumber, notes, quantity, refDes, waste };
    return rest;
  }) ?? []);

  updatedChildren = updatedChildren.map((child, index) => {
    const change = localChanges[child.item?.id ?? ""];
    if (change) {
      affectedChildIndexes.push(index);
      const _validations = { ...child._validations, valid: false };
      if (Object.prototype.hasOwnProperty.call(change, "itemNumber")) {
        itemNumberIndexes.push(index);
        if (change.itemNumber != null && !itemNumberValues.includes(change.itemNumber)) {
          itemNumberValues.push(change.itemNumber);
        }
        if (child.itemNumber != null && !itemNumberValues.includes(child.itemNumber)) {
          itemNumberValues.push(child.itemNumber);
        }
      }

      if (Object.prototype.hasOwnProperty.call(change, "notes")) {
        const error = validators.notes.validateErrors({}, change.notes);
        const message = error ? error[0].message : "";
        _validations.notes = {
          message,
          state: message ? EditFieldState.ERROR : EditFieldState.NORMAL,
        };
      }

      const hasRefDesUpdates = Object.prototype.hasOwnProperty.call(change, "refDes");
      const hasQuantityUpdates = Object.prototype.hasOwnProperty.call(change, "quantity");

      if (hasRefDesUpdates || hasQuantityUpdates) {
        const error = validators.quantity.validateErrors(
          {
            refDes: hasRefDes ? change.refDes ?? child.refDes : "",
          },
          hasQuantityUpdates ? change.quantity : child.quantity,
        );
        const message = error ? error[0].message : "";
        _validations.quantity = {
          message,
          state: message ? EditFieldState.ERROR : EditFieldState.NORMAL,
        };

        if (!hasRefDesUpdates) {
          refDesIndexes.push(index);
          if (child.refDes != null) {
            refDesValues.push(...child.refDes.split(REF_DES_DELIMITER_REGEX));
          }
        }
      }

      if (hasRefDesUpdates) {
        if (change.refDes != null) {
          refDesValues.push(...change.refDes.split(REF_DES_DELIMITER_REGEX));
        }
        if (child.refDes != null) {
          refDesValues.push(...child.refDes.split(REF_DES_DELIMITER_REGEX));
        }
        refDesIndexes.push(index);
      }

      if (Object.prototype.hasOwnProperty.call(change, "waste")) {
        if (change.waste == null) {
          _validations.waste = {
            state: EditFieldState.NORMAL,
          };
        }
        else if (isNaN(change.waste)) {
          _validations.waste = {
            message: "Not a valid input",
            state: EditFieldState.ERROR,
          };
        }
        else {
          const roundedValue = change.waste?.toFixed(2);
          const error = validators.waste.validateErrors({ waste: roundedValue }, roundedValue);
          const message = error ? error[0].message : "";
          _validations.waste = {
            message,
            state: message ? EditFieldState.ERROR : EditFieldState.NORMAL,
          };
        }
      }

      _validations.valid = isValid(_validations);

      return {
        ...child,
        ...change,
        _validations,
      };
    }

    return child;
  });

  if (itemNumberIndexes.length || itemNumberValues.length) {
    const res = validateItemNumber({
      children: updatedChildren,
      isAllowedBlankItemNumber,
      updatedIndexes: itemNumberIndexes,
      validateErrors: validators.itemNumber.validateErrors,
      values: itemNumberValues,
    });
    updatedChildren = res.updatedChildren;
    affectedChildIndexes.push(...res.affectedChildIndexes);
  }

  if (refDesIndexes.length || refDesValues.length) {
    const res = validateRefDes({
      children: updatedChildren,
      updatedIndexes: refDesIndexes,
      validateErrors: validators.refDes.validateErrors,
      values: [...(new Set(refDesValues))],
    });
    updatedChildren = res.updatedChildren;
    affectedChildIndexes.push(...res.affectedChildIndexes);
  }

  // Do a final loop through the children to see who still has an error or who has
  // an error at this point.
  const errorCount = updatedChildren.reduce((count, child) => {
    let childCount = 0;

    if (child._validations) {
      Object.values(child._validations).forEach(value => {
        if (typeof value === "boolean") return;

        if (value.state === EditFieldState.ERROR) {
          childCount++;
        }
      });
    }

    return count + childCount;
  }, 0);

  return { errorCount, updatedChildren, affectedChildIndexes: [...(new Set(affectedChildIndexes))].sort() };
}
