import { SuperComponent } from "../../../../../design/models/changeorder";
import {
  ClientModulesSchemaValidationResult,
  defaultClientModulesSchemaValidationResult,
  ValidationErrorType,
} from "../validation.types";
import { sdk } from "../../editor";

function isValidStatus(parent: string, child: string): boolean {
  // Define the invalid pairs
  const invalidPairs: [string, string][] = [
    ["PRODUCTION", "PROTOTYPE"],
    ["PROTOTYPE", "DESIGN"],
    ["PRODUCTION", "DESIGN"],
  ];

  // Check if the pair is in the invalid list
  const isInvalid = invalidPairs.some(
    (pair) => pair[0] === parent && pair[1] === child
  );

  // Return true if valid, false if invalid
  return !isInvalid;
}

export function validateChildrenStatuses(
  item: SuperComponent
): ClientModulesSchemaValidationResult {
  // Parent node returns valid
  if (item.path.length <= 1) {
    return defaultClientModulesSchemaValidationResult;
  }

  const treeNodes = sdk.state.treeDict.getState();

  const [parentNodeId] = item.path;
  const parentNode = treeNodes[parentNodeId];
  const parent = sdk.state.getChangeset(parentNode);
  const child = sdk.state.getChangeset(item);
  const parentStatus = parent.status.getState();
  const childStatus = child.status.getState();
  const isValid = isValidStatus(parentStatus, childStatus);

  if (isValid) {
    const obsoleteWarning = {
      type: ValidationErrorType.ObsoleteParent,
    };

    return {
      ...defaultClientModulesSchemaValidationResult,
      ...(parentStatus === "OBSOLETE" && obsoleteWarning),
    };
  }

  return {
    children: [],
    valid: false,
    value: item.id,
    message:
      "Nested child Components must be at an equal or greater Status value than their parent assembly",
    type: ValidationErrorType.ChildStatusBehind,
  };
}
