import { Chip, styled, SxProps } from "@mui/material";

import {
  ComponentStatus,
  DuroMidPromotionLabelComponent,
} from "../mid-promotion-label";

/**
 * @interface Item
 * @description Represents the status of an item, used to determine whether to display a Chip component or a
 * mid-promotion label for products, components, and similar API responses.
 *
 * @property {string} [status] - The current status of the item.
 * @property {string} [previousStatus] - The previous status of the item.
 */
interface Item {
  status?: string;
  previousStatus?: string;
}

/**
 * @interface StatusFieldProps
 * @description Props for the `StatusField` component. All of them are optional as they may be used in a legacy context
 * (label)
 *
 * @property {any} [theme] - The theme object, provided by Material-UI, used to style the component.
 * @property {string} [label] - The label for the status field. This is used when the item object is not provided to
 * display single status Chip.
 * @property {Item} [item] - The item object containing current and previous status values. This is
 * used to determine if the item is in a mid-promotion state.
 * @property {SxProps} [sx] - The sx prop allows defining custom styles using the Material-UI system.
 */
interface StatusFieldProps {
  theme?: any;
  label?: string;
  item?: Item;
  sx?: SxProps;
}

/**
 * @component
 * @description A styled `Chip` component that represents a single status field. This component
 * uses the theme's `duro.status` property to style the chip based on the label provided.
 *
 * @param {StatusFieldProps} props - The props that define the appearance and behavior of the status field.
 * @returns {React.Element} The rendered `SingleStatusField` component.
 */
export const SingleStatusField = styled(Chip)<StatusFieldProps>(
  ({ theme, label }) => {
    return {
      ...theme.duro.status[label as keyof typeof theme.duro.status],
      minWidth: "6.25rem",
    };
  }
);

/**
 * @component
 * @description The `StatusField` component renders the status of an item, showing either a single status or a
 * mid-promotion label when the status is transitioning between two states. It supports both legacy and modern use
 * cases.
 *
 * @param {StatusFieldProps} props - The props that determine how the status field is displayed.
 * @returns {React.ReactElement} The rendered `StatusField` component.
 */
export const StatusField: React.FC<StatusFieldProps> = ({
  theme,
  label,
  item,
  sx,
}) => {
  const effectiveLabel = item?.status || label;

  if (label && !item) {
    // Support legacy / deprecated consumers of this component
    return (
      <SingleStatusField
        sx={sx}
        theme={theme}
        label={effectiveLabel}
        item={item}
      />
    );
  } else if (item && item?.previousStatus !== item?.status) {
    // mid-promotion state detected
    const previousStatus: ComponentStatus =
      item.previousStatus as ComponentStatus;
    const nextStatus: ComponentStatus = item.status as ComponentStatus;
    return (
        <DuroMidPromotionLabelComponent
            previousStatus={previousStatus}
            nextStatus={nextStatus}
        />
    );
  }
  return <SingleStatusField sx={sx} theme={theme} label={effectiveLabel} />;
};

export default StatusField;
