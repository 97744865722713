import { schemas }  from '../validations'
import Config       from "../config"


let MAIN_CATEGORIES, arranged_categories, categories

let schema_categories = schemas.categories

window.__cpn_rules = () => window.__currentCompanyCpnType === 'CPN_RULES';
window.__companyIs10DigitCpnType = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-10-DIGIT';

window.__companyIs11DigitCpnType = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-11-DIGIT';

window.__companyIs9DigitCpnType  = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-9-DIGIT';
window.__conditional01Variant  = () => window.__currentCompanyCpnType === 'CONDITIONAL-01-VARIANT';
window.__with6DigitPrefixAndCounter = () => window.__currentCompanyCpnType === 'WITH-6-DIGIT-PREFIX-AND-COUNTER';
window.__nonIntelligentCPN = () => window.__isIntelligentCpnScheme === false;
window.__companyWithHybridCpnType = () => window.__currentCompanyCpnType === 'HYBRID-WITH-6-DIGIT-CPN';

window.__libraryType = "GENERAL";
window.__buildScheduleScheme = "DEFAULT";
window.__revSchemeType = "DEFAULT";
window.__cpnSchemeType = "DEFAULT";

let SynonymCategories = {
    "Printed Circuit Board"         : "PCB",
    "PCB"                           : "Printed Circuit Board",

    "Printed Circuit Board Assembly": "PCBA",
    "PCBA"                          : "Printed Circuit Board Assembly",

    "Miscellaneous Mechanical"      : "Misc Mech",
    "Misc Mech"                     : "Miscellaneous Mechanical",
    "Misc Mechanical"               : "Miscellaneous Mechanical",
    "Misc. Mech"                    : "Miscellaneous Mechanical",
    "Misc. Mechanical"              : "Miscellaneous Mechanical",

    "Miscellaneous Electrical"      : "Misc Electrical",
    "Misc Electrical"               : "Miscellaneous Electrical" ,
    "Misc. Electrical"              : "Miscellaneous Electrical",
    "Misc Elec"                     :"Miscellaneous Electrical" ,
    "Misc. Elec"                    : "Miscellaneous Electrical" ,

    "Resistor"                      : "Res",
    "Res"                           : "Resistor",
    "Capacitor"                     : "Cap",
    "Cap"                           : "Capacitor"
}

var setCategoriesList = function getCategories(id=''){

    MAIN_CATEGORIES = {}
    arranged_categories = []
    categories = []

    if(window.__companyIs10DigitCpnType()){
       schema_categories.setArevoCategories()
    }
    else{
        schema_categories.resetCategories()
    }

    // categories = schema_categories.list()
    categories = window.__categories


    categories.forEach(function(category){
       if(!MAIN_CATEGORIES.hasOwnProperty(category.type)){
           MAIN_CATEGORIES[category.type.toUpperCase()] = [];
       }
    });

    categories.forEach((category, i) => {
        category.displayName = category.name
        MAIN_CATEGORIES[category.type.toUpperCase()].push(category)
    });


    for(var key in MAIN_CATEGORIES) {
        arranged_categories.push(MAIN_CATEGORIES[key])
    }


    arranged_categories.forEach((catType) => {
        catType.sort((a, b) =>
        {
            if(isNaN(b.code.toLowerCase())) return 0
            if(a.displayName.toLowerCase() < b.displayName.toLowerCase()) return -1
            if(a.displayName.toLowerCase() > b.displayName.toLowerCase()) return  1
            return 0
        })


        let catTypeName = catType[0].type

        catType.unshift(
            {
                "name"        : "",
                "shortName"   : "",
                "type"        : catTypeName,
                "displayName" : "",
                "disabled"    : true
            }
        )

        catType.unshift(
            {
                "name"        : ("- - " + catTypeName+ " - -"),
                "shortName"   : "",
                "type"        : catTypeName,
                "displayName" : ("- - " + catTypeName+ " - -"),
                "disabled"    : true
            }
        )

        catType.unshift(
            {
                "name"        : "",
                "shortName"   : "",
                "type"        : catTypeName,
                "displayName" : "",
                "disabled"    : true
            }
        )

    })

    categories = []

    arranged_categories.forEach((category, i) => {
        categories = [...categories, ...category]
    });

    return categories
}

// setCategoriesList()



class Categories
{
    static list ()
    {
        setCategoriesList()
        return categories
    }

    static getMainCategoriesNames ()
    {
        setCategoriesList()
        return Object.keys(MAIN_CATEGORIES)
    }

    static normalizeCategory(inputValue){
        let all_categories = this.list_names()

        if(all_categories.indexOf(inputValue)==-1){
            if(SynonymCategories.hasOwnProperty(inputValue)){
                inputValue = SynonymCategories[inputValue]
            }
        }
        return inputValue
    }

    static list_names(){
        let name_list = [], cats = []
        for(let i=0; i < window.__categories.length; i++){
            name_list.push(window.__categories[i].name)
        }
        return name_list;
    }

    static allListSortedWithCode()
    {
        setCategoriesList()
        categories.sort((a, b) =>
        {
            if(a.code < b.code) return -1
            if(a.code > b.code) return  1
            return 0
        })
        return categories
    }

    static getDisplayName(name)
    {
        setCategoriesList()

        if(name === undefined) return ""

        for(let i=0; i<categories.length; i++)
        {
            let category = categories[i]
            if(category.name === name) return category.displayName
        }

        return ""
    }

    static getShortName(name)
    {
        setCategoriesList()
        for(let i=0; i<categories.length; i++)
        {
            let category = categories[i]
            if(category.name === name) return category.shortName
        }

        return ""
    }

    static getType(name)
    {
      switch (name) {
        case null:
        case undefined:
        case "":
          return ""; //handle use cases where category is not present e.g using import module with empty CPN column
        case "Product":
          return "assembly"; //Handling product use case
        default:
          //Handling component with category use cases
          setCategoriesList();
          const cat = categories.find(cat => cat.name === name);
          if (cat) {
            const { isAllowedInPartAndAssembly, type } = cat;
            return isAllowedInPartAndAssembly ? "assembly" : type;
          }
      }
    // If no switch case matches (e.g - no category is found), return empty string
      return "";
    }

    static findByCPN(cpn)
    {
        setCategoriesList();
        for(let i=0; i<categories.length; i++)
        {
            let category = categories[i]
            if(cpn.startsWith(category.code)) return category
        }
    }

    static getKeys()
    {
        let keys =
        [
            "category",
            "name",
            "description",
            "revision",
            "status",
            "specs",
            "documents",
            "images",
            "assembly"
        ]

        return keys
    }

    static modules()
    {
        return schemas.categories
    }
}

export default Categories
