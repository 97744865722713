import React, {Component}             from "react";
import Utils   from    "../../../../modules/utils";
import "./index.scss";

export class TimeStampColumn extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.state = {
                        dateTime: ""
                     }
    }

    componentDidMount(){
        let state = this.state;
        state.dateTime = this.getDateTime();
        this.setState(state);
    }

    getDateTime()
    {
        let {format, value} = this.props;
        let dateTime        = undefined;
        if(value === 0)
        {
            value = undefined;
        }
        if(format === 'date-time-with-long-format' || format === 'date-with-long-format')
        {
            dateTime = Utils.dateTimeWithLongFormat(value);
        }
        return dateTime;
    }

    render()
    {
        let {dateTime} = this.state;
        let {format}   = this.props;
        let markup     = <div className={`default ${this.props.wrapperClassName}`}>
                            <span className="date-span">{dateTime ? dateTime.dateValue : ""}</span>
                            {format !== 'date-with-long-format'
                                && 
                                <span className="time-span">{dateTime ? dateTime.timeValue : ""}</span>
                            }
                        </div>
        return markup;
    }
}

export default TimeStampColumn;
