import "./index.scss"
import React            from "react"
import {connect}        from "react-redux"
import InlineIcon       from "../icon/inline-icon.js"
import LoadingIcon      from "../../../assets/icons/loading"

const Loading = ({loading}) => {
    let markup = null
    if(loading)
    {
        markup =
            <div className="ui-loading">
                <div className="background"></div>
                <div className="loading-block">
                    <InlineIcon>
                      <LoadingIcon />
                    </InlineIcon>
                </div>
            </div>
    }
    return markup
};

export default connect(state => ({
  loading: state.ui.loading
}))(Loading);
