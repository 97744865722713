import "./index.scss";
import "../index-generic.scss";
import React, {Component} from "react"
import Utils              from "../../../../../modules/utils"
import Tooltip            from 'rc-tooltip';
import API                from "../../../../../modules/api"
import Spinner            from "../../../../ui/spinner"
import Loading            from "../../../../ui/inner-loading"
import SimpleTable        from "../../../common/simple-table"
import InlineImageViewer  from "../../../common/inline-image-viewer/view";
import Config             from "../../../../../modules/config";
import ProductIcon        from "../../../../../assets/icons/product-icon";
import ComponentIcon      from "../../../../../assets/icons/ComponentIcon";
import InlineIcon         from "../../../../ui/icon/inline-icon";
import CpnField           from "../../../common/cpn-field";
import { RevisionField } from "@duro/base/revision-field/";
import { StatusField } from "@duro/base/status-field";
import PerfectScrollbar   from 'react-perfect-scrollbar'

export class WhereUsedModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            showLoading: false,
            showToolTip: false,
            whereUsedModal: {
                open : false
            },
            data_loaded: false,
            showInnerLoading:false,
            list : this.props.parents || [],
        }

        this.openWhereUsedModal            = this.openWhereUsedModal.bind(this)
        this.closeWhereUsedModal           = this.closeWhereUsedModal.bind(this)
        this.onWhereUsedModalExternalClick = this.onWhereUsedModalExternalClick.bind(this)
        this.setMyState                    = this.setMyState.bind(this)
        this.setListData                   = this.setListData.bind(this)
        this.getDataFromApi                = this.getDataFromApi.bind(this)
        this.getTypeData                   = this.getTypeData.bind(this)
        this.setQuanties                   = this.setQuanties.bind(this)
        this.getQuantity                   = this.getQuantity.bind(this)
        this.getFlattenedQuantities        = this.getFlattenedQuantities.bind(this)
        this.setQuantiyState               = this.setQuantiyState.bind(this)
        this.sortByType                    = this.sortByType.bind(this)
    }

    componentWillMount(){
        if (this.props.openWhereUsedModal && !this.props.parents)
        {
            this.getDataFromApi()
        }
    }

    getDataFromApi(){
        let state = this.state
        let mode  = this.props.mode === 'revisionView' ? 'revision' : 'component';
        if(!state.data_loaded){
            state.showLoading = this.props.hasOwnProperty("showLoading") ? this.props.showLoading : true
            state.showInnerLoading = !state.showLoading
            if (state.showInnerLoading)
            {
                this.openWhereUsedModal()
            }
            this.setState(state)
            let displayModal = state.showLoading
            Utils.getParentsForWhereUsed(this.props.objectIds, this.props.mode, (err, data) =>
            {
                if(err)
                {
                    //TODO: handle err
                }
                if(data){
                state.list = mode === 'revision' ? this.getUniqueParentRevisions(data.parents) : data.parents;
                state.showLoading = false
                state.data_loaded = true
                state.showInnerLoading = false
                this.setState(state, () => {
                            this.setListData()
                            this.openWhereUsedModal()
                        }
                    )
                }
            })
        }
        else
        {
            this.openWhereUsedModal()
        }
    }

    getUniqueParentRevisions(revisions)
    {
        let uniqueRevisions    = [];
        let uniqueRevisionsIds = [];
        for(let rev of revisions)
        {
            let duplicateRevs = revisions.filter(item => item.parent === rev.parent);
            if(duplicateRevs.length > 1)
            {
                let latestRev = duplicateRevs.reduce((prev, current) => (prev.created > current.created) ? prev : current);
                if(!uniqueRevisionsIds.includes(latestRev._id))
                {
                    uniqueRevisionsIds.push(latestRev._id);
                    uniqueRevisions.push(latestRev);
                }
            }
            else
            {
                uniqueRevisions.push(rev);
            }
        }
        return uniqueRevisions;
    }

    setListData()
    {
        let state = this.state
        let headingText     = ''
        let productsCount   = 0
        let componentsCount = 0

        let productText     = '0 Products'
        let componentText   = '0 Assemblies'

        let list = []
        let _this = this
        state.list.forEach((item) =>
        {
            if (item.alias === 'prd')
            {
                productsCount++;
                productText = productsCount === 1 ? `${productsCount} Product` : `${productsCount} Products`
                list.push(item)
            }

            if (item.alias === 'cmp')
            {
                item.children.forEach((child) =>
                {
                    if (child.component === _this.props.component._id || (_this.props.mode === 'revisionView' && child.assemblyRevision === _this.props.component._id))
                    {
                        componentsCount++;
                        componentText = componentsCount === 1 ? `${componentsCount} Assembly` : `${componentsCount} Assemblies`
                        list.push(item)
                    }
                })

            }
        })

        headingText += productText
        headingText += ', '
        headingText += componentText
        if (componentsCount === 0 && productsCount === 0)
        {
            headingText = '0 Products, 0 Assemblies'
        }

        state.list = list
        state.headingText = headingText;
        this.setState(state, this.setQuanties)
    }

    sortByType(rows, columns)
    {
        let column   = {name: "type", ascending: false}

        rows.sort((a,b) =>
        {
            a = Utils.hash(column.name, a).value
            b = Utils.hash(column.name, b).value

            if(typeof a === "string") a = a.toLowerCase()
            if(typeof b === "string") b = b.toLowerCase()

            if(a < b) return column.ascending ? -1 :  1
            if(a > b) return column.ascending ?  1 : -1
            return 0
        })

        return rows
    }

    setQuanties()
    {
        this.state.list.forEach((item, index) =>
        {
            if (item.alias === 'prd')
            {
                this.getQuantity(item, index, 'prd');
            }

            if (item.alias === 'cmp')
            {
                this.getQuantity(item, index, 'cmp');
            }
        })
    }

    componentDidMount(){
        this._ismounted = true
    }

    componentDidUpdate()
    {
        if (this.props.setModalPosition)
        {
            this.props.setModalPosition()
        }
    }

    componentWillUnmount(){
        this._ismounted = false
    }

    setMyState(state = this.state, cb){
        if (this._ismounted){
            this.setState(state, cb)
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.openWhereUsedModal !== this.props.openWhereUsedModal)
        {
            if(nextProps.openWhereUsedModal)
            {
                if (nextProps.parents.length)
                {
                    let list = this.props.mode === 'revisionView' ? this.getUniqueParentRevisions(nextProps.parents) : nextProps.parents;
                    this.setState({ list }, () => {
                        this.setListData();
                        this.openWhereUsedModal();
                    });
                }
                else
                {
                    this.getDataFromApi();
                }
            }
            else
            {
                this.closeWhereUsedModal();
            }
        }
    }

    getLink(item)
    {
        let link = ""
        switch(item.alias)
        {
            case "prd" :
            {
                link = this.props.mode === 'revisionView' ? `/product/revision/${item._id}` : `/product/view/${item._id}`;
                break
            }
            case "cmp" :
            {
                link = this.props.mode === 'revisionView' ? `/component/revision/${item._id}` : `/component/view/${item._id}`;
                break
            }
            default :
            {
                // noop
            }
        }
        return link
    }

    openWhereUsedModal(event)
    {
        setTimeout(() =>
            document.body.addEventListener("click", this.onWhereUsedModalExternalClick));
        let whereUsedModal  = this.state.whereUsedModal
        whereUsedModal.open = true
        this.setMyState(this.state)
    }

    closeWhereUsedModal()
    {
        document.body.removeEventListener("click", this.onWhereUsedModalExternalClick)
        let whereUsedModal  = this.state.whereUsedModal
        whereUsedModal.open = false
        this.setMyState(this.state, this.props.closeWhereUsedModal)
    }

    onWhereUsedModalExternalClick(event)
    {
        let target     = event.target
        let parent     = this.refs.whereUsedModalNode
        let isExternal = target !== parent && !Utils.isDescendant(parent, target)
        let InlineImageViewer = document.getElementsByClassName('inline-image-viewer-tooltip');
        if(InlineImageViewer && InlineImageViewer[0] && !InlineImageViewer[0].classList.contains('rc-tooltip-hidden'))
        {
            isExternal = false;
        }
        if(isExternal) this.closeWhereUsedModal()
    }

    getTypeData(item)
    {
        let result = {};

        if(item.alias === 'cmp')
        {
            result.displayType = "COMPONENT"
            result.displayIcon = <InlineIcon><ComponentIcon /></InlineIcon>
        }

        if(item.alias === 'prd')
        {
            result.displayType = "PRODUCT"
            result.displayIcon = <InlineIcon><ProductIcon /></InlineIcon>
        }

        return result;
    }

    getFlattenedQuantities(products)
    {
        let _this       = this
        let productIds  = products.map((product) => { return product.productId })
        let mode  = this.props.mode === 'revisionView' ? 'revision' : '';
        API.products.getFlattenedQuantities({componentId: this.props.component._id, productIds, mode} , (err, data) =>
        {
            products.forEach((product) =>
            {
                data.forEach((productWithQty) =>
                {
                    if (product.productId === productWithQty.productId)
                    {
                        _this.setQuantiyState(productWithQty.quantity, product.index)
                    }
                })
            })
        })
    }

    getQuantity(item, index, alias)
    {
        let quantity = 0
        if(alias === 'cmp')
        {
            item.children.forEach((child) =>
            {
                if (this.props.component._id == child.component || (this.props.mode === 'revisionView' && child.assemblyRevision === this.props.component._id))
                {
                    quantity = child.quantity
                }
            })
        }
        else
        {
            let revId = this.props.mode !== 'revisionView' ? this.props.component.revisions[this.props.component.revisions.length-1] : this.props.component._id;
            item.flattenedBom.forEach((child) =>
            {
                if (revId === child.childRev)
                {
                    quantity = child.quantity
                }
            })
        }

        this.setQuantiyState(quantity, index)
    }

    setQuantiyState(quantity, index)
    {
        let state = this.state;

        if(state.list[index])
        {
            state.list[index].quantity = {forComponentId: this.props.component._id, value: quantity}
        }
        else
        {
            state.list[index].quantity = {forComponentId: this.props.component._id, value: quantity}
        }

        this.setState(state)
    }

    render()
    {
        let markup    = null
        let whereUsedModal = this.state.whereUsedModal
        let state = this.state

        if (state.showLoading)
        {
            return <Loading />
        }

        let headings =
        [
            {
                key         : "type",
                displayName : "Type",
                tooltip     : "",
                sortable    : true
            },
            {
                key         : "cpn",
                displayName : "CPN",
                tooltip     : "CPN",
                sortable    : true,
                headingClass: 'cpn-col'
            },
            {
                key         : "name",
                displayName : "Name",
                tooltip     : "Name",
                sortable    : true
            },
            {
                key         : "images",
                displayName : "Images",
                tooltip     : "Images",
                sortable    : false
            },
            {
                key         : "quantity",
                displayName : "Qty",
                tooltip     : "Quantity",
                sortable    : true
            },
            {
                key         : "revision",
                displayName : "Rev",
                tooltip     : "Revision",
                sortable    : true,
                headingClass: 'revision-col'
            },
            {
                key         : "status",
                displayName : "Status",
                tooltip     : "Status",
                sortable    : true,
                headingClass: 'status-cell'

            }
        ]

        let rows = state.list.length > 0 ? state.list.map((result, i) =>
        {
            let cpn         = Utils.getCpn(result);
            let type        = this.getTypeData(result);
            let quantity    = result.quantity && result.quantity.value
            let cells =
            {
                "type":
                {
                    value        : type.displayType,
                    displayValue :  <Tooltip
                                        placement={"left"}
                                        overlayClassName={"rc-left-custom-arrow"}
                                        overlay={result.alias == "prd" ? "Product" : "Component"}
                                    >
                                        <div className="icon-holder">{type.displayIcon}</div>
                                    </Tooltip>
                },
                "cpn":
                {
                    value        : cpn,
                    tooltip      : cpn,
                    displayValue : <span className="link"><CpnField item={result} cpn={cpn}/></span>,
                    cellClass    : 'cpn-col'
                },
                "name":
                {
                    value       : result.name,
                    tooltip     : result.name,
                    displayValue: result.name
                },
                "revision":
                {
                    value       : result.revision,
                    tooltip     : result.revision,
                    displayValue: <RevisionField item={result} />,
                    cellClass   : 'revision-col'
                },
                "status":
                {
                    value       : result.status,
                    tooltip     : result.status,
                    displayValue: <StatusField item={result} />,
                    cellClass   : 'status-cell'

                },
                "images" :
                {
                    value       : 0,
                    displayValue: <InlineImageViewer imagesWithSrc={this.props.imagesWithSrc} key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={result.images} enlargeIconClass={this.props.mode && this.props.mode === 'componentView' ? 'where-used-inline-image-viewer' : ''} />,
                    notLink     : true,
                    cellClass   : "inline-image-viewer"
                },
                "quantity":
                {
                    value       : quantity,
                    tooltip     : quantity,
                    displayValue: quantity,
                    cellClass   : (!!quantity || quantity === 0) && "quantity-cell"
                },
                rowClassName    : result.alias === "prd" ? "product-row" : "",
                rowLink         : this.getLink(result)
            }

            return cells

        }) : []

        rows = this.sortByType(rows, headings)

        markup =
            <div id="where-used-modal" ref="whereUsedModalNode" className={"custom-duro-modal where-used-modal" + (whereUsedModal.open ? " open" : "")}>

                    <div>
                        <div className="header-block flex-h">
                           <div className="item-flex">
                                <span className="where-used-header">Where Used</span>
                            </div>
                           <div className="item-flex">
                                <span className="info">
                                    {state.headingText}
                                </span>
                            </div>
                        </div>
                        <div className="content-block">
                        {

                            state.showInnerLoading?
                            <Spinner iconClassName="where-used-modal-spinner"/>  :
                            <PerfectScrollbar className="custom-table-scroll">
                                <SimpleTable
                                    headings={headings}
                                    rows={rows}
                                    currentSortItem={"type"}
                                    currentSortItemAscending={false}
                                    footerElForNoEmptyData="Component not added to any assemblies, yet"
                                />
                            </PerfectScrollbar>
                        }
                        </div>
                    </div>

            </div>

        return markup
    }
}

export default WhereUsedModal
