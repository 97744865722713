import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { GridSortItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { ProductIcon } from "assets/icons";
import { BreadCrumbIcon, IBreadCrumbProps, useBreadCrumb } from "common/components/breadcrumb";
import { ErrorModal } from "common/components/modals";
import { PageWrapper } from "common/components/pageLayout";
import { IToolbarActions } from "common/components/toolbar";
import { User } from "common/models/user";
import { PageItemType } from "@duro/utils";
import { Product } from "design/models";
import { OrderByType } from "graphql/query/componentsQueries";
import { IProductList, useProductList } from "graphql/query/productsQueries";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import SEARCH from "v1/action-types/search.js";
import UiActions from "v1/action-types/ui";
import ExportMenu from "v1/components/page/common/export/export-menu";
import UpdateStatusModal from "v1/components/page/common/update-status-modal";
import DeleteModal from "v1/components/page/search/modules/delete-modal";
import RevertBackModal from "v1/components/ui/revert-back-modal";
import buildAction from "v1/helpers/buildAction";
import Utils from "v1/modules/utils";
import { useManageNoRowsOverlay } from "../../components/grid/manageNoRowsOverlay";
import { Table } from "../../components/grid/searchTable";
import { ColumnTypes } from "../../utils/columnDefaults";
import { useSearchStringHandler } from "../../utils/searchStringHandler";
import { useTablePreferences } from "../component/assemblyTab/useTablePreferences";
import { IComponentOld } from "../components";

type ModalDataType = Partial<IProductOld>[];
type ModalToggles = { [key: string]: (isVisible: boolean, modalPayload?: any) => void };
type IState = DefaultRootState & {
  user: { data: User },
  search: {
    value: string,
  }
}
export type IProductOld = Omit<IComponentOld, "category"> & { categories: string[] }

export function sortModelToOrderBy(sortModel: GridSortItem[]): OrderByType {
  if (!sortModel?.length) return undefined;

  const { field, sort } = sortModel[0];
  switch (field) {
    case "revision": return [{ version: sort }];
    case "lastUpdated": return [{ lastModified: sort }];
    case "cpn": return [{ legacyCpn: sort }];
    default: return [{ [field]: sort }];
  }
}

const BREAD_CRUMB_VALUES = {
  icon: BreadCrumbIcon.PRODUCTS,
  label: "Products",
  url: "/products",
};
const notEmptySearch = /type:prd\s*\S+/;
const requiredFieldNames = ["cpn", "name"];
const styleName = "searchProducts";
const columnNames: ColumnTypes[] = [
  "cpn", "description", "eid", "images", "lastUpdated", "name", "revision", "status",
];
const GRID_NAME = "products";

const ErrorMessage = [
  {
    message: (
      <>
        Our apologies a network issue occurred,
        please try again in a few minutes
        and if it happens again contact support at
        <a href="mailto:Support@durolabs.co"> Support@durolabs.co </a>
      </>
    ),
  },
];

export const Products: FC = () => {
  const [data, setData] = useState<Product[]>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalData, setModalData] = useState<ModalDataType>([]);
  const [orderBy, setOrderBy] = useState<OrderByType>();
  const [retainPreviousData, setRetainPreviousData] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showExportMenu, setShowExportMenu] = useState<boolean>(false);
  const [showRevertModal, setShowRevertModal] = useState<boolean>(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState<boolean>(false);
  const { user, _searchString } = useSelector((state: IState) => ({
    user: state.user,
    _searchString: state.search.value,
  }));

  const {
    columnVisibilityModel,
    getCurrentStyles,
    onColumnsReordered,
    onColumnsResized,
    onColumnVisibilityChange,
    onSortModelChange,
    sortModel,
  } = useTablePreferences({ columnNames, styleName, requiredFieldNames });

  // set order by from user prefs
  useEffect(() => {
    setOrderBy(sortModelToOrderBy(sortModel));
  }, [setOrderBy, sortModel]);

  const [searchString] = useDebounce(_searchString, 500);

  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const history = useHistory();

  // Workaround for handling search string from location state
  useSearchStringHandler(searchString, "type:prd");

  const {
    products,
    endCursor,
    error,
    fetchProducts,
    hasNextPage,
    loading: productsLoading,
    totalCount,
  }: IProductList = useProductList({ libraryType: user?.data?.activeLibrary?.type, pageSize: 100 });

  const breadCrumbOptions: IBreadCrumbProps[] = useBreadCrumb(BREAD_CRUMB_VALUES);

  useEffect(() => {
    if (!productsLoading) {
      setData(previousData => ([...(retainPreviousData ? previousData : []), ...products]));
      if (!retainPreviousData) {
        apiRef.current?.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
        apiRef.current?.setSelectionModel([]);
      }
      setLoading(false);
      setItemCount(totalCount);
    }
  }, [apiRef, products, productsLoading, retainPreviousData, totalCount]);

  // To handle the core-api errors
  useEffect(() => {
    if (error) {
      setShowErrorModal(true);
      setRetainPreviousData(true);
    }
    else {
      setShowErrorModal(false);
    }
  }, [error]);

  useEffect(() => {
    setLoading(true);
    setRetainPreviousData(false);
    fetchProducts?.(undefined, orderBy, searchString);
  }, [fetchProducts, orderBy, searchString]);

  const loadMoreData = useCallback(() => {
    if (!hasNextPage || loading) return;
    setRetainPreviousData(true);
    setLoading(true);
    fetchProducts?.(endCursor, orderBy, searchString);
  }, [hasNextPage, loading, fetchProducts, endCursor, orderBy, searchString]);

  const refetchComponents = useCallback(() => {
    if (loading) return;
    setRetainPreviousData(false);
    setLoading(true);
    fetchProducts?.(undefined, orderBy, searchString);
  }, [fetchProducts, loading, orderBy, searchString]);

  const showUiAlert = useCallback((payload: any) => (
    dispatch(buildAction(UiActions.SHOW_ALERT, payload))
  ), [dispatch]);

  const deleteSelectedItems = useCallback(() => {
    setShowDeleteModal(false);
    setLoading(true);
    setRetainPreviousData(false);
    dispatch(buildAction(SEARCH.DELETE_ITEMS, { products: modalData }));
  }, [modalData, dispatch]);

  const duplicateSelectedItems = useCallback((items: ModalDataType) => {
    setLoading(true);
    setRetainPreviousData(false);
    dispatch(buildAction(SEARCH.CLEAR_SELECTED_RESULTS));
    dispatch(buildAction(SEARCH.DUPLICATE_ITEMS, { products: items }));
  }, [dispatch]);

  const onCloseBulkStatusModal = useCallback(() => setShowUpdateStatusModal(false), []);
  const onCloseDeleteModal = useCallback(() => setShowDeleteModal(false), []);
  const onCloseErrorModal = useCallback(() => setShowErrorModal(false), []);
  const onCloseExportMenu = useCallback(() => setShowExportMenu(false), []);
  const onCloseRevertModal = useCallback(() => setShowRevertModal(false), []);

  const onContinueUpdateStatusModal = useCallback(() => {
    setShowUpdateStatusModal(false);
    refetchComponents();
  }, [refetchComponents]);

  const openBulkStatusModal = useCallback((isVisible: boolean, requiredData: ModalDataType) => {
    setShowUpdateStatusModal(isVisible);
    setModalData(requiredData);
  }, []);

  const openDeleteModal = useCallback((isVisible: boolean, requiredData: ModalDataType) => {
    setShowDeleteModal(isVisible);
    setModalData(requiredData);
  }, []);

  const openRevertModal = useCallback((isVisible: boolean, requiredData: ModalDataType) => {
    setShowRevertModal(isVisible);
    setModalData(requiredData);
  }, []);

  const openExportMenu = useCallback((isVisible: boolean, requiredData: ModalDataType) => {
    setModalData(requiredData);
    setShowExportMenu(isVisible);
  }, []);

  const revertSelectedItems = useCallback(() => {
    setShowRevertModal(false);
    setLoading(true);
    setRetainPreviousData(false);
    dispatch(buildAction(SEARCH.REVERT_ITEMS, { products: modalData }));
  }, [modalData, dispatch]);

  const toggleModal = useCallback((modalName: string, modalValue: boolean, payload?: ModalDataType) => {
    const modals: ModalToggles = {
      displayBulkStatusModal: openBulkStatusModal,
      displayDeleteModal: openDeleteModal,
      displayExportMenu: openExportMenu,
      displayRevertModal: openRevertModal,
    };
    modals[modalName](modalValue, payload);
  }, [openBulkStatusModal, openDeleteModal, openExportMenu, openRevertModal]);

  const toolbarItems: IToolbarActions[] = useMemo(() => ([{
    Icon: ControlPointIcon,
    label: "New Product",
    onClick: () => history.push("/product/new"),
  }]), [history]);

  const isSearchResult = useMemo(() => (notEmptySearch.test(searchString)), [searchString]);
  const tiles = useMemo(() => ([
    {
      buttonLabel: "Create Products",
      Icon: ProductIcon,
      onClick: () => history.push("/product/new"),
      text: "The Best Way to Get Started With Duro is to Create a New Product",
    },
  ]), [history]);

  const { OverlayComponent, OverlayProps } = useManageNoRowsOverlay(
    isSearchResult,
    productsLoading,
    tiles,
    products.length > 0,
  );
  const mainContent = useMemo(() => (
    <Table
      apiRef={apiRef}
      columnNames={columnNames}
      columnVisibilityModel={columnVisibilityModel}
      duplicateSelectedItems={duplicateSelectedItems}
      getCurrentStyles={getCurrentStyles}
      gridName={GRID_NAME}
      isSearchResult={isSearchResult}
      items={data}
      loading={loading}
      onColumnsReordered={onColumnsReordered}
      onColumnsResized={onColumnsResized}
      onColumnVisibilityChange={onColumnVisibilityChange}
      onRowScrollEnd={loadMoreData}
      onSortModelChange={onSortModelChange}
      OverlayComponent={OverlayComponent}
      OverlayProps={OverlayProps}
      pageItemType={PageItemType.PRODUCT}
      refetch={refetchComponents}
      sortModel={sortModel}
      toggleModal={toggleModal}
      totalCount={itemCount}
    />
  ), [
    itemCount, isSearchResult, apiRef, columnVisibilityModel,
    duplicateSelectedItems, getCurrentStyles, data, loading,
    loadMoreData, onColumnsReordered, onColumnsResized,
    onColumnVisibilityChange, onSortModelChange, OverlayComponent,
    OverlayProps, refetchComponents, sortModel, toggleModal,
  ]);

  const RightSidePanelChildren = useMemo(() => (
    <ExportMenu
      collection={modalData}
      currentUserEmail={user.data.email}
      defaultTemplate={Utils.getDefaultExportTemplate()}
      exportFirstChildWithShallow={false}
      mode={user.data.role === "VENDOR" ? "revision" : ""}
      onExportCancel={onCloseExportMenu}
      onExportComplete={onCloseExportMenu}
      showAlert={showUiAlert}
      user={user}
    />
  ), [modalData, onCloseExportMenu, showUiAlert, user]);
  return (
    <PageWrapper
      breadCrumbOptions={breadCrumbOptions}
      helmetTitle="Products"
      pageHeading="Products"
      rightSidePanelProps={{
        RightSidePanelChildren,
        visible: showExportMenu,
        width: "16.875rem",
      }}
      toolbarProps={{
        toolbarItems,
      }}
    >
      {
        showUpdateStatusModal && (
          <UpdateStatusModal
            components={[]}
            hideUpdateStatusModal={onCloseBulkStatusModal}
            products={modalData}
            refreshAfterBulkUpdate={onContinueUpdateStatusModal}
          />
        )
      }
      {
        showDeleteModal && (
          <DeleteModal
            components={[]}
            deleteSelectedItems={deleteSelectedItems}
            hideDeleteModal={onCloseDeleteModal}
            products={modalData}
          />
        )
      }
      {
        // Show error modal on empty search criteria
        showErrorModal && !isSearchResult && (
          <ErrorModal
            onClose={onCloseErrorModal}
            open={showErrorModal}
            title={"Search Page Error"}
            confirmLabel={"OK"}
            onConfirm={onCloseErrorModal}
            errors={showErrorModal ? ErrorMessage : []}
          />
        )
      }
      {
        showRevertModal && (
          <RevertBackModal
            components={[]}
            onCancel={onCloseRevertModal}
            onConfirm={revertSelectedItems}
            products={modalData}
          />
        )
      }
      {mainContent}
    </PageWrapper>
  );
};
