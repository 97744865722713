import React, { Component } from "react"
import "./index.scss"
import validator            from "validator"
import Icon                 from "../../../../ui/icon"
import InlineIcon           from "../../../../ui/icon/inline-icon";
import Utils                from "../../../../../modules/utils"
import API from "../../../../../modules/api"
import emailSrc             from "../../../../../assets/icons/email-icon.svg"
import closeSrc             from "../../../../../assets/icons/close.svg"
import SettingsIconSrc      from "../../../../../assets/icons/settings";
import LoadingIcon          from "../../../../../assets/icons/loading";
import ModalBox             from "../../../../ui/modal-box"
import PerfectScrollbar     from 'react-perfect-scrollbar';
import CustomizedSpecificationsModal from "../customized-specifications-modal";
import CustomizedDocumentsModal from "../customized-documents-modal";
import SaveTemplateModal    from "../../save-template-modal";
import ManageTemplatesModal from "../../manage-templates-modal";
import Pusher               from 'pusher-js';
import Config               from "../../../../../modules/config";
import TemplateSelect       from "../../template-select"
import { ButtonVariants } from "common/constants";
import { Button } from "@mui/material";

export class ExportMenu extends Component
{
    constructor(props)
    {
        super(props)
        let template = this.props.defaultTemplate;
        let sourcing = template.sourcing;
        this.pusher  = null;
        this.state =
        {
            sendingEmail: false,
            emailModal: false,
            templateList: "close",
            displaySpecificationsModal: false,
            displayDocumentsModal: false,
            displaySaveTemplateModal: false,
            displayManageTemplatesModal: false,
            quoteMiddleClass : "",
            distributorsMiddleClass: "",
            manufacturersMiddleClass: "",
            primarySourceMiddleClass: "",
            disableTemplateModalSaveButton: true,
            selectedTemplate: "",
            templates: [],
            privateTemplates: [],
            companyTemplates: [],
            recentTemplates: [],
            estimatedTime: 0,
            jobId: "",
            export:
            {
                templateID: "",
                templateName: "",
                depth: template.assembly,
                details: "specifications",
                manufacturers: sourcing.includeManufacturers,
                includePrimarySource: sourcing.includePrimarySource,
                distributors: sourcing.includeDistributors,
                quotes: sourcing.includeQuotes,
                exportFormat: template.assemblyLevel,
                includeCompName: false,
                mappedHeaders: template.mappedHeaders,
                docTypes: template.docTypes,
                exportType: template.exportType,
                duroLabels: template.duroLabels,
                allowedDoctypes: template.allowedDocTypes,
                isPublic: false,
                revisionType: this.props.mode !== 'revision' ? template.revisionType : '',
            },

            inputs :
            {
                csv :
                {
                    checked : false
                },
                xlsx :
                {
                    checked : true
                },
                cc :
                {
                    checked : false
                },
                emails :
                {
                    value : "",
                    valid : true,
                    class: "",
                    message: ""
                }
            }
        }
        this.export                 = this.export.bind(this)
        this.closeTemplateOptions   = this.closeTemplateOptions.bind(this)
        this.onModalExternalClick   = this.onModalExternalClick.bind(this)
        this.toggleTemplateList     = this.toggleTemplateList.bind(this)
        this.onInputChange          = this.onInputChange.bind(this)
        this.onEmailsChange         = this.onEmailsChange.bind(this)
        this.onCcChange             = this.onCcChange.bind(this)
        this.validateEmails         = this.validateEmails.bind(this)
        this.download               = this.download.bind(this)
        this.getData                = this.getData.bind(this);
        this.exportData             = this.exportData.bind(this)
        this.formatDataWithItems    = this.formatDataWithItems.bind(this)
        this.onModalClose           = this.onModalClose.bind(this)
        this.onEmailModalClose      = this.onEmailModalClose.bind(this)
        this.toggleModal            = this.toggleModal.bind(this);
        this.onClose                = this.onClose.bind(this);
        this.updateCustomizedSettings = this.updateCustomizedSettings.bind(this);
        this.toggleSourcingCheckboxes = this.toggleSourcingCheckboxes.bind(this);
        this.getTemplatesOptions    = this.getTemplatesOptions.bind(this);
        this.saveTemplate           = this.saveTemplate.bind(this);
        this.saveTemplateData       = this.saveTemplateData.bind(this);
        this.onChange               = this.onChange.bind(this);
        this.manageTemplates        = this.manageTemplates.bind(this);
        this.getTemplates           = this.getTemplates.bind(this);
        this.setCompanyTemplateFlag = this.setCompanyTemplateFlag.bind(this);
        this.handleOutsideClick     = this.handleOutsideClick.bind(this);
        this.getEstimatedTime       = this.getEstimatedTime.bind(this);
        this.unsubscribePusherChannels = this.unsubscribePusherChannels.bind(this);
        this.initPusher             = this.initPusher.bind(this);
    }

    componentWillMount()
    {
        // let element = document.getElementsByClassName("scrollbar-container")[0]
        // if (element)
        //     element.scrollTo(0, 0)
        this.getTemplates();
    }

    initPusher()
    {
        if(Config.PUSHER_KEY_ID && !this.pusher)
        {
            this.pusher  = new Pusher(Config.PUSHER_KEY_ID, {
                cluster: Config.PUSHER_CLUSTER,
                encrypted: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(this.props.defaultTemplate.duroLabels && this.props.defaultTemplate.duroLabels.length !== prevProps.defaultTemplate.duroLabels.length)
        {
            const newState = { export: { ...this.state.export } };
            newState.export.duroLabels = this.props.defaultTemplate.duroLabels
            newState.export.mappedHeaders = this.props.defaultTemplate.mappedHeaders;
            this.setState(newState);
        }
    }

    componentDidMount()
    {
        const { export: existingExport } = this.state;
        const { customTileTitle } = this.props.user?.data?.activeLibrary || {};
        if (customTileTitle) {
            const newState = {
                export: {
                    ...existingExport,
                    mappedHeaders: {
                        ...existingExport.mappedHeaders,
                        "Custom Specs": {
                            ...existingExport.mappedHeaders["Custom Specs"],
                            userLabel: customTileTitle,
                        },
                    },
                },
            };
            this.setState(newState);
        }
        if (!this.props.skipPusherNotification) {
            this.initPusher();
        }

        document.addEventListener("click", this.handleOutsideClick, false);
    }
    componentWillUnmount()
    {
        this.unsubscribePusherChannels();
        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    unsubscribePusherChannels()
    {
        if(this.pusher)
        {
            this.pusher.unsubscribe(this.props.user.data._id+this.state.jobId);
        }
    }

    export(e)
    {
        e.stopPropagation();
        this.setState({ sendingEmail: true });
        this.download()
    }

    closeTemplateOptions()
    {
        document.body.removeEventListener("click", this.onModalExternalClick)
        this.setState({ templateList: "close" })
    }

    onModalExternalClick(event)
    {
        this.closeTemplateOptions()
    }

    toggleTemplateList(event)
    {
        if (this.state.templateList === "open")
            document.body.addEventListener("click", this.onModalExternalClick)

        this.setState({ templateList: this.state.templateList == "close" ? "open" : "close" });
    }

    onInputChange(event, isValid=true)
    {
        const state = { 
            export: { ...this.state.export },
            inputs: { ...this.state.inputs },
            templates: [...this.state.templates]
        };

        if(event.target.name === "exportFormat") {
            state.export.depth = "deep";
        }
        if(event.target.name === "save-template" || event.target.name === "update-template")
        {
            if(isValid)
            {
                state.export.templateName = event.target.value;
                state.disableTemplateModalSaveButton = false;
            }
            else
            {
                state.export.templateName = "";
                state.disableTemplateModalSaveButton = true;
            }

            if(event.target.name === 'update-template')
            {
                let selectedTemplate  = state.templates && state.templates.find(template => template.templateName === event.target.value);
                state.export.isPublic = selectedTemplate ? selectedTemplate.isPublic : state.export.isPublic;
            }
        }
        else if(event.target.name === "check-row")
        {
            state.export.isPublic = event.target.checked;
        }
        else
        {
            if (event.target.type === "checkbox" && event.target.checked)
            state.export[event.target.name] = event.target.value;
            else if (event.target.type === "checkbox" && !event.target.checked)
                state.export[event.target.name] = "specifications";
            else
                state.export[event.target.name] = event.target.value
            if (event.target.value == "download") state.inputs.cc.checked = false
        }
        this.setState(state);
    }

    setCompanyTemplateFlag(value, isValid, templateName){
        const state = { export: { ...this.state.export } };
        state.export.isPublic = value;
        state.export.templateName = isValid ? templateName : "";
        state.disableTemplateModalSaveButton = !isValid;

        this.setState(state);
    }

    updateSourcingOptions(event)
    {
        const state = { export: { ...this.state.export } };
        state.export[event.target.name] = event.target.checked
        let { primarySourceArray, manufacturerArray, distributorsArray, quotesArray } = this.getSourcingArrays();
        if(event.target.name === "includePrimarySource")
        {
            primarySourceArray.forEach((item) => {
                state.export.mappedHeaders[item].isChecked = event.target.checked;
            });
            state.primarySourceMiddleClass = "";
        }
        else if(event.target.name === "manufacturers")
        {
            manufacturerArray.forEach((item) => {
                if(state.export.mappedHeaders[item])
                    state.export.mappedHeaders[item].isChecked = event.target.checked;
            });
            this.toggleSourcingCheckboxes(state.export.mappedHeaders, state);
        }
        else if(event.target.name === "distributors")
        {
            distributorsArray.forEach((item) => {
                state.export.mappedHeaders[item].isChecked = event.target.checked;
            });
            this.toggleSourcingCheckboxes(state.export.mappedHeaders, state);
        }
        else if(event.target.name === "quotes")
        {
            quotesArray.forEach((item) => {
                state.export.mappedHeaders[item].isChecked = event.target.checked;
            });
            this.toggleSourcingCheckboxes(state.export.mappedHeaders, state);
        }
        if(event.target.name === "includeCompName")
        {
            state.export[event.target.name] = !event.target.checked
        }

        this.setState(state)
    }

    onEmailsChange(event)
    {
        const newState = { inputs: { ...this.state.inputs } };
        const value = event.target.value;
        newState.inputs.emails.value = value;
        const valid = this.validateEmails(value)
        newState.inputs.emails.valid = valid;
        newState.inputs.emails.message = valid ? "" : "Please enter a valid email address";
        newState.inputs.emails.class = valid ? "" : "invalid";
        this.setState(newState);
    }

    onCcChange(event)
    {
        const newState = { inputs: { ...this.state.inputs } };
        newState.inputs.cc.checked = !newState.inputs.cc.checked;
        this.setState(newState);
    }

    validateEmails(value)
    {
        if (!value) return true
        let valid  = true
        let emails = value.split(",")

        emails.forEach((email) =>
        {
            if(!validator.isEmail(email.trim()))
            {
                valid = false
            }
        })

        return valid
    }

    download()
    {
        let data = this.getData();
        this.exportData(data);
    }

    getData()
    {
        let inputs     = this.state.inputs;
        let fileType   = inputs.csv.checked ? "csv" : "xlsx";
        let collection = this.props.collection;
        let state      = this.state;
        let data      = this.formatDataWithItems(collection);
        data          = {...data, ...state.export};
        data.ccEmails   = state.inputs.emails.value.replace(/\s|\n|\r/g, '');
        data.fileType = fileType;
        data.exportStructure = "tree";
        data.exportFirstChildWithShallow = true;
        if (data.depth === "deep" && data.exportFormat === "flattened")
        {
            data.depth = "flattened";
        }
        if(this.props.hasOwnProperty("exportFirstChildWithShallow") && this.props.exportFirstChildWithShallow === false)
        {
            data.exportFirstChildWithShallow = false;
        }

        return data;
    }

    exportData(data)
    {
        let _this   = this
        let state = this.state
        let tabId = Utils.generateUniqueId();
        data.tabId = tabId;
        data.mode  = this.props.mode;
        window.__tabId = tabId

        API.services.exportFile(data, (err, response) =>
        {
            if(err)
            {
                return _this.props.showAlert("errors", err.errors)
            }

            setTimeout(function () {
                _this.getTemplates();
                _this.setState({
                    emailModal: state.export.exportType === "email",
                    sendingEmail: false,
                    jobId: response.jobId,
                });
                if(_this.pusher)
                {
                    _this.getEstimatedTime();
                }
            }, 1000);
        })
    }

    getEstimatedTime()
    {
        let userId     = this.props.user.data._id;
        let channel = this.pusher.subscribe(userId + this.state.jobId);
        let _this      = this;

        channel.bind('export', data =>
        {
            _this.setState({ estimatedTime: data.estimatedTime });
        });
    }

    formatDataWithItems(collection, cb)
    {
        let rows = []
        collection.map(function(result)
        {
            rows.push({_id: result._id, type: result.alias})
        });
        return {items: rows}
    }

    onModalClose(e)
    {
        e.preventDefault()
    }

    onEmailModalClose(e)
    {
        this.unsubscribePusherChannels();
        const newState = {
            emailModal: false,
            estimatedTime: 0,
        }
        this.setState(newState, this.props.onExportComplete)
    }

    toggleModal(modalName, modalValue, event)
    {
        event?.stopPropagation();
        this.setState({[modalName]: modalValue});
    }

    onClose(modalName, modalValue)
    {
        this.setState({[modalName]: modalValue, disableTemplateModalSaveButton: true});
    }

    updateCustomizedSettings(settings, specifications=true, duroLabels=null)
    {
        const newState = { export: { ...this.state.export } };
        if(specifications)
        {
            newState.export['mappedHeaders'] = settings;
            newState.export['duroLabels'] = duroLabels;
        }
        else
        {
            newState.export['docTypes'] = settings
            let allowedDocTypes = [];
            settings.forEach((type) => {
                if(type.isChecked)
                {
                    allowedDocTypes.push(type.name);
                }
            });
            newState.export['allowedDoctypes'] = allowedDocTypes;
        }
        this.toggleSourcingCheckboxes(newState.export['mappedHeaders'], newState);
        this.setState(newState);
    }

    getSourcingArrays()
    {
        let primarySourceArray = ["Primary Source Unit Price", "Primary Source MPN", "Primary Source Manufacturer", "Primary Source DPN", "Primary Source Distributor", "Total Price", "Primary Source Lead Time"];
        let manufacturerArray = ["Manufacturer","MPN","MPN Link","Datasheet","Mfr Description"];

        if(window.__customFields){
            if(window.__customFields.warrantyFieldEnabled)
                manufacturerArray.push("Warranty");
            if(window.__customFields.wasteFieldEnabled )
                primarySourceArray.push("Primary Source Extended Cost");
        }
        let distributorsArray = ["Distributor","DPN","DPN Link","Dist Description","Package","Package Quantity"];
        let quotesArray = ["Quantity Min","Unit Price","Lead Time"];

        return { primarySourceArray, manufacturerArray, distributorsArray, quotesArray };
    }

    toggleSourcingCheckboxes(mappedHeaders, state)
    {
        const newState = { export: { ...this.state.export } };
        let { primarySourceArray, manufacturerArray, distributorsArray, quotesArray } = this.getSourcingArrays();
        let primaryCount = 0;
        let manufacturerCount = 0;
        let distributorsCount = 0;
        let quotesCount = 0;

        Object.keys(mappedHeaders).forEach((header) => {
            if(primarySourceArray.includes(header))
            {
                if(mappedHeaders[header].isChecked)
                {
                    primaryCount++;
                }
                if(primaryCount > 0 && primaryCount !== primarySourceArray.length)
                {
                    newState.primarySourceMiddleClass = "middle";
                    newState.export.includePrimarySource = true;
                }
                else if(primaryCount === primarySourceArray.length)
                {
                    newState.primarySourceMiddleClass = "";
                    newState.export.includePrimarySource = true;
                }
                else
                {
                    newState.primarySourceMiddleClass = "";
                    newState.export.includePrimarySource = false;
                }
            }
            else if(manufacturerArray.includes(header))
            {
                if(mappedHeaders[header].isChecked)
                {
                    manufacturerCount++;
                }
                if(manufacturerCount > 0 && manufacturerCount !== manufacturerArray.length)
                {
                    newState.manufacturersMiddleClass = "middle";
                    newState.export.manufacturers = true;
                }
                else if(manufacturerCount === manufacturerArray.length)
                {
                    newState.manufacturersMiddleClass = "";
                    newState.export.manufacturers = true;
                }
                else
                {
                    newState.manufacturersMiddleClass = "";
                    newState.export.manufacturers = false;
                }
            }
            else if(distributorsArray.includes(header))
            {
                if(mappedHeaders[header].isChecked)
                {
                    distributorsCount++;
                }
                if(distributorsCount > 0 && distributorsCount !== distributorsArray.length)
                {
                    newState.distributorsMiddleClass = "middle";
                    newState.export.distributors = true;
                }
                else if(distributorsCount === distributorsArray.length)
                {
                    newState.distributorsMiddleClass = "";
                    newState.export.distributors = true;
                }
                else
                {
                    newState.distributorsMiddleClass = "";
                    newState.export.distributors = false;
                }
            }
            else if(quotesArray.includes(header))
            {
                if(mappedHeaders[header].isChecked)
                {
                    quotesCount++;
                }
                if(quotesCount > 0 && quotesCount !== quotesArray.length)
                {
                    newState.quoteMiddleClass = "middle";
                    newState.export.quotes = true;
                }
                else if(quotesCount === quotesArray.length)
                {
                    newState.quoteMiddleClass = "";
                    newState.export.quotes = true;
                }
                else
                {
                    newState.quoteMiddleClass = "";
                    newState.export.quotes = false;
                }
            }
        });
        this.setState(newState);
    }

    getTemplatesOptions()
    {
        let templates = this.state.templates;
        let options = [];
        let companyTemplates = [];
        let privateTemplates = [];
        let defaultTemplate  = [];
        let recentTemplates  = [];
        let temp             = Utils.getDefaultExportTemplate();

        this.state.recentTemplates.forEach((template) => {
            recentTemplates.push({value: template._id, label: template.templateName});
        });

        defaultTemplate.push({value: temp._id, label:temp.templateName});

        templates.forEach((item, i) => {
            let value = {value: item._id, label:item.templateName};
            if(!item.isPublic)
            {
                privateTemplates.push(value);
            }
            else
            {
                companyTemplates.push(value);
            }
        });

        options.push({label: "", options: defaultTemplate});
        options.push({label: "RECENT", options: recentTemplates});
        options.push({label: "PRIVATE", options: privateTemplates});
        options.push({label: "COMPANY", options: companyTemplates});

        return options;
    }

    saveTemplateData()
    {
        let data = this.getData();
        let updateTemplate = false;
        let id = "";
        let templates = this.state.templates;
        templates.forEach((item, i) => {
            if(item.templateName === this.state.export.templateName)
            {
                updateTemplate = true;
                id = item._id;
            }
        });
        this.saveTemplate(data, updateTemplate, false, id);
    }

    saveTemplate(data, updateTemplate=false, removeTemplate=false, id="")
    {
        let _this = this;
        let state = this.state;

        let apiRequest = updateTemplate ? API.services.updateTemplate : removeTemplate ? API.services.removeTemplate : API.services.saveTemplate;
        data.id = id;
        apiRequest(data, (err, response) =>
        {
            if(err)
            {
                return _this.props.showAlert("errors", err.errors);
            }
            _this.getTemplates();
        });
    }

    getTemplates()
    {
        let _this     = this;

        let userId    = this.props.user.data._id;
        let templates = [];
        let recentTemplates = [];
        API.services.getUserTemplates(userId, (err, response) =>
        {
            if(err)
            {
                return _this.props.showAlert("errors", err.errors)
            }
            templates       = response.templates || [];
            recentTemplates = response.recentTemplates || [];
            _this.setState({ templates, recentTemplates });
        });
    }

    manageTemplates(templates, removeTemplates)
    {
        let data  = {label: "", value: ""};
        let temp  = {};
        let _this = this;
        removeTemplates.forEach((id) => {
            temp = _this.state.templates.find((element) => element._id === id);
            if(temp.templateName === _this.state.selectedTemplate.label)
            {
                data.label = "Default Settings";
                data.value = id;
                this.onChange(data);
            }
        })
        this.setState({ templates });
    }

    onChange(data)
    {
        const state = { export: { ...this.state.export }, inputs: { ...this.state.inputs } };
        state.selectedTemplate = data;
        let template = {};
        let mappedHeaders = {};
        if(state.selectedTemplate.label !== "Default Settings")
        {
            let templates = this.state.templates;
            templates.forEach((item) => {
                if(item["_id"] === data.value)
                {
                    template = item;
                }
            });

            mappedHeaders = JSON.parse(template.mappedHeaders);
            window.__userRole === 'VENDOR' && Utils.manipulateHeadersForVendor(mappedHeaders, template);
            state.inputs.emails.value = template.emails ? template.emails.join() : "";
        }
        else
        {
            template = Utils.getDefaultExportTemplate();
            mappedHeaders = template.mappedHeaders;
            state.inputs.emails.value = "";
        }
        state.export.depth = template.assembly;
        if(template.includeDocuments)
        {
            state.export.details = "all";
        }
        else
        {
            state.export.details = "specifications";
        }
        state.export.exportFormat = template.assemblyLevel;
        state.export.mappedHeaders = mappedHeaders;
        let documentTypes = state.export.docTypes;
        state.export.docTypes.forEach((type, i) => {
            let docTypes = template._id ? template.docTypes : template.allowedDocTypes;
            if(docTypes.includes(type.name))
            {
                documentTypes[i].isChecked = true;
            }
            else
            {
                documentTypes[i].isChecked = false;
            }
        })
        state.docTypes = documentTypes;
        state.export.allowedDoctypes = template.docTypes;
        state.export.duroLabels = template.duroLabels && template.duroLabels.length > 0 ? template.duroLabels : Object.keys(mappedHeaders);
        state.export.exportType = template.exportType;
        state.export.distributors = template.sourcing.includeDistributors;
        state.export.manufacturers = template.sourcing.includeManufacturers;
        state.export.quotes = template.sourcing.includeQuotes;
        state.export.includePrimarySource = template.sourcing.includePrimarySource;
        state.export.templateName = template.templateName;
        state.export.templateID = template._id;

        this.setState(state);
    }

    handleOutsideClick(e)
    {
        if(this.node && !this.node.contains(e.target) || e.target.classList.contains("background")) this.onEmailModalClose();
    }

    render()
    {
        let inputs = this.state.inputs
        let emails = inputs.emails.value ? this.props.currentUserEmail + "," + inputs.emails.value : this.props.currentUserEmail
        emails = emails.replace(/\s|\n|\r/g, '').split(',')
        let time = Math.ceil(this.state.estimatedTime);
        let showEstimatedTime = (time >= 60);
        let showLoader = (!showEstimatedTime && time === 0);
        let docTypesClassName = `customize-fields ${this.state.export.details === "all" ? "" : "disabled"}`;
        let ccEmailClassName = this.state.export.exportType === "download" ? "disableccEmails" : "";
        const disableExportButton = this.props.collection.length < 1 || !this.state.inputs.emails.valid || this.state.sendingEmail;
        let markup =

                <div className="export-side-area">
                    {this.state.emailModal ?
                    <div className={"email-sent-modal"} ref={node => { this.node = node;}}>
                        <ModalBox onClose={this.onEmailModalClose}>
                            <div className="email-sent-block">
                                <Icon
                                    src={emailSrc}
                                />
                                <h1>Preparing Export</h1>
                                <div className="email-info">
                                    <div className="modal-text">
                                        <div className="content">
                                        We are preparing your export. It will be emailed to <br/>
                                        {this.props.currentUserEmail} <br/>
                                        and any additional listed recipients.<br/><br/>
                                        </div>
                                        {
                                            showEstimatedTime ?
                                            <span>Estimated Time:&nbsp;
                                                <b><i>
                                                {Utils.convertSecToMinAndHours(this.state.estimatedTime)}
                                                </i></b>
                                            </span>
                                            : showLoader ?
                                            <div className="notification-time-section">
                                                <div className="notification-loader">
                                                    <InlineIcon>
                                                        <LoadingIcon />
                                                    </InlineIcon>
                                                </div>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>

                        </ModalBox>
                    </div> : null }

                    {
                        this.state.displaySpecificationsModal ?
                        <div className="customized-specifications">
                            <CustomizedSpecificationsModal
                            onClose={this.onClose}
                            mappedHeaders={this.state.export.mappedHeaders}
                            updateCustomizedSettings={this.updateCustomizedSettings}
                            duroLabels={this.state.export.duroLabels}
                            toggleSourcingCheckboxes={this.toggleSourcingCheckboxes}
                            />
                        </div>
                        : null
                    }

                    {
                        this.state.displayDocumentsModal ?
                        <div className="customized-documents-types">
                            <CustomizedDocumentsModal
                            onClose={this.onClose}
                            docTypes={this.state.export.docTypes}
                            updateCustomizedSettings={this.updateCustomizedSettings}
                            />
                        </div>
                        : null
                    }

                    {
                        this.state.displaySaveTemplateModal ?
                        <div className="save-template-modal">
                            <SaveTemplateModal
                            onClose={this.onClose}
                            saveTemplateData={this.saveTemplateData}
                            onInputChange={this.onInputChange}
                            disableTemplateModalSaveButton={this.state.disableTemplateModalSaveButton}
                            selectedTemplate={this.state.selectedTemplate}
                            templates={this.state.templates}
                            showAlert={this.props.showAlert}
                            user={this.props.user}
                            setCompanyTemplateFlag={this.setCompanyTemplateFlag}
                            recentTemplates={this.state.recentTemplates}
                            />
                        </div>
                        : null
                    }

                    {
                        this.state.displayManageTemplatesModal &&
                        <div className="manage-templates-modal">
                            <ManageTemplatesModal
                            onClose={this.onClose}
                            templates={this.state.templates}
                            privateTemplates={this.state.privateTemplates}
                            companyTemplates={this.state.companyTemplates}
                            saveTemplate={this.saveTemplate}
                            manageTemplates={this.manageTemplates}
                            user={this.props.user}
                            />
                        </div>
                    }

                    <div
                        ref="modalNode"
                        className={"modal open export-scroll-area"}
                        >
                    <PerfectScrollbar className="export-screen-scroll export-screen-content">
                        <div className="header-block">
                            <h2>Export Settings</h2>
                            {this.props.onExportCancel &&
                                <div className="close-icon" onClick={this.props.onExportCancel}>
                                    <Icon
                                        src={closeSrc}
                                    />
                                </div>
                            }
                            <TemplateSelect
                                selectedTemplate={this.state.selectedTemplate}
                                onChange={this.onChange}
                                options={this.getTemplatesOptions()}
                                toggleManageTemplateModal={(e) => { this.toggleModal("displayManageTemplatesModal", true, e) }}
                                toggleSaveModal={(e) => { this.toggleModal("displaySaveTemplateModal", true, e) }}
                                mode='export'
                            />
                            <div className="export-button-wrapper">
                                <Button
                                    color="primary"
                                    disabled={disableExportButton}
                                    onClick={this.export}
                                    variant={ButtonVariants.OUTLINED}
                                >
                                    Export
                                </Button>   
                                    
                            </div>
                        </div>
                        <div className="export-content-holder">
                            {/* this.props.mode !== 'revision' &&
                                <div className="revision-to-include">
                                    <h3>Revisions:</h3>
                                    <div className="custom-radio">
                                       <div className="radio large">
                                           <input
                                               type="radio"
                                               value="releasedOnly"
                                               name="revisionType"
                                               checked={this.state.export.revisionType === "releasedOnly"}
                                               onChange={(e) => this.onInputChange(e)}
                                               />
                                         <label htmlFor="radio8" />
                                         <span htmlFor="radio8">
                                             Released Only
                                         </span>
                                       </div>
                                       <div className="radio large">
                                           <input
                                               type="radio"
                                               value="latestInProgress"
                                               name="revisionType"
                                               checked={this.state.export.revisionType === "latestInProgress"}
                                               onChange={(e) => this.onInputChange(e)}
                                               />
                                         <label htmlFor="radio9" />
                                         <span htmlFor="radio9">
                                             Latest In Progress
                                         </span>
                                       </div>
                                   </div>
                                </div>*/}
                             <div className={`file-to-include ${this.props.mode === 'revision' ? 'is-mode-revision' : ''}`}>
                                <h3>Specifications:</h3>
                                <div className="customize-fields" onClick={(e) => { this.toggleModal("displaySpecificationsModal", true, e) }}>
                                    <InlineIcon>
                                        <SettingsIconSrc />
                                    </InlineIcon>
                                    <a>
                                        Customize Fields
                                    </a>
                                </div>
                            </div>

                            <div className="documents-to-include">
                                <h3>Documents:</h3>
                                <div className="cutom-checkbox">
                                       <input
                                           type="checkbox"
                                           value="all"
                                           name="details"
                                           className="large"
                                           checked={this.state.export.details === "all"}
                                           onChange={(e) => this.onInputChange(e)}
                                           />
                                     <label htmlFor="Include All Documents" />
                                     <span htmlFor="Include All Documents">
                                       Include Documents
                                     </span>
                                   </div>
                                <div className={docTypesClassName} onClick={this.state.export.details === "all" ? (e) => { this.toggleModal("displayDocumentsModal", true, e) } : null}>
                                    <InlineIcon>
                                        <SettingsIconSrc />
                                    </InlineIcon>
                                    {
                                        this.state.export.details === "all" ?
                                        <a>
                                            Select Document Types
                                        </a>
                                        :
                                        <span>
                                            Select Document Types
                                        </span>
                                    }
                                </div>
                            </div>
                            { window.__userRole !== "VENDOR" &&
                                <div className="export-depth sourcing-block">
                                    <h3>Sourcing:</h3>
                                    <div className="cutom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="includePrimarySource"
                                            checked={this.state.export.includePrimarySource}
                                            onChange={(e) => this.updateSourcingOptions(e)}
                                            className="large"
                                        />
                                        <label
                                            htmlFor="includePrimarySource"
                                            className={this.state.primarySourceMiddleClass}
                                          />
                                        <span>Include Primary Sources</span>
                                    </div>
                                    <div className="cutom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="manufacturers"
                                            checked={this.state.export.manufacturers}
                                            onChange={(e) => this.updateSourcingOptions(e)}
                                            className="large"
                                        />
                                        <label
                                            htmlFor="manufacturers"
                                            className={this.state.manufacturersMiddleClass}
                                          />
                                        <span>Include Manufacturers</span>
                                    </div>
                                    <div className="cutom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="distributors"
                                            checked={this.state.export.distributors}
                                            onChange={(e) => this.updateSourcingOptions(e)}
                                            className="large"
                                        />
                                        <label
                                            htmlFor="distributors"
                                            className={this.state.distributorsMiddleClass}
                                          />
                                        <span>Include Distributors</span>
                                    </div>
                                    <div className="cutom-checkbox">
                                        <input
                                            type="checkbox"
                                            name="quotes"
                                            checked={this.state.export.quotes}
                                            className="large"
                                            onChange={(e) => this.updateSourcingOptions(e)}
                                        />
                                        <label
                                            htmlFor="quotes"
                                            className={this.state.quoteMiddleClass}
                                          />
                                        <span>Include Quotes</span>
                                    </div>
                                </div>
                            }
                            {
                                false &&
                            <div className="export-depth sourcing-block">
                                <h3>File and Folder Naming:</h3>
                                <div className= "cutom-checkbox">
                                    <input
                                            type="checkbox"
                                            name="includeCompName"
                                            disabled={false}
                                            className="large"
                                            checked={this.state.includeCompName}
                                            onChange={(e) => this.onInputChange(e)}
                                        />
                                        <label
                                            htmlFor="includeCompName"
                                          />
                                    <span>Include Component Name</span>
                                </div>
                            </div>
                            }

                            <div className="export-depth">
                                <h3>Assembly:</h3>
                                <div className="custom-radio">
                                   <div className="radio large">
                                       <input
                                           type="radio"
                                           value="shallow"
                                           name="depth"
                                           checked={this.state.export.depth === "shallow"}
                                           onChange={(e) => this.onInputChange(e)}
                                           />
                                     <label htmlFor="radio4" />
                                     <span htmlFor="radio4">
                                         First Level Only
                                     </span>
                                   </div>
                                   <div className="radio large">
                                       <input
                                           type="radio"
                                           value="deep"
                                           name="depth"
                                           checked={this.state.export.depth === "deep"}
                                           onChange={(e) => this.onInputChange(e)}
                                           />
                                     <label htmlFor="radio5" />
                                     <span htmlFor="radio5">
                                       All Levels
                                     </span>
                                   </div>
                                   <div className={"radio large pl-22 "}>
                                       <input
                                           type="radio"
                                           value="indented"
                                           name="exportFormat"
                                           checked={this.state.export.depth === "deep" && this.state.export.exportFormat === "indented"}
                                           onChange={(e) => this.onInputChange(e)}
                                           />
                                        <label htmlFor="radio6" />
                                        <span htmlFor="radio6">
                                            Indented
                                        </span>
                                         <input
                                               type="radio"
                                               value="flattened"
                                               name="exportFormat"
                                               checked={this.state.export.depth === "deep" && this.state.export.exportFormat === "flattened"}
                                               onChange={(e) => this.onInputChange(e)}
                                               className="ml-10"
                                               />
                                         <label htmlFor="radio7" />
                                         <span htmlFor="radio7">
                                            Flattened
                                        </span>
                                   </div>
                               </div>
                            </div>

                            <div className="export-block">
                                <h3>Export:</h3>
                                <div className="custom-radio">
                                   <div className="radio large">
                                       <input
                                           type="radio"
                                           value="download"
                                           name="exportType"
                                           checked={this.state.export.exportType === "download"}
                                           onChange={(e) => this.onInputChange(e)}
                                           />
                                     <label htmlFor="radio1" />
                                     <span htmlFor="radio1">
                                         Download
                                     </span>
                                   </div>
                                   <div className="radio large" >
                                       <input
                                           type="radio"
                                           value="email"
                                           name="exportType"
                                           checked={this.state.export.exportType === "email"}
                                           onChange={(e) => this.onInputChange(e)}
                                           />
                                     <label htmlFor="radio2" />
                                     <span htmlFor="radio2">
                                       Email
                                     </span>
                                   </div>
                                </div>
                                <div className={ccEmailClassName}>
                                    <div className="export-block-heading">
                                        <span >Your export will be sent to:</span>
                                        <span> {this.props.currentUserEmail}</span>
                                    </div>
                                    <span className="cc-span">CC to other people:</span>
                                    <div className="mail-block" ref="shareNode">
                                        <div className="arrow" />
                                        <textarea
                                            type="text"
                                            placeholder="Enter email addresses (separate by commas)"
                                            value={inputs.emails.value}
                                            onChange={this.state.export.exportType === "email" ? this.onEmailsChange : function(){}}
                                            data-tip={this.state.inputs.emails.message}
                                            data-place="left"
                                            data-type="error"
                                            className={this.state.inputs.emails.class}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </PerfectScrollbar>
                    </div>
                </div>

        return markup
    }
}
export default ExportMenu
