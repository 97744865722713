import { useMemo } from "react";
import { CellParams } from "../cell.types";
import { colorPalette } from "components/themes";

const LastModifiedRenderCell = ({ row }: CellParams) => {
  const date = new Date(row?.lastModified);
  const optionsDate: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "2-digit",
    year: "numeric",
  };

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);
  const formattedDate = date.toLocaleDateString("en-US", optionsDate);

  return useMemo(() => {
    return (
      <div style={{ display: "flex" }}>
        <span style={{ minWidth: 95, display: "block" }}>{formattedDate}</span>
        <span style={{ color: colorPalette.taupeGray }}> {formattedTime}</span>
      </div>
    );
  }, [row.lastModified]);
};

export const LastModifiedCell = {
  renderCell: LastModifiedRenderCell,
  headerName: "Last Updated",
  field: "lastModified",
  width: 180,
};
