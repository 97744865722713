import { SuperComponent } from "design/models/changeorder";
import { EditorState } from "./state";
import { getPreviousRevisionValue, normalizeRevision } from "./revisions";
import { getWindow } from "utils/window";
import { ChangeOrderREST } from "../rest";

async function getNextRevisionOnStatusChange(
  input: SuperComponent,
  status: string
) {
  const originalStatus = EditorState.getChangeset(input).originalStatus;

  const item = { ...input, status };
  const previousRevision = getPreviousRevisionValue(item);

  const opts = {
    status: item.status,
    revSchemeType: getWindow().__revSchemeType,
    libraryType: getWindow().__libraryType,
    defaultBlacklistedRevisions: getWindow().__defaultBlacklistedRevisions,
    currentRevision: item.revisionValue,
    isClient: true,
    previousRevision,
    revisionBump: false,
  };

  const requiresRevisionBump =
    !item.modified &&
    item.status === originalStatus &&
    item.status === item.previousStatus;

  const requiresRevertedRevision =
    item.modified &&
    item.previousStatus === item.status &&
    item.status !== originalStatus;

  if (requiresRevisionBump) {
    const getBumpedRevision = await ChangeOrderREST.components.getNextRevision(
      item?.cpn?.displayValue,
      item?.status
    );
    const bumpedRevision = await getBumpedRevision();

    if (bumpedRevision) {
      item.legacyNextRevision = bumpedRevision;
      opts.revisionBump = true;
    }
  } else if (requiresRevertedRevision) {
    item.legacyNextRevision = item.previousRevisionValue;
  } else {
    item.legacyNextRevision = item.revisionValue;
  }

  return normalizeRevision(opts, item.legacyNextRevision) as string;
}

export const updateStatus = async (item: SuperComponent, status: string) => {
  const changeset = EditorState.getChangeset(item);
  const isModifiedStatus = changeset.originalStatus !== status;

  if (isModifiedStatus) {
    const revision = await getNextRevisionOnStatusChange(item, status);
    await changeset.legacyNextRevision.setState(revision);
  } else {
    await changeset.legacyNextRevision.setState(changeset.originalRevision);
  }

  await changeset.status.setState(status);
  await changeset.errors.setState([]);
  await changeset.reviewed.setState(false);
  await EditorState.flags.isReviewed.setState(false);
};
