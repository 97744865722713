import { SuperComponent } from "design/models/changeorder";
import { ClientModulesSchemaValidationResult, ValidationErrorType } from "../validation.types";
import { getWindow } from "utils/window";

export function validatePrimarySource(
  item: SuperComponent
): ClientModulesSchemaValidationResult {
  return {
    valid:
      !!item.primarySource?.minQuantity ||
      getWindow().__isPrimarySourceWarningDisabledInCO, // undefined/null/0 is invalid.
    message: "Primary Source is required",
    value: item.id,
    children: [],
    type: ValidationErrorType.NoPrimarySource,
  };
}
