import { CellParams } from "../cell.types";
import { changeOrderComponentStatuses } from "features/changeorders/constants";
import { sdk } from "features/changeorders/sdk";
import { sdk as sdk2 } from "features/changeorders/sdk/editor";
import { StatusField } from "@duro/base/status-field";
import { StatusContainer } from "common/components/fields";
import { ChangeOrderType, SuperComponent } from "design/models/changeorder";
import { ArrowForward } from "@mui/icons-material";
import { DuroErrorTooltip } from "common/components/tooltip";
import { MenuItem, Select } from "@mui/material";
import { useDebounce } from "use-debounce";
import { useEffect, useState } from "react";
import { createStore } from "common/context/store";

export type UpdateStatusFieldProps = {
  fields: string[];
  row: SuperComponent;
  onInputChange: (value: string) => void;
  forcedUpdate?: string;
};

const isRevisionManaged = (item: SuperComponent) => {
  if (item.alias === "CMP" && window.__isNotRevisionManagedEnabled) {
    return item.revisionManaged;
  }
  return true;
};

const isOnshape = (item: SuperComponent) => {
  const vendor = item?.vendorInfo?.currentVendors?.at(-1);
  return vendor === "ONSHAPE";
};

export const statuses = changeOrderComponentStatuses.map((e) => e.toString());

export function hasPromotionError({
  previousStatus,
  status,
  statuses,
}: {
  previousStatus: string;
  status: string;
  statuses: string[];
}) {
  const lastStatusIndex = statuses.findIndex(
    (field) => field === previousStatus
  );

  // Collect statuses that come before the last status
  const disabledStatuses = statuses.filter(
    (_, index) => index < lastStatusIndex
  );

  // Check if the current status is in the list of disabled statuses
  return {
    lastStatusIndex,
    disabledStatuses,
    error: disabledStatuses.includes(status),
  };
}

export const promotionErrorItems = createStore<string[]>([]);

const StatusRenderCell = ({ row }: CellParams) => {
  const state = sdk2.state.getChangeset(row);
  const [status] = state.status.useStore();
  const [coType] = sdk.storage.form.type.useStore();
  const [statusState, setStatusState] = useState<string>(row.status);
  const [statusDebounced] = useDebounce(statusState, 1000);

  useEffect(() => {
    setStatusState(status);
  }, [status]);

  const { disabledStatuses, error: promotionError } = hasPromotionError({
    previousStatus: row.previousStatus ?? "",
    status,
    statuses,
  });

  useEffect(() => {
    if (statusDebounced !== status) {
      sdk2.status.updateStatus(row, statusDebounced);
    }
  }, [statusDebounced]);

  useEffect(() => {
    const { error } = hasPromotionError({
      previousStatus: row.previousStatus ?? "",
      status,
      statuses,
    });

    const promotionErrors = promotionErrorItems.getState();

    if (!error && promotionErrors.includes(row.treeId)) {
      promotionErrorItems.setState((prev) =>
        prev.filter((i) => i !== row.treeId)
      );
    }

    if (error && !promotionErrors.includes(row.treeId)) {
      promotionErrorItems.setState((prev) => [...prev, row.treeId]);
    }
  }, [status]);

  const isDCO = coType === ChangeOrderType.DCO;
  const isDisabledRevManagement = !isRevisionManaged(row);

  const label = row.previousStatus ?? row.status;

  const isDisabled = isDCO || isDisabledRevManagement;

  return (
    <StatusContainer>
      <StatusField label={label} sx={{ minWidth: 110 }} />
      <ArrowForward sx={{ height: 12 }} />
      <DuroErrorTooltip
        error={{
          message: `Must be ${state.originalStatus} or higher`,
          valid: !promotionError,
        }}
        tooltipProps={{ placement: "right" }}
      >
        <Select
          disabled={isDisabled}
          sx={{
            height: 20,
            minWidth: 140,
            ...(isDisabled && {
              opacity: 0.6,
            }),
            ...(promotionError && {
              backgroundColor: "red",
              color: "black",
              border: "none", // Remove the border
              "& .MuiSelect-select": {
                backgroundColor: "red",
                color: "black",
                border: "none",
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: 0,
              },
              "& fieldset": {
                border: "none", // Remove the border for the outlined variant
              },
              "&:hover .MuiSelect-select": {
                backgroundColor: "darkred", // Darker red on hover for effect
              },
              "& .MuiSvgIcon-root": {
                color: "black", // Dropdown icon color
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent", // Remove outline when focused
              },
            }),
          }}
          value={statusState}
          onChange={(e) => setStatusState(e.target.value)}
        >
          {statuses.map((field) => (
            <MenuItem
              value={field}
              disabled={disabledStatuses.includes(field)}
              key={field}
            >
              {field}
            </MenuItem>
          ))}
        </Select>
      </DuroErrorTooltip>
    </StatusContainer>
  );
};

export const StatusCell = {
  renderCell: StatusRenderCell,
  headerName: "Status",
  field: "status",
  width: 300,
};
