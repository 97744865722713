import "./index.scss";
import React, { Component } from 'react';
import InlineIcon   from "../../../../ui/icon/inline-icon.js";
import ImageEnlargeIcon  from "../../../../../assets/icons/image-enlarge-icon.js";
import Tooltip  from 'rc-tooltip';
import ImageViewer   from "../../../../ui/image-viewer";
import Config  from "../../../../../modules/config";
import API from "../../../../../modules/api";
import Utils from "../../../../../modules/utils";
import GearIcon  from "../../../../../assets/icons/gear";
import Image     from "../../../../ui/image";

class InlineImageViewer extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
                        images: this.props.images,
                        hasLoadedAll: false,
                        hasDefaultImage: false
                     };
        this.onMouseEnter = this.onMouseEnter.bind(this);
    }

    hasLoadedImage(id)
    {
        let hasLoaded = null;
        if(this.props.imagesWithSrc && this.props.imagesWithSrc.length > 0)
        {
            for(let image of this.props.imagesWithSrc)
            {
                if(image._id && image._id === id)
                {
                    hasLoaded = image;
                }
            }
        }
        return hasLoaded;
    }

    componentDidMount()
    {
        let { isValispaceComponent } = this.props;
        let {images, hasDefaultImage} = this.state;
        if(images && images.length && !isValispaceComponent)
        {
            let image = images[0];
            let loadedImage = this.hasLoadedImage(image);
            if(image && !image._id && !loadedImage)
            {
                let _this = this;
                API.images.getImagesBulk([images[0]], (err, data) =>
                {
                    if(data && data[0])
                    {
                        images[0] = data[0];
                        _this.setState({images});
                        this.props.imagesWithSrc.push(data);
                    }
                });
            }
            else if(loadedImage)
            {
                images[0] = loadedImage;
                this.setState({images});
            }
        }
        else if(images && images.length === 0)
        {
            this.setState({hasDefaultImage: true});
        }
    }

    onMouseEnter()
    {
        let {images, hasLoadedAll} = this.state;
        if(hasLoadedAll || images.length === 1)
        {
            return;
        }
        let _this = this;
        let imagesToBeSent = images.filter((image) =>
                            {
                                let loadedImage = !image._id ? _this.hasLoadedImage(image) : true;
                                if (!loadedImage)
                                {
                                    return image;
                                }
                            });
        if(imagesToBeSent.length)
        {
            API.images.getImagesBulk(imagesToBeSent, (err, data) =>
            {
                if(err)
                {

                }
                else
                {
                    for(let img of data)
                    {
                        let index = images.findIndex(image => !image._id && image === img._id);
                        if(index > 0)
                        {
                            images[index] = img;
                        }
                    }
                    _this.setState({images});
                }
            });
        }
        this.setState({hasLoadedAll: true});
    }

    render()
    {
        let {images, hasDefaultImage} = this.state;
        let src = images && images[0] ? images[0].src : '#';
        let imgIdentifier = this.props.imgIdentifier || '';

        if(src !== '#' && this.props.defaultResolution && images[0].variants)
        {
            src           = images[0].variants[this.props.defaultResolution];
            imgIdentifier = `img-${images[0]._id}-${Utils.generateUniqueId()}`;
        }

        let imageViewer =
                        <React.Fragment>
                            <div className="inline-image-viewer">
                                <div className="text-block">
                                    <div className="table-image-viewer-wrapper">
                                        <ImageViewer defaultResolution={Config.defaultResolutions.enlargedView} images={images} inlineImageViewer={true}/>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>;
        let markup = null;

        markup =
                <div className="table-image-viewer-wrapper">
                        <div className={` ${hasDefaultImage ? ' default-img' : ''}`}>
                            {
                                !hasDefaultImage ?
                                <React.Fragment>

                                    <Image
                                      className="table-image-viewer"
                                      src={src}
                                      isRenderInTable={true}
                                      imgIdentifier={imgIdentifier}
                                      defaultImageClass={this.props.defaultImageClass}
                                      >

                                    </Image>

                                    <div id={imgIdentifier} className="hidden">
                                      <Tooltip
                                          overlayClassName={`simple-rc-tip default-styles inline-image-viewer-tooltip`}
                                          overlay={imageViewer}
                                      >
                                          <div className={`enlarge-icon-holder ${this.props.enlargeIconClass}`} onMouseEnter={this.onMouseEnter}>
                                              <InlineIcon>
                                                  <ImageEnlargeIcon />
                                              </InlineIcon>
                                          </div>
                                      </Tooltip>
                                    </div>
                                </React.Fragment>
                                :
                                <div className={`table-image-viewer-holder default-img ${this.props.defaultImageClass}`}>
                                  <InlineIcon className='default-img-icon'>
                                      <GearIcon />
                                  </InlineIcon>
                                </div>
                            }
                        </div>
                </div>
        return markup
    }
}

export default InlineImageViewer;
