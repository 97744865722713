import { SuperComponent } from "design/models/changeorder";
import { useVirtualList, VirtualListArgs } from "./hooks/useVirtualComponents";
import { gql } from "@apollo/client";
import {
  CoBaseRowFragment,
  CoComponentRowFragment,
  CoProductRowFragment,
} from "./fragment/changeOrderFragment";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import { useUser } from "graphql/query/userQueries";
import { OrderByType } from "graphql/query/componentsQueries";
import { ApolloError } from "@apollo/client";

export const ComponentsQueryFragment = gql`
  fragment componentsQueryFragment on Components {
    connection(after: $endCursor, first: $pageSize) {
      edges {
        node {
          ${CoComponentRowFragment.spread}
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${CoComponentRowFragment.fragment}
`;

export const ProductsQueryFragment = gql`
  fragment productsQueryFragment on Products {
    connection(after: $endCursor, first: $pageSize) {
      edges {
        node {
          ${CoProductRowFragment.spread}
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${CoProductRowFragment.fragment}
`;

export const GET_COMPONENTS_WITH_CHILDREN = gql`
  query (
    $libraryType: LibraryType
    $orderBy: [ComponentsOrderByInput]
    $pageSize: Int
    $endCursor: String
    $search: SearchFields
  ) {
    components(libraryType: $libraryType, orderBy: $orderBy, search: $search) {
      ...componentsQueryFragment
    }
  }
  ${ComponentsQueryFragment}
`;

export const GET_PRODUCTS_WITH_CHILDREN = gql`
  query (
    $libraryType: LibraryType
    $orderBy: [ProductsOrderByInput]
    $pageSize: Int
    $endCursor: String
    $search: SearchFields
  ) {
    products(libraryType: $libraryType, orderBy: $orderBy, search: $search) {
     connection(after: $endCursor, first: $pageSize) {
        edges {
          node {
            ${CoBaseRowFragment}
            children {
              component {
                ${CoBaseRowFragment}
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
`;

export type VListGenericGenerator<T> = (
  args: VirtualListArgs<T>
) => VirtualListGeneric<T>;

export type VirtualListGeneric<T> = {
  data: T[];
  error: ApolloError | undefined;
  totalCount: number;
  hasNextPage: boolean;
  loading: boolean;
  loadMoreData: () => Promise<void>;
  refetch: () => void;
};

export const useProductVirtualList: VListGenericGenerator<SuperComponent> = (
  args
) => {
  return useVirtualList<SuperComponent>(
    {
      ...args,
      connectionKey: "products",
    },
    GET_PRODUCTS_WITH_CHILDREN
  );
};

/**
 * Fetches a list of components and provides virtualization capabilities.
 */
export const useComponentVirtualList: VListGenericGenerator<SuperComponent> = (
  args
) => {
  return useVirtualList<SuperComponent>(
    {
      ...args,
      connectionKey: "components",
    },
    GET_COMPONENTS_WITH_CHILDREN
  );
};

export type VListOpts = {
  libraryType: string | undefined;
  orderBy: {
    [field: string]: GridSortDirection;
  }[];
  pageSize: number;
  queryKey: string;
  searchString: string;
};

export function useVirtualListQueryOptions(
  queryKey: string,
  searchString = "",
  pageSize = 25
): VListOpts {
  const user = useUser();
  const libraryType = user?.data?.activeLibrary?.type;
  const orderBy: OrderByType = [{ legacyCpn: "desc" }];
  return {
    libraryType,
    orderBy,
    pageSize,
    queryKey,
    searchString,
  };
}
