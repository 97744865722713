import {
  VirtualListGeneric
} from "graphql/virtual-list";
import { SuperComponent } from "design/models/changeorder";
import { useCallback, useRef, useState } from "react";
import {
  DuroVirtualColumn,
  DuroVirtualList,
} from "common/components/duro-vlist";
import { ChangeOrderLayoutConfig } from "features/changeorders/constants";
import { sdk } from "features/changeorders/sdk/editor";
import { Store } from "common/context/store";
import { DuroButton } from "common/components";
import { Box } from "@mui/material";
import { ButtonVariants } from "common/constants";

interface TableProps {
  name: string;
  virtualListData: VirtualListGeneric<SuperComponent>;
  pageSize: number;
  queryKey?: string;
  columns: DuroVirtualColumn<SuperComponent>[];
  pending: Store<SuperComponent[]>;
  handleAddButtonClick: (pendingRows: SuperComponent[]) => Promise<void>;
}

const ROW_HEIGHT = 38;

export const ChangeOrderSimpleTable: React.FC<TableProps> = ({
  virtualListData,
  columns,
  name,
  handleAddButtonClick,
}) => {
  const [pendingRows, setPendingRows] = useState<SuperComponent[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const { totalCount, data, loadMoreData, loading, error, hasNextPage } = virtualListData
  const [excludedRows] = sdk.state.rows.useStore();

  const onSelectionChange = useCallback((_, selectedData) => {
    setPendingRows(selectedData);
  }, []);

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <DuroVirtualList
        getRowId={(row) => row.id}
        onSelectionChange={onSelectionChange}
        columns={columns}
        rowHeight={ROW_HEIGHT}
        excluded={excludedRows}
        data={data}
        totalWidth={ChangeOrderLayoutConfig.sidebarWidth}
        totalHeight={ref.current?.parentElement?.clientHeight}
        hasNextPage={hasNextPage}
        loadMoreData={loadMoreData}
        loading={loading}
        totalCount={totalCount}
        error={error?.message}
        name={name}
        headerSlot={
          <Box
            sx={{
              flex: "1",
              paddingRight: "1rem",
              position: "absolute",
              right: "0",
            }}
          >
            <DuroButton
              variant={ButtonVariants.OUTLINED}
              disabled={pendingRows.length == 0}
              onClick={() => {
                handleAddButtonClick(pendingRows).then(() => {
                  setPendingRows([]);
                });
              }}
            >
              Add
            </DuroButton>
          </Box>
        }
      />
    </div>
  );
};
