import React from 'react';
import { SvgIcon, SvgIconProps } from "@mui/material";

interface ProductIconFilledProps extends SvgIconProps {
  htmlColor?: string;
}

export const ProductIconFilled: React.FC<ProductIconFilledProps> = ({ htmlColor, ...props }) => (
    <SvgIcon {...props} htmlColor={htmlColor} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="evenodd" fill={htmlColor || 'currentColor'}>
            <path d="M19.778 2H4.222C3 2 2 3 2 4.222v15.556C2 21 3 22 4.222 22h15.556C21 22 22 21 22 19.778V4.222C22 3 21 2 19.778 2zm-5 10a2.774 2.774 0 0 1 2.778 2.778 2.774 2.774 0 0 1-2.778 2.778A2.774 2.774 0 0 1 12 14.778 2.774 2.774 0 0 1 14.778 12zm-3.89 1.111v4.445H6.445V13.11h4.445zm.99-7.661 3.142 3.143-3.143 3.142-3.143-3.142 3.143-3.143z" />
        </g>
    </SvgIcon>
);
