import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { useToasts } from "common/components/toasts";
import { client } from "graphql/apolloClient";
import { useUser } from "graphql/query/userQueries";

export const UPDATE_CAD_SETTINGS_MUTATION = gql`
mutation updateSettings($input: CadSettingsInputRef! ) {
  cad {
    updateSettings(input: $input) {
      activeReleaseNamingScheme
      allowedDesignRevisionTypes
      customReleaseNamingScheme
      findAndReplaceReleaseNamingScheme {
        find
        matchCase
        replace
        useRegex
      }
      overwriteFilenameWithCpn
      procurementDefault
      setEidToFilename
    }
  }
}
`;

export const useOnSubmit = (onClose : () => void) => {
  const { closeToast, enqueueToast } = useToasts();
  const { updateCadSettingsCache } = useUser();
  const [updateCadSettings] = useMutation(UPDATE_CAD_SETTINGS_MUTATION);

  return useCallback((
    input: { library: any }, // need to update this type after CAD-API updates
  ) => {
    onClose();
    const toastId = enqueueToast({ message: "Updating CAD settings", showSpinner: true, persist: true });
    updateCadSettings({
      variables: {
        input,
      },
    }).then((res: any) => {
      const settings = res.data?.cad?.updateSettings ?? {};
      updateCadSettingsCache(client, settings);
      closeToast(toastId);
      enqueueToast({
        autoHideDuration: 3000,
        message: "CAD settings updated successfully",
        variant: "success",
      });
    }).catch((error: any) => {
      closeToast(toastId);
      enqueueToast(
        {
          body: error.message,
          message: "CAD settings update failed",
          persist: true,
          variant: "error",
        },
      );
    });
  }, [closeToast, enqueueToast, onClose, updateCadSettings, updateCadSettingsCache]);
};
