import { useCallback, useMemo } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select as SelectItem,
  Typography,
  styled,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";

export interface SelectStyleProps {
  borderRadius?: string;
  marginBottom?: string;
  maxWidth?: string;
  width?: string;
}

export interface SectionOptionType<T> {
  value: T;
  label: string;
}

export interface DropdownProps<T extends string> {
  displayEmpty?: boolean;
  name?: string;
  isRequired: boolean;
  onChange: (e: SelectChangeEvent<T>) => void;
  options: Record<string, SectionOptionType<T>[]>;
  placeholder?: string;
  value?: T;
  idToLabelMap: Record<string, string>;
  disabled?: boolean;
}

export interface SelectProps {
  sx?: {
    backgroundColor?: string;
    color?: string;
    fontSize?: string;
    padding?: string;
    paddingTop?: string;
  };
}

export function SectionSelect<T extends string>(props: DropdownProps<T>) {
  const {
    displayEmpty,
    isRequired,
    name,
    onChange,
    options,
    placeholder,
    value,
    idToLabelMap,
    disabled = false,
  } = props;

  const Options = useMemo(() => {
    const optionElements: React.ReactNode[] = [];

    Object.entries(options).map(([section, items], sectionindex) => {
      if (section !== "Default Template") {
        optionElements.push(
          <SelectOptions
            key={`${section}-${sectionindex}`}
            disabled
            style={{ pointerEvents: "none" }}
          >
            {section}
          </SelectOptions>
        );
      }

      items.forEach((item) => {
        optionElements.push(
          <SelectOptions
            key={`${section}-${item.value}`}
            value={item.value}
            style={{
              backgroundColor: section === "Recent" ? "black" : undefined,
            }}
          >
            <Typography
              noWrap
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
            >
              {item.label}
            </Typography>
          </SelectOptions>
        );
      });

      optionElements.push(
        <MenuItem
          key={`divider-${sectionindex}`}
          divider
          style={{
            pointerEvents: "none",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        />
      );
    });

    return optionElements;
  }, [options]);

  const renderValue = useCallback(
    (selected: string) => {
      if (selected && idToLabelMap[selected]) return idToLabelMap[selected];
      return <Typography>{placeholder}</Typography>;
    },
    [placeholder]
  );

  return (
    <SelectWrapper>
      <FormControl fullWidth size="small">
        <SelectItem
          displayEmpty={displayEmpty}
          disabled={disabled}
          name={name}
          onChange={onChange}
          renderValue={placeholder !== undefined ? renderValue : undefined}
          required={isRequired}
          value={value}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                width: "100px",
                maxHeight: 300,
                overflowY: "auto",
              },
            },
          }}
        >
          {Options}
        </SelectItem>
      </FormControl>
    </SelectWrapper>
  );
}

const SelectOptions = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.duro.eventModal.selectOptions.backgroundColor,
}));

const SelectWrapper = styled(Box)(() => ({
  width: "100%",
  maxWidth: "100%",
}));
