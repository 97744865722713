import "./index.scss";
import {Component} from "react";
import DeleteWarningModal from "./delete-warning-modal";
import Utils              from "../../../../modules/utils";
import InlineIcon         from "../../../ui/icon/inline-icon.js";
import RemoveIcon         from "../../../../assets/icons/cross-icon.js";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import { FormModal } from "common/components/modals";
import { Box, Typography } from "@mui/material";

class ManageTemplatesModal extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            templates: Utils.clone(this.props.templates),
            removeTemplates : [],
            showDeleteWarningModal: false,
            template : {},
            toggleManageTemplateModalSaveButton: false
        }
        this.onClose                 = this.onClose.bind(this);
        this.getPrivateTemplates     = this.getPrivateTemplates.bind(this);
        this.getCompanyTemplates     = this.getCompanyTemplates.bind(this);
        this.remove                  = this.remove.bind(this);
        this.saveTemplate            = this.saveTemplate.bind(this);
        this.closeDeleteWarningModal = this.closeDeleteWarningModal.bind(this);
        this.showDeleteWarningModal  = this.showDeleteWarningModal.bind(this);
    }

    closeDeleteWarningModal(modalName, modalValue)
    {
        this.setState({[modalName]: modalValue});
    }

    onClose()
    {
        this.props.onClose("displayManageTemplatesModal", false);
    }

    showDeleteWarningModal(item)
    {
        this.setState({template: item , showDeleteWarningModal: true});
    }

    remove()
    {
        let state = this.state;
        let item = state.template;
        let templates = state.templates;
        let removeTemplates = [];
        state.removeTemplates.push(item._id);
        templates.forEach((template, i) => {
            if(template._id === item._id)
            {
                templates.splice(i, 1);
            }
        });
        this.setState({templates: templates, showDeleteWarningModal: false, toggleManageTemplateModalSaveButton: true});
    }

    getPrivateTemplates()
    {
        let templates = [];
        this.state.templates.forEach((item, i) => {
            if(!item.isPublic && this.props.user.data._id === item.creator)
            {
                templates[i] =  <div key={"item-"+i} className="row-item" style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0',
                borderBottom: '1px solid hsla(0,0%,84.7%,.25098039215686274)'}}>
                                <div>
                                    {item.templateName}
                                </div>
                                <InlineIcon className="remove-template"
                                onClick={(e) => {this.showDeleteWarningModal(item)}} customStyles={{ width: '16px', height: '15px'}}>
                                    <RemoveIcon />
                                </InlineIcon>
                            </div>
            }
        })
        return templates;
    }

    getCompanyTemplates()
    {
        const { coTemplatesMappings } = this.props;
        let templates = [];
        let isAdmin   = this.props.user.data.role === "ADMINISTRATOR" ? true : false;
        this.state.templates.forEach((item, i) => {
            if(item.isPublic)
            {
                const mappingExists = Utils.coMappingExists(item._id,coTemplatesMappings);
                templates[i] =  <div key={"item-"+i} className="row-item" style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0',
                borderBottom: '1px solid hsla(0,0%,84.7%,.25098039215686274)'}}>
                                <div>
                                    {item.templateName}
                                </div>
                                <InlineIcon className={`remove-template ${isAdmin && !mappingExists ? "" : "disabled"}`}
                                tooltip={ isAdmin && mappingExists ? "This template cannot be deleted. It is being used as a Mandatory Approver Template." : ""}
                                onClick={isAdmin && !mappingExists ? (e) => {this.showDeleteWarningModal(item)} : function(){}} customStyles={{ width: '16px', height: '15px'}}>
                                    <RemoveIcon />
                                </InlineIcon>
                            </div>
            }
        })
        return templates;
    }

    saveTemplate()
    {
        let data = this.state.removeTemplates;
        if(data.length > 0)
        {
            this.props.manageTemplates(this.state.templates, data);
            this.props.saveTemplate(data, false, true);
        }
        this.onClose();
    }

    handleOutsideClick(e)
    {
        let isDeleteWarningModalEvent = (this.state.showDeleteWarningModal || (e.target.closest('.ui-modal-box')));
        if(!isDeleteWarningModalEvent && (!this.node.contains(e.target)) || e.target.classList.contains("background")) this.onClose();
    }

    render()
    {
        let saveButtonClass = this.state.toggleManageTemplateModalSaveButton ? "" : "disabled";
        let markup = <div >
                        {
                            this.state.showDeleteWarningModal ?
                            <div className="delete-warning-modal">
                                <DeleteWarningModal
                                    closeDeleteWarningModal={this.closeDeleteWarningModal}
                                    remove={this.remove}
                                    templateName={this.state.template.templateName}
                                />
                            </div>
                            : null
                        }
                        <FormModal
                          onCancel={this.onClose}
                          onClose={this.onClose}
                          onSubmit={this.saveTemplate}
                          open={true}
                          submitLabel="Save"
                          title={"Manage Templates"}
                        >
                            <Box className="modal-body">
                                <Box sx={{ marginBottom: '1.5rem'}}>
                                    <Typography variant="h3" style={{borderWidth: '1px 0', borderStyle: 'solid', borderColor: 'hsla(0,0%,84.7%,.25098039215686274)', padding: '6px 0', color: '#888', fontWeight:'700'}}>PRIVATE TEMPLATES</Typography>
                                    <PerfectScrollbar>
                                        <Box className="modal-body-content templates-screen-scroll" style={ {width: '100%'}}>
                                            {this.getPrivateTemplates()}
                                        </Box>
                                    </PerfectScrollbar>
                                </Box>
                                <Box sx={{ marginBottom: '1.5rem'}}>
                                    <Typography variant="h3" style={{borderWidth: '0 0 1px', borderStyle: 'solid', borderColor: 'hsla(0,0%,84.7%,.25098039215686274)', padding: '6px 0', color: '#888', fontWeight:'700'}}>COMPANY TEMPLATES</Typography>
                                    <PerfectScrollbar>
                                        <Box className="modal-company-templates templates-screen-scroll">
                                            {this.getCompanyTemplates()}
                                        </Box>
                                    </PerfectScrollbar>
                                </Box>
                            </Box>
                        </FormModal>
                     </div>
        return markup;
    }
}

export default ManageTemplatesModal;
