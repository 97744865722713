import { ButtonVariants } from "common/constants";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import {
  changeOrderComponentStatuses,
  ClientModulesSchemaValidationResult,
  defaultClientModulesSchemaValidationResult,
} from "../../..";
import { sdk, Modifications } from "features/changeorders/sdk/editor";
import { useRef, useState, useEffect } from "react";
import { DuroButton } from "common/components";
import { colorPalette } from "components/themes";
import { BulkColumnDef } from "./bulk.actions";
import { StyledRevisionInput } from "./fields";
import { validateRevisionAgainstStatus } from "features/changeorders/sdk/utils/validators/revision.validator";
import { DuroErrorTooltip } from "common/components/tooltip";
import { ChangeOrderType, SuperComponent } from "design/models/changeorder";
import { DuroGridBasic } from "common/components/grid/grid-basic";
import {
  cloneChangeset,
  setClonedChangeset,
} from "features/changeorders/sdk/editor/state";
import { hasPromotionError, statuses, promotionErrorItems } from "./cells";
import { getPreviousRevisionValue } from "features/changeorders/sdk/editor/revisions";

type BulkStatusUpdateModalProps = {
  open: boolean;
  setClose: () => void;
  selection: GridSelectionModel;
  selectedRows: SuperComponent[];
  type: ChangeOrderType;
};

const defaultBulkStatus = "Choose Status";

export const BulkStatusUpdateModal: React.FC<BulkStatusUpdateModalProps> = ({
  open,
  setClose,
  selection,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const [bulkSelection, setBulkSelection] = useState<GridSelectionModel>([]);
  const [isDisabledContinue, setIsDisabledContinue] = useState(true);
  const originalChanges = useRef<Map<string, Modifications>>(cloneChangeset());
  const [stateRows] = sdk.state.rowsWithTree.useStore();
  const rows = stateRows.filter((r) => selection.includes(r.treeId));

  const [input, setInput] = useState<ClientModulesSchemaValidationResult>(
    defaultClientModulesSchemaValidationResult
  );

  useEffect(() => {
    setBulkSelection(selection);
  }, [selection]);

  const [bulkRevision, setBulkRevision] = useState(input.value);
  const [bulkStatus, setBulkStatus] = useState(defaultBulkStatus);
  const [promotionErrors] = promotionErrorItems.useStore();

  useEffect(() => {
    const errorItems = promotionErrors.filter((id) => selection.includes(id));
    setIsDisabledContinue(() => errorItems.length > 0);
  }, [promotionErrors]);

  useEffect(() => {
    if (bulkRevision !== "") {
      const results = bulkValidator(bulkRevision);
      setInput(() => results);
    } else {
      setInput(() => defaultClientModulesSchemaValidationResult);
    }
  }, [bulkRevision]);

  const handleSaveWithClose = () => {
    // close
    setClose();
  };

  const handleReset = () => {
    setBulkStatus(() => defaultBulkStatus);
    setBulkRevision("");
    setClonedChangeset(originalChanges.current);
  };

  const handleCloseAndReset = () => {
    handleReset();
    setClose();
  };

  function bulkValidator(revision: string, status?: string) {
    return validateRevisionAgainstStatus({
      currentRevision: { ...input },
      currentStatus: status ?? bulkStatus,
      nextRevision: revision.toUpperCase(),
    });
  }

  const handleBulkStatusUpdate = (status: string) => {
    if (bulkRevision !== "") {
      const results = bulkValidator(bulkRevision, status);
      setInput(results);
    }

    setBulkStatus(() => status);
  };

  const handleBulkRevisionUpdate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.toUpperCase();
    setBulkRevision(() => value);
  };

  const handleApplyBulkUpdates = async () => {
    setLoading(() => true);
    const selectedRows = rows.filter((r) => {
      return bulkSelection.includes(r.treeId);
    });

    const errors = selectedRows.map(
      (row) =>
        hasPromotionError({
          previousStatus: row.previousStatus ?? "",
          status: bulkStatus,
          statuses,
        }).error
    );

    setTimeout(async () => {
      await Promise.all(
        selectedRows.map(async (row) => {
          const { status, originalRevision, legacyNextRevision, reviewed } =
            sdk.state.getChangeset(row);

          let rev =
            bulkRevision === "" ? getPreviousRevisionValue(row) : bulkRevision;

          // No revision set, bump status with default
          if (
            (bulkRevision === "" && bulkStatus !== row.previousStatus) ||
            bulkStatus === "OBSOLETE"
          ) {
            await sdk.status.updateStatus(row, bulkStatus);
          } else if (bulkStatus !== row.previousStatus) {
            await legacyNextRevision.setState(() => rev);
            await status.setState(() => bulkStatus);
          } else if (bulkStatus === row.previousStatus && row.modified) {
            await status.setState(() => bulkStatus);
            await legacyNextRevision.setState(row.previousRevisionValue);
          } else if (bulkStatus === row.previousStatus) {
            await status.setState(() => bulkStatus);
            await legacyNextRevision.setState(() => originalRevision);
          }

          reviewed.setState(() => false);
        })
      );

      setIsDisabledContinue(errors.some((e) => e));
      setLoading(() => false);
    }, 500);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseAndReset}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <StyledDialog>
          <DialogTitle id="alert-dialog-title">Update Status</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                Status changes cannot be undone. Please review the updates below
                before continuing.
              </Typography>
              <StyledBulkActions>
                <StyledBulkField>
                  <Typography>Bulks Actions</Typography>

                  <Select
                    value={bulkStatus}
                    onChange={(e) => handleBulkStatusUpdate(e.target.value)}
                    sx={{ minWidth: 150 }}
                  >
                    {[defaultBulkStatus, ...changeOrderComponentStatuses].map(
                      (status) => (
                        <MenuItem value={status} key={status}>
                          {status}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </StyledBulkField>
                <StyledBulkField>
                  <Typography>Set Revision</Typography>
                  <DuroErrorTooltip
                    error={{
                      message: input?.message ?? "Revision error found.",
                      valid: input.valid,
                    }}
                  >
                    <StyledRevisionInput
                      value={bulkRevision}
                      onChange={handleBulkRevisionUpdate}
                      isValid={input.valid}
                      type="text"
                      onClick={() => {}}
                      sx={{
                        ".MuiInputBase-input": {
                          padding: 0,
                        },
                      }}
                    />
                  </DuroErrorTooltip>
                </StyledBulkField>
                <DuroButton
                  variant={ButtonVariants.OUTLINED}
                  onClick={() => handleApplyBulkUpdates()}
                  disabled={!input.valid || bulkStatus === defaultBulkStatus}
                >
                  Apply
                </DuroButton>
              </StyledBulkActions>
            </DialogContentText>
            <DialogContent>
              <Box sx={{ height: "50vh", mt: 2, overflowY: "scroll" }}>
                <DuroGridBasic
                  selectionModel={bulkSelection}
                  setSelectedRows={(selection) => setBulkSelection(selection)}
                  rows={rows}
                  columns={BulkColumnDef}
                  showToolbar={false}
                  autoHeight
                  getRowId={(r) => r.treeId}
                  loading={loading}
                />
              </Box>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <DuroButton
              variant={ButtonVariants.OUTLINED}
              onClick={handleCloseAndReset}
            >
              Cancel
            </DuroButton>
            <Button variant={ButtonVariants.OUTLINED} onClick={handleReset}>
              Reset
            </Button>
            <DuroButton
              onClick={handleSaveWithClose}
              autoFocus
              disabled={isDisabledContinue}
            >
              Continue
            </DuroButton>
          </DialogActions>
        </StyledDialog>
      </Dialog>
    </>
  );
};

const StyledBulkActions = styled("div")`
  padding: 1rem 0rem 0rem 0rem;
  gap: 20px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid ${colorPalette.taupeGray};
  button {
    max-height: 16px;
  }
`;

const StyledBulkField = styled("div")`
  display: flex;
  align-items: center;
  max-height: 20px;
  p {
    margin-right: 20px;
  }
  > div {
    max-height: 20px;
  }
`;

const StyledDialog = styled("div")({
  background: colorPalette.dark,
  padding: "1.5rem",
});

export {};
