import React, { useCallback, useState, useEffect, useRef } from 'react';
import ModalBox from "../../../../ui/modal-box";
import MandatoryIcon from "../../../../../assets/icons/mandatory-approver-icon";
import InlineIcon from "../../../../ui/icon/inline-icon.js";
import Utils   from "../../../../../modules/utils";

import "./index.scss";
import { TransactionalModal } from 'common/components/modals';

const MandatoryApproverNotifier = (props) => {

    const {template, onModalCancel, setPreviousData, coRule} = props;
	const {coType, status} = Utils.splitCoRuleCombination(coRule)
	const [initialView, setInitialView] = useState(true);

	const onCancel = useCallback(() => {
		setPreviousData(template, coRule)
		setInitialView(false)
		onModalCancel()
	}, [coRule, onModalCancel, setPreviousData, template]);

	const handleOutsideClick = useCallback((e) => {
		setPreviousData(template, coRule)
		onCancel();
		onModalCancel();
	}, [coRule, onCancel, onModalCancel, setPreviousData, template]);

	useEffect(() => {
		setTimeout(() => document.addEventListener("click", handleOutsideClick, false));
		return () => {
			document.removeEventListener("click", handleOutsideClick, false);
		}
	}, [handleOutsideClick])

	return (
		initialView &&
		<TransactionalModal
			acceptLabel={"Got it"}
			cancelLabel={"Cancel"}
			onAccept={onCancel}
			onClose={onCancel}
			open={initialView}
			title={"Mandatory Approver Template Added "}
		>
			<div style={{ marginBottom: "16px" }}>
				Your administrator has set up a mandatory approver template, requiring select users be included as approvers on this change order. Additional users may still be added to the approver list. If the criteria listed below changes, the approvers list will be reset.
			</div>
			<ul className="rules-list">
				<li> <b>Rules applied:</b> </li>
				<li> <b>Change Order Type:</b> {coType.toUpperCase()}</li>
				<li> <b>Status:</b> {status} </li>
				<li> <b>Approver Template:</b> {template.templateName} </li>
			</ul>
		</TransactionalModal>
	)
}

export default MandatoryApproverNotifier;
