import "./index.scss"
import { Component } from "react";
import Utils                from "../../../../modules/utils"
import API                  from "../../../../modules/api"
import DeleteSrc            from "../../../../assets/icons/cross-icon.js"
import ExportMenu           from "./export-menu"
import buildAction          from "../../../../helpers/buildAction"
import UI                   from '../../../../action-types/ui';
import InlineIcon from "../../../ui/icon/inline-icon.js";
import ExtendedTable        from "../../common/extended-table"
import Query                from "../../../../modules/query";
import { RevisionField } from "@duro/base/revision-field/";
import CpnField from "../../common/cpn-field";
import { StatusField } from "@duro/base/status-field";
import RightSearchMenu      from "../../../ui/right-search-menu";
import Config               from "../../../../modules/config";

export class Export extends Component
{

    constructor(props, context)
    {
        super(props, context)
        this.setLoading         = this.setLoading.bind(this)
        this.getQuery           = this.getQuery.bind(this)
        this.onSearchChange     = this.onSearchChange.bind(this)
        this.add                = this.add.bind(this);
        this.remove             = this.remove.bind(this)
        this.showAlert          = this.showAlert.bind(this)
        this.state              = this.props.state
        this.initialState       = Utils.clone(this.state)
        this.selectAll          = this.selectAll.bind(this);
        this.onInputChange      = this.onInputChange.bind(this);
        this.getDataFromAPI     = this.getDataFromAPI.bind(this);
        this.setSelectAllButtonStatus = this.setSelectAllButtonStatus.bind(this);
        this.syncSearchInputsWithExportChilds = this.syncSearchInputsWithExportChilds.bind(this);

        let viewStyles;

        try
        {
            viewStyles = window.__userStyles.styles.exportPage || {}
        }
        catch(error)
        {
            viewStyles = {}
        }
        this.currentSortItemAscending= ("defaultSortAssending" in viewStyles ? viewStyles.defaultSortAssending : true)
        this.current = ("defaultSortColumnName" in viewStyles ? viewStyles.defaultSortColumnName : "cpn")
        this.headings = [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "CPN",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 121),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    headingClass: "user-col",
                    disabled    : true
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 206),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    headingClass: "user-col",
                    disabled    : true
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 119),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", 140),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : false
                },
                {
                    key          : "remove",
                    displayName  : "",
                    sortable     : false,
                    minWidth     : 30,
                    width        : 30,
                    visibility   : true,
                    headingClass : "remove-col",
                    disabled     : true,
                    dragable     : false,
                    expandAble   : false,
                    hideFromSettings: true
                }
            ]
        if
        (
            this.props.location.state &&
            this.props.location.pathname.match("export") &&
            this.props.location.state.data
        )
        {
            this.state.collection = this.props.location.state.data
            this.state.mode       = this.props.location.state.mode
        }
    }

    componentDidMount()
    {
        if(this.state.mode !== 'revision')
        {
            this.getDataFromAPI(false, this.state.search);
        }
    }

    setLoading(loading)
    {
        const {dispatch} = this.props
        if(loading)
        {
            dispatch(buildAction(UI.LOAD_START));
        }
        else
        {
            dispatch(buildAction(UI.LOAD_END));
        }
    }

    getQuery(type, value)
    {
        let collection = this.state.collection;
        let query = Query.parse(value, type);
        query.type = type;
        query.limit = Config.EXPORT_RIGHT_SIDE_BAR_LIMIT.toString();
        query.without_co = "true";
        query.lean = "true";
        query.is_export = "true";
        let queryString  = Query.stringify(query);
        return queryString;
    }

    onSearchChange(event)
    {
        let search          = this.state.search;
        let value           = event.target.value;
        search.loading_flag = true;
        this.state.page          = 1;
        search.value        = value;

        this.setState(this.state, () =>
        {
            this.getDataFromAPI(true, search, value);
        })
    }

    showAlert(type, value)
    {
        const {dispatch} = this.props
        if(type === "modal")
        {
            dispatch(buildAction(UI.SHOW_ALERT, {type: "modal", text: value}))
        }

        if(type === "errors")
        {
            dispatch(buildAction(UI.SHOW_ALERT, {type: "errors", errors: value, err: new Error(value[0])}))
        }
    }

    remove(i)
    {
        let collection = this.state.collection;
        let search     = this.state.search;
        if(collection.length > 0)
        {
            search.inputs.find((input) => {
                if(input._id === collection[i]._id)
                {
                    input.isAddedInList = false;
                    input.checked = false;
                }
            });
        }
        collection.splice(i, 1);
        this.syncSearchInputsWithExportChilds(search);
        this.setSelectAllButtonStatus(search);
        this.setState(this.state);
    }

    add()
    {
        let state                      = this.state;
        let {search, collection, list} = state;
        let {inputs, selections}       = search;

        inputs.forEach((input, i) =>
        {
            if (input.checked && !input.isAddedInList)
            {
                input.isAddedInList = true;
                search.selections.push(search.inputs[i]);
            }
        })

        if(search.selections.length < 1) return;

        state.collection = [...collection, ...selections];
        search.selections = [];
        search.selectAllFlag = false;
        this.setSelectAllButtonStatus(search);
        this.setState({search});
    }

    onInputChange(i, event)
    {
        let state = this.state;
        let search = state.search;
        let current = i;
        if (event.shiftKey)
        {
            let checkedstate = false;
            let start = -1;
            let end  = -1;
            if(!search.inputs[current].checked)
            {
                checkedstate = true;
            }

            if(state.lastchecked < current && state.lastchecked !== -1)
            {
                start = state.lastchecked;
                end = current;
            }
            if(state.lastchecked > current && state.lastchecked !== -1)
            {
                start = current;
                end = state.lastchecked;
            }
            if(start >= 0 && end >= 0)
            {
                for(let j = start; j <= end; j++)
                {
                    search.inputs[j].checked = checkedstate;
                }
                if(!search.inputs[current].checked)
                {
                    search.inputs[current].checked = checkedstate;
                }
            }

        }
        else
        {
            search.inputs[i].checked = !search.inputs[i].checked;
        }
        state.lastchecked = i;
        this.setSelectAllButtonStatus(search);
        this.setState({search});
    }

    setSelectAllButtonStatus(search)
    {
        let columns = search.inputs;
        let count = 0;
        columns.map((col, i) =>
        {
            if (col.checked || col.isAddedInList)
            {
                count++;
            }
        })

        if(count === columns.length && columns.length > 0)
        {
            search.selectAllFlag = true;
            search.selectAllButoonClazz = "";
        }
        else if (count !== columns.length && count > 0)
        {
            search.selectAllFlag = false;
            search.selectAllButoonClazz = "middle";
        }
        else
        {
            search.selectAllFlag = false;
            search.selectAllButoonClazz = "";
        }
    }

    selectAll()
    {
       let search = this.state.search;
        if (!search.selectAllFlag)
        {
            search.selectAllFlag = true;
            search.selectAllButoonClazz = "";
            search.inputs.forEach(input => {input.checked = true});
        }
        else
        {
            search.selectAllFlag = false;
            search.inputs.forEach(input => input.checked = false);
        }
        this.setSelectAllButtonStatus(search);
        this.setState({search});
    }

    getDataFromAPI(onSearchChangeFlag=false, search=[], value="")
    {
        let query = this.getQuery('all', value ? value : '')
        let state = this.state;
        let page  = state.page;
        API.search(query, page, (err, data) =>
        {
            if(err)
            {
                console.error(err)
                search.inputs = [];
                search.count = 0;
                return this.setState(state);
            }
            search = search.length > 0 ? search : state.search;
            let searchInputs = [];
            search.count = data.count;
            data.results.forEach((result, i) =>
            {
                if(result.alias === 'cmp' || result.alias === 'prd')
                {
                   result.cpn = Utils.getCpn(result);
                }
                searchInputs.push(result);
            })
            if(onSearchChangeFlag)
            {
                this.state.search.inputs = [];
            }
            search.loading_flag = false;
            this.state.search.inputs = [...this.state.search.inputs,...searchInputs];
            this.syncSearchInputsWithExportChilds(search);
            this.setSelectAllButtonStatus(search);
            this.setState((state) => ({search, loading_flag: false, page: page+1}));
        })
    }

    syncSearchInputsWithExportChilds(search)
    {
        let collection = this.state.collection;

        if(collection.length > 0)
        {
            collection.forEach((item) => {
                search.inputs.find((input) => {
                    if(input._id === item._id)
                    {
                        input.isAddedInList = true;
                        input.checked = true;
                    }
                });
            });
        }
    }

    getLink(item)
    {
        if(item.alias === 'cmp')
        {
            return this.state.mode === 'revision' ? `/component/revision/${item._id}` :  `/component/view/${item._id}`;
        }
        else
        {
            return this.state.mode === 'revision' ? `/product/revision/${item._id}` :  `/product/view/${item._id}`;
        }
    }

    render()
    {
        let collection = this.state.collection
        let search     = this.state.search
        let routeClass = "app-row export-table"

        let headings = this.headings

        let rows = collection.map((item, i) =>
        {
            let cpn      = Utils.getCpn(item);

            let cells =
            {
                "cpn":
                {
                    value        : cpn,
                    tooltip      : cpn,
                    displayValue : <span className="link"><CpnField item={item} cpn={cpn}/></span>,
                    cellClass    : 'cpn-col'
                },
                "name":
                {
                    value        : item.name,
                    tooltip      : item.name,
                    displayValue : item.name
                },

                "status":
                {
                    value       : item.status,
                    tooltip     : item.status,
                    displayValue: <StatusField item={item} />,
                    cellClass   : 'status-cell'

                },

                "revision":
                {
                    value        : item.revision,
                    tooltip      : item.revision,
                    displayValue : <RevisionField item={item} />
                },
                "remove":
                {
                    value        : "",
                    cellClass    : "remove-col",
                    notLink      : true,
                    displayValue :
                            <div onClick={() => this.remove(i)}>
                                 <InlineIcon
                                    tooltip="Remove"
                                    tooltipPlace="top"
                                 >
                                    <DeleteSrc useCurrentColor={true}/>
                                 </InlineIcon>
                            </div>
                },
                rowLink         : this.getLink(item),
            }

            return cells
        })


        let markup =
            <div className="export-route">
                <div className={routeClass}>
                    <div className="crumbs"></div>
                    <h1>Export</h1>
                    <ExtendedTable
                        wrapperClassName={"export-table"}
                        wrapperSelectorClass={"export-list"}
                        themeDataTableId={"export-table"}
                        headings={headings}
                        rows={rows}
                        endStaticColumns={1}
                        stylesName="exportPage"
                        currentSortItem={this.current}
                        includeToolBar={true}
                        currentSortItemAscending={this.currentSortItemAscending}
                        afterSyncWithParentState={()=> {}}
                        footerRow={rows.length === 0 && {
                                dataCellEl: <p>No Products or Components added</p>
                            }
                        }
                    />
                </div>
                <div className="export-right-side-area">
                    {
                        this.state.mode !== 'revision' &&
                        <RightSearchMenu
                            heading="Add Products and Components"
                            add={this.add}
                            onSearchChange={this.onSearchChange}
                            onInputChange={this.onInputChange}
                            selectAll={this.selectAll}
                            disableDesignResults={false}
                            name="export-search-bar"
                            search={this.state.search}
                            loading_flag={this.state.search.loading_flag}
                            getDataFromAPI={this.getDataFromAPI}
                            max_pages={Math.ceil(this.state.search.count/Config.EXPORT_RIGHT_SIDE_BAR_LIMIT)}
                            page={this.state.page}
                            mode={this.state.mode}
                        />
                    }
                    <ExportMenu
                        showAlert={this.showAlert}
                        collection={collection}
                        currentUserEmail={this.props.currentUserEmail}
                        defaultTemplate={this.props.defaultTemplate}
                        user={this.props.user}
                        mode={this.state.mode}
                    />
                </div>
            </div>
        return markup
    }
}

export default Export
