import axios from "axios";
import config from "v1/modules/config";

const restClient = axios.create({
  baseURL: config.API_ORIGIN,
  withCredentials: true,
  responseType: "json",
});

let authToken: string | null = null;

export const setAxiosToken = (token: string) => {
  authToken = token;
};

restClient.interceptors.request.use(
  (config) => {
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { restClient };
