import React, {Component}   from "react"
import RightSearchMenu      from "../../../../ui/right-search-menu"
import Utils                from "../../../../../modules/utils"
import Config               from "../../../../../modules/config"
import {connect}            from "react-redux"
import buildAction          from "../../../../../helpers/buildAction"
import CO                   from "../../../../../action-types/changeorder"
import API                  from "../../../../../modules/api"
import Query                from "../../../../../modules/query";
import mixpanel from "common/mixpanel";

export class COSearchMenu extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            openRighSearch : true,
            list           : this.props.list,
            searchResults  : [],
            page           : 1,
            lastchecked    : -1,
            search         :
            {
                value                : "",
                selections           : [],
                inputs               : [],
                results              : [],
                makeCustomSearch     : false,
                selectAllFlag        : false,
                parentAssemblyFlag   : false,
                selectAllButoonClazz : "",
                loading_flag         : true,
                count                : 0
            }
        }
        this.getQuery                             = this.getQuery.bind(this);
        this.onSearchChange                       = this.onSearchChange.bind(this);
        this.onInputChange                        = this.onInputChange.bind(this);
        this.selectAll                            = this.selectAll.bind(this);
        this.add                                  = this.add.bind(this);
        this.setSelectAllButtonStatus             = this.setSelectAllButtonStatus.bind(this);
        this.syncSearchInputsWithCoChildsOnCoEdit = this.syncSearchInputsWithCoChildsOnCoEdit.bind(this);
        this.getDataFromAPI                       = this.getDataFromAPI.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let state = this.state
        let search = state.search
        search.inputs = this.syncSearchInputsWithCoChildsOnCoEdit(nextProps.list, state.search.inputs)
        search.selectAllButoonClazz = ""
        search.selections = []
        this.setSelectAllButtonStatus(search)
        search.selectAllFlag = false
        this.setState(state)
    }

    componentWillMount()
    {
        this.getDataFromAPI()
    }

    getDataFromAPI(onSearchChangeFlag=false)
    {
        let state = this.state
        let value = state.search.value
        let page = state.page
        let query = this.getQuery('all', value ? value : '')
        API.search( query, page, (err, data) =>
        {
            if(err)
            {
                //TODO: handle err
            }
            let search = this.state.search
            let searchInputs = []
            let prd_results = data.results
            let results = [...prd_results]
            this.state.search.count = data.count
            let index = this.state.search.results.length
            results.forEach((result, i) =>
            {
                let cpn = result.cpn;
                if(result.alias === 'cmp' || result.alias === 'prd')
                {
                   cpn = Utils.getCpn(result);
                }

                let input =
                {
                    cpn    : cpn,
                    name   : result.name,
                    status : result.status,
                    checked: false,
                    index : index + i,
                    isAddedInList : false,
                    result: result,
                    inOpenCO: result.co ? true : false
                }
                searchInputs.push(input)
            })
            this.syncSearchInputsWithCoChildsOnCoEdit(this.props.list, searchInputs)
            if(onSearchChangeFlag)
            {
                this.state.search.inputs = []
                this.state.search.results = []
                if (value.trim())
                {
                    let payload = {
                        searchString: value,
                        searchSource: "Change Order right sidebar",
                        resultsCount: data.count
                    }
                    API.services.recordSearch(payload)
                }
            }
            this.state.search.inputs = [...this.state.search.inputs,...searchInputs]
            this.state.search.results = [...this.state.search.results,...results]
            this.setSelectAllButtonStatus(search)
            this.setState((state) => ({search, loading_flag: false, page: page+1}));
        })
    }

    syncSearchInputsWithCoChildsOnCoEdit(list, searchInputs, options={})
    {
        for(let j=0; j<searchInputs.length; j++)
        {
            if (!options.leaveChecked)
            searchInputs[j].checked = false
            searchInputs[j].isAddedInList = false
        }
        for(let i=0 ; i < list.length; i++)
        {
            for(let j=0; j<searchInputs.length; j++)
            {
                let searchInputCpn = searchInputs[j].cpn;
                if(list[i].alias === 'cmp')
                {
                    list[i].cpn = Utils.getCpn(list[i]);
                }
                if(searchInputs[j].alias === 'cmp')
                {
                    searchInputs[j].cpn = Utils.getCpn(searchInputs[j]);
                }

                if (list[i].cpn === searchInputs[j].cpn)
                {
                    if (!options.leaveChecked)
                    searchInputs[j].checked = true
                    searchInputs[j].isAddedInList = true
                }
            }
        }
        return searchInputs
    }

    onInputChange(i, event)
    {
        let state = this.state
        let search = this.state.search
        let current = i
        if (event.shiftKey)
        {
            let checkedstate = false
            let start = -1;
            let end  = -1;
            if(!search.inputs[current].checked)
            {
                checkedstate = true
            }

            if(state.lastchecked < current && state.lastchecked !== -1)
            {
                start = state.lastchecked
                end = current
            }
            if(state.lastchecked > current && state.lastchecked !== -1)
            {
                start = current
                end = state.lastchecked
            }
            if(start >= 0 && end >= 0)
            {
                for(let j = start; j <= end; j++)
                {
                    search.inputs[j].checked = checkedstate;
                }
                if(!search.inputs[current].checked)
                {
                    search.inputs[current].checked = checkedstate
                }
            }

        }
        else
        {
            search.inputs[i].checked = !search.inputs[i].checked
        }
        state.lastchecked = i
        this.setSelectAllButtonStatus(search)
        this.setState({search})
    }

    setSelectAllButtonStatus(search)
    {
        let columns = search.inputs
        let count = 0
        columns.map((col, i) =>
        {
            if (col.checked || col.isAddedInList)
            {
                count++
            }
        })

        if(count === columns.length && columns.length > 0)
        {
            search.selectAllFlag = true
            search.selectAllButoonClazz = ""
        }
        else if (count !== columns.length && count > 0)
        {
            search.selectAllFlag = false
            search.selectAllButoonClazz = "middle"
        }
        else
        {
            search.selectAllButoonClazz = ""
        }
    }

    selectAll()
    {
       let search = this.state.search;
        if (!search.selectAllFlag)
        {
            search.selectAllFlag = true;
            search.selectAllButoonClazz = "";
            search.inputs.forEach(input => {input.checked = true});
        }
        else
        {
            search.selectAllFlag = false;
            search.inputs.forEach(input => input.checked = false);
        }
        this.setSelectAllButtonStatus(search);
        this.setState({search});
    }

    getQuery(type, value)
    {
        let query = Query.parse(value);
        query.limit = `${Config.RIGHT_SIDE_BAR_LIMIT.toString()}`;
        query.without_co = "true";
        query.lean = "true";
        query.response_type = "changeOrderEdit";
        let queryString  = Query.stringify(query);
        return queryString;
    }

    onSearchChange(event)
    {
        let state = this.state
        let value  = event.target.value
        state.search.value = value
        state.loading_flag = true
        state.page = 1
        state.search.selections = []
        state.search.inputs =[]
        state.search.results =[]
        this.setState(state, () => this.getDataFromAPI(true));
    }

    add()
    {
        let search      = this.state.search
        let inputs      = search.inputs
        let list = this.state.list
        inputs.forEach((input) =>
        {
            if (input.checked && !input.isAddedInList)
            {
                input.isAddedInList = true
                search.selections.push(search.results[input.index])
                mixpanel.track('ChangeOrder', {
                    co: this.props.coId,
                    item: search.results[input.index].cpn, // use the cpn instead of name
                    operation: 'Add Products/Components',
                    "Change Order UI Version": "v1"
                })
            }
        })

        if(search.selections.length < 1) return
        this.props.add(Utils.clone(search))
        search.selections = []
        search.selectAllFlag = false
        this.setState({search})
    }

    render()
    {
        let markup =
            <RightSearchMenu
                heading="Add Products and Components"
                add={this.add}
                onSearchChange={this.onSearchChange}
                onInputChange={this.onInputChange}
                selectAll={this.selectAll}
                disableDesignResults={false}
                coId={this.props.coId}
                search={this.state.search}
                name="prd-cmp-list-for-co"
                changeorders= {this.props.changeorders}
                loading_flag={this.state.loading_flag}
                getDataFromAPI={this.getDataFromAPI}
                max_pages={Math.ceil(this.state.search.count/Config.RIGHT_SIDE_BAR_LIMIT)}
                page={this.state.page}
            />

        return markup
    }
}

export default COSearchMenu
