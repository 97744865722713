import { ValidationTooltipType } from "./types";
import { Pane, TooltipText } from "./tooltip.styles";
import { DuroButton } from "common/components";
import { sdk } from "features/changeorders/sdk";
import { sdk as sdkv2 } from "features/changeorders/sdk/editor";

export const ChildrenObseleteError: ValidationTooltipType = ({ row }) => {
  const [c] = sdkv2.state.childrenDisplayed.useStore();
  const childrenDisplayed = c[row.treeId];

  return (
    <Pane sx={{ pb: "30px", ...(childrenDisplayed ? { height: 40 } : {}) }}>
      <TooltipText>
        One or more child Components has a Status of Obsolete.
      </TooltipText>
      {!childrenDisplayed && (
        <>
          <TooltipText>
            Add child Components and update their Status to resolve this issue.
          </TooltipText>
          <DuroButton
            onClick={() => {
              sdkv2.state.childrenIds.setState((prev) => [...prev, row.id]);
              sdk.storage.displayChildrenModal.setState(true);
            }}
          >
            Add Child Components
          </DuroButton>
        </>
      )}
    </Pane>
  );
};
