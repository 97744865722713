import { BookmarkIcon, DeleteIcon, EditIcon, ExportIcon, SerializationIcon } from "assets/icons";
import { BuildPageWrapper } from "build/components/pageWrapper";
import { SerializationModal } from "build/components/serializationModal";
import { BreadCrumbIcon, useBreadCrumb } from "common/components/breadcrumb";
import { IToolbarActions } from "common/components/toolbar";
import { ModelType } from "@duro/utils";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

interface BuildInstanceRouteParams {
  instanceId: string
}

export function BuildInstance() {
  const { pathname } = useLocation();
  const { instanceId } = useParams<BuildInstanceRouteParams>();
  const [displaySerializationModal, setDisplaySerializationModal] = useState(false);

  const breadCrumbOptions = useBreadCrumb({
    icon: BreadCrumbIcon.PRODUCTS,
    label: `Instance ${instanceId}`,
    url: pathname,
  });

  const onEditClicked: MouseEventHandler = useCallback(e => {
    // TODO: Open the edit serialization dialog on clicking edit.
    // This will open the serialization modal loaded with the settings used do create the lot.
  }, []);

  const onBookmarkClicked: MouseEventHandler = useCallback(e => {
    // Call update production to toggle the bookmarked state of the production.
    // This will place the anchor CPN associated with the lot on the Productions Dashboard
  }, []);

  const onDeleteClicked: MouseEventHandler = useCallback(e => {
    // Call delete lot to delete the Lot and all it's instances
  }, []);

  const onExportClicked: MouseEventHandler = useCallback(e => {
    // Opens the Export page with the lot contents loaded to the export table
  }, []);

  const toggleSerializationModal = useCallback(() => (
    setDisplaySerializationModal((displayModal: boolean) => !displayModal)
  ), []);

  const onSubmitSerializationModal = useCallback(() => {
    setDisplaySerializationModal(false);
  }, []);

  const toolbarItems = useMemo((): IToolbarActions[] => [
    {
      Icon: EditIcon,
      onClick: onEditClicked,
      tooltip: "Edit",
    },
    {
      Icon: BookmarkIcon,
      onClick: onBookmarkClicked,
      tooltip: "Bookmark",
    },
    {
      Icon: DeleteIcon,
      onClick: onDeleteClicked,
      tooltip: "Delete",
    },
    {
      Icon: ExportIcon,
      onClick: onExportClicked,
      tooltip: "Export",
    },
    {
      Icon: SerializationIcon,
      onClick: () => toggleSerializationModal(),
      tooltip: "Serialize",
    },
  ], [onBookmarkClicked, onDeleteClicked, onEditClicked, onExportClicked, toggleSerializationModal]);

  // ToDo: Pull serialization data from the page
  const getPayloadForSerialization = useMemo(() => ({
    id: "64c90bd2d99819aefda0f531",
    alias: ModelType.CMP,
  }), []);

  return (
    <BuildPageWrapper
      breadCrumbOptions={breadCrumbOptions}
      helmetTitle="Build Instance"
      toolbarProps={{
        toolbarItems,
      }}
    >
      This is the Instance {instanceId}!
      {displaySerializationModal && (
        <SerializationModal
          itemToBeSerialized={getPayloadForSerialization}
          onCancel={toggleSerializationModal}
          onClose={toggleSerializationModal}
          onSubmitCallback={onSubmitSerializationModal}
          open={displaySerializationModal}
          title="Serialize and Create New Lot"
        />
      )}
    </BuildPageWrapper>
  );
}
