import React from "react";
import CoAlert from "v1/assets/icons/co-alert";
import { IComponentOld } from "design/pages/components";
import { IProductOld } from "design/pages/products";
import { Component } from "../../../design/models/component";
import { Product } from "../../../design/models/product";
import Tooltip from "@mui/material/Tooltip";
import { Box, styled } from "@mui/material";

import { DuroIcon } from "@duro/base/icon";
import { DuroInlineLayout } from "@duro/layout";
import { DuroSize, Optional } from "@duro/utils";

/**
 * @typedef {object} RevisionItem
 * @description Combines selected properties from several types and makes all properties optional.
 * This type is used to create a flexible data structure that can represent a revision state
 * across different object models (e.g., `Component`, `Product`) in both old and new parts of the app.
 *
 * @property {boolean} [modified] - Indicates if the item was modified.
 * @property {string} [revision] - The current revision of the item.
 * @property {string} [previousRevision] - The previous revision of the item.
 * @property {string} [revisionValue] - The current revision value of the item.
 * @property {string} [previousRevisionValue] - The previous revision value of the item.
 * @property {string} [revisionType] - The type of revision, which can help in distinguishing
 * between different kinds of revisions (e.g., `coRevision`, `releaseRevision`).
 */
type RevisionItem = Optional<
  Pick<IProductOld, "modified" | "revision" | "previousRevision"> &
    Pick<IComponentOld, "modified" | "revision" | "previousRevision"> &
    Pick<Component, "modified" | "revisionValue" | "previousRevisionValue"> &
    Pick<Product, "modified" | "revisionValue" | "previousRevisionValue"> &
    Pick<Component, "revisionType"> &
    Pick<Component, "status" | "previousStatus"> &
    Pick<Product, "status" | "previousStatus">
>;

/**
 * @typedef {"current" | "previous"} RevisionDisplayType
 * @description Represents whether the current or previous revision value should be displayed.
 */
type RevisionDisplayType = "current" | "previous";

/**
 * @interface RevisionFieldProps
 * @description Props for the `RevisionField` component.
 * @property {RevisionItem} item - The item to be displayed in the revision field.
 * @property {RevisionDisplayType} [displayType] - Determines whether the current or previous
 * revision should be displayed.
 */
export interface RevisionFieldProps {
  item: RevisionItem;
  displayType?: RevisionDisplayType;
}

const getModifiedBox = (showMargin: boolean) => (
  <Box
    sx={{ alignSelf: "flex-start", ...(showMargin && { marginTop: "-6px" }) }}
  >
    {getModifiedIcon()}
  </Box>
);

/**
 * @function getRevisionValue
 * @description Utility function to get the appropriate revision value (current or previous) from the item.
 * @param {RevisionItem} item - The item from which to retrieve the revision value.
 * @param {boolean | null} isPrevious - A boolean indicating whether to retrieve the previous revision value.
 * @returns {string} The revision value (either current or previous), or "—" if the value is not available.
 */
export function getRevisionValue(
  item: RevisionItem,
  isPrevious: null | boolean
) {
  const { previousRevision, previousRevisionValue, revision, revisionValue } =
    item;

  if (isPrevious) {
    return previousRevision?.trim() || previousRevisionValue?.trim() || "—";
  } else {
    return revision?.trim() || revisionValue?.trim() || "—";
  }
}

/**
 * @function getModifiedIcon
 * @description Renders an icon with a tooltip indicating that the item has been modified.
 * @returns {JSX.Element} The icon wrapped in a tooltip.
 */
function getModifiedIcon() {
  return (
    <Tooltip
      placement="right"
      title="The component has been modified."
      className="align-top"
    >
      <DuroIcon size={DuroSize.XS}>
        <CoAlert />
      </DuroIcon>
    </Tooltip>
  );
}

/**
 * @component
 * @description The `RevisionField` component is responsible for displaying the revision status of an item.
 * It supports showing either the current or previous revision, and can indicate if the item has been modified.
 *
 * @param {RevisionFieldProps} props - The props for the component, including the item and an optional display type.
 * @returns {JSX.Element} The rendered `RevisionField` component.
 */
const RevisionField: React.FC<RevisionFieldProps> = (props) => {
  let { item, displayType } = props;
  /**
   * @constant {boolean} usePreviousRevision
   * @description A constant that determines whether to use the previous revision value when
   * rendering the data into the template.
   */
  const usePreviousRevision = displayType !== "current";
  const previousRevisionValue = getRevisionValue(item, usePreviousRevision);
  const currentRevisionValue = getRevisionValue(item, !usePreviousRevision);

  if (displayType) {
    return (
      <DuroInlineLayout gap="0.25rem" wrap="nowrap" alignItems="center">
        <span>{previousRevisionValue}</span>
        {item.modified ? (
          item.revisionType !== "coRevision" &&
          item.revisionType !== "releaseRevision" &&
          item.status !== "DESIGN" ? (
            <Box sx={{ alignSelf: "flex-start" }}>{getModifiedIcon()}</Box>
          ) : null
        ) : null}
      </DuroInlineLayout>
    );
  } else if (previousRevisionValue !== currentRevisionValue && item.status !== "DESIGN") {
    // show previous rev value w/ modified icon arrow and current rev value
    return (
      <DuroInlineLayout gap="0.25rem" wrap="nowrap" alignItems="center">
        {previousRevisionValue}
        {item.modified && item.status !== "DESIGN" ? (
          <Box sx={{ alignSelf: "flex-start" }}>{getModifiedIcon()}</Box>
        ) : null}

        <FontSize24>
          <span>&rarr;</span>
        </FontSize24>
        {currentRevisionValue}
      </DuroInlineLayout>
    );
  } else if (previousRevisionValue !== currentRevisionValue && item.status === "DESIGN") {
    // show previous rev value w/ modified icon arrow and current rev value
    return (
      <DuroInlineLayout gap="0.25rem" wrap="nowrap" alignItems="center">
        {currentRevisionValue}
      </DuroInlineLayout>
    );
  }else {
    // show previous rev value with modified tooltip, conditionally show arrow and the current rev value
    const showRevisionChange =
      item.previousStatus !== item.status &&
      item.previousRevision === item.revision;
    const useModifiedTooltip = ( item.modified && item.status!== 'DESIGN')&& (
      <Box sx={{ alignSelf: "flex-start", marginTop: "-6px" }}>
        {getModifiedIcon()}
      </Box>
    );

    return (
      <DuroInlineLayout gap="0.25rem" wrap="nowrap" alignItems="center">
        {getRevisionValue(item, usePreviousRevision)}
        {useModifiedTooltip}
        {showRevisionChange && (
          <>
            <FontSize24>
              <span>&rarr;</span>
            </FontSize24>
            {getRevisionValue(item, false)}
          </>
        )}
      </DuroInlineLayout>
    );
  }
};

/**
 * @constant {object} FontSize24
 * @description A styled component that applies a font size of 24px. It is used to render the arrow icon
 * between the current and previous revision values when they differ.
 *
 * @private
 * This component is currently private and should be refactored once a comprehensive typography system is in place.
 */
const FontSize24 = styled("span")({
  fontSize: "24px",
});

export default RevisionField;
