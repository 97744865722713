import "./index.scss"
import React           from "react"
import Utils           from "../../../../../../modules/utils"
import TimeStampColumn from "../../../../common/timestamp-column";

const Details = (props) => {
    let webhook = props.webhook;
    let markup =
    <div className="details-block">
        <div className="webhook-properties">
            <div className="items-block">
                <table>
                    <tbody>
                        <tr>
                            <th>Webhook Url</th>
                            <td>{webhook.webhookUrl}</td>
                        </tr>
                        <tr>
                            <th>MODE</th>
                            <td>
                                {webhook.mode}
                            </td>
                        </tr>
                        <tr>
                            <th>EVENT TYPES</th>
                            <td>
                                {webhook.eventTypes.join(', ')}
                            </td>
                        </tr>
                        <tr>
                            <th>SIGNING SECRET</th>
                            <td>
                                {webhook.signingSecret}
                            </td>
                        </tr>
                        <tr>
                            <th>DESCRIPTION</th>
                            <td>
                                {webhook.description}
                            </td>
                        </tr>
                        <tr>
                            <th className='include-flag-lable'>
                                Include Component and Product data
                            </th>
                            <td>
                                {webhook.includeComponentProductData ? 'True' : 'False'}
                            </td>
                        </tr>
                        <tr>
                            <th>CREATED</th>
                            <td>
                                <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={webhook.created} />
                            </td>
                        </tr>
                        <tr>
                            <th>LAST MODIFIED</th>
                            <td>
                                <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={webhook.lastModified} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    return markup
};

export default Details;
