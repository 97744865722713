import "./designTokens.scss";
import { FC, Fragment, ReactNode } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps } from "@mui/system";

export type DuroModalProps = {
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  header?: string;
  children?: ReactNode;
  footer?: ReactNode;
  ariaLabelledby: string;
  ariaDescribedby: string;
  customStyle?: object;
  refetch?: () => void;
};

export const DuroModal: FC<DuroModalProps> = ({
  open,
  onClose,
  showCloseButton = true,
  header,
  children,
  footer,
  ariaDescribedby,
  ariaLabelledby,
  customStyle,
}) => {
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby={ariaLabelledby}
        aria-describedby={ariaDescribedby}
      >
        <Box sx={{ ...styles.modalContainer, ...customStyle }}>
          {showCloseButton && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={styles.closeButton}
            >
              <CloseIcon sx={{ fontSize: "large" }} />
            </IconButton>
          )}
          {header && (
            <Box sx={styles.header}>
              <Typography
                id={ariaLabelledby}
                variant="h6"
                component="header"
                sx={{ flexGrow: 1 }}
              >
                {header}
              </Typography>
            </Box>
          )}
          {children && (
            <Box sx={styles.content} id={ariaDescribedby}>
              {children}
            </Box>
          )}
          {footer && <Box sx={styles.footer}>{footer}</Box>}
        </Box>
      </Modal>
    </Fragment>
  );
};

// default variable values are set to info modal values
const styles = {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "var(--app-modal-min-width)",
    width: "var(--app-modal-width)",
    bgcolor: "var(--app-modal-bgcolor)",
    borderRadius: "var(--app-modal-border-radius)",
    boxShadow: "var(--app-modal-box-shadow)",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "stretch",
    gap: "0.25rem",
    p: "var(--app-modal-padding)",
  } as SxProps,
  closeButton: {
    position: "absolute",
    inset: "16px 16px auto auto",
    zIndex: 1000,
    padding: "0px",
  } as SxProps,
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.25rem",
    mb: "var(--app-modal-header-margin-bottom)",
  } as SxProps,
  content: {
    mb: "var(--app-modal-content-margin-bottom)",
  } as SxProps,
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "0.25rem",
    mt: "var(--app-modal-footer-margin-top)",
  } as SxProps,
};
