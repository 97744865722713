import { useQuery, WatchQueryFetchPolicy } from "@apollo/client";
import { useMemo } from "react";
import { GET_CHANGE_ORDER_APPROVERS_TEMPLATES } from "graphql/query/changeOrdersQueries";

export type ChangeOrderApprovalTemplate = {
  id: string;
  templateName: string;
  approvalType: string;
  isPublic: boolean;
  lastUsed: number;
  approvers: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    groups: string[];
  }[];
  notifiers: any[];
  externalUsers: any[];
};

export type ChangeOrderApprovalTemplatesResponse = {
  data: {
    changeOrderApprovalTemplates: ChangeOrderApprovalTemplate[];
  };
};

export const useGetApproverTemplates = (
  fetchPolicy: WatchQueryFetchPolicy = "cache-first"
) => {
  const { data, error, loading, refetch } = useQuery(
    GET_CHANGE_ORDER_APPROVERS_TEMPLATES,
    {
      fetchPolicy,
    }
  );

  const templates = useMemo(() => data?.changeOrderApprovalTemplates, [data]);
  return { data: templates, error, loading, refetch };
};
