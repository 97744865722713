import { ChangeEvent, forwardRef, useMemo } from "react";
import MuiIndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Checkbox, FormControlLabel, styled, Typography } from "@mui/material";
import { CheckboxFilledIcon, CheckboxUnfilledIcon } from "assets/icons";

interface IFormControlLabelStyles {
  margin?: string,
  paddingRight?: string,
}
interface CustomDuroCheckboxProps {
  checkboxFontSize?: string;
  checkBoxLabel?: string,
  checked?: boolean,
  disabled?: boolean,
  formControlLabelStyles?: IFormControlLabelStyles,
  id?: string,
  onClick?: (event: any) => void,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  labelStyles?: {
    fontSize?: string,
    marginLeft?: string,
  },
  value?: string | number | null,
}

export const DuroCheckbox = forwardRef<any, CustomDuroCheckboxProps>(({
  checkBoxLabel,
  checkboxFontSize,
  formControlLabelStyles,
  labelStyles,
  ...rest
}, ref) => {
  const _label = useMemo(() => (
    checkBoxLabel && (
      <CustomLabel labelStyles={labelStyles}>
        {checkBoxLabel}
      </CustomLabel>
    )
  ), [checkBoxLabel, labelStyles]);

  const customFontSize = useMemo(() => ({ fontSize: checkboxFontSize ?? "1rem" }), [checkboxFontSize]);

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          checkedIcon={<CheckboxFilledIcon {...customFontSize} />}
          icon={<CheckboxUnfilledIcon {...customFontSize} />}
          ref={ref}
          indeterminateIcon={<MuiIndeterminateCheckBoxIcon />}
          {...rest}
        />
      }
      formControlLabelStyles={formControlLabelStyles}
      label={_label}
    />
  );
});

export const CustomDuroCheckbox = styled(DuroCheckbox, {
  shouldForwardProp: prop => prop !== "checkboxFontSize",
})<Pick<CustomDuroCheckboxProps, "checkboxFontSize">>(
  ({ checkboxFontSize }) => ({
    "& .MuiSvgIcon-root": { fontSize: checkboxFontSize ?? "1.5rem" },
  }),
);

export const CustomLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== "labelStyles",
})<Pick<CustomDuroCheckboxProps, "labelStyles">>(({ labelStyles }) => labelStyles);

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== "formControlLabelStyles",
})<Pick<CustomDuroCheckboxProps, "formControlLabelStyles">>(
  ({ formControlLabelStyles }) => ({
    margin: "0",
    ...formControlLabelStyles,
  }),
);
