import "./index.scss";
import React, {Component}                       from "react";
import DropZone                                 from "react-dropzone";
import Link                                     from "../../../../../../ui/link";
import Loading                                  from "../../../../../../ui/inner-loading";
import Config from "../../../../../../../modules/config";
import Icon                                     from "../../../../../../ui/icon";
import API                                      from "../../../../../../../modules/api";
import Utils                                    from "../../../../../../../modules/utils";
import largeSrc from "../../../../../../../assets/icons/upload-l.svg";
import downloadSrc                              from "../../../../../../../assets/icons/download.svg";
import importIcon from "../../../../../../../assets/icons/import-icon-black.svg";
import ModalBox                                 from "../../../../../../ui/modal-box";
import HelpIcon                                 from "../../../../../../../assets/icons/help-icon.js";
import InlineIcon                               from "../../../../../../ui/icon/inline-icon.js";
import PerfectScrollbar                         from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css'
import { userById } from "graphql/query/userQueries";
import { ModelType } from "@duro/utils";
import { extractCpnRules } from "utils/cpn";

class UploadScreen extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            loading : false,
            message : "",
            fileType: this.props.fileType || "new",
            modal :
            {
                open : false,
                height: "100vh",
                minHeight: "500px"
            }
        };

        this.onFileDrop           = this.onFileDrop.bind(this);
        this.openModal            = this.openModal.bind(this);
        this.closeModal           = this.closeModal.bind(this);
        this.onModalExternalClick = this.onModalExternalClick.bind(this);
        this.setFileType          = this.setFileType.bind(this);
        this.showAlert            = this.showAlert.bind(this);
    }

    componentWillUnmount(){
        this._ismounted = false;
    }

    setMyState(state = this.state, cb){
        if(this._ismounted)
        {
            this.setState(state, cb);
        }
    }

    componentDidMount()
    {
        this._ismounted = true;
        if (this.props.displayModal === true)
        {
            this.openModal();
        }
    }

    setFileType(e){
        let state = this.state;
        state.fileType = e.target.value;
        this.setState(state);
    }

    openModal(event)
    {
        let modal = this.state.modal;
        modal.open = true;
        let el = document.getElementsByClassName("edit-product-route");
        el = el.length > 0 ? el : document.getElementsByClassName("edit-component-route");
        let height = el.length > 0 ? (el[0].offsetHeight + 'px') : (document.getElementById("sidebar-view").clientHeight + 'px');

        this.state.modal.height = this.props.height || height;
        this.setState(this.state);
    }

    closeModal()
    {
        let modal  = this.state.modal;
        modal.open = false;
        this.setState(this.state);
    }

    onModalExternalClick(event)
    {
        let target     = event.target;
        let parent     = this.refs.modalNode;
        let isExternal = target !== parent && !Utils.isDescendant(parent, target);
        if(isExternal) this.closeModal();
    }

    onFileDrop(files)
    {
        let file     = files[0];
        if (!file || Utils.haveExcelFileExtension(file.name) === false)
        {
            this.showAlert([{"message": "Only .csv, .xlsx and .xls files are allowed."}]);
            return;
        }

        this.setState({loading: true});

        API.components.parseFile(file, (err, data) =>
        {
            if(data && data.headings)
            {
                data.headings = data.headings.filter(function(n){ return n != undefined });
            }
            this.setState({loading: false});

            if(err)
            {

                this.showAlert(err.errors);
                return console.error(err);
            }

            let event = Utils.getEvent(this, data);
            event.fileType = this.state.fileType;
            this.props.next(event);
        })
    }

    showAlert(errors)
    {
        const { showUiAlert } = this.props;
        showUiAlert({type: "errors", errors: errors});
    }

    render()
    {
        if(this.state.loading)
        {
            return <Loading />
        }
        const maxFileSize = Config.MAX_FILE_SIZE;
        let modal = this.state.modal;
        const user = userById();  
        const cpnRules = extractCpnRules(user.activeLibrary, ModelType.COMPONENT) ?? {};
        let markup =
            <div className="upload-side-area">
                <ModalBox onClose={this.props.onClose}>
                    <header className="upload-modal-header">
                        <Icon src={importIcon} />
                        <div className="header-heading">
                            <h3>Step 1 of 3: Upload Your File.</h3>
                        </div>
                    </header>
                    <div className="main-block">
                        <div className="content-block">
                            <div className="custom-modal-header">
                                <div className="header-holder">
                                    <div className="heading-holder">
                                        <h2 onClick={this.autoPopulate}>Import Components From a File</h2>
                                        <Link
                                          to={{ pathname: 'https://duro.zendesk.com/hc/en-us/articles/360030713392-Importing-Spreadsheet-Files' }}
                                          target="_blank"
                                          >
                                            <InlineIcon>
                                                <HelpIcon/>
                                            </InlineIcon>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <PerfectScrollbar className="component-file-scroll">
                                <div className="card-panel">
                                    <div className="card-panel-header">
                                        <h3>UPLOAD YOUR FILE</h3>
                                    </div>
                                    <div className="card-panel-body">

                                        <div className="types-holder">
                                            <h5 className="">TYPE OF IMPORT</h5>
                                            {
                                                ["new", "update"].includes(this.state.fileType) ?
                                                <div className="custom-radio">
                                                   <div className="radio">
                                                       <input
                                                           type="radio"
                                                           value="new"
                                                           name="radio1"
                                                           checked={this.state.fileType === "new"}
                                                           onChange={(e) => this.setFileType(e)}
                                                           />
                                                     <label htmlFor="radio1" />
                                                     <span htmlFor="radio1">
                                                         Create New Components
                                                     </span>
                                                   </div>

                                                   <div className="radio">
                                                       <input
                                                           type="radio"
                                                           value="update"
                                                           name="radio2"
                                                           checked={this.state.fileType === "update"}
                                                           // data-tip="This feature is going to launch soon..."
                                                           onChange={(e) => this.setFileType(e)}
                                                           />
                                                     <label htmlFor="radio2" />
                                                     <span htmlFor="radio2"
                                                       >
                                                       Update Existing Library Components
                                                     </span>
                                                   </div>
                                               </div>
                                                : null
                                            }

                                            {
                                                ["new_assembly", "update_assembly"].includes(this.state.fileType) ?
                                                <div className="custom-radio">
                                                    <div className="radio">
                                                        <input
                                                            type="radio"
                                                            value="new_assembly"
                                                            name="radio3"
                                                            checked={this.state.fileType === "new_assembly"}
                                                            onChange={(e) => this.setFileType(e)}
                                                            />
                                                      <label htmlFor="radio3" />
                                                      <span htmlFor="radio3">
                                                          New Components
                                                      </span>
                                                    </div>
                                                    <div className="radio">
                                                        <input
                                                            type="radio"
                                                            value="update_assembly"
                                                            name="radio4"
                                                            checked={this.state.fileType === "update_assembly"}
                                                            onChange={(e) => this.setFileType(e)}
                                                            />
                                                      <label htmlFor="radio4" />
                                                      <span htmlFor="radio4">
                                                        Existing Components
                                                      </span>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        <div className="components-fields">
                                            {
                                                ["new", "new_assembly"].includes(this.state.fileType) ?
                                                <div>
                                                    <h4> Required Labels</h4>
                                                    <ul className="required-fields-list">
                                                        <li><span>*</span> Name</li>
                                                        {
                                                            this.state.fileType === "new_assembly" &&
                                                            <li><span>*</span> Quantity</li>
                                                        }

                                                        {
                                                            this.state.fileType === "new_assembly" && Utils.isItemNumber(this.props.currentlyOpenedCategory) && !window.__isAllowedBlankItemNumber &&
                                                            <li><span>*</span> Item Number</li>
                                                        }
                                                        {
                                                            cpnRules.useTwoVariableScheme ?
                                                                <>
                                                                    <li><span>*</span> Family </li>
                                                                    <li><span>*</span> Suffix </li>
                                                                </> 
                                                                : <li><span>*</span> Category <span className="custom-muted-text">(if not present, we will add it)</span></li>
                                                        }
                                                    </ul>
                                                </div>
                                                : null
                                            }

                                            {
                                                ["update", "update_assembly"].includes(this.state.fileType) ?
                                                <div>
                                                    <h4> Required Labels</h4>
                                                    <ul className="required-fields-list">
                                                        <li><span>*</span> CPN</li>
                                                        {
                                                            this.state.fileType === "update_assembly" &&
                                                            <li><span>*</span> Quantity</li>
                                                        }

                                                        {
                                                            this.state.fileType === "update_assembly" && Utils.isItemNumber(this.props.currentlyOpenedCategory) && !window.__isAllowedBlankItemNumber &&
                                                            <li><span>*</span> Item Number</li>
                                                        }

                                                    </ul>
                                                </div>
                                                : null
                                            }
                                        </div>

                                        <DropZone
                                            ref="dropZone"
                                            className="drop-zone"
                                            activeClassName="accept-files"
                                            rejectClassName="reject-files"
                                            onDrop={this.onFileDrop}
                                            // accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv, .xls"}
                                            accept={""}
                                            maxSize={maxFileSize}
                                            multiple={false}
                                            >
                                            <div className="expanded-block">
                                                <div className="expanded-holder">
                                                    <Icon src={largeSrc} />
                                                    <div>Drag File to Upload, </div>
                                                    or<a className="browse">Browse</a>
                                                </div>
                                                <p>(xlsx, csv)</p>
                                            </div>
                                            <div className="modal"></div>
                                        </DropZone>
                                    </div>
                                </div>

                                <div className="card-panel">
                                    <div className="card-panel-header">
                                        <h3>NEED A TEMPLATE?</h3>
                                    </div>
                                    <div className="card-panel-body last-panel">
                                        <p>
                                            Download one of the following spreadsheet templates
                                        </p>
                                        <p className="tmp-instruction">
                                            and follow the embedded instructions:
                                        </p>
                                        <p className="indent">
                                            <ul>
                                                <li>
                                                    <Link to={{ pathname: "https://s3-us-west-2.amazonaws.com/plm-assets/v1/import-templates/DURO-IMPORT-TEMPLATE-New-Components.xlsx" }} target="_blank">

                                                    <Icon src={downloadSrc} />
                                                    Creating New Components
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={{ pathname: "https://s3-us-west-2.amazonaws.com/plm-assets/v1/import-templates/DURO-IMPORT-TEMPLATE-Update-Components.xlsx" }} target="_blank">
                                                    <Icon src={downloadSrc} />
                                                    Update Existing Components
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={{ pathname: "https://s3-us-west-2.amazonaws.com/plm-assets/v1/import-templates/DURO-IMPORT-TEMPLATE-Update-Assembly.xlsx" }} target="_blank">
                                                    <Icon src={downloadSrc} />
                                                    Creating or Updating Assemblies
                                                    </Link>
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </PerfectScrollbar>
                            <div className="card-panel-footer">
                                <div className="left-section">
                                    <button
                                        className="dark"
                                        onClick={this.props.onClose}
                                        >
                                        CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            </div>
        return markup
    }
}

export default UploadScreen
