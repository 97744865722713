import { SuperComponent } from "design/models/changeorder";
import { getWindow } from "utils/window";
import schemas, { validateField } from "v1/modules/validations";
import {
  ClientModulesSchemaField,
  ClientModulesSchemaValidationResult,
  ValidationErrorType,
} from "../validation.types";

const companySettings = {
  isClient: true,
  revSchemeType: window.__revSchemeType,
  libraryType: window.__libraryType,
  defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions,
};

// Validates a revision field
export function validateRevision(
  item: SuperComponent
): ClientModulesSchemaValidationResult {
  const isClient =
    getWindow().__revSchemeType === "DEFAULT" &&
    item.previousStatus === "DESIGN" &&
    item.status === "PROTOTYPE" &&
    item.previousRevisionValue === item.legacyNextRevision
      ? false
      : true;

  const currentCompanySettings = {
    ...companySettings,
    revSchemeType: window.__revSchemeType,
  };

  // Utilize client-modules logic for revision validation
  return {
    type: ValidationErrorType.Revision,
    ...validateField(
      { value: item.legacyNextRevision },
      schemas.component.revision,
      item.legacyNextRevision,
      {
        ...currentCompanySettings,
        status: item.status,
        isClient,
        previousRevision: item.previousRevisionValue,
      }
    ),
  };
}

type validateRevisionAgainstStatusArgs = {
  nextRevision: string;
  currentRevision: ClientModulesSchemaField;
  currentStatus: string;
};

export function validateRevisionAgainstStatus({
  nextRevision,
  currentRevision,
  currentStatus: status,
}: validateRevisionAgainstStatusArgs): ClientModulesSchemaValidationResult {
  return validateField(
    currentRevision,
    schemas.product.revision,
    nextRevision,
    {
      status,
      ...companySettings,
    }
  );
}
