import { styled, Box, MenuItem, Select, SxProps } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

export const StatusContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

interface StatusDropdownProps {
  status: string;
  statuses: string[];
  disabledStatuses?: string[];
  onChange: (value: string) => void;
  isDisabled?: boolean;
  height?: number;
  sx?: SxProps;
}

export const StatusDropdown: React.FC<StatusDropdownProps> = ({
  status,
  statuses,
  disabledStatuses = [],
  onChange,
  isDisabled = false,
  height = 20,
  sx = {},
}) => {
  const [statusState, setStatusState] = useState<string>(status);
  const [statusDebounced] = useDebounce(statusState, 500);
  useEffect(() => {
    onChange(statusDebounced);
  }, [statusState]);
  return (
    <Select
      sx={{ height, ...sx }}
      value={status}
      onChange={(e) => setStatusState(e.target.value)}
      disabled={isDisabled}
    >
      {statuses.map((field) => (
        <MenuItem
          value={field}
          disabled={disabledStatuses.includes(field)}
          key={field}
        >
          {field}
        </MenuItem>
      ))}
    </Select>
  );
};
