import { createStore, Store } from "common/context/store";
import { ChangeOrderType, SuperComponent } from "design/models/changeorder";
import { ClientModulesSchemaValidationResult } from "../utils";
import { filterRows } from "./tree/tree.logic";
import { storage } from "../storage";

export type Modifications = {
  errors: Store<ClientModulesSchemaValidationResult[]>;
  reviewed: Store<boolean>;
  status: Store<string>;
  legacyNextRevision: Store<string>;
  originalStatus: string;
  originalRevision: string;
};

type EditorStateType = {
  cachedValidations: Record<string, ClientModulesSchemaValidationResult[]>;
  flags: {
    isReviewed: Store<boolean>;
    __forceRowIdUpdated: Store<boolean>;
    tableIsLoading: Store<boolean>;
  };
  rows: Store<SuperComponent[]>;
  rowsWithTree: Store<SuperComponent[]>;
  tree: Store<SuperComponent[]>;
  treeDict: Store<Record<string, SuperComponent>>;
  childrenIds: Store<string[]>;
  parentIds: Store<string[]>;
  childrenDisplayed: Store<Record<string, boolean>>;
  changeset: Map<string, Modifications>;
  pendingRows: Store<SuperComponent[]>;
  getChangeset(row: SuperComponent): Modifications;
};

const handleRowsUpdate = (state: SuperComponent[]) => {
  EditorState.rowsWithTree.setState(() =>
    filterRows(state, EditorState.tree.getState())
  );
};

const handleTreeChange = (state: SuperComponent[]) => {
  const reduced = state.reduce(
    (acc, item) => ({
      ...acc,
      [item.treeId]: item,
    }),
    {}
  );

  EditorState.treeDict.setState(reduced);
};

export const EditorState: EditorStateType = {
  cachedValidations: {},
  flags: {
    isReviewed: createStore(false),
    tableIsLoading: createStore(false),
    __forceRowIdUpdated: createStore(false),
  },
  childrenDisplayed: createStore({}),
  childrenIds: createStore<string[]>([]),
  parentIds: createStore<string[]>([]),
  pendingRows: createStore<SuperComponent[]>([]),
  rows: createStore<SuperComponent[]>([], handleRowsUpdate),
  rowsWithTree: createStore<SuperComponent[]>([]),
  tree: createStore<SuperComponent[]>([], handleTreeChange),
  treeDict: createStore<Record<string, SuperComponent>>({}),
  changeset: new Map(),
  getChangeset(row: SuperComponent) {
    const exists = EditorState.changeset.get(row.id);

    if (exists) {
      return exists;
    }

    const defaultChangeset = {
      errors: createStore<ClientModulesSchemaValidationResult[]>([]),
      reviewed: createStore(false),
      status: createStore(row.status),
      legacyNextRevision: createStore(row.legacyNextRevision),
      originalStatus: row.status,
      originalRevision: row.legacyNextRevision,
    };

    if (!exists) {
      EditorState.changeset.set(row.id, defaultChangeset);
    }

    return defaultChangeset;
  },
};

export function cloneChangeset() {
  const clonedChangeset = new Map<
    string,
    ReturnType<typeof EditorState["getChangeset"]>
  >();

  EditorState.changeset.forEach((value, key) => {
    // Deep clone each changeset object
    const clonedValue = {
      errors: createStore([...value.errors.getState()]), // Deep copy of the errors array
      reviewed: createStore(value.reviewed.getState()), // Copy the boolean value
      status: createStore(value.status.getState()), // Copy the status value
      legacyNextRevision: createStore(value.legacyNextRevision.getState()), // Copy the next revision
      originalStatus: value.originalStatus, // Primitive, direct copy
      originalRevision: value.originalRevision, // Primitive, direct copy
    };

    // Add the cloned value to the new changeset map
    clonedChangeset.set(key, clonedValue);
  });

  return clonedChangeset;
}

export function setClonedChangeset(
  clonedChangeset: Map<string, Modifications>
) {
  clonedChangeset.forEach((clonedValue, key) => {
    const originalValue = EditorState.changeset.get(key);

    if (originalValue) {
      // Update existing stores with cloned values, triggering reactivity
      originalValue.errors.setState([...clonedValue.errors.getState()]);
      originalValue.reviewed.setState(clonedValue.reviewed.getState());
      originalValue.status.setState(clonedValue.status.getState());
      originalValue.legacyNextRevision.setState(
        clonedValue.legacyNextRevision.getState()
      );
    } else {
      // If no existing value, set a new entry into the changeset
      EditorState.changeset.set(key, clonedValue);
    }
  });
}

export const resetEditorState = () => {
  EditorState.flags.isReviewed.setState(false);
  EditorState.rows.setState([]);
  EditorState.rowsWithTree.setState([]);
  EditorState.tree.setState([]);
  EditorState.treeDict.setState({});
  EditorState.changeset.clear();
  EditorState.pendingRows.setState([]);
};
