import "./index.scss";
import React, { Component } from "react";
import ModalBox             from "../../../../ui/modal-box";
import API                  from "../../../../../modules/api";
import LazyInput            from "../../../../ui/lazy-input/input.js";
import Documents            from "../../../../../components/page/common/documents/new"
import Schemas              from "../../../../../modules/schemas"
import validations, { validateField, schemas }  from '../../../../../modules/validations';
export default class McMasterLoginModal extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.message = "must contain atleast 1 character";
        this.state =
        {
            forceResetChildState: false,
            isConnectButtonEnable: false,
            inputs: {
                username: "",
                password: "",
                passphrase: "",
                certificateId: null
            },
            errorMessage: "",
            isEmpty: {username: "", password: "", passphrase: ""},
            isMessage: {username: "", password: "", passphrase: ""}
        }
        this.onClose = this.onClose.bind(this);
        this.connect = this.connect.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.toggleConnectButton = this.toggleConnectButton.bind(this);
        this.onDocumentChange = this.onDocumentChange.bind(this);
        this.invalidCertificateFormat = this.invalidCertificateFormat.bind(this);
    }

    componentDidMount()
    {
        document.addEventListener("click", this.handleOutsideClick, false);
    }

    componentWillUnmount()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    onClose()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
        this.props.onClose("displayMcMasterModal");
    }

    onInputChange(event)
    {
        let state = this.state;
        let {value, name} = event.target;
        let inputs = state.inputs;
        inputs[name] = value;
        let enableButton = this.toggleConnectButton();
        state.isConnectButtonEnable = enableButton;
        if(value === "")
        {
            state.isEmpty[name] = "invalid";
            state.isMessage[name] = this.message;
        }
        else
        {
            state.isEmpty[name] = "";
            state.isMessage[name] = "";
        }
        state.errorMessage = "";
        this.setState(state);
    }

    connect(event)
    {
        event.stopPropagation();
        let { inputs } = this.state;
        API.integrations.mcMasterLogin(inputs, (err, response) =>
        {
            if(err)
            {
                this.setState({ errorMessage: "Unable to connect, Invalid credentials" , isConnectButtonEnable: false});
                return;
            }
            this.props.showAlert("modal", "Connection Succeeded");
            this.onClose();
            this.props.getIntegrations();
            this.setState({ errorMessage: "" });
        });
    }

    toggleConnectButton(inputs=null)
    {
        let {username, password, passphrase, certificateId} = inputs ? inputs : this.state.inputs;
        let isEnable = true;
        if(username === "" || password === "" || passphrase === "" || certificateId === null) isEnable=false;
        return isEnable;
    }

    handleOutsideClick(e)
    {
        if(!this.node.contains(e.target) || e.target.classList.contains("background")) this.onClose();
    }

    onDocumentChange(event, payload)
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
        let { inputs } = this.state;
        payload = {target: { value: payload, name: event.target.name}};
        if (payload.target && payload.target.value && payload.target.value.documents[0] && payload.target.value.documents[0].file)
        {
            inputs.certificateId = payload.target.value.documents[0].file;
            this.setState({ inputs, errorMessage: "", isConnectButtonEnable: this.toggleConnectButton(inputs) });
        }
        else if(payload.target && payload.target.value && payload.target.value.documents.length === 0 && inputs.certificateId)
        {
            inputs.certificateId = null;
            this.setState({ inputs,  errorMessage: "", isConnectButtonEnable: this.toggleConnectButton(inputs) });
        }
        document.addEventListener("click", this.handleOutsideClick, false);
    }

    invalidCertificateFormat()
    {
        this.setState({ errorMessage: "Invalid certificate! Only .pfx is allowed." , isConnectButtonEnable: false});
    }

    render()
    {
        let state = this.state;
        let {username, password, passphrase} = state.inputs;
        let { isConnectButtonEnable, errorMessage, isEmpty, isMessage } = state;
        let connectClass = `connect ${isConnectButtonEnable ? "" : "disable"}`;
        let markup =
        <div ref={node => { this.node = node;}}>
            <ModalBox onClose={this.onClose}>
                <div className="mcmaster-form-block">
                    <h1>McMaster</h1>
                    <div className="field-block">
                        <label htmlFor="username">Username *</label>
                        <LazyInput
                            type="text"
                            name="username"
                            className={isEmpty["username"]}
                            value={username}
                            data-tip={isMessage["username"]}
                            data-place="right"
                            data-type="error"
                            onChange={(e) => this.onInputChange(e)}
                        />

                        <label htmlFor="password">Password *</label>
                        <LazyInput
                            type="password"
                            name="password"
                            className={isEmpty["password"]}
                            value={password}
                            data-tip={isMessage["password"]}
                            data-place="right"
                            data-type="error"
                            onChange={(e) => this.onInputChange(e)}
                        />

                        <label htmlFor="passphrase">Certificate Passphrase *</label>
                        <LazyInput
                            type="password"
                            name="passphrase"
                            className={isEmpty["passphrase"]}
                            value={passphrase}
                            data-tip={isMessage["passphrase"]}
                            data-place="right"
                            data-type="error"
                            onChange={(e) => this.onInputChange(e)}
                        />

                        <label htmlFor="certificate">UPLOAD McMaster Certificate FILE *</label>
                        <div className="upload-metadata-block">
                            <Documents
                                name="documents"
                                onChange={this.onDocumentChange}
                                clientSchema={validations.component.documents}
                                documentSchema={Schemas.component.documents}
                                forceResetChildState={this.state.forceResetChildState}
                                invalidFileFormatError={this.invalidCertificateFormat}
                                mcmasterCertificate={true}

                            />
                        </div>
                    </div>
                    {
                        errorMessage &&
                        <p>{errorMessage}
                        </p>
                    }
                    <div className="btn-holder">
                        <div className="btn-container">
                            <button
                                className={connectClass}
                                onClick={isConnectButtonEnable ? this.connect : function(){}}
                                >
                                Connect
                            </button>
                            <button
                                className="cancel"
                                onClick={this.onClose}
                                >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </ModalBox>
        </div>
        return markup;
    }
}
