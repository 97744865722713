import { datadogRum } from "@datadog/browser-rum";
import { FullStory, init } from "@fullstory/browser";
import { userById } from "graphql/query/userQueries";
import { User } from "./models";
import mixpanel from "common/mixpanel"
import config from "../v1/modules/config";

/**
 * Sets the user information in the analytics, or clears it if no user passed in.
 *
 * @param user The user to assign to the current session.
 */
function setUserForAnalytics(user?: User) {
  const isValidUser = typeof user?.id === "string";
  if (window.DURO_ENV?.datadog) {
    if (isValidUser) {
      datadogRum.setUser({
        company: user.primaryCompany?.id,
        email: user.email,
        id: user.id,
        role: user.role,
      });
    }
    else {
      datadogRum.clearUser();
    }
  }

  if (isValidUser && window.DURO_ENV?.mixpanel) {
    mixpanel.init(window.DURO_ENV.mixpanel.token, {
      debug: config.LOG_MIXPANEL,
      track_pageview: true,
      persistence: 'localStorage'
    })
    mixpanel.identify(user.id)
    mixpanel.setPeople(user)
  }

  if (isValidUser && window.DURO_ENV?.fullStory) {
    FullStory("setIdentity", {
      uid: user.id,
      properties: {
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        role: user.role,
        companyId: user.primaryCompany?.id,
        companyName: user.primaryCompany?.name,
      },
    });
  }

  userById.onNextChange(setUserForAnalytics);
}

// Setup DataDog RUM
if (window.DURO_ENV?.datadog) {
  datadogRum.init(window.DURO_ENV.datadog);
}

// Setup FullStory
if (window.DURO_ENV?.fullStory) {
  init({
    devMode: !!window.DURO_ENV.fullStory.devMode,
    orgId: window.DURO_ENV.fullStory.ordId,
  });
}

// Make sure that the user is set for analytics when the user changes
userById.onNextChange(setUserForAnalytics);
