import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, styled } from "@mui/material";
import { AutofitIcon, FilterIcon, SerializationIcon } from "assets/icons";
import { SerializationModal } from "build/components/serializationModal";
import { Lot, LotRevision } from "build/models";
import { EmptyTable, EmptyTableStyle, Grid, ToolBarItem, useGridHeight } from "common/components/grid";
import { Link } from "common/components/link";
import { ToolbarItemType } from "common/constants";
import { ModelType, PageItemType, PageMode } from "@duro/utils";
import { COLUMN_DEFAULTS } from "design/utils/columnDefaults";
import { useComponentRevisionWithLots } from "graphql/query/componentRevisionQueries";
import { useProductRevisionsWithLots } from "graphql/query/productRevisionQueries";
import { privatePaths } from "v1/app/routes";
import {
  instancesCountValueGetter, lotLabelValueGetter, lotCreatedDateValueGetter, lotDueDateValueGetter,
  lotCreatedDateRenderCell, lotDueDateRenderCell, lotRenderCell, LotNotesRenderCell,
} from ".";
import { colorPalette } from "@duro/themes";
import { useComponentWithLots } from "graphql/query/componentsQueries";
import { useProductsWithLots } from "graphql/query/productsQueries";

interface IBuildTabProps {
  id: string;
  cpn: string;
  gridName: string;
  mode: string;
  lastReleasedRev?: string;
  modified?: boolean;
}

interface LotTabRow {
  id: string,
  item: {
    lot: {
      createdDate: string,
      dueDate: string,
      instanceCount: number,
      labels: string,
      notes: string,
      number: {
        displayValue: string,
      },
    },
    cpn: {
      displayValue: string,
    },
    name: string,
    revisionValue: string,
    status: string,
  }
}

const footer = (
  <>
    Read our
    <Link
      customStyle={{ color: colorPalette.purple }}
      to={privatePaths.docs.quickstart}
      target="_blank"> Quick Start Guide
    </Link> and
    <Link
      to={privatePaths.docs.documentation}
      customStyle={{ color: colorPalette.purple }}
      target="_blank" > Documentation
    </Link > to get up and running.
  </>
);

const emptyTableStyle: EmptyTableStyle = {
  iconStyle: {
    color: colorPalette.purple,
    background: colorPalette.purple,
  },
  buttonStyle: {
    background: colorPalette.purpleDark,
    hoverBackground: colorPalette.purple,
    borderColor: colorPalette.purple,
  },
};

export const DEFAULT_TABLE_HEIGHT = 285;

export const BuildTab: FC<IBuildTabProps> = ({ id, cpn, gridName, lastReleasedRev, mode, modified }) => {
  const [data, setData] = useState([]);
  const [displaySerializationModal, setDisplaySerializationModal] = useState(false);

  const { fetchCmpWithLots, cmpLotsData, cmpLotLoading } = useComponentWithLots();
  const { fetchCmpRevWithLots, cmpRevLotsData, cmpRevLotLoading } = useComponentRevisionWithLots();
  const { fetchPrdWithLots, prdLotsData, prdLotLoading } = useProductsWithLots();
  const { fetchPrdRevWithLots, prdRevLotsData, prdRevLotLoading } = useProductRevisionsWithLots();

  useEffect(() => {
    switch (gridName) {
      case PageItemType.PRODUCT:
        if (mode === PageMode.REVISION) {
          fetchPrdRevWithLots([id]);
        }
        else {
          fetchPrdWithLots([id]);
        }
        break;
      case PageItemType.COMPONENT:
        if (mode === PageMode.REVISION) {
          fetchCmpRevWithLots([id]);
        }
        else {
          fetchCmpWithLots([id]);
        }
        break;
    }
  }, [fetchCmpRevWithLots, fetchCmpWithLots, fetchPrdRevWithLots, fetchPrdWithLots, gridName, id, mode]);

  useEffect(() => {
    if (
      !prdLotLoading
      && !cmpRevLotLoading
      && !prdRevLotLoading
      && !cmpLotLoading
      && (prdLotsData || cmpLotsData || cmpRevLotsData || prdRevLotsData)
    ) {
      let lotsData: any;
      switch (gridName) {
        case PageItemType.PRODUCT:
          if (mode === PageMode.REVISION) {
            lotsData = prdRevLotsData;
          }
          else {
            lotsData = prdLotsData;
          }
          break;
        case PageItemType.COMPONENT:
          if (mode === PageMode.REVISION) {
            lotsData = cmpRevLotsData;
          }
          else {
            lotsData = cmpLotsData;
          }
          break;
      }

      const tableData = lotsData?.build.lots
        ?.map(({ revisions, ...lot }: Lot & { revisions: LotRevision[] }, index: number) => {
          const revData = revisions.find(lotRev => (lotRev.cpn === cpn));
          return ({
            id: index,
            item: {
              ...revData,
              lot,
              cpn: {
                displayValue: revData?.cpn,
              },
              status: lot.status,
            },
          });
        });
      setData(tableData);
    }
  }, [
    cmpLotLoading,
    cmpLotsData,
    cmpRevLotLoading,
    cmpRevLotsData,
    cpn,
    gridName,
    id,
    mode,
    prdLotLoading,
    prdLotsData,
    prdRevLotLoading,
    prdRevLotsData,
  ]);

  const gridHeight = useGridHeight(data.length, DEFAULT_TABLE_HEIGHT);

  const columns = useMemo(() => (
    [{
      editable: false,
      field: "lot",
      headerName: "Lot",
      hideable: false,
      minWidth: 71,
      position: 0,
      renderCell: lotRenderCell,
      sortable: true,
      width: 87,
    },
    {
      editable: false,
      field: "instances",
      headerName: "Instances",
      hideable: true,
      minWidth: 90,
      position: 4,
      sortable: true,
      valueGetter: instancesCountValueGetter,
      width: 122,
    },
    {
      ...COLUMN_DEFAULTS.cpn,
      renderCell: undefined,
    },
    COLUMN_DEFAULTS.name,
    {
      editable: false,
      field: "lotNotes",
      headerName: "Lot Notes",
      hideable: false,
      minWidth: 150,
      position: 0,
      renderCell: LotNotesRenderCell,
      sortable: true,
      width: 250,
    },
    {
      ...COLUMN_DEFAULTS.revision,
      headerName: "Rev",
      minWidth: 79,
      width: 89,
    },
    COLUMN_DEFAULTS.status,
    {
      editable: false,
      field: "label",
      headerName: "Label",
      hideable: false,
      minWidth: 71,
      position: 0,
      valueGetter: lotLabelValueGetter,
      sortable: true,
      width: 159,
    },
    {
      editable: false,
      field: "createdDate",
      headerName: "Create Date",
      hideable: true,
      minWidth: 90,
      position: 4,
      renderCell: lotCreatedDateRenderCell,
      sortable: false,
      valueGetter: lotCreatedDateValueGetter,
      width: 200,
    },
    {
      editable: false,
      field: "dueDate",
      headerName: "Due Date",
      hideable: true,
      minWidth: 90,
      position: 4,
      renderCell: lotDueDateRenderCell,
      sortable: false,
      valueGetter: lotDueDateValueGetter,
      width: 188,
    }]
  ), []);

  const toolbarItems = useMemo<ToolBarItem[]>(() => [
    {
      disabled: true,
      Icon: FilterIcon,
      label: "Filter",
      type: ToolbarItemType.ACTION,
    },
    {
      disabled: true,
      Icon: AutofitIcon,
      label: "Autofit",
      type: ToolbarItemType.ACTION,
    },
  ], []);

  const toggleSerializationModal = useCallback(() => (
    setDisplaySerializationModal((displayModal: boolean) => !displayModal)
  ), []);

  const onSubmitSerializationModal = useCallback(() => {
    setDisplaySerializationModal(false);
  }, []);

  const getPayloadForSerialization = useMemo(() => {
    const alias = gridName === PageItemType.COMPONENT
      ? ModelType.CMP
      : ModelType.PRD;

    return {
      id: lastReleasedRev,
      alias,
    };
  }, [gridName, lastReleasedRev]);

  const EmptyTableTile = useMemo(() => ([
    {
      buttonLabel: "Serialize",
      customStyle: emptyTableStyle,
      Icon: SerializationIcon,
      onClick: () => setDisplaySerializationModal(true),
      text: "Serialize and Create New Lot",
      disableButton: modified,
    },
  ]), [modified]);

  const noRowsOverlayProps = useMemo(() => ({ tiles: EmptyTableTile, footer }), [EmptyTableTile]);

  const totalInstanceCount = useMemo(() => `${data.length} LOTS, ${data.reduce((total: number, row: LotTabRow) =>
    total + row.item.lot.instanceCount, 0)} INSTANCES`, [data]);

  return (
    <>
      <TableWrapper height={gridHeight}>
        <Grid
          columnDefinition={columns}
          data={data}
          loading={cmpLotLoading || cmpRevLotLoading || prdLotLoading || prdRevLotLoading}
          name={gridName}
          noRowsOverlay={EmptyTable}
          noRowsOverlayProps={noRowsOverlayProps}
          toolbarItems={toolbarItems}
          totalCount={totalInstanceCount}
        />
      </TableWrapper>
      {displaySerializationModal && (
        <SerializationModal
          itemToBeSerialized={getPayloadForSerialization}
          onCancel={toggleSerializationModal}
          onClose={toggleSerializationModal}
          onSubmitCallback={onSubmitSerializationModal}
          open={displaySerializationModal}
          title="Serialize and Create New Lot"
        />
      )}
    </>
  );
};

const TableWrapper = styled(Box)<{ height: number }>(({ height }) => ({
  display: "flex",
  flex: 1,
  height: `${height / 16}rem`,
  maxHeight: "calc(100vh - 150px)",
}));
