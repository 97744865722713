export const removeKeys = (arr: any[], keyToRemove: string) => {
  return arr.map((obj) => removeKey(obj, keyToRemove));
};

export function removeKey(obj: Record<string, any>, keyToRemove: string) {
  const result: typeof obj = {};

  return Object.keys(obj).reduce((acc, key) => {
    const isArray = Array.isArray(obj[key]);

    if (typeof obj[key] === "object" && obj[key] != null && !isArray) {
      acc[key] = removeKey(obj[key], keyToRemove);
    } else if (key !== keyToRemove) {
      acc[key] = obj[key];
    }
    return acc;
  }, result);
}
