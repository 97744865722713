import "./index.scss";
import React, {Component}   from "react";
import Icon                 from "../../../../ui/icon";
import closeSrc             from "../../../../../assets/icons/close.svg";
import SvgIcon              from "../../../../../assets/icons/download-report";
import CancelDownloadModal  from "./cancel-download-modal";
import TickIcon             from "../../../../../assets/icons/tick-icon";
import LoadingIcon          from "../../../../../assets/icons/loading";
import InlineIcon           from "../../../../ui/icon/inline-icon.js";
import Utils                from "../../../../../modules/utils";

export class ExportNotification extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            displayCancelDownloadModal: false,
            totalFiles: 0,
            estimatedTime: 0,
            status:"",
            file:"",
            delay: false,
            type: "specifications"
        }

        this.closeCancelDownloadModal = this.closeCancelDownloadModal.bind(this);
        this.showCancelDownloadModal  = this.showCancelDownloadModal.bind(this);
        this.closeNotificationModal   = this.closeNotificationModal.bind(this);
        this.renderDownloadOption     = this.renderDownloadOption.bind(this);
        this.decrement                = this.decrement.bind(this);
        this.delay                    = this.delay.bind(this);
    }


    componentDidMount()
    {
        let userId  = this.props.user._id;
        let channel = this.props.pusher.subscribe(userId+this.props.jobId);
        let _this   = this;
        channel.bind('export', data =>
        {
            let delay = false;
            if(data.status === "Completed" && _this.state.estimatedTime > 9)
            {
                delay = true;
                data.estimatedTime = 9;
                data.status = "In-Progress";
            }
            this.setState({
                totalFiles: data.numberOfFiles,
                estimatedTime: Math.round(data.estimatedTime),
                status: data.status,
                file: data.file,
                delay: delay,
                type: data.type
            });
        });
    }

    componentWillUnmount()
    {
        this.props.pusher.unsubscribe(this.props.user._id+this.props.jobId);
    }

    closeCancelDownloadModal(modalName, modalValue, buttonValue=false)
    {
        this.setState({[modalName]: modalValue});
        if(buttonValue){
            this.props.pusher.unsubscribe(this.props.user._id+this.props.jobId);
            this.closeNotificationModal();
        }
    }

    showCancelDownloadModal(modalName)
    {
         this.setState({displayCancelDownloadModal: true});
    }

    closeNotificationModal()
    {
        this.props.toggleNotificationModal("displayNotificationModal", false);
    }

    calculateTime()
    {
        let time = this.state.estimatedTime;
        let secMinORHours = Utils.convertSecToMinAndHours(time);
        let estimatedTime = time < 10 ? "few seconds left... " : (secMinORHours + " left... ");
        return {time, estimatedTime};
    }

    renderDownloadOption(completed, InProgress)
    {
        let totalFiles = this.state.totalFiles;
        return (
        <div className="notification-download">
            <div className="notification-icon">
                <SvgIcon/>
            </div>
            {
                this.state.type === "all" ?
                <div className="notification-files">
                {completed ? "Zipped" : "Zipping"} <span className="files-remaining">{!InProgress && !completed ? "" : totalFiles}</span> {totalFiles === 1 ? "component" : "components"}
                </div>
                : completed ?
                    <div className="notification-files">
                        Prepared specifications
                    </div>
                    :
                    InProgress ?
                        <div className="notification-files">
                            Preparing specifications
                        </div>
                        :
                            <div className="notification-files">
                                Preparing
                            </div>
            }
        </div>);
    }

    decrement()
    {
        let state = this.state;
        state.estimatedTime--;
        if(state.estimatedTime >= 9)
        {
            this.setState(state);
        }
    }

    delay()
    {
        this.setState({estimatedTime: 2, delay: false, status: "Completed", file: ""});
        this.closeNotificationModal();
    }

    render()
    {
        let {status, file, delay} = this.state;
        let {time, estimatedTime} = this.calculateTime();
        let Completed  = (status === "Completed");
        let InProgress = (status === "In-Progress");
        let interval   = setTimeout(this.decrement, 1000);
        if(file && window.__tabId === this.props.tabId)
        {
            window.location.href = file;
        }
        if(time < 10)
        {
            clearTimeout(interval);
        }
        if(delay || file)
        {
            interval   = setTimeout(this.delay, 3000);
        }

        return (
        <div className="export-modals-holder">
        {
            this.state.displayCancelDownloadModal ?
            <div className="cancel-download-modal">
                <CancelDownloadModal
                closeCancelDownloadModal={this.closeCancelDownloadModal}/>
                </div>
                : null
            }
            <div className="export-notification-modal">
                <div className="notification-holder">
                    <div className="notification-heading">
                        {Completed ? "Download Ready" : "Preparing Download"}
                        <div className="buttons modal-close">
                            <Icon src={closeSrc}
                            onClick={Completed ? this.closeNotificationModal : this.showCancelDownloadModal}/>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="notification-loading">
                        <div className="notification-loading-area">
                        {
                            this.renderDownloadOption(Completed, InProgress)
                        }
                        </div>
                        <div className="notification-progress-area">
                        {
                            Completed ?
                            <div className="notification-progress-icon">
                                <TickIcon/>
                            </div>
                            :
                            <div className="notification-progress-time">
                                <div className="notification-progress">
                                    <span className="minutes-remaining">{!InProgress ? "processing... " : estimatedTime}</span>
                                    <span className="cancel-link"
                                    onClick={this.showCancelDownloadModal}>cancel</span>
                                </div>
                                <div className="notification-loader">
                                    <InlineIcon>
                                        <LoadingIcon />
                                    </InlineIcon>
                                </div>
                            </div>
                        }

                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default ExportNotification
