import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { Box, Tooltip, Typography, styled } from "@mui/material";
import { CustomDuroCheckbox } from "common/components/checkbox";
import { InputField, Select } from "common/components/inputs";
import { FormModal } from "common/components/modals";
import { Role } from "common/constants";
import { DEFAULT_USER_GROUPS } from "v1/modules/constants";
import { useUser } from "../../../../../../../design/pages/component/hooks";
import USER_FORM from "../../../../../../action-types/user_form";
import buildAction from "../../../../../../helpers/buildAction";
import Schemas from "../../../../../../modules/schemas";
import Utils from "../../../../../../modules/utils";

export const CHECKBOX_FONT_SIZE = "1.3rem";
export const CHECKBOX_LABEL_STYLE = { marginLeft: "0.6rem" };
export const DEBOUNCE_TIME = 300;
export const FORM_MODAL_STYLE = { width: "47.5rem" };
export const USER_MODAL_TITLE = {
  NEW_USER: "New User",
  EDIT_USER: "Edit User",
};
export const SELECT_STYLE = { width: "100%" };

interface IUserFormView {
  afterSubmitSuccessCb?: (data: any) => void,
  fromSandbox: boolean,
  groupName?: string,
  mode: string,
  onCancel: () => void,
}

export const UserFormView = ({
  afterSubmitSuccessCb,
  fromSandbox,
  groupName,
  mode,
  onCancel: propOnCancel,
}: IUserFormView) => {
  const dispatch = useDispatch();
  const userFormStore = useSelector((state: any) => state.userForm);
  const company = useSelector((state: any) => state.company);

  const inputs = userFormStore?.inputs;
  const [groups, setGroups] = useState(groupName
    ? [groupName.toUpperCase()]
    : userFormStore?.inputs.groups.value);

  const user = useUser();
  const ModalTitle = useMemo(
    () =>
      (mode === "edit" ? USER_MODAL_TITLE.EDIT_USER : USER_MODAL_TITLE.NEW_USER),
    [mode],
  );

  const onInputChange = useMemo(() => (
    debounce(event => {
      Utils.persistCursor(event, event.target.value);
      const { name, value } = event.target;
      dispatch(buildAction(USER_FORM.UPDATE_INPUT_ON_CHANGE, { name, value }));
    }, DEBOUNCE_TIME)
  ), [dispatch]);

  const debouncedInputChange = useCallback(event => (
    onInputChange(event)), [onInputChange]);

  const haveGroup = useCallback(currentGroup => groups.includes(currentGroup.toUpperCase()), [groups]);

  const setSubmitCb = useCallback((data = null) => {
    dispatch(buildAction(USER_FORM.RESET_FORM));
    propOnCancel();

    if (afterSubmitSuccessCb) {
      afterSubmitSuccessCb(data);
    }
  }, [afterSubmitSuccessCb, dispatch, propOnCancel]);
  const onSubmit = useCallback(event => {
    event.preventDefault();
    const data = Utils.fromInputsToValues({ ...userFormStore?.inputs });
    if (
      userFormStore?.user.superAdmin
      && Schemas.helpers.isSuperAdminUser(userFormStore?.user, user)
    ) {
      data.role = "ADMINISTRATOR";
    }

    const payload: any = { data };
    payload.successCb = setSubmitCb();

    if (mode === "edit") {
      payload.update = true;
      payload.userId = userFormStore.user._id;
    }
    if (fromSandbox) {
      payload.fromSandbox = true;
    }
    dispatch(buildAction(USER_FORM.SUBMIT_USER_FORM, payload));
  }, [
    dispatch,
    fromSandbox,
    mode,
    setSubmitCb,
    user,
    userFormStore?.inputs,
    userFormStore?.user,
  ]);

  const changeGroupsValue = useCallback(event => {
    const updatedGroups = [...groups];
    const value = event.target.value.toUpperCase();
    if (event.target.checked) {
      updatedGroups.push(value);
    }
    else {
      updatedGroups.splice(groups.indexOf(value), 1);
    }
    setGroups(updatedGroups);

    dispatch(buildAction(USER_FORM.UPDATE_INPUT_ON_CHANGE, {
      name: "groups",
      value: updatedGroups,
    }));
  }, [dispatch, groups]);

  const onCancel = useCallback(event => {
    event.preventDefault();
    dispatch(buildAction(USER_FORM.RESET_FORM));
    propOnCancel();
  }, [dispatch, propOnCancel]);

  const allowedUserRolesCallback = useCallback(
    () =>
      Utils.getAllowedUserRoles(company),
    [company],
  );

  const isDisabledForSiteAdmin = useMemo(() => {
    if (!userFormStore?.user) {
      return false;
    }

    const isSuperAdmin = userFormStore.user.superAdmin;
    const isAdminUser = userFormStore.user._id === user._id && userFormStore.user.role !== Role.ADMINISTRATOR;

    if (
      !isSuperAdmin
      || (isSuperAdmin && Schemas.helpers.isSuperAdminUser(userFormStore.user, user))
      || isAdminUser) {
      return false;
    }
    return true;
  }, [user, userFormStore]);

  const allowedUserRoles = useMemo(() => (user.role !== Role.ADMINISTRATOR
    ? allowedUserRolesCallback().filter((item: any) => item !== Role.ADMINISTRATOR)
    : allowedUserRolesCallback()), [allowedUserRolesCallback, user.role]);

  const disableSubmit = inputs && Object.keys(inputs).some((key: string) => {
    const isRequiredField = ["email", "firstName", "lastName", "title", "role"].includes(key);
    const hasErrorMessage = !!inputs[key].message;
    const isEmptyValue = key !== "title" && !inputs[key].value;
    return isRequiredField && (hasErrorMessage || isEmptyValue);
  });

  if (!inputs) return null;

  return (
    <FormModal
      disableSubmit={disableSubmit}
      modalProps={FORM_MODAL_STYLE}
      onCancel={onCancel}
      onClose={onCancel}
      onSubmit={onSubmit}
      open={true}
      title={ModalTitle}
    >
      <InputFieldWrapper>
        <Box>
          <Tooltip
            arrow
            placement="top"
            title={inputs.firstName.message}
          >
            <InputField
              defaultValue={inputs.firstName.value ?? ""}
              disabled={isDisabledForSiteAdmin}
              error={inputs.firstName.message}
              isRequired={true}
              label="First Name"
              name="firstName"
              onChange={debouncedInputChange}
              placeholder="First Name"
            />
          </Tooltip>
        </Box>
        <Box>
          <Tooltip
            arrow
            placement="top"
            title={inputs.lastName.message}
          >
            <InputField
              defaultValue={inputs.lastName.value ?? ""}
              disabled={isDisabledForSiteAdmin}
              error={inputs.lastName.message}
              isRequired={true}
              label="Last Name"
              name="lastName"
              onChange={debouncedInputChange}
              placeholder="Last Name"
            />
          </Tooltip>
        </Box>
      </InputFieldWrapper>
      <InputFieldWrapper>
        <Box>
          <Tooltip
            arrow
            placement={"top"}
            title={inputs.email.message}
          >
            <InputField
              defaultValue={inputs.email.value ?? ""}
              disabled={isDisabledForSiteAdmin}
              error={inputs.email.message}
              isRequired={true}
              label="Email"
              name="email"
              onChange={debouncedInputChange}
              placeholder="Email"
            />
          </Tooltip>
        </Box>
        <Box>
          <Tooltip
            arrow
            title={inputs.role.message}
            placement="right"
          >
            <Select
              componentStyles={ SELECT_STYLE }
              defaultValue={inputs.role.value ?? Role.USER}
              disabled={isDisabledForSiteAdmin}
              isRequired={false}
              label="Role *"
              name="role"
              onChange={debouncedInputChange}
              options={allowedUserRoles}
            />
          </Tooltip>
        </Box>
      </InputFieldWrapper>
      <InputFieldWrapper>
        <Box>
          <Tooltip
            arrow
            title={inputs.title.message}
            placement="right"
          >
            <InputField
              defaultValue={inputs.title.value ?? ""}
              disabled={isDisabledForSiteAdmin}
              error={inputs.title.message}
              label="Title"
              name="title"
              onChange={debouncedInputChange}
              placeholder="Job Title"
            />
          </Tooltip>
        </Box>
      </InputFieldWrapper>
      <GroupsWrapper>
        <Typography>Include in the following groups</Typography>
        <GroupsCheckBoxWrapper>
          {DEFAULT_USER_GROUPS.map(group => (
            <GroupItem key={group}>
              <CustomDuroCheckbox
                checkBoxLabel={group}
                disabled={isDisabledForSiteAdmin}
                checked={haveGroup(group)}
                value={group}
                checkboxFontSize={CHECKBOX_FONT_SIZE}
                labelStyles={CHECKBOX_LABEL_STYLE}
                onChange={changeGroupsValue} />
            </GroupItem>
          ))}
        </GroupsCheckBoxWrapper>
      </GroupsWrapper>
    </FormModal>
  );
};

export const InputFieldWrapper = styled(Box)({
  display: "grid",
  gridGap: "1.6rem",
  gridTemplateColumns: "48% 48%",
  marginBottom: "1rem",
});

export const GroupItem = styled(Box)({
  alignItems: "center",
  display: "flex",
  marginBottom: "0.5rem",
  marginRight: "0.8rem",
});

export const GroupsWrapper = styled(Box)({
  marginBottom: "1rem",
  position: "relative",
});

export const GroupsCheckBoxWrapper = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "0.5rem",
});
