import {
  Box,
  styled,
  Typography,
  TextField,
  TextFieldProps,
  InputAdornment,
} from "@mui/material";

import { Search } from "@mui/icons-material";
import { ChangeOrderLayoutConfig } from "features/changeorders/constants";
import { FC } from "react";
import { colorPalette } from "components/themes";

export const SearchField: React.FC<TextFieldProps> = (props) => (
  <TextField
    {...props}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Search sx={{ color: colorPalette.taupeGray, fontSize: "18px" }} />
        </InputAdornment>
      ),
    }}
  />
);

export const SearchContainer = styled(Box)({
  "& input": {
    fontSize: "0.8rem",
  },
});

export const TableContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flex: 1,
});

export const AddComponentsBar = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "> *": {
    width: "100%",
    padding: "0 1.125rem",
  },
  "&:first-of-type": {
    paddingBottom: "0.5rem",
  },
});

export const LeadingText = styled(Typography)({
  margin: "10px 0",
  fontSize: "19px",
});

const FlexBox = styled(Box)({
  display: "flex",
});

export type SidebarTableHeaderProps = {
  count: number;
  selected: number;
};

export const SidebarTableHeader: FC<SidebarTableHeaderProps> = ({
  count,
  selected,
  children,
}) => {
  return (
    <ResultCountWrapper>
      {children}
      <SelectedCount>
        {selected} Selected of {count}
      </SelectedCount>
    </ResultCountWrapper>
  );
};

export const ManageTemplatesContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 1,
  flexGorw: 1,
});

const ResultCountWrapper = styled("div")`
  color: #888;
  font-size: 14px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`;

const SelectedCount = styled("span")`
  min-width: 90px;
  text-align: right;
`;

export const SidebarWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
});
