import { useEffect, useMemo, useState } from "react";
import { OrderByType } from "../query/componentsQueries";
import { useHistory } from "react-router-dom";
import { parseSearchString } from "design/utils/search";
import { useLocation } from "react-router-dom";
import {
  ApolloError,
  DocumentNode,
  FetchPolicy,
  useLazyQuery,
} from "@apollo/client";
import { gatewayClient } from "graphql/gatewayClient";

export type GQLQueryCursor = {
  start?: string;
  end?: string;
};

export interface GenericListResponse<RowModel> {
  [key: string]: GenericListData<RowModel>;
}

export interface GenericListData<RowModel> {
  connection: GenericConnection<RowModel>;
  __typename: string;
}

export interface GenericConnection<RowModel> {
  edges: GenericEdge<RowModel>[];
  pageInfo: PageInfo;
  totalCount: number;
  __typename: string;
}

export interface GenericEdge<RowModel> {
  node: RowModel;
}

export interface PageInfo {
  hasNextPage: boolean;
  endCursor?: string;
  __typename?: string;
}

export type VirtualListSerializer<RowModel> = (
  edges: GenericEdge<RowModel>[]
) => RowModel[];

export type VirtualListArgs<RowModel> = {
  endCursor?: string;
  fetchPolicy?: FetchPolicy;
  libraryType?: string;
  orderBy?: OrderByType;
  pageSize?: number;
  queryKey: string;
  serializeEdges?: VirtualListSerializer<RowModel>;
  connectionKey?: string;
  searchString?: string;
};

export type VListState<RowModel> = {
  data: RowModel[];
  cursor: GQLQueryCursor;
  inResetState: boolean;
  pageInfo: PageInfo;
  totalCount: number;
  loading: boolean;
  error: ApolloError | undefined;
};

export type VirtualListStateGenerator = <RowModel>() => VListState<RowModel>;

const initialState = {
  data: [],
  cursor: {},
  inResetState: true,
  pageInfo: {
    hasNextPage: false,
    endCursor: undefined,
  },
  totalCount: 0,
  loading: false,
  error: undefined,
};

/**
 * Generic GraphQL virtualized list hook.
 */
export const useVirtualList = <RowModel>(
  args: VirtualListArgs<RowModel>,
  query: DocumentNode
) => {
  if (!args?.connectionKey) {
    throw new Error("connectionKey is required for virtual list");
  }

  const connectionKey = args.connectionKey;
  const [state, setState] = useState<VListState<RowModel>>(initialState);
  const history = useHistory();
  const location = useLocation();
  const {
    fetchPolicy = "cache-first",
    libraryType,
    pageSize = 30,
    orderBy,
  } = args;

  const [loadData, response] = useLazyQuery<GenericListResponse<RowModel>>(
    query,
    {
      fetchPolicy,
    }
  );

  useEffect(() => {
    const connection = response.data?.[connectionKey]?.connection;
    const edges: GenericEdge<RowModel>[] = connection?.edges || [];
    const serializedEdges = args.serializeEdges
      ? args?.serializeEdges(edges)
      : edges.map((e) => e.node);

    // if (!response.error && !response.loading && connection) {
    /**
     * NOTE: This is a client side fix for an api bug that is returning children and errors in the
     * same response. Instead of checking for response.error, we check for response.data
     *
     * API: https://github.com/durolabs/duro-platform-services/blob/main/apis/core/src/util/revisionRules.ts#L185
     * In case it has changed, it is this code on line L185:
     * ```
     * if (delimiter && !foundDelimiter) {
     *    throw new GraphQLError("Delimiter missing");
     * }
     * ```
     */
    if (response.loading && !state.loading) {
      setState((prev) => ({ ...prev, loading: true }));
    } else if (!response.loading && connection && response.data) {
      setState((prev) => ({
        totalCount: connection?.totalCount,
        pageInfo: connection?.pageInfo,
        cursor: {
          start: prev.cursor.end ?? connection.pageInfo?.endCursor,
          end: connection.pageInfo?.endCursor,
        },
        data: [...prev.data, ...serializedEdges],
        inResetState: false,
        error: response.error || prev.error,
        loading: response.loading,
      }));
    }
  }, [response]);

  // Fires the gql query
  const loadMoreData = async () => {
    loadData({
      variables: {
        startCursor: state.cursor.start,
        endCursor: state.cursor.end,
        libraryType: libraryType,
        orderBy: orderBy,
        pageSize: pageSize,
        search: parseSearchString(args.searchString),
      },
    });
  };

  // Helper to reset the state
  const resetData = () => setState(() => initialState);

  // When the search string changes, reset the data before
  // loading new components
  useEffect(() => resetData(), [args.searchString]);

  // If a reset state is found, query new components
  useEffect(() => {
    if (state.inResetState) loadMoreData();
  }, [state.inResetState]);

  useEffect(() => {
    if (args.searchString) {
      const search = new URLSearchParams(location.search);
      search.set(args.queryKey, args.searchString);
      history.push({
        search: search.toString(),
      });
    }
  }, [args.searchString]);

  return useMemo(
    () => ({
      data: state.data,
      error: state.error,
      totalCount: state.totalCount,
      loading: state.loading,
      hasNextPage: state.pageInfo.hasNextPage,
      loadMoreData,
      refetch() {
        resetData();
      },
    }),
    [
      state.data,
      state.totalCount,
      state.pageInfo.hasNextPage,
      state.error,
      state.loading,
    ]
  );
};

export type VirtualListGeneric<T> = {
  data: T[];
  error: ApolloError | undefined;
  totalCount: number;
  loading: boolean;
  loadMoreData: () => Promise<void>;
  refetch: () => void;
};
