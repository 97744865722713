import { Box, Button, Icon as MuiIcon, styled, Typography } from "@mui/material";
import { ButtonVariants } from "common/constants";
import { ComponentType } from "react";
import { colorPalette } from "@duro/themes";

export interface EmptyTableTileProps<T = any> {
  buttonLabel: string,
  customStyle?: EmptyTableStyle,
  Icon?: ComponentType<T>,
  onClick: () => void,
  subText?: string,
  text: string,
  disableButton?: boolean,
}
export interface IconStyle {
  color?: string,
  background?: string,
}

export interface ButtonStyle {
  background?: string,
  hoverBackground?: string,
  borderColor?: string,
}

export interface EmptyTableStyle {
  iconStyle?: IconStyle,
  buttonStyle?: ButtonStyle,
}

export function EmptyTableTile(
  {
    buttonLabel,
    customStyle,
    Icon, onClick,
    subText,
    text,
    disableButton,
  }: EmptyTableTileProps,
) {
  return (
    <ContentWrapper>
      {Icon && (
        <IconSection color="primary" iconStyle={customStyle?.iconStyle}><Icon /></IconSection>
      )}
      <DescriptionSection>{text}</DescriptionSection>
      {subText && <SubDescriptionSection>{subText}</SubDescriptionSection>}
      <Box>
        <CustomButton
          buttonStyle={customStyle?.buttonStyle}
          variant={ButtonVariants.OUTLINED}
          onClick={onClick}
          disabled={disableButton}
        >
          {buttonLabel}
        </CustomButton>
      </Box>
    </ContentWrapper>
  );
}

const ContentWrapper = styled(Box)({
  backgroundColor: colorPalette.darkGrey,
  borderRadius: "0.5rem",
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  margin: "0.5rem",
});

export const IconSection = styled(MuiIcon, {
  shouldForwardProp: propName => propName !== "iconStyle",
})<{ iconStyle?: IconStyle }>(({ iconStyle }) => ({
  alignSelf: "center",
  color: iconStyle?.color ?? "primary",
  fontSize: "3rem",
  "& .MuiSvgIcon-root": {
    fontSize: "3rem",
  },
}));

const DescriptionSection = styled(Typography)({
  padding: "1rem 0",
  fontSize: "1rem",
  fontWeight: "bold",
});

const CustomButton = styled(Button, {
  shouldForwardProp: propName => propName !== "buttonStyle",
})<{ buttonStyle?: ButtonStyle }>(({ buttonStyle }) => ({
  background: buttonStyle?.background ?? "primary",
  border: buttonStyle?.borderColor ?? "primary",
  "&:disabled": {
    backgroundColor: buttonStyle?.background ?? "primary",
  },
  ...(buttonStyle ? {
    "&:hover": {
      background: buttonStyle.hoverBackground,
      border: buttonStyle.borderColor,
    },
  } : {}),
}));

const SubDescriptionSection = styled(Typography)({
  paddingBottom: "1rem",
  fontSize: "0.875rem",
});
