import "../image-viewer-dynamic/index.scss"
import React        from "react"
import DropZone     from "react-dropzone"
import InlineIcon   from "../icon/inline-icon.js"
import Config       from "../../../modules/config"
import Utils        from "../../../modules/utils"
import ArrowIcon     from "../../../assets/icons/pagination"
import CloseIcon     from "../../../assets/icons/close"
import InlineImageViewerIcon    from "../../../assets/icons/inline-image-viewer-arrow-icon";
import GearIcon  from "../../../assets/icons/gear";
import Image from "../image";

import {
    componentDefaultImg as defaultSrc
    }               from "../../../assets"

class ImageViewer extends React.Component {

    constructor(props, context)
    {
        super(props, context);
        this.state =
        {
            class  : "selected",
            index  : 0,
            images : []
        }

        this.images = this.images.bind(this);
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
        this.update = this.update.bind(this);
        this.onFileDrop = this.onFileDrop.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onPrimaryClick = this.onPrimaryClick.bind(this);
        this.setImageViewerState = this.setImageViewerState.bind(this)
    }

    setImageViewerState(state = this.state, payload){
        let fields = state
        if ( payload.index === 0 )
            fields.index = 0
        else
            fields.index = payload.index ? payload.index : state.index
        fields.class = payload.className ? payload.className : state.class
        fields.images = payload.images ? payload.images : state.images
        const data = Object.assign(state, fields);

        if (payload.callback)
            this.setState({...state, data}, payload.callback)
        else
            this.setState({...state, data})
    }


    componentWillMount(){
        if (this.props.images){
            this.setImageViewerState(this.state, {images: this.props.images} )
        }

        if(!this.props.edit && this.state.images.length < 1)
        {
            this.setImageViewerState(this.state, {images: [{src: defaultSrc}]} )
        }
    }

    images()
    {
        return this.props.this.state && this.props.this.state.images
    }

    previous()
    {
        let index = this.state.index === 0
            ? this.state.images.length - 1
            : this.state.index - 1
        this.update(index, "left")
    }

    next()
    {
        let index = this.state.index < this.state.images.length - 1
            ? this.state.index + 1
            : 0
        this.update(index, "right")
    }

    update(imgIndex = 0, slide = "left")
    {
        const index = imgIndex
        const className = "selected slide-from-" + slide

        this.setImageViewerState(this.state, {index, className} )
    }

    onFileDrop(files)
    {
        if(files.length < 1) return
        let index = this.state.images.length
        let images = this.state.images

        files.forEach((file) =>
        {
            images.push(file)
        })

        this.setImageViewerState(this.state, {index, images, callback: this.onChange} )
    }

    onRemoveClick()
    {
        if(this.state.images.length < 1) return

        this.state.images.splice(this.state.index, 1)
        let images = this.state.images
        let index = 0
        if (images.length !== 0)
            index  = this.state.index > images.length - 1 ? this.state.index - 1 : this.state.index
        this.setImageViewerState(this.state, {index, images, callback: this.onChange} )
    }

    onChange()
    {
        if(this.props.onChange)
        {
            let event = Utils.getEvent(this, this.state.images)
            this.props.onChange(event)
        }
    }

    onPrimaryClick(event)
    {
        let target = this.state.images[this.state.index]
        this.state.images.splice(this.state.index, 1)
        this.state.images.unshift(target)

        let images = this.state.images
        let index = 0

        this.setImageViewerState(this.state, {index, images, callback: this.onChange} )
    }

    render()
    {
        let imageNodes = []
        const maxFileSize = Config.MAX_FILE_SIZE;
        this.state.images.forEach((image, i) =>
        {
            let selected   = i === this.state.index
            let src        = image.src || image.preview
            if(this.props.defaultResolution && image.variants && image.src)
            {
                src = image.variants[this.props.defaultResolution];
            }
            let imageBlock =
                <Image
                    keyValue={i}
                    className={"image " + (selected ? this.state.class : "")}
                    src={src}
                >
                </Image>
            imageNodes.push(imageBlock)
        })

        let clazz = "ui-image-viewer"
            + (this.state.images.length === 0 ? " empty"  : "")
            + (this.state.images.length === 1 ? " single" : "")
            + (this.props.edit ? " edit" : "")

        let arrowIcon = this.props.inlineImageViewer ? <InlineImageViewerIcon/> : <ArrowIcon />;
        let markup =

            <div className={clazz}>
                <div
                    className={"remove-btn"}
                    onClick={this.onRemoveClick}
                    >
                    <InlineIcon>
                      <CloseIcon />
                    </InlineIcon>
                </div>
                {
                    this.props.images && this.props.images.length > 0 ?
                    <div className="images">
                        {imageNodes}
                        <div className="image-copy">Add images?</div>
                    </div>
                    :
                    <div className="default-img">
                        <InlineIcon>
                            <GearIcon />
                        </InlineIcon>
                    </div>
                }
                <div className="controls">
                    <div className="arrow previous" onClick={this.previous}>
                      <InlineIcon className={this.props.inlineImageViewer ? "navigation-icons" : ""}>
                      {arrowIcon}
                      </InlineIcon>
                    </div>
                    <div className="count">
                        <span>{this.state.index + 1}</span>
                        <span className="of"> of </span>
                        <span>{this.state.images.length}</span>
                    </div>
                    <div className="arrow next" onClick={this.next}>
                      <InlineIcon>
                        {arrowIcon}
                      </InlineIcon>
                    </div>
                </div>
                <div className="primary-block">
                    <input
                        type="checkbox"
                        name="primary"
                        checked={this.state.index === 0}
                        onChange={this.onPrimaryClick}
                    />
                    <label htmlFor="primary" />
                    <span>
                        <span>Primary image </span>
                    </span>
                </div>
                <DropZone
                    className="drop-zone"
                    activeClassName="accept-files"
                    rejectClassName="reject-files"
                    multiple={true}
                    accept={"image/*"}
                    onDrop={this.onFileDrop}
                    maxSize={Config.maxFileSize}
                    >
                    <div>
                        <div>
                            <span>Drag Files</span>
                            <span>or Browse</span>
                        </div>
                    </div>
                    <div className="modal"></div>
                </DropZone>
            </div>

        return markup
    }
}

ImageViewer.defaultProps =
{
    images : []
}

export default ImageViewer
