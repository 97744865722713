import { useMemo } from 'react';
import { TransactionalModal } from 'common/components/modals';

const ConfirmAlert = ({
  confirm,
  confirmButtonText,
  errorType = "",
  heading,
  reject,
  text,
}) => {
  const warningClass = useMemo(() => (
    errorType === "warning" ? "global-modal-box-for-errors warning-err" : "save-changes "
  ), [errorType]);
  return (
    <div className={`new-warning-modal ${warningClass}`}>
      <TransactionalModal
        acceptLabel={confirmButtonText}
        cancelLabel={"Cancel"}
        onAccept={confirm}
        onCancel={reject}
        onClose={reject}
        open={true}
        title={heading}
      >
        <p dangerouslySetInnerHTML={{ __html: text }}>
        </p>
      </TransactionalModal>
    </div>
  );
}

export default ConfirmAlert
