import { styled, SxProps } from "@mui/material";
import { colorPalette } from "components/themes";
import React from "react";

export enum LayoutBasicClasses {
  SIDEBAR = "layout-basic-sidebar",
  SIDEBAR_CONTENT = "layout-basic-sidebar-content",
  CONTAINER = "layout-basic-container",
  TOOLBAR = "layout-basic-toolbar",
  TOP_FOLD = "layout-basic-top-fold",
  BOTTOM_FOLD = "layout-basic-bottom-fold",
  CONTENT = "layout-basic-content",
}

const DURO_LEFTBAR_WIDTH = 55;
const DURO_NAV_HEIGHT = 70;

export type LayoutBasicProps = {
  withSidebar?: boolean;
  withToolbar?: boolean;
  sidebarWidth?: number;
  toolbarHeight?: number;
  sx?: SxProps;
  className?: string;
};

export const defaultLayoutProps: LayoutBasicProps = {
  withSidebar: false,
  withToolbar: false,
  sidebarWidth: 300,
  toolbarHeight: 50,
};

type NumberOrUndefined = number | undefined;

const calc = (...add: NumberOrUndefined[]) =>
  `${add.reduce((a, b) => (a ?? 0) + (b ?? 0), 0)}px`;

export const LayoutBasicToolbar = styled("div")<LayoutBasicProps>(
  ({ toolbarHeight = 0, withToolbar, sidebarWidth }) => ({
    background: colorPalette.yellow,
    color: colorPalette.white,
    minHeight: toolbarHeight,
    position: "sticky",
    top: 0,
    zIndex: 1,
    left: 0,
    display: withToolbar ? "flex" : "none",
    alignItems: "center",
    justifyContent: "space-between",
    width: sidebarWidth ? 
      `calc(100vw - ${calc(sidebarWidth, DURO_LEFTBAR_WIDTH)})` : 
      `calc(100vw - ${calc(DURO_LEFTBAR_WIDTH)})`,
    boxShadow: "0 0 8px black",
  })
);

export const LayoutBasicBottomFold = styled("div")<LayoutBasicProps>(({}) => ({
  display: "flex",
  width: "100%",
  height: "70%",
}));

export const LayoutBasicTopFold = styled("div")<LayoutBasicProps>(({}) => ({
  display: "grid",
  gridTemplateColumns: "100%",
  background: colorPalette.dark,
}));

export const LayoutBasicContainer = styled("div")<LayoutBasicProps>(
  ({ toolbarHeight }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: colorPalette.darkGrey,
    height: "100%",
  })
);

export const LayoutFixedScroll = styled("div")({});

export const LayoutBasicSidebar = styled("div")<LayoutBasicProps>(
  ({ sidebarWidth, toolbarHeight = 0 }) => ({
    transition: "width 0.5s ease",
    display: "flex",
    borderLeft: `1px solid ${colorPalette.dark4}`,
    background: colorPalette.darkGrey,
    width: sidebarWidth,
    position: "fixed",
    right: 0,
    bottom: 0,
    top: DURO_NAV_HEIGHT,
    height: `calc(100vh - ${calc(DURO_NAV_HEIGHT)})`,
    zIndex: 9999,
    overflow: "hidden",
  })
);

export const LayoutBasicContent = styled("div")<LayoutBasicProps>(
  ({ sidebarWidth }) => ({
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    background: colorPalette.darkGrey,
    maxWidth: sidebarWidth ? `calc(100vw - ${calc(sidebarWidth, DURO_LEFTBAR_WIDTH)})` : "100vw",
  })
);

export const LayoutBasicSidebarContent = styled("div")<LayoutBasicProps>(
  () => ({
    height: "100%",
    display: "flex",
    rowGap: "1%",
    flexDirection: "column",
  })
);

export type LayoutBasicElements = {
  BottomFold: React.FC<LayoutBasicProps>;
  TopFold: React.FC<LayoutBasicProps>;
  Container: React.FC<LayoutBasicProps>;
  Sidebar: React.FC<LayoutBasicProps>;
  SidebarContent: React.FC<LayoutBasicProps>;
  Toolbar: React.FC<LayoutBasicProps>;
  Content: React.FC<LayoutBasicProps>;
};

export const createLayoutBasic = (
  props = defaultLayoutProps
): LayoutBasicElements => {
  return {
    Toolbar: ({ children, ...rest }) => (
      <LayoutBasicToolbar
        className={LayoutBasicClasses.TOOLBAR}
        {...props}
        {...rest}
      >
        {children}
      </LayoutBasicToolbar>
    ),
    BottomFold: ({ children, ...rest }) => (
      <LayoutBasicBottomFold
        {...props}
        className={LayoutBasicClasses.BOTTOM_FOLD}
      >
        {children}
      </LayoutBasicBottomFold>
    ),
    TopFold: ({ children, ...rest }) => (
      <LayoutBasicTopFold
        className={LayoutBasicClasses.TOP_FOLD}
        {...props}
        {...rest}
      >
        {children}
      </LayoutBasicTopFold>
    ),
    Container: ({ children, ...rest }) => (
      <LayoutBasicContainer
        className={LayoutBasicClasses.CONTAINER}
        {...props}
        {...rest}
      >
        {children}
      </LayoutBasicContainer>
    ),
    Sidebar: ({ children, ...rest }) => (
      <LayoutBasicSidebar
        className={LayoutBasicClasses.SIDEBAR}
        {...props}
        {...rest}
      >
        {children}
      </LayoutBasicSidebar>
    ),
    SidebarContent: ({ children, ...rest }) => (
      <LayoutBasicSidebarContent
        className={LayoutBasicClasses.SIDEBAR_CONTENT}
        {...props}
        {...rest}
      >
        {children}
      </LayoutBasicSidebarContent>
    ),
    Content: ({ children, ...rest }) => (
      <LayoutBasicContent
        className={LayoutBasicClasses.CONTENT}
        {...props}
        {...rest}
      >
        {children}
      </LayoutBasicContent>
    ),
  };
};
