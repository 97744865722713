import { Container, TextField, styled } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { InputFieldVariants } from "common/constants";
import moment, { Moment } from "moment";
import { ElementType, FC, useCallback, useMemo, useState } from "react";
import { colorPalette } from "@duro/themes";
import { FieldTitle } from "./index";

export type Date = number | null;
interface IDateTimePickerButtonProps {
  sx: {
    marginRight: string;
    padding: string;
  };
}

interface IRenderInputProps {
  errorMessage?: string;
  placeholder?: string;
  readOnly?: boolean;
  setError?: boolean;
  style?: {
    background?: string;
    border?: string;
    fontSize?: string;
    height?: string;
    padding?: string;
    width?: string;
    boxSizing?: string;
  };
  variant?: InputFieldVariants;
}

interface InputWrapperProps {
  disableUnderline?: boolean;
  style?: {
    backgroundColor?: string;
    display?: string;
    gridTemplateColumns?: string;
    alignItems?: string;
    justifyContent?: string;
  };
}
interface ICustomPickerStyle {
  alignItems?: string;
  display?: string;
  justifyContent?: string;
}

interface DateTimePickerProps {
  calendarStyle?: {
    placement?: string;
  };
  customStyle?: ICustomPickerStyle;
  defaultValue?: Date;
  disabled?: boolean;
  EnclosingTag: ElementType;
  inputFormat?: string;
  inputWrapperProps?: InputWrapperProps;
  isRequired: boolean;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  onChange: (value: number | null) => void;
  onError?: (value: string | null) => void;
  openPickerButtonProps?: IDateTimePickerButtonProps;
  openPickerIcon?: ElementType;
  renderInputProps?: IRenderInputProps;
  setIntialValue?: boolean;
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
  calendarStyle,
  customStyle,
  defaultValue,
  disabled,
  EnclosingTag,
  inputFormat,
  inputWrapperProps,
  isRequired,
  label,
  maxDate,
  minDate,
  onChange,
  onError,
  openPickerButtonProps,
  openPickerIcon,
  renderInputProps,
  setIntialValue,
}) => {
  const [value, setValue] = useState<number | null>(
    defaultValue ?? (setIntialValue ? moment().valueOf() : null)
  );

  const _inputFormat = useMemo(
    () => inputFormat ?? "MMM D, YYYY hh:mmA",
    [inputFormat]
  );

  const handleChange = useCallback(
    (newValue: Moment | null) => {
      const formattedValue = newValue?.valueOf() ?? null;
      setValue(formattedValue);
      onChange(formattedValue);
    },
    [onChange]
  );

  const renderInput = useCallback(
    (params) => {
      const { setError, errorMessage, variant, ...rest } =
        renderInputProps ?? {};
      return (
        <TextField
          {...params}
          error={setError}
          helperText={errorMessage}
          inputProps={{ ...params.inputProps, ...rest }}
          variant={variant}
          onChange={(e) => {
            const inputValue = e.target.value;
            const parsedDate = moment(
              inputValue,
              ["MM/DD/YYYY", "MMM D, YYYY"],
              true
            );
            if (parsedDate.isValid()) {
              const formattedValue = parsedDate.valueOf();
              setValue(formattedValue);
              onChange(formattedValue);
            }
          }}
        />
      );
    },
    [renderInputProps]
  );
  return (
    <PickerWrapper style={customStyle}>
      {label && (
        <FieldTitle
          isRequired={isRequired}
          label={label}
          sx={{
            fontSize: "0.75rem",
            marginBottom: "0.5rem",
          }}
        />
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <EnclosingTag
          AutoComplete={false}
          components={{ OpenPickerIcon: openPickerIcon }}
          disabled={disabled}
          disableMaskedInput
          inputFormat={_inputFormat}
          InputProps={inputWrapperProps}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleChange}
          onError={onError}
          OpenPickerButtonProps={openPickerButtonProps}
          PaperProps={PopoverStyle}
          PopperProps={calendarStyle}
          renderInput={renderInput}
          slots={{
            field: TextField,
          }}
          slotProps={{
            textField: {
              error: renderInputProps?.setError,
              helperText: renderInputProps?.errorMessage,
            },
          }}
          value={value}
        />
      </LocalizationProvider>
    </PickerWrapper>
  );
};

const PickerWrapper = styled(Container, {
  shouldForwardProp: (prop) => prop !== "style",
})<ICustomPickerStyle>(({ style }) => ({
  ...style,
  marginBottom: "0.625rem",
  "@media (min-width: 37.5rem)": {
    padding: "0",
  },
  "& .MuiFilledInput-root": {
    backgroundColor: colorPalette.black,
  },
}));

const PopoverStyle = {
  sx: {
    backgroundColor: colorPalette.gray,
    backgroundImage: "none",
    marginTop: "0.25rem",
  },
};
