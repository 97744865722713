import React, { useState, useEffect } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
  Tooltip,
} from "@mui/material";
import { DuroButton } from "common/components";
import { colorPalette } from "components/themes";

interface ChangeRevisionContentProps {
  onApply: (revisionType: string, customValue?: string) => void;
  onCancel: () => void;
  allowCustomOverride: boolean;
  itemStatus: { initialStatus: string };
  onDocumentationChange: () => void;
  onMinorChange: () => void;
  onMajorChange: () => void;
  onCustomChange: (v: string) => void;
  errorMessage?: string;
  initialRevisionValue: string;
  revisionTypes: {
    documentation: string;
    minor: string;
    major: string;
    showMajor: boolean;
  };
}

// Utility function to determine the labels based on the revSchemeType
export const getRevisionTypeLabels = (itemStatus: {
  initialStatus: string;
}) => {
  const revSchemeType = window.__revSchemeType;
  const isAlphaBetaNumeric = revSchemeType === "alphaBetaNumericXYZ";

  return {
    documentation: isAlphaBetaNumeric
      ? "Document Change"
      : "Documentation Change",
    minor: isAlphaBetaNumeric ? "Prototype Change" : "Minor Change",
    major: isAlphaBetaNumeric ? "Production Release" : "Major Change",
    showMajor: !isAlphaBetaNumeric || itemStatus.initialStatus !== "PROTOTYPE",
  };
};

export const ChangeRevisionContent: React.FC<ChangeRevisionContentProps> = ({
  onApply,
  onCancel,
  allowCustomOverride,
  onDocumentationChange,
  onMinorChange,
  onMajorChange,
  onCustomChange,
  errorMessage,
  initialRevisionValue,
  revisionTypes: labels,
}) => {
  const [selectedRevisionType, setSelectedRevisionType] = useState<string>(
    labels.documentation
  ); // Selected radio button
  const [customValue, setCustomValue] = useState(initialRevisionValue);

  useEffect(() => {
    onCustomChange(customValue);
  }, [customValue]);

  const handleApply = () => {
    if (selectedRevisionType === "Custom") {
      onApply(selectedRevisionType, customValue);
    } else {
      onApply(selectedRevisionType);
    }
  };

  return (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#2C2F33",
        borderRadius: "8px",
        color: "black",
        background: colorPalette.dark3,
      }}
    >
      <FormControl
        component="fieldset"
        sx={{
          "& .MuiButtonBase-root": {
            padding: "5px 8px",
          },
        }}
      >
        <RadioGroup
          value={selectedRevisionType}
          onChange={(e) => setSelectedRevisionType(e.target.value)} // Only update the selected revision type
        >
          <FormControlLabel
            value={"documentation"} // Static label
            control={<Radio />}
            label={labels.documentation} // Display static label
            style={{ color: "white" }}
            onChange={onDocumentationChange}
          />
          <FormControlLabel
            value={"minor"} // Static label
            control={<Radio />}
            label={labels.minor} // Display static label
            style={{ color: "white" }}
            onChange={onMinorChange}
          />
          <FormControlLabel
            value={"major"} // Static label
            control={<Radio />}
            label={labels.major} // Display static label
            style={{ color: "white" }}
            onChange={onMajorChange}
          />
          {allowCustomOverride && (
            <FormControlLabel
              value="Custom"
              control={<Radio />}
              label={
                <Tooltip
                  title={errorMessage}
                  placement="top"
                  open={!!errorMessage && selectedRevisionType === "Custom"}
                >
                  <TextField
                    value={customValue}
                    onChange={(e) => {
                      setCustomValue(() => e.target.value);
                    }}
                    size="small"
                    style={{
                      marginLeft: 8,
                      width: "50px",
                    }}
                    inputProps={{
                      style: {
                        color: "black",
                        background:
                          customValue !== "" && !!errorMessage
                            ? "red"
                            : "white",
                      },
                    }}
                  />
                </Tooltip>
              }
            />
          )}
        </RadioGroup>
      </FormControl>
      <div
        style={{
          marginTop: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DuroButton onClick={onCancel} variant="outlined">
          Cancel
        </DuroButton>
        <DuroButton
          disabled={!!errorMessage && selectedRevisionType === "Custom"}
          onClick={handleApply}
          variant="contained"
        >
          Apply
        </DuroButton>
      </div>
    </div>
  );
};
