const data = {
    leadTime:
    {
        units()
        {
            let list =
            [
                {
                    value: "DAY",
                    displayName: "Day"
                },
                {
                    value: "WEEK",
                    displayName: "Week"
                },
                {
                    value: "DAYS",
                    displayName: "Days"
                },
                {
                    value: "WEEKS",
                    displayName: "Weeks"
                }
            ]

            return list
        }
    },

    package:
    {
        types()
        {
            let list =
            [
                {
                    value: "",
                    displayName: ""
                },
                {
                    value: "BULK",
                    displayName: "Bulk"
                },
                {
                    value: "PACK",
                    displayName: "Pack"
                },
                {
                    value: "TRAY",
                    displayName: "Tray"
                },
                {
                    value: "TAPE & REEL",
                    displayName: "Tape & Reel"
                },
                {
                    value: "CUT TAPE",
                    displayName: "Cut Tape"
                },
                {
                    value: "CUSTOM REEL",
                    displayName: "Custom Reel"
                },
                {
                    value: "TUBE",
                    displayName: "Tube"
                },
                {
                    value: "AMMO PACK",
                    displayName: "Ammo Pack"
                },
                {
                    value: "INDIVIDUAL",
                    displayName: "Individual"
                },
                {
                    value: "SPOOL",
                    displayName: "Spool"
                },
                {
                    value: "STRIP",
                    displayName: "Strip"
                },
                {
                    value: "FT",
                    displayName: "FT"
                }
            ]

            return list
        }
    }
}

export default data
