import { useMutation } from '@apollo/client';
import { ARCHIVE_CHANGE_ORDER_APPROVER_TEMPLATE } from 'graphql/query/changeOrdersQueries';

export const useArchiveApproverTemplate = () => {
  const [archiveTemplateMutation, { loading, error }] = useMutation(ARCHIVE_CHANGE_ORDER_APPROVER_TEMPLATE);

  const archiveTemplate = async (templateId: string) => {
    try {
      const result = await archiveTemplateMutation({
        variables: { id: templateId },
      });
      return result.data.archiveChangeOrderApprovalTemplate;
    } catch (e) {
      console.error('Error deleting template:', e);
      throw e;
    }
  };

  return { archiveTemplate, loading, error };
};