import { CellParams } from "../cell.types";
import { sdk } from "features/changeorders/sdk";
import { StatusField } from "@duro/base/status-field";
import { ChangeOrderType } from "design/models/changeorder";

const StatusRenderCell = ({ row }: CellParams) => {
  const [state] = sdk.editor.getChangeset(row).useStore();
  const coType = sdk.storage.form.type.getState();
  const label =
    coType === ChangeOrderType.DCO ? row.previousStatus ?? row.status : state.originalStatus;

  return <StatusField label={label} sx={{ minWidth: 110 }} />;
};

export const ModalStatusCell = {
  renderCell: StatusRenderCell,
  headerName: "Status",
  field: "status",
  width: 130,
};
