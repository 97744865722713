import { Box, styled } from "@mui/material";
import { BreadcrumbLabel, CustomBreadcrumb, PossibleIcons } from "common/components/breadcrumb";

interface ITemporaryBreadCrumbProps {
  icon: string,
  label: string,
}

/**
 * This component is temporary replace for the breadcrumbs in the legacy library pages (CO, Release)
 * @param {ITemporaryBreadCrumbProps}
 */

export const TemporaryBreadCrumb = ({ icon, label }: ITemporaryBreadCrumbProps) => {
  const IconToRender = icon && PossibleIcons[icon.toUpperCase()];

  return (<CustomBreadcrumb color="primary">
    <BreadcrumbWrapper>
      {
        IconToRender && <IconToRender sx={{ height: "1.5rem", width: "1.5rem" }} />
      }
      <BreadcrumbLabel>{label}</BreadcrumbLabel>
    </BreadcrumbWrapper>
  </CustomBreadcrumb>
  );
};

const BreadcrumbWrapper = styled(Box)({
  alignItems: "center",
  display: "flex",
});
