import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { getRowId } from "common/components/grid";
import { Link } from "common/components/link";
import { useToasts } from "common/components/toasts";
import { BuildRevision } from "build/models";
import { Component } from "design/models";
import { ModelType } from "@duro/utils";
import { client } from "graphql/apolloClient";
import { useComponentRevisionWithLots } from "graphql/query/componentRevisionQueries";
import { useProductsWithLots } from "graphql/query/productsQueries";
import { useProductRevisionsWithLots } from "graphql/query/productRevisionQueries";
import { useComponentWithLots } from "graphql/query/componentsQueries";
import { SerialNumberType, SerializationNode } from "./useSerializationData";
import { CreatedLotFragment } from "graphql/fragment/lotFragment";

const CREATE_LOT_QUERY = gql`
  mutation createLot($input: BuildCreateLotInput!) {
    build {
      createLot(input: $input) {
        ...createdLotFragment
      }
    }
  }
  ${CreatedLotFragment}
`;

enum SourceType {
  DURO_BUILD_REVISION = "DURO_BUILD_REVISION",
  DURO_COMPONENT_REVISION = "DURO_COMPONENT_REVISION",
  DURO_PRODUCT_REVISION = "DURO_PRODUCT_REVISION",
}

interface SourceFromRowsArgs {
  baseSerialNumberType: SerialNumberType;
  includeChildren: boolean;
  parentQuantity: number;
  parentSelected: boolean;
  rows: SerializationNode[];
  selectionModel: GridRowId[];
  sourceId: string;
  type?: SourceType;
}

interface SourceFromRowsResponse {
  childSources?: SourceFromRowsResponse[],
  instanceNotes?: string;
  quantityToBuildOverride?: number,
  serialNumberTypeOverride?: SerialNumberType;
  sourceId: string;
  type: SourceType;
}

function sourceFromRows(args: SourceFromRowsArgs): SourceFromRowsResponse {
  const {
    baseSerialNumberType,
    includeChildren,
    parentQuantity,
    parentSelected,
    rows,
    selectionModel,
    sourceId,
    type,
  } = args;
  const row = rows.find(r => r.item.id === sourceId);

  const source: SourceFromRowsResponse = {
    sourceId,
    type: type ?? SourceType.DURO_COMPONENT_REVISION,
  };

  if (!row) return source;

  const selected = selectionModel.includes(getRowId(row));

  if (includeChildren && row.item.children?.length) {
    const children = row.item.children.map(child => (
      sourceFromRows({
        baseSerialNumberType: row.serialNumberType ?? baseSerialNumberType,
        includeChildren,
        parentQuantity: row.qtyToBuild,
        parentSelected: selected,
        rows,
        selectionModel,
        sourceId: child.assemblyRevision?.id ?? "",
      })
    )).filter(child => Object.keys(child).length > 2);
    if (children.length) {
      source.childSources = children;
    }
  }

  // If the current item isn't selected, then return early. Set the quantity to build override only
  // if the parent was selected so the server knows to skip this one (and any children not selected).
  if (!selected) {
    if (parentSelected) {
      source.quantityToBuildOverride = 0;
    }
    return source;
  }

  if (row.notes) {
    source.instanceNotes = row.notes;
  }

  if (!parentSelected || row.qtyToBuild !== (row.quantity * parentQuantity)) {
    source.quantityToBuildOverride = row.qtyToBuild;
  }

  if (row.serialNumberType !== baseSerialNumberType) {
    source.serialNumberTypeOverride = row?.serialNumberType;
  }

  return source;
}

export interface SubmitSerializationArgs {
  addToDashboard: boolean;
  autoAssemble: boolean;
  dueDate: number;
  includeChildren: boolean;
  labels: string;
  notes: string;
  productionRun: number;
  currentItem: Component | BuildRevision;
  rows: SerializationNode[];
  selectionModel: GridRowId[];
}

export function useOnSubmit(callback?: (data: SubmitSerializationArgs) => void) {
  const [createLot] = useMutation(CREATE_LOT_QUERY);
  const { closeToast, enqueueToast } = useToasts();
  const { updateCmpLotsCache } = useComponentWithLots();
  const { updateCmpRevLotsCache } = useComponentRevisionWithLots();
  const { updatePrdLotsCache } = useProductsWithLots();
  const { updatePrdRevLotsCache } = useProductRevisionsWithLots();

  return useCallback((data: SubmitSerializationArgs) => {
    const toastId = enqueueToast({ message: "Serializing Lot", showSpinner: true, persist: true });

    callback?.(data);
    const {
      addToDashboard,
      autoAssemble,
      currentItem,
      dueDate,
      includeChildren,
      labels,
      notes,
      productionRun,
      rows,
      selectionModel,
    } = data;
    const asDate = new Date(dueDate);
    const determineType = () => {
      if ((currentItem as BuildRevision).isBuildRev) {
        return SourceType.DURO_BUILD_REVISION;
      }
      return currentItem.alias === ModelType.CMP
        ? SourceType.DURO_COMPONENT_REVISION : SourceType.DURO_PRODUCT_REVISION;
    };

    createLot({
      variables: {
        input: {
          addToDashboard,
          autoAssemble,
          dueDate: asDate.toISOString(),
          includeChildren,
          labels: labels.split(",").map(l => l.trim()).filter(Boolean),
          notes: notes.length ? notes : undefined,
          productionRun,
          serialNumberType: SerialNumberType.GENERATED,
          sources: [sourceFromRows({
            baseSerialNumberType: SerialNumberType.GENERATED,
            includeChildren,
            parentQuantity: productionRun,
            parentSelected: true,
            rows,
            selectionModel,
            sourceId: currentItem.id,
            type: determineType(),
          })],
        },
      },
    }).then(r => {
      const lot = r.data?.build?.createLot ?? {};
      closeToast(toastId);
      if (currentItem.alias === ModelType.CMP) {
        updateCmpLotsCache(client, lot, [((currentItem as Component)?.parent?.id as unknown as string)]);
        updateCmpRevLotsCache(client, lot, [currentItem.id]);
      }
      else {
        updatePrdLotsCache(client, lot, [((currentItem as Component)?.parent?.id as unknown as string)]);
        updatePrdRevLotsCache(client, lot, [currentItem.id]);
      }
      enqueueToast({
        body: (
          <Box>
            <Link to={`/build/lot/${lot.id}`}>Lot {lot.number?.displayValue}</Link> has been created.
          </Box>
        ),
        message: "Serialization Complete",
        variant: "success",
      });
    }).catch(e => {
      closeToast(toastId);
      enqueueToast({ body: e.message, message: "Serialization Failed.", persist: true, variant: "error" });
    });
  }, [
    callback,
    closeToast,
    createLot,
    enqueueToast,
    updateCmpLotsCache,
    updateCmpRevLotsCache,
    updatePrdLotsCache,
    updatePrdRevLotsCache,
  ]);
}
