import { SuperComponent } from "design/models/changeorder";
import {
  ClientModulesSchemaValidationResult,
  defaultClientModulesSchemaValidationResult,
  validateComponents,
  ValidationErrorGroup,
  ValidationErrorType,
  ValidationErrorTypeGroups,
} from "../utils";
import { EditorState as state } from "./state";
import { storage } from "../storage";

export const validateEditorItems = async (rows: SuperComponent[]) => {
  state.flags.tableIsLoading.setState(true);

  const items = rows.map((item) => {
    const entry = state.getChangeset(item);
    const legacyNextRevision = entry.legacyNextRevision.getState();
    const status = entry.status.getState();

    return {
      ...item,
      legacyNextRevision,
      status,
    };
  });

  const errors = await validateComponents({
    items,
    onItemValidate: (errors, item) => {
      const itemModifications = state.getChangeset(item);
      itemModifications.errors.setState(errors);
      itemModifications.reviewed.setState(true);

      if (errors.length) {
        state.cachedValidations[item.id] = errors;
      } else {
        delete state.cachedValidations[item.id];
      }
    },
    enableLogGrouping: process.env.NODE_ENV === "development",
  });

  const isValid = errors.every((error) => error.valid);
  state.flags.isReviewed.setState(true);
  storage.isValid.setState(isValid);
  state.flags.tableIsLoading.setState(false);
  return {
    errors,
    isValid,
    items,
  };
};

export const getRowValidation = (
  row: SuperComponent,
  errs: ClientModulesSchemaValidationResult[],
  isReviewed: boolean
) => {
  const errors = (errs ?? []).filter(
    (v): v is ClientModulesSchemaValidationResult => !v.valid && v != null
  );

  const error = errors[0] ?? defaultClientModulesSchemaValidationResult;

  // Validation has not run at least once.
  if (!isReviewed) {
    const defaultError: ClientModulesSchemaValidationResult = {
      ...defaultClientModulesSchemaValidationResult,
      type: ValidationErrorType.RequiresValidation,
      valid: false,
    };

    return {
      ...row,
      errorType: ValidationErrorType.RequiresValidation,
      errors: [defaultError],
      requiresValidation: true,
      errorGroup: ValidationErrorGroup.RequiresValidation,
      error: defaultError,
      isReviewed: false,
    };
  }

  let errorGroup: ValidationErrorGroup = ValidationErrorGroup.IsValid;

  if (!isReviewed) {
    errorGroup = ValidationErrorGroup.RequiresValidation;
  } else if (error?.type != null) {
    errorGroup = ValidationErrorTypeGroups[error.type];
  }

  return {
    ...row,
    errorType: error?.type,
    error,
    errors,
    errorGroup,
    isReviewed,
  };
};

export const useRowReview = (row: SuperComponent) => {
  const validation = state.getChangeset(row);
  const [isReviewed] = validation.reviewed.useStore();
  const [errs] = validation.errors.useStore();
  return getRowValidation(row, errs, isReviewed);
};
