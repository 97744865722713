import { CircularProgress, Box, styled } from "@mui/material";
import { BuildCircleOutlined } from "@mui/icons-material";

export const ValidateIcon: React.FC<{ isValidationLoading: boolean }> = ({ isValidationLoading }) => {
  return (
    <ValidateIconWrapper>
      {isValidationLoading ? (
        <CircularProgress size={17} thickness={7} />
      ) : (
        <BuildCircleOutlined width={12} height={12} />
      )}
    </ValidateIconWrapper>
  );
};

const ValidateIconWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "1rem",
  height: "1rem",
  margin: "2px",
});
