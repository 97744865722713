import React from "react";
import { Collapse, Typography, Box, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colorPalette } from "components/themes";

export const Expander: React.FC<{
  title?: string;
  expanded: boolean;
  setExpanded: () => void;
}> = ({ children, title, expanded, setExpanded }) => {
  return (
    <Box
      sx={{
        border: `1px solid ${colorPalette.dark4}`,
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <Button
        onClick={() => setExpanded()}
        aria-expanded={expanded}
        aria-label="show more"
        fullWidth
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textTransform: "none",
          backgroundColor: colorPalette.dark,
          padding: "20px 16px",
          borderRadius: "0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {title && (
            <Typography fontSize="1em" color={colorPalette.white}>
              {title}
            </Typography>
          )}
          <ExpandMoreIcon
            sx={{
              transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
              transition: "transform 0.3s",
            }}
          />
        </Box>
      </Button>
      <Collapse in={expanded} timeout="auto">
        <Box sx={{ padding: "16px", backgroundColor: colorPalette.dark4 }}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
