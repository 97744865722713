import { FC, useCallback, useMemo, useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { CadSettings } from "common/models";
import { ButtonVariants } from "common/constants";
import { useUser } from "graphql/query/userQueries";
import { isEqual } from "lodash";
import { colorPalette } from "@duro/themes";
import InlineIcon from "v1/components/ui/icon/inline-icon.js";
import Permissions from "v1/modules/schemas/permissions";
import { CadSettingsModal } from "../cadSettingsModal";
import { CadSettingsModalProvider } from "../cadSettingsModal/cadSettingsModalContextProvider";
import LockCloseIcon from "../../../../../assets/icons/lock-close.js";
import LockOpenIcon from "../../../../../assets/icons/lock-open.js";

const defaultCadSetting: CadSettings & { "__typename": string } = {
  activeReleaseNamingScheme: "STANDARD",
  allowedDesignRevisionTypes: "ALPHA_NUMERIC",
  customReleaseNamingScheme: null,
  findAndReplaceReleaseNamingScheme: [],
  overwriteFilenameWithCpn: false,
  procurementDefault: "MAKE",
  setEidToFilename: false,
  __typename: "CadLibrarySettings",
};

export const Integrations: FC = () => {
  const { data: user } = useUser();
  const [showCadSettingsModal, setShowCadSettingsModal] = useState(false);
  const toggleCadSettingsModal = useCallback(() => (
    setShowCadSettingsModal(!showCadSettingsModal)
  ), [showCadSettingsModal]);

  const { isDisabled, Icon, tooltipText } = useMemo(() => {
    const actionAllowed = Permissions.can("edit", "configurations", user);
    return {
      Icon: actionAllowed ? LockOpenIcon : LockCloseIcon,
      isDisabled: !actionAllowed,
      tooltipText: actionAllowed ? "" : "Administrator access only",
    };
  }, [user]);

  const cadSettingsData = useMemo(() => (
    user?.activeLibrary.cad
  ), [user?.activeLibrary.cad]);

  const buttonLabel = useMemo(() => {
    const areEqual = isEqual(defaultCadSetting, cadSettingsData?.settings);
    return areEqual ? "Set up" : "Edit";
  }, [cadSettingsData?.settings]);

  return (
    <Wrapper>
      <Header>
        <IconWrapper>
          <InlineIcon // Using this for consistency with older sections on the page
            tooltip={tooltipText}
          >
            <Icon />
          </InlineIcon>
        </IconWrapper>
        <HeadingText>
          CAD Settings
        </HeadingText>
      </Header>
      <BodyText>
        Use these settings to customize how Duro interacts <br /> with your integrated CAD tools.
      </BodyText>
      <Button variant={ButtonVariants.OUTLINED} onClick={toggleCadSettingsModal} disabled={isDisabled}>
        {buttonLabel}
      </Button>
      {
        showCadSettingsModal
        && (
          <CadSettingsModalProvider cadSettingsData={cadSettingsData!}>
            <CadSettingsModal
              onModalClose={toggleCadSettingsModal}
              open={showCadSettingsModal}
            />
          </CadSettingsModalProvider>
        )
      }
    </Wrapper>
  );
};

export const Header = styled(Box)({
  display: "flex",
});

export const IconWrapper = styled(Box)({
  left: "-1.563rem",
  marginRight: "0.438rem",
  position: "absolute",
});

export const HeadingText = styled(Typography)({
  fontSize: "0.875rem",
  paddingBottom: "0.625rem",
});

export const Wrapper = styled(Box)({
  padding: "1.5rem 0",
});

const BodyText = styled(Typography)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
  marginBottom: "0.938rem",
});
