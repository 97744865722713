import Config  from "../../../../v1/modules/config";
import Utils   from "../../../../v1/modules/utils"
import { isPresentInInputFiles } from "@duro/changeorder/utils";

let assembledDocsArr = []

function setDocumentsData(documents) {
  documents.map((file, index) => {
    file.specs = {
      type     : "generic",
      revision : "1",
      status   : "DESIGN",
      lastModified: Date.now()
    }
    file.info = {
      id: null,
      processFlag: false,
      isUpdated: false,
      isPending: false,
      errors: []
    }
    file.fileId = `file-${index}`
    if (window.__libraryType === "GENERAL") {
      switch (window.__revSchemeType) {
        case Config.revisionTypes.numericAlphaXY:
        case Config.revisionTypes.alphaNumericXY:
        case Config.revisionTypes.numericXY:
        case Config.revisionTypes.prototypeAlphaNumericXY:
          file.specs.revision = "1"
          break
        case Config.revisionTypes.numericAlphaABXY:
          file.specs.revision = "0A";
          break;
        default:
          break
      }
    }
      return file
  })
  return documents;
}

function uploadDocument(documents) {
  return new Promise((resolve, reject) => {
    if (documents.length === 0) return resolve([]);

    for (let document of documents) {
      document.info.isBeingUploaded = true;
    }

    let docBatches = Utils.createBatches(documents, 5);
    let index = 0;

    let onComplete = (err, data) => {
      if (++index === docBatches.length) {
        return resolve(assembledDocsArr);
      }
      create(index);
    };

    let onDocumentUpload = (doc, counter) => {
      doc.info.isBeingUploaded = false;
      doc.info.processFlag = false;
      if (counter === docBatches[index]?.length) {
          onComplete();
      }
    };

    let create = () => {
      let counter = 0;
      for (let doc of docBatches[index]) {
        doc.specs = doc.specs;
        doc.info.processFlag = true;
        doc.info.isBeingUploaded = true;
        let startTime = new Date() / 1000;

        const progressCb = (err, currentProgress) => {
          Utils.updateDocProgress(currentProgress, startTime, doc, (fillRule) => {
              doc.progressSoFar = fillRule;
          });
        }
        const responseCb = (err, res, newDocument) => {
          counter++;
          if (err) {
              doc.info.errors = err.errors[0].message;
              onDocumentUpload(doc, counter);
          } else {
            doc.info.errors = "";
            assembledDocsArr.push(newDocument);
            doc.info.id = res.data;
            Utils.getDocument(res.data, (err, response) => {
              if (!err) {
                  doc.src = response.src;
              }
              onDocumentUpload(doc, counter);
            });
          }
        }

        if (isPresentInInputFiles(documents, doc.fileId)) {
          Utils.uploadDocument("/documents", doc, progressCb, responseCb);
        } else {
            counter++;
            if (counter === docBatches[index].length) {
              onComplete();
            }
        }
      }
    };
    create(0);
  });
}

export async function addDocuments(files) {
  assembledDocsArr = [];
  let newDocuments = setDocumentsData(files);
  let docsForTheView = await uploadDocument(newDocuments);
  return docsForTheView;
}
