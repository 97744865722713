import "./index.scss"
import React, {Component}           from "react"
import Utils                        from "../../../../../modules/utils"

class ShowHideColumnsPopup extends Component {
    constructor(props, context)
    {
        super(props, context)
        this.state =
        {
            modal :
            {
                open : false
            },
            selectAllCheckbox: {
              value: 'selectAll',
              visibility:  true,
              disabled: false,
              className: '',
            },
            isBuildModal: false,
            styles: {},

            headings : this.props.headings

        }

        this.onChange             = this.onChange.bind(this)
        this.openModal            = this.openModal.bind(this)
        this.closeModal           = this.closeModal.bind(this)
        this.onModalExternalClick = this.onModalExternalClick.bind(this)
        this.allColumnsChecked    = this.allColumnsChecked.bind(this)
        this.allColumnsUnChecked  = this.allColumnsUnChecked.bind(this)
    }

    allColumnsChecked()
    {

      let state  = this.state
      let columns  = state.headings
      let flag     = true

      columns.forEach((col, i) =>
      {
        if (!col.visibility && !col.disabled) {
          flag = false
        }
      })

      return flag
    }

   allColumnsUnChecked()
   {
     let state    = this.state
     let columns  = state.headings
     let flag     = true

     columns.forEach((col, i) =>
     {
       if (col.visibility && !col.disabled) {
         flag = false
       }
     })

     return flag
   }

  onChange(event, i)
  {
    let state    = this.state
    let columns  = state.headings

    if (event.target.name === "category-check-all"){
        columns.forEach((col) =>
        {
            if (!col.disabled)
            {
                col.visibility = event.target.checked
            }
        })
    }
    else if (!columns[i].disabled)
    {
        columns[i].visibility = event.target.checked
    }
    let _this = this
    this.setState(state, () => {

        let columns = this.state.headings
        let data = {}
        columns.forEach((col) =>
        {
            data[col.key] = {}
            data[col.key].visibility = col.visibility
        })
        _this.props.setHeadingsVisibility(columns)
    })
  }

  openModal(event)
  {
    document.body.addEventListener("click", this.onModalExternalClick)
    let modal  = this.state.modal
    modal.open = true
    this.setState(this.state)

    // To validate if "Create Production Build" modal is open
    // when user click on Serialization icon
    if (document.querySelector('#production-build-modal')) {
      this.setState({ isBuildModal: true })
    }

    this.setState({
      styles: {
        position: 'fixed',
        right: 'inherit',
        top: 'inherit',
        marginLeft: '84px',
        marginTop: '35px',
        zIndex: 1
      }
    })
  }

  componentWillReceiveProps(nextProps)
  {
        if (nextProps.displayMenu !== this.state.modal.open)
        {
            if (nextProps.displayMenu === false)
                this.closeModal()
            else if (nextProps.displayMenu === true)
                this.openModal()
        }
    }

  closeModal()
  {
    document.body.removeEventListener("click", this.onModalExternalClick)
    let modal  = this.state.modal
    modal.open = false
    this.setState(this.state, this.props.onHideOfColumnSettings)
  }

  onModalExternalClick(event)
  {
    let target     = event.target
    let parent     = this.refs.modalNode
    let isExternal = target !== parent && !Utils.isDescendant(parent, target)
    if(isExternal) this.closeModal()
  }

   render()
    {
        let columns  = this.state.headings
        let modal = this.state.modal

        let markup =
            <section id="table-settings-menu" style={this.state.isBuildModal ? this.state.styles : null}>
                <div ref="modalNode" className={"modal" + (modal.open ? " open" : "")}>
                  <label className="light-grey">
                    Show/Hide Columns
                  </label>
                  <ul>

                    <li>
                      <input
                           type="checkbox"
                           name="category-check-all"
                           onChange={(e) => this.onChange(e)}
                           checked={this.allColumnsChecked() ? "checked" : ""}
                      />

                      <label
                       className={
                            this.allColumnsChecked() === this.allColumnsUnChecked() ? "middle" : ""}
                       htmlFor="category-check-all"
                      />
                      <span>Select All</span>
                    </li>

                    {
                        columns.map((col, i) =>
                        {
                            let displayValue = col.columnSettingText || col.displayName;
                            let block = col.hideFromSettings ? null :
                                  <li key={i}>
                                    <input  type="checkbox"
                                         disabled={col.disabled ? "disabled" : ""}
                                         name={col.name}
                                         onChange={(e) => this.onChange(e, i)}
                                         value={col.name}
                                         checked={col.visibility ? "checked" : ""}
                                         className={col.disabled ? "disabled" : ""}
                                    />
                                    <label
                                    className={col.disabled ? "disabled" : ""}
                                     htmlFor={col.name}
                                    />
                                    <span className={col.disabled ? "dark-grey" : "light-grey"}>{displayValue}</span>
                                  </li>

                            return block
                        })
                    }
                  </ul>
                </div>
            </section>
        return markup
    }

}

export default ShowHideColumnsPopup
