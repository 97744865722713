import { restClient } from "utils/api";
import { useState, useEffect } from "react";
import { ChangeOrderType, EcoDefaultApproval } from "design/models/changeorder";
import { client } from "graphql/apolloClient";
import { GET_CHANGE_ORDER_APPROVERS_TEMPLATES } from "graphql/query/changeOrdersQueries";
import { ChangeOrderApprovalTemplate } from "./use-get-approver-templates";

type CompanyApproversTemplateMapping = Record<string, string>;

type TemplatesResponse = {
  data: CompanyApproversTemplateMapping;
};

export const getCompanyTemplateMappings = async () => {
  const { data: response } = await restClient.get<TemplatesResponse>(
    "/services/files/getCompanyCoTemplateMappings"
  );

  return response.data;
};

export const getCompanyTemplates = async () => {
  const { data } = await client.query<{
    changeOrderApprovalTemplates: ChangeOrderApprovalTemplate[];
  }>({
    query: GET_CHANGE_ORDER_APPROVERS_TEMPLATES,
  });

  return data.changeOrderApprovalTemplates;
};

// Cache objects to store templates, template mappings, and results based on parameters
let cachedTemplates: ChangeOrderApprovalTemplate[] | null = null;
let cachedTemplateMappings: { [key: string]: string } | null = null;

// Cache for storing results based on status, coType, and approvalType
const resultCache = new Map<string, ChangeOrderApprovalTemplate | null>();

export const createMandatoryCoApprovers = (
  status: string,
  coType: ChangeOrderType,
  approvalType: EcoDefaultApproval
) => {
  return async () => {
    // Key for caching the result of the specific combination of inputs
    const cacheKey = `${status}-${coType}-${approvalType}`;

    // Return cached result if it exists
    if (resultCache.has(cacheKey)) {
      return resultCache.get(cacheKey);
    }

    // Skip certain statuses
    if (status !== "PRODUCTION" && status !== "PROTOTYPE") {
      return;
    }

    // Check if templates and template mappings are already cached
    const templates =
      cachedTemplates || (cachedTemplates = await getCompanyTemplates());
    const templateMappings =
      cachedTemplateMappings ||
      (cachedTemplateMappings = await getCompanyTemplateMappings());

    // Generate template key from status
    const [firstChar, ...restChar] = status.toString();
    const templateKey =
      coType?.toLocaleLowerCase() ??
      `${coType.toLowerCase()}${firstChar}${restChar.join("").toLowerCase()}`;
    const templateId = templateKey
      ? templateMappings[templateKey] ?? null
      : null;

    if (!templateId) {
      // Cache the result as null if no templateId is found
      resultCache.set(cacheKey, null);
      return;
    }

    const templateMatch = templates?.find(
      (tpl) =>
        tpl.approvalType === approvalType.toString() && tpl.id === templateId
    );

    // Cache the found template or null if no match
    resultCache.set(cacheKey, templateMatch || null);

    return templateMatch;
  };
};
