import "./index.scss";
import React, {Component}           from "react";
import TimeStampColumn  from "../../../../common/timestamp-column";
import ChangeOrderItem  from "../../../../../ui/change-order-item";
import DescriptionBlock from "../../../../common/description-block";

class Details extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            descriptionLength: 420,
            showFullDesc: false,
            fiveLines: false // More than five lines
        }

        this.toggleDesc = this.toggleDesc.bind(this);
    }
    
    toggleDesc(){
        this.setState(prevState => ({
            showFullDesc: !prevState.showFullDesc
        }))
    }

    componentDidMount() {
        setTimeout(() => {
            if (document.querySelector(".w-extended").clientHeight > 90) {
                this.setState({
                    fiveLines: true,
                    showFullDesc: true
                })
            }
        }, 0)
    }

    render()
    {
        let release = this.props.release;
        let longDesc = release.description.length > this.state.descriptionLength
        let showFullDesc = this.state.showFullDesc
        let markup =

            <div className="details-block">
                <div className="release-heading">
                    <div className="name-holder">
                        <h1>{release.name}</h1>
                    </div>
                </div>

                <div className="release-properties">
                    <div className="items-block">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Release No:</th>
                                    <td>{release.releaseNo}</td>
                                </tr>
                                <tr>
                                    <th>Author:</th>
                                    <td>{release.creator ? release.creator.firstName + " " + release.creator.lastName : ""}</td>
                                </tr>
                                <tr>
                                    <th>Time:</th>
                                    <td><TimeStampColumn value={release.created} format='date-time-with-long-format'/></td>
                                </tr>

                            </tbody>

                        </table>
                        <div>
                            <DescriptionBlock
                                position={`bottom w-extended ${showFullDesc && 'less'} side`}
                                heading='DESCRIPTION'
                                showFullDesc={!showFullDesc}
                                longDesc={longDesc || this.state.fiveLines}
                                toggleDesc={this.toggleDesc}
                                description={release.description}
                            />

                            <div className="change-order-wrapper">
                                <div className="heading">CHANGE ORDER</div>
                                <div>
                                {
                                    release.refCo?
                                    <ChangeOrderItem
                                        resolution = {release.refCo.resolution}
                                        status = {release.refCo.status}
                                        con    = {release.refCo.con}
                                        id     = {release.refCo._id}
                                        target = '_blank'
                                    /> : ''
                                }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        return markup
    }
}

export default Details;
