import { ComponentLink } from "common/components";
import { CellParams } from "../cell.types";
import { colorPalette } from "components/themes";

const CPNRenderCell = ({ row }: CellParams) => {
  return (
    <span style={{ fontSize: "0.875rem", color: colorPalette.green }}>
      <ComponentLink item={row} />
    </span>
  );
};

export const CPNCell = {
  renderCell: CPNRenderCell,
  field: "cpn",
  width: 100,
  headerName: "CPN",
  valueGetter({ row }: CellParams) {
    return row.cpn.displayValue;
  },
};
