import React, {Component}           from "react"
import Utils                        from "../../../../../modules/utils"
import { connect }                  from "react-redux"
import buildAction                  from "../../../../../helpers/buildAction"
import ASSEMBLY_EDIT                from "../../../../../action-types/assembly-edit.js"
import ITEM_ASSEMBLY                from "../../../../../action-types/item-assembly"
import Schemas                      from "../../../../../modules/schemas"
import DeleteSrc                    from "../../../../../assets/icons/cross-icon.js"
import AlertIcon                    from "../../../../../assets/icons/alert-icon"
import WarningTriangleIcon          from "../../../../../assets/icons/warning-triangle"
import SourcingUtils                from "../../sourcing/sourcing-utils"
import ExtendedTable                from "../../../common/extended-table"
import Permissions                  from "../../../../../modules/schemas/permissions"
import AddAssemblyIcon              from "../../../../../assets/icons/assembly-add-icon"
import ImportIcon                   from "../../../../../assets/icons/import-icon"
import WebSrcIcon                   from "../../../../../assets/icons/newcomp-web"
import ManualIcon                   from "../../../../../assets/icons/newcomp-manual"
import PlusIcon                     from "../../../../../assets/icons/plus.js"
import InlineIcon                   from "../../../../ui/icon/inline-icon.js"
import validations                  from "../../../../../modules/validations"
import {debounce, isEqual}          from "lodash"
import ComponentSuccessPopover      from "../../../common/component-success-popover";
import TimeStampColumn              from "../../../common/timestamp-column";
import VendorIndexElement           from "../../../common/vendor-index-element";
import { RevisionField } from "@duro/base/revision-field/";
import CpnField                     from "../../../common/cpn-field";
import { StatusField } from "@duro/base/status-field";
import CurrencyField                from "../../../common/currency-field";
import { getHeadings, HEADINGS }    from "../../extended-table/helpers";
import MassField                    from "../../../common/mass-field";
import ValidationRunningModal       from "../../validation-running-modal";
import UI                           from "../../../../../action-types/ui";
import { DuroMenu } from "../../../../../../common/components/duroMenu";
import { EditTabsContext } from "design/pages/common/editTabsContextProvider";

const REF_DES_DELIMITER_REGEX = /,| |:|;/;
const TAB_NAME = "assemblyTab";

export class Assembly extends Component
{
    static contextType = EditTabsContext;
    constructor(props, context)
    {
        super(props, context)
        this.createInputs                   = this.createInputs.bind(this)
        this.createChild = this.createChild.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
        this.onTableChangeDebounced = debounce(() => { }, 2000)
        this.removeChild                    = this.removeChild.bind(this)
        this.onChange                       = this.onChange.bind(this)
        this.getMassIcon                    = this.getMassIcon.bind(this)

        this.syncSearchInputsWithList = this.syncSearchInputsWithList.bind(this)
        this.syncExtentendTable             = this.syncExtentendTable.bind(this)
        this.afterSyncWithParentState       = this.afterSyncWithParentState.bind(this)

        this.showHideAssemblySearch         = this.showHideAssemblySearch.bind(this)
        this.syncChildrenWithStore = this.syncChildrenWithStore.bind(this)
        this.syncChildrenWithStoreDebounce = debounce(this.syncChildrenWithStore, 100)

        this.isItemNumberOnly = (this.props.tabsType === "prd") || (this.props.tabsType === "cmp" && this.props.category && Utils.shouldDisplayOnlyItemNumber(this.props.category, window.__displayRefDesAndItemNumber))

        if (this.props.objectType === 'prd')
        {
            this.validations = validations.product.children
        }
        else if (this.props.objectType === 'cmp')
        {
            this.validations = validations.component.children
        }

        let styles;
        try
        {
            styles = window.__userStyles.styles.assemblyTable || {}
        }
        catch(error){
            styles = {}
        }

        let refDesField = {}

        if (!this.isItemNumberOnly)
        {
            refDesField = getHeadings(
                [{ ...HEADINGS.refDes, position: 6, input: true }],
                styles
            )[0];
        }

        if (Utils.isRefDesORItemNumberColumn(styles.defaultSortColumnName))
        {
            styles.defaultSortColumnName = "cpn"
        }

        this.state =
        {
            success_message: "",
            newlyAddedChildsFromSheet: [],
            allowMultiple : false,
            pauseAutoSorting: false,
            search :
            {
                class      : "",
                value      : "",
                multiple   : false,
                results    : [],
                selections : []
            },

            currentSortItemAscending: ("defaultSortAssending" in styles ? styles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in styles ? styles.defaultSortColumnName : "cpn"),
            headings: this.createHeadings(styles),
            syncWithParentState: false,
            showPopover: false,
        }

        if (Object.getOwnPropertyNames(refDesField).length > 0)
        {
            this.state.headings.splice(6, 0, refDesField)
        }
    }

    createHeadings(styles)
    {
        let headings =
        [
            { ...HEADINGS.cpn, width: 254 },
            HEADINGS.name,
            { ...HEADINGS.quantity, input: true },
            HEADINGS.category,
            HEADINGS.procurement,
            HEADINGS.cmpState,
            HEADINGS.revision,
            {
            ...HEADINGS.itemNumber,
            input: true,
            minWidth: 71,
            width: 120,
            },
            HEADINGS.eid,
            HEADINGS.status,
            { ...HEADINGS.notes, input: true },
            HEADINGS.mass,
            HEADINGS.unitOfMeasure,
            HEADINGS.unitPrice,
            HEADINGS.totalPrice,
            HEADINGS.leadTime,
            HEADINGS.description,
            HEADINGS.mpn,
            HEADINGS.manufacturer,
            HEADINGS.lastUpdated,
            HEADINGS.remove,
        ]

        if(window.__customFields && window.__customFields.wasteFieldEnabled && !this.props.isDiffTool)
        {
            headings.push(
            {
                ...HEADINGS.waste,
                input: true,
                position: styles.waste ? styles.waste.position : 2
            },
            {
                ...HEADINGS.extendedQuantity,
                position: styles.extendedQuantity ? styles.extendedQuantity.position : 3
            },
            {
                ...HEADINGS.extendedCost,
                position: styles.extendedCost ? styles.extendedCost.position : 4
            });
        }
        return getHeadings(headings, styles);
    }

    syncExtentendTable() {
        this.setState({ syncWithParentState: true });
    }

    afterSyncWithParentState() {
        this.setState({ syncWithParentState: false });
    }

    countValidationErrorsForNewChildren(newChildren) {
        let errorCount = 0;
        newChildren.forEach(child => {
            if (child.inputs.itemNumber.valid === false) errorCount++;
            if (child.inputs.refDes.valid === false) errorCount++;
            if (child.inputs.quantity.valid === false) errorCount++;
            if (child.inputs.notes.valid === false) errorCount++;
            if (child.inputs.waste.valid === false) errorCount++;
        });
        return errorCount;
    }

    componentWillReceiveProps(nextProps) {
        let {
            newlyAddedChildsFromSheet,
            pauseAutoSorting,
            showPopover,
            success_message,
            syncChildren,
            syncWithParentState
        } = this.state;
        let { children } = this.context.getAssembly();
        let shouldSyncWithStore = false;

        if (nextProps.children && !isEqual(children, nextProps.children)) {
            children = [...nextProps.children]
            children = this.createInputs(children)
        }

        if (nextProps.newlyAddedComponentsFromRightSearch) {
            nextProps.clearNewlyAddedComponentsFromState()
            children = this.getUniqueChildren(nextProps.newlyAddedComponentsFromRightSearch, children);
            pauseAutoSorting = true;
            syncChildren = true;
            shouldSyncWithStore = true;
            showPopover = true;
            children = this.createInputs(children)
            this.context.setAssembly({children});
            const numErrors = this.countValidationErrorsForNewChildren(nextProps.newlyAddedComponentsFromRightSearch);
            if (numErrors) nextProps.toggleErrorCount(false, TAB_NAME, numErrors);
        }

        if (nextProps.newlyCreatedComponent) {
            children = nextProps.newlyCreatedComponent.map((component) =>
                this.createChild(component, component.quantity, component.refDes, component.notes, component.itemNumber, component.waste)
            );

            const components_text = nextProps.newlyCreatedComponent.length === 1 ? " component." : " components."
            success_message = nextProps.actionType === "new" ? "Success. You’ve imported " : "Success. You’ve updated "
            success_message = success_message + nextProps.newlyCreatedComponent.length + components_text

            newlyAddedChildsFromSheet = children.map(child => child.component._id);
            pauseAutoSorting = true
            syncWithParentState = true
            shouldSyncWithStore = true;
            children = this.createInputs(children)
            this.context.setAssembly({children});
            nextProps.clearNewlyCreatedComponentsFromState()
        }

        this.setState({
            newlyAddedChildsFromSheet,
            pauseAutoSorting,
            showPopover,
            success_message,
            syncChildren,
            syncWithParentState,
        }, this.onChange);
        if (shouldSyncWithStore) this.syncChildrenWithStoreDebounce(nextProps, children);
    }

    getUniqueChildren(newList, previousList) {
        return [ ...newList, ...previousList ]
    }

    syncChildrenWithStore(props, _children) {
        const { updateChildren } = props;
        updateChildren([..._children]);
    }

    addInputsInChildren = () => {
        let { children } = this.context.getAssembly();
        if (children?.length && !children.every((item) => "inputs" in item)) {
            children = this.createInputs(children);
            this.context.setAssembly({children});
        }
    }

    componentDidUpdate() {
       this.addInputsInChildren();
    }

    componentWillMount() {
       this.addInputsInChildren();
    }

    createInputs(startingChildren) {
        let children = startingChildren.map((child) => {
            const newChild = { ...child };

            if (!newChild.inputs) {
                newChild.inputs = {
                    quantity: { value: child.quantity, },
                    refDes: { value: child.refDes || "", },
                    itemNumber: { value: child.itemNumber || "", },
                    notes: { value: child.notes || "", },
                    waste: { value: child.waste || 0, },
                };
            }

            if (this.isItemNumberOnly) {
                newChild.inputs.refDes.value = "";
                newChild.refDes = "";
            }

            return newChild;
        });

        children = this.validateChildrenItemNumber([], -1, children).updatedChildren;

        if (!this.isItemNumberOnly) {
            children = this.validateChildrenRefDes([], -1, children).updatedChildren;
        }

        return children;
    }

    createChild(component, quantity, refDes="", notes="", itemNumber="", waste="")
    {
        if(quantity < 0) quantity = '';
        let child =
        {
            notes     : notes,
            quantity  : quantity,
            refDes    : refDes,
            waste     : waste,
            itemNumber: itemNumber,
            component : component,
            newlyAdded : component.newlyAdded,
        }
        if(quantity !== '0' && !Number(quantity)) {
            child.inputs = {
                quantity :
                {
                    value : "",
                    message: "",
                    valid: false,
                    class: ""
                },
                refDes :
                {
                    value : ""
                },
                itemNumber :
                {
                    value : ""
                },
                notes :
                {
                    value : "",
                    message: "",
                    valid: true,
                    class: ""
                },
                waste :
                {
                    value : "",
                    message : "",
                    valid: true,
                    class: ""
                }
            }
            let error = this.validations.quantity.validateErrors({refDes: ""}, "")
            child.inputs.quantity.message = error ? error[0].message : ""
            child.inputs.quantity.valid = !child.inputs.quantity.message
            child.inputs.quantity.class = child.inputs.quantity.valid ? "" : "invalid"
        }
        return child
    }

    onInputChange(event, i)
    {
        const { children } = this.context.getAssembly();
        let updatedChildren = null;
        let affectedChildIndexes = null;
        let name  = event.target.name
        let value = event.target.value
        let child = children[i]
        let {
            refDes: refDesInput,
            quantity: quantityInput,
            notes: notesInput,
            itemNumber: itemNumberInput,
            waste: wasteInput
        }   = child.inputs;
        let error;
        const prevValidState = child.inputs[name].valid !== false;
        let errorChanges = 0;

        switch(name)
        {
            case "itemNumber": {
                const oldItemNumber = itemNumberInput.value;
                const newItemNumber = value.trim();

                updatedChildren = [...children];
                updatedChildren[i] = {
                    ...child,
                    itemNumber: newItemNumber,
                    inputs: {
                        ...child.inputs,
                        itemNumber: {
                            ...child.inputs.itemNumber,
                            value: newItemNumber,
                        },
                    },
                };

                const res = this.validateChildrenItemNumber(
                    [oldItemNumber, newItemNumber].filter(Boolean),
                    i,
                    updatedChildren
                );
                affectedChildIndexes = res.affectedChildIndexes;
                errorChanges += res.errorChanges;
                updatedChildren = res.updatedChildren;
                break;
            }

            case "quantity": {
                const fetchedQuantityValue = value
                const fetchedRefDesValue   = !this.isItemNumberOnly ? refDesInput.value : "";
                quantityInput.value = fetchedQuantityValue
                child.quantity = fetchedQuantityValue
                error = this.validations.quantity.validateErrors(
                    {
                        refDes: fetchedRefDesValue
                    },
                    Number(fetchedQuantityValue) ?
                        Number(fetchedQuantityValue) : fetchedQuantityValue
                )
                quantityInput.message = error ? error[0].message : ""
                quantityInput.valid = !quantityInput.message
                quantityInput.class = quantityInput.valid ? "" : "invalid"

                break;
            }

            case "refDes": {
                const trimmedValue = value.trim().toUpperCase();
                const oldRefDesValues = refDesInput.value.split(REF_DES_DELIMITER_REGEX);
                const newRefDesValues = trimmedValue.split(REF_DES_DELIMITER_REGEX);

                updatedChildren = [...children];
                updatedChildren[i] = {
                    ...child,
                    refDes: trimmedValue,
                    inputs: {
                        ...child.inputs,
                        refDes: {
                            ...child.inputs.refDes,
                            value: trimmedValue,
                        },
                    },
                };

                const res = this.validateChildrenRefDes(
                    oldRefDesValues.concat(newRefDesValues),
                    i,
                    updatedChildren,
                );
                affectedChildIndexes = res.affectedChildIndexes;
                errorChanges += res.errorChanges;
                updatedChildren = res.updatedChildren;
                break;
            }

            case "notes": {
                const fetchedNotesValue = value;
                notesInput.value = fetchedNotesValue;
                child.notes = fetchedNotesValue;

                error = this.validations.notes.validateErrors({}, fetchedNotesValue);
                notesInput.message = error ? error[0].message : "";
                notesInput.valid = !notesInput.message;
                notesInput.class = notesInput.valid ? "" : "invalid";
                break;
            }

            case "waste": {
                value = value.trim();
                let { roundedValue, isNotNumber} = Utils.roundFieldValue(value, 2);
                if(!isNotNumber)
                {
                    wasteInput.value = roundedValue;
                    child.inputs.waste.value = roundedValue;
                    child.waste = roundedValue;
                    error = this.validations.waste.validateErrors({waste: Number(child.inputs.waste.value) ? Number(child.inputs.waste.value) : roundedValue}, roundedValue);
                    wasteInput.message = error ? error[0].message : "";
                    wasteInput.valid = !wasteInput.message;
                    wasteInput.class = wasteInput.valid ? "" : "invalid";
                }
                else
                {
                    wasteInput.value = value;
                    child.inputs.waste.value = value;
                    child.waste = value;
                    wasteInput.message = "Not a valid input";
                    wasteInput.valid = !wasteInput.message;
                    wasteInput.class = wasteInput.valid ? "" : "invalid";
                }
                break;
            }

            default: {
                // noop
            }
        }

        this.syncChildrenWithStoreDebounce(this.props, updatedChildren ?? children)

        if (updatedChildren) {
            this.context.setAssembly({ children: updatedChildren });
            this.setState(this.state, () => {
                if (errorChanges != 0) {
                    this.props.toggleErrorCount(errorChanges < 0, TAB_NAME, Math.abs(errorChanges));
                }
            });
            return affectedChildIndexes.map(childIndex => ({
                id: updatedChildren[childIndex].component._id,
                index: childIndex,
                input: updatedChildren[childIndex].inputs[name],
            }));
        }

        const currentValidState = !!child.inputs[name].valid;
        if (currentValidState !== prevValidState)
            this.props.toggleErrorCount(currentValidState, TAB_NAME);
        return child.inputs[name]
    }

    getRefDesMap(children) {
        const refDesMap = {};
        children.forEach((child, index) => {
            const { value } = child.inputs.refDes;
            if (value === "") return;

            const refDesValues = value.split(REF_DES_DELIMITER_REGEX);
            refDesValues.forEach(refDes => {
                if (value === "") return;
                if (!refDesMap[refDes]) refDesMap[refDes] = [];
                refDesMap[refDes].push(index)
            });
        });
        return refDesMap;
    }

    getItemNumberMap(children) {
        const isAllowedBlankItemNumber = window.__isAllowedBlankItemNumber;
        const itemNumberMap = {};
        children.forEach((child, index) => {
            const { value } = child.inputs.itemNumber;
            if (isAllowedBlankItemNumber && value === "") return;

            if (!itemNumberMap[value]) {
                itemNumberMap[value] = [index];
            } else {
                itemNumberMap[value].push(index);
            }
        });
        return itemNumberMap;
    }

    validateChildrenRefDes(values, updatedIndex, children) {
        const refDesMap = this.getRefDesMap(children);

        let errorChanges = 0;
        const affectedChildIndexes = [];
        const updatedChildren = children.map((child, index) => {
            const { value } = child.inputs.refDes;
            const refDesValues = value.split(REF_DES_DELIMITER_REGEX).filter(Boolean);

            // No need to update children that are not the one updated and have no ref des or no
            // conflicting ref des.
            if (
                index !== updatedIndex &&
                values.length &&
                (!value || !refDesValues.find(refDes => values.includes(refDes)))
            ) {
                return child;
            }

            affectedChildIndexes.push(index);

            let message = ""

            const duplicateRefDes = refDesValues.filter(rdv => refDesMap[rdv]?.length > 1);
            if (duplicateRefDes.length) {
                message = "Duplicate Ref Des value: " + [...(new Set(duplicateRefDes))].join();
            } else {
                const quantity = child.inputs.quantity.value;
                let error = this.validations.refDes.validateErrors({
                    quantity: Number(quantity) ? Number(quantity) : quantity
                }, value);
                message = error ? error[0].message : "";
            }

            const valid = !message;
            const cssClass = valid ? "" : "invalid";

            if (child.inputs.refDes.valid != valid) {
                errorChanges += valid ? -1 : 1;
            }

            return {
                ...child,
                inputs: {
                    ...child.inputs,
                    refDes: {
                        ...child.inputs.refDes,
                        message,
                        valid,
                        class: cssClass,
                    }
                },
            };
        });

        return { affectedChildIndexes, errorChanges, updatedChildren };
    }

    validateChildrenItemNumber(values, updatedIndex, children) {
        const isAllowedBlankItemNumber = window.__isAllowedBlankItemNumber;
        const itemNumberMap = this.getItemNumberMap(children);

        let errorChanges = 0;
        const affectedChildIndexes = [];
        const updatedChildren = children.map((child, index) => {
            const { value } = child.inputs.itemNumber;

            if (
                updatedIndex !== index &&
                values.length &&
                ((isAllowedBlankItemNumber && value === "") || !values.includes(value))
            ) {
                return child;
            }

            affectedChildIndexes.push(index);

            let message = "";

            if (itemNumberMap[value]?.length > 1) {
                // Duplicate item numbers is the primary error to display.
                message = `Value must be unique within an assembly. Value ${value} is already used in this assembly`;
            } else {
                // If not effected by duplicate error message, check for other errors.
                let isInteger = Utils.isIntegerValue(value)
                let error = this.validations.itemNumber.validateErrors({ isAllowedBlankItemNumber }, isInteger ? Number(value) : value)
                message = error ? error[0].message : "";
            }

            const valid = !message;
            const cssClass = valid ? "" : "invalid";

            if (child.inputs.itemNumber.valid != valid) {
                errorChanges += valid ? -1 : 1;
            }

            return {
                ...child,
                inputs: {
                    ...child.inputs,
                    itemNumber: {
                        ...child.inputs.itemNumber,
                        message,
                        valid,
                        class: cssClass,
                    },
                },
            };
        });

        return { affectedChildIndexes, errorChanges, updatedChildren };
    }

    removeChild(i, isVendorCmp) {
        if (isVendorCmp) return;
        let children = [...this.context.getAssembly()?.children]

        // Removing a row causes the extended table to do a complete re-render that is really slow
        // on large tables.
        if (children.length > 5) this.props.dispatch(buildAction(UI.VALIDATION_START))

        setTimeout(() => {
            let errorChanges = 0;
            const [child] = children.splice(i, 1);

            const { value: oldItemNumber, valid: itemNumberValid } = child.inputs.itemNumber;
            if (oldItemNumber) {
                const res = this.validateChildrenItemNumber([oldItemNumber], -1, children);
                children = res.updatedChildren;
                errorChanges += res.errorChanges;
            }
            if (itemNumberValid === false) errorChanges--;

            const oldRefDesValues = child.inputs.refDes.value.split(REF_DES_DELIMITER_REGEX).filter(Boolean);
            if (oldRefDesValues.length) {
                const res = this.validateChildrenRefDes(oldRefDesValues, -1, children);
                children = res.updatedChildren;
                errorChanges += res.errorChanges;
            }
            if (child.inputs.refDes.valid === false) errorChanges--;

            if (child.inputs.quantity.valid === false) errorChanges--;
            if (child.inputs.notes.valid === false) errorChanges--;
            if (child.inputs.waste.valid === false) errorChanges--;
            this.context.setAssembly({children});
            this.setState(this.state, () => {
                if (errorChanges != 0) {
                    this.props.toggleErrorCount(errorChanges < 0, TAB_NAME, Math.abs(errorChanges));
                }

                this.onChange();
            });
            this.syncChildrenWithStore(this.props, children);
        }, 100);
    }

    syncSearchInputsWithList(item)
    {
        let inputs = this.props.item_assembly.search.inputs
        let updatedInputs = []
        inputs.forEach((input) => {
            if (input.cpn === item.component.cpn)
            {
                input.checked = false
                input.isAddedInList = false
            }
            updatedInputs.push(input)
        })

        const { dispatch } = this.props;
        dispatch(buildAction(ITEM_ASSEMBLY.UPDATE_SEARCH_INPUTS, updatedInputs))
    }

    onChange()
    {
        if(this.props.onChange)
        {
            let obj =
            {
                data  : this.context.getAssembly()?.children ?? [],
                valid : true
            }
            let event = Utils.getEvent(this, obj)
        }
    }

    getMassIcon(component)
    {
        let markup = null
        let isAssembly = component && component.category && Schemas.component.category.getType(component.category).toLowerCase() === "assembly"
        if (!isAssembly) return

        if ((!!component.massStatus && component.massStatus.toUpperCase() === "ERROR") || !component.massStatus)
        {
            markup =
                    <InlineIcon
                        tooltip="Missing mass values for one or more children"
                        tooltipPlace="top"
                        className="warningEl"
                        key={"i"+'-mass-error'}
                        >
                        <AlertIcon/>
                    </InlineIcon>
        }
        else if (!!component.massStatus && component.massStatus.toUpperCase() === "WARNING")
        {
            markup =
                    <InlineIcon
                        tooltip="Manually entered value"
                        tooltipPlace="top"
                        className="warningEl"
                        key={"i"+'-mass-warning'}
                        >
                        <WarningTriangleIcon/>
                    </InlineIcon>
        }

        return markup;
    }

    showHideAssemblySearch(event)
    {
        // this.props.showHideAssemblySearch(true)
        this.props.showHideAssemblySearch()
    }

    render()
    {
        let columns  = this.state.columns
        let { children } = this.context.getAssembly();
        if (children?.length && !children.every((item) => "inputs" in item)) return null
        let { company } = this.props;
        let rows = children.map((child, i) =>
        {
            let activeClass = this.state.newlyAddedChildsFromSheet.length > 0 && this.state.newlyAddedChildsFromSheet.includes(child.component._id)
            let isNewlyAddedChildFromRightSearch = child.newlyAdded

            let assemblyCmp = this.props.mode === "revision" && child.assemblyRevision ? child.assemblyRevision : child.component
            let isVendorCmp = Utils.isVendorCmp(Utils.getVendor(assemblyCmp)) && (!child.isAddedAfterPullRequest && !child.newlyAdded);
            let rowClass = isNewlyAddedChildFromRightSearch || activeClass ? "active" : isVendorCmp ? "managed-by-vendor" : "";
            let leadTime = null;
            let unitPrice = null;
            let totalPrice = null;
            if(assemblyCmp.primarySource)
            {
                leadTime = assemblyCmp.primarySource.leadTime.value !== null && assemblyCmp.primarySource.leadTime.units && assemblyCmp.primarySource.leadTime.value + " " + assemblyCmp.primarySource.leadTime.units

                unitPrice = assemblyCmp.primarySource.unitPrice !== null ? assemblyCmp.primarySource.unitPrice : undefined

                totalPrice = assemblyCmp.primarySource.unitPrice !== null && child.quantity * assemblyCmp.primarySource.unitPrice
            }

            let cpn      = Utils.getCpn(assemblyCmp);

            let description = assemblyCmp.description ? assemblyCmp.description : '';
            let procurement = assemblyCmp.procurement ? assemblyCmp.procurement : '';
            let cmpState = assemblyCmp.cmpState ? assemblyCmp.cmpState : '';
            let lastModified = Utils.dateTimeWithLongFormat(assemblyCmp.lastModified);
            let { mpn, manufacturer } = SourcingUtils.getPrimarySourceMpnAndMfr(assemblyCmp);
            let massPrecision = Utils.getCompanyMassPrecision(company);
            const onChange = event => this.onInputChange(event, i);
            let wasteField = child.inputs['waste'] ? child.inputs['waste']["value"] : 0;
            let { extendedQuantity, extendedCost } = Utils.calculateExtendedQuantityAndCost(wasteField, child.inputs['quantity']["value"], unitPrice);

            let refDesField     = {}

            if (!this.isItemNumberOnly)
            {
                refDesField = {
                    value       : child.inputs['refDes']["value"],
                    inputValue  : child.inputs['refDes']["value"],
                    showVendorTooltip  : isVendorCmp,
                    notLink     : true,
                    haveInput   : true,
                    inputProps  : {
                        type: "text",
                        name: 'refDes',
                        inputClass: child.inputs['refDes']["class"],
                        message: child.inputs['refDes']["message"],
                        dependent: 'quantity',
                        onChange,
                    }
                };
            }

            let cells = {

                "cpn" : {
                    value       : cpn,
                    displayValue: <span className='link'><CpnField item={assemblyCmp} cpn={cpn}/></span>,
                    tooltip     : cpn
                },
                "name" : {
                    value       : assemblyCmp.name,
                    displayValue: assemblyCmp.name,
                    tooltip     : assemblyCmp.name
                },
                "category" : {
                    value       : Schemas.component.category.getDisplayName(assemblyCmp.category),
                    displayValue: Schemas.component.category.getDisplayName(assemblyCmp.category),
                    tooltip     : Schemas.component.category.getDisplayName(assemblyCmp.category)
                },
                "procurement" : {
                    value       : procurement,
                    displayValue: procurement,
                    tooltip     : procurement
                },
                "cmpState" : {
                    value       : cmpState,
                    displayValue: cmpState,
                    tooltip     : cmpState
                },
                 "description" : {
                    value       : description,
                    displayValue: description,
                    tooltip     : description
                },
                "mpn":
                {
                    value       : mpn,
                    tooltip     : mpn,
                    displayValue: mpn,

                },
                "manufacturer":
                {
                    value       : manufacturer,
                    tooltip     : manufacturer,
                    displayValue: manufacturer,

                },
                "lastUpdated":
                {
                    value       : assemblyCmp.lastModified ? assemblyCmp.lastModified : '',
                    tooltip     : assemblyCmp.lastModified ? `${lastModified.dateValue} ${lastModified.timeValue}` : '',
                    displayValue: assemblyCmp.lastModified && <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={assemblyCmp.lastModified} wrapperClassName='edit-mode' />,
                    cellClass   : 'timestamp-column-edit-mode'
                },
                "revision" : {
                    value       : assemblyCmp.revision,
                    displayValue: <RevisionField item={assemblyCmp} />,
                    tooltip     : "tooltip"
                },
                "itemNumber" : {
                    value       : child.inputs['itemNumber']["value"],
                    inputValue  : child.inputs['itemNumber']["value"],
                    showVendorTooltip  : isVendorCmp,
                    cellClass   : "editable-vendor-fields",
                    notLink     : true,
                    haveInput   : true,
                    inputProps  : {
                        type: "text",
                        name: 'itemNumber',
                        autoComplete: "off",
                        inputClass: child.inputs['itemNumber']["class"],
                        message: child.inputs['itemNumber']["message"],
                        onChange,
                    }
                },
                "eid" : {
                    value       : assemblyCmp.eid,
                    displayValue: assemblyCmp.eid,
                    tooltip     : assemblyCmp.eid
                },
                "status" : {
                    value       : assemblyCmp["status"],
                    displayValue:  <StatusField item={assemblyCmp} status={assemblyCmp["status"]}/>,
                    tooltip     : assemblyCmp["status"]
                },
                "unitPrice" : {
                    value       : unitPrice,
                    displayValue: [
                                    <div className="price-value" key={i+'-unit-price'}>
                                        {unitPrice && <CurrencyField symbol={this.props.defaultCurrency} value={unitPrice}/>}
                                    </div>,
                                    SourcingUtils.haveInValidCosting(assemblyCmp) === true &&
                                        <InlineIcon
                                            tooltip="Incomplete Sources"
                                            tooltipPlace="top"
                                            className="warningEl"
                                            key={i+'-unit-price-icon'}
                                            >
                                            <AlertIcon/>
                                        </InlineIcon>
                                ],
                    tooltip     : unitPrice && (this.props.defaultCurrency + Number(unitPrice).toFixed(5) )
                },
                "mass" : {
                    value       : assemblyCmp.mass,
                    displayValue: [
                                    <div className="price-value" key={i+'-mass'}>
                                        <MassField
                                            mass={assemblyCmp.mass}
                                            massPrecision={massPrecision}
                                        />
                                    </div>,
                                    this.getMassIcon(assemblyCmp)
                                ],
                    tooltip     : Utils.getRoundedMass(assemblyCmp.mass, massPrecision)
                },
                "totalPrice" : {
                    value       : totalPrice,
                    displayValue: <CurrencyField symbol={this.props.defaultCurrency} value={totalPrice}/>,
                    tooltip     : (this.props.defaultCurrency + Number(totalPrice).toFixed(5) )
                },
                "leadTime" : {
                    value       : leadTime ? Utils.stringValueToDays(leadTime.split(' ')[0], leadTime.split(' ')[1]) : -1,
                    displayValue: leadTime,
                    tooltip     : leadTime
                },
                "unitOfMeasure" : {
                    value       : assemblyCmp.unitOfMeasure,
                    displayValue: assemblyCmp.unitOfMeasure,
                    tooltip     : assemblyCmp.unitOfMeasure
                },
                "quantity" : {
                    value       : child.inputs['quantity']["value"],
                    showVendorTooltip  : isVendorCmp,
                    inputValue  : child.inputs['quantity']["value"],
                    notLink     : true,
                    haveInput   : true,
                    inputProps  : {
                                    type: "text",
                                    name: 'quantity',
                                    inputClass: child.inputs['quantity']["class"],
                                    message: child.inputs['quantity']["message"],
                                    dependent: this.isItemNumberOnly ? null : 'refDes',
                                    onChange,
                    }
                },
                "waste" : {
                    value : Utils.roundFieldValue(wasteField, 2).roundedValue,
                    inputValue : Utils.roundFieldValue(wasteField, 2).roundedValue,
                    cellClass : "wasteField",
                    notLink : true,
                    haveInput : true,
                    inputProps :
                    {
                        type: "text",
                        name: 'waste',
                        inputClass: child.inputs['waste'] ? child.inputs['waste']['class'] : "",
                        message:  child.inputs['waste'] ? child.inputs['waste']['message'] : "",
                        onChange
                    }
                },
                "extendedQuantity" : {
                    value : extendedQuantity,
                    cellClass : "extended-quantity",
                    displayValue: Utils.roundFieldValue(extendedQuantity, 2).roundedValue,
                    tooltip: extendedQuantity
                },
                "extendedCost" : {
                    value : extendedCost,
                    cellClass : "extended-cost",
                    displayValue: Utils.concatCurrencySymbol(extendedCost, this.props.defaultCurrency),
                    tooltip: Utils.concatCurrencySymbol(extendedCost, this.props.defaultCurrency)
                },
                "notes" : {
                    value       : child.inputs['notes']["value"],
                    inputValue  : child.inputs['notes']["value"],
                    showVendorTooltip  : isVendorCmp,
                    notLink     : true,
                    haveInput   : true,
                    inputProps  : {
                        type: "text",
                        name: 'notes',
                        autoComplete: "off",
                        inputClass: child.inputs['notes']["class"],
                        message: child.inputs['notes']["message"],
                        onChange,
                    }
                },

                "remove":  {
                    value        : "",
                    cellClass    : "remove-col",
                    notLink      : true,
                    displayValue : <div onClick={() => this.removeChild(i, this.props.isVendorCmp && isVendorCmp)}>
                                        <InlineIcon
                                            tooltip="Remove"
                                            tooltipPlace="top"
                                            >
                                            <DeleteSrc/>
                                        </InlineIcon>
                                    </div>
                },
                rowLink         : "/component/view/" + child.component._id,
                rowClassName    : rowClass,
                rowId           : Utils.generateUniqueId(),
                item_id         : child.component._id,
                indexTableEl    : isVendorCmp ?
                                    <VendorIndexElement vendorTooltipText={this.props.vendorTooltipText} />
                                : null
            }

            if (!this.isItemNumberOnly)
            {
                cells["refDes"]     = refDesField;
            }

            return cells
        })

        let actionList = [
            {
                label: "Add From Library",
                icon: () => <AddAssemblyIcon />,
                onClick: this.props.showHideAssemblySearch,
                labelWapperClass: 'menu-option'
            },
            {
                label: "Add From File",
                icon: () => <ImportIcon />,
                onClick: () => this.props.toggleModal("displayFileImport", true),
                labelWapperClass: 'menu-option'
            },
            {
                label: "Add From Vendor",
                icon: () => <WebSrcIcon />,
                onClick: () => this.props.toggleModal("displayVendorModal", true),
                labelWapperClass: 'menu-option'
            },
            {
                label: "Create Manually",
                icon: () => <ManualIcon />,
                onClick: () => this.props.toggleModal("displayManualModal", true),
                labelWapperClass: 'menu-option'
            }
        ];

        let headings = this.state.headings
        Utils.updateHeadings(headings)
        let markup =
            <div className="assembly-tab assembly-tab-edit-common">
                <ValidationRunningModal
                    open={true}
                    message="Checking for Validation Issues."
                />
               {
                   this.state.showPopover ?
                    <ComponentSuccessPopover
                        cmpId={this.props.cmpId}
                        className={"without-add-another"}
                        />
                    : null
               }
                <ExtendedTable
                    vendorTooltipText={this.props.vendorTooltipText}
                    wrapperClassName="assembly-table-edit"
                    wrapperSelectorClass="assembly-table-edit"
                    stylesName="assemblyTable"
                    themeDataTableId={"assemblyEdit"}
                    headings={headings}
                    pauseAutoSorting={this.state.pauseAutoSorting}
                    rows={rows || []}
                    borderedTable={true}
                    allowRowSelect={false}
                    endStaticColumns={1}
                    syncWithParentState={this.state.syncWithParentState}
                    afterSyncWithParentState={this.afterSyncWithParentState}
                    onHideOfColumnSettings={this.props.onHideOfColumnSettings}
                    displayColumnsSetting={this.props.displayColumnsSetting}
                    autoFitColumnsWidth={this.props.autoFitColumnsWidth}
                    afterAutoFitColumnsWidth={this.props.afterAutoFitColumnsWidth}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    includeToolBar={true}
                    tableActionButtons={this.props.getIconsActionsList()}
                    resultText={`${rows.length} Components`}
                    onTableChangeDebounced={this.onTableChangeDebounced}
                    footerRow={ Permissions.can("create", "component", this.props.user.data) ? {
                                bordered: true,
                                dataCellEl: <p>Add Components</p>,
                                indexCellEl: Permissions.can("create", "component", this.props.user.data) &&
                                            <div>
                                                <DuroMenu anchorComponent=
                                                              {<InlineIcon className="plus-icon"
                                                                           customStyles={{ width: "100%" }}>
                                                                  <PlusIcon/>
                                                              </InlineIcon>}
                                                          name="assembly"
                                                          options={actionList}
                                                          customButtonStyles={{ display: "inline-block" }}>
                                                </DuroMenu>
                                             </div>
                            }
                            :
                            rows.length === 0 &&
                            {
                                dataCellEl: <p>No Assembly added</p>
                            }
                        }

                />

            </div>
        return markup
    }
}

const mapDispatchToProps = dispatch => ({
  updateChildren: (children) => dispatch({type: ASSEMBLY_EDIT.ON_INPUT_CHANGE, children: children, storeSync: true, modified: true}),
  dispatch,
})

const mapStateToProps = state => ({
  user: state.user,
  children: state.assembly_edit.children,
  assembly_edit: state.assembly_edit,
  company: state.company
})

export default connect(mapStateToProps, mapDispatchToProps)(Assembly)
