import React, {
  useMemo,
  useState,
  useCallback,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
} from "react";
import {
  DataGridProProps,
  GridCallbackDetails,
  GridCellCheckboxRenderer,
  GridColDef,
  GridColumnHeaderParams,
  GridHeaderCheckbox,
  GridInitialState,
  GridPinnedColumns,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowIdGetter,
  GridSelectionModel,
  GridValidRowModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
  GridRowTreeNodeConfig,
} from "@mui/x-data-grid-pro";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Box, Typography } from "@mui/material";
import { StyledBaseGrid } from "./styledBaseGrid";
import { DuroCheckbox } from "../checkbox";
import {
  InitialDivider,
  ToolbarContainer,
  ToolbarItemsContainer,
  ToolbarWrapper,
} from "./gridToolbar";
import { colorPalette } from "components/themes";
import { EmptyOverlay } from "features/changeorders";
import { ImageContextProvider } from "../imageContext/imageContextProvider";
import styled from "@emotion/styled";
import {
  NavButtonType,
  createNavButtons,
} from "./toolbar-items/create-table-nav-buttons";
import { GRID_TREE_DATA_GROUPING_COL_DEF } from "@mui/x-data-grid-pro/hooks/features/treeData/gridTreeDataGroupColDef";
import { CancelOutlined } from "@mui/icons-material";

export type CustomGroupingCellElement = (
  params: GridRenderCellParams
) => ReactNode;

export function getCustomGroupingCellProps(params: GridRenderCellParams) {
  return {
    params,
    childrenCount: getChildrenCount(params.rowNode),
  };
}

type CheckboxColDef = Partial<
  Omit<GridColDef, "renderCell"> & {
    renderCell: (props: GridCheckboxParams) => ReactNode;
  }
>;

interface CustomToolbarProps {
  renderItems: React.ReactNode; // Additional items to render in the toolbar
  toolbarLeftItems?: JSX.Element[]; // Additional items to render in the toolbar
  inputLabel?: string; // The label for the dropdown
  rowCount: number;
  filtersCount?: number;
  onClearFilters?: () => void;
}

export const CustomToolbar: React.FC<CustomToolbarProps> = ({
  renderItems,
  rowCount,
  toolbarLeftItems,
  filtersCount = 0,
  onClearFilters,
}) => {
  return (
    <ToolbarWrapper>
      <ToolbarContainer>
        <GridToolbarContainer>
          {toolbarLeftItems}
          <InitialDivider sx={{ width: "2px" }} />
          <ToolbarItemsContainer>{renderItems}</ToolbarItemsContainer>
        </GridToolbarContainer>
      </ToolbarContainer>
      {filtersCount > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: colorPalette.green,
            margin: "0rem 1rem 0rem 0.5rem",
          }}
        >
          {filtersCount} {filtersCount === 1 ? "Filter" : "Filters"} active
          <StyledDeleteIcon onClick={onClearFilters}>
            <CancelOutlined />
          </StyledDeleteIcon>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="right"
        flex="1"
        color={colorPalette.taupeGray}
      >
        {rowCount === 1 ? `${rowCount} result` : `${rowCount} results`}
      </Box>
    </ToolbarWrapper>
  );
};

const CheckboxContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "checked" && prop !== "disabled",
})<{
  checked: boolean;
  disabled?: boolean;
}>(({ checked, disabled }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  cursor: disabled ? "not-allowed" : "pointer",
  "& svg": {
    display: checked ? undefined : "none",
  },
  "&:hover svg": {
    display: disabled ? "none" : "inherit",
  },
  "& p": {
    display: checked ? "none" : undefined,
  },
  "&:hover p": {
    display: disabled ? "inherit" : "none",
  },
}));

function getChildrenCount(node: GridRowTreeNodeConfig): number {
  return node.children?.length || 0;
}

export type GridCheckboxParams = GridRenderCellParams &
  PropsWithChildren<{ index: number }>;

export function GridCheckbox(props: GridCheckboxParams) {
  const { children, index, value: checked, ...checkboxProps }: any = props;

  if (children) return children;

  return (
    <CheckboxContainer checked={checked}>
      <GridCellCheckboxRenderer {...checkboxProps} value={checked} />
      <Typography variant="body2">{index + 1}</Typography>
    </CheckboxContainer>
  );
}

type CustomNavItem = {
  position: number;
  element: JSX.Element;
};

function insertElementsAtPositions(
  existingElements: JSX.Element[],
  navItems: CustomNavItem[]
): JSX.Element[] {
  const updatedElements = [...existingElements];

  navItems.forEach((item) => {
    updatedElements.splice(item.position, 0, item.element);
  });

  return updatedElements;
}

export const DuroGridBasic: React.FC<
  DataGridProProps & {
    toolbarItems?: NavButtonType[];
    toolbarLeftItems?: JSX.Element[];
    apiRef?: MutableRefObject<any>;
    checkboxColumnDefinition?: CheckboxColDef;
    initialPinnedColumns?: GridPinnedColumns;
    selectedRowClassName?: string;
    deselectedRowClassName?: string;
    getRowId?: GridRowIdGetter<GridValidRowModel>;
    onSelectionChange?: (
      selectionModel: GridSelectionModel,
      details: GridCallbackDetails
    ) => void;
    pinnedColumns?: GridPinnedColumns;
    setSelectedRows?: (selectedRows: string[]) => void;
    groupingHeaderName?: string;
    useTreeData?: boolean;
    customNavItems?: CustomNavItem[];
    toolbarProps?: Partial<CustomToolbarProps>;
    showToolbar?: boolean;
    isRowDisabled?: (row: any) => boolean;
  }
> = ({
  toolbarItems = [],
  apiRef,
  checkboxColumnDefinition,
  initialPinnedColumns,
  getRowId,
  onSelectionChange,
  selectedRowClassName = "",
  deselectedRowClassName = "",
  pinnedColumns,
  setSelectedRows,
  groupingHeaderName = "Group",
  useTreeData = false,
  customNavItems = [],
  toolbarProps = {},
  toolbarLeftItems = [],
  showToolbar = true,
  isRowDisabled,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<GridSelectionModel>([]);
  const backupApiRef = useGridApiRef();
  const internalApiRef = apiRef ?? backupApiRef;

  const navButtons = createNavButtons({
    navItems: toolbarItems,
    state: {
      loading: loading,
      selectedItems: selectedItems as string[],
      setLoading: setLoading,
      setSelectedItems: setSelectedItems,
    },
  });

  const renderCell = useCallback(
    (params: GridRenderCellParams) => {
      const index =
        internalApiRef.current?.getRowIndexRelativeToVisibleRows(params.id) ||
        0;

      const row = internalApiRef.current?.getRow(params.id);
      const isDisabled = isRowDisabled?.(row) ?? false;

      const newParams = { ...params, index, disabled: isDisabled };

      if (checkboxColumnDefinition?.renderCell) {
        return checkboxColumnDefinition.renderCell(newParams);
      }

      return <GridCheckbox {...newParams} />;
    },
    [checkboxColumnDefinition, internalApiRef]
  );

  const columns: Array<GridColDef> = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        ...checkboxColumnDefinition,
        type: "string",
        renderCell,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <GridHeaderCheckbox {...params} />;
        },
        minWidth: 32,
        width: 32,
      },
      ...props.columns
        .filter(
          (col) =>
            col.field !== GRID_CHECKBOX_SELECTION_COL_DEF.field ||
            col.field !== GRID_TREE_DATA_GROUPING_COL_DEF
        )
        .map((col) => ({
          ...col,
          cellClassName: (params: any) => {
            const row = internalApiRef.current?.getRow(params.id);
            const isDisabled = isRowDisabled?.(row) ?? false;
            return isDisabled ? "disabled-row" : "";
          },
        })),
    ],
    [checkboxColumnDefinition, renderCell, props.columns]
  );
  const getRowClassName = useCallback(
    (params: GridRowClassNameParams) => {
      const rowClass = params.indexRelativeToCurrentPage % 2 ? "odd" : "even";
      const isDisabled = isRowDisabled?.(params.row) ?? false;
      return isDisabled
        ? `${rowClass} disabled-row`
        : `${rowClass} ${selectedRowClassName}`;
    },
    [
      internalApiRef,
      selectedRowClassName,
      deselectedRowClassName,
      isRowDisabled,
    ]
  );

  const componentsProps = useMemo(
    () => ({
      toolbar: {
        rowCount: props.rows.length,
        renderItems: insertElementsAtPositions(navButtons, customNavItems),
        toolbarLeftItems,
        ...toolbarProps,
      },
      noRowsOverlay: {
        message: "No Products or Components added",
      },
    }),
    [navButtons, props.rows.length]
  );

  const [initialState] = useState<GridInitialState>(() => {
    const initPinCols = {
      ...initialPinnedColumns,
      left: [
        GRID_CHECKBOX_SELECTION_COL_DEF.field,
        ...(initialPinnedColumns?.left || []).filter(
          (field) => field !== GRID_CHECKBOX_SELECTION_COL_DEF.field
        ),
      ],
    };

    return {
      pinnedColumns: initPinCols,
    };
  });

  const updatedPinnedColumns = useMemo(() => {
    if (!pinnedColumns) {
      return pinnedColumns;
    }
    return {
      ...pinnedColumns,
      left: [
        GRID_CHECKBOX_SELECTION_COL_DEF.field,
        ...(pinnedColumns?.left || []).filter(
          (field) => field !== GRID_CHECKBOX_SELECTION_COL_DEF.field
        ),
      ],
    };
  }, [pinnedColumns]);

  const { children, ...restProps } = props;

  const handleRowSelection = (selection: GridSelectionModel) => {
    const validSelection = selection.filter((id) => {
      const row = internalApiRef.current?.getRow(id);
      return !(isRowDisabled?.(row) ?? false);
    });
    setSelectedItems(validSelection);
    setSelectedRows?.(validSelection as string[]);
    if (onSelectionChange) onSelectionChange(validSelection, {});
  };

  return (
    <ImageContextProvider>
      <StyledBaseGrid
        {...restProps}
        treeData={useTreeData}
        getTreeDataPath={(row) => row?.path ?? []}
        defaultGroupingExpansionDepth={useTreeData ? -1 : 0}
        apiRef={internalApiRef}
        autoPageSize
        loading={props.loading || loading}
        columns={columns}
        rowHeight={30}
        headerHeight={30}
        checkboxSelection
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        hideFooter
        initialState={initialState}
        pinnedColumns={updatedPinnedColumns}
        onSelectionModelChange={handleRowSelection}
        getRowId={getRowId}
        getRowClassName={getRowClassName}
        components={{
          NoRowsOverlay: EmptyOverlay,
          BaseCheckbox: DuroCheckbox,
          ...(showToolbar && {
            Toolbar: CustomToolbar,
          }),
        }}
        componentsProps={componentsProps}
        sx={{
          ".MuiCheckbox-indeterminate svg": {
            width: 18,
          },
          ".MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "flex",
            },
          ".disabled-row": {
            pointerEvents: "none",
          },
        }}
      />
    </ImageContextProvider>
  );
};

const StyledDeleteIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  marginLeft: "0.5rem",
  "& svg": {
    color: colorPalette.white,
    "& path": {
      fill: colorPalette.white,
    },
    "&:hover": {
      "& path": {
        fill: colorPalette.green,
      },
    },
  },
});
