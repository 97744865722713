import { colorPalette } from "@duro/themes";
import { Badge, Box, Tab as MuiTab, styled, Tabs } from "@mui/material";
import { useDataTestId } from "common/hooks/useDataTestId";
import React, { useMemo } from "react";

const StyledTab = styled(MuiTab, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<Pick<ITabProps, "isActive" | "customStyles">>(
  ({ customStyles, isActive, theme }) => ({
    borderRadius: "0.4rem",
    color: theme.duro.tabs.tabButton.baseTextColor,
    fontSize: "1rem",
    margin: "0 0.625rem",
    minHeight: "unset",
    overflow: "unset",
    padding: "0",
    textTransform: "none",
    fontWeight: 400,
    "&.Mui-selected, &:hover, &:focus": {
      color: theme.palette.common.white,
      opacity: 1,
      background: "unset",
      "& .MuiBadge-badge": {
        color: theme.palette.common.white,
      },
    },
    "&:first-of-type": {
      marginLeft: "0",
    },
    "&:last-child": {
      marginRight: "0",
    },
    "&.tabs-class": {
      backgroundColor: `${
        isActive ? colorPalette.darkGrey : colorPalette.blackRussian
      }`,
      color: `${isActive ? colorPalette.white : colorPalette.taupeGray}`,
      borderRadius: "0.5rem 0.5rem 0 0",
      margin: "0.125rem",
      minHeight: "1.75rem",
      padding: "0.313rem 1rem 0.313rem 1.8rem",
    },
    ...customStyles,
  })
);

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "badgeStyles",
})<{ badgeContent: number | undefined; badgeStyles?: badgeStyles }>(
  ({ theme, badgeStyles }) => {
    const styles: any = {
      borderRadius: "0.4rem",
      justifyContent: "center",
      padding: "0.125rem 0.375rem 0.125rem 0.375rem",
      width: "90%",
      "& .MuiBadge-badge": {
        backgroundColor: theme.duro.tabs.tabButton.error.badgeColor,
        color: theme.duro.tabs.tabButton.baseTextColor,
        fontSize: theme.typography.fontSize,
        height: "1.375rem",
        top: "-0.25rem",
        width: "1.375rem",
      },
      ...badgeStyles,
    };

    return styles;
  }
);

export interface ITabContext {
  styleProps?: {
    padding?: string;
  };
}

export type badgeStyles = {
  flex?: number;
  justifyContent?: string;
};

export interface CustomStyles {
  fontSize?: string;
  marginBottom?: string;
  marginLeft?: string | number;
  minWidth?: string;
}
export interface ITabProps {
  badgeStyles?: badgeStyles;
  children: React.ReactNode;
  className?: string;
  customStyles?: CustomStyles;
  disabled?: boolean;
  errorCount?: number;
  Icon?: React.ElementType;
  iconPosition?: "bottom" | "end" | "start" | "top";
  isActive?: boolean;
  label?: string;
  onClick?: () => void;
  sx?: any;
  value: string;
}

export interface ITabStyles {
  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  margin?: string;
  minHeight?: string;
  padding?: string;
}

export function Tab({
  badgeStyles,
  children,
  className,
  disabled,
  errorCount,
  Icon,
  iconPosition,
  isActive,
  value,
  ...others
}: ITabProps) {
  const buttonDataTestId = useDataTestId("tab-button", value);
  const badgeDataTestId = useDataTestId("tab-badge", value);
  const label = useMemo(
    () => (
      <StyledBadge
        badgeContent={errorCount}
        badgeStyles={badgeStyles}
        color="error"
        // Set this as any so that the data-testid prop can be set.
        componentsProps={{ badge: { "data-testid": badgeDataTestId } } as any}
      >
        {children}
      </StyledBadge>
    ),
    [badgeDataTestId, badgeStyles, children, errorCount]
  );

  return (
    <StyledTab
      {...others}
      className={className}
      data-testid={buttonDataTestId}
      disableFocusRipple={true}
      disabled={disabled}
      icon={Icon && <Icon />}
      iconPosition={iconPosition}
      isActive={isActive}
      label={label}
      value={value}
    />
  );
}

export const TabList = styled(Tabs)(() => ({
  marginBottom: "1.5rem",
  marginTop: "1.5rem",
  minHeight: "unset",
  overflow: "unset",
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-scroller": {
    // This has to be set via the important flag, as it is set directly on the component,
    // and this needs to be removed so that the error badge is not cut off.
    overflow: "unset !important",
  },
}));

export interface TabPanelProps {
  children?: React.ReactNode;
  className?: string;
  currentValue: string;
  style?: {
    flex?: number;
  };
  value: string;
}

export function TabPanel(props: TabPanelProps) {
  const { children, currentValue, value, ...other } = props;
  const showPanel = useMemo(
    () => currentValue === value,
    [currentValue, value]
  );
  const dataTestId = useDataTestId("tab-panel", value);

  return (
    <Box
      role="tabpanel"
      hidden={!showPanel}
      {...other}
      data-testid={dataTestId}
    >
      {showPanel && children}
    </Box>
  );
}

// Need to remove old Tab context, once we update the old Tab's UI.
export const TabContext = styled(Box)({
  minHeight: "18.75rem",
  padding: "1.5rem 2.75rem 1rem 2.75rem",
});

// Wrapper for tabs with the updated UI.
export const TabsWrapper = styled(Box)({
  minHeight: "18.75rem",
  padding: "1.5rem 0",
});
