export * from "./rest";
export * from "./utils";
export * from "./storage";
import { recipes } from "./logic";
import { storage, caches } from "./storage";

export const sdk = {
  ...recipes,
  storage,
  caches,
};
