import "./index.scss"
import React, {Component} from "react"
import { connect }        from "react-redux"
import Utils              from "../../../../../modules/utils"
import InlineIcon         from "../../../../ui/icon/inline-icon.js"
import EcoIcon            from "../../../../../assets/icons/eco-icon.js"
import McoIcon            from "../../../../../assets/icons/mco-icon.js"
import DcoIcon            from "../../../../../assets/icons/dco-icon.js"
import WarningModal       from "../../../../ui/warning-modal";

export class ChangeOrderTypes extends Component
{
    constructor(props)
    {
        super(props)

        this.onClick = this.onClick.bind(this)
        this.onAcceptCoTypeChange = this.onAcceptCoTypeChange.bind(this)
        this.onRejectCoTypeChange = this.onRejectCoTypeChange.bind(this)
        this.state =
        {
            icons :
            [
                {
                    name    : "eco",
                    src     : <EcoIcon/>,
                    label   : "ECO",
                    tooltip : "Engineering Change Order"
                },
                {
                    name    : "mco",
                    src     : <McoIcon/>,
                    label   : "MCO",
                    tooltip : "Manufacturing Change Order"
                },
            ],
            isCoTypeChanged: false,
            eventOnCoTypeChange: {},
        }

        if (window.__libraryType === "GENERAL" && this.props.company && this.props.company.data
            && this.props.company.data.settings && this.props.company.data.settings.isDcoEnabled)
        {
            this.state.icons.push({
                name    : "dco",
                src     : <DcoIcon/>,
                label   : "DCO",
                tooltip : "Documentation Change Order"
            })
        }
    }

    onClick(event)
    {
        let state = this.state;
        let {loadCoTemplates, list, onChange} = this.props;
        let value = event.target.getAttribute("name");
        let eventOnCoTypeChange = Utils.getEvent(this.refs.node, value);
        window.__isConfigurationsEnabled && window.__isCoMandatoryToggleEnabled && ['eco', 'mco'].includes(value) ? loadCoTemplates(list, value) : null;
        if (value && value.toUpperCase() === 'DCO'){
            state.isCoTypeChanged = true;
            state.eventOnCoTypeChange = eventOnCoTypeChange;
            this.setState(state);
        }
        else
        {
            onChange && onChange(eventOnCoTypeChange);
        }
    }

    onAcceptCoTypeChange()
    {
        let state = this.state;
        state.isCoTypeChanged = false;
        window.__isConfigurationsEnabled && window.__isCoMandatoryToggleEnabled ? this.props.loadCoTemplates(this.props.list, 'dco') : null;
        this.setState(state);
        this.props.onChange && this.props.onChange(state.eventOnCoTypeChange)
    }

    onRejectCoTypeChange()
    {
        let state = this.state;
        state.isCoTypeChanged = false;
        this.setState(state);
    }


    render()
    {
        let blocks = this.state.icons.map((icon, i) =>
        {
            let block =
                <div
                    key={i}
                    name={icon.name}
                    onClick={this.props.value === icon.name ? null : this.onClick}
                    data-tip={icon.tooltip}
                    data-place="top-start"
                    className={this.props.value === icon.name ? "selected" : ""}
                    >
                    <InlineIcon>{icon.src}</InlineIcon>
                    <label>{icon.label}</label>
                </div>
            return block
        })

        let markup =
            <div>
                {
                    this.state.isCoTypeChanged &&
                        <WarningModal
                            onCancel={this.onRejectCoTypeChange}
                            onConfirm={this.onAcceptCoTypeChange}
                            isWarningIcon={true}
                            title={"Warning"}
                            description={"Documentation Change Orders do not allow changes to Revision or Status values."}
                        />
                }
                <div
                    ref="node"
                    name={this.props.name}
                    className={`co-types ${this.props.disabled ? 'disabled' : ''}`}
                    >
                    {blocks}
                </div>
            </div>

        return markup
    }
}

const mapStateToProps = store => ({
  company: store.company
})

export default connect(mapStateToProps)(ChangeOrderTypes)
