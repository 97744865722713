import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DuroButton } from "common/components/button";
import { Button, styled } from "@mui/material";
import { colorPalette } from "@duro/themes";
import { ButtonVariants } from "common/constants";
import { sdk } from "../sdk/editor";

type DCOTypeModalProps = {
  open: boolean;
  setClose: () => void;
};

export const DCOTypeModal: React.FC<DCOTypeModalProps> = ({
  open,
  setClose,
}) => {
  const handleSaveWithClose = () => {
    sdk.editor.handleDCOChange();
    setClose();
  };

  return (
    <Dialog
      open={open}
      onClose={setClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialog>
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Documentation Change Orders do not allow changes to Revision or
            Status values.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.OUTLINED} onClick={setClose}>
            No
          </Button>
          <DuroButton onClick={handleSaveWithClose} autoFocus>
            Ok
          </DuroButton>
        </DialogActions>
      </StyledDialog>
    </Dialog>
  );
};

const StyledDialog = styled("div")({
  background: colorPalette.dark,
  padding: "1.5rem",
});
