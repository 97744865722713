import { DuroModalProps, DuroModal } from "common/components/modals/duroModal";
import { ButtonFooter } from "../buttonFooter.component";
import { sdk } from "features/changeorders/sdk";
import { TemplateList } from "./modify-template-list.component";
import { useGetApproverTemplates, useArchiveApproverTemplate } from "features/changeorders/hooks";

export const ManageTemplatesModal: React.FC<DuroModalProps> =
  ({
    open,
    onClose,
    ariaLabelledby,
    ariaDescribedby,
  }) => {
    const [pendingTemplatesToDelete] = sdk.storage.pendingTemplatesToDelete.useStore();
    const { data, loading, refetch } = useGetApproverTemplates("cache-and-network");
    const { archiveTemplate } = useArchiveApproverTemplate();

    const handleSave = async () => {
      const templateIds = Array.from(pendingTemplatesToDelete);
      await Promise.all(templateIds.map(archiveTemplate));
      sdk.storage.displayModifyModal.setState(false);
      sdk.storage.pendingTemplatesToDelete.resetState();
      sdk.storage.confirmTemplateToDelete.resetState();
      await refetch();
    }

    return (
      <DuroModal
        open={open}
        onClose={onClose}
        showCloseButton={false}
        header="Manage Templates"
        footer={
          <ButtonFooter
            onCancelClick={onClose}
            onConfirmClick={handleSave}
            cancelButtonText="Cancel"
            confirmButtonText="Save"
            cancelDisabled={false}
            confirmDisabled={false}
            confirmColor="primary"
            cancelColor="info"
          />
        }
        ariaLabelledby={ariaLabelledby}
        ariaDescribedby={ariaDescribedby}
      >
        <TemplateList templates={data} />
      </DuroModal>
    );
  };
