import { FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import { Box, styled } from "@mui/material";
import {
  GridCallbackDetails, GridColumnVisibilityModel, GridEventListener,
  GridSelectionModel, GridSortModel,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { Grid, SortMode } from "common/components/grid";
import { PrimaryVariantType } from "design/components/fields";
import { useComponentNameFieldRenderCell } from "design/components/grid/cellRenderers";
import {  PageItemType, StatusValue } from "@duro/utils";
import { AssemblyTableStyle, Component, Product } from "design/models";
import { useGenerateColumns, useGenerateToolbar } from "design/utils/gridColumns";
import { IComponentList } from "graphql/query/componentsQueries";
import { IProductList } from "graphql/query/productsQueries";
import { OverlayComponentType } from "./manageNoRowsOverlay";
import { VariantModalWrapper } from "../../pages/common/variantWrapper";
import { ColumnTypes } from "../../utils/columnDefaults";
import { IComponentOld } from "../../pages/components";
import { IProductOld } from "../../pages/products";

export type ItemType = Product | Component;
type ListType = Partial<IComponentList | IProductList>;

export type TableRow = {
  item: ItemType,
  id: number,
}

type ITableProps = {
  apiRef: MutableRefObject<GridApiPro>;
  columnNames: ColumnTypes[];
  columnVisibilityModel: GridColumnVisibilityModel;
  duplicateSelectedItems?: (items: Partial<IComponentOld>[]) => void;
  getCurrentStyles: () => AssemblyTableStyle;
  gridName: string;
  isSearchResult: boolean;
  items: Component[] | Product[];
  onColumnsReordered: GridEventListener<"columnOrderChange">;
  onColumnsResized: GridEventListener<"columnWidthChange">;
  onColumnVisibilityChange: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void;
  onRowScrollEnd?: () => void;
  onSortModelChange: (sortModel: GridSortModel, details: GridCallbackDetails) => void;
  OverlayComponent: OverlayComponentType;
  OverlayProps: any;
  pageItemType: PageItemType,
  refetch: () => void;
  sortModel: GridSortModel;
  toggleModal?: (
    modalName: string,
    modalValue: boolean,
    modalData?: Partial<IComponentOld | IProductOld>[],
  ) => void;
} & ListType;

type FieldMapperType = {
  [field: string]: (item: Partial<ItemType>) => Partial<IComponentOld | IProductOld>
};

// a map for core-api to legacy field paths
const legacyFieldMapper: FieldMapperType = {
  alias: item => ({ alias: item.alias?.toLocaleLowerCase() }),
  id: item => ({ _id: item.id }),
  revisionValue: item => ({ revision: item.revisionValue }),
  previousRevisionValue: item => ({ previousRevision: item.previousRevisionValue }),
  cpn: item => ({ cpn: (typeof (item.cpn) === "string") ? item.cpn : item.cpn?.displayValue }),
};

const pinnedColumns = { left: ["cpn"] };
const toolbarItemNames: string[] = [
  "Update Status", "Copy", "Delete", "Export", "Revert", "Refresh",
];
const scrollEndThreshold = 2000;

const TOOLBAR_DIVIDER_INDICES = [1];

export const Table: FC<ITableProps> = ({
  apiRef,
  columnNames,
  columnVisibilityModel,
  duplicateSelectedItems,
  getCurrentStyles,
  gridName,
  items,
  loading,
  onColumnsReordered,
  onColumnsResized,
  onColumnVisibilityChange,
  onSortModelChange,
  onRowScrollEnd,
  OverlayComponent,
  OverlayProps,
  pageItemType,
  refetch,
  sortModel,
  toggleModal,
  totalCount,
}) => {
  const [data, setData] = useState<TableRow[]>([]);
  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  useEffect(() => setData(items.map((item, index) => ({
    id: index,
    item,
  }))), [items]);

  /**
   * Generate data with legacy API schema to work with legacy modals
   *
   * @param fields selected fields to generate
   * @returns legacy data with selected fields
   */
  const generateLegacyData = useCallback((fields: string[]) => {
    // get component/product data against selected DataGrid rows
    const rows = selectionModel?.map(id => fields.reduce((current: Partial<ItemType>, key: string) => ({
      [key]: apiRef?.current?.getRow(id)?.item[key],
      ...current,
    }), {}));

    // map each row to legacy API with only selected fields
    return rows.map(row => (
      fields.reduce((item: Partial<IComponentOld | IProductOld>, key: string) => {
        if (key in legacyFieldMapper) return { ...legacyFieldMapper[key](row), ...item };
        return { [key]: row[key as keyof ItemType], ...item };
      }, {})
    ));
  }, [selectionModel, apiRef]);

  const updateStatusOnClick = useCallback(() => {
    toggleModal?.("displayBulkStatusModal", true, generateLegacyData([
      "cpn",
      "description",
      "id",
      "modified",
      "name",
      "previousStatus",
      "previousRevisionValue",
      "revisionValue",
      "status",
      "vendorInfo",
    ]));
  }, [toggleModal, generateLegacyData]);

  const deleteOnClick = useCallback(() => {
    toggleModal?.("displayDeleteModal", true, generateLegacyData(["cpn", "id", "name"]));
  }, [toggleModal, generateLegacyData]);

  const exportOnClick = useCallback(() => {
    toggleModal?.("displayExportMenu", true, generateLegacyData(["alias", "id"]));
  }, [toggleModal, generateLegacyData]);

  const revertOnClick = useCallback(() => {
    toggleModal?.("displayRevertModal", true, generateLegacyData(["cpn", "id", "name"]));
  }, [toggleModal, generateLegacyData]);

  const duplicateOnClick = useCallback(() => {
    duplicateSelectedItems?.(generateLegacyData(["id"]));
  }, [generateLegacyData, duplicateSelectedItems]);

  const handleIconVisibility = useCallback((label: string) => {
    switch (label) {
      case "Update Status":
      case "Export":
      case "Copy":
        if (selectionModel.length > 0) return false;
        break;
      case "Delete":
        if (selectionModel.length < 1) return true;
        return selectionModel
          .some(model => apiRef?.current?.getRow(model)?.item?.status !== StatusValue.DESIGN);
      case "Revert":
        if (selectionModel.length < 1) return true;
        return selectionModel.some(model => apiRef?.current?.getRow(model)?.item?.modified === false);
    }
    return true;
  }, [apiRef, selectionModel]);

  const [variantModalOpen, setVariantModalOpen] = useState(false);
  const [primaryVariant, setPrimaryVariant] = useState<PrimaryVariantType | undefined>();

  const onCloseVariantModal = useCallback(() => {
    setVariantModalOpen(false);
    setPrimaryVariant(undefined);
  }, []);

  const openVariantModal = useCallback((pv: PrimaryVariantType) => {
    setVariantModalOpen(true);
    setPrimaryVariant(pv);
  }, []);

  const nameFieldRenderCell = useComponentNameFieldRenderCell(openVariantModal, false);
  const customRules = useMemo(() => ({ name: { renderCell: nameFieldRenderCell } }), [nameFieldRenderCell]);
  const columns = useGenerateColumns({
    columnNames,
    customRules,
    getCurrentStyles,
  });

  const customItems = useMemo(() => ({
    Refresh: { onClick: refetch },
    "Update Status": { disabled: handleIconVisibility("Update Status"), onClick: updateStatusOnClick },
    Delete: { disabled: handleIconVisibility("Delete"), onClick: deleteOnClick },
    Export: { disabled: handleIconVisibility("Export"), onClick: exportOnClick },
    Revert: { disabled: handleIconVisibility("Revert"), onClick: revertOnClick },
    Copy: { disabled: handleIconVisibility("Copy"), onClick: duplicateOnClick },
  }), [
    deleteOnClick, duplicateOnClick, exportOnClick,
    handleIconVisibility, refetch, revertOnClick, updateStatusOnClick,
  ]);
  const toolbarItems = useGenerateToolbar({
    itemNames: toolbarItemNames,
    customItems,
    dividerIndices: TOOLBAR_DIVIDER_INDICES,
  });

  const onSelectionChange = useCallback((_selectionModel: GridSelectionModel) => {
    setSelectionModel(() => data.filter(row => _selectionModel.includes(row.id)).map(row => row?.id));
  }, [data]);

  const rowCount = useMemo(() => (
    `${totalCount?.toLocaleString()} ${totalCount === 1 ? "result" : "results"}`
  ), [totalCount]);

  return (
    <>
      <VariantModalWrapper
        id={primaryVariant?.item?.id}
        onClose={onCloseVariantModal}
        open={variantModalOpen}
        pageItemType={pageItemType}
        primaryVariant={primaryVariant}
      />
      <TableWrapper>
        <Grid
          apiRef={apiRef}
          columnDefinition={columns}
          columnVisibilityModel={columnVisibilityModel}
          data={data}
          loading={loading}
          name={gridName}
          noRowsOverlay={OverlayComponent}
          noRowsOverlayProps={OverlayProps}
          onColumnsReordered={onColumnsReordered}
          onColumnsResized={onColumnsResized}
          onColumnVisibilityModelChange={onColumnVisibilityChange}
          onRowScrollEnd={onRowScrollEnd}
          onSelectionChange={onSelectionChange}
          onSortChange={onSortModelChange}
          pinnedColumns={pinnedColumns}
          scrollEndThreshold={scrollEndThreshold}
          sortMode={SortMode.SERVER}
          sortModel={sortModel}
          toolbarItems={toolbarItems}
          totalCount={rowCount}
        />
      </TableWrapper>
    </>
  );
};

const TableWrapper = styled(Box)({
  display: "flex",
  flex: 1,
});
