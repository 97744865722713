import { gql } from "@apollo/client";


export const LINK_APPLICATION_MUTATION = gql`
mutation LinkApplication($id: String!, $appKey: String!) {
    linkApplication(input: {id: $id, appKey: $appKey}) {
        vendorInfo {
            currentVendor
            originalVendor
            previousVendor
            isLinked
        }
        name
        id
        status
        revision
    }
}
`

export const UNLINK_APPLICATION_MUTATION = gql`
mutation UnlinkAllApplications($id: String!) {
    unlinkAllApplications(id: $id) {
        id
        name
        status
        revision
        vendorInfo {
            originalVendor
            previousVendor
            currentVendor
            isLinked
        }
    }
}
`
