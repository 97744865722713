import "./index.scss"
import React, { Component } from "react"
import Icon                 from "../../../../ui/icon"
import Utils                from "../../../../../modules/utils"
import { connect } from "react-redux"
import RightSideBarMenu     from "../../../../ui/right-side-bar-menu"
import validator            from "validator"
import NewUserModalBox      from "../../../settings/users/user-modal-box"
import InlineIcon           from "../../../../ui/icon/inline-icon.js"
import SearchIcon           from "../../../../../assets/icons/search.js"
import AvatarIcon from "../../../../../assets/icons/user-icon.js"
import CoTemplatesSelect    from './co-template-select'
import { Button } from "@mui/material"
import { ButtonVariants } from "common/constants"


export class AddUsersList extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            addNewUser : false,
            openRighSearch : true,
            search: {
                value : '',
                searchResults : this.props.usersList,
                selectedResults : []
            },
            allUsers: this.props.usersList || [],
            usersListToDisplay: [],
            inputs :
            {
                emails :
                {
                    value : "",
                    valid : true,
                    class: "",
                    message: ""
                }
            }
        }
        this.onSearchChange                     = this.onSearchChange.bind(this)
        this.onInputChange                      = this.onInputChange.bind(this)
        this.selectAll                          = this.selectAll.bind(this)
        this.add                                = this.add.bind(this)
        this.onSearchFocus                      = this.onSearchFocus.bind(this)
        this.onSearchFocusOut                   = this.onSearchFocusOut.bind(this)
        this.setSelectAllButtonStatus           = this.setSelectAllButtonStatus.bind(this)
        this.setUsersList                       = this.setUsersList.bind(this)
        this.onEmailsChange                     = this.onEmailsChange.bind(this)
        this.validateEmails                     = this.validateEmails.bind(this)
        this.addEmails                          = this.addEmails.bind(this)
        this.resetEmailInput                    = this.resetEmailInput.bind(this)
        this.toggleAddNewUserModal              = this.toggleAddNewUserModal.bind(this)
        this.afterNewUserCreationSuccessCb      = this.afterNewUserCreationSuccessCb.bind(this)
        this.getDisabledClazz                   = this.getDisabledClazz.bind(this)
    }

    afterNewUserCreationSuccessCb(data)
    {
        this.props.addUserAfterNewUserCreation(data, this.props.listName)
    }

    addEmails(event)
    {
        this.props.onAddExternalUserEmails(this.state.inputs.emails.value.toLowerCase().split(','))
        this.resetEmailInput()
    }

    toggleAddNewUserModal()
    {
      this.state.addNewUser = !this.state.addNewUser
      this.setState(this.state)
    }

    resetEmailInput()
    {
        let input   = this.state.inputs.emails
        input.value = ""
        input.message = ""
        input.class = ""
        input.valid = true
        this.setState(this.state)
    }

    onEmailsChange(event)
    {
        let value   = event.target.value
        let input   = this.state.inputs.emails
        input.value = value
        input.valid = this.validateEmails(input.value)
        input.message = input.valid ? "" : "Please enter a valid email address"
        input.class = input.valid ? "" : "invalid"
        this.setState(this.state)
    }

    validateEmails(value)
    {
        if (!value) return true
        let valid  = true
        let emails = value.split(",")

        emails.forEach((email) =>
        {
            if(!validator.isEmail(email.trim()))
            {
                valid = false
            }
        })

        return valid
    }

    onSearchFocus()
    {
        let state = this.state
        state.searchFocused = true
        this.setState(this.state)
    }

    onSearchFocusOut()
    {
        let state = this.state
        state.searchFocused = false
        this.setState(this.state)
    }

    onInputChange(event, user)
    {
        let checked  = event.target.checked
        user.checked = checked
        // this.setState(this.state, this.setSelectAllButtonStatus)
        this.setSelectAllButtonStatus()
    }

    setSelectAllButtonStatus()
    {
        let state = this.state
        let search = state.search
        let searchResults = state.search.searchResults

        search.selectedResults = []

        let count = 0
        searchResults.map((user, i) =>
        {
            if (user.checked)
            {
                search.selectedResults.push(Utils.clone(user))
                count++
            }
        })

        if(count === searchResults.length)
        {
            search.selectAllFlag = true
            search.selectAllButoonClazz = ""
        }
        else if (count !== searchResults.length && count > 0)
        {
            search.selectAllFlag = false
            search.selectAllButoonClazz = "middle"
        }
        else
        {
            search.selectAllButoonClazz = ""
        }

        this.setState(this.state)
    }

    selectAll(event)
    {
        let permittedUsers = this.props.permittedUsers
        let checked  = event.target.checked
        let state = this.state
        let search = state.search

        search.selectAllButoonClazz = ""
        search.selectAllFlag = checked

        search.selectedResults = []

        if (search.selectAllFlag)
        {
            search.selectAllFlag = true
            search.selectAllButoonClazz = ""
            search.searchResults.forEach(user =>
            {
                if (!this.props.disabledUserIdz.includes(user._id) && permittedUsers.includes(user.role))
                {
                    search.selectedResults.push(Utils.clone(user))
                    user.checked = true
                }
            })
        }
        else
        {
            search.selectAllFlag = false
            search.searchResults.forEach(user => user.checked = false)
        }

        this.setState(state)

    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.usersList.length !== this.state.allUsers)
        {
            let state = this.state
            state.allUsers = nextProps.usersList
            this.setUsersList()
        }
    }

    setUsersList(){
        let state = this.state
        state.usersListToDisplay = []

        state.allUsers.forEach((user, i) =>
        {
            // if (this.props.user.data._id !== user._id)
            // {
                let userData = Utils.clone(user)
                state.usersListToDisplay.push(userData)
            // }
        })

        if (state.search.value === '')
        {
            state.search.searchResults = []
            state.search.searchResults = Utils.clone(state.usersListToDisplay)
        }

        this.setState(state)
    }

    onSearchChange(event)
    {
        let value  = event.target.value
        let state = this.state
        let search = state.search
        search.searchResults    = []
        search.selectedResults = []
        search.value          = value
        search.selectAllButoonClazz = ""
        search.selectAllFlag = false

        state.allUsers.forEach((user, i) =>
        {
            if ( user.name.toLowerCase().match(value.toLowerCase()) )
            {
                search.searchResults.push(user)
            }
        })

        this.setState(state)
    }

    add()
    {
        let search = this.state.search
        let searchResults      = search.searchResults
        let selectedUsers = search.selectedResults

        if(selectedUsers.length < 1) return

        let state = this.state
        state.search.searchResults.forEach((user) => {
            user.checked = false
        })
        search.selectedResults = []
        this.setState(state, () => { this.props.onAddUsers(selectedUsers) })
    }

    getDisabledClazz()
    {
        let disabledClazz = "disabled"
        let inputs = []
        let state = this.state
        let search = state.search
        let disabledUserIdz = this.props.disabledUserIdz
        search.searchResults.forEach((user) =>
        {
            if (user.checked && !disabledUserIdz.includes(user._id))
            {
                disabledClazz = "enabled"
                return
            }
        })
        return disabledClazz
    }

    render()
    {
        let {search, inputs, searchFocused, addUser} = this.state;
        let {disabledUserIdz, permittedUsers, listName, templates, approvalTypeFromTemplate, users, approvalType, updateListsTemplates, setSelectedTemplateName, selectedTemplateName, changeorders, user, enableSave, enableSaveButton, displayCoTooltip, setCoTemplates, loadTemplate, coTemplatesMappings, history, heading} = this.props;

        let {searchResults} = search;
        let addEmailButtonClazz = inputs.emails.value && inputs.emails.valid ? "" : "disabled"
        let addBtnClazz = this.getDisabledClazz()
        const listAreaClass = listName === "notification" ? " list-notifiers" : ""
        const selectedUsers = { 
            selectedApprovers: this.props.selectedUsers.approverList, 
            selectedNotifiers: this.props.selectedUsers.coInternalNotifyUsers, 
            selectedExternals: this.props.selectedUsers.coExternalNotifyUsers,
        }

        let markup =
            <RightSideBarMenu
                heading={heading}
                className="add-users-list"
                >
                <div className="co-select-wrapper">
                    <CoTemplatesSelect 
                        templates={templates}
                        selectedUsers={selectedUsers}
                        approvalTypeFromTemplate={approvalTypeFromTemplate}
                        allUsers={users.users}
                        approvalType={approvalType}
                        updateListsTemplates={updateListsTemplates}
                        setSelectedTemplateName={setSelectedTemplateName}
                        selectedTemplateName={selectedTemplateName}
                        creator={changeorders.changeForm.co.creator}
                        user={user}
                        enableSave={enableSave}
                        enableSaveButton={enableSaveButton}
                        displayCoTooltip={displayCoTooltip}
                        setCoTemplates={setCoTemplates}
                        loadTemplate={loadTemplate}
                        coTemplatesMappings={coTemplatesMappings}
                    />
                </div>
                {
                  this.state.addNewUser &&
                      <NewUserModalBox
                        onClose={this.toggleAddNewUserModal}
                        displayModal={addUser}
                        history={history}
                        onCancel={this.toggleAddNewUserModal}
                        afterSubmitSuccessCb={this.afterNewUserCreationSuccessCb}
                      />
                }

                <div className={"user-list-holder " + listName}>
                        <div className={"search-area-holder " + (searchFocused ? "active" : "")}>
                            <InlineIcon>
                                <SearchIcon/>
                            </InlineIcon>
                            <input
                                type="text"
                                value={search.value}
                                onChange={this.onSearchChange}
                                onFocus={this.onSearchFocus}
                                onBlur={this.onSearchFocusOut}
                                placeholder="Enter existing user"
                            />
                        </div>

                        <div className={searchResults.length > 0 ? "visible" : ""}>
                            <span className="result-count">Returned {searchResults.length} results</span>
                            <div className="select-all-area">
                                <div className="select-all-holder">
                                    <div>
                                        <input
                                            className="checked-input"
                                            type="checkbox"
                                            checked={search.selectAllFlag ? true : false}
                                            onChange={(e) => this.selectAll(e)}
                                        />
                                        <label
                                            className={"checked-label " + search.selectAllButoonClazz}
                                        />
                                        <span className="select-all-label">SELECT ALL</span>
                                    </div>
                                    <Button
                                        color="primary"
                                        disabled={addBtnClazz === "disabled"}
                                        onClick={this.add}
                                        variant={ButtonVariants.OUTLINED}
                                    >
                                        Add
                                    </Button>

                                </div>
                            </div>
                            <div className={listAreaClass + " list-area"}>
                                <ul>
                                    {
                                        searchResults.map((user, i) =>
                                        {
                                            let disabled = false
                                            let toolTipText = disabled? "Tooltip text" : ''

                                            if (!permittedUsers.includes(user.role))
                                            {
                                                disabled = true
                                                toolTipText = "Cannot add user having role " + user.role.toLowerCase()
                                            }

                                            let labelClass = disabledUserIdz.includes(user._id) ? "cross-checked" : disabled ? " disabled" : ""

                                            disabled = labelClass.length > 0
                                            let block =
                                                <li key={i} className="flex-item">
                                                    <span
                                                        className="check-box"
                                                        data-tip={disabled? toolTipText : ''}>
                                                        <input
                                                            className="checked-input"
                                                            type="checkbox"
                                                            checked={!disabledUserIdz.includes(user._id) && user.checked ? true : false}
                                                            onChange={disabled ? null : (e) => this.onInputChange(e, user)}
                                                            disabled={disabled}
                                                        />
                                                        <label
                                                            className={"checked-label " + labelClass}
                                                            disabled={disabled}
                                                        />
                                                    </span>
                                                    <span className={ "avatar-holder " + (user.avatar ? "user-avatar" : "default-avatar") }>
                                                        {
                                                            user.avatar
                                                            ?
                                                                <div className="user-img">
                                                                    <img src={user.avatar.src} alt="avatar" />
                                                                </div>
                                                            :
                                                            <span className="default-icon">
                                                                <InlineIcon>
                                                                    <AvatarIcon/>
                                                                </InlineIcon>
                                                            </span>
                                                        }
                                                        <div>
                                                            <span
                                                                data-tip=""
                                                                data-tootip-text={user.name}
                                                                data-place="top"
                                                                // onMouseOver={(e) => this.checkToolTipAppearance(e)}
                                                                data-name="name"
                                                            >
                                                                {user.name}
                                                            </span>

                                                            {
                                                                user.groups.length > 0 ?
                                                                <div className="muted-text group-name">
                                                                    {
                                                                        Utils.capitalizeString(user.groups.join(", ")).replace('Qa', 'QA')
                                                                    }
                                                                </div> :
                                                                ' '
                                                            }

                                                        </div>
                                                    </span>
                                                </li>
                                            return block
                                        })
                                    }
                                </ul>
                            </div>
                            {/*TODO: Removing this funtionality for now from the Change Order as suggested by Jessica*/}
                            {/*<div className="add-new-user">
                                <p>Invite new user to join this list</p>
                                <a
                                    onClick={this.toggleAddNewUserModal}>
                                    Create new user account.
                                </a>
                            </div>*/}

                            {
                                this.props.displayMailBlock &&
                                <div className="mail-block">
                                    <p>
                                        Want to notify people who don’t have a Duro account?
                                    </p>
                                    <textarea
                                        type="text"
                                        placeholder="Enter email addresses (separate by commas)"
                                        value={inputs.emails.value}
                                        onChange={this.onEmailsChange}
                                        data-tip={inputs.emails.message}
                                        data-place="left"
                                        data-type="error"
                                        className={inputs.emails.class}
                                    />

                                    <Button
                                        className={"add-email-btn"}
                                        color="primary"
                                        disabled={!!addEmailButtonClazz}
                                        onClick={this.addEmails}
                                        variant={ButtonVariants.OUTLINED}
                                        >
                                        Add Emails
                                    </Button>
                                </div>

                            }
                        </div>
                    </div>
            </RightSideBarMenu>



        return markup
    }
}

export default connect((store) => store)(AddUsersList)
