import React, {Component} from "react";
import "./index.scss";
import SingleWorkspace    from "./single-workspace";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import Spinner            from "../../../../../ui/spinner";
import API                from "../../../../../../modules/api";
import Utils              from "../../../../../../modules/utils";
import HeaderBanner       from "../../../../common/header-banner";

export default class WorkspacesModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            error: "",
            isContinueEnabled: false,
            loading: true,
            workspaces: [],
            selectedWorkspace: {}
        }
        this.getWorkspaces = this.getWorkspaces.bind(this);
        this.selectProjects  = this.selectProjects.bind(this);
        this.changeWorkspace = this.changeWorkspace.bind(this);
    }

    componentDidMount()
    {
        this.getWorkspaces();
    }

    getWorkspaces()
    {
        let state = this.state;
        API.integrations.getValispaceWorkspaces((err, response) =>
        {
            if(err)
            {
                state.error = "There is some error while connecting to valispace, please try after reconnecting the valispace integration from account settings";
                state.loading = false;
                this.setState(state);
                return;
            }

            state.workspaces = response;
            state.selectedWorkspace.id = this.props.selectedWorkspace.id ? this.props.selectedWorkspace.id : response[0].id;
            state.selectedWorkspace.name = this.props.selectedWorkspace.name ? this.props.selectedWorkspace.name : response[0].name;
            state.loading    = false;
            state.isContinueEnabled = true;
            this.setState(state);
        })
    }

    selectProjects()
    {
        this.props.renderProjectsModal(this.state.selectedWorkspace);
    }

    changeWorkspace(name, id)
    {
        let state = this.state;
        state.selectedWorkspace.id = id;
        state.selectedWorkspace.name = name;
        this.setState(state);
    }

    renderWorkspaces()
    {
        let state = this.state;
        let markup = [];
        state.workspaces.forEach((workspace) => {
            markup.push(<SingleWorkspace workspace={workspace} selectedWorkspace={state.selectedWorkspace} changeWorkspace={this.changeWorkspace}/>);
        })
        return markup;
    }

    render()
    {
        let state = this.state;
        let continueButtonClass = `btn-continue ${state.isContinueEnabled ? "" : "disabled" }`;
        let { headerText }  = this.props;

        let markup =
                <div className="workspaces-list">
                        <header className="valispace-modal-header">
                            <HeaderBanner text={headerText}/>
                            <div className="btn-container">
                                {
                                    !state.error &&
                                    <button
                                        className={continueButtonClass}
                                        onClick={this.selectProjects}>
                                        Continue
                                    </button>
                                }
                            </div>
                        </header>
                        <div className="content-wrapper">
                            <div className="modal-heading">
                                <h1> Select a Workspace </h1>
                            </div>
                            {
                                state.loading ?
                                <Spinner /> :
                                !state.error ?
                                <div className="details-wrapper">
                                    <div className="details-heading">
                                        <h3>VALISPACE <span>WORKSPACES</span></h3>
                                    </div>
                                    <div className="list-table">
                                    <PerfectScrollbar className="table-wrapper-scroll">
                                        <table className="workspaces-table" id="workspaces-table">
                                            <tbody>
                                                {this.renderWorkspaces()}
                                            </tbody>
                                        </table>
                                    </PerfectScrollbar>
                                    </div>
                                </div>
                                : <div>{state.error}</div>
                            }
                        </div>
                </div>
        return markup
    }
}
