import {
  Box,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Checkbox } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Dispatch, SetStateAction } from "react";

export type SaveAsNewTemplateProps = {
  templateName: string;
  setTemplateName: Dispatch<SetStateAction<string>>;
  isPublic: boolean;
  setIsPublic: Dispatch<SetStateAction<boolean>>;
};

export const SaveAsNewTemplate: React.FC<SaveAsNewTemplateProps> = ({
  templateName,
  setTemplateName,
  isPublic,
  setIsPublic,
}) => {
  return (
    <>
      <Typography>Save As New Template</Typography>
      <TextField
        placeholder="Enter Name"
        fullWidth
        value={templateName}
        onChange={(e) => setTemplateName(e.target.value)}
        inputProps={{ maxLength: 60 }}
      />
      <Box sx={{ display: "flex", alignItems: "center", pl: 0.25 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
              size="small"
              sx={{ pl: 1 }}
            />
          }
          label="Accessible to all company account users"
          sx={{
            "& .MuiFormControlLabel-label": {
              paddingLeft: 0.5,
            },
          }}
        />
        <Tooltip
          title="New templates are private by default"
          arrow
          placement="right"
        >
          <InfoIcon fontSize="small" />
        </Tooltip>
      </Box>
    </>
  );
};
