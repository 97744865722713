import { Box, Button, Typography } from "@mui/material";
import { ProductIconFilled } from "assets/icons";
import { ButtonVariants } from "common/constants";

export const DataroomsHeader = () => {
    return (
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center" 
        padding={2} 
      >
        <Box display="flex" alignItems="center" ml={1}>
          <ProductIconFilled color='primary'/>
          <Typography variant="h6" ml={1}>Datarooms</Typography>
        </Box>
        <Button 
          variant={ButtonVariants.OUTLINED} 
        >
          + New Dataroom
        </Button>
      </Box>
    );
  };