
import "./index.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import Utils from "../../../../../../modules/utils";
import Schemas from "../../../../../../modules/schemas";
import API from "../../../../../../modules/api";
import Link from "../../../../../ui/link";
import ExtendedTable from "../../../../common/extended-table";
import InlineIcon from "../../../../../ui/icon/inline-icon.js";
import ArrowIcon from "../../../../../../assets/icons/arrow-icon";
import TimeStampColumn from "../../../../common/timestamp-column";
import InlineImageViewer from "../../../inline-image-viewer/view";
import Config from "../../../../../../modules/config";
import Spinner from "../../../../../ui/spinner";
import { StatusField } from "@duro/base/status-field";
import { HEADINGS, getHeadings } from "../../../extended-table/helpers";
import LazyInput from "../../../../../ui/lazy-input/input.js";
import validations, { validateField } from "../../../../../../modules/validations";
import Tooltip from 'rc-tooltip';
import LinkIcon from "../../../../../../assets/icons/open-link-icon.js";
import { debounce } from "lodash";
import ValidationRunningModal from "../../../validation-running-modal";
import UI from "../../../../../../action-types/ui";
import buildAction from "../../../../../../helpers/buildAction";

export class InstanceAssembly extends Component
{
    constructor(props, context)
    {
        super(props, context)
        let { children } = this.props;
        let styles;
        try
        {
            styles = window.__userStyles.styles.assemblyTableTreeView || {};
        }
        catch(error) {
            styles = {}
        }

        let headings = getHeadings([
            {
                ...HEADINGS.cpn,
                displayName: "CPN/Name",
                dragable: false,
                minWidth: 215,
                tooltip: "CPN/Name",
            },
            HEADINGS.serial,
            HEADINGS.images,
            HEADINGS.level,
            HEADINGS.category,
            HEADINGS.cmpState,
            HEADINGS.revision,
            HEADINGS.eid,
            HEADINGS.status,
            HEADINGS.productionDate,
            HEADINGS.label,
        ], styles);

        this.state =
        {
            showLoading: true,
            syncTable: false,
            headings: headings,
            inputs: [],
            updated: false,
            children: Utils.sortComponents("component.cpn", children),
            current: ("defaultSortColumnName" in styles ? styles.defaultSortColumnName : "cpn"),
            generatedRows: [],
            currentSortItemAscending: ("defaultSortAssending" in styles ? styles.defaultSortAssending : true),
            duplicatedSerialsData: {},
            rows:[],
        }

        this.items = {};
        this.onTableChange = this.onTableChange.bind(this);
        this.onTableChangeDebounced = debounce(this.onTableChange, 3000);
        this.afterSyncTable = this.afterSyncTable.bind(this);
        this.getChildAssembly = this.getChildAssembly.bind(this);
        this.generateCells = this.generateCells.bind(this);
        this.generateRows = this.generateRows.bind(this);
        this.setChildOpen = this.setChildOpen.bind(this);
        this.fetchComponent = this.fetchComponent.bind(this);
        this.setCells = this.setCells.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.setItems = this.setItems.bind(this);
        this.setItemsForChildren = this.setItemsForChildren.bind(this);
        this.validateSerialInputs = this.validateSerialInputs.bind(this);
        this.setSerialDuplicateError = this.setSerialDuplicateError.bind(this);
        this.assignDuplicateOf = this.assignDuplicateOf.bind(this);
        this.assemblyErrors = this.assemblyErrors.bind(this); 
        this.productionInstancesApiValidation = this.productionInstancesApiValidation.bind(this); 
        this.errors = 0;

        this.setItems({_id: this.props.objectData._id, serial: this.props.inputs.serial.value}); // Set Items for Parent Component
        if (this.props.inputs.serialDuplicateOf) this.items[`${this.props.objectData._id}`].serial.duplicateOf = this.props.inputs.serialDuplicateOf;

        this.setItemsForChildren(children);
    }

    setItemsForChildren(children)
    {
        for (let child of children)
        {
            let childData = child.component;
            if (typeof(childData) === "object")
            {
                this.setItems(childData);
            }
        }
    }


    setItems(data)
    {
        if (data)
        {
            this.items[`${data._id}`] =
            {
                serial: {
                    message: "",
                    valid: true,
                    class: "",
                    value: data.serial,
                    originalValue: data.serial,
                }
            }

            if (data.children && data.children.length > 0)
            {
                this.setItemsForChildren(data.children)
            }

        }
    }

    validateSerialInputs(data, serial, duplicatedSerialsData)
    {
        let { objectData, inputs } = this.props;
        const keys = Object.keys(this.items);
        let excludeKeys = [];
        let isParentSerialErrorSet = false;
        keys.forEach((key1) => {
            if (!excludeKeys.includes(key1))
            {
                let isError = false;
                let itemSerial1 = this.items[key1].serial;
                keys.forEach((key2) => {
                    if (!excludeKeys.includes(key2) && key2 !== key1)
                    {
                        let itemSerial2 = this.items[key2].serial;
                        if (itemSerial1.value && itemSerial2.value && itemSerial1.value.toUpperCase() === itemSerial2.value.toUpperCase())
                        {
                            isError = true;
                            this.setSerialDuplicateError(key1);
                            this.setSerialDuplicateError(key2);
                            this.items[key1].serial.isChildErrorExistForAnotherChild = true;
                            this.items[key2].serial.isChildErrorExistForAnotherChild = true;

                            if (this.items[key1].serial.duplicateOf)
                            {
                                this.assignDuplicateOf(key1, key2) // function parameters : (from, to)
                            }
                            else if (this.items[key2].serial.duplicateOf)
                            {
                                this.assignDuplicateOf(key2, key1) // function parameters : (from, to)
                            }

                            if ([key1, key2].includes(objectData._id))
                            {
                                // Set Error in Parent if parent serial value matcher with child
                                let key = objectData._id === key1 ? key1 : key2;
                                let childKey = objectData._id === key1 ? key2 : key1;
                                isParentSerialErrorSet = true;
                                inputs.serial.valid = false;
                                inputs.serial.message = "Duplicate Serial Number Found";
                                inputs.serial.class = "invalid";
                                inputs.serial.errorSetFromChild = true;

                                if (this.items[`${key}`].serial.duplicateOf && this.items[`${key}`].serial.duplicateOf._id !== objectData._id)
                                {
                                    inputs.serialDuplicateOf = this.items[`${key}`].serial.duplicateOf;
                                }

                                this.items[childKey].serial.isChildErrorExistForBothChildAndParent = true;
                                this.items[childKey].serial.isErrorSetFromParent = true;
                            }

                            if (!excludeKeys.includes(key1)) excludeKeys.push(key1) // push key only once
                            excludeKeys.push(key2);
                        }
                    }
                })


                if (!isError )
                {
                    if (this.items[key1].serial.duplicateOf)
                    {
                        this.items[key1].serial.message = "";
                    }
                    else
                    {
                        this.setSerialDuplicateError(key1, true);
                        this.items[key1].serial.isChildErrorExistForAnotherChild = false;
                    }
                }
            }
        });

        if (!isParentSerialErrorSet && inputs.serial.errorSetFromChild)
        {
            if (inputs.serialDuplicateOf && inputs.serialDuplicateOf._id !== objectData._id)
            {
                inputs.serial.valid = false;
                inputs.serial.message = ""; // Setting message empty so that customized duplicate tooltip will be shown
                inputs.serial.class = "invalid";
            }
            else
            {
                inputs.serial.valid = true;
                inputs.serial.message = "";
                inputs.serial.class = "valid";

            }

            delete inputs.serial.errorSetFromChild;
        }
    }

    assignDuplicateOf(from, to)
    {
        let duplicateOf = this.items[from].serial.duplicateOf;
        let serialItem = this.items[to].serial;

        if(
            duplicateOf && duplicateOf.serial
            && serialItem.value && duplicateOf.serial === serialItem.value
            && serialItem.originalValue && duplicateOf.serial !== serialItem.originalValue
        )
        {
            this.assignDuplicateOf(from, to)
            this.items[to].serial.duplicateOf = duplicateOf;
        }
    }

    setSerialDuplicateError(id, isDefaultValues=false)
    {
        if (isDefaultValues)
        {
            // Resetting Default Values
            let seriaValue = this.items[`${id}`].serial.value;

            this.items[`${id}`].serial =
            {
                message: "",
                class: "",
                valid: true,
                value: seriaValue,
            }
        }
        else
        {
            this.items[`${id}`].serial.message = "Duplicate Serial Number Found";
            this.items[`${id}`].serial.class = "invalid";
            this.items[`${id}`].serial.valid = false;
        }
    }

    onInputChange(event, item)
    {
        this.props.setEditBarState();
        let { rows } = this.state;
        let name = event.target.name;
        item[name] = event.target.value;
        item.target = item.target ? `${item.target} ${name}`: name;
        rows.push(item);
        this.onTableChangeDebounced(rows);
        this.setState({rows: rows});
    }

    onTableChange(rows=[])
    {
        let { dispatch, updateChildrenInstances, updateProductionInstances, setEditBarState } = this.props;
        let state = this.state;
        
        if(rows.length > 2 ) dispatch(buildAction(UI.VALIDATION_START));

        const updateState = (inputs) => {
            this.setState({generatedRows: []});
            this.generateRows();
            this.setState({inputs: inputs});
        }

        const updateItem = (inputs, item) => {
            let updated = false;
            for(let input of inputs)
            {
                if(input._id === item._id)
                { 
                    input.serial = item.serial;
                    input.label  = item.label;
                    updated = true;
                    break;
                }
            }
            if(!updated)
            {
                inputs.push({_id:item._id, serial:item.serial, label:item.label});
            }
            updateChildrenInstances(inputs);
        }
       
        for(let item of rows)
        {
            if(item.target && item.target.includes('label'))
            {
                let label = {
                    value: item.label,
                    valid: true
                }
                validateField(label, validations.productionInstance.label, item.label);
                    item.labelErrorMsg = label.message;
                    item.labelClass = label.class;
                    item.label = label.value;
                if(label.valid)
                    updateItem(state.inputs, item);
                updateProductionInstances(state.children);
                updateState(state.inputs);

                if(!item.target.includes('serial') && item.serialClass !== "invalid" && item.labelClass !== "invalid"){
                    delete item.target;
                }
            }

            if(item.target && item.target.includes('serial'))
            {
                let { duplicatedSerialsData } = state;
                
                let serial = {
                    value: item.serial,
                    valid: true
                }
                validateField(serial, validations.productionInstance.serial, item.serial);
                
                this.items[`${item._id}`].serial.message = serial.message;
                this.items[`${item._id}`].serial.class = serial.class;
                this.items[`${item._id}`].serial.valid = serial.valid;
                this.items[`${item._id}`].serial.value = serial.value.trim();

                this.validateSerialInputs(item, serial, duplicatedSerialsData);
                item = this.productionInstancesApiValidation(item,updateState,updateItem);
            }
        }    
        setTimeout(() => {
            this.props.dispatch(buildAction(UI.VALIDATION_END))
        }, rows.length * 300);  
        this.setState({rows:[]});
        setEditBarState(true);
    }

    productionInstancesApiValidation(item,updateState,updateItem)
    {
        let state = this.state;
        API.productionInstances.serialExists({serial: item.serial, id: item._id}, (err, data) =>
        {
            if (data)
            {
                if(data.exist)
                {
                    const wasValid = this.items[`${item._id}`].serial.valid;
                    this.items[`${item._id}`].serial.duplicateOf = data.duplicate_of;

                    this.setSerialDuplicateError(item._id);
                    this.items[`${item._id}`].serial.isChildErrorExistForAnotherChild = true;

                    // if serial is not valid then it means same serial is using at front end
                    // so if serial is valid then set the message empty so that
                    // customized duplicate error will be shown to user
                    if (wasValid)
                        this.items[`${item._id}`].serial.message = "";
                }
                else
                {
                    if (this.items[`${item._id}`].serial.duplicateOf)
                        delete this.items[`${item._id}`].serial.duplicateOf;
                    updateItem(state.inputs, item);
                }
                this.props.updateProductionInstances(state.children);
                updateState(state.inputs);
            }
            else if (err)
            {
                this.props.showUiAlert({ type: "errors", errors: err.errors, err: err });
            }                    
            if(item.serialClass !== "invalid" && item.labelClass !== "invalid"){
                delete item.target;
            }
        })
    }

    afterSyncTable()
    {
        this.setState({syncTable: false});
    }

    getChildAssembly(component, i)
    {
        if (!component.listClass)
        {
            component.listClass = "open";
        }
        else
        {
            component.listClass = component.listClass === "open" ? "close" : "open";
        }
        if (component.children.length > 0 && component.listClass === "open")
        {
            let children = [];

            for(let cmp of component.children)
            {
                cmp.haveChildren = cmp.component.children.length > 0;

                cmp.listClass = "close";

                if(cmp.haveChildren)
                {
                    cmp.component.children.forEach((childCmp) =>
                    {
                        if(typeof childCmp.component === "string")
                        {
                            let componentId = `${childCmp.component}?include=children&lean=true`;
                            API.productionInstances.findById(componentId, (err, componentData) =>
                            {
                                childCmp.component = componentData;
                                this.setItems(componentData);
                            });
                        }
                    });
                }
                children.push(cmp);
            }
            component.children = Utils.sortComponents("component.cpn", children);
        }
        this.state.syncTable = true;
        if (!this.props.isDiffTool || component.listClass === "close") this.generateRows(component.listClass);
    }

    generateRows(openCloseClass="")
    {
        let state = this.state;
        state.showLoading = false;

        this.errors = 0;
        this.generateCells({children: this.state.children}, 0);
        this.setState(state, () => {
            this.props.calculateAssemblyErrors(this.errors);
            if (this.state.children && this.state.children.length < 500)
            {
                //Note: Adding try catch so that we will be fetching data from database if it's size is more than the allowed cache quota
                try
                {
                    Utils.setStore("lastAssemblyTree",   this.state.children);
                    Utils.setStore("lastAssemblyParent", this.props.objectData._id);
                }
                catch(err)
                {
                    Utils.setStore("lastAssemblyTree", null);
                    Utils.setStore("lastAssemblyParent", null);
                }
            }
        });
    }

    assemblyErrors(root, level)
    {
        let { rows } = this.state;
        let childs = root.children;
        if (childs && childs.length > 0 )
        {
            level++;
            childs.forEach((child) =>
            {
                if (typeof(child.component) === "object")
                    this.assemblyErrors(child.component, level);
                if(child.component.target && child.component.target.includes('serial'))
                    rows.push(child.component)
            })
        }   
        rows.length >= 1 ? this.onTableChange(rows) : "";
    }

    setCells(prdInstance, level)
    {
        prdInstance  = prdInstance.component;

        let itemSerialData = this.items[`${prdInstance._id}`].serial;
        prdInstance.serialErrorMsg = itemSerialData.message;
        prdInstance.serialClass = itemSerialData.class;

        let cmpState = prdInstance.workflowState ? prdInstance.workflowState : '';
        let dateTime = Utils.dateTimeWithLongFormat(prdInstance.productionDate);
        let padding  = (level*20)+'px';
        padding      = level === 1 ? '15px' : padding;
        let to       = `/${prdInstance.alias === "prd" ? "product" : "component"}/revision/${prdInstance.objectRevision}`;
        prdInstance.listClass = prdInstance.listClass === "open" ? "open" : "close";
        let serialDuplicateTooltip  = null

        // message (Duplicate Serial Number Found) has higer Priority than duplicateOf (Tooltip with Link)
        if (!itemSerialData.message && itemSerialData.duplicateOf)
        {
            serialDuplicateTooltip  = Utils.makeSerialDuplicateInputTooltip(itemSerialData.duplicateOf, "Serial Number");
        }

        // if serialDuplicateTooltip is present then don't set visible props, because if we
        // set visible: true then the Error tooltip keeps showing even without hover.
        let serialDuplicateTooltipProps = serialDuplicateTooltip ? {} : {visible: false};

        this.errors = prdInstance.serialClass === 'invalid' ? this.errors+1 : this.errors;
        this.errors = prdInstance.labelClass  === 'invalid' ? this.errors+1 : this.errors;

        let cells = {
            "cpn" : {
                value       : prdInstance.cpn,
                displayValue:
                            <span className="tree-child-assembly" style={{paddingLeft: padding}}>
                                <span className='link position-relative'>
                                    {
                                        prdInstance.children.length > 0 ?
                                        <InlineIcon
                                          stopPropagation={true}
                                          onClick={
                                              (e) => {
                                                  this.getChildAssembly(prdInstance)
                                              }
                                          }
                                          className={prdInstance.listClass}
                                          onMouseOver={(e) => this.addHOverState(e)}
                                        >
                                          <ArrowIcon/>
                                        </InlineIcon> : null
                                    }
                                    {prdInstance.cpn}
                                </span>
                                    <Link
                                    to={to}
                                    className={`link`}>
                                    {prdInstance.name}
                                    </Link>
                            </span>,
                tooltip     : `${prdInstance.cpn} ${prdInstance.name}`,
            },
            "images" : {
                value       : 0,
                displayValue: <InlineImageViewer key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={prdInstance.images} imagesWithSrc={this.props.imagesWithSrc} />,
                notLink     : true,
                cellClass   : "inline-image-viewer"
            },
            "level" : {
                value       : level,
                displayValue: level,
                tooltip     : level
            },
            "serial" : {
                value       : prdInstance.serial,
                displayValue: <Tooltip
                                    {...serialDuplicateTooltipProps}
                                    placement={"right"}
                                    overlayClassName={"simple-rc-tip error"}
                                    getTooltipContainer={() => document.querySelector("#routes")}
                                    overlay={ serialDuplicateTooltip
                                    ?
                                        <div>
                                          <p className="serial-validation-tooltip">
                                            <span className="link-text">{serialDuplicateTooltip.errorMessage}</span>
                                            <br/>
                                            {
                                                serialDuplicateTooltip.alias === "prd"
                                                ?
                                                    <Link
                                                        to={serialDuplicateTooltip.viewLink}
                                                        target="_blank"
                                                        className="open-link-holder white"
                                                        >
                                                        <span className="link-text">{serialDuplicateTooltip.linkMessage}
                                                          <InlineIcon >
                                                            <LinkIcon/>
                                                          </InlineIcon>
                                                        </span>
                                                    </Link>
                                                :
                                                    <span className="link-text">{serialDuplicateTooltip.linkMessage}</span>
                                            }
                                          </p>
                                        </div>
                                    : ""
                                  }
                                  >
                                  <LazyInput
                                    type="text"
                                    name="serial"
                                    className={serialDuplicateTooltip ? "invalid" : prdInstance.serialClass || ''}
                                    value={prdInstance.serial}
                                    onChange={(e) => this.onInputChange(e, prdInstance)}
                                    data-place="right"
                                    data-type="error"
                                    data-tip={serialDuplicateTooltip ? "" : (prdInstance.serialErrorMsg || '')}
                                />
                                </Tooltip>,
                tooltip     : prdInstance.serial,
                cellClass   : 'instance-input',
                notLink     : true
            },
            "label" : {
                value       : prdInstance.label,
                displayValue: <LazyInput
                                    type="text"
                                    name="label"
                                    className={prdInstance.labelClass || ''}
                                    value={prdInstance.label}
                                    onChange={(e) => this.onInputChange(e, prdInstance)}
                                    data-place="right"
                                    data-type="error"
                                    data-tip={prdInstance.labelErrorMsg || ''}
                                />,
                tooltip     : prdInstance.label,
                cellClass   : 'instance-input',
                notLink     : true,
                haveInput   : true,

            },
            "category" : {
                value       : Schemas.component.category.getDisplayName(prdInstance.category),
                displayValue: Schemas.component.category.getDisplayName(prdInstance.category),
                tooltip     : Schemas.component.category.getDisplayName(prdInstance.category)
            },

            "cmpState" : {
                value       : cmpState,
                displayValue: cmpState,
                tooltip     : cmpState
            },
            "revision" : {
                value       : prdInstance.revision,
                displayValue: prdInstance.revision,
                tooltip     : "tooltip"
            },
            "eid" : {
                value       : prdInstance.eid,
                displayValue: prdInstance.eid,
                tooltip     : prdInstance.eid
            },
            "status" : {
                value       : prdInstance.status,
                displayValue:   <StatusField item={prdInstance} status={prdInstance.status}/>,
                tooltip     : prdInstance.status
            },
            "productionDate":
            {
                value       : prdInstance.productionDate,
                tooltip     : dateTime ? `${dateTime.dateValue} ${dateTime.timeValue}` : '',
                displayValue: <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={prdInstance.productionDate} />
            },
            rowLink         : to
        }
        return cells
    }

    async componentWillReceiveProps(nextProps) {
        const { children } = this.state;
        const { collapseTreeView } = nextProps;

        const updateRows = () => {
            this.generateRows();
            this.props.afterToggleTreeView();
        }

        if (collapseTreeView === 'collapse') {
          const rootChilds = this.removeNestedChildren(children);

          this.setState({ children: rootChilds }, updateRows);
        }

        if (collapseTreeView === 'expandLevel') {
            const openedChilds = await this.setChildOpen(children);

            this.setState({ children: openedChilds }, updateRows);
        }

        if (collapseTreeView === 'expand') {
            const openedChilds = await this.setChildOpen(children, false);

            this.setState({ children: openedChilds }, updateRows);
        }

        if (nextProps.newSerialValue !== this.props.newSerialValue)
        {
            // ---------- Scenario to capture Serial Validation when Child changes (Child -> Parent)

            // Reset Object of Serial when serial number changed from Parent
            // So that this Parent object can be used to check any matched value
            // when user change serial number of any child

            let parentId = this.props.objectData._id;
            this.items[`${parentId}`] = {
                serial: {
                    message: this.props.inputs.serial.message,
                    valid: this.props.inputs.serial.class !== "invalid",
                    class: this.props.inputs.serial.class,
                    value: this.props.inputs.serial.value,
                }
            }

            if (this.props.inputs.serialDuplicateOf)
            {
                this.items[`${this.props.objectData._id}`].serial.duplicateOf = this.props.inputs.serialDuplicateOf;
            }

            // ---------- Scenario to capture Serial Validation when Parent changes (Parent -> Child)

            const keys = Object.keys(this.items);
            let keysToRemoveDuplicateOf = [];
            let keysForChildErrorExistForAnotherChild = [];

            keys.forEach((key) => {
                if (key !== parentId)
                {
                    let serialItem = this.items[key].serial
                    if (nextProps.newSerialValue && serialItem.value && serialItem.value.toUpperCase() === nextProps.newSerialValue.toUpperCase())
                    {
                            let { inputs } = this.props

                            // Set For Parent
                            inputs.serial.valid = false
                            inputs.serial.message = "Duplicate Serial Number Found"
                            inputs.serial.class = "invalid"
                            inputs.serial.errorSetFromChild = true

                            // Set For Child
                            this.setSerialDuplicateError(key)
                            this.items[key].serial.isErrorSetFromParent = true
                    }
                    else if (this.items[key].serial.isErrorSetFromParent)
                    {
                        delete this.items[key].serial.isErrorSetFromParent

                        // Do not reset error, if error also exist for child
                        if (this.items[key].serial.isChildErrorExistForAnotherChild && !this.items[key].serial.isChildErrorExistForBothChildAndParent)
                        {
                            if (this.items[key].serial.duplicateOf)
                            {
                                keysToRemoveDuplicateOf.push(key)
                            }
                        }
                        else if (this.items[key].serial.isChildErrorExistForBothChildAndParent)
                        {
                            delete this.items[key].serial.isChildErrorExistForBothChildAndParent
                            keysForChildErrorExistForAnotherChild.push(key)
                        }
                        else
                        {
                            this.setSerialDuplicateError(key, true)
                        }
                    }
                }
            });

            // If the error exist in between a parent and only 1 child, also duplicateOf doesn't exist
            // then reset the error for that child otherwise if two children are similar
            // then it should keep showing error.
            if (keysForChildErrorExistForAnotherChild.length === 1 )
            {
                let key = keysForChildErrorExistForAnotherChild[0];
                if (this.items[key].serial.duplicateOf)
                {
                    keysToRemoveDuplicateOf.push(key)
                }
                else
                {
                    this.setSerialDuplicateError(key, true)
                }
            }

            // if keysToRemoveDuplicateOf length is greater than one then it
            // means value matched with another value ("Duplicate Serial Number Found"), so do not set serial.message as empty
            if (keysToRemoveDuplicateOf.length === 1)
            {
                this.items[keysToRemoveDuplicateOf[0]].serial.message = "" // setting error message empty to show custom error tooltip
            }

            this.generateRows();
            this.setState({syncTable: true})
        }
      }

    componentDidMount()
    {
        let { children, rows } = this.state;
        !this.errors && rows ? this.assemblyErrors({children: children}, 0) : "";
        if(children && children.length > 0)
        {
            children.forEach((component, counter) =>
            {
                let childComponent = component.component;
                let child = childComponent && childComponent.children;
                if(child && child.length > 0)
                {
                    child.forEach((item) =>
                    {
                        if(typeof item.component === 'string')
                        {
                            let componentId = `${item.component}?include=children&lean=true`;
                            API.productionInstances.findById(componentId, (err, componentData) =>
                            {
                                item.component = componentData;
                                this.setItems(componentData);
                            });
                        }
                    });
                }
            });
        }
        //Note: Render assembly tree immediately without waiting for loading child components
        this.generateRows("open");
    }

    fetchComponent (component){
        return new Promise((resolve, reject) => {
            let componentId = `${component}?include=children&lean=true`;
            API.productionInstances.findById(componentId, (err, componentData) =>
            {
                component = componentData;
                this.setItems(componentData)
                resolve(component);
            });
        })
    };

    /**
    * Recursive traversal of children tree.
    * Loop through each children in the tree to set
    * listClass property to 'open'.
    */
    async setChildOpen(root, byLevel = true) {
        const startLevel = 0;
        const levels = new Map();
        const queue = [];

        queue.push([root, startLevel]);

        while (queue.length > 0) {
            const [node, level] = queue.shift();

            const levelNodes = levels.get(level) || [];
            levelNodes.push(node);
            levels.set(level, levelNodes);

            for(const { component } of node){
                let { children, listClass } = component;
                for (const childCmp of children) {
                    if(typeof childCmp.component === "string")
                    {
                        childCmp.component = await this.fetchComponent(childCmp.component)
                    }
                }
                children = Utils.sortComponents("component.cpn", children);
                !!children.length &&
                (byLevel ? listClass === "open" : true) &&
                queue.push([children, level + 1]);
            }
        }

        let result = [];

        for (let i = levels.size - 1; i >= 0; i--) {
            for (const row of levels.get(i)){
                const level = []
                for (const {component, ...childProps} of row) {
                  let { children, listClass } = component;
                  level.push({
                      ...childProps,
                      component: {
                          ...component,
                          listClass: "open",
                          children:
                          !!children.length &&
                          (byLevel ? listClass === "open" : true)
                              ? result.shift()
                              : children,
                      },
                  })
                }
                result.push(level);
            }
        }
        this.state.syncTable = true;
        return result.flat();
    }

    removeNestedChildren(children)
    {
        return children.map((child) => {
            let comp = child.component
            if (comp && typeof(comp) === "object")
            {
                child.component.listClass = null
            }

            let compRev = child.assemblyRevision
            if (compRev && typeof(compRev) === "object")
            {
                child.assemblyRevision.listClass = null
            }
            return child
        })
    }

    generateCells(root, level)
    {
        if (level === 0)
        {
            this.state.generatedRows = []
        }

        this.errors ? root.listClass = "open" : "";
        if (root.listClass !== "close" && root.children && root.children.length > 0 )
        {
            level++
            root.children.forEach((c) =>
            {
                if(level === 1)
                {
                    c.parent = this.props.objectData._id;
                    c.parentAlias = this.props.objectData.alias;
                    c.vendor= Utils.getVendor(this.props.objectData);
                }
                else
                {
                    c.parent = root._id;
                    c.parentAlias = root.alias;
                    c.vendor= Utils.getVendor(root);
                }

                c.rowIndex = this.state.generatedRows.length

                if (typeof(c.component) === "object")
                {
                    this.state.generatedRows.push(this.setCells(c, level));
                    this.generateCells(c.component, level);
                }
            })
        }
        const expandAllIcon = document.querySelector(".action-item.tree-expand-icon")
        const childsCount = this.getChildsCount(this.state.children)
        if (expandAllIcon)
        {
            if (childsCount === this.state.generatedRows.length)
            {
                expandAllIcon.classList.remove("active");
                expandAllIcon.classList.add("disabled");
            }
            else{
                expandAllIcon.classList.add("active");
                expandAllIcon.classList.remove("disabled");
            }
        }
        const collapseAllIcon = document.querySelector(".action-item.tree-collapse-icon")
        if (collapseAllIcon)
        {
            if(!this.errors) 
            {
                collapseAllIcon.classList.replace("disabled", "active");
            }
            else
            {
                collapseAllIcon.classList.replace("active", "disabled");
            }
        }
    }

    /**
     * Recursive traversal of children tree.
     * Loop through each children in the tree to count
     * the number of childrens.
     */
    getChildsCount(childrens) {
      return childrens.reduce(
        (count, { component }) =>
          (count +=
            Array.isArray(component.children) && component.children.length
              ? this.getChildsCount(component.children) + 1
              : 1),
        0
      );
    }

    render()
    {
        let { children, headings, generatedRows, current, syncTable,
            currentSortItemAscending } = this.state;

        if (children.length && this.state.showLoading)
        {
            return <Spinner iconClassName="where-used-modal-spinner"/>
        }

        let markup =
            <div className="assembly-tab app-row assembly-tab-edit-common">
                <ValidationRunningModal
                    open={ true }
                    message="Checking for Validation Issues."
                />
                <ExtendedTable
                    wrapperClassName="assembly-list-tree"
                    wrapperSelectorClass="assembly-list-tree"
                    headings={headings}
                    rows={generatedRows}
                    stylesName="assemblyTableTreeView"
                    allowRowSelect={false}
                    startStaticColumns={1}
                    currentSortItem={current}
                    collapseTreeView={this.props.collapseTreeView}
                    afterToggleTreeView={this.afterToggleTreeView}
                    currentSortItemAscending={currentSortItemAscending}
                    tableActionButtons={this.props.getIconsActionsList()}
                    scrollPagination={true}
                    disableDataSorting={true}
                    tableClass={"striped"}
                    paginationSize={30}
                    includeToolBar={true}
                    resultText={`${generatedRows.length} Instances`}
                    syncWithParentState={syncTable}
                    afterSyncWithParentState={this.afterSyncTable}
                    footerRow={
                        generatedRows.length === 0 &&
                        {
                            dataCellEl: <p>No Instance available</p>
                        }
                    }
                />
            </div>
        return markup
    }
}
const mapDispatchToProps = dispatch => ({
   dispatch,
})
export default connect(mapDispatchToProps)(InstanceAssembly)
