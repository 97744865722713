import schemas from "v1/modules/validations";
import {
  ChangeOrderType,
  SuperComponent,
  ChangeOrder,
  ChangeOrderUser,
  Approver,
  PlmAPIEffectivity,
} from "design/models/changeorder";
import { ChangeOrderREST } from "./rest";
import { getPayload, ChangeOrderSubmitType } from "./rest/submit-change-order";
import {
  storage,
  getChangeset,
  getModifiedChangeOrder,
  ChangesetStorage,
  resetCache,
  ChangeSetStorageStateUpdate,
  addTreeIdMapping,
} from "./storage";
import { createStore, Store } from "common/context/store";
import {
  ClientModulesSchemaValidationResult,
  ClientModulesValidationOpts,
  defaultClientModulesSchemaValidationResult,
  validateComponents,
  ValidationErrorGroup,
  ValidationErrorType,
  ValidationErrorTypeGroups,
} from "./utils";
import { getWindow } from "utils/window";
import { getRowsByComponentType, getRowsByProductType } from "./utils/filters";
import { getChangeOrderByIds } from "graphql/query/changeOrdersQueries";
import { filterAndDeduplicatePaths } from "../components/tables/changeorder-table/tree.paths";
import { sdk } from "./editor";

function getRowIdFromTreeId(treeId: string) {
  return storage.caches.duplicateComponents[treeId.split("::").at(-1) ?? ""];
}

function getRowFromTreeId(treeId: string) {
  return storage.caches.duplicateComponents[treeId.split("::").at(-1) ?? ""];
}

function batchSetChangeSetUpdate(
  uniqueRow: SuperComponent,
  state: ChangeSetStorageStateUpdate
) {
  const rows =
    storage.caches.duplicateComponents[uniqueRow.cpn.displayValue] ?? [];

  if (uniqueRow.path[1]) {
    const parent = storage.caches.rows[uniqueRow.path[0]];
    const parentChangeset = getChangeset(parent);
    const nextState = state(parentChangeset.getState());

    if (nextState.requiresValidation) {
      storage.caches.requiresValidation[parent.id] = true;
    } else {
      delete storage.caches.requiresValidation[parent.id];
    }

    parentChangeset.setState((prev) => ({
      ...prev,
      requiresValidation: nextState.requiresValidation,
    }));
  }

  for (const row of rows) {
    const changeset = getChangeset(row);
    const nextState = state(changeset.getState());

    if (nextState.requiresValidation) {
      storage.caches.requiresValidation[row.id] = true;
    } else {
      delete storage.caches.requiresValidation[row.id];
    }

    changeset.setState(nextState);
  }
}

// When a change order is submitted, the change order is updated with
// the latest revisions and statuses
const updateRemoteRevisions = async () => {
  const rows = storage.rows.getState();

  if (!rows.length) {
    return;
  }

  const seenIds = new Set<string>();

  for (const item of rows) {
    if (seenIds.has(item.id)) {
      continue;
    }

    const itemChanges = storage.changeset[item.id].getState();
    const { id, alias, lastModified } = item;
    const { status, legacyNextRevision, originalRevision, originalStatus } =
      itemChanges;

    const statusChanged = originalStatus !== status;
    const revChanged = originalRevision !== legacyNextRevision;

    const isModified = statusChanged || revChanged;

    if (!isModified) {
      continue;
    }

    if (!statusChanged) {
      await ChangeOrderREST.components.postComponentNextRevisionUpdate(
        id,
        legacyNextRevision,
        alias
      );
    } else {
      const updateNextRevisionPayload = {
        modified: true,
        status,
        revision: legacyNextRevision,
        nextCoRevision: legacyNextRevision,
        lastModified: new Date(lastModified).getTime(),
      };

      await ChangeOrderREST.components.postComponentUpdate(
        id,
        updateNextRevisionPayload,
        alias
      );

      seenIds.add(item.id);
    }
  }
};

// Submit change order to the server
const submitChangeorder = async (
  status: ChangeOrderSubmitType,
  isNewChangeOrder: boolean
) => {
  if (status === "SAVE_DRAFT" && !!storage.isNameFieldError.getState()) {
    return Promise.reject("Name field is required for draft change orders.");
  }

  if (status === "SUBMIT" && !storage.isValid.getState()) {
    return Promise.reject("Change order is not ready for submission.");
  }

  const userId = localStorage.getItem("__uid");
  const components = getRowsByComponentType(storage.rows.getState());
  const products = getRowsByProductType(storage.rows.getState());
  let remoteCo = storage.remoteCo?.getState();

  if (isNewChangeOrder) {
    // Create the new Change Order to get the provisioned ID
    const newChangeOrderId = await ChangeOrderREST.changeorder.create(
      userId ?? ""
    );

    // Fetch the Change Order Number associated with the newly created Change Order
    const conIdResponse = await getChangeOrderByIds([newChangeOrderId]);
    const changeorders = conIdResponse.data?.changeOrdersByIds ?? [];
    remoteCo = changeorders[0];
  }

  const payload = getPayload(
    status,
    components,
    products,
    getModifiedChangeOrder(remoteCo),
    userId
  );

  await updateRemoteRevisions();

  await ChangeOrderREST.changeorder.postChangeOrder(payload);

  // Crude redirect to the change order view page,
  // should be handled by the router later.
  window.location.href = `/changeorder/view/${payload._id}`;
};

const getRowValidations = (data: SuperComponent[]): SuperComponent[] => {
  return data.map((row) => {
    const validation = getChangeset(row).getState();
    const errors = (validation.errors ?? []).filter(
      (v): v is ClientModulesSchemaValidationResult => !v.valid && v != null
    );

    const error = errors[0] ?? defaultClientModulesSchemaValidationResult;

    // Validation has not run at least once.
    if (validation.requiresValidation) {
      const defaultError: ClientModulesSchemaValidationResult = {
        ...defaultClientModulesSchemaValidationResult,
        type: ValidationErrorType.RequiresValidation,
        valid: false,
      };

      return {
        ...row,
        errorType: ValidationErrorType.RequiresValidation,
        errors: [defaultError],
        requiresValidation: true,
        errorGroup: ValidationErrorGroup.RequiresValidation,
        error: defaultError,
      };
    }

    let errorGroup: ValidationErrorGroup = ValidationErrorGroup.IsValid;

    if (validation.requiresValidation) {
      errorGroup = ValidationErrorGroup.RequiresValidation;
    } else if (error?.type != null) {
      errorGroup = ValidationErrorTypeGroups[error.type];
    }

    return {
      ...row,
      errorType: error?.type,
      error,
      errors,
      errorGroup,
      requiresValidation: validation.requiresValidation,
    };
  });
};

const runValidationOnce = async (row: SuperComponent) => {
  const entry = storage.changeset[row.id].getState();
  const item = {
    ...row,
    legacyNextRevision: entry.legacyNextRevision,
    status: entry.status,
  };
  let valid = true;
  await validateComponents({
    items: item,
    onItemValidate: (errors, item) => {
      batchSetChangeSetUpdate(item, (prev) => ({
        ...prev,
        errors,
        requiresValidation: false,
      }));

      if (errors.length > 0) {
        valid = false;
      }
    },
    enableLogGrouping: process.env.NODE_ENV === "development",
  });

  if (Object.keys(storage.caches.requiresValidation).length === 0 && valid) {
    storage.isValid.setState(valid);
  }

  storage.rowIdValidated.setState(row.id);
};

const runValidations = async () => {
  const toValidate: SuperComponent[] = [];
  const itemsToValidate = Object.keys(storage.caches.requiresValidation);

  if (itemsToValidate.length === 0) {
    return {
      valid: true,
      items: [],
    };
  }

  for (const id of itemsToValidate) {
    const change = storage.changeset[id].getState();
    const item = storage.caches.rows[id];

    if (item) {
      toValidate.push({
        ...item,
        legacyNextRevision: change.legacyNextRevision,
        status: change.status,
      });
    }
  }

  let changeOrderLineItemsValid = true;

  await validateComponents({
    items: toValidate,
    onItemValidate: (errors, item) => {
      batchSetChangeSetUpdate(item, (prev) => ({
        ...prev,
        errors,
        requiresValidation: false,
      }));

      if (errors.length > 0) {
        changeOrderLineItemsValid = false;
      }
    },
    enableLogGrouping: process.env.NODE_ENV === "development",
  });

  storage.caches.requiresValidation = {};

  storage.isPendingValidation.setState(false);

  if (changeOrderLineItemsValid) {
    storage.isValid.setState(changeOrderLineItemsValid);
  }

  return {
    valid: changeOrderLineItemsValid,
    items: toValidate,
  };
};

const validateNavButton = async () => {
  try {
    storage.caches.isValidationLoading.setState(true);
    await runValidations();
  } catch (error) {
    console.error("Validation failed:", error);
  } finally {
    storage.caches.isValidationLoading.setState(false);
  }
};

const handleDisplayModal = (show: boolean) => {
  storage.displayChildrenModal.setState(show);
};

const normalizeRevision = (opts: ClientModulesValidationOpts, value: string) =>
  schemas.component.revision.normalize(opts, value).revision;

const getPreviousRevisionValue = (item: SuperComponent) => {
  if (item.previousStatus === "DESIGN" && item.previousRevisionValue === "") {
    return "1";
  }
  return item.previousRevisionValue
    ? item.previousRevisionValue
    : item.revisionValue;
};

async function getNextRevisionOnStatusChange(
  changeset: Store<ChangesetStorage>,
  input: SuperComponent,
  status: string
) {
  const { originalStatus } = changeset.getState();
  const item = { ...input, status };
  const previousRevision = getPreviousRevisionValue(item);

  const opts = {
    status: item.status,
    revSchemeType: getWindow().__revSchemeType,
    libraryType: getWindow().__libraryType,
    defaultBlacklistedRevisions: getWindow().__defaultBlacklistedRevisions,
    currentRevision: item.revisionValue,
    isClient: true,
    previousRevision,
    revisionBump: false,
  };

  const requiresRevisionBump =
    !item.modified &&
    item.status === originalStatus &&
    item.status === item.previousStatus;

  const requiresRevertedRevision =
    item.modified &&
    item.previousStatus === item.status &&
    item.status !== originalStatus;

  if (requiresRevisionBump) {
    const getBumpedRevision = await ChangeOrderREST.components.getNextRevision(
      item?.cpn?.displayValue,
      item?.status
    );
    const bumpedRevision = await getBumpedRevision();

    if (bumpedRevision) {
      item.legacyNextRevision = bumpedRevision;
      opts.revisionBump = true;
    }
  } else if (requiresRevertedRevision) {
    item.legacyNextRevision = item.previousRevisionValue;
  } else {
    item.legacyNextRevision = item.revisionValue;
  }

  return normalizeRevision(opts, item.legacyNextRevision) as string;
}

const updateStatus = async (item: SuperComponent, status: string) => {
  const entry = storage.changeset[item.id];
  const entryState = entry.getState();

  if (entryState.originalStatus !== status) {
    const revision = await getNextRevisionOnStatusChange(entry, item, status);

    batchSetChangeSetUpdate(item, (prev) => ({
      ...prev,
      status,
      errors: [],
      legacyNextRevision: revision,
      requiresValidation: true,
    }));
  } else {
    batchSetChangeSetUpdate(item, (prev) => ({
      ...prev,
      status: prev.originalStatus,
      legacyNextRevision: prev.originalRevision,
      requiresValidation: true,
    }));
  }

  storage.isPendingValidation.setState(true);
};

export const updateRevision = (item: SuperComponent, revision: string) => {
  batchSetChangeSetUpdate(item, (prev) => ({
    ...prev,
    errors: [],
    legacyNextRevision: revision,
    requiresValidation: true,
  }));

  storage.isPendingValidation.setState(true);
};

const deleteRowCache = (id: string) => {
  delete storage.caches.rows[id];
  delete storage.caches.requiresValidation[id];
  delete storage.changeset[id];
  delete storage.caches.duplicateComponents[id];
  delete storage.caches.duplicateComponentIds[id];
};

const deleteAllRows = () => {
  resetCache();
  storage.isPendingValidation.setState(false);
  storage.rows.setState(() => []);
};

const deleteRowsByIds = async (ids: string[]) => {
  const nextTreeState = { ...storage.treeData.getState() };
  const cIds = ids.flatMap((id) => id.split("::").at(-1) ?? "");
  const rows = storage.rows.getState();
  const nextRowsState = rows.filter(
    (row) => !cIds.includes(row.cpn.displayValue)
  );
  const filteredRows = filterRows(nextRowsState, nextTreeState);
  await storage.rows.setState(() => filteredRows);
  return Promise.resolve();
};

const setCache = (row: SuperComponent) => {
  if (!storage.caches.rows[row.id]) {
    storage.caches.rows[row.id] = row;
  }
  if (!storage.caches.requiresValidation[row.id]) {
    storage.caches.requiresValidation[row.id] = true;
  }
  if (!storage.changeset[row.id]) {
    storage.changeset[row.id] = createStore<ChangesetStorage>({
      requiresValidation: true,
      originalStatus: row.status,
      originalRevision: row.legacyNextRevision,
      status: row.status,
      legacyNextRevision: row.legacyNextRevision,
      errors: [],
    });
  }
};

// Append paths to the row if they don't exist,
// Example, user adds a row from the sidebar.
function appendPaths(row: SuperComponent) {
  return {
    ...row,
    treeId: row.treeId ? row.treeId : row.cpn.displayValue,
    path: row.path ? row.path : [row.cpn.displayValue],
  };
}

function filterRows(
  data: SuperComponent[],
  treeData: Record<string, SuperComponent>
) {
  const coRowIds = data.map((row) => row.cpn.displayValue);
  const treeValues = Object.values(treeData);
  const treeDataFiltered = filterAndDeduplicatePaths(treeValues, coRowIds);
  return treeDataFiltered;
}

// Add a row to the cache and return the next state without setting it.
const addRow = (
  row: SuperComponent,
  currentState: SuperComponent[],
  currentTreeData: Record<string, SuperComponent>
) => {
  if (!storage.caches.rows[row.id]) {
    const newRow = appendPaths(row);
    setCache(newRow);
    return {
      nextRowsState: [...currentState, newRow],
      nextTreeDataState: { ...currentTreeData, [newRow.treeId]: newRow },
    };
  }

  addTreeIdMapping(row);

  return {
    nextRowsState: currentState,
    nextTreeDataState: currentTreeData,
  };
};

// Use a reducer to create the final state once all rows are processed.
const addRows = async (rows: SuperComponent[]): Promise<void> => {
  const initialState = {
    nextRowsState: storage.rows.getState(),
    nextTreeDataState: storage.treeData.getState(),
  };

  const { nextRowsState, nextTreeDataState } = rows.reduce(
    ({ nextRowsState, nextTreeDataState }, row) =>
      addRow(row, nextRowsState, nextTreeDataState),
    initialState
  );

  // Update the state only once after processing all rows.
  await storage.rows.setState(filterRows(nextRowsState, nextTreeDataState));
  await storage.treeData.setState(nextTreeDataState);

  storage.isPendingValidation.setState(true);
};

const addPendingRows = async (): Promise<void> => {
  const pendingRows = [
    ...storage.pendingProducts.getState(),
    ...storage.pendingComponents.getState(),
  ];

  await addRows(pendingRows);
  // Batch state updates
  storage.pendingProducts.setState([]);
  storage.pendingComponents.setState([]);
};

const displaySaveUpdateTemplatesModal = (show: boolean) => {
  storage.displaySaveUpdateTemplatesModal.setState(show);
};

const displayModifyModal = (show: boolean) => {
  storage.displayModifyModal.setState(show);
};

const updateTempTemplateData = (
  templateName: string,
  approvers: ChangeOrderUser[],
  isPublic: boolean,
  approvalType: string
) => {
  // storage.tempTemplateData.setState({ templateName, approvers });
  storage.templates.setState({
    templateName,
    approvers,
    isPublic,
    approvalType,
  });
};
const displayDeleteTemplateModal = (show: boolean) => {
  storage.displayDeleteTemplateModal.setState(show);
};

// Revert all modified statuses/revisions when CO type is changed to DCO
// @TODO, figure out what happens with validations
const handleDCOChange = () => {
  const modifiedItems = Object.values(storage.changeset);
  for (const item of modifiedItems) {
    const { originalStatus, originalRevision } = item.getState();
    item.setState({
      ...item.getState(),
      status: originalStatus,
      legacyNextRevision: originalRevision,
      requiresValidation: true,
    });
  }

  storage.form.type.setState(ChangeOrderType.DCO);
};

function addApprovers() {
  const deduped = storage.pendingApprovers
    .getState()
    .filter(
      (approver) =>
        !storage.form.approverList.getState().some((a) => a.id === approver.id)
    );

  storage.form.approverList.setState((prev) => [...prev, ...deduped]);
  storage.pendingApprovers.setState([]);
}

function addUsersToBeNotified() {
  const deduped = storage.pendingInternalNotifyUsers
    .getState()
    .filter(
      (notifier) =>
        !storage.coInternalNotifyUsers
          .getState()
          .some((n) => n.id === notifier.id)
    );

  storage.coInternalNotifyUsers.setState((prev) => [...prev, ...deduped]);
  storage.pendingInternalNotifyUsers.setState([]);
}

const addExternalEmailsToBeNotified = (inputExternalNotifiers: string) => {
  const externalNotifiers = storage.coExternalNotifyUserEmails.getState();
  const newExternalNotifiers = inputExternalNotifiers
    .split(",")
    .map((email) => email.trim());
  const uniqueExternalNotifiers = Array.from(
    new Set([...externalNotifiers, ...newExternalNotifiers])
  );
  storage.coExternalNotifyUserEmails.setState(uniqueExternalNotifiers);
};

function convertApproverToUser(approver: Approver): ChangeOrderUser {
  return approver.user;
}

export async function setInitialChangeOrder(co: ChangeOrder | undefined) {
  if (co) {
    storage.remoteCo = createStore(co);
    const { form } = storage;
    form.con.setState(co.con);
    form.creator.setState(co.creator);
    form.name.setState(co.name);
    form.description.setState(co.description);
    form.type.setState(co.type);
    form.approvalType.setState(co.approvalType);
    form.approverList.setState(co.approvers.map(convertApproverToUser));
    storage.products.setState(() => co.products);
    storage.components.setState(() => co.components);
    storage.coInternalNotifyUsers.setState(() => co.coInternalNotifyUsers);
    storage.coExternalNotifyUserEmails.setState(
      () => co.coExternalNotifyUserEmails
    );
    storage.documentLinks.setState(() => co.documentLinks);
    if (co.erpOptions) {
      if (co.erpOptions.effectivity) {
        const unixStart = "1970-01-01T00:00:00.000Z";
        const { startDate, endDate } = co.erpOptions.effectivity;

        const effectivityResponseParsed: PlmAPIEffectivity = {
          ...co.erpOptions.effectivity,
          startDate: new Date(startDate)?.getTime(),
          endDate: new Date(endDate)?.getTime(),
        };

        const defaultEffectivityState = form.erpOptions.effectivity.getState();

        if (startDate === unixStart) {
          effectivityResponseParsed.startDate = new Date(
            defaultEffectivityState.startDate
          ).getTime();
        }

        if (endDate === unixStart) {
          effectivityResponseParsed.endDate = new Date(
            defaultEffectivityState.endDate
          ).getTime();
        }

        form.erpOptions.effectivity.setState(() => effectivityResponseParsed);
      }

      form.erpOptions.itemType.setState(co.erpOptions.itemType);

      if (co.erpOptions.additionalPayloadForNotifications) {
        form.erpOptions.additionalPayloadForNotifications.setState(
          co.erpOptions.additionalPayloadForNotifications
        );
      }
    }
    const data = [...co.products, ...co.components];
    await sdk.util.mountRemoteRows(data, co.tree);
    storage.coLoaded.setState(true);
  }
}

export const recipes = {
  validation: {
    runValidations,
    runValidationOnce,
    validateNavButton,
  },
  editor: {
    getRowsTree: filterRows,
    getRowValidations,
    addRow,
    addRows,
    addPendingRows,
    deleteAllRows,
    deleteRowsByIds,
    updateStatus,
    updateRevision,
    getChangeset,
    children: {
      handleDisplayModal,
    },
  },
  approvers: {
    convertApproverToUser,
    addApprovers,
    displaySaveUpdateTemplatesModal,
    displayModifyModal,
    updateTempTemplateData,
    displayDeleteTemplateModal,
  },
  changeorder: {
    handleDCOChange,
    setInitialChangeOrder,
    submitChangeorder,
  },
  notificationList: {
    addUsersToBeNotified,
    addExternalEmailsToBeNotified,
  },
};
