import "./index.scss"
import React, {Component}             from "react"
import DropZone                       from "react-dropzone"
import Config                         from "../../../../../modules/config"
import Utils                          from "../../../../../modules/utils"
import Icon                           from "../../../../ui/icon"
import uploadSrc                      from "../../../../../assets/icons/upload-s.svg"
import DeleteSrc                      from "../../../../../assets/icons/cross-icon.js"
import alertErrorSrc                  from "../../../../../assets/icons/alert-error.svg"
import API                            from "../../../../../modules/api"
import validations, {validateField}   from '../../../../../modules/validations';
import { connect }                    from "react-redux"
import ExtendedTable                  from "../../../common/extended-table"
import Permissions                    from "../../../../../modules/schemas/permissions"
import LazyInput                      from "../../../../ui/lazy-input/input.js"
import ProgressCircle                 from "../../../../ui/progress-circle";
import TimeStampColumn                from "../../../common/timestamp-column";
import VendorIcon                     from "../../../../../assets/icons/vendor-label";
import InlineIcon                     from "../../../../ui/icon/inline-icon.js";
import VendorIndexElement             from "../../../common/vendor-index-element";
import { DocumentRegenerateButton }   from "../../document-regenerate-button";
import { EditTabsContext } from "design/pages/common/editTabsContextProvider";
import { isPresentInInputFiles } from "@duro/changeorder/utils";

export class Documents extends Component
{
    static contextType = EditTabsContext;
    constructor(props, context)
    {
        super(props, context)
        this.onInputChange            = this.onInputChange.bind(this)
        this.addDocuments             = this.addDocuments.bind(this)
        this.uploadDocument           = this.uploadDocument.bind(this)
        this.setDocumentsData         = this.setDocumentsData.bind(this)
        this.removeDocument           = this.removeDocument.bind(this)
        this.onChange                 = this.onChange.bind(this)
        this.setDocuments             = this.setDocuments.bind(this)
        this.getUploadedFiles         = this.getUploadedFiles.bind(this)
        this.documentFiles            = this.props.documents
        this.schema                   = this.props.clientSchema
        this.documentSchema           = this.props.documentSchema
        this.coId = this.props.coId ? this.props.coId : null
        this.creteDocumentsInputs     = this.creteDocumentsInputs.bind(this)
        this.syncExtentendTable       = this.syncExtentendTable.bind(this)
        this.afterSyncWithParentState = this.afterSyncWithParentState.bind(this)
        this.onDropRejected           = this.onDropRejected.bind(this)
        this.handleSelectDisplay      = this.handleSelectDisplay.bind(this)

        let viewStyles;
        this.pusher = null;
        try
        {
            viewStyles = window.__userStyles.styles.documentsTable || {}
        }
        catch(error){
            viewStyles = {}
        }

        this.state =
        {
            syncWithParentState: true,
            currentSortItemAscending: ("defaultSortAssending" in viewStyles ? viewStyles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in viewStyles ? viewStyles.defaultSortColumnName : "name"),
            headings: [
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 50,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "ext",
                    displayName : "File type",
                    tooltip     : "File type",
                    sortable    : true,
                    minWidth    : 50,
                    width       : Utils.getStyleValue(viewStyles, "ext", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "ext", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "ext", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "size",
                    displayName : "File size",
                    tooltip     : "File size",
                    sortable    : true,
                    minWidth    : 50,
                    width       : Utils.getStyleValue(viewStyles, "size", "width", 124),
                    position    : Utils.getStyleValue(viewStyles, "size", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "size", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "type",
                    displayName : "Doc type",
                    tooltip     : "Doc type",
                    sortable    : true,
                    minWidth    : 165,
                    width       : Utils.getStyleValue(viewStyles, "type", "width", 165),
                    position    : Utils.getStyleValue(viewStyles, "type", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "type", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 107,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 130),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 4),
                    visibility  : props.cmptype === "co" ? false : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : props.cmptype === "co" ? true : false,
                    hideFromSettings: props.cmptype === "co" ? true : false
                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : 50,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 5),
                    visibility  : props.cmptype === "co" ? false : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : props.cmptype === "co" ? true : false,
                    hideFromSettings: props.cmptype === "co" ? true : false
                },
                {
                    key         : "lastUpdated",
                    displayName : "Last Updated",
                    tooltip     : "Last Updated",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "lastUpdated", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "lastUpdated", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "lastUpdated", "visibility", true),
                    disabled    : false,
                },
                {
                    key         : "remove",
                    displayName : "",
                    sortable    : false,
                    minWidth    : 30,
                    width       : 30,
                    visibility  : true,
                    headingClass   : "remove-col",
                    dragable    : false,
                    expandAble  : false,
                    disabled    : true,
                    hideFromSettings: true
                }
            ]
        }
        this.updateDocState = this.updateDocState.bind(this);
        this.getCompanyId   = this.getCompanyId.bind(this);
    }

    creteDocumentsInputs(documents)
    {
        documents.forEach((doc) =>
        {
            doc.inputs = doc.inputs ||
            {
                type :
                {
                    value : doc.specs.type,
                    message : "",
                    class   : "",
                    valid   : true
                },

                revision :
                {
                    value : doc.specs.revision,
                    message : "",
                    class   : "",
                    valid   : true
                },

                status :
                {
                    value : doc.specs.status,
                    message : "",
                    class   : "",
                    valid   : true
                },
                lastModified:
                {
                    value : doc.specs.lastModified,
                    message: "",
                    class: "",
                    valid: true
                }
            }
        })
        return documents
    }

    getCompanyId()
    {
        let {company} = this.props;
        if(company && company.data)
        {
            return Utils.extractId(company.data);
        }
        return null;
    }

    componentDidUpdate() {

        const documents = this.context.getDocuments();
        if (documents?.length && !documents.every((item) => "inputs" in item))
        {
            if (documents.some((item) => "file" in item)) {
                this.handleExistingDocs();
            }
            else
            {
                this.creteDocumentsInputs(documents);
            }
        }
    }

    componentDidMount() {
        const documents = this.context.getDocuments();
        if (documents?.length && !documents.every((item) => "inputs" in item)) {
            if (documents.some((item) => "file" in item)) {
                this.handleExistingDocs();
            }
        }
    }

    handleExistingDocs  = () => {
        this.setDocuments();
        if(this.props.initializePusher && !this.pusher) this.pusher = Utils.initPusher();
        let { syncWithParentState } = this.state;
        const documents = this.context.getDocuments();
        let companyId = this.getCompanyId();
        if(companyId && this.pusher)
        {
            let channel = this.pusher.subscribe(`company-${companyId}`);
            channel.bind('document-regeneration', res =>
            {
                if(!res) return;
                let { doc, found }     =  Utils.getMatchedDocument(documents, res.doc._id);
                if(!found) return;
                doc.specs.lastModified = res.lastModified;
                doc.status             = res.doc.status;
                syncWithParentState = true;
                this.context.setDocuments(documents);
                this.setState({syncWithParentState});
            });
        }
    }

    updateDocState(docId)
    {
        let { syncWithParentState } = this.state;
        const documents = this.context.getDocuments();
        let {doc, found} = Utils.getMatchedDocument(documents, docId);
        if(!found) return;
        doc.status = "PROCESSING";
        syncWithParentState = true;
        this.context.setDocuments(documents);
        this.setState({syncWithParentState});
    }

    setDocuments() {
        let files = this.context.getDocuments();
        let docs = []
        let data, info = {}
        if(files.length)
        {
            for (let doc of files)
            {
                info = { id: doc.file._id, processFlag: false, oldDocuments: true, errors: [] }
                data = {...doc.file, info, specs: doc.specs }
                docs.push(data)
            }
            this.context.setDocuments(this.creteDocumentsInputs(docs));
        }
        this.setState(this.state, () => {this.syncExtentendTable();})
    }

    onInputChange(event, i)
    {
        let name  = event.target.name
        let value = event.target.value
        const documents = this.context.getDocuments();
        let doc = documents[i]
        let specSchema = this.schema.specs
        let validator;
        switch(name)
        {
            case "type" :
            {
                let input = doc.inputs.type
                validator = specSchema.type
                validateField(input, validator, value, {allowedDocTypes: window.__allowedDocTypes})
                break
            }

            case "revision" :
            {
                let status = doc.inputs.status.value
                let input = doc.inputs.revision
                validator = specSchema.revision
                let previousRevision = doc.specs.revision === value && status === doc.specs.status ? "" : doc.specs.revision

                //To skip the greater than validations when user switch status from DESIGN -> PROTOTYPE
                const { revisionTypes } = Config;
                const validTypes = [
                    revisionTypes.alphaNumericXY,
                    revisionTypes.numericXY,
                    revisionTypes.numericAlphaXY,
                    revisionTypes.alphaBetaNumericXYZ
                ];
                let revSchemeType = validTypes.includes(window.__revSchemeType) ? window.__revSchemeType : "DEFAULT"

                let isClient = revSchemeType === "DEFAULT" && previousRevision === "DESIGN" && status === "PROTOTYPE" &&  previousRevision === value ? false : true

                let validationPayload = {
                    status: status,
                    revSchemeType: revSchemeType,
                    libraryType: window.__libraryType,
                    isClient,
                    previousRevision: previousRevision,
                    defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
                }

                validateField(input, validator, value.toUpperCase(), validationPayload);
                break
            }

            case "status" :
            {
                validator = specSchema.revision
                let currentRevision = doc.specs.revision
                let revisionInput = doc.inputs.revision
                let previousRevision = doc.specs.revision === revisionInput.value && value === doc.specs.status ? "" : doc.specs.revision
                doc.inputs.status.value = value

                const { revisionTypes } = Config;
                const validTypes = [
                    revisionTypes.alphaNumericXY,
                    revisionTypes.numericXY,
                    revisionTypes.numericAlphaXY,
                    revisionTypes.alphaBetaNumericXYZ
                ];
                let revSchemeType = validTypes.includes(window.__revSchemeType) ? window.__revSchemeType : "DEFAULT"
                
                let validationPayload = {
                    status: value,
                    revSchemeType: revSchemeType,
                    libraryType: window.__libraryType,
                    isClient: true,
                    revActionType: null,
                    previousRevision: previousRevision,
                    currentRevision
                }

                if (value == doc.specs.status)
                {
                    revisionInput.value = currentRevision
                }
                else
                {
                    revisionInput.value = validator.normalize(validationPayload, revisionInput.value).revision
                }
                validateField(revisionInput, validator, revisionInput.value, validationPayload);
                break
            }

            default :
            {
                // noop
            }
        }
        doc.info.isUpdated = true
        this.context.setDocuments(documents);
        this.setState(this.state, this.onChange)
    }

    submitParentForm(){
        const documents = this.context.getDocuments();
        let submitForm = true
        for (let document of documents){
            if (document.info.isPending == true)
                submitForm = false
        }
        if (submitForm == true){
            return this.props.submitForm()
        }
    }

    setDocumentsData(documents) {
        documents.map((file, index) => {
            file.specs = {
                    type     : "generic",
                    revision : "1",
                    status   : "DESIGN",
                    lastModified: Date.now()
                }
            file.info = {
                id: null,
                processFlag: false,
                isUpdated: false,
                isPending: false,
                errors: []
            }
            file.fileId = `file-${index}`
            if (window.__libraryType === "GENERAL")
            {
                switch (window.__revSchemeType) {
                    case Config.revisionTypes.numericAlphaXY:
                    case Config.revisionTypes.alphaNumericXY:
                    case Config.revisionTypes.numericXY:
                    case Config.revisionTypes.prototypeAlphaNumericXY:
                        file.specs.revision = "1"
                        break
                    case Config.revisionTypes.numericAlphaABXY:
                        file.specs.revision = "0A";
                        break;
                    case Config.revisionTypes.alphaBetaNumericXYZ:
                        file.specs.revision = "";
                        break;
                    default:
                        break
                }
            }
            return file
        })

        return documents;
    }

    addDocuments(files)
    {
        if (this.state.cmptype === 'co') {
            mixpanel.track('ChangeOrder', {
                co: this.props.coId,
                numFiles: files.length,
                operation: 'Add Documents',
                "Change Order UI Version": "v1"
            })
        }
        let newDocuments = this.setDocumentsData(files);
        this.context.setDocuments(this.creteDocumentsInputs([...this.context.getDocuments(), ...newDocuments]));
        this.uploadDocument(newDocuments);
    }

    uploadDocument(documents) {
        //set isBeingUploaded process flag true so we can display consisten UI
        if (documents.length === 0) return
        for (let document of documents){
            document.info.isBeingUploaded = true;
        }

        let docBatches = Utils.createBatches(documents, 5)
        let index = 0

        let onComplete = (err, data) =>
        {
            if(++index === docBatches.length)
            {
                return
            }
            create(index)
        }

        let onDocumentUpload = (doc, counter) =>
        {
            doc.info.isBeingUploaded = false;
            doc.info.processFlag = false;
            this.setState(this.state, this.onChange)
            if (counter === docBatches[index]?.length)
            {
                onComplete()
            }
        }

        let create = () =>
        {
            let counter = 0
            for (let doc of docBatches[index]){
                doc.specs = doc.specs;
                doc.info.processFlag = true;
                doc.info.isBeingUploaded = true;
                this.setState(this.state, this.onChange);
                let startTime =  new Date()/1000;

                if (isPresentInInputFiles(documents, doc.fileId)) {
                    Utils.uploadDocument("/documents", doc, (err, currentProgress) =>
                    {
                        Utils.updateDocProgress(currentProgress, startTime, doc, (fillRule) =>
                        {
                            doc.progressSoFar = fillRule;
                            this.setState(this.syncExtentendTable);
                        });
                    }, (err, res) =>
                    {
                        counter++
                        if(err)
                        {
                            doc.info.errors = err.errors[0].message;
                            onDocumentUpload(doc, counter);
                        }
                        else
                        {
                            doc.info.errors = "";
                            doc.info.id = res.data;
                            Utils.getDocument(res.data, (err, response) => {
                                if(!err)
                                {
                                    doc.src = response.src;
                                }
                                onDocumentUpload(doc, counter);
                            });
                        }
                    });
                }
                else
                {
                    counter++
                    if (counter === docBatches[index].length)
                    {
                        onComplete()
                    }
                }
            }//loop end
        }

        create(0)
        this.setState(this.state, this.onChange);
    }

    onChange()
    {
        let event = Utils.getEvent(this, this.context.getDocuments());
        let payload = {documents: this.getUploadedFiles(), isUploadedDocuments: this.getFilesProcessedState()}
        event.target.value = payload
        this.setState(this.state)
        this.props.onChange && this.props.onChange(event, payload)
        this.syncExtentendTable()
    }

    getUploadedFiles(){
        let data = []
        let newFile = {}
        for (let file of this.context.getDocuments()){
            if (file.info.id)
            {
                newFile = {}
                newFile.file = file.info.id
                newFile.specs = {}
                newFile.specs.type = file.inputs.type.value
                newFile.specs.revision = file.inputs.revision.value
                newFile.specs.status = file.inputs.status.value
                file.file = file.info.id
                data.push(newFile)
            }
        }
        return data
    }

    getFilesProcessedState(){
        let documentsProcessed = true
        for (let file of this.context.getDocuments()){
            if (file.info.processFlag == true || file.info.errors.length > 0 || !Utils.isValidated(file.inputs))
                documentsProcessed = false
        }
        return documentsProcessed
    }

    componentWillUnmount()
    {
        let companyId = this.getCompanyId();
        if(companyId && this.pusher)
        {
            this.pusher.unsubscribe(`company-${companyId}`);
        }
    }

    removeDocument(i, isVendorCmp, documentVendor)
    {
        if(isVendorCmp && Utils.isVendorDoc(documentVendor)) return;
        const documents = this.context.getDocuments();
        if(documents[i].info.errors.length)
        {
            this.props.toggleErrorCount(true, 'documentTab');
        }
        documents.splice(i, 1);
        this.context.setDocuments(documents);
        this.setState(this.state, this.onChange())
    }

    syncExtentendTable()
    {
        let state = this.state
        state.syncWithParentState = true
        this.setState(state)
    }

    afterSyncWithParentState()
    {
        this.setState({syncWithParentState: false});
    }

    onDropRejected(docs)
    {
        const maxFileSize = this.props.company.maxFileSize || Config.MAX_FILE_SIZE;
        let maxFileSizeInMB = maxFileSize/1000000;
        let documents = docs.map((doc, i) =>
        {
            let err = "Invalid file type";
            let fileSize = doc.size/1000000;
            if( fileSize > maxFileSizeInMB)
            {
                err = `File exceeds ${maxFileSizeInMB}mb limit`;
                this.props.toggleErrorCount(false, 'documentTab');
            }
            let file  = doc;
            file.specs =
            {
                    type     : "",
                    revision : "",
                    status   : ""
            }
            file.info =
            {
                id: null,
                processFlag: false,
                isUpdated: false,
                isPending: false,
                errors: [err],
                hasExceededMaxFileSize: err === `File exceeds ${maxFileSizeInMB}mb limit` ? true : false,
                hasInvalidFileType: err === "Invalid file type" ? true : false

            }
            return file;
        });
        this.context.setDocuments([...this.context.getDocuments(), ...this.creteDocumentsInputs(documents)]);
        this.setState(this.onChange);
    }

    handleSelectDisplay(isVendorCmp, docIsRejectedOrIsInProgress, doc, fieldName, i)
    {
        let docInput = "";
        if(isVendorCmp && Utils.isVendorDoc(doc.vendor))
        {
            docInput = (
                <LazyInput
                    type="text"
                    name={fieldName}
                    value={doc.inputs[fieldName]['value']}
                />
            );
        }
        else if(!docIsRejectedOrIsInProgress)
        {
            docInput = (
                <select
                    name={fieldName}
                    value={doc.inputs[fieldName]['value']}
                    className={doc.inputs[fieldName].class}
                    data-tip={doc.inputs[fieldName].message}
                    data-place="right"
                    data-type="error"
                    onChange={(event) => this.onInputChange(event, i)}
                    >
                    {Utils.toOptions(this.documentSchema.specs[fieldName].list(doc.specs.status))}
                </select>
            );
        }
        return docInput;
    }

    render()
    {
        // return <p> commented </p>
        const { headings } = this.state;
        const documents = this.context.getDocuments();
        if (documents?.length && !documents.every((item) => "inputs" in item )) return null
        let isVendorCmp = this.props.hasOwnProperty("vendorData") && this.props.vendorData.isVendorCmp;
        let tooltipText = this.props.vendorTooltipText ? this.props.vendorTooltipText : "";
        let rows = documents.map((doc, i) =>
        {
            let belongsToVendor = isVendorCmp && Utils.isVendorDoc(doc.vendor);
            let haveError = doc.info && doc.info.errors.length
            let errorTooltip = haveError ? "please remove this document to submit the form as document  " + doc.info.errors : ""
            let docIsRejectedOrIsInProgress = haveError || doc.info.isBeingUploaded;
            let lastModified        = doc.specs && doc.specs.lastModified ? doc.specs.lastModified : undefined;
            let lastModifiedTooltip = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;

            let parentVendor   = this.props.component ? Utils.getVendor(this.props.component).toUpperCase() : "";
            let needsToBeRegenerated = Utils.fileCanBeRegenerated(parentVendor, doc);

            let fileData =
            {
                vendor: parentVendor,
                fileType: Utils.extractDocumentTypeFromFileName(doc.name)
            };

            let cells = {

                "name": {
                    value       : doc.name,
                    tooltip     : doc.name,
                    notLink     : docIsRejectedOrIsInProgress || needsToBeRegenerated ? true : false,
                    displayValue:
                                <span className={needsToBeRegenerated ? "" : "link"}>
                                    {doc.name}
                                </span>
                },
                "status": {
                    value            : docIsRejectedOrIsInProgress ? "" : doc.inputs['status']["value"],
                    notLink          : docIsRejectedOrIsInProgress ? true : false,
                    displayValue     : this.handleSelectDisplay(isVendorCmp, docIsRejectedOrIsInProgress, doc, "status", i),
                    showVendorTooltip: belongsToVendor,
                    notLink          : true,
                    haveInput        : docIsRejectedOrIsInProgress ? false : true
                },

                "type": {
                    value            : docIsRejectedOrIsInProgress ? "" : doc.inputs['type']["value"],
                    notLink          : docIsRejectedOrIsInProgress ? true : false,
                    displayValue     : this.handleSelectDisplay(isVendorCmp, docIsRejectedOrIsInProgress, doc, "type", i),
                    showVendorTooltip: belongsToVendor,
                    notLink          : true,
                    haveInput        : docIsRejectedOrIsInProgress ? false : true

                },

                "revision": {
                    value            : "revision",
                    showVendorTooltip: belongsToVendor,
                    notLink     : docIsRejectedOrIsInProgress ? true : false,
                    displayValue: docIsRejectedOrIsInProgress ?
                                    this.props.cmptype === "co" ?
                                        doc.info.isBeingUploaded ?
                                        <div className="flex">
                                            <p className="current-status-text">{doc.info.errors.length ? doc.info.errors : "Waiting to upload..."}</p>
                                            {
                                                <span className="cancel-file" onClick={() => this.removeDocument(i)}> cancel </span>
                                            }
                                        </div>
                                        : doc.info.errors
                                    :
                                        doc.info.isBeingUploaded ?
                                        <div className="flex">
                                            {
                                                <span className="cancel-file" onClick={() => this.removeDocument(i)}> cancel </span>
                                            }
                                            <p className="current-status-text">{doc.info.errors.length ? doc.info.errors : "Waiting to upload..."}</p>
                                        </div>
                                        : doc.info.errors
                                    :
                                    <LazyInput
                                        type="text"
                                        name={'revision'}
                                        autoComplete="off"
                                        value={doc.inputs['revision']["value"]}
                                        className={doc.inputs['revision'].class}
                                        data-tip={doc.inputs['revision'].message}
                                        data-place="right"
                                        data-type="error"
                                        onChange={(event) => this.onInputChange(event, i)}
                                />,
                    notLink     : true,
                    haveInput   : docIsRejectedOrIsInProgress ? false : true
                },

                "ext": {
                    value       : docIsRejectedOrIsInProgress ? "" : Utils.getExtension(doc.name),
                    tooltip     : docIsRejectedOrIsInProgress ? "" : Utils.getExtension(doc.name).toUpperCase(),
                    displayValue: docIsRejectedOrIsInProgress ? "" : Utils.getExtension(doc.name).toUpperCase(),
                    notLink     : true
                },

                "size": {
                    value       : docIsRejectedOrIsInProgress ? "" : Utils.fileSize(doc.size),
                    tooltip     : docIsRejectedOrIsInProgress ? "" : Utils.fileSize(doc.size),
                    displayValue: docIsRejectedOrIsInProgress ? "" : Utils.fileSize(doc.size),
                    notLink     : true,
                    cellClass   : "file-size"
                },

                "lastUpdated" :
                {
                    value        : lastModified ? lastModified : '',
                    displayValue : lastModified &&
                                    <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified}  wrapperClassName='edit-mode'/>,
                    tooltip      : lastModifiedTooltip ? `${lastModifiedTooltip.dateValue} ${lastModifiedTooltip.timeValue}` : '',
                    cellClass    : "timestamp-column-edit-mode",
                    notLink      : true
                },

                "remove":  {
                    value        : "",
                    cellClass    : "remove-col",
                    notLink      : true,
                    displayValue : doc.info.isBeingUploaded ?
                                    <div className="progress-outline">
                                        <ProgressCircle progressSoFar={doc.progressSoFar} />
                                    </div>
                                    :
                                    <div className="document-remove" onClick={() => this.removeDocument(i, isVendorCmp, doc.vendor)}>
                                        <InlineIcon
                                            tooltip="Remove"
                                            tooltipPlace="top"
                                            >
                                            <DeleteSrc/>
                                        </InlineIcon>
                                    </div>
                },
                rowClassName : doc.info.isBeingUploaded ? "file-uploading" : haveError ? "file-upload-error" :  belongsToVendor ? "managed-by-vendor" : "",
                isUploadingFile: docIsRejectedOrIsInProgress,
                indexTableEl : haveError && !docIsRejectedOrIsInProgress ?
                                <Icon
                                    tooltip={errorTooltip}
                                    tooltipType="error"
                                    tooltipPlace="top"
                                    src={alertErrorSrc}
                                    className="warningEl"
                                />
                                :
                                    belongsToVendor ?
                                    <VendorIndexElement vendorTooltipText={tooltipText} />
                                :
                                null,
                rowLink         : docIsRejectedOrIsInProgress ? "" : needsToBeRegenerated ? "" : doc.src || "",
                simpleLink      : true,
                targetBlank     : true,
                "indexTableEl": needsToBeRegenerated ?
                < DocumentRegenerateButton docId={doc._id} fileData={fileData} docState={doc.status} updateDocState={this.updateDocState}/>
                    : null,
            }
            return cells
        })


        const maxFileSize = this.props.company.maxFileSize || Config.MAX_FILE_SIZE;
        let maxFileSizeInMB = maxFileSize/1000000;

        let markup =
            <div className="documents-tab" id="documents-tab">
                <div className="documents-tab-drop-zone">
                    <div>
                        <DropZone
                            ref="drop"
                            className="drop-zone"
                            activeClassName="accept-files"
                            rejectClassName="reject-files"
                            onDrop={this.addDocuments}
                            maxSize={maxFileSize}
                            onDropRejected={this.onDropRejected}
                            >
                            <div>
                                <div>
                                    <span>Drag Files to Upload (max {maxFileSizeInMB} MB)</span>
                                    <span>or Browse</span>
                                </div>
                                <Icon src={uploadSrc} />
                            </div>
                            <div className="modal"></div>
                        </DropZone>
                    </div>
                </div>


                <ExtendedTable
                    vendorTooltipText={tooltipText}
                    wrapperClassName="documents-table-view"
                    wrapperSelectorClass="documents-table-view"
                    headings={headings}
                    rows={rows}
                    stylesName={this.props.styleName ? this.props.styleName : "documentsTable"}
                    themeDataTableId={"documentsTable"}
                    borderedTable={true}
                    allowRowSelect={false}
                    endStaticColumns={1}
                    syncWithParentState={this.state.syncWithParentState}
                    afterSyncWithParentState={this.afterSyncWithParentState}
                    includeToolBar={true}
                    resultText={`${rows.length} Documents`}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    footerRow={ Permissions.can("create", "component", this.props.user.data) ? {
                                bordered: true,
                                dataCellEl: <p>Add Documents using drop zone above</p>,
                                indexCellEl: null
                            }
                            :
                            rows.length === 0 &&
                            {
                                dataCellEl: <p>No Assembly added</p>
                            }
                        }

                />

            </div>
        return markup
    }
}

export default connect((store) => store)(Documents)
