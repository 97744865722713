import { Box, styled, Typography } from "@mui/material";
import { DuroButton } from "common/components/button";

export const TooltipText = styled(Typography)({
  fontSize: 14,
  marginBottom: 8,
});

export const RecheckButton = styled(DuroButton)({
  position: "absolute",
  bottom: 0,
  right: 0,
});

export const Pane = styled("div")({
  // height: "100%",
});
