import { ValidationTooltipType } from "./types";
import { styled } from "@mui/material";
import { colorPalette } from "@duro/themes";
import { Pane, RecheckButton, TooltipText } from "./tooltip.styles";
import { useEffect, useState } from "react";

export const MultipleChangeOrdersError: ValidationTooltipType = ({
  row,
  error,
  handleButtonClick = () => {},
}) => {
  const dupes = error?.duplicateChangeOrders ?? [];

  return (
    <Pane>
      <TooltipText>
        This {row.alias?.toLowerCase() === "prd" ? "Product" : "Component"} is
        included in one or more existing open Change Orders.
      </TooltipText>
      <TooltipText>
        They must be resolved before submitting this new Change Order for
        approval.
      </TooltipText>

      <ChangeOrderList>
        {dupes.map((dupe) => (
          <ChangeOrderListItem key={dupe.id}>
            <a href={`/changeorder/view/${dupe.id}`} target="_blank">
              {dupe.con.displayValue}
            </a>
          </ChangeOrderListItem>
        ))}
      </ChangeOrderList>

      <RecheckButton onClick={handleButtonClick}>RECHECK</RecheckButton>
    </Pane>
  );
};

const ChangeOrderList = styled("ul")({
  height: 60,
  width: 210,
  overflowX: "hidden",
  overflowY: "scroll",
  "::-webkit-scrollbar": {
    backgroundColor: colorPalette.gray,
  },
  scrollbarColor: colorPalette.silver,
});

const ChangeOrderListItem = styled("li")({
  listStyleType: "none",
  marginBottom: 5,
});
