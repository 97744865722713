import { CPNCell } from "./cells/cpn-cell";
import { DescriptionCell, NameCell, toDefaults } from "./table.config";
import { DeleteCell, RevisionCell, StatusCell } from "./cells";

export const BulkColumnDef = [
  CPNCell,
  NameCell,
  StatusCell,
  RevisionCell,
  DescriptionCell,
  DeleteCell,
].map(toDefaults);
