import { CellParams } from "../cell.types";
import { RevisionFieldValue, RevisionInput } from "../fields";
import { Box, styled } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { colorPalette } from "components/themes";
import { sdk } from "features/changeorders/sdk/editor";
import { sdk as sdkv1 } from "features/changeorders/sdk";

const RevisionRenderCell = ({ row }: CellParams) => {
  const changes = sdk.state.getChangeset(row);
  const [errors] = changes.errors.useStore();
  const [requiresValidation] = changes.reviewed.useStore();
  const [status] = changes.status.useStore();
  const [legacyNextRevision] = changes.legacyNextRevision.useStore();
  const type = sdkv1.storage.form.type.getState();
  const resolution = sdkv1.storage.form.resolution.getState();

  const defaultNoError = {
    value: "",
    message: "",
    class: "valid",
    valid: true,
    children: [],
  };

  const error =
    (errors ?? []).find((error) => error.type === "revision") || defaultNoError;
  const childObsoleteWarning =
    (errors ?? []).find((error) => error.type === "obsolete-children") ||
    defaultNoError;

  return (
    <RevisionContainer>
      <RevisionFieldValue coType={type} resolution={resolution} item={row} />

      <ArrowSeparator />

      <RevisionInput
        changes={{
          status,
          legacyNextRevision,
          requiresValidation,
          errors,
          originalRevision: changes.originalRevision,
          originalStatus: changes.originalStatus,
        }}
        coType={type}
        item={row}
        error={error}
        childObsoleteWarning={childObsoleteWarning.valid === false}
        warning={false}
      />
    </RevisionContainer>
  );
};

export const RevisionCell = {
  renderCell: RevisionRenderCell,
  headerName: "revision",
  field: "lastReleaseRevision",
  width: 140,
};

const RevisionContainer = styled(Box)({
  height: 22,
  display: "flex",
  color: colorPalette.taupeGray,
  position: "relative",
});

const ArrowSeparator = styled(ArrowForward)({
  width: 12,
  margin: "0 3px 0 5px",
});
