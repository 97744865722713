import React, { Component } from "react";
import Link from "../../../../ui/link";
import Loading from "../../../../ui/inner-loading";
import Utils from "../../../../../modules/utils";
import Permissions from "../../../../../modules/schemas/permissions";
import ExtendedTablePaginated from "../../../common/extended-table/extended-table-paginated";
import PlusIcon from "../../../../../assets/icons/plus";
import FilterIcon from "../../../../../assets/icons/filter";
import InlineIcon from "../../../../ui/icon/inline-icon";
import TimeStampColumn from "../../../common/timestamp-column";
import FilterMenu from "../../../common/extended-table/filter-menu";
import { userOptedIntoBeta } from "features/changeorders/sdk/utils/create-new-co";

export class ChangeOrderList extends Component {
  constructor(props) {
    super(props);

    let viewStyles;
    try {
      viewStyles = window.__userStyles.styles.searchChangeOrders || {};
    } catch (error) {
      viewStyles = {};
    }

    this.state = {
      currentSortItemAscending:
        "defaultSortAssending" in viewStyles
          ? viewStyles.defaultSortAssending
          : false,
      current:
        "defaultSortColumnName" in viewStyles
          ? viewStyles.defaultSortColumnName
          : "lastModified",
      headings: [
        {
          key: "con",
          displayName: "CON",
          tooltip: "Change Order Number",
          sortable: true,
          minWidth: 66,
          width: Utils.getStyleValue(viewStyles, "con", "width", 133),
          position: Utils.getStyleValue(viewStyles, "con", "position", 0),
          visibility: Utils.getStyleValue(
            viewStyles,
            "con",
            "visibility",
            true
          ),
          disabled: true,
        },
        {
          key: "name",
          displayName: "Name",
          tooltip: "Name",
          sortable: true,
          minWidth: 77,
          width: Utils.getStyleValue(viewStyles, "name", "width", 177),
          position: Utils.getStyleValue(viewStyles, "name", "position", 1),
          visibility: Utils.getStyleValue(
            viewStyles,
            "name",
            "visibility",
            true
          ),
          disabled: true,
        },
        {
          key: "description",
          displayName: "Description",
          tooltip: "Description",
          sortable: true,
          minWidth: 124,
          width: Utils.getStyleValue(viewStyles, "description", "width", 259),
          position: Utils.getStyleValue(
            viewStyles,
            "description",
            "position",
            2
          ),
          visibility: Utils.getStyleValue(
            viewStyles,
            "description",
            "visibility",
            true
          ),
          disabled: false,
        },
        {
          key: "status",
          displayName: "Status",
          tooltip: "Status",
          sortable: true,
          minWidth: 87,
          width: Utils.getStyleValue(viewStyles, "status", "width", 237),
          position: Utils.getStyleValue(viewStyles, "status", "position", 3),
          visibility: Utils.getStyleValue(
            viewStyles,
            "status",
            "visibility",
            true
          ),
          disabled: false,
        },
        {
          key: "resolution",
          displayName: "Resolution",
          tooltip: "Resolution",
          sortable: true,
          minWidth: 118,
          width: Utils.getStyleValue(viewStyles, "resolution", "width", 143),
          position: Utils.getStyleValue(
            viewStyles,
            "resolution",
            "position",
            4
          ),
          visibility: Utils.getStyleValue(
            viewStyles,
            "resolution",
            "visibility",
            true
          ),
          disabled: false,
        },
        {
          key: "lastModified",
          displayName: "Last Modified",
          tooltip: "Last Modified",
          sortable: true,
          ascending: true,
          minWidth: 170,
          width: Utils.getStyleValue(viewStyles, "lastModified", "width", 170),
          position: Utils.getStyleValue(
            viewStyles,
            "lastModified",
            "position",
            6
          ),
          visibility: Utils.getStyleValue(
            viewStyles,
            "lastModified",
            "visibility",
            true
          ),
          disabled: false,
        },
      ],
      openFilter: false,
      headingsFilter: this.getInitialHeadingsFilters(),
      rows: [],
      filteredRows: [],
    };

    this.updateInitialData = this.updateInitialData.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getCOActionsList = this.getCOActionsList.bind(this);
    this.filterRows = this.filterRows.bind(this);
    this.removeFilters = this.removeFilters.bind(this);
    this.getActiveFilters = this.getActiveFilters.bind(this);
    this.getInitialHeadingsFilters = this.getInitialHeadingsFilters.bind(this);
    this.isDcoEnabled = this.isDcoEnabled.bind(this);
  }

  getInitialHeadingsFilters() {
    return [
      {
        key: "myChangeOrders",
        name: "My Change Orders",
        visibility: false,
      },
      {
        key: "needsMyApproval",
        name: "Needs My Approval",
        visibility: false,
      },
      {
        key: "approvedByMe",
        name: "Approved By Me",
        visibility: false,
      },
      {
        key: "rejectedByMe",
        name: "Rejected By Me",
        visibility: false,
      },
      {
        key: "allOpen",
        name: "All Open",
        visibility: false,
      },
      {
        key: "allClosed",
        name: "All Closed",
        visibility: false,
      },
      {
        key: "allApproved",
        name: "All Approved",
        visibility: false,
      },
      {
        key: "allRejected",
        name: "All Rejected",
        visibility: false,
      },
      {
        key: "allDrafts",
        name: "All Drafts",
        visibility: false,
      },
      {
        key: "allECOs",
        name: "All ECOs",
        visibility: false,
      },
      {
        key: "allMCOs",
        name: "All MCOs",
        visibility: false,
      },
    ];
  }

  isDcoEnabled() {
    return this.props.company?.data?.settings?.isDcoEnabled;
  }

  updateInitialData(self) {
    const { results } = self;

    if (results.length) {
      this.setState({
        rows: this.getRows(results),
        filteredRows: this.getRows(results),
      });
    }
  }

  // It update table rows when property 'result' is updated
  componentWillReceiveProps(nextProps) {
    const filters = this.getInitialHeadingsFilters();

    // if user has DCO enabled, add DCO filter
    if (this.isDcoEnabled()) {
      filters.push({
        key: "allDCOs",
        name: "All DCOs",
        visibility: false,
      });
    }

    this.setState({
      headingsFilter: filters,
      rows: this.getRows(nextProps.results),
    });
    setTimeout(() => {
      this.filterRows(filters);
    }, 0);
  }

  // It update table rows when property 'result' isn't updated
  componentDidMount() {
    this.updateInitialData(this.props);
  }

  getRows(res) {
    let rows = [];
    let results = res;

    results.map((result, i) => {
      let lastModified = result.lastModified ? result.lastModified : undefined;
      let lastUpdatedToolTip = lastModified
        ? Utils.dateTimeWithLongFormat(lastModified)
        : null;
      let cells = {
        con: {
          value: result.con,
          tooltip: result.con,
          displayValue: (
            <span className="link">{result.cpn || result.con}</span>
          ),
        },
        name: {
          value: result.name,
          tooltip: result.name,
          displayValue: result.name,
        },
        description: {
          value: result.description,
          tooltip: result.description,
          displayValue: result.description,
        },
        status: {
          value: result.status,
          tooltip: result.status,
          displayValue: (
            <span className={"status-label " + result.status}>
              {result.status}
            </span>
          ),
        },
        resolution: {
          value: result.resolution,
          tooltip: result.resolution,
          displayValue: (
            <span className={"resolution-label " + result.resolution}>
              {result.resolution}
            </span>
          ),
        },
        lastModified: {
          value: lastModified,
          tooltip: lastUpdatedToolTip
            ? `${lastUpdatedToolTip.dateValue} ${lastUpdatedToolTip.timeValue}`
            : "",
          displayValue: (
            <TimeStampColumn
              key={Utils.generateUniqueId()}
              format="date-time-with-long-format"
              value={lastModified}
            />
          ),
        },
        object: result,
        rowLink: "/changeorder/view/" + result._id,
        rowId: result._id,
      };
      rows.push(cells);
    });

    return rows;
  }

  getCOActionsList() {
    let actionsList = this.props.getIconsActionsList() || [];

    actionsList.push({
      type: "customEl",
      element: (
        <span
          key="filterKey"
          className={`action-item active flex-v${
            this.getActiveFilters() > 0 ? " active-filter" : ""
          }`}
          data-tip="Filter table results"
          onClick={() => this.setState({ openFilter: true })}
          onMouseOver={this.onMouseHover}
          onMouseLeave={this.onMouseLeave}
        >
          <InlineIcon className="flex-v">
            <FilterIcon />
          </InlineIcon>
          <span className="item-title">Filter</span>
          <FilterMenu
            openMenu={this.state.openFilter}
            toggleOpenMenu={(e) => this.setState({ openFilter: e })}
            headings={this.state.headingsFilter}
            headingsSelect={(items) => this.filterRows(items)}
          />
        </span>
      ),
    });

    return actionsList;
  }

  filterRows(filters) {
    const userId = this.props.user.data && this.props.user.data._id;
    window.localStorage.setItem("filters", JSON.stringify(filters));
    const isDCOEnabled = this.isDcoEnabled();

    this.setState({
      filteredRows: this.state.rows.filter((val) => {
        return (
          (filters[0]?.visibility
            ? val?.object?.creator?.includes(userId)
            : true) &&
          (filters[1]?.visibility
            ? val?.object?.approverList?.some((appr) => {
                return appr?.user === userId && appr?.action === "";
              })
            : true) &&
          (filters[2]?.visibility
            ? val?.object?.approverList?.some((appr) => {
                return appr?.user === userId && appr?.action === "APPROVED";
              })
            : true) &&
          (filters[3]?.visibility
            ? val?.object?.approverList?.some((appr) => {
                return appr?.user === userId && appr?.action === "REJECTED";
              })
            : true) &&
          (filters[4]?.visibility
            ? val?.object?.status?.includes("OPEN")
            : true) &&
          (filters[5]?.visibility
            ? val?.object?.status?.includes("CLOSED")
            : true) &&
          (filters[6]?.visibility
            ? val?.resolution?.value?.includes("APPROVED")
            : true) &&
          (filters[7]?.visibility
            ? val?.resolution?.value?.includes("REJECTED")
            : true) &&
          (filters[8]?.visibility
            ? val?.status?.value?.includes("DRAFT")
            : true) &&
          (filters[9]?.visibility
            ? val?.object?.type?.includes("ECO")
            : true) &&
          (filters[10]?.visibility
            ? val?.object?.type?.includes("MCO")
            : true) &&
          (isDCOEnabled && filters[11]?.visibility
            ? val?.object?.type?.includes("DCO")
            : true)
        );
      }),
    });
  }

  removeFilters(e) {
    e.preventDefault();
    let headings = [...this.state.headingsFilter];

    headings.map((val) => (val.visibility = false));
    this.setState({
      headingsFilter: headings,
      filteredRows: [...this.state.rows],
    });
    window.localStorage.removeItem("filters");
  }

  getActiveFilters() {
    let ini = 0;
    return this.state.headingsFilter.reduce(
      (bef, val) => (val.visibility ? ++bef : bef),
      ini
    );
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    let { headings, filteredRows } = this.state;

    let markup = (
      <div>
        <ExtendedTablePaginated
          wrapperClassName="co-list-block"
          wrapperSelectorClass="co-list-view"
          headings={headings}
          headingsFilter={this.getActiveFilters()}
          removeFilters={(e) => this.removeFilters(e)}
          rows={filteredRows}
          stylesName="searchChangeOrders"
          allowRowSelect={false}
          onRowSelect={this.props.addToSelectedList}
          onSelectAllRows={this.props.selectAllResults}
          currentSortItem={this.state.current}
          currentSortItemAscending={this.state.currentSortItemAscending}
          resetLocalState={this.props.resetLocalState}
          clearSelectedRowsStore={this.props.clearSelectedRowsStore}
          resultText={`${filteredRows.length} results`}
          includeToolBar={true}
          tableActionButtons={this.getCOActionsList()}
          scrollPagination={true}
          paginationSize={150}
          count={this.props.count}
          footerRow={
            Permissions.can("create", "change_order", this.props.user.data)
              ? {
                  bordered: true,
                  dataCellEl: <p>Add Change Orders</p>,
                  indexCellEl: Permissions.can(
                    "create",
                    "change_order",
                    this.props.user.data
                  ) && (
                    <div className="add-more-actions">
                      <Link
                        to={
                          userOptedIntoBeta
                            ? { pathname: "/changeorder/new/order" }
                            : ""
                        }
                        onClick={this.props.createCoWithDefaultData}
                      >
                        <button className="add-trigger" data-tip={"Add..."}>
                          <InlineIcon className="plus-icon">
                            <PlusIcon />
                          </InlineIcon>
                        </button>
                      </Link>
                    </div>
                  ),
                }
              : filteredRows.length === 0 && {
                  dataCellEl: <p>No Change Orders</p>,
                }
          }
        />
      </div>
    );

    return markup;
  }
}

export default ChangeOrderList;
