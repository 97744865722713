import { Icon, styled } from "@mui/material";
import { DuroSize, DuroSizeMap } from "@duro/utils"; // Ensure the correct import path

interface DuroIconProps {
  size: DuroSize;
}

const DuroIcon = styled(Icon)<DuroIconProps>(({ size }) => ({
  fontSize: DuroSizeMap[size],
  height: "100%",
  "& > svg": {
    fontSize: DuroSizeMap[size],
    height: DuroSizeMap[size],
    width: DuroSizeMap[size],
  },
}));

export default DuroIcon;
