import {
  createContext, useContext, useState, useMemo, useCallback, FC, ReactNode,
} from "react";
import { CadAndPdmSettings } from "common/models";

export interface IFindAndReplaceFieldsData {
  find: string,
  matchCase: boolean,
  replace: string,
  useRegex: boolean,
}

interface FileNameFormatSettings {
  currentType: string,
  customScheme: {
    customFieldError: boolean,
    customSchemeData: string,
  },
  findAndReplaceFields: {
    findAndReplaceFieldsData: IFindAndReplaceFieldsData[],
  },
}

interface IContext {
  getAllowedRevisionType: () => string,
  getEidToFileNameValue: () => boolean,
  getFileNameFormatSettingsData: () => FileNameFormatSettings,
  getOverwriteFileNameValue: () => boolean,
  getProcurement: () => string,
  setAllowedRevisionTypeValue: (_val: string) => void,
  setEidToFileNameValue: (_val: boolean) => void,
  setFileNameFormatSettingsData: (_val: Partial<FileNameFormatSettings>) => void,
  setOverwriteFileNameValue: (_val: boolean) => void,
  setProcurementValue: (_val: string) => void,
}

interface ICadSettingsModalProvider {
  cadSettingsData: CadAndPdmSettings;
  children: ReactNode;
}

type findAndReplaceFieldsResponseType = IFindAndReplaceFieldsData & { __typename?: string };

export const FileNameFormatSettingsDefault = {
  currentType: "STANDARD",
  customScheme: {
    customFieldError: false,
    customSchemeData: "",
  },
  findAndReplaceFields: {
    findAndReplaceFieldsData: [
      {
        find: "",
        matchCase: false,
        replace: "",
        useRegex: false,
      },
    ],
  },
};

export const CadSettingsModalContext = createContext<IContext>({
  getAllowedRevisionType: () => "ALPHA_NUMERIC",
  getEidToFileNameValue: () => false,
  getFileNameFormatSettingsData: () => FileNameFormatSettingsDefault,
  getOverwriteFileNameValue: () => false,
  getProcurement: () => "MAKE",
  setAllowedRevisionTypeValue: (_val: string) => { },
  setEidToFileNameValue: (_val: boolean) => { },
  setFileNameFormatSettingsData: (_val: Partial<FileNameFormatSettings>) => { },
  setOverwriteFileNameValue: (_val: boolean) => { },
  setProcurementValue: (_val: string) => { },
});

export const CadSettingsModalProvider: FC<ICadSettingsModalProvider> = (
  {
    cadSettingsData,
    children,
  },
) => {
  const initialFileNameFormatSettingsData = {
    currentType: cadSettingsData?.settings?.activeReleaseNamingScheme ?? "STANDARD",
    customScheme: {
      customFieldError: false,
      customSchemeData: cadSettingsData?.settings?.customReleaseNamingScheme ?? "",
    },
    findAndReplaceFields: {
      findAndReplaceFieldsData: cadSettingsData?.settings?.findAndReplaceReleaseNamingScheme?.length
        ? cadSettingsData?.settings?.findAndReplaceReleaseNamingScheme
          ?.map((item: findAndReplaceFieldsResponseType) => {
            const { __typename, ...rest } = item;
            return rest;
          })
        : [{
          find: "",
          matchCase: false,
          replace: "",
          useRegex: false,
        },
        ],
    },
  };
  const [fileNameFormatSettings, setFileNameFormatSettings] = useState(initialFileNameFormatSettingsData);

  // States of Checkboxes
  const [overwriteFileName, setOverwriteFileName] = useState<boolean>(
    cadSettingsData?.settings?.overwriteFilenameWithCpn ?? false,
  );
  const [eidToFileName, setEidToFileName] = useState<boolean>(
    cadSettingsData?.settings?.setEidToFilename ?? false,
  );
  // States of RadioButtons
  const [allowedRevisionType, setAllowedRevisionType] = useState<string>(
    cadSettingsData?.settings?.allowedDesignRevisionTypes ?? "ALPHA_NUMERIC",
  );
  const [procurement, setProcurement] = useState<string>(
    cadSettingsData?.settings?.procurementDefault ?? "MAKE",
  );

  const setFileNameFormatSettingsData = useCallback(data => {
    setFileNameFormatSettings(prevData => ({ ...prevData, ...data }));
  }, []);

  // Setters of Checkboxes
  const setOverwriteFileNameValue = useCallback(value => {
    setOverwriteFileName(value);
  }, []);

  const setEidToFileNameValue = useCallback(value => {
    setEidToFileName(value);
  }, []);

  // Setters of Radio Buttons
  const setAllowedRevisionTypeValue = useCallback(value => {
    setAllowedRevisionType(value);
  }, [setAllowedRevisionType]);

  const setProcurementValue = useCallback(value => {
    setProcurement(value);
  }, [setProcurement]);

  const getFileNameFormatSettingsData = useCallback(() => fileNameFormatSettings, [fileNameFormatSettings]);

  // Getters of Checkboxes
  const getOverwriteFileNameValue = useCallback(() => overwriteFileName, [overwriteFileName]);
  const getEidToFileNameValue = useCallback(() => eidToFileName, [eidToFileName]);

  // Getters of Radio Buttons
  const getAllowedRevisionType = useCallback(() => allowedRevisionType, [allowedRevisionType]);
  const getProcurement = useCallback(() => procurement, [procurement]);

  const CadSettingsModalContextValues = useMemo(() => ({
    getAllowedRevisionType,
    getEidToFileNameValue,
    getFileNameFormatSettingsData,
    getOverwriteFileNameValue,
    getProcurement,
    setAllowedRevisionTypeValue,
    setEidToFileNameValue,
    setFileNameFormatSettingsData,
    setOverwriteFileNameValue,
    setProcurementValue,
  }), [
    getAllowedRevisionType,
    getEidToFileNameValue,
    getFileNameFormatSettingsData,
    getOverwriteFileNameValue,
    getProcurement,
    setAllowedRevisionTypeValue,
    setEidToFileNameValue,
    setFileNameFormatSettingsData,
    setOverwriteFileNameValue,
    setProcurementValue,
  ]);

  return (
    <CadSettingsModalContext.Provider value={CadSettingsModalContextValues}>
      {children}
    </CadSettingsModalContext.Provider >
  );
};

export function useCadSettingsModalContext() {
  return useContext(CadSettingsModalContext);
}
