import { gql } from "@apollo/client";

export const FamiliesFragment = gql`
  fragment familiesFragment on Family {
      value
  }
`;
export const FamilySuffixFragment = gql`
  fragment familySuffixFragment on Family {
    cpns {
      component {
        id
        name
        cpn {
          displayValue
        }
        alias
      }
      suffix
    }
}
`;
