import "../index.scss"
import React, {Component}       from "react"
import ItemTeamIcon             from "../../../../../assets/icons/item-team-icon"
import Utils                    from "../../../../../modules/utils"
import TileItem                 from "../../tile-item"

export class ItemTeam extends Component
{
  constructor(props)
  {
    super(props)
  }

  render()
  {
    let { item } = this.props
    let teamPlayers = [
      { name: "product manager", id: "productManager" },
      { name: "engineering", id: "engineering" },
      { name: "quality assurance (qa)", id: "qa" },
      { name: "procurement", id: "procurement" },
      { name: "manufacturing", id: "manufacturing" }
    ]
    let markup =
      <TileItem title="Team" icon={<ItemTeamIcon />}>
        {
          teamPlayers.map(player => (
            <div className="player" key={`teamPlayer-${player.id}`}>
              <span className="player-title">{ player.name }</span>
              {
                item.team[player.id].email &&
                <a
                  href={`mailto:${item.team[player.id].email}`}
                  className={`player-email diff-${player.id}-section`}
                  data-tip=""
                  data-place="top"
                  onMouseOver={(e) => Utils.checkToolTipAppearance(e, item.team[player.id].email)}
                >
                  { item.team[player.id].email }
                </a>
              }
            </div>
          ))
        }
      </TileItem>
    return markup
  }
}
export default ItemTeam
