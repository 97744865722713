import { useMemo } from "react";
import { ApolloClient, FetchPolicy, NormalizedCacheObject, gql, useQuery } from "@apollo/client";
import { FamiliesFragment, FamilySuffixFragment } from "graphql/fragment/familyFragment";

export const GET_FAMILY = gql`
  query {
    families {
      ...familiesFragment
    }
  }
  ${FamiliesFragment}
`;

export const GET_FAMILY_SUFFIX = gql`
  query($family: String) {
    family(family: $family) {
      ...familySuffixFragment
    }
  }
  ${FamilySuffixFragment}
`;

interface IFamilySuffixListArgs {
  client: ApolloClient<NormalizedCacheObject>
  familyValue: string,
  fetchPolicy?: FetchPolicy,
}

export function useFamily(fetchPolicy: FetchPolicy = "network-only") {
  const { data, error, loading } = useQuery(GET_FAMILY, {
    fetchPolicy,
  });

  const family = useMemo(() => data, [data]);

  return { data: family, error, loading };
}

export async function getFamilySuffix(args: IFamilySuffixListArgs) {
  const { client, familyValue, fetchPolicy = "cache-first" } = args;
  const { data, error, loading } = await client.query({
    query: GET_FAMILY_SUFFIX,
    variables: { family: familyValue },
    fetchPolicy,
  });
  const family = data?.family ?? {};
  return { data: family, error, loading };
}
