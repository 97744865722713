import { Tooltip, styled } from "@mui/material";
import { useCallback, useMemo } from "react";
import { CellParams } from "../cell.types";
import DeleteSrc from "v1/assets/icons/cross-icon";
import { sdk } from "features/changeorders/sdk/editor";

const DeleteRenderCell = ({ row }: CellParams) => {
  const handleDeleteRows = useCallback(() => {
    sdk.editor.deleteRowsByTreeIds([row.id]);
  }, [row]);

  return useMemo(
    () => (
      <Tooltip title="Remove component">
        <StyledDeleteIcon onClick={handleDeleteRows}>
          <DeleteSrc />
        </StyledDeleteIcon>
      </Tooltip>
    ),
    []
  );
};

export const DeleteCell = {
  headerName: "",
  renderCell: DeleteRenderCell,
  field: "delete",
  width: 20,
};

const StyledDeleteIcon = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  cursor: "pointer",
});
