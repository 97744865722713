import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import * as NavButtons from "common/components/grid/toolbar-items";
import { SuperComponent } from "design/models/changeorder";
import { sdk } from "features/changeorders/sdk/editor";
import { useState, useMemo, useCallback } from "react";

export function appendDisabledStateToNavButton<
  T extends { isDisabled: () => boolean }
>(navButtons: T[], cond: boolean) {
  navButtons.forEach((navButton) => {
    navButton.isDisabled = () => cond;
  });
}

export const setAllRowsExpansion = (
  api: GridApiPro,
  data: { treeId: string }[],
  expand: boolean
) => {
  const allRowIds = data.map((row) => row.treeId);
  allRowIds.forEach((rowId) => {
    api.setRowChildrenExpansion(rowId, expand);
  });
};

export const useTreeView = (
  apiRef: React.MutableRefObject<GridApiPro>,
  data: SuperComponent[]
) => {
  const [isReviewed] = sdk.state.flags.isReviewed.useStore();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isTreeView, setIsTreeView] = useState(true);

  const NavButtonCollapse = useMemo(
    () => ({ ...NavButtons.NavButtonCollapse }),
    []
  );
  const NavButtonExpand = useMemo(
    () => ({ ...NavButtons.NavButtonExpand }),
    []
  );
  const NavButtonFlat = useMemo(() => ({ ...NavButtons.NavButtonFlat }), []);
  const NavButtonTree = useMemo(() => ({ ...NavButtons.NavButtonTree }), []);
  const NavButtonRemove = useMemo(
    () => ({ ...NavButtons.NavButtonRemove }),
    []
  );
  const NavButtonValidate = useMemo(
    () => ({
      ...NavButtons.NavButtonValidate,
      isDisabled: () => data.length === 0 || isReviewed,
    }),
    [data, isReviewed]
  );
  const NavButtonAddChildren = useMemo(
    () => ({ ...NavButtons.NavButtonIncludeChildren }),
    []
  );

  const hasChildrenInTable = useMemo(
    () => data.some((row) => row.path.length > 1),
    [data]
  );

  useMemo(() => {
    appendDisabledStateToNavButton([NavButtonTree], isTreeView);
    appendDisabledStateToNavButton([NavButtonFlat], !isTreeView);
    appendDisabledStateToNavButton(
      [NavButtonCollapse],
      !isExpanded || !hasChildrenInTable
    );
    appendDisabledStateToNavButton(
      [NavButtonExpand],
      isExpanded || !hasChildrenInTable
    );
  }, [isTreeView, isExpanded, hasChildrenInTable]);

  const handleTreeViewEnable = useCallback(() => setIsTreeView(true), []);
  const handleTreeViewDisable = useCallback(() => setIsTreeView(false), []);
  const handleExpandAllRows = useCallback(() => {
    setAllRowsExpansion(apiRef.current, data, true);
    setIsExpanded(true);
  }, [apiRef, data]);

  const handleCollapseAllRows = useCallback(() => {
    setAllRowsExpansion(apiRef.current, data, false);
    setIsExpanded(false);
  }, [apiRef, data]);

  NavButtonTree.onClick = handleTreeViewEnable;
  NavButtonFlat.onClick = handleTreeViewDisable;
  NavButtonExpand.onClick = handleExpandAllRows;
  NavButtonCollapse.onClick = handleCollapseAllRows;

  return useMemo(
    () => ({
      buttons: {
        NavButtonCollapse,
        NavButtonExpand,
        NavButtonFlat,
        NavButtonTree,
        NavButtonRemove,
        NavButtonValidate,
        NavButtonAddChildren,
      },
      isTreeView,
      setIsTreeView,
    }),
    [
      NavButtonCollapse,
      NavButtonExpand,
      NavButtonFlat,
      NavButtonTree,
      NavButtonRemove,
      NavButtonValidate,
      NavButtonAddChildren,
      isTreeView,
    ]
  );
};
