import "./index.scss"
import React, {Component}   from "react"
import Link                 from "../../../../ui/link"
import Utils                from "../../../../../modules/utils"
import Icon                 from "../../../../ui/icon"
import coSrc                from "../../../../../assets/icons/co.svg"
import buildAction          from "../../../../../helpers/buildAction";
import COMPONENT            from "../../../../../action-types/component";
import ItemDeleteModal      from "../../../common/view-action-items/prod-co-cpn-delete-modal";
import WarningModal         from "../../../../ui/warning-modal";
import EllipsisTooltip      from "../../../common/ellipsis-tooltip"
import CTAIconToolbar       from "../../../common/cta-icon-toolbar"

class Actions extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            revModal :
            {
                open : false
            },
            whereUsedModal: false,
            openVariantModal: false,
            deleteModal: false,
            modifiedRevision: false,
        }

        this.redirect = this.redirect.bind(this)
        this.openRevModal = this.openRevModal.bind(this)
        this.closeRevModal = this.closeRevModal.bind(this)
        this.onRevModalExternalClick = this.onRevModalExternalClick.bind(this)
        this.gotoRevision = this.gotoRevision.bind(this)
        this.openWhereUsedModal  = this.openWhereUsedModal.bind(this);
        this.closeWhereUsedModal = this.closeWhereUsedModal.bind(this);
        this.makeDuplicate       = this.makeDuplicate.bind(this);
        this.openVariantModal    = this.openVariantModal.bind(this);
        this.closeVariantModal   = this.closeVariantModal.bind(this);
        this.createVariant       = this.createVariant.bind(this);
        this.toggleFavorite      = this.toggleFavorite.bind(this);
        this.closeDeleteModal    = this.closeDeleteModal.bind(this);
        this.onDeleteClickHandler= this.onDeleteClickHandler.bind(this);
        this.deleteComponent     = this.deleteComponent.bind(this);
        this.addToChangeOrder    = this.addToChangeOrder.bind(this);
        this.showEditWarningWhileInCO = this.showEditWarningWhileInCO.bind(this);
        this.onRejectEditWhileInCO    = this.onRejectEditWhileInCO.bind(this);
        this.export              = this.export.bind(this);
    }

    redirect(path, state)
    {
        document.body.removeEventListener("click", this.onRevModalExternalClick)
        this.props.history.push({pathname: path, state: state})
    }

    openRevModal(event)
    {
        document.body.addEventListener("click", this.onRevModalExternalClick)
        let state = this.state
        state.revModal.open = true
        if(event && event.target.name === "modified-revision") state.modifiedRevision = true
        this.setState(this.state)
    }

    closeRevModal()
    {
        document.body.removeEventListener("click", this.onRevModalExternalClick)
        let state  = this.state
        state.revModal.open = false
        state.modifiedRevision = false
        this.setState(state)
    }

    onRevModalExternalClick(event)
    {
        let target     = event.target
        if (this.refs.revTableNode)
        {
            let parent     = this.refs.revTableNode.refs.revModalNode
            let isExternal = target !== parent && !Utils.isDescendant(parent, target)
            if(isExternal) this.closeRevModal()
        }
    }

    gotoRevision(id, isMostRecent=false)
    {
        let {component} = this.props
        if (isMostRecent)
        {
            return this.redirect(`/component/view/${component._id}${ window.__userRole === "SUPPLIER" ? '?viewRecent=true' : ''}`)
        }
        return this.redirect(`/component/revision/${id}`)
    }

    openWhereUsedModal(event)
    {
        this.setState({ whereUsedModal: true });
    }

    closeWhereUsedModal()
    {
        this.setState({ whereUsedModal: false });
    }

    makeDuplicate(actionType='duplicate')
    {
        let { component, dispatch, history } = this.props;
        let payload =
        {
            componentId: component._id,
            history,
        };
        dispatch(buildAction(COMPONENT.MAKE_DUPLICATE, payload));
    }

    createVariant(componentId)
    {
        const {dispatch, history} = this.props;
        let payload = {componentId, history}
        dispatch(buildAction(COMPONENT.CREATE_VARIANT, payload));
    }

    openVariantModal()
    {
        this.setState({ openVariantModal: true });
    }

    closeVariantModal()
    {
        this.setState({ openVariantModal: false });
    }

    toggleFavorite()
    {
        let { component, dispatch, user } = this.props;
        let payload =
        {
            user: user.data,
            component
        }
        dispatch(buildAction(COMPONENT.MARK_FAVOURITE, payload))
    }

    onDeleteClickHandler()
    {
        this.setState({ deleteModal: true });
    }

    closeDeleteModal()
    {
        this.setState({ deleteModal: false });
    }

    deleteComponent()
    {
        this.setState({ deleteModal: false }, this.props.deleteComponent());
    }

    addToChangeOrder(event)
    {
        event.preventDefault();
        let data =
        {
            children : [ this.props.component ]
        }
        this.props.addToChangeOrder(data)
    }

    showEditWarningWhileInCO()
    {
        this.setState({showEditWarningWhileInCO: true});
    }

    onRejectEditWhileInCO()
    {
        this.setState({showEditWarningWhileInCO: false});
    }

    export(event)
    {
        event.preventDefault()
        let data =
        {
           data :
           [
               this.props.revision
           ],
           mode: 'revision'
        }
        this.props.history.push("/export", data)
    }

    render()
    {
        let { component, revision, user } = this.props;
        user = user && user.data ? user.data : null;
        let releasedRev = Utils.getLastReleasedRevision(component.revisions)
        let userRole = window.__userRole
        let isRestrictedRole = Utils.checkForRestrictedRole(userRole);

        let disabledRevisionId = null
        let oldRevision  = component.revisions.slice(-1)[0]
        if (oldRevision && component.modified === false)
            disabledRevisionId = oldRevision._id
        let componentEditLink = `/component/edit/${component._id}`;
        let isReleasedRev = releasedRev._id ===  revision._id;
        let shocIconsForVendor = isReleasedRev && isRestrictedRole;
        const cmpRes = component.co?.resolution;
        const banner = (() => {
        if (cmpRes === "NONE") {
            return "an UNRESOLVED";
        } else if (cmpRes === "REJECTED") {
            return "a REJECTED";
        } else if (cmpRes === "APPROVED") {
            return "an APPROVED";
        }
        return "an UNRESOLVED";
        })();

        let markup =
            <div className="actions-block">

                {
                    // disabledRevisionId !== revision._id &&
                    (releasedRev._id ===  revision._id && isRestrictedRole) ? null :
                    <div className="banner-block">
                        <div className="app-row">
                            <div>
                                <Icon src={coSrc} />
                                <div>
                                    Currently viewing Revision
                                    <span> {revision.revision} </span>
                                    from
                                    <span> {Utils.dateInUSFormat(revision.created)}</span>
                                </div>
                            </div>
                            <div>
                                <Link to={isRestrictedRole ? `/component/revision/${releasedRev._id}` : `/component/view/${component._id}`}>
                                    <button>{isRestrictedRole ? 'VIEW LATEST RELEASE' : 'VIEW MOST RECENT'}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }

                <div className={`nav-block app-row ${releasedRev._id ===  revision._id && user && user.role === "SUPPLIER" ? 'released-revision' : ''}`}>
                    {/* <Breadcrumb
                        isVendorCmp={isVendorCmp}
                        vendorLabel={vendorLabel}
                        crumb={cpn}
                        crumbClasses={'_crumb'}
                    /> */}
                    <EllipsisTooltip
                        classes={"ellipsis-tooltip tooltip-no-width"}
                        title={revision.name}
                        innerClasses={"truncate-name"}
                    >
                        { revision.name }
                    </EllipsisTooltip>
                    <CTAIconToolbar
                        { ...this.props }
                        user={user}
                        item={component}
                        itemName="component"
                        itemEditLink={componentEditLink}
                        disabledRevisionId={disabledRevisionId}
                        gotoRevision={this.gotoRevision}
                    />
                </div>
                {
                    this.state.deleteModal &&
                    <ItemDeleteModal
                        onCloseDeleteModal={this.closeDeleteModal}
                        onDeleteBtnClick={this.deleteComponent}
                        headingTxt="Delete Component?"
                        bodyTxt="Deleting a Component permanently removes all data
                        including attachments. This can’t be undone."
                    />
                }
                {
                    this.state.showEditWarningWhileInCO &&
                        <WarningModal
                            onCancel={this.onRejectEditWhileInCO}
                            linkTo={componentEditLink}
                            isWarningIcon={true}
                            title={"Warning"}
                            description={`This component is currently in ${banner} Change Order. Any edits could impact the intention of the Change Order.`}
                    />
                }
            </div>
        return markup
    }
}

export default Actions


