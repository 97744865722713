import UI                                               from "../../../action-types/ui"
import PRODUCT                                          from "../../../action-types/product"
import buildAction                                      from "../../../helpers/buildAction"
import { getInputs }                                    from "./selector"
import API                                              from "../../../modules/api"
import Schemas                                          from "../../../modules/schemas"
import Utils                                            from "../../../modules/utils"
import validations, { validateField }                   from '../../../modules/validations';
import { takeEvery, takeLatest, all, cps, put, select, call } from "redux-saga/effects"
import payload from "v1/tests/fixtures/changeorder/new/createSubmiPayloadObject"


export function* updateProductName(action)
{
    let inputs = yield select(getInputs)
    let name   = action.payload.name
    let value  = action.payload.value
    let index  = action.payload.index
    let input  = ""

    try
    {
        input                 = inputs.name
        validateField(input, validations.product.name, value)
        inputs["nameDuplicateOf"] = null
        if (input.valid)
        {
            input.valid = false
            inputs.submit.class = "disabled"
            inputs.submit.class = "disabled"

            yield put(buildAction(PRODUCT.UPDATE_NEW_PRODUCT_FORM_FIELDS, inputs))
            yield call(Utils.delay)
            let data = yield cps(API.products.nameExists, {name: value})
            let exists = data.exist
            input.message = exists ? "Name already exists in library." : ""
            input.valid   = !input.message
            input.class   = input.valid ? "" : "invalid"
            inputs["nameDuplicateOf"] = data.duplicate_of
        }
        inputs.submit.class = Utils.isValidated(inputs) && inputs.isUploadedThumbnails && inputs.isUploadedDocuments ? "" : "disabled"
        yield put(buildAction(PRODUCT.UPDATE_NEW_PRODUCT_FORM_FIELDS, inputs))
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }

}

export function* updateProductEid(action)
{
    let inputs = yield select(getInputs)
    let name   = action.payload.name
    let value  = action.payload.value
    let index  = action.payload.index
    let input  = ""

    try
    {

        let input = inputs.eid
        validateField(inputs.eid, validations.product.eid, value)
        inputs["eidDuplicateOf"] = null
        if (input.valid)
        {
            input.valid = false
            inputs.submit.class = "disabled"
            yield put(buildAction(PRODUCT.UPDATE_NEW_PRODUCT_FORM_FIELDS, inputs))
            yield call(Utils.delay)
            try
            {
                let payload = {eid: String(value)}
                let data = yield cps(API.products.eidExists, payload)
                let exists = data.exist
                input.message = exists ? "EID already exists in library." : ""
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                inputs["eidDuplicateOf"] = data.duplicate_of
            }
            catch(err)
            {
                input.message = "Some error has been occured"
                input.valid   = !input.message
                input.class   = input.valid ? "" : "invalid"
                yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
            }
        }
        inputs.submit.class = Utils.isValidated(inputs) && inputs.isUploadedThumbnails && inputs.isUploadedDocuments ? "" : "disabled"
        yield put(buildAction(PRODUCT.UPDATE_NEW_PRODUCT_FORM_FIELDS, inputs))
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }

}


export function* updateInputState(action)
{
    let inputs = yield select(getInputs)
    let name   = action.payload.name
    let value  = action.payload.value
    let index  = action.payload.index
    let input  = ""

    switch(name)
    {
        case "revision" :
        {
            validateField(inputs.revision, validations.product.revision, value.toUpperCase(), {status: inputs.status.value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions})
            break
        }

        case "status" :
        {
            inputs.status.value   = value
            let validationPayload = {status: value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType}
            inputs.revision.value = validations.product.revision.normalize(validationPayload, null).revision//Schemas.product.revision.normalize(null, value)
            input                 = inputs.revision
            validateField(input, validations.component.revision, input.value, {status: inputs.status.value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType});
            // input.message         = Schemas.product.revision.validate(input.value, inputs.status.value)
            // input.valid           = !input.message
            // input.class           = input.valid ? "" : "invalid"
            break
        }

        case "description" :
        {
            validateField(inputs.description, validations.product.description, value)
            break
        }

        case "images" :
        {
            inputs.images = value.images
            inputs.isUploadedThumbnails = value.isUploadedThumbnails
            break
        }

        case "documents" :
        {
            inputs.documents = value.documents
            inputs.isUploadedDocuments = value.documentsProcessed
            break
        }

        case "productTeam" :
        {
            input         = inputs.productTeam[index]
            input.value   = Schemas.product.team.normalize(value)
            input.message = Schemas.product.team.validate(input.value)
            input.valid   = !input.message
            input.class   = input.valid ? "" : "invalid"
            break
        }

        case "categoryItem" :
        {
            input         = inputs.categoryList[index]
            input.value   = value
            input.checked = !input.checked
            break
        }
        
        case "category" :
        {
            
            const catList = Schemas.categories.list()
            const categoryObject = catList.find(cat => cat.name === value)
            
            inputs = {
                ...inputs,
                category: {
                    class: "",
                    disabled: false,
                    message: "",
                    valid: true,
                    name: categoryObject?.name,
                    code: categoryObject?.code,
                    value: value,
                    secretSuffixCategory: categoryObject?.code
                },
            }

            break
        }

        case "forecastsVolume" :
        {
            input = inputs.forecasts[index]
            value = value && Number(value) ? parseInt(value) : value
            validateField(input.volume, validations.product.forecasts.volume, value)
            break
        }

        case "forecastsTarget" :
        {
            input = inputs.forecasts[index]
            let previousInput = inputs.forecasts[index - 1]
            validateField(input.targetDate, validations.product.forecasts.targetDate, value ? value : 0)
            Utils.validateForecastDates(inputs, input)
            break
        }

        default :
        {
            // noop
        }
    }
    inputs.submit.class = Utils.isValidated(inputs) && inputs.isUploadedThumbnails && inputs.isUploadedDocuments ? "" : "disabled"
    yield put(buildAction(PRODUCT.UPDATE_NEW_PRODUCT_FORM_FIELDS, {...inputs}))
}

export function* submitProductForm(action)
{
    yield put(buildAction(PRODUCT.DISABLE_FORM, true))
    yield put(buildAction(UI.LOAD_START))

    try
    {
        const productId = yield cps(API.products.create, action.payload.data)
        yield put(buildAction(PRODUCT.RESET_PRODUCT_NEW_FORM, {}))
        action.payload.history.push({ pathname: ("/product/edit/" + productId), showAll: true, state: { isNewObject: true } })
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))

    }
    yield put(buildAction(UI.LOAD_END))
}

export function* uploadDocument(action)
{
    let inputs = yield select(getInputs)
    yield put(buildAction(PRODUCT.DISABLE_FORM, true))

    try
    {
        let response = null
        let index = action.payload.index

        if (index !== undefined && action.payload.documents[index].info.id)
        {

            let doc = action.payload.documents[index]
            let specs = { specs: doc.specs }
            try
            {
                doc.info.processFlag = true
                yield put(buildAction(PRODUCT.UPDATE_DOCUMENTS_STATE, action.payload.documents))

                response = yield cps(API.documents.update, doc.info.id, specs)

                doc.info.processFlag = false
                yield put(buildAction(PRODUCT.UPDATE_DOCUMENTS_STATE, action.payload.documents))

            }
            catch(err)
            {
                doc.info.processFlag = false
                doc.info.errors = err.errors
                yield put(buildAction(PRODUCT.UPDATE_DOCUMENTS_STATE, action.payload.documents))
            }
        }
        else
        {
            for (let doc of action.payload.documents)
            {
                if (!doc.info.id && doc.info.errors.length === 0)
                {
                    try
                    {
                        doc.info.processFlag = true
                        yield put(buildAction(PRODUCT.UPDATE_DOCUMENTS_STATE, action.payload.documents))

                        response = yield cps(API.documents.create, doc)
                        doc.info.id = response

                        doc.info.processFlag = false
                        yield put(buildAction(PRODUCT.UPDATE_DOCUMENTS_STATE, action.payload.documents))

                    }
                    catch(err)
                    {
                        doc.info.processFlag = false
                        doc.info.errors = err.errors
                        yield put(buildAction(PRODUCT.UPDATE_DOCUMENTS_STATE, action.payload.documents))
                    }
                }
            }

        }
        yield put(buildAction(PRODUCT.UPDATE_DOCUMENTS_STATE, action.payload.documents))

        inputs.submit.class = Utils.isValidated(inputs) ? "" : "disabled"
        if (!inputs.submit.class)
        {
            yield put(buildAction(PRODUCT.DISABLE_FORM, false))
        }

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }

}

export default function* (getState)
{
    yield all([
        takeEvery(PRODUCT.SUBMIT_PRODUCT_NEW_FORM, submitProductForm),
        takeLatest(PRODUCT.UPDATE_NEW_FORM_INPUT_STATE, updateInputState),
        takeEvery(PRODUCT.UPLOAD_DOCUMENT, uploadDocument),
        takeLatest(PRODUCT.NEW_FORM_NAME_CHANGE, updateProductName),
        takeLatest(PRODUCT.NEW_FORM_EID_CHANGE, updateProductEid)
    ])
}
