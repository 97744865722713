export enum ChangeOrderTabValue {
  APPROVERS = "approverList",
  CMP_PRD = "chidrenData",
  DOCUMENTS = "documentLinks",
  HISTORY = "history",
  NOTIFIERS = "notificationList",
}

export const enum ChangeOrderType {
  DCO = "dco",
  ECO = "eco",
  MCO = "mco",
}

/**
 *
 * Error handling; change order edit view.
 */
export enum ChangeOrderEditErrorTypes {
  RETRIEVE_CO_ERROR = "RETRIEVE_CO_ERROR",
  NO_CO_ERROR = "NO_CO_ERROR",
  INVALID_STATUS = "INVALID_STATUS",
  INVALID_REVISION = "INVALID_REVISION",
  INVALID_CO_OPENED = "INVALID_CO_OPENED",
  CO_SUBMISSION_ERROR = "CO_SUBMISSION_ERROR",
}

export type ChangeOrderEditErrorPayload = {
  type: ChangeOrderEditErrorTypes;
  message: string;
};

export type ChangeOrderEditErrors = Record<
  ChangeOrderEditErrorTypes,
  ChangeOrderEditErrorPayload
>;

export const ChangeOrderErrors: ChangeOrderEditErrors = {
  CO_SUBMISSION_ERROR: {
    type: ChangeOrderEditErrorTypes.CO_SUBMISSION_ERROR,
    message:
      "There was an error submitting the change order. Please try again.",
  },
  RETRIEVE_CO_ERROR: {
    type: ChangeOrderEditErrorTypes.RETRIEVE_CO_ERROR,
    message:
      "There was an error retrieving the change order. Please refresh the page. If the issue persists, please contact support.",
  },
  NO_CO_ERROR: {
    type: ChangeOrderEditErrorTypes.NO_CO_ERROR,
    message: "No change order with this id was found.",
  },
  INVALID_STATUS: {
    type: ChangeOrderEditErrorTypes.INVALID_STATUS,
    message: "Invalid status.",
  },
  INVALID_REVISION: {
    type: ChangeOrderEditErrorTypes.INVALID_REVISION,
    message: "Invalid revision.",
  },
  INVALID_CO_OPENED: {
    type: ChangeOrderEditErrorTypes.INVALID_CO_OPENED,
    message: "CPN already present in an open change order",
  },
};
