import { SuperComponent } from "design/models/changeorder";

export const createRow = (row: SuperComponent) => {
  if (row.oid) return row;

  const oid = String(row.id);

  return {
    ...row,
    path: [row.cpn.displayValue],
    treeId: row.cpn.displayValue,
    id: row.cpn.displayValue,
    oid,
  };
};

export const createTreeNode = (row: SuperComponent) => {
  if (row.oid) return row;

  const oid = String(row.id);

  return {
    ...row,
    id: row.cpn.displayValue,
    oid,
  };
};
