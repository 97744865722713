import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "../v1/modules/config";
import { ImageFragment } from "./fragment/imageFragment";

const httpLink = new HttpLink({
  uri: config.REACT_APP_GRAPHQL_URL+'/preview',
});

export const gatewayClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          imagesByIds: {
            read(_, { args, cache, toReference }) {
              const ids: string[] | undefined = args?.ids;

              // Empty id lists can just have an empty array returned.
              if (!ids?.length) return [];

              // Try an find all of the images in the cache, and if any are missing return undefined.
              // The undefined response means that apollo won't cache the response from this call.
              if (
                ids.find(
                  (id) =>
                    !cache.readFragment({
                      fragment: ImageFragment,
                      id: `Image:${id}`,
                    })
                )
              ) {
                return undefined;
              }

              // When all of the Image IDs are in the cache, then turn them into references to load
              // said images from cache.
              return ids.map((id) => toReference({ __typename: "Image", id }));
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: { errorPolicy: "all" },
  },
});

export function setGatewayHeaders(token: string) {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));

  gatewayClient.setLink(authLink.concat(httpLink));
}
