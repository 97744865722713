import View                         from "../../../../../ui/view"
import Link                         from "../../../../../ui/link"
import Utils                        from "../../../../../../modules/utils"
import ArrowIcon                    from "../../../../../../assets/icons/co-small-arrow"
import InlineIcon                   from "../../../../../ui/icon/inline-icon.js"
import React, {Component}           from "react"
import WhereUsedSrc                 from "../../../../../../assets/icons/where-used"
import CompareRevIcon               from "../../../../../../assets/icons/compare-rev.js";
import WhereUsedModal               from "../../../../common/view-action-items/where-used-modal"
import API                          from "../../../../../../modules/api"
import ExtendedTable                from "../../../../common/extended-table"
import Permissions                  from "../../../../../../modules/schemas/permissions"
import EditIcon                     from "../../../../../../assets/icons/edit"
import TimeStampColumn              from "../../../../common/timestamp-column";
import InlineImageViewer            from "../../../../common/inline-image-viewer/view";
import Config                       from "../../../../../../modules/config";
import { RevisionField } from "@duro/base/revision-field/";
import CpnField from "../../../../common/cpn-field";
import { StatusField } from "@duro/base/status-field";

class Children extends Component
{
    constructor(props)
    {
        super(props)
        let isRevisionTypeDefault = Utils.isRevisionTypeDefault()
        let revisionColumnMinWidth = isRevisionTypeDefault ? 120 : 190

        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.changeOrderAssemblyTableView || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.revisionScheme = isRevisionTypeDefault ? "default-rev-scheme" : "custom-rev-scheme"
        this.state =
        {
            current : "cpn",
            currentSortItemAscending: true,
            headings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    sortable    : true,
                    minWidth    : this.props.mode === "view" ? 160 : 120,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 160),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    disabled    : true

                },
                {
                    key         : "name",
                    displayName : "Name",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true

                },
                {
                    key         : "images",
                    displayName : "Images",
                    tooltip     : "Images",
                    sortable    : false,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "images", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "images", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "images", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastRelease",
                    displayName : "Last Release",
                    tooltip     : "Last Release",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "lastRelease", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "lastRelease", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "lastRelease", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "description",
                    displayName : "Description",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 340),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false

                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    sortable    : true,
                    minWidth    : revisionColumnMinWidth,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", revisionColumnMinWidth),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : false

                },
                {
                    key         : "status",
                    displayName : "Status",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 275),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "eid",
                    displayName : "EID",
                    sortable    : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(viewStyles, "lastUpdated", "width", 145),
                    position    : Utils.getStyleValue(viewStyles, "lastUpdated", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "lastUpdated", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastUpdated",
                    displayName : "Last Updated",
                    sortable    : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "lastUpdated", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "lastUpdated", "position", 7),
                    visibility  : Utils.getStyleValue(viewStyles, "lastUpdated", "visibility", true),
                    disabled    : false
                }
            ],
            whereUsedModal: {
                open : false,
                object : ''
            }
        }

        this.getTableRows                   = this.getTableRows.bind(this);
        this.createLink                     = this.createLink.bind(this);
        this.openWhereUsedModal             = this.openWhereUsedModal.bind(this)
        this.closeWhereUsedModal            = this.closeWhereUsedModal.bind(this)
        this.setModalPosition               = this.setModalPosition.bind(this)

    }

    openWhereUsedModal(event, item)
    {
        let element = event.target
        let parentTableEl = document.getElementsByClassName("co-list extended-table")[0]

        var elementPos = element.getBoundingClientRect();
        var parentPos = parentTableEl.getBoundingClientRect();

        let top  = elementPos.top - parentPos.top
        let left = elementPos.left - parentPos.left
        left = left + 30

        let state = this.state
        let whereUsedModal  = state.whereUsedModal
        whereUsedModal.open = true
        whereUsedModal.object = item
        whereUsedModal.top = top
        whereUsedModal.left = left
        this.setState(state)
    }

    setModalPosition()
    {
        let state = this.state
        let whereUsedModal  = state.whereUsedModal
        let whereUsedModalElement = document.getElementsByClassName("where-used-modal")[0]
        if (whereUsedModalElement)
        {
            let offsetHeight = whereUsedModalElement.offsetHeight
            whereUsedModalElement.style.top = ( whereUsedModal.top - ( offsetHeight/2 ) ) + 'px'
            whereUsedModalElement.style.left = ( whereUsedModal.left ) + 'px'
        }
    }

    closeWhereUsedModal()
    {
        let whereUsedModal  = this.state.whereUsedModal
        whereUsedModal.open = false
        this.setState(this.state)
    }

    createLink(item, co)
    {
        let to = null
        if (co.status === "CLOSED" && co.resolution !== "FINALIZING")
        {
            to  = "/" + (item.alias === "cmp" ? "component" : "product") + "/revision/" + item._id

            if (co.resolution === "APPROVED")
            {
                if (item.alias === "prd")
                {
                    for (let prd of co.childRevision.productRevision)
                    {
                        if (prd.revisions[prd.revisions.length - 1]._id === item._id)
                        {
                            to  = "/product/view/" + prd._id
                            break;
                        }
                    }
                }

                if (item.alias === "cmp")
                {
                    for (let cmp of co.childRevision.componentRevision)
                    {
                        if (cmp.revisions[cmp.revisions.length - 1]._id === item._id)
                        {
                            to  = "/component/view/" + cmp._id
                            break;
                        }
                    }
                }
            }
            else
            {
                if (item.alias === "prd")
                {
                    for (let prd of co.children.products)
                    {
                        if (prd.cpn === item.cpn)
                        {
                            to  = "/product/view/" + prd._id
                            break;
                        }
                    }
                }

                if (item.alias === "cmp")
                {
                    for (let cmp of co.children.components)
                    {

                        if (cmp.cpn === item.cpn)
                        {
                            to  = "/component/view/" + cmp._id
                            break;
                        }
                    }
                }
            }

        }
        else
        {
            to  = "/" + (item.alias === "cmp" ? "component" : "product") + "/view/" + item._id
        }
        return to
    }

    getNextCoRevision(item, unChangedChildren)
    {
        let unChangedChildrenList = [];
        if(item.alias === "cmp" && unChangedChildren.hasOwnProperty("components")) unChangedChildrenList = unChangedChildren.components;
        if(item.alias === "prd" && unChangedChildren.hasOwnProperty("products")) unChangedChildrenList = unChangedChildren.products;
        if(unChangedChildrenList.includes(item._id)) return item.revision;
        return item.nextCoRevision;
    }

    getTableRows()
    {
        let co = this.props.co
        let children = []
        let list = []
        let isDcoType = co.type && co.type.toUpperCase() === 'DCO';

        if (co.status === "CLOSED" && co.resolution !== "FINALIZING")
        {
            children = this.props.co.childRevision
            list     = children.productRevision
            list     = list.concat(children.componentRevision)
        }
        else
        {
            children = this.props.co.children
            list     = children.products
            list     = list.concat(children.components)
        }



        let state = this.state
        let rows = list.map((item, i) =>
            {
                let nextCoRevision = co.unChangedChildren ? this.getNextCoRevision(item, co.unChangedChildren) : item.nextCoRevision;
                let lastModified         = item.lastModified;
                let lastModifiedToolTip  = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;
                let to  = this.createLink(item, co)
                // let revision = item.revisions.slice(-1)[0]

                let itemLastRevision = ""
                let itemRevision     = ""
                let itemLastStatus   = ""
                let itemStatus       = ""
                if (co.status === "CLOSED" && co.resolution !== "FINALIZING" && co.resolution === "APPROVED")
                {
                    itemLastRevision = item.previousRevision || item.revision;
                    itemRevision     = item.revision
                    itemLastStatus   = item.status || item.lastStatus
                    itemStatus       = item.status
                }
                else if(isDcoType)
                {
                    itemLastRevision = itemRevision = item.previousRevision;
                    itemLastStatus   = itemStatus   = item.previousStatus;
                }
                else if(co.status === "CLOSED" && co.resolution !== "FINALIZING" && co.resolution === "REJECTED")
                {
                    itemLastRevision = item.revision;
                    itemRevision     = item.nextCoRevision;
                    itemLastStatus   = item.status || item.lastStatus;
                    itemStatus       = item.status;
                }
                else
                {
                    itemLastRevision = item.revision//previousRevision
                    itemRevision     = item.status === "DESIGN" ? item.revision : nextCoRevision;
                    itemLastStatus   = item.status//previousStatus
                    itemStatus       = item.status
                }

                let cpn = item.cpn;
                cpn = Utils.getCpn(item);

                let cells =
                {
                    "cpn":
                    {
                        value        : cpn,
                        displayValue :
                            <div className="cpn-holder">
                                <Link
                                    to={to}
                                    target=""
                                    className="link">
                                    <CpnField item={item} cpn={cpn}/>
                                </Link>
                                <div className="where-used-holder">
                                {   item.alias === "cmp" &&
                                    <InlineIcon
                                        onClick={(e) => this.openWhereUsedModal(e, item)}
                                        className={state.whereUsedModal.open ? "active" : ""}
                                        tooltip="Where used"
                                        name="where-used"
                                        >
                                        <WhereUsedSrc/>
                                    </InlineIcon>
                                }
                                </div>

                                {
                                    this.props.mode === "view" && item.revisions.length > 0 &&
                                    <div className={`compare-rev-icon-holder`}>
                                        <InlineIcon
                                        onClick={(e) => this.props.showDiffToolRightSideBar(e, item)}
                                        tooltip="Compare Revisions"
                                        name="compare-revisions">
                                            <CompareRevIcon/>
                                        </InlineIcon>
                                    </div>
                                }
                            </div>,
                        notLink     : true,
                        tooltip     : cpn
                    },
                    "name":
                    {
                        value        : item.name,
                        displayValue : item.name,
                        tooltip      : item.name,
                    },
                    "lastRelease":
                    {
                        value       : item.previousStatus,
                        tooltip     : item.previousStatus,
                        cellClass   : 'last-release-cell',
                        displayValue:
                            <div className="last-release-holder status-holder">
                                <StatusField label={item.previousStatus} />
                                <span className="revision-label">
                                    REV {item.previousRevision}
                                </span>
                            </div>,
                        notLink     : true
                    },
                    "description":
                    {
                        value        : item.description,
                        displayValue : item.description,
                        tooltip      : item.description,
                    },
                    "revision":
                    {
                        value       : nextCoRevision,
                        cellClass   : 'revision-cell',
                        tooltip     : nextCoRevision,
                        displayValue :
                            <div className="revision-holder">
                                <span className={`revision-label ${this.revisionScheme}`}>
                                   <RevisionField item={item} />
                                </span>
                            </div>
                    },
                    "status":
                    {
                        value       : itemStatus,
                        tooltip     : itemStatus,
                        displayValue :
                            <span className="status-holder">
                                <StatusField item={item} />
                            </span>

                    },
                    "eid":
                    {
                        value        : item.eid,
                        displayValue : item.eid,
                        tooltip      : item.eid,
                    },
                    "lastUpdated" :
                    {
                        value        : lastModified ? lastModified : '',
                        displayValue : lastModified && <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} />,
                        tooltip      : lastModified && lastModifiedToolTip ? `${lastModifiedToolTip.dateValue} ${lastModifiedToolTip.timeValue}`: '',
                        notLink      : true
                    },
                    "images" : {
                    value       : 0,
                    displayValue: <InlineImageViewer key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} imagesWithSrc={this.props.imagesWithSrc} images={item.images}/>,
                    notLink     : true,
                    cellClass   : "inline-image-viewer"
                    },
                    rowLink         : to
                }
                return cells
            })
        return rows

    }
    render()
    {
        let headings  = this.state.headings
        let rows      = this.getTableRows()
        let co        = this.props.co

        let markup  =
            <div>
                {
                    this.state.whereUsedModal.open &&
                    <WhereUsedModal
                        component={this.state.whereUsedModal.object}
                        openWhereUsedModal={this.state.whereUsedModal.open}
                        getDataFromApi={true}
                        objectIds={[this.state.whereUsedModal.object._id]}
                        closeWhereUsedModal={this.closeWhereUsedModal}
                        setModalPosition={this.setModalPosition}
                        history={this.props.history}
                        mode={ this.state.whereUsedModal.object.parent ? 'revisionView' : 'componentView' }
                    />
                }

                <ExtendedTable
                    wrapperClassName="co-list"
                    wrapperSelectorClass="co-list"
                    stylesName="changeOrderAssemblyTableView"
                    themeDataTableId={"coListTable"}
                    headings={headings}
                    allowRowSelect={false}
                    includeToolBar={true}
                    rows={rows || []}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    scrollPagination={true}
                    paginationSize={25}
                    resultText={`${rows.length} results`}
                    footerRow={ co.status === 'DRAFT' && Permissions.can("edit", "change_order", this.props.user.data) ?  {
                                bordered: true,
                                dataCellEl: <p>Edit to add</p>,
                                indexCellEl:<div className="add-more-actions">
                                                <Link to={"/changeorder/edit/" + co._id}>
                                                    <button className='add-trigger'
                                                            data-tip='Edit'
                                                        >
                                                        <InlineIcon><EditIcon/></InlineIcon>
                                                    </button>
                                                </Link>
                                             </div>
                            }
                            :
                            rows.length === 0 &&
                            {
                                dataCellEl: <p>No Products and Components</p>
                            }
                        }
                />
            </div>
        return markup
    }
}

export default Children
