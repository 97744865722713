import React, { Component }         from "react"
import Utils                        from "../../../../modules/utils"
import { connect }                  from "react-redux"
import Link from "../../../ui/link";
import { ButtonWrapper, CustomButton } from "design/pages/component/componentHeader";
import { ButtonVariants } from "common/constants";

class Actions extends Component {
    constructor(props) {
        super(props)
        this.update                     = this.update.bind(this);
        this.getButtonsState            = this.getButtonsState.bind(this);
        this.getActionButtons           = this.getActionButtons.bind(this);
        this.showSaveAsRevisionModal    = this.showSaveAsRevisionModal.bind(this);
        const {proceed, proceedSaveAsRevision} = this.getButtonsState(this.props);

        this.state = {
            proceed:  proceed,
            proceedSaveAsRevision: proceedSaveAsRevision,
        };
    }

    getButtonsState(props)
    {
        const {
            inputs,
            modified,
            children,
            childrenModified,
            assemblyErrors,
            isAssemblyEditing,
        } = props;
        if(children)
        {
            props.calculateAssemblyErrors(children);
        }
        let validated            = Utils.isValidated(inputs);
        let childrenValidated    = Utils.isValidated(props.children);
        return {
            proceed: (modified || childrenModified) && validated && childrenValidated && assemblyErrors === 0 && !isAssemblyEditing, 
            proceedSaveAsRevision: validated && childrenValidated && assemblyErrors === 0 && !isAssemblyEditing,
        };
    }

    componentWillReceiveProps(nextProps)
    {
        this.update(nextProps);
    }

    update(props)
    {
        const {proceed, proceedSaveAsRevision} = this.getButtonsState(props);
        this.state.proceed = proceed;
        this.state.proceedSaveAsRevision = proceedSaveAsRevision;
        this.setState(this.state);
    }

    showSaveAsRevisionModal()
    {
        this.props.showHideSaveAsRevisionModal(true);
    }

    handleCancelEvent = (() => {
        const { isNewObject, history, productLink } = this.props;
        if (isNewObject) {
            history.goBack();
        }
        else {
            history.push({ pathname: productLink });
        }
    });

    getActionButtons()
    {
        const { inputs, isNewObject } = this.props;
        let markup =
            <ButtonWrapper>
                <CustomButton
                    color="secondary"
                    onClick={this.handleCancelEvent}
                    variant={ButtonVariants.OUTLINED}
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    color="secondary"
                    onClick={this.props.onSubmit}
                    disabled={!this.state.proceed}
                    type="submit"
                    variant={ButtonVariants.CONTAINED}
                >
                    Save
                </CustomButton>
                    {
                        inputs.status.value === "DESIGN" && !isNewObject ?
                        <CustomButton
                            color="secondary"
                            disabled={!this.state.proceedSaveAsRevision}
                            onClick={this.showSaveAsRevisionModal}
                            variant={ButtonVariants.CONTAINED}
                        >
                            Save as Revision
                        </CustomButton>
                        : null

                    }
            </ButtonWrapper>
        return markup;
    }
    render() {
        return this.getActionButtons()
    }
}

export default connect((store) => ({
    children: store.assembly_edit.children,
    childrenModified: store.assembly_edit.modified,
    inputs: store.product.editPage.inputs,
    modified: store.product.editPage.modified,
}))(Actions)
