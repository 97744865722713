import { useCallback, useMemo } from "react";
import {
  ApolloClient,
  ApolloError,
  FetchPolicy,
  gql,
  NormalizedCacheObject,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import { Product } from "design/models";
import {
  ProductsFragment,
  ProductsLastReleasedRevisionFragment,
} from "graphql/fragment/productsFragment";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import { parseSearchString } from "design/utils/search";
import { useUser } from "./userQueries";
import { Role } from "common/constants";
import { ProductsLotFragment } from "graphql/fragment/productFragment";
import { Lot } from "build/models";
import { AssemblyChild, SuperComponent } from "design/models/changeorder";
import { uniqueId } from "lodash";
import { CoBaseRowFragment } from "graphql/fragment/changeOrderFragment";

export const GET_PRODUCTS = gql`
  query (
    $libraryType: LibraryType
    $orderBy: [ProductsOrderByInput]
    $pageSize: Int
    $endCursor: String
    $search: SearchFields
  ) {
    products(libraryType: $libraryType, orderBy: $orderBy, search: $search) {
      ...productsFragment
    }
  }
  ${ProductsFragment}
`;

export const GET_PRODUCTS_LAST_RELEASE_REVISION = gql`
  query (
    $libraryType: LibraryType
    $orderBy: [ProductsOrderByInput]
    $pageSize: Int
    $endCursor: String
    $search: SearchFields
  ) {
    products(libraryType: $libraryType, orderBy: $orderBy, search: $search) {
      ...productsLastReleasedRevisionFragment
    }
  }
  ${ProductsLastReleasedRevisionFragment}
`;

export const GET_PRODUCT_WITH_LOT = gql`
  query productsWithLots($ids: [ID!]!) {
    productsByIds(ids: $ids) {
      ...productsLotFragment
    }
  }
  ${ProductsLotFragment}
`;

export type OrderByType = { [field: string]: GridSortDirection }[] | undefined;

interface IProductListArgs {
  endCursor?: string;
  fetchPolicy?: FetchPolicy;
  libraryType?: string;
  orderBy?: [];
  pageSize?: number;
}

interface ProductsQueryData {
  products?: {
    connection: {
      edges: { node: Product }[];
      pageInfo: {
        hasNextPage: boolean;
        endCursor?: string;
      };
      totalCount: number;
    };
  };
}


export interface IProductList {
  products: Product[];
  endCursor?: string;
  error?: ApolloError;
  fetchProducts?: (
    endCursor: string | undefined,
    orderBy: any,
    searchString?: string
  ) => void;
  hasNextPage?: boolean;
  loading?: boolean;
  totalCount: number;
}

interface PrdsWithLots {
  build: {
    lots: Lot[];
  };
}

interface PrdsWithLotsQueryData {
  productsByIds?: PrdsWithLots[];
}

export const useProductQuery = () => {
  const { role } = useUser().data ?? {};

  return {
    role,
    query:
      role === Role.VENDOR ? GET_PRODUCTS_LAST_RELEASE_REVISION : GET_PRODUCTS,
  };
};

/**
 * Loads up all the products.
 *
 * @param LibraryType Library of which products to be load.
 * @param orderBy order in which products to be sorted.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The products when loaded, errors, and the loading state.
 */
export function useProductList(args: IProductListArgs): IProductList {
  const { fetchPolicy = "network-only", libraryType, pageSize = 30 } = args;

  const { role, query } = useProductQuery();

  const [loadProducts, { data, error, loading }] =
    useLazyQuery<ProductsQueryData>(query, { fetchPolicy });
  const { edges, totalCount = 0, pageInfo } = data?.products?.connection ?? {};
  const fetchProducts = useCallback(
    (endCursor, orderBy, searchString) => {
      loadProducts({
        variables: {
          endCursor,
          libraryType,
          orderBy,
          pageSize,
          search: parseSearchString(searchString),
        },
      });
    },
    [libraryType, pageSize, loadProducts]
  );

  const products: Product[] = useMemo(() => {
    if (!edges) return [];
    return edges
      .map(({ node }) =>
        role === Role.VENDOR ? node.lastReleaseRevision : node
      )
      .filter((n) => !!n) as Product[];
  }, [edges, role]);

  return {
    products,
    endCursor: pageInfo?.endCursor,
    error,
    fetchProducts,
    hasNextPage: pageInfo?.hasNextPage,
    loading,
    totalCount,
  };
}

/**
 * Marks products based on the ID's as dirty in the cache and causing them to be re-fetched.
 *
 * @param client The apollo client used to reload the products.
 * @param ids Ids of the products to reload.
 * @returns Promise that will resolve when the refetching is done.
 */
export async function refetchProducts(
  client: ApolloClient<NormalizedCacheObject>,
  ids: string[]
) {
  await client.refetchQueries({
    updateCache(cache) {
      ids.forEach((id) => cache.evict({ id: `Product:${id}` }));
    },
  });
}

export function useProductsWithLots(fetchPolicy: FetchPolicy = "cache-first") {
  const [getProductsWithLots, { data, error, loading }] =
    useLazyQuery<PrdsWithLotsQueryData>(GET_PRODUCT_WITH_LOT, { fetchPolicy });

  const fetchPrdWithLots = useCallback(
    (ids: string[]) => {
      ids?.length &&
        getProductsWithLots({
          variables: { ids },
        });
    },
    [getProductsWithLots]
  );

  const updatePrdLotsCache = useCallback(
    (
      client: ApolloClient<NormalizedCacheObject>,
      newLot: Lot,
      ids: string[]
    ) => {
      const prevData = client.readQuery({
        query: GET_PRODUCT_WITH_LOT,
        variables: { ids },
      });
      if (prevData?.productsByIds) {
        const newData = {
          ...prevData,
          productsByIds: prevData.productsByIds.map((prd: any) => ({
            ...prd,
            build: {
              ...prd.build,
              lots: [...prd.build.lots, newLot],
            },
          })),
        };
        client.writeQuery({
          query: GET_PRODUCT_WITH_LOT,
          variables: { ids },
          data: newData,
        });
      }
    },
    []
  );

  const prdLotsData = useMemo(
    () => data?.productsByIds?.[0],
    [data?.productsByIds]
  );
  return {
    fetchPrdWithLots,
    prdLotsData,
    prdLotError: error,
    prdLotLoading: loading,
    updatePrdLotsCache,
  };
}


type ProductsWithChildrenQueryResult = {
  data: SuperComponent[];
  error: ApolloError | undefined;
  loading: boolean;
};

const GET_PRODUCTS_BY_IDS = gql`
  query ProductsByIds($ids: [ID]) {
    productsByIds(ids: $ids) {
      ${CoBaseRowFragment}
    }
  }
`;

type ProductsWithChildrenQueryData = {
  productsByIds: SuperComponent[];
}

function appendPathToBaseProduct(product: SuperComponent): SuperComponent {
  return {
    ...product,
    path: [product.id],
    treeId: uniqueId(),
  };
}


export const useProductPathsWithChildren = (
  ids: string[]
): ProductsWithChildrenQueryResult => {
  const { data, error, loading } = useQuery<ProductsWithChildrenQueryData>(
    GET_PRODUCTS_BY_IDS,
    {
      variables: { ids },
      fetchPolicy: "no-cache",
    }
  );

  return {
    data: data?.productsByIds?.map(appendPathToBaseProduct) ?? [],
    error,
    loading,
  };
};
