import { FilterList } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { SuperComponent } from "design/models/changeorder";
import { ValidationErrorGroup } from "features/changeorders/sdk";
import { sdk } from "features/changeorders/sdk/editor";
import { useEffect, useMemo, useState } from "react";
import { filterAndDeduplicatePaths } from "./tree.paths";
import { colorPalette } from "components/themes";
import { CheckboxFilledIcon, CheckboxUnfilledIcon } from "assets/icons";

interface FiltersDropdownProps {
  filterOptions: { label: string; value: string }[];
  onFilterChange: (value: string[]) => void;
  appliedFilters: string[];
}

export const FiltersDropdown: React.FC<FiltersDropdownProps> = ({
  filterOptions,
  onFilterChange,
  appliedFilters,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setSelectedValues(appliedFilters);
  }, [appliedFilters]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedValues(appliedFilters);
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newSelectedFilters = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];
    setSelectedValues(newSelectedFilters);
    onFilterChange(newSelectedFilters);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        sx={{
          mr: "0rem",
          cursor: "pointer",
          paddingRight: "0.4rem",
          color:
            selectedValues.length > 0 || open
              ? colorPalette.green
              : colorPalette.white,
          "&:hover": {
            color: colorPalette.green,
          },
          "& svg": {
            "& path": {
              fill:
                selectedValues.length > 0 || open
                  ? colorPalette.green
                  : colorPalette.white,
            },
          },

          "&:hover svg path": {
            fill: colorPalette.green,
          },
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={handleClick}
      >
        <IconButton>
          <FilterList />
        </IconButton>
        Filter
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ padding: "0.5rem 1rem" }}
      >
        {filterOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox
              checked={selectedValues.includes(option.value)}
              onChange={handleCheckboxChange}
              value={option.value}
              checkedIcon={<CheckboxFilledIcon />}
              icon={<CheckboxUnfilledIcon />}
              sx={{ mr: "0.5rem" }}
            />

            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const PRIMARY_TABLE_FILTER_OPTS = [
  {
    label: "Needs Validation",
    value: ValidationErrorGroup.RequiresValidation,
  },
  { label: "Valid", value: ValidationErrorGroup.IsValid },
  { label: "Errors", value: ValidationErrorGroup.Errors },
  { label: "Warnings", value: ValidationErrorGroup.Warning },
];

type UsePrimaryTableFilters = (options: { data: SuperComponent[] }) => {
  FiltersDropdown: JSX.Element;
  data: SuperComponent[];
  filtersCount: number;
  setSelectedFilters: (filters: string[]) => void;
};

const filterRowsByValidationGroup = (
  data: SuperComponent[],
  validationGroup: string[]
) => {
  const validIds: string[] = [];

  data.forEach((item) => {
    const { errors, reviewed } = sdk.state.getChangeset(item);

    const validation = sdk.validation.getRowValidation(
      item,
      errors.getState(),
      reviewed.getState()
    );

    if (validationGroup.includes(validation.errorGroup)) {
      validIds.push(item.cpn.displayValue);
    }
  });

  return filterAndDeduplicatePaths(data, validIds);
};

export const usePrimaryTableFilters: UsePrimaryTableFilters = ({ data }) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const filtered = useMemo(() => {
    if (selectedFilters.length === 0) return data;
    sdk.state.flags.tableIsLoading.setState(true);
    return filterRowsByValidationGroup(data, selectedFilters);
  }, [selectedFilters, data]);

  useEffect(() => {
    sdk.state.flags.tableIsLoading.setState(false);
  }, [filtered]);

  return {
    FiltersDropdown: (
      <FiltersDropdown
        filterOptions={PRIMARY_TABLE_FILTER_OPTS}
        onFilterChange={(selected) => setSelectedFilters(selected)}
        appliedFilters={selectedFilters}
      />
    ),
    data: filtered,
    filtersCount: selectedFilters.length,
    setSelectedFilters: (filters) => setSelectedFilters(filters),
  };
};
