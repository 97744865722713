import React, { ReactElement } from "react";

import {
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  styled,
  useTheme,
} from "@mui/material";
import { colorPalette } from "components/themes";

export enum TooltipVariants {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export type DuroTooltipProps = {
  variant: TooltipVariants;
} & TooltipProps;

export const getTooltipStyles = (theme: Theme) => ({
  [TooltipVariants.ERROR]: {
    [`& .${tooltipClasses.arrow}`]: {
      color: colorPalette.errorRed,
    },
    [`.${tooltipClasses.tooltip}`]: {
      backgroundColor: colorPalette.errorRed,
      color: colorPalette.black,
    },
  },
  [TooltipVariants.WARNING]: {
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.warning.main,
    },
    [`.${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.warning.main,
    },
  },
  [TooltipVariants.INFO]: {
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.info.main,
    },
    [`.${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.info.main,
    },
  },
});

export interface StyledTooltipProps extends TooltipProps {
  variant: TooltipVariants;
}

const StyledTooltip = styled(({ className, variant, ...props }: StyledTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, variant }: { theme: Theme; variant: TooltipVariants }) => ({
  ...getTooltipStyles(theme)[variant],
}));

export const DuroTooltip: React.FC<DuroTooltipProps> = ({
  children,
  variant,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledTooltip {...props} theme={theme} variant={variant}>
      <div>{children}</div>
    </StyledTooltip>
  );
};

interface DuroErrorTooltipProps {
  error: {
    valid: boolean;
    message: string;
  };
  tooltipProps?: Partial<TooltipProps>;
  children: ReactElement;
}

export const DuroErrorTooltip: React.FC<DuroErrorTooltipProps> = ({
  error,
  tooltipProps = {},
  children,
}) => {
  if (!error.valid) {
    return (
      <DuroTooltip
        {...tooltipProps}
        variant={TooltipVariants.ERROR}
        title={error.message}
      >
        {children}
      </DuroTooltip>
    );
  }
  return <>{children}</>;
};
