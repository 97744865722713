import { Box } from "@mui/material";
import { DataroomItem } from "./DataroomItem";
import { TEST_DATAROOMS } from "../constants";

export const DataroomsList = () => {
    return (
      <Box 
        display="flex" 
        flexWrap="wrap" 
        gap={2}
      >
        {TEST_DATAROOMS.map((room) => (
          <Box key={room.id} flex="1 1 300px" maxWidth="300px">
            <DataroomItem room={room} />
          </Box>
        ))}
      </Box>
    );
};
