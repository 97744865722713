import { gql } from "@apollo/client";

export const CoBaseRowFragment = `
  id
  cpn {
    displayValue
    id
  }
  description
  name
  legacyNextRevision
  previousRevisionValue
  previousStatus
  revisionValue
  status
  modified
  lastModified
  eid
  alias
  primarySource {
    minQuantity
  }
`;

export const CoBaseComponentRow = `
  ${CoBaseRowFragment}
  revisionManaged
  vendorInfo {
    currentVendors
    originalVendor
    previousVendors
  }
`;

export const CoProductRowFragment = {
  fragment: gql`
  fragment productRowFragment on Product {
     ${CoBaseRowFragment}
  }`,
  spread: `...productRowFragment`,
};

export const CoProductRowChildrenFragment = {
  fragment: gql`
  fragment productRowChildrenFragment on Product {
     children {
      component {
        ${CoBaseRowFragment}
      }
    }
  }`,
  spread: `...productRowChildrenFragment`,
};

export const CoComponentRowFragment = {
  fragment: gql`
  fragment componentRowFragment on Component {
     ${CoBaseComponentRow}
  }`,
  spread: `...componentRowFragment`,
};

export const CoComponentRowChildrenFragment = {
  fragment: gql`
  fragment componentRowChildrenFragment on Component {
     children {
      component {
        ${CoBaseRowFragment}
      } 
    }
  }`,
  spread: `...componentRowChildrenFragment`,
};

export const CoBasicUserFragment = gql`
  fragment userInfoFragment on User {
    id
    firstName
    lastName
    email
    groups
  }
`;

export const CoBasicFragment = gql`
fragment coBasicFragment on ChangeOrder {
  id
  name
  description
  type
  approvalType
  approvers {
    action
    invitedAt
    performedAt
    user {
      ...userInfoFragment
    }
  }
  alias
  archived
  created
  creator {
    ...userInfoFragment
  }
  isParentInclude
  lastModified
  library {
    id
  }
  resolution
  status
  company {
    id
  }
  coInternalNotifyUsers {
    ...userInfoFragment
  }
  coExternalNotifyUserEmails 
  history {
    user {
      id
    }
    action
    comment
    created
  }
  documentLinks {
    specs {
      lastModified
      revision
      status
      type
    }
    document {
      id
      name
      size
      mime
    }
  }
  erpOptions {
    additionalPayloadForNotifications {
      childComponent
      parentAssemblies
    }
    effectivity {
      endDate
      startDate
      overrideExisting
      isEnabled
    }
    itemType {
      isEnabled
      overrideExisting
      value
    }
  }
  con {
    displayValue
    prefix
    suffix
    id
  }

  products {
    ${CoProductRowFragment.spread}
  }
    
  components {
    ${CoComponentRowFragment.spread}
  }
}
${CoProductRowFragment.fragment}
${CoComponentRowFragment.fragment}
${CoBasicUserFragment}
`;
