import { SuperComponent } from "design/models/changeorder";
import { createRow, createTreeNode } from "./preprocessors";
import { EditorState } from "./state";

export function addModifications(rows: SuperComponent[]) {
  rows.forEach((row) => {
    EditorState.getChangeset(row);
  });
}

export async function mountRemoteRows(
  inputRows: SuperComponent[],
  inputTree: SuperComponent[]
) {
  const rows = inputRows.map(createRow);
  const tree = inputTree.map(createTreeNode);
  await EditorState.tree.setState(() => tree);
  await EditorState.rows.setState(() => rows);
}
