import { useMemo } from "react";
import { CellParams } from "../cell.types";
import { styled } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { StatusField } from "@duro/base/status-field";
import { colorPalette } from "@duro/themes";
import { DuroInlineLayout } from "@duro/layout";

const StyledRevisionField = styled(Box)({
  display: "flex",
  gap: "0.5rem",
});

const StyledStatusField = styled(StatusField)({
  marginRight: "1rem",
  minWidth: 110,
});

const StyledRev = styled(Typography)({
  fontSize: 13,
  color: colorPalette.silver,
});

const StyledRevValue = styled(Typography)({
  fontSize: 13,
  color: colorPalette.white,
  marginLeft: "0.25rem",
});

const PreviousStatusRenderCell = ({ row }: CellParams) => {
  return useMemo(() => {
    return (
      <StyledRevisionField>
        <StyledStatusField label={row.previousStatus ?? row.status} />
        <DuroInlineLayout>
          <StyledRev>REV</StyledRev>
          <StyledRevValue>
            {row.previousRevisionValue === "" ? "—" : row.previousRevisionValue}
          </StyledRevValue>
        </DuroInlineLayout>
      </StyledRevisionField>
    );
  }, [row]);
};

export const PreviousStatusCell = {
  renderCell: PreviousStatusRenderCell,
  headerName: "Last Release",
  field: "previousStatus",
  minWidth: 200,
};
