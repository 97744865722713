import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { sdk } from "features/changeorders";

export const NavButtonIncludeChildren = {
  id: 1,
  isDisabled: (ids: string[]) => ids.length === 0,
  Icon: () => <AddCircleOutlineOutlined width={12} height={12} />,
  label: "Add Children",
  onClick: (ids: string[]) => sdk.editor.children.handleDisplayModal(true),
};
