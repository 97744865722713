import "./index.scss"
import React        from "react"
import View         from "../../../../../ui/view"
import Utils        from "../../../../../../modules/utils"
import DescriptionBlock from "../../../../common/description-block"

class Details extends View
{
    constructor(props)
    {
        super(props)
        this.state = {
            descriptionLength: 420,
            showFullDesc: false,
            fiveLines: false // More than five lines
        }

        this.toggleDesc = this.toggleDesc.bind(this)
    }
    
    toggleDesc(){
        this.setState(prevState => ({
            showFullDesc: !prevState.showFullDesc
        }))
    }

    componentDidMount() {
        setTimeout(() => {
            if (document.querySelector(".w-extended")?.clientHeight > 90) {
                this.setState({
                    fiveLines: true,
                    showFullDesc: true
                })
            }
        }, 0)
    }

    render()
    {
        let co = this.props.co
        let longDesc = co.description.length > this.state.descriptionLength
        let showFullDesc = this.state.showFullDesc
        let actionAndActionStatus = this.props.actionAndActionStatus
        let markup =

            <div className="details-block">
                <div className="co-heading">
                    <div className="name-holder">
                        <h1>{co.name}</h1>
                    </div>
                    {
                        actionAndActionStatus.isActionPerformed && actionAndActionStatus.isInApproverList &&
                        <div className="decision-badge">
                            <span className="muted-text">YOUR DECISION:</span>
                            <span className={"resolution-label " + actionAndActionStatus.decision}>{actionAndActionStatus.decision}</span>
                        </div>
                    }
                </div>
                

                <div className="co-properties">
                    <div className="items-block">
                        <table>
                            <tbody>
                                <tr>
                                    <th>TYPE:</th>
                                    <td>{co.type}</td>

                                </tr>
                                <tr>
                                    <th>CON:</th>
                                    <td>{co.con}</td>
                                </tr>
                                <tr>
                                    <th>APPROVAL TYPE:</th>
                                    <td>{co.approvalType}</td>
                                </tr>
                            </tbody>

                        </table>

                        <DescriptionBlock
                            position={`bottom w-extended ${showFullDesc && 'less'} side`}
                            heading='DESCRIPTION'
                            showFullDesc={!showFullDesc}
                            longDesc={longDesc || this.state.fiveLines}
                            toggleDesc={this.toggleDesc}
                            description={co.description}
                        />
                    </div>

                </div>

            </div>

        return markup
    }
}

export default Details
