import { createStore, Store } from "common/context/store";
import {
  ChangeOrder,
  ChangeOrderERPItemType,
  ChangeOrderPLMApi,
  ChangeOrderType,
  ChangeOrderUser,
  DocumentLink,
  EcoDefaultApproval,
  ERPOptionsAdditionalPayloadForNotifications,
  ERPOptionsItemType,
  PlmAPIEffectivity,
  SuperComponent,
} from "design/models/changeorder";
import { ClientModulesSchemaValidationResult } from "./utils";
import moment from "moment";

export type ChangeSetStorageStateUpdate = (
  prevState: ChangesetStorage
) => ChangesetStorage;

export type ChangesetStorage = {
  errors: ClientModulesSchemaValidationResult[];
  requiresValidation: boolean;
  status: string;
  legacyNextRevision: string;
  originalStatus: string;
  originalRevision: string;
};

export type ChangesetGetter = (item: SuperComponent) => Store<ChangesetStorage>;

export interface ChangeOrderEditorStorage {
  remoteCo: Store<ChangeOrder> | undefined;
  coId: Store<string>;
  treeData: Store<Record<string, SuperComponent>>;
  form: {
    resolution: Store<string>;
    con: Store<ChangeOrder["con"]>;
    creator: Store<ChangeOrderUser>;
    name: Store<string | null>;
    description: Store<string>;
    type: Store<ChangeOrderType>;
    approvalType: Store<EcoDefaultApproval>;
    approverList: Store<ChangeOrderUser[]>;
    lastApproverList: Store<ChangeOrderUser[]>;
    lastApprovalType: Store<EcoDefaultApproval>;
    erpOptions: {
      effectivity: Store<PlmAPIEffectivity>;
      itemType: Store<ERPOptionsItemType>;
      additionalPayloadForNotifications: Store<ERPOptionsAdditionalPayloadForNotifications>;
    };
  };
  coInternalNotifyUsers: Store<ChangeOrderUser[]>;
  coExternalNotifyUserEmails: Store<string[]>;
  lastNotifiers: Store<ChangeOrderUser[]>;
  lastExternal: Store<string[]>;
  products: Store<SuperComponent[]>;
  components: Store<SuperComponent[]>;
  rows: Store<SuperComponent[]>;
  changeset: Record<string, Store<ChangesetStorage>>;
  errors: Record<string, Store<ClientModulesSchemaValidationResult[]>>;
  cachedValidations: Record<string, boolean>;
  isValid: Store<boolean>;
  isPendingValidation: Store<boolean>;
  isNameFieldError: Store<boolean>;
  pendingInternalNotifyUsers: Store<ChangeOrderUser[]>;
  pendingProducts: Store<SuperComponent[]>;
  pendingComponents: Store<SuperComponent[]>;
  pendingApprovers: Store<ChangeOrderUser[]>;
  isFullscreenView: Store<boolean>;
  coLoaded: Store<boolean>;
  caches: {
    duplicateComponentIds: Record<string, string[]>;
    duplicateComponents: Record<string, SuperComponent[]>;
    requiresValidation: Record<string, boolean>;
    rows: Record<string, SuperComponent>;
    isValidationLoading: Store<boolean>;
  };
  documentLinks: Store<DocumentLink[]>;
  displayChildrenModal: Store<boolean>;
  rowIdValidated: Store<string>;
  displaySaveUpdateTemplatesModal: Store<boolean>;
  displayModifyModal: Store<boolean>;
  templates: Store<{
    approvalType: string;
    templateName: string;
    approvers: ChangeOrderUser[];
    isPublic: boolean;
  } | null>;
  displayDeleteTemplateModal: Store<boolean>;
  displayMandatoryApproversModal: Store<boolean>;
  currentMandatoryTemplate: Store<{
    open: boolean;
    type: string;
    name: string;
    action: "Removed" | "Added";
    status: string;
    id: string;
  }>;
  pendingTemplatesToDelete: Store<Set<string>>;
  confirmTemplateToDelete: Store<string>;
  isErpOptionsEffectivityDateError: Store<boolean>;
}

export type SearchStoreCount = {
  count: Store<number>;
  selected: Store<number>;
};

export const caches = {
  components: createStore<Record<string, string>>({}),
  products: createStore<Record<string, string>>({}),
};

export const resetCache = () => {
  storage.changeset = {};
  storage.caches = {
    duplicateComponentIds: {},
    duplicateComponents: {},
    requiresValidation: {},
    rows: {},
    isValidationLoading: createStore(false),
  };
};

export const defaultERPItemType = {
  isEnabled: false,
  overrideExisting: false,
  value: ChangeOrderERPItemType.NONE,
};

const createInitialState = (): ChangeOrderEditorStorage => ({
  caches: {
    duplicateComponentIds: {},
    duplicateComponents: {},
    requiresValidation: {},
    rows: {},
    isValidationLoading: createStore(false),
  },
  rowIdValidated: createStore<string>(""),
  coLoaded: createStore(false),
  remoteCo: undefined,
  coId: createStore<string>(""),
  treeData: createStore<Record<string, SuperComponent>>({}),
  pendingProducts: createStore<SuperComponent[]>([]),
  pendingComponents: createStore<SuperComponent[]>([]),
  pendingApprovers: createStore<ChangeOrderUser[]>([]),
  pendingInternalNotifyUsers: createStore<ChangeOrderUser[]>([]),
  form: {
    resolution: createStore<string>(""),
    con: createStore<ChangeOrder["con"]>({
      id: "",
      displayValue: "",
    }),
    creator: createStore<ChangeOrderUser>({
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      groups: [],
    }),
    name: createStore<string | null>(null, (val) => {
      storage.isNameFieldError.setState(() => val === "");
    }),
    description: createStore<string>(""),
    type: createStore<ChangeOrderType>(ChangeOrderType.ECO),
    approvalType: createStore<EcoDefaultApproval>(EcoDefaultApproval.FIRST_IN),
    lastApprovalType: createStore<EcoDefaultApproval>(
      EcoDefaultApproval.FIRST_IN
    ),
    approverList: createStore<ChangeOrderUser[]>([]),
    lastApproverList: createStore<ChangeOrderUser[]>([]),
    erpOptions: {
      effectivity: createStore<PlmAPIEffectivity>(
        {
          startDate: moment().add(1, "day").valueOf(), // tomorrow
          endDate: moment().add(2, "days").valueOf(), // day after tomorrow
          overrideExisting: false,
          isEnabled: false,
        },
        (newState) => {
          const hasStartDate =
            newState.startDate !== 0 && moment(newState.startDate).isValid();
          const hasEndDate =
            newState.endDate !== 0 && moment(newState.endDate).isValid();

          storage.isErpOptionsEffectivityDateError.setState(
            () =>
              newState.isEnabled &&
              (!hasStartDate ||
                !hasEndDate ||
                (hasStartDate &&
                  hasEndDate &&
                  newState.startDate > newState.endDate))
          );
          return newState;
        }
      ),

      itemType: createStore<ERPOptionsItemType>(defaultERPItemType),
      additionalPayloadForNotifications:
        createStore<ERPOptionsAdditionalPayloadForNotifications>({
          childComponent: false,
          parentAssemblies: false,
        }),
    },
  },
  coInternalNotifyUsers: createStore<ChangeOrderUser[]>([]),
  coExternalNotifyUserEmails: createStore<string[]>([]),
  lastNotifiers: createStore<ChangeOrderUser[]>([]),
  lastExternal: createStore<string[]>([]),
  rows: createStore<SuperComponent[]>([]),
  products: createStore<SuperComponent[]>([]),
  components: createStore<SuperComponent[]>([]),
  changeset: {},
  errors: {},
  cachedValidations: {},
  isFullscreenView: createStore<boolean>(false),
  isValid: createStore<boolean>(false),
  isPendingValidation: createStore<boolean>(true, (bool) => {
    if (bool) {
      storage.isValid.setState(false);
    }
  }),
  isNameFieldError: createStore<boolean>(false),
  documentLinks: createStore<DocumentLink[]>([]),
  displayMandatoryApproversModal: createStore<boolean>(false),
  currentMandatoryTemplate: createStore<{
    open: boolean;
    type: string;
    name: string;
    action: "Removed" | "Added";
    status: string;
    id: string;
  }>({
    open: false,
    type: "",
    name: "",
    action: "Removed",
    status: "",
    id: "",
  }),
  displayChildrenModal: createStore<boolean>(false),
  displaySaveUpdateTemplatesModal: createStore<boolean>(false),
  displayModifyModal: createStore<boolean>(false),
  templates:
    createStore<{
      templateName: string;
      approvers: ChangeOrderUser[];
      isPublic: boolean;
      approvalType: string;
    } | null>(null),
  displayDeleteTemplateModal: createStore<boolean>(false),
  pendingTemplatesToDelete: createStore<Set<string>>(new Set()),
  confirmTemplateToDelete: createStore<string>(""),
  isErpOptionsEffectivityDateError: createStore<boolean>(false),
});

export const storage = createInitialState();

export const resetState = () => {
  storage.coLoaded.resetState();
  storage.coId.resetState();
  storage.pendingProducts.resetState();
  storage.pendingComponents.resetState();
  storage.pendingApprovers.resetState();
  storage.pendingInternalNotifyUsers.resetState();
  storage.form.resolution.resetState();
  storage.form.creator.resetState();
  storage.form.name.resetState();
  storage.form.description.resetState();
  storage.form.type.resetState();
  storage.form.approvalType.resetState();
  storage.form.approverList.resetState();
  storage.form.lastApproverList.resetState();
  storage.displayMandatoryApproversModal.resetState();
  storage.currentMandatoryTemplate.resetState();
  storage.lastExternal.resetState();
  storage.lastNotifiers.resetState();
  storage.form.erpOptions.effectivity.resetState();
  storage.form.erpOptions.itemType.resetState();
  storage.rows.resetState();
  storage.products.resetState();
  storage.components.resetState();
  storage.isFullscreenView.resetState();
  storage.isValid.resetState();
  storage.isPendingValidation.resetState();
  storage.isNameFieldError.resetState();
  storage.displayChildrenModal.resetState();
  storage.displaySaveUpdateTemplatesModal.resetState();
  storage.displayModifyModal.resetState();
  storage.displayDeleteTemplateModal.resetState();
  storage.templates.resetState();
  storage.pendingTemplatesToDelete.resetState();
  storage.confirmTemplateToDelete.resetState();
  storage.isErpOptionsEffectivityDateError.resetState();
  storage.caches.duplicateComponentIds = {};
  storage.caches.duplicateComponents = {};
  storage.caches.requiresValidation = {};
  storage.caches.rows = {};
  storage.caches.isValidationLoading.resetState();
  storage.rowIdValidated.resetState();
  storage.coInternalNotifyUsers.resetState();
  storage.coExternalNotifyUserEmails.resetState();
  storage.changeset = {};
  storage.errors = {};
  storage.cachedValidations = {};
  storage.documentLinks.resetState();
  storage.displayMandatoryApproversModal.resetState();
};

export const addTreeIdMapping = (item: SuperComponent) => {
  if (!storage.caches.duplicateComponentIds[item.cpn.displayValue]) {
    storage.caches.duplicateComponents[item.cpn.displayValue] = [item];
    storage.caches.duplicateComponentIds[item.cpn.displayValue] = [item.treeId];
  } else if (
    !storage.caches.duplicateComponentIds[item.cpn.displayValue].includes(
      item.treeId
    )
  ) {
    storage.caches.duplicateComponents[item.cpn.displayValue].push(item);
    storage.caches.duplicateComponentIds[item.cpn.displayValue].push(
      item.treeId
    );
  }
};

export const getChangeset = (item: SuperComponent) => {
  if (item.id === undefined) {
    throw new Error("Item id is required", { cause: item });
  }

  if (!storage.changeset[item.id]) {
    storage.changeset[item.id] = createStore<ChangesetStorage>(
      {
        errors: [],
        requiresValidation: true,
        status: item.status,
        legacyNextRevision: item.legacyNextRevision,
        originalRevision: item.legacyNextRevision,
        originalStatus: item.status,
      },
      () => {
        storage.isPendingValidation.setState(true);
        storage.isValid.setState(false);
      }
    );
  }

  addTreeIdMapping(item);

  return storage.changeset[item.id];
};

export const getModifiedChangeOrder = (
  remoteCo: ChangeOrder | undefined
): ChangeOrderPLMApi => {
  if (!remoteCo) throw new Error("No change order to merge with");
  const name = storage.form.name.getState();
  if (name == null) throw new Error("Name is required");

  const webhooks = storage.form.erpOptions.additionalPayloadForNotifications;

  return {
    ...(remoteCo ?? {}),
    creator: storage.form.creator.getState(),
    resolution: storage.form.resolution.getState(),
    name,
    description: storage.form.description.getState(),
    type: storage.form.type.getState(),
    approvalType: storage.form.approvalType.getState(),
    approvers: storage.form.approverList.getState(),
    coInternalNotifyUsers: storage.coInternalNotifyUsers.getState(),
    coExternalNotifyUserEmails: storage.coExternalNotifyUserEmails.getState(),
    documentLinks: storage.documentLinks.getState(),
    erpOptions: {
      effectivity: storage.form.erpOptions.effectivity.getState(),
      itemType: storage.form.erpOptions.itemType.getState(),
      additionalPayloadForNotifications: webhooks.getState(),
    },
  };
};
