import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
  ValidationCell,
  RevisionCell,
  StatusCell,
  LastModifiedCell,
  ModalRevisionCell,
  ModalStatusCell,
  ModalPreviousStatusCell,
  PreviousStatusCell,
  DeleteCell,
} from "./cells";
import { SuperComponent } from "design/models/changeorder";
import { CPNCell } from "./cells/cpn-cell";

export type TableColumnDef = { field: string; headerName: string };

export const NameCell = {
  field: "name",
  headerName: "Name",
  width: 140,
};

export const EidCell = {
  field: "eid",
  headerName: "EID",
  width: 50,
};

export const DescriptionCell = {
  field: "description",
  headerName: "Description",
  width: 200,
};

export const toDefaults = (
  options: GridColDef<SuperComponent>
): GridColDef<SuperComponent> => ({
  filterable: false,
  sortable: false,
  hideSortIcons: true,
  ...options,
});

export const autoGroupColumnDef = {
  headerName: "CPN",
  width: 150,
  renderCell: CPNCell.renderCell,
  hide: true,
};

export const ColumnDefinitions: GridColDef<SuperComponent>[] = [
  CPNCell,
  ValidationCell,
  NameCell,
  RevisionCell,
  StatusCell,
  PreviousStatusCell,
  DescriptionCell,
  LastModifiedCell,
  EidCell,
  DeleteCell,
].map(toDefaults);

export const PreviewModalColumnDefinitions: GridColDef<SuperComponent>[] = [
  CPNCell,
  NameCell,
  ModalPreviousStatusCell,
  DescriptionCell,
  ModalRevisionCell,
  ModalStatusCell,
  EidCell,
  LastModifiedCell,
].map(toDefaults);
