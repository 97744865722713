import Box from "@mui/material/Box";
import React, { useState, useMemo, useEffect } from "react";
import { SectionSelect } from "./sectionSelect.component";
import { ChangeOrderUser, EcoDefaultApproval } from "design/models/changeorder";
import { useMutation } from "@apollo/client";
import { UPDATE_CHANGE_ORDER_APPROVERS_TEMPLATE_LASTUSED } from "graphql/query/changeOrdersQueries";
import { sdk } from "features/changeorders/sdk";

export type TemplateData = {
  id: string;
  templateName: string;
  isPublic?: boolean;
  lastUsed?: number | null;
  approvalType: EcoDefaultApproval;
  approvers: ChangeOrderUser[];
  notifiers: ChangeOrderUser[];
  externalUsers: string[];
};

type TemplateDropdownProps = {
  data?: TemplateData[];
  defaultTemplate?: TemplateData;
  onSelect: (templateId: string, template: TemplateData | undefined) => void;
};

export const TemplateDropdown: React.FC<TemplateDropdownProps> = ({
  data = [],
  defaultTemplate,
  onSelect,
}) => {
  const [selectedTemplateName, setSelectedTemplateName] = useState("");
  const [mandatoryTemplate] = sdk.storage.currentMandatoryTemplate.useStore();
  useEffect(() => {
    if (mandatoryTemplate?.id !== "") {
      setSelectedTemplateName(mandatoryTemplate?.id);
    } else {
      setSelectedTemplateName("");
    }
  }, [mandatoryTemplate]);

  const [updateTemplateMutation] = useMutation(
    UPDATE_CHANGE_ORDER_APPROVERS_TEMPLATE_LASTUSED
  );

  const IdtoLabelMap: Record<string, string> = useMemo(() => {
    const map: Record<string, string> = {};
    if (defaultTemplate) {
      map[defaultTemplate.id] = defaultTemplate.templateName;
    }
    data.forEach((template) => {
      map[template.id] = template.templateName;
    });
    return map;
  }, [data, defaultTemplate]);

  const options = useMemo(() => {
    const formattedOptions: Record<string, { label: string; value: string }[]> =
      {};

    if (defaultTemplate) {
      const section = "Default Template";
      const temp = {
        label: defaultTemplate.templateName,
        value: defaultTemplate.id,
      };
      formattedOptions[section] = [temp];
    }

    const recentTemplates = data
      .filter(
        (template) =>
          template.lastUsed !== null &&
          template.lastUsed !== undefined &&
          template.templateName !== "Default Template"
      )
      .sort((a, b) => (b.lastUsed as number) - (a.lastUsed as number))
      .slice(0, 3);
    if (recentTemplates.length > 0) {
      const section = "Recent";
      if (!formattedOptions[section]) {
        formattedOptions[section] = [];
      }
      formattedOptions[section] = recentTemplates.map((template) => ({
        label: template.templateName,
        value: template.id,
      }));
    }

    data.forEach((template) => {
      const section =
        template.templateName === "Default Template"
          ? null
          : template.isPublic
          ? "Company"
          : "Private";
      if (section === null) {
        return;
      }
      if (!formattedOptions[section]) {
        formattedOptions[section] = [];
      }

      formattedOptions[section].push({
        label: template.templateName,
        value: template.id,
      });
    });
    return formattedOptions;
  }, [data, mandatoryTemplate]);

  const updateLastUsed = async (id: string) => {
    const curTime = Date.now();
    try {
      await updateTemplateMutation({
        variables: {
          input: {
            id: id,
            lastUsed: curTime,
          },
        },
      });
    } catch (err) {
      console.log("error is ", err);
    }
  };

  const handleSelection = (event: any) => {
    const selectedId = event.target.value;
    const selectedTemplate =
      data.find((item) => item.id === selectedId) || defaultTemplate;
    setSelectedTemplateName(selectedId);
    if (selectedId != 1) {
      updateLastUsed(event.target.value);
    }
    onSelect(selectedId, selectedTemplate);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "100%" }}>
      <SectionSelect
        options={options}
        onChange={handleSelection}
        displayEmpty
        placeholder="Select template"
        value={selectedTemplateName}
        disabled={mandatoryTemplate.open}
        isRequired={false}
        name="Template"
        idToLabelMap={IdtoLabelMap}
      />
    </Box>
  );
};

export default TemplateDropdown;
