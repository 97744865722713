import {Component} from "react"
import Loading            from "../../../../ui/inner-loading"
import Utils              from "../../../../../modules/utils"
import ExtendedTablePaginated from "../../../common/extended-table/extended-table-paginated"
import Avatar from "@duro/base/avatar";
import API from "@duro/v1api";
import ChangeOrderItem    from "../../../../ui/change-order-item";
import TimeStampColumn    from "../../../common/timestamp-column";
import RefreshIcon        from "../../../../../assets/icons/refresh-icon.js";
import WarningModal       from "../../../../ui/warning-modal";
import VendorPillbox      from "../../../component/components/vendor";

export class ReleaseList extends Component
{
    constructor(props)
    {
        super(props)

        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.searchReleases || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.state =
        {
            currentSortItemAscending : ("defaultSortAssending" in viewStyles ? viewStyles.defaultSortAssending : false),
            current : ("defaultSortColumnName" in viewStyles ? viewStyles.defaultSortColumnName : "created"),
            syncReleaseBtn: {active: true},
            showReleaseInitiatedModal: false,
            headings :
            [
                {
                    key         : "releaseNo",
                    displayName : "Release No",
                    tooltip     : "Release No",
                    sortable    : true,
                    minWidth    : 66,
                    width       : Utils.getStyleValue(viewStyles, "releaseNo", "width", 133),
                    position    : Utils.getStyleValue(viewStyles, "releaseNo", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "releaseNo", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 77,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 177),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 124,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 259),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "author",
                    displayName : "Author",
                    tooltip     : "Author",
                    sortable    : true,
                    minWidth    : 87,
                    width       : Utils.getStyleValue(viewStyles, "author", "width", 237),
                    position    : Utils.getStyleValue(viewStyles, "author", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "author", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "created",
                    displayName : "Time",
                    tooltip     : "Time",
                    sortable    : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "created", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "created", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "created", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "source",
                    displayName : "Source",
                    tooltip     : "Source",
                    sortable    : true,
                    minWidth    : 118,
                    width       : Utils.getStyleValue(viewStyles, "source", "width", 143),
                    position    : Utils.getStyleValue(viewStyles, "source", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "source", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "change_order_item",
                    displayName : "Change Order",
                    tooltip     : "Change-order-item",
                    sortable    : true,
                    minWidth    : 185,
                    width       : Utils.getStyleValue(viewStyles, "change_order_item", "width", 185),
                    position    : Utils.getStyleValue(viewStyles, "change_order_item", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "change_order_item", "visibility", true),
                    disabled    : false
                }

            ]
        }

        this.getRows             = this.getRows.bind(this);
        this.getIconsActionsList = this.getIconsActionsList.bind(this);
        this.onClickReleaseSync  = this.onClickReleaseSync.bind(this);
        this.onSyncReleaseInitiatedModalClose = this.onSyncReleaseInitiatedModalClose.bind(this);

    }

    onSyncReleaseInitiatedModalClose()
    {
        this.setState({syncReleaseBtn: {active: true}, showReleaseInitiatedModal: false});
    }

    getRows()
    {
        let rows = [];
        let results = this.props.results;
        results.map((result, i) =>
        {
            let name = result.creator ? (result.creator.firstName + " " + result.creator.lastName) : "";
            let created             = result.created ? result.created : undefined;
            let createdToolTip      = created ? Utils.dateTimeWithLongFormat(created) : null;
            let con   = result.refCo ? result.refCo.con    : '';
            let refCo = result.refCo ? result.refCo.status : '';
            let {isVendorCmp, vendorLabel} = Utils.getVendorLabel(result);

            let cells =
            {
                "releaseNo":
                {
                    value        : result.releaseNo,
                    tooltip      : result.releaseNo,
                    displayValue :  <span className="link">
                                        {result.releaseNo}
                                    </span>
                },
                "name":
                {
                    value       : result.name,
                    tooltip     : result.name,
                    displayValue : result.name
                },
                "description":
                {
                    value       : result.description,
                    tooltip     : result.description,
                    displayValue : result.description
                },
                "author":
                {
                    value       : {name},
                    tooltip     : {name},
                    cellClass   : 'user-info-col',
                    displayValue :
                        [
                            <Avatar
                                findById={API.images.findById}
                                imageId={result.creator ? result.creator.avatar : null}
                                index={i}
                                key={Utils.generateUniqueId()}
                            />
                            ,
                            <span
                                className="user-name"
                                data-tip=""
                                key={i + '-name'}
                                onMouseOver={(e) => Utils.checkToolTipAppearance(e, name)}
                            >{name}</span>
                        ]
                },
                "created":
                {
                    value        : created,
                    tooltip      : createdToolTip ? `${createdToolTip.dateValue} ${createdToolTip.timeValue}`: '',
                    displayValue : <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={created} />
                },
                "source":
                {
                    value        : result.vendor,
                    tooltip      : result.vendor,
                    displayValue : <VendorPillbox isVendorCmp={isVendorCmp} label={vendorLabel}  className="custom-source-pillbox" isComponentLinked={true}/>
                },
                "change_order_item":
                {
                    notLink      : true,
                    value        : refCo,
                    tooltip      : refCo,
                    cellClass    : "change-order-block",
                    displayValue : refCo ?
                        <ChangeOrderItem
                            resolution={result.refCo.resolution}
                            status={refCo}
                            con={con}
                            id={result.refCo._id}
                            /> : ''
                },
                object: result,
                rowLink         : "/release/view/"+result._id,
                rowId           : result._id

            }

            rows.push(cells);
        })
        return rows;
    }

    onClickReleaseSync()
    {
        this.setState({syncReleaseBtn: {active: false}, showReleaseInitiatedModal: true}, () => {
            API.release.syncRelease((err, response) =>
            {
                if(err)
                {
                    return;
                }
            });
        });
    }

    getIconsActionsList()
    {
        let {syncReleaseBtn} = this.state;
        let actionsList = [];
        let actionEntry =
        {
            type          : 'action',
            iconSrc       : <RefreshIcon />,
            toolTip       : "Sync Onshape Releases",
            name          : "Sync",
            iconClassName : "",
            onClick       : this.onClickReleaseSync,
            active        : syncReleaseBtn.active
        };
        actionsList.push(actionEntry);
        return actionsList;
    }


    getSyncReleaseInitiatedModal()
    {
        let markup =
        <WarningModal
            onCancel={this.onSyncReleaseInitiatedModalClose}
            hideTitleIcon={true}
            hideCancelBtn={true}
            onConfirm={this.onSyncReleaseInitiatedModalClose}
            title={"Syncing Process Initiated"}
            description={["Now syncing all unpublished Onshape releases to Duro.", <br/> ,"This process may take a few minutes to complete."]}
            className="release-sync-modal"
        />;
        return markup;
    }

    render()
    {
        if(this.props.loading)
        {
            return <Loading />
        }
        let headings = this.state.headings
        let rows = this.getRows();

        let markup  =
                <div className="release-search-list">
                    {
                        this.state.showReleaseInitiatedModal ? this.getSyncReleaseInitiatedModal() : null
                    }

                    {
                        <ExtendedTablePaginated
                            tableActionButtons={this.getIconsActionsList()}
                            wrapperClassName="rel-list-block"
                            wrapperSelectorClass="rel-list-view"
                            headings={headings}
                            rows={rows}
                            stylesName="searchReleases"
                            allowRowSelect={false}
                            onRowSelect={this.props.addToSelectedList}
                            onSelectAllRows={this.props.selectAllResults}
                            currentSortItem={this.state.current}
                            currentSortItemAscending={this.state.currentSortItemAscending}
                            resetLocalState={this.props.resetLocalState}
                            clearSelectedRowsStore={this.props.clearSelectedRowsStore}
                            resultText={this.props.resultText}
                            includeToolBar={true}
                            scrollPagination={true}
                            paginationSize={150}
                            count={this.props.count}
                            footerRow={
                                rows.length === 0 &&
                                {
                                    dataCellEl: <p>No Releases</p>
                                }
                            }
                        />
                    }
                </div>

        return markup
    }
}

export default ReleaseList
