import "./index.scss"
import React, {Component}   from "react";
import Tooltip              from 'rc-tooltip';
import Utils                from "../../../../../../../modules/utils"
import {validateField}      from "../../../../../../../modules/validations"
import Icon                 from "../../../../../../ui/icon"
import DeleteSrc            from "../../../../../../../assets/icons/cross-icon.js"
import AddSrc               from "../../../../../../../assets/icons/add-row.js";
import DownSrc              from "../../../../../../../assets/icons/down.js";
import UpSrc                from "../../../../../../../assets/icons/up.js"
import WarningSrc           from "../../../../../../../assets/icons/warning.js"
import SyncedSrc            from "../../../../../../../assets/icons/synced.js"
import $                    from "jquery";
import LazyInput            from "../../../../../../ui/lazy-input/input.js"
import InlineIcon           from "../../../../../../ui/icon/inline-icon.js"
import TimeStampColumn      from "../../../../timestamp-column";
import SourcingUtils        from "../../../sourcing-utils"

class Quotes extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.onHeadingClick      = this.onHeadingClick.bind(this)
        this.sort                = this.sort.bind(this)
        this.add                 = this.add.bind(this)
        this.remove              = this.remove.bind(this)
        this.onInputChange       = this.onInputChange.bind(this)
        this.onChange            = this.onChange.bind(this)
        this.setQuotesValues     = this.setQuotesValues.bind(this)
        this.validateInputValues = this.validateInputValues.bind(this)
        this.validateAllFields   = this.validateAllFields.bind(this)
        this.schema              = this.props.schema
        this.clientSchema        = this.props.clientSchema
        this.shiftKeySelected    = this.shiftKeySelected.bind(this);
        this.onCheckBoxChange    = this.onCheckBoxChange.bind(this);

        this.state =
        {
            column :
            {
                name      : "minQuantity",
                ascending : true
            },
            inputChanged : false,
            columns :
            [
                {
                    name     : "checkBox",
                    value    : "",
                    width    : "30px"
                    // width    : 30
                },
                {
                    name     : "minQuantity",
                    class    : "sortable",
                    value    : "Min qty",
                    width    : "94px",
                    // width    : 100,
                    sortable : true
                },

                {
                    name     : "unitPrice",
                    class    : "sortable",
                    value    : "Unit price",
                    width    : "100px",
                    // width    : 100,
                    sortable : true
                },
                {
                    name     : "leadTime.valueInDays",
                    class    : "sortable",
                    value    : "Lead time",
                    width    : "260px",
                    // width    : 100,
                    sortable : true
                },
                {
                    name     : "lastUpdated",
                    class    : "sortable",
                    value    : "Last Updated",
                    width    : "175px",
                    // width    : 100,
                    sortable : true
                },

                {
                    value : "",
                    width    : "25px"
                    // width    : 30
                }
            ],
            lastchecked: -1,
            selectedSourcingRowsIds : []
        }

        this.componentWillMount = this.sort
        this.onRowSelect   = this.onRowSelect.bind(this)
        this.resetModifiedFlag = this.resetModifiedFlag.bind(this)
    }

    resetModifiedFlag()
    {
        this.props.quotes.forEach((quote, index) => {
            quote.modified = false;
        })
    }

    onRowSelect(event, quote)
    {
        let element = event.target
        let checked = event.target.checked
        $(element).prop('checked', checked);
        if(quote.inputs.minQuantity.value !== "" && quote.inputs.minQuantity.valid)
        {
            this.props.onPrimarySourceChange(checked, quote);
        }
    }

    onHeadingClick(event)
    {
        let column       = this.state.column
        let target       = event.target
        if ( target.tagName !== "TH" )
            target       = target.closest("th")
        column.name      = target.getAttribute("name")
        column.ascending = !target.className.match("ascending")
        this.setState(this.state, this.sort)
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.quotes !== this.props.quotes) {
            this.setQuotesValues(nextProps.quotes)
            this.setState(this.state)
        }
    }

    sort(cb)
    {
        let column  = this.state.column
        let columns = this.state.columns
        let quotes  = this.props.quotes

        columns.forEach((col) =>
        {
            col.class = col.sortable ? "sortable " : col.class
            if(col.name === column.name) col.class += column.ascending ?  "ascending" : "descending"
        })

        quotes.sort((a,b) =>
        {
            a = Utils.hash(column.name, a)
            b = Utils.hash(column.name, b)

            if (column.name === "minQuantity" || column.name === "leadTime")
            {
                if(parseInt(a, 0) < parseInt(b, 0)) return column.ascending ? -1 :  1
                if(parseInt(a, 0) > parseInt(b, 0)) return column.ascending ?  1 : -1
            }
            else
            {
                if(typeof a === "string") a = a.toLowerCase()
                if(typeof b === "string") b = b.toLowerCase()

                if(a < b) return column.ascending ? -1 :  1
                if(a > b) return column.ascending ?  1 : -1
            }

            return 0
        })

        this.setQuotesValues(quotes)
        this.setState(this.state, cb)
    }

    setQuotesValues(quotes, options={}){
        let cmpLastModified = (this.props.component && this.props.component.lastModified) ? this.props.component.lastModified: undefined;
        quotes.forEach((quote) =>
        {
            if (!quote.inputs){
                quote.uniqueId = Utils.generateUniqueId()
                let lastModified = null;
                if(!quote.isNewlyCreated)
                {
                    lastModified = quote.lastUpdated ? quote.lastUpdated : cmpLastModified;
                }
                quote.inputs = {
                    minQuantity :
                    {
                        value : quote.minQuantity || '',
                        message : "",
                        class   : "",
                        valid : true
                    },
                    unitPrice :
                    {
                        value : quote.unitPrice,
                        message : "",
                        class   : "min-height",
                        valid : true
                    },
                    leadTime :
                    {
                        value : quote.leadTime.value || "0",
                        message : "",
                        class   : "",
                        valid : true
                    },
                    leadTimeUnit :
                    {
                        value : quote.leadTime.units || "DAYS",
                        message : "",
                        class   : "",
                        valid : true
                    },
                    lastUpdated :
                    {
                        value   : lastModified ? <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} /> : '',
                        message : "",
                        class   : "",
                        valid : true
                    },
                    isPrimary :
                    {
                        value : quote.isPrimary || false,
                        message : "",
                        class   : "",
                        valid : true
                    },
                    isChecked :
                    {
                        value   : quote.isPrimary || false,
                        message : "",
                        class   : "",
                        valid   : true
                    }
                }
                this.validateAllFields(quote, {trackInputChanged: options.trackInputChanged})
            }
        })
    }


    componentDidUpdate(prevProps) {
      if (prevProps.status !== this.props.status) {
        let quotes  = this.props.quotes
        quotes.forEach((quote) =>
        {
            this.validateAllFields(quote)
        })
        this.setState(this.state)
      }
    }

    validateAllFields(quote, options={}){
        this.validateInputValues(quote, "minQuantity", quote.inputs.minQuantity.value, options)
        this.validateInputValues(quote, "unitPrice", quote.inputs.unitPrice.value, options)
        this.validateInputValues(quote, "leadTime", quote.inputs.leadTime.value, options)
        this.validateInputValues(quote, "leadTimeUnit", quote.inputs.leadTimeUnit.value, options)
    }

    add()
    {
        let data =
        {
            minQuantity : "",
            unitPrice   : "",
            leadTime    : {},
            isNewlyCreated : true,
            isPrimary   : false,
            uniqueId    : Utils.generateUniqueId()
        }
        this.props.quotes.push(data)
        this.setQuotesValues(this.props.quotes, {trackInputChanged: true})
        this.setState(this.state, this.onChange)
    }

    remove(i)
    {
        this.props.toggleCountOnRemove(this.props.quotes[i], "quote");
        let quote = this.props.quotes[i]

        if (quote.isPrimary === true)
        {
            quote.isPrimary = false
            // this.deselectPrimarySource(quote)
        }
        this.props.quotes.splice(i, 1)
        let checkedQuote = 0;
        let isValid = false;
        this.props.manufacturers.forEach((man, manIndex) => {
            man.distributors.forEach((dist, distIndex) => {
                dist.quotes.forEach((q, quoteIndex) => {
                    if(q.inputs.isChecked.value && q.isPrimary === false){
                        checkedQuote++;
                        if(q.inputs.minQuantity.value !== "" && q.inputs.minQuantity.valid)
                        {
                            quote = q;
                            isValid = true;
                        }
                    }
                });
            });
        });
        if(checkedQuote === 1 && isValid)
        {
            this.props.onPrimarySourceChange(true, quote);
        }
        else{
             this.props.onPrimarySourceChange(false, quote);
        }
        this.setState(this.state, () => this.onChange({trackInputChanged: true}))
    }

    shiftKeySelected(event, current)
    {
        let checkedstate = false
        let start = -1;
        let end  = -1;
        let state = this.state;
        if(!this.props.quotes[current].inputs.isChecked.value)
        {
            checkedstate = true;
        }

        if(state.lastchecked < current && state.lastchecked !== -1)
        {
            start = state.lastchecked;
            end = current;
        }
        if(state.lastchecked > current && state.lastchecked !== -1)
        {
            start = current;
            end = state.lastchecked;
        }
        if(start >= 0 && end >= 0)
        {
            for(let j = start; j <= end; j++)
            {
                this.props.quotes[j].inputs.isChecked.value = checkedstate;
                $(`.dist-${this.props.distributorIndex+1}.quote-${j+1} input[name='isPrimary']`).prop("checked", checkedstate);
            }
            if(!this.props.quotes[current].inputs.isChecked.value)
            {
                this.props.quotes[current].inputs.isChecked.value = checkedstate;
            }
        }
    }

    onInputChange(event, i, fieldName)
    {
        let name  = event.target.name
        let value = event.target.value;
        let checked = event.target.checked;

        if (fieldName === 'leadTimeUnit')
            name = "leadTimeUnit"
        let quote = this.props.quotes[i]

        if (this.props.tabsType === "cmp" || this.props.tabsType === "prd") {

            if(event.target.name === 'isPrimary')
            {
                quote.inputs.isChecked.value = event.target.checked; // this input value decide to compare data with API...
            }
            if(event.shiftKey)
            {
                quote.inputs.isChecked.value = !event.target.checked;
                $(`.dist-${this.props.distributorIndex+1}.quote-${i+1} input[name='isPrimary']`).prop("checked", !event.target.checked);
                this.shiftKeySelected(event, i);
                this.state.lastchecked = i;
            }
            if (event.target.name === 'isPrimary')
            {
                let count = 0;
                $(`input[name='isPrimary']:checked`).each(function () {
                    count++;
                });
                if (count === 1) {
                    if(!checked)
                    {
                        let checkedQuote = this.props.quotes[$("input[name='isPrimary']:checked").attr("quotekey")];
                        quote = checkedQuote ? checkedQuote : quote;
                        checked = true;
                    }

                    if(!quote.inputs.isChecked.value)
                    {
                        this.props.manufacturers.forEach((man, manIndex) => {
                            man.distributors.forEach((dist, distIndex) => {
                                dist.quotes.forEach((q, quoteIndex) => {
                                    if(q.inputs.isChecked.value)
                                    {
                                        quote = q;
                                    }
                                });
                            });
                        });
                    }
                    this.onRowSelect(event, quote);
                }
                this.props.toggleRefreshBtn(0, quote);
            } else {
                // Based on this *modified flag, we will update quote lastUpdated field with the component modified date in the backend API...*
                quote.modified = true;
            }
        } else {
            if (event.target.name === 'isPrimary')
            {
                this.onRowSelect(event, quote)
            }
        }

        if (quote.isPrimary)
        {
            this.props.syncPrimaryQuoteInputWithData(quote)
        }

        this.validateInputValues(quote, name, value, {trackInputChanged: true})
        Utils.persistCursor(event, value)
        this.setState(this.state, this.onChange)
        if(quote.inputs.minQuantity.value !== "" && quote.inputs.minQuantity.valid && quote.inputs.isChecked.value)
        {
            this.props.onPrimarySourceChange(true, quote);
        }
        this.props.toggleRefreshBtn(0, quote);
    }

    deselectPrimarySource(quote)
    {
      quote.isPrimary = false
      this.props.onPrimarySourceDeselect()
      this.props.syncPrimaryQuoteInputWithData({})
      this.props.toggleRefreshBtn(1); // On deselect do not count this checkBox...
      this.onChange()
    }

    validateInputValues(quote, name, value, options={}){
        let input, validator = null
        let trackInputChanged = options.trackInputChanged
        let state = this.state

        switch(name)
        {
            case "minQuantity" :
            {
                state.inputChanged = trackInputChanged ? true : false

                validator   = this.clientSchema.minQuantity
                input       = quote.inputs.minQuantity
                let inputValue = value
                this.props.validateSourcingFields(input, validator, validator.normalize(value), {status: this.props.status}, {valid_class: ""}, input.valid);
                input.value = inputValue
                quote.minQuantity = value
                if (!!quote.minQuantity === false && quote.isPrimary)
                {
                    this.deselectPrimarySource(quote)
                }
                break
            }

            case "unitPrice" : {
                state.inputChanged = !!trackInputChanged;
                validator   = this.clientSchema.unitPrice;
                input       = quote.inputs.unitPrice;
                const priceWithoutCurrency = Number(value?.toString().replace("$", ""));
                const [unitPriceValue, validatorValue] = isNaN(priceWithoutCurrency) ? [value, value] 
                    : [SourcingUtils.precisePriceValue(priceWithoutCurrency), priceWithoutCurrency];
                
                this.props.validateSourcingFields(input, validator, validatorValue, {status: this.props.status}, {valid_class: ""}, input.valid);
                quote.unitPrice = input.value = !trackInputChanged ? unitPriceValue : value;
                if (!!quote.unitPrice === false && quote.isPrimary) {
                    this.deselectPrimarySource(quote)
                }
                break
            }

            case "leadTime" :
            {
                state.inputChanged = trackInputChanged ? true : false

                value = parseInt(value) ? parseInt(value) : 0
                validator   = this.clientSchema.leadTime.value
                input       = quote.inputs.leadTime
                this.props.validateSourcingFields(input, validator, value, {leadTime: {value: value, units: quote.inputs.leadTimeUnit.value}}, {valid_class: ""}, input.valid);
                quote.leadTime = {value: value, units: quote.inputs.leadTimeUnit.value, valueInDays: Utils.stringValueToDays(value, quote.inputs.leadTimeUnit.value)}

                validator   = this.clientSchema.leadTime.units
                input       = quote.inputs.leadTimeUnit
                input.value = validator.normalize({leadTime: {value: quote.inputs.leadTime.value}}, input.value).units
                quote.leadTime.units = input.value;
                break
            }

            case "leadTimeUnit" :
            {
                state.inputChanged = trackInputChanged ? true : false

                let leadTimeValue = parseInt(quote.inputs.leadTime.value)
                validator   = this.clientSchema.leadTime.units
                input       = quote.inputs.leadTimeUnit

                input.value = validator.normalize({leadTime: {value: leadTimeValue}}, value).units
                quote.leadTime = {value: quote.inputs.leadTime.value, units: input.value, valueInDays: Utils.stringValueToDays(quote.inputs.leadTime.value, value)}
                break
            }

            default :
            {
                // noop
            }
        }
    }

    onChange(options={})
    {
        if(this.props.onChange)
        {
            let data  = {data: this.props.quotes, valid: true, inputChanged: (this.state.inputChanged || options.trackInputChanged)}
            let event = Utils.getEvent(this, data)

            this.props.onChange(event)
        }
    }

    onCheckBoxChange(event, quoteId)
    {
        let value = event.target.checked;
        let selectedSourcingRowsIds = this.state.selectedSourcingRowsIds;
        if(value)
        {
            selectedSourcingRowsIds.push(quoteId);
        }
        else
        {
            const index = selectedSourcingRowsIds.indexOf(quoteId);
            if (index > -1) {
              selectedSourcingRowsIds.splice(index, 1);
            }
        }
        this.setState({selectedSourcingRowsIds});
    }

    removeSource(event, quoteId)
    {
        let selectedSourcingRowsIds = this.state.selectedSourcingRowsIds;
        const index = selectedSourcingRowsIds.indexOf(quoteId);
        let splicedIds = [];
        if (index > -1)
        {
          splicedIds = selectedSourcingRowsIds.splice(index, 1);
          selectedSourcingRowsIds=[];
        }
        this.props.removeSource(event, quoteId, splicedIds);
        this.setState({selectedSourcingRowsIds});
    }

    clearSourceWarning(event, quoteId)
    {
        let selectedSourcingRowsIds = this.state.selectedSourcingRowsIds;
        let splicedIds = [];
        const index = selectedSourcingRowsIds.indexOf(quoteId);
        if (index > -1)
        {
          splicedIds = selectedSourcingRowsIds.splice(index, 1);
          selectedSourcingRowsIds=[];
        }
        this.props.clearSourceWarning(event, quoteId, splicedIds);
        this.setState({selectedSourcingRowsIds});
    }

    normalizeOnFocusOut = (event, quoteIndex) => {
        const name = event.target.name;
        const quote = this.props.quotes[quoteIndex];
        this.validateInputValues(quote, name, quote[name]);       
        this.onChange() 
    }

    render()
    {
        let quotes = this.props.quotes
        let markup =
            <div className="quotes">
                <table>
                    <tbody>
                        <tr>
                        {
                            this.state.columns.map((col, i) =>
                            {
                                let headerCheckBox =
                                        <span
                                            className={"select-all-qoutes primary-source-checkbox " + this.props.distClass}
                                            >
                                                <input
                                                    type="checkbox"
                                                    name="selectAllQuotes"
                                                    onClick={
                                                        (e) => this.props.selectAllItems(event, this.props.quotes, this.props.distClass)
                                                    }
                                                />
                                                <label
                                                    htmlFor="group"
                                                />
                                            </span>

                                let textBlock =
                                    <div
                                        className={"text-block"}
                                        >
                                        <div onClick={col.sortable ? this.onHeadingClick : null}>{col.value}</div>
                                        {quotes.length > 1 && <div onClick={col.sortable ? this.onHeadingClick : null}/>}
                                    </div>


                                    let expandEl =
                                            <span
                                                // className="col-move"
                                                // onMouseDown={(e) => this.onMouseDown(e, i)}
                                                // onMouseEnter={(e) => this.addExpandHoverClass(e)}
                                                // onMouseLeave={(e) => this.removeExpandHoverClass(e)}
                                                // onClick={this.performNoEvent}
                                                // onDoubleClick={this.onThDoubleClick}
                                            >
                                            </span>

                                    let block =
                                        <th
                                            key={i}
                                            name={col.name}
                                            className={col.class  + " resizing---"}
                                            style={{width: col.width}}
                                            // style={{width: col.width+"px"}}
                                            >
                                            <span className={`handler ${(this.props.tabsType === 'cmp' && i == 0) ? "header-checkbox": ""}`}>
                                                { (this.props.tabsType === 'cmp' && i == 0) ? headerCheckBox : textBlock}
                                                {expandEl}
                                            </span>
                                        </th>
                                return block
                            })
                        }
                        </tr>
                        {
                            quotes.map((quote, i) =>
                            {
                                let quoteClass = this.props.distClass + 'quote-'+(i+1)+' '
                                let row =
                                    <tr
                                        key={i}
                                        className={quoteClass + (quote.isPrimary ? 'primary-source' : '') + (quote.unitPriceState === "worsened" ? ' warning-source' : '') }>
                                        <td>
                                        {
                                            quote.unitPriceState === "worsened" ?
                                            <Tooltip
                                                placement={"right"}
                                                overlayClassName={"simple-rc-tip source-no-exist"}
                                                overlay={
                                                    <div>
                                                        <p className="tooltip-text">Source is no longer available.</p>
                                                        <div className="tooltip-action-area">
                                                            <div className="tooltip-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    name="doForAll"
                                                                    id="doForAll"
                                                                    onChange={(event) => this.onCheckBoxChange(event, quote._id)}
                                                                />
                                                                <label htmlFor="group"></label>
                                                                <span>Apply to all</span>
                                                            </div>
                                                            <button type="button1" className="sourcing-action-btn"
                                                                onClick={(event) => this.removeSource(event, quote._id)}>
                                                                Remove Source
                                                            </button>
                                                            <button type="button2"
                                                                className="sourcing-action-btn"
                                                                onClick={(event) => this.clearSourceWarning(event, quote._id)}>
                                                                Clear Warning
                                                            </button>
                                                        </div>
                                                    </div>}
                                                >
                                                <div className="warning-icon-width">
                                                    <InlineIcon
                                                        className="warning-icon-inner-width"
                                                        >
                                                        <WarningSrc/>
                                                    </InlineIcon>
                                                </div>
                                            </Tooltip>
                                            :
                                            quote.isPrimary ?
                                            <span
                                                className="primarySource active center-state checkbox"
                                                onClick={ (event) => this.deselectPrimarySource(quote) }
                                            />
                                            :
                                            <span
                                                className="primary-source-checkbox"
                                            >
                                                <input
                                                    type="checkbox"
                                                    name="isPrimary"
                                                    quotekey={i}
                                                    onChange={
                                                        (event) => this.onInputChange(event, i)
                                                    }
                                                />
                                                <label
                                                    htmlFor="group"
                                                />
                                            </span>
                                        }
                                        </td>
                                        <td>
                                            <LazyInput
                                                type="text"
                                                name="minQuantity"
                                                autoComplete="off"
                                                value={quote.inputs.minQuantity.value}
                                                onChange={(event) => this.onInputChange(event, i)}
                                                className={quote.inputs.minQuantity.class}
                                                data-tip={quote.inputs.minQuantity.message}
                                                data-type="error"
                                            />
                                        </td>

                                        <td>
                                            <div className="unitPrice-style">
                                                <LazyInput
                                                    type="text"
                                                    name="unitPrice"
                                                    autoComplete="off"
                                                    value={quote.inputs.unitPrice.value}
                                                    onChange={(event) => this.onInputChange(event, i)}
                                                    className={`min-height ${quote.inputs.unitPrice.class}`}
                                                    data-tip={quote.inputs.unitPrice.message}
                                                    data-type="error"
                                                    placeholder={`${this.props.defaultCurrency}${SourcingUtils.unitPricePlaceHolder()}`}
                                                    shouldRerender={true}
                                                    timeout={1}
                                                    onBlur={(event) => this.normalizeOnFocusOut(event, i)}
                                                />
                                                {
                                                    quote.unitPriceState === "down" ?
                                                        <InlineIcon
                                                            className="unitPrice-icon">
                                                            <DownSrc />
                                                        </InlineIcon>
                                                        :
                                                        quote.unitPriceState === "up" ?
                                                        <InlineIcon
                                                            className="unitPrice-icon">
                                                            <UpSrc />
                                                        </InlineIcon>
                                                        :
                                                        quote.unitPriceState === "noChange" ?
                                                        <InlineIcon
                                                            className="unitPrice-icon">
                                                            <SyncedSrc />
                                                        </InlineIcon>
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </td>
                                        <td className="leadtime-inputs">
                                        <div className="leadtime-inner-style">
                                            <div
                                                className={quote.inputs.leadTime.class}
                                                data-tip={quote.inputs.leadTime.message}
                                                data-place="right"
                                                data-type="error"
                                            >
                                                <LazyInput
                                                    type="text"
                                                    name="leadTime"
                                                    autoComplete="off"
                                                    value={quote.inputs.leadTime.value}
                                                    className={quote.inputs.leadTime.class}
                                                    data-tip={quote.inputs.leadTime.message}
                                                    data-place="right"
                                                    data-type="error"
                                                    onChange={(event) => this.onInputChange(event, i)}
                                                />
                                                <div className="radio">
                                                   <input
                                                        type="radio"
                                                        value="DAYS"
                                                        onChange={(event) => this.onInputChange(event, i, "leadTimeUnit")}
                                                        checked={["DAYS", "DAY"].includes(quote.leadTime.units)}
                                                       />
                                                 <label />
                                                 <span>
                                                     Day(s)
                                                 </span>
                                               </div>

                                               <div className="radio">
                                                   <input
                                                        type="radio"
                                                        value="WEEKS"
                                                        onChange={(event) => this.onInputChange(event, i, "leadTimeUnit")}
                                                        checked={["WEEKS", "WEEK"].includes(quote.leadTime.units)}
                                                       />
                                                 <label />
                                                 <span>
                                                     Week(s)
                                                 </span>
                                               </div>
                                            </div>

                                            <div className="leadtime-icon-style">
                                               {
                                                    quote.leadTimeState === "down" ?
                                                        <InlineIcon
                                                            className="unitPrice-icon">
                                                            <DownSrc />
                                                        </InlineIcon>
                                                        :
                                                        quote.leadTimeState === "up" ?
                                                        <InlineIcon
                                                            className="unitPrice-icon">
                                                            <UpSrc />
                                                        </InlineIcon>
                                                        :
                                                        quote.leadTimeState === "noChange" ?
                                                        <InlineIcon
                                                            className="unitPrice-icon">
                                                            <SyncedSrc />
                                                        </InlineIcon>
                                                        :
                                                        ""
                                                }
                                            </div>
                                        </div>
                                        </td>

                                        <td>
                                            <div
                                                type="text"
                                                name="lastUpdated"
                                                autoComplete="off"
                                                className="lastUpdated"
                                                data-tip={quote.inputs.lastUpdated.message}
                                                data-type="error"
                                            >
                                            {quote.inputs.lastUpdated.value}
                                            </div>
                                        </td>

                                        <td className="remove-col">
                                            <InlineIcon onClick={(e) => this.remove(i)}>
                                                <DeleteSrc />
                                            </InlineIcon>
                                        </td>
                                    </tr>
                                return row
                            })
                        }
                        <tr
                            className={"add-row " + (this.props.distClass + 'add-quote ')}
                            onClick={() => this.add()}
                            >
                            <td>
                                <div>
                                    <InlineIcon tooltip={"Add new quote"} tooltipPlace="top">
                                        <AddSrc />
                                    </InlineIcon>
                                </div>
                            </td>
                            <td>
                                <span className="add-row-text">
                                    Add Row
                                </span>
                            </td>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                    </tbody>
                </table>
            </div>
        return markup
    }
}

export default Quotes
