import "./index.scss"
import React, { Component }     from "react"
import DropZone                 from "react-dropzone"
import Config                   from "../../../modules/config"
import Icon                     from "../icon"
import closeSrc                 from "../../../assets/icons/close.svg"
import API                      from "../../../modules/api"

export class ImageUploader extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.onFileChange = this.onFileChange.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.isProcessedAllImages = this.isProcessedAllImages.bind(this)
        this.resetState = this.resetState.bind(this)
        this.setImageObject = this.setImageObject.bind(this)

        this.state =
        {
            uploaded: false,
            inputs:
            {
                avatar :
                {
                  class : false ? "filled" : "",
                  style :
                  {
                      backgroundImage : "url('')"
                  }
                }
            },
            images: []
        }

    }

    componentWillMount()
    {
        if (this.props.imageId){
            this.setImageObject(this.props.imageId)
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.imageId && typeof nextProps.imageId !== "string" && !Array.isArray(nextProps.imageId)){
            this.setImageObject(nextProps.imageId)
            this.setState(this.state)
        }
        else if(nextProps.imageId == null || nextProps.imageId == []){
            this.resetState()
        }
    }

    setImageObject(image){
        let state = this.state
        image.info = {
            id: image._id,
            processFlag: false,
            errors: []
        }
        state.inputs.avatar.class = "filled"
        state.inputs.avatar.style = { backgroundImage: "url('"+ image.src +"')" }
        state.uploaded = false
        state.images.push(image)
        this.setState(state)
    }

    resetState()
    {
        let state = this.state
        state.images = []
        state.inputs.avatar.class = ""
        state.inputs.avatar.style.backgroundImage = ""
        this.setState(state)
    }

    onFileChange(files)
    {
        if ( files.length > 0 )
        {
            let file = files[0]
            file.info = {
                id: null,
                processFlag: false,
                errors: []
            }
            let state = this.state
            state.images = []
            this.setState(state)
            state.inputs.avatar.class = "filled"
            state.inputs.avatar.style = { backgroundImage: "url('"+ file.preview +"')" }
            state.images.push(file)
            this.setState(state)
            this.uploadImage()
        }

    }

    isProcessedAllImages()
    {
        let files = this.state.images
        let valid = true
        if (files && Array.isArray(files))
        {
            files.forEach(function(file)
            {
                if(file.info.processFlag === true)
                {
                    valid = false
                    return valid
                }
            })
        }
        return valid
    }

    getImageId()
    {
        let imageId = null
        let image = this.state.images[0]
        if(image.info.id)
        {
            imageId = image.info.id
        }
        return imageId
    }

    getErrors()
    {
        let errors = []
        let files = this.state.images
        files.forEach((file) => {
            if (!file.info.id && file.info.errors.length > 0)
            {
                errors.push({file: file.name, errors: file.info.errors})
            }
        })
        return errors
    }

    uploadImage()
    {
        this.props.onChange([], [], false)
        let images = this.state.images
        this.state.uploaded = false
        let canceledImagesCount = 0
        for (let img of images)
        {
            if (!img.info.id && img.info.errors.length === 0)
            {
                img.info.processFlag = true
                this.setState({images: images})
                API.images.create(img, (err, res) =>
                {
                    if(err)
                    {
                        img.info.errors = err.errors
                        canceledImagesCount++
                    }
                    else
                    {
                        img.info.id = res
                    }
                    img.info.processFlag = false
                    this.setState({images: images, uploaded: true})

                    if (this.isProcessedAllImages())
                    {
                        this.props.onChange && this.props.onChange(this.getImageId(), this.getErrors(), true)
                        if (this.getErrors().length > 0)
                        {
                            this.resetState()
                        }
                    }
                })
            }
        }
    }

    onRemoveClick()
    {
        this.resetState()
        this.props.onChange(null, [], true)
    }

    render()
    {
        let disabledClazz = this.props.disabled ? " disabled" : ""
        const maxFileSize = Config.MAX_FILE_SIZE;

        let markup =
            <div>
                <DropZone
                    className={"image-block " + this.state.inputs.avatar.class + disabledClazz}
                    activeClassName="accept-files"
                    rejectClassName="reject-files"
                    onDrop={this.onFileChange}
                    multiple={false}
                    accept={"image/*"}
                    maxSize={maxFileSize}
                    >
                    <div>
                        <label>{this.props.label} {this.isProcessedAllImages() ? "" : "(Uploading...)"}</label>
                        <div className="display-block">
                            <div className="copy">{this.props.placeholder}</div>
                            <div className="image" style={{...this.state.inputs.avatar.style}}></div>
                        </div>
                        <div className="drag-block">
                            <span>Drag File</span>
                            <span>or Browse</span>
                            <div className="modal"></div>
                        </div>
                    </div>
                </DropZone>
                <div
                    className={"rm-btn-dz remove-btn" + this.state.inputs.avatar.class}
                    onClick={this.onRemoveClick}
                    >
                    <Icon src={closeSrc} />
                </div>
            </div>

        return markup
    }
}


const mapStateToProps = state => ({
  maxFileSize: state.company.maxFileSize
})

export default ImageUploader
