import "./index.scss"
import React           from "react"
import ModalBox        from "../../../../ui/modal-box"
import NewUserModalBox from "../../users/user-modal-box"
import Utils           from "../../../../../modules/utils"
import {connect}       from "react-redux";
import USERS           from "../../../../../action-types/users"
import buildAction     from "../../../../../helpers/buildAction"


export class AddExistingUsersModal extends React.Component
{
    constructor(props, context)
    {
        super(props, context)
        this.openModal  = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
        this.displayAddNewUser = this.displayAddNewUser.bind(this)
        this.closeAddNewUserModal = this.closeAddNewUserModal.bind(this)
        this.addToSelectedList = this.addToSelectedList.bind(this)
        this.addUsersToGroup = this.addUsersToGroup.bind(this)
        this.resetStateValues = this.resetStateValues.bind(this)

        this.state          = {
            openModal: true,
            addNewUser: false,
            searchText: '',
            selectedUsers: []
        }
    }

    addToSelectedList(event){
      let {selectedUsers} = this.state

      let index;
      if (event.target.checked) {
        selectedUsers.push(event.target.value)
      } else {
        index = selectedUsers.indexOf(event.target.value)
        selectedUsers.splice(index, 1)
      }

      this.setState(this.state)
    }

    addUsersToGroup(){
      let groupName     = this.props.groupName
      let {dispatch}    = this.props

      let payload = {
        data: {userIdList: this.state.selectedUsers, groupName: groupName.toUpperCase()}
      }
      dispatch(buildAction(USERS.ADD_TO_GROUP, payload))
      this.closeAddNewUserModal()
    }

    openModal()
    {
        this.state.openModal = true
        this.resetStateValues()
        this.setState(this.state)
    }

    closeModal()
    {
      this.state.openModal = false
      this.resetStateValues()
      this.setState(this.state, this.props.onClose)
    }

    resetStateValues(){
      this.state.openModal = false
      this.state.searchText = ''
      this.state.selectedUsers = []
    }

    displayAddNewUser()
    {
      this.state.addNewUser = true
      this.state.openModal  = false
      this.setState(this.state)
    }

    closeAddNewUserModal()
    {
      this.state.addNewUser = false
      this.setState(this.state, this.props.onClose)
    }

    onInputChange(e)
    {
      this.state.searchText = e.target.value
      this.setState(this.state)
    }

    render()
    {
        let displayModal = this.props.displayModal
        let groupName     = this.props.groupName
        let { users }     = this.props.users
        let { searchText } = this.state

        let userRows = users.map((user, i) =>
        {
          if( user.name.toLowerCase().match(searchText.toLowerCase()))
          {
            let disabledClass = user.groups.indexOf(groupName.toUpperCase()) < 0 ?
                          '' : 'cross-checked'
            let row  =
              <div key={i} className="list">
                  <div className="title-area">
                    <p>{Utils.capitalizeString(user.name)}</p>
                    <p className="muted-text">{user.email}</p>
                  </div>
                  <div className="selector">
                      <input
                        type="checkbox"
                        name="group"
                        value={user._id}
                        className={disabledClass}
                        onClick={this.addToSelectedList}
                      />
                      <label
                        htmlFor="group"
                        className={disabledClass}
                      />
                  </div>
              </div>
            return row
          }
        })

        let markup =

        <div className="add-users-modal">

          {
              this.state.addNewUser ?
              <NewUserModalBox
                onClose={this.closeAddNewUserModal}
                displayModal={this.state.addUser}
                displayModal={displayModal}
                history={this.props.history}
                onCancel={this.closeAddNewUserModal}
                groupName={groupName.toLowerCase()}
              />
              : ""
          }

          {
            this.state.openModal ?
            <ModalBox
                onClose={this.closeModal}
                history={this.props.history}
                className='existing-users-modal'
            >
              <h1>Add users to this group</h1>
              <div className="muted-text group-name">
                ({groupName})
              </div>

              <input
                type="text"
                name="name"
                placeholder="Enter name of existing user"
                value={this.state.searchText}
                onChange={this.onInputChange}
                autoComplete="off"
              />


              <hr/>
              <div className={"user-list " + (userRows.length > 0 ? "large" : "")}>
                  {
                    searchText.length > 0 ?
                    userRows : ""
                  }
              </div>

              <div className="button-section">
                <button
                    type="button"
                    value="cancel"
                    onClick={this.closeModal}
                    className="empty">
                  CANCEL
                </button>

                <input
                    type="submit"
                    value="ADD USERS"
                    onClick={this.addUsersToGroup}
                    className={this.state.selectedUsers.length > 0 ? '' : 'disabled'}
                />
              </div>

              <span className="muted-text">
                Invite new user to join this group?
              </span>
              <span
                className="green"
                onClick={this.displayAddNewUser}
                >
                Create new user account.
              </span>


            </ModalBox>
            : ""
          }
        </div>
        return markup
    }
}

export default connect((store) => store)(AddExistingUsersModal)
