import { ChangeEvent, FC, FormEvent, useCallback, useContext, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Typography,
  styled,
} from "@mui/material";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { FormModal } from "common/components/modals";
import { ButtonVariants } from "common/constants";
import { colorPalette } from "@duro/themes";
import { CadSettingsModalContext, FileNameFormatSettingsDefault } from "./cadSettingsModalContextProvider";
import { FileNameFormatSettings } from "./formatSettingsSection";
import { useGenerateCheckboxesGroup, useGenerateRadioButtonGroups } from "./hooks";
import { useOnSubmit } from "./useOnSubmit";

const modalStyles = { width: "40.625rem" };

interface ICadSettingsModal {
  onModalClose: () => void;
  open: boolean;
}

export interface IRadioButtonsData {
  title: string,
  options: string[],
  value: string,
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void,
}

export interface CheckboxItem {
  label: string,
  value: boolean,
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void,
}
export interface ICheckboxesData {
  title: string,
  itemList: CheckboxItem[],
}

export const CadSettingsModal: FC<ICadSettingsModal> = ({ onModalClose, open }) => {
  const {
    getAllowedRevisionType,
    getEidToFileNameValue,
    getFileNameFormatSettingsData,
    getOverwriteFileNameValue,
    getProcurement,
    setAllowedRevisionTypeValue,
    setEidToFileNameValue,
    setFileNameFormatSettingsData,
    setOverwriteFileNameValue,
    setProcurementValue,
  } = useContext(CadSettingsModalContext);

  const onSubmit = useOnSubmit(onModalClose);

  const radioButtonsData: IRadioButtonsData[] = useMemo(() => (
    [
      {
        title: "Allowed Design Revision Types",
        options: ["ALPHA", "NUMERIC", "ALPHA_NUMERIC"],
        value: getAllowedRevisionType(),
        handleOnChange: (event: ChangeEvent<HTMLInputElement>) => (
          setAllowedRevisionTypeValue((event.target as HTMLInputElement).value)
        ),
      },
      {
        type: "RADIO",
        title: "Procurement Default",
        options: ["MAKE", "BUY"],
        value: getProcurement(),
        handleOnChange: (event: ChangeEvent<HTMLInputElement>) => (
          setProcurementValue((event.target as HTMLInputElement).value)
        ),
      },
    ]
  ), [
    getAllowedRevisionType,
    getProcurement,
    setAllowedRevisionTypeValue,
    setProcurementValue,
  ]);

  const checkboxesData: ICheckboxesData[] = useMemo(() => (
    [
      {
        title: "File Naming Options",
        itemList: [
          {
            label: "Overwrite filename with CPN",
            value: getOverwriteFileNameValue(),
            handleOnChange: (event: ChangeEvent<HTMLInputElement>) => (
              setOverwriteFileNameValue(event.target.checked)
            ),
          },
          {
            label: "Set EID to filename",
            value: getEidToFileNameValue(),
            handleOnChange: (event: ChangeEvent<HTMLInputElement>) => (
              setEidToFileNameValue(event.target.checked)
            ),
          },
        ],
      },
    ]
  ), [getEidToFileNameValue, getOverwriteFileNameValue, setEidToFileNameValue, setOverwriteFileNameValue]);

  const checkboxesSection = useGenerateCheckboxesGroup(checkboxesData);
  const radioButtonSection = useGenerateRadioButtonGroups(radioButtonsData);

  const {
    currentType,
    customScheme: {
      customFieldError,
      customSchemeData,
    },
    findAndReplaceFields: { findAndReplaceFieldsData },
  } = useMemo(() => (getFileNameFormatSettingsData()), [getFileNameFormatSettingsData]);

  const handleOnSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newData = {
      library: {
        activeReleaseNamingScheme: currentType,
        allowedDesignRevisionTypes: getAllowedRevisionType(),
        customReleaseNamingScheme: customSchemeData,
        findAndReplaceReleaseNamingSchemes: findAndReplaceFieldsData,
        overwriteFilenameWithCpn: getOverwriteFileNameValue(),
        procurementDefault: getProcurement(),
        setEidToFilename: getEidToFileNameValue(),
      },
    };
    onSubmit(newData);
  }, [
    currentType,
    customSchemeData,
    findAndReplaceFieldsData,
    getAllowedRevisionType,
    getEidToFileNameValue,
    getOverwriteFileNameValue,
    getProcurement,
    onSubmit,
  ]);

  const resetToDefault = useCallback(() => {
    setAllowedRevisionTypeValue("ALPHA_NUMERIC");
    setEidToFileNameValue(false);
    setFileNameFormatSettingsData(FileNameFormatSettingsDefault);
    setOverwriteFileNameValue(false);
    setProcurementValue("MAKE");
  }, [
    setAllowedRevisionTypeValue,
    setEidToFileNameValue,
    setFileNameFormatSettingsData,
    setOverwriteFileNameValue,
    setProcurementValue,
  ]);

  const ActionSection = useMemo(() => (
    <>
      <Button
        onClick={resetToDefault}
        startIcon={<ReplayRoundedIcon />}
        variant={ButtonVariants.TEXT}
      >
        Reset to Default
      </Button>
      <Button onClick={onModalClose} variant={ButtonVariants.OUTLINED} color="info">
        Cancel
      </Button>
      <Button
        color="primary"
        disabled={customFieldError}
        type="submit"
        variant={ButtonVariants.OUTLINED}
      >
        Save
      </Button>
    </>
  ), [customFieldError, onModalClose, resetToDefault]);

  const ModalHeader = useMemo(() => (
    <Typography>CAD Settings</Typography>
  ), []);

  return (
    <FormModal
      actionSection={ActionSection}
      modalProps={modalStyles}
      onClose={onModalClose}
      onSubmit={handleOnSubmit}
      open={open}
      title={ModalHeader}
    >
      <Box>
        <FormControl>
          <FileNameFormatSettings />
          <StyledDivider />
          {checkboxesSection}
          {radioButtonSection}
        </FormControl>
      </Box>
    </FormModal>
  );
};

export const StyledDivider = styled(Divider)({
  borderColor: colorPalette.jaguar,
  margin: "0.5rem 0 1.5rem",
});
