import { restClient } from "utils/api";

export interface CreateNewChangeOrderRequest {
  type: string;
  name: string;
  status: string;
  resolution: string;
  approverList: ApproverList[];
}

export interface ApproverList {
  user: string;
}

export async function createNewChangeOrder(userId: string) {
  const response = await restClient.post("/changeorders", {
    type: "ECO",
    name: "",
    status: "DRAFT",
    resolution: "NONE",
    approverList: [
      {
        user: userId,
      },
    ],
  });

  return response?.data?.data ?? ""
}
