import "./index.scss"
import React, {Component} from "react"
import ProgressBar        from './progressBar.js';

class ProgressBarComponent extends Component
{
    constructor(props)
    {
        super(props)
    }

    render()
    {
        var containerStyle = {
            width: '159px',
            height: '159px'
        };

        var options = {
            step: function(state, circle) {
                circle.path.setAttribute('stroke', "#3CD1B5");
                circle.path.setAttribute('stroke-width', state.width);
                var value = Math.round(circle.value() * 100);
                if (value === 0) {
                circle.setText('');
                } else {
                circle.setText("<span class='percentage-text'>" + value +"</span>" + "<span class='percentage-sign'>%</span>");
                }

            }

        };

        let percentage = this.props.percentage > 1 ? 1 : this.props.percentage
        let progressBarText = this.props.text ? this.props.text : "IMPORTING FILE"
        let msg = this.props.msg ? this.props.msg : "please wait..."
        return(
            <div className="progress-bar-holder">
                <ProgressBar.Circle
                progress={percentage}
                options={options}
                initialAnimate={true}
                containerStyle={containerStyle}
                containerClassName={'progressbar-container'}
                />
                <div className="progress-bar-text-holder">
                    <h2>
                        {progressBarText}
                    </h2>
                    <span>{msg}</span>
                </div>
            </div>)
    }

}

export default ProgressBarComponent
