import { SuperComponent } from "design/models/changeorder";
import {
  ClientModulesSchemaValidationResult,
  ComponentsExistsQueryResponse,
  FoundChangeOrder,
  ProductsExistsQueryResponse,
  ValidationErrorType,
} from "../validation.types";
import { getRowsByComponentType, getRowsByProductType } from "../filters";
import {
  CHECK_MULTIPLE_CO_COMPONENTS_QUERY,
  CHECK_MULTIPLE_CO_PRODUCTS_QUERY,
} from "graphql/query/changeOrdersQueries";
import { client } from "graphql/apolloClient";
import { getLastURLPath, isIdPath } from "utils/window";

export async function createExistingChangeOrderValidator(
  data: SuperComponent[]
): Promise<(item: SuperComponent) => ClientModulesSchemaValidationResult> {
  const coId = getLastURLPath();

  if (!isIdPath(coId) && coId !== "order") {
    throw new Error("Cannot validate change orders without a change order ID");
  }

  const products = getRowsByProductType(data);
  const components = getRowsByComponentType(data);

  const filterClosedChangeOrders = (changeOrders: FoundChangeOrder[] | null) =>
    (changeOrders ?? []).filter(
      (co) => co.id !== coId && co.status !== "CLOSED"
    );

  const componentQueryPromise =
    components.length > 0
      ? client.query<ComponentsExistsQueryResponse>({
          query: CHECK_MULTIPLE_CO_COMPONENTS_QUERY,
          variables: {
            ids: components.map((item) => item.oid),
          },
          fetchPolicy: "no-cache",
        })
      : Promise.resolve(null);

  const productQueryPromise =
    products.length > 0
      ? client.query<ProductsExistsQueryResponse>({
          query: CHECK_MULTIPLE_CO_PRODUCTS_QUERY,
          variables: {
            ids: products.map((item) => item.oid),
          },
          fetchPolicy: "no-cache",
        })
      : Promise.resolve(null);

  const [componentChangeOrders, productChangeOrders] = await Promise.all([
    componentQueryPromise,
    productQueryPromise,
  ]);

  const cmpExistingCos = componentChangeOrders?.data?.componentsByIds ?? [];
  const prdExistingCos = productChangeOrders?.data?.productsByIds ?? [];

  const componentChangeOrderMap = new Map(
    cmpExistingCos.map((component) => [
      component.id,
      filterClosedChangeOrders(component.changeOrders),
    ])
  );

  const productChangeOrderMap = new Map(
    prdExistingCos.map((product) => [
      product.id,
      filterClosedChangeOrders(product.changeOrders),
    ])
  );

  return function validateMultipleCO(item: SuperComponent) {
    const changeOrders =
      item.alias === "CMP"
        ? componentChangeOrderMap.get(item?.oid ?? "")
        : item.alias === "PRD"
        ? productChangeOrderMap.get(item?.oid ?? "")
        : [];

    const hasDuplicateChangeOrders = changeOrders && changeOrders.length > 0;

    return {
      value: item.id,
      message: hasDuplicateChangeOrders
        ? `Duplicate change order found for ${item.cpn.displayValue}`
        : "",
      class: "",
      valid: !hasDuplicateChangeOrders,
      type: ValidationErrorType.MultipleCO,
      duplicateChangeOrders: changeOrders,
      children: [],
    };
  };
}
