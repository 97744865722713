import "./index.scss";
import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Component } from "react";
import Utils                from "../../../modules/utils";
import Query                from "../../../modules/query";
import InlineIcon           from "../icon/inline-icon.js";
import SearchIcon           from "../../../assets/icons/search.js";
import ProductIcon          from "../../../assets/icons/product-icon";
import ComponentIcon        from "../../../assets/icons/ComponentIcon";
import ChangeOrderIcon      from "../../../assets/icons/triangle-icon";
import API                  from "../../../modules/api";
import InlineImageViewer    from "../../page/common/inline-image-viewer/view";
import Config               from "../../../modules/config";
import EllipsisTooltip      from "../../page/common/ellipsis-tooltip";
import PerfectScrollbar     from 'react-perfect-scrollbar'
import Tooltip              from "rc-tooltip";

class Search extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            loading      : false,
            initialState : true,
            value        : "",
            results      : [],
            focused      : false,
            index        : null,
            timeOutID    : 0
        }

        this.initialState       = Utils.clone(this.state)
        this.update             = this.update.bind(this)
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this)
        this.onSearchInputBlur  = this.onSearchInputBlur.bind(this)
        this.search             = this.search.bind(this)
        this.onKeyUp            = this.onKeyUp.bind(this)
        this.blur               = this.blur.bind(this)
        this.gotoItem           = this.gotoItem.bind(this)
        this.gotoSearch         = this.gotoSearch.bind(this)
        this.gotoSearchAll      = this.gotoSearchAll.bind(this)
        this.parseResult        = this.parseResult.bind(this)
        this.handleArrowEvent   = this.handleArrowEvent.bind(this);
        this.getToolTip         = this.getToolTip.bind(this);
        this.imagesWithSrc      = [];
        this.restrictedRoutes   = ["/search", "/components", "/products"]

    }

    update(cb)
    {
        if(this.updater.isMounted(this))
        {
            this.setState(this.state, cb)
        }
        if (this.props.isVariantModal)
        {
            this.setState(this.state, cb)
        }
    }

    onSearchInputFocus(event)
    {
        event.target.focus();
        document.addEventListener("keyup", this.onKeyUp)
        let state = this.state
        state.focused = true
        if(this.props.isVariantModal)
        {
            this.props.hideCompareIcon(true);
        }
        this.state = state
        this.update()
    }

    onSearchInputBlur()
    {
        // TODO: blur event listener is called before clickthrough can fire
        // this may be solved by using a react auto-complete dropdown component
        setTimeout(() =>
        {
            document.removeEventListener("keyup", this.onKeyUp)
            this.blur()
        }, 300)
    }

    search(event, forceSearch=false)
    {
        let value = event && (event.target.value || "")
        let state = this.state
        state.value = value
        this.state = state
        this.update(() =>
        {
            let query = Query.parse(value ? value : "" );
            if(this.props.alias === "prd") {
                query.type = "prd";
                if(!value) {
                    query.alias = "prd"
                    query.archived = false;
                }
            }
            else if(this.props.alias === "cmp")
            {
                query.type = "cmp"
                query.category = this.props.category;
                query.fromVariantModal = true;
                if(!value) {
                    query.categoryFilter = true;
                }
            }
            if(!query.type) query.type = "all"
            query.limit = 10
            query.lean = true
            let that    = this
            clearTimeout(this.state.timeOutID);
            this.state.timeOutID = setTimeout(function(){
                that.setState({loading: true, initialState: false})
                API.search(query, (err, data) =>
                {
                    if(err)
                    {
                        data.results = []
                    }
                    let state     = that.state
                    state.results = data.results
                    state.loading = false
                    that.update()
                    if (state.value && state.value.trim())
                    {
                        let payload = {
                            searchString: state.value,
                            searchSource: "Global search box",
                            resultsCount: data.count
                        }
                        API.services.recordSearch(payload)
                    }
                })
            }, 800);
        })
    }
    onKeyUp(event)
    {
        let { results, index } = this.state;

        if(event.key === "Escape")
        {
            this.state = this.initialState
            this.update(this.blur)
            return
        }

        if(event.key === "Enter")
        {
            if(results.length === 0 || this.state.value === "")
            {
                return
            }

            if(typeof index === "number" && this.props.isVariantModal)
            {
                this.props.AddToVariantGroup(results[index])
                return
            }

            if(typeof index === "number")
            {
                this.gotoItem(index)
                // this.state = this.initialState
                this.update(this.blur)
                return
            }


            this.gotoSearch()
            this.update(this.blur)
            return
        }

        if(event.key === "ArrowDown")
        {
            if(results.length < 1) return;
            this.handleArrowEvent(-1, 1);
            return;
        }

        if(event.key === "ArrowUp")
        {
            if(results.length < 1) return;
            this.handleArrowEvent(results.length, -1);
            return;
        }
    }

    handleArrowEvent(indexValue, nextValue)
    {
        let { results, index } = this.state;
        if(typeof index !== "number") index = indexValue;
        let min  = 0;
        let max  = results.length - 1;
        let next = index + (nextValue);
        if(next < min) next = max;
        if(next > max) next = min;
        let state = this.state
        state.index = next;
        this.state = state;
        this.update();
        return;
    }

    blur()
    {
        Utils.blur()
        let state = this.state
        state.focused = false
        if(this.props.isVariantModal)
        {
            this.props.hideCompareIcon(false);
        }
        state.index   = null
        this.state = state
        this.update()
    }

    gotoItem(i)
    {
        this.blur()
        let item = this.parseResult(this.state.results[i])
        this.props.history.push({pathname: item.link, saveResults: true})
    }

    gotoSearch()
    {
        const { value } = this.state;
        document.removeEventListener("keyup", this.onKeyUp)
        this.props.history.push({ pathname: Utils.selectSearchRoute(value), state: { query: value } })
    }

    gotoSearchAll()
    {
        document.removeEventListener("keyup", this.onKeyUp)
        this.props.history.push({pathname: "/search", state: {query: ""}})
    }

    parseResult(result)
    {
        result = Utils.clone(result)
        let path = window.__userRole === 'VENDOR' ? 'revision' : 'view'

        switch(result.alias)
        {
            case "prd" :
            {
                result.displayType = this.getToolTip("Product");
                result.link        = `/product/${path}/${result._id}`
                result.displayLink = Utils.getCpn(result);
                break
            }
            case "cmp" :
            {
                result.displayType = this.getToolTip("Component");
                result.link        = `/component/${path}/${result._id}`
                result.displayLink = Utils.getCpn(result);
                break
            }
            case "co" :
            {
                result.displayType = this.getToolTip("Change Order");
                result.link        = `/changeorder/view/${result._id}`
                result.displayLink = result.con
                break
            }
            default :
            {
                // noop
            }
        }

        result.eid = result.eid || ""

        return result
    }

    getToolTip(type)
    {
        let overlay = type;
        let dataTip = type;
        let displayIcon = null;
        switch(type)
        {
            case "Product":
                displayIcon = <ProductIcon />;
                break;
            case "Component":
                displayIcon = <ComponentIcon />;
                break;
            case "Change Order":
                dataTip = "changeorder";
                displayIcon = <ChangeOrderIcon />;
                break;
            default:
                break;
        }

        return (<Tooltip overlay={overlay} placement="top" overlayClassName={"type-container default-styles"}>
                   <div className="type-icon" data-tip={dataTip} data-for="item-type-tooltip">
                    <InlineIcon className="item-icon">
                        {displayIcon}
                    </InlineIcon>
                    </div>
                </Tooltip>);
    }

    componentWillReceiveProps(nextProps) {
      if (
            nextProps.location.pathname === "/search" ||
            nextProps.location.pathname !== this.props.location.pathname )
      {
        if (!nextProps.location.saveResults)
            this.setState(this.initialState)
      }
    }

    componentWillMount()
    {
        if (this.props.isVariantModal)
        {
            this.search(null, true);
        }
    }
    render()
    {
        if (this.restrictedRoutes.includes(this.props.location.pathname)) {
            return null
        }

        if(this.props.isVariantModal && this.props.updateSearchResults)
        {
            this.search(null, true);
            this.props.updateSearchResultsFlag();
        }

        let results = this.state.results

        let variants = this.props.variants ? this.props.variants : null;
        let variantsIds = [];
        if(this.props.isVariantModal && variants)
        {
            variants.forEach((variant) =>
            {
                variantsIds.push(variant._id);
            });
        }

        let searchRowClazz = "search-row"
            + (this.state.focused ? " focused" : "")
            + (this.state.value   ? " active"  : "")

        let markup =

            <div className={searchRowClazz}>
            <div className="search-input" ref="node">
                <div className="search-bar">
                    <div onClick={this.gotoSearchAll}>
                        <InlineIcon>
                            <SearchIcon/>
                        </InlineIcon>
                    </div>
                    {this.props.alias && this.state.focused ?
                        this.props.alias === 'prd' ?
                            <span className="variant-search-prefix"> {`type:${this.props.alias}`} </span>
                            :<span className="variant-search-prefix"> {`cat:${this.props.category}`} </span>
                        : null
                    }
                    <input
                        ref="input"
                        type="text"
                        placeholder={this.props.placeholder ? this.props.placeholder: "Enter a Search Term or Query"}
                        onClick={this.onSearchInputFocus}
                        onBlur={this.onSearchInputBlur}
                        onChange={this.search}
                        value={this.state.value}
                    />
                </div>
                <div className="search-results">
                {
                    this.state.initialState !== false || !this.state.focused ? null :
                    <PerfectScrollbar className="search-results-scroll">
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                {this.props.isVariantModal ? <th>CPN / Name</th> : <th>ID / Name</th>}
                                <th>Images</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>EID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                results.length > 0 ?
                                    results.map((result, i) =>
                                    {
                                        result = this.parseResult(result)

                                        let row =
                                            <tr
                                                key={i}
                                                className={`${i === this.state.index ? "focused" : ""} ${ (result.variantGroup || result._id === this.props.currentObjectId || variantsIds.includes(result._id)) && this.props.isVariantModal ? "existing-variants" : ""}`}
                                                onClick={() => this.props.isVariantModal && (result._id !== this.props.currentObjectId && !result.variantGroup && !variantsIds.includes(result._id)) ? this.props.AddToVariantGroup(result) : !this.props.isVariantModal ? this.gotoItem(i) : ""}
                                                >
                                                <td>{result.displayType}</td>
                                                <td>
                                                    <span>{result.displayLink}</span>
                                                    <EllipsisTooltip
                                                        index={i}
                                                        title={result.name}
                                                        classes={"ellipsis-tooltip"}
                                                        innerClasses={"text-block"} >
                                                        {result.name}
                                                   </EllipsisTooltip>
                                                </td>
                                                <td className='inline-image-viewer'>
                                                  <div className='text-block'>
                                                    <div className='table-image-viewer-wrapper' style={{justifyContent: 'start'}}>
                                                      <InlineImageViewer key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} imagesWithSrc={this.imagesWithSrc} images={result.images} defaultImageClass='white-background'/>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td><EllipsisTooltip
                                                    index={i}
                                                    title={result.description}
                                                    classes={"ellipsis-tooltip"}
                                                    innerClasses={"text-block"}
                                                    twoLines={true} >
                                                    {result.description}
                                                    </EllipsisTooltip>
                                                </td>
                                                <td>{result.status}</td>
                                                <td><EllipsisTooltip
                                                    index={i}
                                                    title={result.eid}
                                                    classes={"ellipsis-tooltip"}
                                                    innerClasses={"text-block"} >
                                                    {result.eid}
                                                    </EllipsisTooltip>
                                                </td>
                                            </tr>

                                        return row
                                    })
                                :
                                <tr className="footer-row">
                                    <td colSpan="6">
                                        {
                                            this.state.loading === true ?
                                            "Searching..."
                                            :
                                            "No results match your search."
                                        }
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    </PerfectScrollbar>
                }
                </div>
            </div>
            </div>

        return markup
    }
}

export default Search
