import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { colorPalette } from "@duro/themes";
import {
  AddComponentsBar,
  DeleteTemplateModal,
  LeadingText,
  ManageTemplatesContainer,
  ManageTemplatesModal,
  SaveUpdateTemplatesModal,
  SearchContainer,
  SearchField,
  SidebarWrapper,
  TableActionNav,
  TableActionNavHeader,
  TableActionNavItem,
  TableContainer,
} from "features/changeorders/components";
import {
  DuroVirtualList,
  DuroVirtualColumn,
} from "common/components/duro-vlist";
import { GridColDef } from "@mui/x-data-grid-pro";
import { MouseEventHandler, useMemo, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { ChangeOrderLayoutConfig, sdk } from "features/changeorders";
import { Approver, EcoDefaultApproval } from "design/models/changeorder";
import { styled } from "@mui/material";
import { Grid, ToolBarItem } from "common/components/grid";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { AutofitIcon } from "assets/icons";
import { ButtonVariants, ToolbarItemType } from "common/constants";
import { ChangeOrderUser } from "design/models/changeorder";
import DeleteSrc from "v1/assets/icons/cross-icon";
import { Fragment } from "react";
import { Circle, Save, Settings } from "@mui/icons-material";
import { CHANGE_ORDER_APPROVERS } from "graphql/query/changeOrdersQueries";
import { convertEnumToUpperToCapitalized } from "utils/enum-to-locale-text";
import { DuroButton } from "common/components";
import { storage } from "features/changeorders";
import { TemplateDropdown } from "features/changeorders/components";
import { useGetApproverTemplates } from "features/changeorders/hooks/use-get-approver-templates";
import { TemplateData } from "features/changeorders/components/approvers-options/dropdown/templates-dropdown.component";
import { mandatoryApprovers } from "features/changeorders/sdk/editor/tree/tree.logic";

export const ApproversTab = () => {
  const [requiredApprovers] = mandatoryApprovers.useStore();
  const [approvers, setApprovers] = sdk.storage.form.approverList.useStore();
  const [approvalType] = sdk.storage.form.approvalType.useStore();
  const creator = sdk.storage.form.creator.getState();
  const selectedApproversCount = approvers.length;

  const handleApprovalTypeChange: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const target = event.target as HTMLButtonElement;
    const value = target.value as EcoDefaultApproval;
    sdk.storage.form.approvalType.setState(value);
  };

  const handleDeleteAllApprovers = () => {
    setApprovers((prev) =>
      prev.filter(
        (approver) =>
          approver.id === creator.id || requiredApprovers.includes(approver.id)
      )
    );
  };

  const handleDeleteApprover = (userId: string) => () => {
    setApprovers((prev) => prev.filter((approver) => approver.id !== userId));
  };

  const approversColumns: GridColDef<Approver["user"]>[] = [
    {
      field: "user",
      headerName: "USER",
      renderCell({ row }) {
        return `${row.firstName} ${row.lastName}`;
      },
      hideable: true,
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "email",
      headerName: "EMAIL",
      renderCell({ row }) {
        return row.email;
      },
      hideable: true,
      sortable: false,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "groups",
      headerName: "GROUPS",
      renderCell({ row }) {
        return row.groups.join(", ");
      },
      hideable: true,
      sortable: false,
      minWidth: 350,
      flex: 2,
    },
    {
      field: "delete",
      headerName: "",
      renderHeader() {
        return (
          <StyledDeleteIcon onClick={handleDeleteAllApprovers} sx={{ ml: 1 }}>
            <DeleteSrc />
          </StyledDeleteIcon>
        );
      },
      renderCell({ row }) {
        if (row.id === creator?.id || requiredApprovers.includes(row.id))
          return <Fragment />;

        return (
          <StyledDeleteIcon onClick={handleDeleteApprover(row.id)}>
            <DeleteSrc />
          </StyledDeleteIcon>
        );
      },
      width: 20,
      sortable: false,
    },
  ];

  return (
    <>
      <ApprovalFormControl>
        <TableActionNavHeader>
          Add Users to Approve or Reject This Change Order.
        </TableActionNavHeader>
        <TableActionNav>
          <TableActionNavItem>
            <FormLabel sx={{ textWrap: "nowrap" }}>Approval type:</FormLabel>
            <RadioGroupWrapper aria-label="approval-type" name="approval-type">
              {Object.values(EcoDefaultApproval).map((radioType) => (
                <RadioStyle
                  disabled={requiredApprovers.length > 0}
                  key={radioType}
                  value={radioType}
                  control={
                    <Radio
                      size="small"
                      checkedIcon={<Circle />}
                      checked={approvalType === radioType}
                      onClick={handleApprovalTypeChange}
                    />
                  }
                  label={
                    <StyledRadioText>
                      {convertEnumToUpperToCapitalized(radioType)}
                    </StyledRadioText>
                  }
                />
              ))}
            </RadioGroupWrapper>
          </TableActionNavItem>
        </TableActionNav>
      </ApprovalFormControl>

      <Box height={500}>
        <Grid
          getRowId={(row) => row.id}
          apiRef={useGridApiRef()}
          name="Approvers"
          columnDefinition={approversColumns}
          data={approvers}
          enableCellFocus={false}
          toolbarItems={toolbarItems}
          autoHeight
          totalCount={
            selectedApproversCount < 2
              ? `${selectedApproversCount} Approver`
              : `${selectedApproversCount} Approvers`
          }
          pinnedColumns={{ right: ["delete"] }}
        />
      </Box>
    </>
  );
};

export const ApproversSideBar = () => {
  const [addEnabled, setAddEnabled] = useState(false);
  const creator = sdk.storage.form.creator.getState();
  const [approvers] = sdk.storage.form.approverList.useStore();
  const [displaySaveUpdateTemplatesModal] =
    sdk.storage.displaySaveUpdateTemplatesModal.useStore();
  const [displayModifyTemplatesModal] =
    sdk.storage.displayModifyModal.useStore();
  const [displayDeleteTemplateModal] =
    sdk.storage.displayDeleteTemplateModal.useStore();
  const [text, setText] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const [mandatoryTemplate] = sdk.storage.currentMandatoryTemplate.useStore();

  const defaultTemplate = useMemo(() => {
    const defaultApprovalList = [creator];
    const defaultApprovalType = EcoDefaultApproval.FIRST_IN;
    const id = "1";
    const defTemplate = {
      id: id,
      approvalType: defaultApprovalType,
      approvers: defaultApprovalList,
      templateName: "Default Template",
      externalUsers: [],
      notifiers: [],
    };

    return defTemplate;
  }, [creator]);

  const { data, error, loading } = useQuery<{
    userById: {
      primaryCompany: {
        users: ChangeOrderUser[];
      };
    };
  }>(CHANGE_ORDER_APPROVERS, {
    fetchPolicy: "cache-first",
  });

  const {
    data: templateData,
    loading: templateLoading,
    refetch,
  } = useGetApproverTemplates("cache-and-network");

  const userData = data?.userById.primaryCompany.users ?? [];
  const filtered = userData.filter((user) =>
    (user.firstName.toLowerCase() + user.lastName.toLowerCase()).includes(
      text.toLowerCase()
    )
  );

  const handleSelectDropDownItem = (
    templateId: string,
    template: TemplateData | undefined
  ) => {
    if (template) {
      const { approvalType, approvers, externalUsers, notifiers } = template;
      sdk.storage.form.approvalType.setState(approvalType);
      sdk.storage.form.approverList.setState(approvers);
      sdk.storage.coExternalNotifyUserEmails.setState(externalUsers);
      sdk.storage.coInternalNotifyUsers.setState(notifiers);
    }
  };

  const handleTemplateModal = () => {
    sdk.approvers.displaySaveUpdateTemplatesModal(true);
  };

  const handleModifyModal = () => {
    sdk.approvers.displayModifyModal(true);
  };

  const sidebarColumns: DuroVirtualColumn<ChangeOrderUser>[] = [
    {
      field: "firstName",
      headerName: "select all",
      renderCell(row) {
        return `${row.firstName} ${row.lastName}`;
      },
      width: 260,
    },
  ];

  const handleCloseDeleteTemplateModal = () => {
    sdk.storage.confirmTemplateToDelete.resetState();
    sdk.approvers.displayDeleteTemplateModal(false);
  };

  const handleCloseManageTemplatesModal = async () => {
    sdk.storage.pendingTemplatesToDelete.resetState();
    sdk.storage.confirmTemplateToDelete.resetState();
    sdk.approvers.displayModifyModal(false);
  };

  return (
    <SidebarWrapper>
      <AddComponentsBar>
        <LeadingText mt={0}>Add Approvers to Change Order</LeadingText>
        <ManageTemplatesContainer sx={{ marginBottom: "12px" }}>
          <Box sx={{ width: "80%" }}>
            {!templateLoading && (
              <TemplateDropdown
                data={templateData}
                defaultTemplate={defaultTemplate}
                onSelect={handleSelectDropDownItem}
              />
            )}
          </Box>
          <Settings onClick={handleModifyModal} />
          <Save
            sx={{ opacity: mandatoryTemplate.open ? 0.5 : 1 }}
            onClick={() => {
              if (mandatoryTemplate.open) {
                return;
              }

              handleTemplateModal();
            }}
          />
          {storage.displaySaveUpdateTemplatesModal.getState() ? (
            <SaveUpdateTemplatesModal
              open={displaySaveUpdateTemplatesModal}
              onClose={() =>
                sdk.approvers.displaySaveUpdateTemplatesModal(false)
              }
              ariaLabelledby="save-update-templates-modal"
              ariaDescribedby="save-update-templates-modal"
              refetch={refetch}
            />
          ) : null}
          {storage.displayModifyModal.getState() ? (
            <ManageTemplatesModal
              open={displayModifyTemplatesModal}
              onClose={handleCloseManageTemplatesModal}
              ariaLabelledby="modify-templates-modal"
              ariaDescribedby="modify-templates-modal"
            />
          ) : null}
          {storage.displayDeleteTemplateModal.getState() ? (
            <DeleteTemplateModal
              open={displayDeleteTemplateModal}
              onClose={handleCloseDeleteTemplateModal}
              ariaLabelledby="delete-template-modal"
              ariaDescribedby="delete-template-modal"
            />
          ) : null}
        </ManageTemplatesContainer>

        <SearchContainer>
          <SearchField
            placeholder="Enter existing user"
            onChange={(e) => setText(e.target.value)}
            value={text}
            fullWidth
          />
        </SearchContainer>
      </AddComponentsBar>
      {!loading && (
        <TableContainer ref={ref}>
          <DuroVirtualList
            columns={sidebarColumns}
            excluded={approvers}
            data={filtered}
            loading={loading}
            error={error?.message}
            rowHeight={40}
            totalCount={userData.length}
            totalHeight={window.innerHeight - 250}
            totalWidth={ChangeOrderLayoutConfig.sidebarWidth}
            onSelectionChange={(selectionIds) => {
              const selectedItems = userData.filter(
                (item) =>
                  selectionIds.includes(item.id) && item.id !== creator.id
              );
              sdk.storage.pendingApprovers.setState(() => selectedItems);
              setAddEnabled(selectedItems.length > 0);
            }}
            handleAddButtonClick={sdk.approvers.addApprovers}
            headerSlot={
              <Box
                sx={{
                  flex: "1",
                  paddingRight: "1rem",
                  position: "absolute",
                  right: "0",
                }}
              >
                <DuroButton
                  variant={ButtonVariants.OUTLINED}
                  disabled={!addEnabled}
                  onClick={() => {
                    sdk.approvers.addApprovers();
                  }}
                >
                  Add
                </DuroButton>
              </Box>
            }
          />
        </TableContainer>
      )}
    </SidebarWrapper>
  );
};

const RadioStyle = styled(FormControlLabel)({
  justifyContent: "center",
  flex: "1",
  color: colorPalette.white,
});

const RadioGroupWrapper = styled(RadioGroup)({
  display: "flex",
  flexDirection: "row",
});

const StyledRadioText = styled(Typography)({
  color: colorPalette.white,
  fontSize: "0.875rem",
});

const toolbarItems: ToolBarItem[] = [
  {
    disabled: true,
    Icon: AutofitIcon,
    label: "Autofit",
    type: ToolbarItemType.ACTION,
  },
];

const StyledDeleteIcon = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  cursor: "pointer",
});

const ApprovalFormControl = styled(FormControl)({
  width: 520,
  marginBottom: 0,
});
