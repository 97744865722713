import "./index.scss"
import React                  from "react"
import ItemCADPropertiesIcon  from "../../../../../assets/icons/vendor-label"
import TileItem               from "../../tile-item"
import LazyInput              from "../../../../ui/lazy-input/input";
import Utils                  from "../../../../../modules/utils";

const CustomProperty = ({onInputChange, keyValue, value, isVendorCmp, tooltipText, mode, index}) => {
  const isPropertyViewable = (mode === 'view');

  const valueElement = isPropertyViewable ? <span className="inner-value">{value}</span> : ( <div className={`inner-value${isVendorCmp ? ' have-input' : ''}`} data-tip={isVendorCmp ? tooltipText : null} data-for={isVendorCmp ? 'vendor-disable-input' : null}>
    <LazyInput
        type="text"
        name="customProperties"
        value={value}
        onChange={(event) => onInputChange(event, index)}
    />
  </div>)

  return <React.Fragment>
    <span className={`inner-attribute cad-properties-key`}>{keyValue}</span>
    {valueElement}
  </React.Fragment> 
}

const CustomPropertiesList = ({item, mode, isVendorCmp, tooltipText, onInputChange}) => {
  
  return  (item.customProperties.map((property, index) => {
            let {key, value} = property;
            return (key === 'Applicability' && !Utils.isApplicabilityFieldEnabled()) ? null :
            <div key={`Custom-Property-${index}`} className={`inner-info values-to-right ${`diff-${key.replace(/[^a-zA-Z ]/g, '').split(' ').join('-').toLowerCase()}-section`}`}>
              <CustomProperty
                keyValue={key}
                value={value}
                onInputChange={onInputChange}
                isVendorCmp={isVendorCmp}
                tooltipText={tooltipText}
                mode={mode}
                index={index}
              />
            </div>
          }))
}

const ItemCADProperties = ({ item, mode, isVendorCmp, tooltipText, onInputChange }) => {
    if(!Utils.shouldDisplayCadTile(item.customProperties)) return null;

    return (
      <TileItem className="cad-properties" title="CAD Properties" icon={ <ItemCADPropertiesIcon /> }>
        <CustomPropertiesList item={item} mode={mode} onInputChange={onInputChange} isVendorCmp={isVendorCmp} tooltipText={tooltipText}/>
      </TileItem>
    )
}

export default ItemCADProperties
