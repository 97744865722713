import { Checkbox, styled } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { useState } from "react";
import { Select } from "common/components/inputs";
import { DateTimePicker } from "common/components/inputs";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

const ERPOptionRow = styled("div")({
  display: "flex",
  gridGap: "2rem",
  maxWidth: "768px",
  margin: "1rem 0",
});

const ERPCheckbox = styled("div")({
  display: "flex",
  minWidth: "200px",
  alignItems: "center",
  "& > span": {
    marginRight: "1rem",
    "&:last-of-type": {
      width: "150px",
    },
  },
});

const ERPToggle = styled("div")({
  display: "flex",
  alignItems: "center",
});

export const ChangeOrderDatePickerField = ({ placeholder = "" }) => {
  return (
    <DateTimePicker
      isRequired={false}
      onChange={() => {}}
      label=""
      EnclosingTag={MuiDatePicker}
      inputFormat={"MMM D, YYYY"}
      renderInputProps={{
        placeholder,
      }}
    />
  );
};
export const ChangeOrderCheckboxField = ({ label = "" }) => {
  return (
    <ERPCheckbox>
      <Checkbox />
      <span>{label}</span>
    </ERPCheckbox>
  );
};

export const ChangeOrderERPOptions = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <ERPToggle onClick={() => setOpen(!open)}>
        <span className="erp-option-icon">
          {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </span>
        <span>ERP Options</span>
      </ERPToggle>

      {open && (
        <div className="erp-options">
          <ERPOptionRow>
            <ChangeOrderCheckboxField label="Set Effectivity" />
            <ChangeOrderDatePickerField placeholder="Start Date" />
            <ChangeOrderDatePickerField placeholder="End Date" />
            <ChangeOrderCheckboxField label="Override existing" />
          </ERPOptionRow>

          <ERPOptionRow>
            <ChangeOrderCheckboxField label="Set Item Type" />
            <Select
              options={[{ value: "ITEM TYPE A", label: "ITEM TYPE A" }]}
              value="ITEM TYPE A"
              isRequired={false}
              onChange={() => {}}
            />

            <ChangeOrderCheckboxField label="Override existing" />
          </ERPOptionRow>
        </div>
      )}
    </div>
  );
};
