import "../index.scss"
import "react-datepicker/dist/react-datepicker.css";
import React, { Component, Fragment }     from "react"
import InlineIcon               from "../../../../ui/icon/inline-icon.js"
import SerializationIcon        from "../../../../../assets/icons/serialization"
import ItemDetailsIcon          from "../../../../../assets/icons/item-details-icon"
import WebSrcIcon               from "../../../../../assets/icons/newcomp-web"
import AlertErrorIcon           from "../../../../../assets/icons/alert-error"
import WarningTriangleIcon      from "../../../../../assets/icons/warning-triangle"
import TimeStampColumn          from "./../../../common/timestamp-column"
import MassField                from "./../../../common/mass-field"
import Utils                    from "../../../../../modules/utils"
import UnitPrice                from "../../unit-price"
import ExtendedRolledUpCost     from "../../extended-rolled-up-cost"
import TileItem                 from "../../tile-item"

export class ItemDetails extends Component
{
  constructor(props)
  {
    super(props)
  }

  render()
  {
    let { item, massPrecision, isPrdOrCmpViewPage, isProductionInstance } = this.props;
    let {primarySource} = item
    let primarySourceLeadTimeValue = isProductionInstance || primarySource.leadTime.value === null ? '' : primarySource.leadTime.value
    let primarySourceLeadTimeUnits = isProductionInstance || primarySource.leadTime.units === null ? '' : primarySource.leadTime.units
    const lastModifiedBy = Utils.getLastModifiedBy(item);
    let modifiedBy = isPrdOrCmpViewPage ? lastModifiedBy : `${item.creator.firstName} ${item.creator.lastName}`;

    let markup =
      <TileItem title="Details" icon={ <ItemDetailsIcon /> }>
        { item.description && <p className="description diff-description-section">{ item.description }</p> }
        {
          !isProductionInstance && window.__userRole !== 'VENDOR' &&
          <Fragment>
            <InlineIcon className="primary-source">
              <WebSrcIcon />
              <span>primary source</span>
            </InlineIcon>
            <div className="inner-info values-to-right diff-mpn-section">
              <span className="inner-attribute">mpn</span>
              <span className="inner-value">
                {primarySource.mpn !== null && primarySource.mpn}
              </span>
            </div>
            <div className="inner-info values-to-right diff-mfr-section">
              <span className="inner-attribute">mfr</span>
              <span className="inner-value">
                {primarySource.manufacturer !== null && primarySource.manufacturer}
              </span>
            </div>
            <div className="inner-info values-to-right diff-min-qty-section">
              <span className="inner-attribute">min qty</span>
              <span
                className="inner-value"
                data-tip=""
                data-place="top"
                onMouseOver={(e) => Utils.checkToolTipAppearance(e, primarySource.minQuantity)}
              >
                {primarySource.minQuantity !== null && primarySource.minQuantity}
              </span>
            </div>
            <UnitPrice
              primarySource={primarySource}
              item={item}
              mode="VIEW"
            />
            <ExtendedRolledUpCost
              extendedCost={primarySource.extendedCost}
              object={item}
              mode="VIEW"
            />
            <div className={`inner-info${item.alias !== 'prd' ? ' last-info ' : ' '}values-to-right diff-lead-time-section`}>
              <span className="inner-attribute">lead time</span>
              <span className="inner-value">
                {`${primarySourceLeadTimeValue} ${primarySourceLeadTimeUnits}`}
              </span>
            </div>
            {
              item.alias === 'prd' &&
              <div className={`inner-info values-to-right diff-mass-cell-section`}>
                <span className="inner-attribute no-cl">{`MASS (${Utils.getCompanyMassUnit()})`}</span>
                <div className="inner-value">
                  {
                    (isPrdOrCmpViewPage && item.massStatus && item.massStatus.toUpperCase() === "ERROR") &&
                    <InlineIcon
                      className="inline-icon"
                      tooltip="Missing mass values for one or more children"
                      tooltipPlace="top"
                    >
                      <AlertErrorIcon />
                    </InlineIcon>
                  }

                  {
                    (isPrdOrCmpViewPage && item.massStatus && item.massStatus.toUpperCase() === "WARNING") &&
                    <InlineIcon
                      className="inline-icon"
                      tooltip="Manually entered value"
                      tooltipPlace="top"
                    >
                      <WarningTriangleIcon />
                    </InlineIcon>
                  }
                  <MassField
                    mass={item.mass}
                    massPrecision={massPrecision}
                  />
                </div>
              </div>
            }
            {
              item.alias === 'prd' &&
              <div className="inner-info last-info values-to-right diff-procurement-cell-section">
                <span className="inner-attribute">procurement</span>
                <span className="inner-value">
                  {item.procurement}
                </span>
              </div>
            }
            {/* <ProcurementView item={item}/> */}
            <div className="inner-info">
              <span className="inner-attribute">created</span>
              <TimeStampColumn format='date-time-with-long-format' value={item.created} />
            </div>
            <div className="inner-info">
              <span className="inner-attribute">modified</span>
              <TimeStampColumn format='date-time-with-long-format' value={item.lastModified} />
            </div>
          </Fragment>
        }
        {
          isProductionInstance &&
          <Fragment>
            <InlineIcon className="primary-source">
              <SerializationIcon />
              <span>production info</span>
            </InlineIcon>
            <div className="inner-info">
              <span className="inner-attribute">date</span>
              <TimeStampColumn format='date-time-with-long-format' value={item.productionDate} />
            </div>
            <div className="inner-info">
              <span className="inner-attribute">serial</span>
              <span className="inner-value serial" data-tip={item.serial} data-place={"top"}>{item.serial}</span>
            </div>
            <div className="inner-info last-info">
              <span className="inner-attribute">label</span>
              <span className="inner-value">{item.label}</span>
            </div>
          </Fragment>
        }
        <div className="inner-info">
          <span className="inner-attribute">{`${isProductionInstance ? 'created' : 'modified'} by`}</span>
          <span className="inner-value created-by">{ modifiedBy }</span>
        </div>
      </TileItem>
    return markup
  }
}

export default ItemDetails
