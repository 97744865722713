import { styled } from "@mui/material";
import { DuroButton } from "common/components/button";

const BetaBanner = styled("div")({
  position: "fixed",
  top: 20,
  right: 120,
  zIndex: 9999,
  width: 136,
  maxHeight: "40px !important",
});

export const BetaOptInButton = () => {
  const enabled =
    (localStorage.getItem("changeOrderBetaOptIn") ?? "false") === "true";

  function toggleBeta() {
    if (enabled) {
      localStorage.removeItem("changeOrderBetaOptIn");
    } else {
      localStorage.setItem("changeOrderBetaOptIn", "true");
    }

    window.location.reload();
  }

  return (
    <BetaBanner>
      <DuroButton onClick={() => toggleBeta()}>
        {enabled ? "Disable" : "Enable"} Beta
      </DuroButton>
    </BetaBanner>
  );
};
