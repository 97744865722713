import "./index.scss"
import React            from "react"
import {Link}           from "react-router-dom"
import {Helmet}         from "react-helmet"
import Utils            from "../../../../modules/utils"

class Policy extends React.Component
{
    render()
    {
        let markup =
            <div className="policy-route app-row">
                <Helmet>
                    <title>{Utils.makeTitle("Legal - Privacy Policy")}</title>
                </Helmet>
                <div className="main-block">

                    <div className="nav-block">
                        <Link to="/legal/terms">TERMS of USE</Link>
                        <div>PRIVACY POLICY</div>
                    </div>

                    <div className="content-block">
                        <h1>Privacy Policy</h1>
                        <date>Last Modified on October 5th, 2020</date>

                        <h2 className="section">Our Privacy Commitment</h2>

                        <p>
                            Duro Labs, Inc. (“<em>Duro Labs</em>,” “<em>we</em>,” “<em>us</em>” or “<em>our</em>”) is committed to respecting the privacy rights and concerns of all users of Duro Labs’ website where this Privacy Policy is posted (the “<em>Site</em>”) (we refer to the Site, the services, the products and the software made available by Duro Labs collectively, as the “<em>Duro Labs Features</em>”). As such, we have established and implemented this Privacy Policy to inform visitors to the Site and users of the Duro Labs Features how we use and protect the information we collect through the Site or the Duro Labs Features. By accessing the Site or using any of the Duro Labs Features, you consent to Duro Labs’ collection, use, storage, deletion and disclosure of information relating to you as set forth in this Privacy Policy. This Privacy Policy is effective as of the date set forth above and is only applicable to the Duro Labs Features and not to any other website that you may be able to access from the Site or the Duro Labs Features which may have its own data collection and use practices and policies.
                        </p>

                        <p>
                            This Privacy Policy answers the following questions:
                        </p>

                            <p className="indent">
                                a) What information do we collect about you and how do we use it?
                            </p>

                            <p className="indent">
                                b) Will we share your personal information with third parties?
                            </p>

                            <p className="indent">
                                c) What choices do you have about the collection and use of your personal information?
                            </p>

                            <p className="indent">
                                d) What security measures do we take to safeguard your personal information?
                            </p>

                        <h2 className="section">What information do we collect about you and how do we use it?</h2>

                        <h3>Types of Information We Collect</h3>

                        <p>
                            Personal information means information that can be used to identify and contact you. You do not need to submit personal information to access the Site. However, you will be required to submit personal information to access certain aspects of the Duro Labs Features. We may collect non-personal information, such as your browser type, when you accessed the Duro Labs Features and from what physical location you accessed the Duro Labs Features. This information may be aggregated to measure the number of visits, average time spent, page views, and other statistics about users of the Duro Labs Features. We also may use this data to monitor the Duro Labs Features’ performance and to make the Duro Labs Features easier and more convenient to use.
                        </p>

                        <p>
                            We collect personal information when you create an account through the Duro Labs Features; participate in registration forms, feedback forms or surveys; purchase a product or service through the Duro Labs Features; or receive customer or technical support.
                        </p>

                        <p>
                            To create an account for the Duro Labs Features, we require the person or entity that has entered into a Master Subscription Agreement with Duro Labs (the “Master Account Holder”) to provide certain information, including name, website address, subscription type, billing information, and mailing address.  To access an account that has been established for the Master Account Holder, we require you to provide your name and email address, username, and password.
                        </p>

                        <p>
                            If you use a third party service to create an account, including but not limited to Google, LinkedIn, or Facebook, we will receive personal data via that third party service but only after you have consented to that third party service sharing your personal data with us. Duro Labs limits the personal data collected from a third party service to only the minimum data required to establish a new account and authenticate your authorization to do so. Such personal data includes your name, email address, and profile image URL.
                        </p>

                        <p>
                            By submitting personal information through the Site, you authorize Duro Labs to use this personal information for the purposes identified herein and in providing the Duro Labs Features, such as responding to your inquiries, processing transactions, requesting feedback, and administering surveys.
                        </p>

                        <h3>Third Party Privacy Policies</h3>

                        <p>
                            Through the Duro Labs Features, you may be able to link to technology, software and services owned and controlled by third parties (“<em>Third Party Features</em>”). You may be permitted or required to submit personal information to access Third Party Features. Such use of Third Party Features and submission of information through Third Party Features will be subject to the applicable third party’s terms of use and/or terms of service and privacy policy. You agree to look solely to such third party and not to Duro Labs to enforce your rights in relation thereto. When you have clicked on a third party logo or URL displayed on the Site, or accessible through the Duro Labs Features, which links you to any Third Party Features, our Terms of Use no longer apply and you must read the terms of use and/or terms of service and privacy policy of the third party to see how your personal information will be handled on their website.
                        </p>

                        <h3>How We Use Your Personal Information</h3>

                        <p>
                            The personal information you provide to us will allow us to communicate with you and provide the Duro Labs Features to you, depending on the Duro Labs Features for which you register. This includes alerting you of new products or services, features, or enhancements to the Duro Labs Features; verifying your identity; handling your customer service questions or issues; requesting feedback; or administering surveys. We may also use your email address as part of the Duro Labs Features in sending you messages about the Duro Labs Features and other general announcements. We keep track of your interactions with us, including but not limited to your activity within the Duro Labs Features. Except as set forth in this Privacy Policy, or unless we have your consent, we will not share your personal information with any person or entity other than those affiliated with us, entities acting on our behalf, and relevant third parties such as those needed to collect and maintain our information collection servers and perform technology and related services.
                        </p>

                        <p>
                            We may use your personal information with non-personal information that we have collected in a manner such that the end-product does not personally identify you or any other user of the Duro Labs Features. We may make your personal information non-personally identifiable by either combining it with information about other users (aggregating your personal information with information about other users), or by removing characteristics (such as your name or email address) that make the information personally identifiable. This process is known as de-personalizing your information. You grant us a royalty-free, worldwide, perpetual, irrevocable and fully transferable right and license to use your personal information in connection with the analytical and statistical analysis of the data we collect in providing the Duro Labs Features (the “Analytical Data”). We are expressly authorized to make any commercial use of the Analytical Data including, without limitation, sharing the Analytical Data with third parties, provided that we do not sell, trade, or otherwise transfer from us any part of the Analytical Data that personally identifies any Duro Labs Features users.
                        </p>

                        <h3>Do Not Track</h3>

                        <p>
                            In compliance with California legislation, AB370, effective January 1, 2014, Duro Labs’ practices in responding to “do not track” signals and collecting user information over time and across a network of websites when you visit the Site are as follows: We honor the request of “do not track” signals sent by your browser when you visit the Site. Additionally, Duro Labs does not collect user information about your online activities over time and across different websites, or authorize third parties to do so.
                        </p>

                        <h3>Analytics Tools</h3>

                        <p>
                            Duro Labs uses Google Analytics and other analytics tools to help us learn about who visits the Site and what pages are being viewed.
                        </p>

                        <h3>Cookies</h3>

                        <p>
                            “Cookies” are small bits of electronic information that a website sends to a visitor’s browser and are stored on the visitor’s hard drive. Like many websites, we employ cookies in certain areas of the Site to allow us to provide information to make your online experience more convenient. For example, when you return to the Site after logging in, cookies provide information to the Site so that the Site will remember who you are on subsequent visits, speeding up or enhancing your experience of the Duro Labs Features. Cookies may also be used to keep track of your login name and password, and keep track of click stream data, and personalize Duro Labs Features for you. While Duro Labs may use cookies to track your visit to the Site, this information does not identify you personally and you remain anonymous, even if you have previously submitted personal information to Duro Labs through the Site or otherwise. You may still access the Site and the Duro Labs Features if you set your browser not to accept cookies.
                        </p>

                        <p>
                            Third parties whose websites are accessible through links on the Site may also use their own cookies when you link to their site or service. Duro Labs does not control these third parties’ use of cookies and cannot guarantee that they will uphold the same privacy standards as Duro Labs. Duro Labs expressly disclaims any and all liability related to the collection, use or disclosure of information by third parties. You should review the privacy policies of any third party sites that you visit through a link from the Site to understand how these third parties use cookies and the information they collect through cookies. Most cookies are “session cookies” which means that they are automatically deleted at the end of each session. Most browsers are initially set to accept cookies. You can set your browser to notify you when you receive a cookie, giving you a chance to decide whether or not to accept it.
                        </p>

                        <h3>Log Information</h3>

                        <p>
                            Our servers automatically record information that your browser sends whenever you visit the Site. This information includes your Internet Protocol address, your browser type and version, the search engine you used to find the Duro Labs Features, if any, which Duro Labs Features you use and from where, and when and how long you use them. We use this information to monitor and analyze how users use the Duro Labs Features, to provide customer service and to maintain and improve the Duro Labs Features. This information allows for more accurate reporting and improvement of the Duro Labs Features.
                        </p>

                        <h2 className="section">Will we share your personal information with third parties?</h2>

                        <h3>Sharing Your Information</h3>

                        <p>
                            We use your personal information to provide the Duro Labs Features to you, deliver products and services you request, complete transactions, handle your customer service questions or issues and send communications to you relating to your account. The personal information uploaded by you through the Duro Labs Features will be shared only with authorized persons to whom you specifically grant access. We may also transmit your personal information to third parties whose APIs and SDKs are integrated within the Duro Labs Features.  In addition, we may use your depersonalized personal information to provide analyses of our users in the aggregate (including Analytical Data subject to the terms of this Privacy Policy) to prospective partners and other third parties. We will also share the personal information we collect from you under the following circumstances, and you expressly authorize us to share your information as follows:
                        </p>

                        <p className="indent">
                            <em>Protection of rights.</em> We will share personal information if we have a good faith belief that (i) access use, preservatin or disclosure of such information is necessary to satisfy any applicable law, regulation, legal process, such as a court order or subpoena, or a request by law enforcement or governmental authorities, (ii) such action is appropriate to enforce our Terms of Use for the Duro Labs Features, including any investigation of potential violations thereof, (iii) such action is necessary to detect, prevent, or otherwise address fraud, security or technical issues associated with the Duro Labs Features, or (iv) such action is appropriate to protect the rights, property or safety of Duro Labs, its employees, users of the Duro Labs Features or others.
                        </p>

                        <p className="indent">
                            <em>Asset transfers.</em> If we become involved in a merger, acquisition or other transaction involving the sale of some or all of Duro Labs’ assets, user information, including personal information collected from you through your use of the Duro Labs Features, could be included in the transferred assets. Should such an event occur, we will use reasonable means to notify you through email and/or a prominent notice on the Site.
                        </p>

                        <p className="indent">
                            <em>Service Providers.</em> We may share your personal information with service providers (including our affiliates that provide services on our behalf) that we engage for the purpose of processing information on our and your behalf. Additionally, we may partner with other companies to process, analyze, and/or store data including, but not limited to, Analytical Data. While providing services for us, these companies may access your personal information. To the extent applicable, we require these entities to comply with this Privacy Policy and appropriate confidentiality and security measures.
                        </p>

                        <h3>Additional Information You Should Know About Third Parties</h3>

                        <p>
                            The Site may contain direct links to websites operated by our partners and other entities. These third party websites may collect information about you when you visit them or otherwise communicate with them. This Privacy Policy does not cover the information practices of third party websites linked to the Site or accessible through the Duro Labs Features. Although we encourage such third parties to adopt and follow their own privacy policies, we are not responsible for their collection and use of your personal information. You should refer to the privacy policies and statements of other websites or contact the respective webmasters of those websites to obtain information regarding their information collection, use and disclosure policies.
                        </p>

                        <h2 className="section">What choices do you have about the collection and use of your personal information?</h2>

                        <h3>Control of Your Information</h3>

                        <p>
                            You may update or delete your personal information or modify your account preferences for the Duro Labs Features by contacting us at <a href="mailto:info@durolabs.co">info@durolabs.co</a>. Please note that if you disable your account, we will keep your contact information on file, and may retain other account information for a reasonable period of time afterward for the purpose of internal account management and fraud prevention activities.
                        </p>

                        <h3>Opting Out of Receiving Communications From Us</h3>

                        <p>
                            If you no longer want to receive our emails, you may unsubscribe by writing to us at <a href="mailto:info@durolabs.co">info@durolabs.co</a>. Please note that you cannot unsubscribe from certain correspondence from us, including messages relating to your account transactions.
                        </p>

                        <h3>Mediation</h3>

                        <p>
                            You agree that, in the event any dispute or claim arises out of or relating to this Privacy Policy, you and Duro Labs will attempt in good faith to negotiate a written resolution of the matter directly between the parties. You agree that if the matter remains unresolved for forty-five (45) days after notification (via certified mail or personal delivery) that a dispute exists, all parties shall join in mediation services in Los Angeles, California with a mutually agreed mediator in an attempt to resolve the dispute. Should you file any arbitration claims, or any administrative or legal actions without first having attempted to resolve the matter by mediation, then you agree that you will not be entitled to recover attorneys’ fees, even if you would otherwise be entitled to them.
                        </p>

                        <h2 className="section">What security measures do we take to safeguard your personal information?</h2>

                        <p>
                            The personal information that you provide to us is stored on servers, which are located in secured facilities with restricted access, and protected by protocols and procedures designed to ensure the security of such information. In addition, we restrict access to personal information to Duro Labs employees, independent contractors and agents who need to know this information in order to develop, operate and maintain the Duro Labs Features. All Duro Labs personnel who have access to this information are trained in the maintenance and security of such information. However, no server, computer or communications network or system, or data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to protect user information, we cannot ensure or warrant the security of any information you transmit to us or through the use of any of the Duro Labs Features and you acknowledge and agree that you provide such information and engage in such transmissions at your own risk. Once we receive a transmission from you, we will endeavor to maintain its security on our systems.
                        </p>

                        <p>
                            In the event that personal information you provide to us is compromised as a result of a breach of security, when appropriate we will take reasonable steps to investigate the situation, notify you and take the necessary steps to comply with any applicable laws and regulations.
                        </p>

                        <h2 className="section">Notification of Changes</h2>

                        <p>
                            This Privacy Policy may change from time to time and we will post all changes on the Site.
                        </p>

                        <h2 className="section">Questions? Contact Us</h2>

                        <p>
                            If you have any questions about our privacy practices or this Privacy Policy, please contact us by email at <a href="mailto:info@durolabs.co">info@durolabs.co</a>
                        </p>

                        <h2 className="section">Terms of Use</h2>

                        <p>
                            Please read the <Link to="/legal/terms">Terms of Use</Link> governing the use of the Site and the Duro Labs Features.
                        </p>

                    </div>
                </div>
            </div>

        return markup
    }
}

export default Policy
