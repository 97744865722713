import { DuroModal, DuroModalProps } from "common/components/modals/duroModal"; 
import { Typography } from "@mui/material";
import { ButtonFooter } from "../buttonFooter.component";
import { sdk } from "features/changeorders/sdk";

export const DeleteTemplateModal: React.FC<DuroModalProps> = ({
  open,
  onClose,
  ariaLabelledby,
  ariaDescribedby,
}) => {
  const [pendingTemplatesToDelete, setPendingTemplatesToDelete] = sdk.storage.pendingTemplatesToDelete.useStore();
  const [confirmTemplateToDelete] = sdk.storage.confirmTemplateToDelete.useStore();

  const addPendingTemplateToDelete = () => {
    setPendingTemplatesToDelete((prev) => new Set(prev).add(confirmTemplateToDelete));
    sdk.storage.displayDeleteTemplateModal.setState(false);
  }
  
  return (
    <DuroModal
      open={open} 
      onClose={onClose} 
      ariaLabelledby={ariaLabelledby} 
      ariaDescribedby={ariaDescribedby}
      header="Delete Template?"
      footer={
        <ButtonFooter
          onCancelClick={onClose}
          onConfirmClick={addPendingTemplateToDelete}
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          cancelDisabled={false}
          confirmDisabled={false}
          confirmColor="error"
          cancelColor="info"
        />
      }
    >
      <Typography>Are you sure you want to delete this template?</Typography>
      <Typography>This action cannot be undone.</Typography>
    </DuroModal>
  );
}