import React, {Component} from "react"
import Loading            from "../../../../ui/inner-loading"
import Utils              from "../../../../../modules/utils"
import Schemas            from "../../../../../modules/schemas"
import ExtendedTablePaginated from "../../../common/extended-table/extended-table-paginated"
import {connect}          from 'react-redux';
import PlusIcon           from "../../../../../assets/icons/plus.js"
import InlineIcon         from "../../../../ui/icon/inline-icon.js"
import WebIcon            from "../../../../../assets/icons/newcomp-web"
import ManualIcon         from "../../../../../assets/icons/newcomp-manual"
import ImportIcon         from "../../../../../assets/icons/import-icon"
import Permissions        from "../../../../../modules/schemas/permissions"
import TimeStampColumn    from "../../../common/timestamp-column";
import InlineImageViewer  from "../../../common/inline-image-viewer/view";
import Config             from "../../../../../modules/config";
import { RevisionField } from "@duro/base/revision-field/";
import CpnField           from "../../../common/cpn-field";
import { StatusField } from "@duro/base/status-field";
import VariantModal       from "../../../common/view-action-items/variant-modal"
import Link               from "../../../../ui/link";
import VariantIconHolder  from "../../../common/variants/variant-icon-holder"
import { DuroMenu } from "../../../../../../common/components/duroMenu";

export class ComponentList extends Component
{
    constructor(props)
    {
        super(props)

        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.searchComponents || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.state =
        {
            current : (this.props.location.state && this.props.location.state.imported_ids ? "lastModified" : "cpn"),
            currentSortItemAscending: false,
            openVariantModal: false,
            primaryVariant: null,
            VariantModal: {
                top: 0,
                left: 0
            },
            headings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "Customer Part Number",
                    sortable    : true,
                    minWidth    : 65,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "eid",
                    displayName : "EID",
                    tooltip     : "External ID",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "eid", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "eid", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "eid", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "category",
                    displayName : "Category",
                    tooltip     : "Category",
                    sortable    : true,
                    minWidth    : 105,
                    width       : Utils.getStyleValue(viewStyles, "category", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "category", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "category", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 77,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "images",
                    displayName : "Images",
                    tooltip     : "Images",
                    sortable    : false,
                    ascending   : true,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "images", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "images", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "images", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 124,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 347),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : 97,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 87,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 7),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastModified",
                    displayName : "Last Modified",
                    tooltip     : "Last Modified",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "lastModified", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "lastModified", "position", 8),
                    visibility  : Utils.getStyleValue(viewStyles, "lastModified", "visibility", true),
                    disabled    : false
                }
            ]
        }
        this.getRows    = this.getRows.bind(this);
        this.getActionList = this.getActionList.bind(this);
        this.setCurrentSortBy = this.setCurrentSortBy.bind(this);
        this.closeVariantModal= this.closeVariantModal.bind(this);
    }

    setCurrentSortBy(name, ascending)
    {
        let state = this.state
        state.currentSortItemAscending = ascending
        state.current = name
        this.setState(state)
    }

    closeVariantModal(e, result)
    {
        let state = this.state;
        state.openVariantModal = false;
        state.primaryVariant = null;
        this.setState(state);
    }

    getRows()
    {
        let rows = [];
        let props             = this.props;
        let results           = props.results;
        let locationState     = props.location.state;
        let selectedSearchIds = props.preSelectedSearchResults
        let path = window.__userRole === "VENDOR" ? "revision" : "view"
        results.map((result, i) =>
        {
            let activeClass = locationState && locationState.imported_ids ? locationState.imported_ids.includes(result._id) : false;
            let lastModified        = result.lastModified ? result.lastModified : undefined;
            let lastUpdatedToolTip  = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;

            let rowSelected = false;
            selectedSearchIds.forEach((selected_result, i) =>
            {
                if (selected_result._id === result._id)
                {
                    rowSelected = true
                }
            })
            let cpn = Utils.getCpn(result);

            let cells =
            {
                "cpn":
                {
                    value        : cpn,
                    tooltip      : cpn,
                    displayValue :  <span className="link">
                                        <CpnField item={result} cpn={cpn}/>
                                    </span>
                },
                "eid":
                {
                    value        : result.eid,
                    tooltip      : result.eid,
                    displayValue : result.eid
                },
                "category":
                {
                    value        : result.category,
                    tooltip      : result.category,
                    displayValue : Schemas.component.category.getDisplayName(result.category)
                },
                "name":
                {
                    value        : result.name,
                    tooltip      : result.name,
                    cellClass    : result.variantGroup && window.__userRole !== 'VENDOR'? "name-block" : '',
                    displayValue : result.variantGroup && window.__userRole !== 'VENDOR' ?
                                    <div className='item-name'>
                                        <Link
                                        to={`/component/${path}/${result._id}`}
                                        className={`link ${result.variantGroup ? '' : 'not-variant'}`}>
                                        {result.name}
                                        </Link>
                                        <VariantIconHolder
                                            openVariantModal={(primaryVariant, variantModal) => {
                                                let state = this.state
                                                state.openVariantModal = true;
                                                state.primaryVariant = primaryVariant;
                                                state.VariantModal.top = variantModal.top
                                                state.VariantModal.left = variantModal.left
                                                this.setState(state)
                                            }}
                                            result={result}
                                            leftFomular={(left) => left - 400}
                                            toolTip="Component Variants"
                                            tableSelector="cmp-list-block extended-table"
                                         />
                                    </div>
                                    : result.name,
                },
                "description":
                {
                    value        : result.description,
                    tooltip      : result.description,
                    displayValue : result.description
                },
                "revision":
                {
                    value        : result.revision,
                    tooltip      : result.revision,
                    displayValue : <RevisionField item={result} />
                },
                "status":
                {
                    value        : result.status,
                    tooltip      : result.status,
                    displayValue : <StatusField item={result} />
                },
                "lastModified":
                {
                    value        : lastModified,
                    tooltip      : lastUpdatedToolTip ? `${lastUpdatedToolTip.dateValue} ${lastUpdatedToolTip.timeValue}`: '' ,
                    displayValue : <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} />
                },
                "images" : {
                    value       : 0,
                    displayValue: <InlineImageViewer imagesWithSrc={this.props.imagesWithSrc} key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={result.images}/>,
                    notLink     : true,
                    cellClass   : "inline-image-viewer"
                },
                rowSelected     : rowSelected,
                rowClassName    : (activeClass ? "active" : ""),
                rowLink         : `/component/${path}/${result._id}`,
                object          : result,
                rowId           : result._id
            }
            rows.push(cells);
        })

        return rows;
    }

    getActionList()
    {
        return [
            {
                label: "Import From File",
                icon: () => <ImportIcon />,
                onClick: () => {document.querySelector("span[data-tip='Import components from file']").querySelector("span").click()}
            },

            {
                label: "Import From Vendor",
                icon: () => <WebIcon />,
                onClick: () => {document.querySelector("span[data-tip='Create a new Component using its MPN to pull specs directly from vendor websites']").querySelector("span").click()}
            },

            {
                label: "Create Component Manually",
                icon: () => <ManualIcon />,
                onClick: () => {document.querySelector("span[data-tip='Manually Create a new Component Part or Assembly']").querySelector("span").click()}
            }
        ];
    }

    render()
    {
        if(this.props.loading)
        {
            return <Loading />
        }
        let headings = this.state.headings;
        let rows     = this.getRows();

        let actionsList = this.getActionList();

        let markup  =
                <div>
                {
                    this.state.openVariantModal && window.__userRole!=='VENDOR' &&
                    <div className="variant-table-holder">
                        <VariantModal
                            openVariantModal={this.state.openVariantModal}
                            closeVariantModal={this.closeVariantModal}
                            objectData={this.state.primaryVariant}
                            variantModalPosition={this.state.VariantModal}
                            history={this.props.history}
                            getDataFromApi={true}
                            fromLibraryTable={true}
                        />
                    </div>
                }

                    <ExtendedTablePaginated
                        wrapperClassName="cmp-list-block"
                        wrapperSelectorClass="cmp-list-view"
                        headings={headings}
                        rows={rows}
                        stylesName="searchComponents"
                        allowRowSelect={this.props.allowRowSelect}
                        clearSelectedRows={this.props.clearSelectedRows}
                        onRowSelect={this.props.addToSelectedList}
                        onSelectAllRows={this.props.selectAllResults}
                        currentSortItem={this.state.current}
                        currentSortItemAscending={this.state.currentSortItemAscending}
                        resetLocalState={this.props.resetLocalState}
                        setCurrentSortBy={this.setCurrentSortBy}
                        clearSelectedRowsStore={this.props.clearSelectedRowsStore}
                        scrollPagination={true}
                        paginationSize={150}
                        includeToolBar={true}
                        resultText={this.props.resultText}
                        tableActionButtons={this.props.getIconsActionsList()}
                        footerRow={ Permissions.can("create", "component", this.props.user.data) ? {
                                bordered: true,
                                dataCellEl: <p>Add Components</p>,
                                indexCellEl: Permissions.can("create", "component", this.props.user.data) &&
                                            <div className="add-more-actions">
                                                <DuroMenu anchorComponent=
                                                              {<InlineIcon className="plus-icon"
                                                                           customStyles={{ width: "100%" }}>
                                                                  <PlusIcon/>
                                                              </InlineIcon>}
                                                          name="component-list"
                                                          options={actionsList}
                                                          customButtonStyles={{ display: "inline-block" }}>
                                                </DuroMenu>
                                             </div>
                            }
                            :
                            rows.length === 0 &&
                            {
                                dataCellEl: <p>No Components</p>
                            }
                        }

                    />
                </div>

        return markup
    }
}

export default connect((store) => store)(ComponentList)
