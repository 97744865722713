import "./index.scss";
import React, { Component } from 'react';
import ModalBox             from "../modal-box"
import InlineIcon           from "../icon/inline-icon.js"
import AlertIcon            from "../../../assets/icons/alert-icon.js"
import WarningIcon          from "../../../assets/icons/warning-triangle-black-bg.js";
import Link                 from "../link"
import { TransactionalModal } from "common/components/modals";
import { useHistory } from "react-router-dom";

const WarningModal = (props) => {
    const history = useHistory();
    const navigateTo = () => {
        history?.push({ pathname: props.linkTo ?? "/dashboard" })
    }

    return (
    <div className={`new-warning-modal ${props.className ? props.className : "save-changes"}`} >
        <TransactionalModal 
            acceptLabel={props.okButtonText ? props.okButtonText : 'OK'}
            onClose={props.onCancel}
            onCancel={props.onCancel}
            onAccept={props.onConfirm ? props.onConfirm : navigateTo}
            open={true}
            title={props.title}
        >
            <div className="warning-text">
                <p className="save-warning-text">{props.description}</p>
            </div>
        </TransactionalModal>
    </div>
)
    }

export default WarningModal
