import { Chip, styled } from "@mui/material";
import { Link } from "common/components/link";
import { useMemo } from "react";
import { colorPalette } from "@duro/themes";

export interface SerialNumberFieldProps {
  displayValue: string;
  instanceId: string;
  displayBackground?: boolean;
}

const SerialBackground = styled(Chip)({
  "& a": {
    color: colorPalette.dark,
  },
});

// TODO: (PLA-298) Enable pointer events once the instance page is ready for customers.
const StyledLink = styled(Link)({ pointerEvents: "none" });

export function SerialNumberField({ displayValue, instanceId, displayBackground }: SerialNumberFieldProps) {
  const to = useMemo(() => `/build/instance/${instanceId}`, [instanceId]);

  const link = <StyledLink to={to}>{displayValue}</StyledLink>;

  if (displayBackground) {
    return <SerialBackground variant="filled" color="primary" label={link} />;
  }

  return link;
}
