import { User } from "common/models";
import { ChangeOrderResolution } from "@duro/utils";
import {
  ChangeOrder,
  ChangeOrderStatus,
  ChangeOrderType,
  EcoDefaultApproval,
} from "design/models/changeorder";

export const createDefaultCOState = (user: User): ChangeOrder => ({
  treeData: {},
  tree: [],
  id: "order",
  name: "",
  description: "",
  type: ChangeOrderType.ECO,
  approvalType: EcoDefaultApproval.FIRST_IN,
  approvers: [
    {
      action: "NONE",
      invitedAt: new Date().getTime(),
      performedAt: null,
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        groups: [],
      },
    },
  ],
  alias: "CO",
  archived: false,
  created: new Date().toISOString(),
  creator: {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    groups: [],
  },
  isParentInclude: false,
  lastModified: new Date().toISOString(),
  library: {
    id: user.activeLibrary.id,
  },
  resolution: ChangeOrderResolution.NONE,
  status: ChangeOrderStatus.DRAFT,
  company: {
    id: user.primaryCompany.id,
  },
  coInternalNotifyUsers: [],
  coExternalNotifyUserEmails: [],
  history: [],
  documentLinks: [],
  con: {
    displayValue: "New Change Order",
    id: "order",
  },
  erpOptions: null,
  revisionValue: "",
  exportBundleKey: "",
  productRevisions: [],
  componentRevisions: [],
  unchangedComponents: [],
  unchangedProducts: [],
  products: [],
  components: [],
});
