import {
  ChangeOrderType,
  EcoDefaultApproval,
  SuperComponent,
} from "design/models/changeorder";
import { filterAndDeduplicatePaths } from "features/changeorders/components/tables/changeorder-table/tree.paths";
import { EditorState } from "../state";
import { getComponentTree } from "graphql/query/componentsQueries";
import { createRow, createTreeNode } from "../preprocessors";
import { storage } from "../../storage";
import { createMandatoryCoApprovers } from "features/changeorders/hooks/use-company-templates";
import { createStore } from "common/context/store";
import {
  mandatoryExternalUsers,
  mandatoryNotifiers,
} from "features/changeorders/pages";

export type TreeNodeItem = {
  id: string;
  treeId: string;
};

export function getIdFromTreeId(treeId: string): string {
  const parts = treeId.split("::");
  return parts[parts.length - 1];
}

export function getTreeNodesById<T extends TreeNodeItem>(
  data: T[],
  key: string
) {
  return data.filter((entry) => entry.id === key);
}

export function filterRows(data: SuperComponent[], treeData: SuperComponent[]) {
  const coRowIds = data.map((row) => row.cpn.displayValue);
  return filterAndDeduplicatePaths(treeData, coRowIds);
}

export function findRelatedRows<
  T extends TreeNodeItem,
  K extends { id: string }
>(tree: T[], filterByRows: K[]): T[] {
  // Filter the tree array to return tree whose id matches any id in flatRows

  return tree.filter((row) =>
    filterByRows.some((filterByRows) => filterByRows.id === row.id)
  );
}

export function getRowsAfterDeletion(
  rows: SuperComponent[],
  treeIdsToDelete: string[]
) {
  // Filter the rows array to remove any rows that have a treeId in treeIdsToDelete
  return rows.filter((row) => !treeIdsToDelete.includes(row.id));
}

export const deleteRowsByTreeIds = (ids: string[]) => {
  EditorState.flags.tableIsLoading.setState(true);
  const items = EditorState.rows.getState();
  const updatedRows = getRowsAfterDeletion(items, ids);
  EditorState.rows.setState(updatedRows);
  EditorState.flags.tableIsLoading.setState(false);

  const statuses = updatedRows.map(
    (i) => EditorState.getChangeset(i).originalStatus
  );

  const currentTpl = storage.currentMandatoryTemplate.getState();
  const shouldRemoveProduction =
    currentTpl.status === "PRODUCTION" && !statuses.includes("PRODUCTION");
  const shouldRemovePrototype =
    currentTpl.status === "PROTOTYPE" && !statuses.includes("PROTOTYPE");

  if (shouldRemoveProduction) {
    setCompanyApprovers("PRODUCTION", "Removed");
  } else if (shouldRemovePrototype) {
    setCompanyApprovers("PROTOTYPE", "Removed");
  }

  ids.forEach((id) => {
    delete EditorState.cachedValidations[id];
  });

  if (Object.keys(EditorState.cachedValidations).length === 0) {
    storage.isValid.setState(true);
  }
};

function deduplicateArrays<T, K>(
  sourceArray: T[],
  filterArray: T[],
  getValue: (item: T) => K
): T[] {
  const seenValues = new Set(filterArray.map(getValue)); // Add filterArray values to the set

  const deduplicatedArray = [
    ...filterArray, // Start with filterArray
    ...sourceArray.filter((item) => !seenValues.has(getValue(item))), // Add unique items from sourceArray
  ];

  return deduplicatedArray;
}

export const mandatoryApprovers = createStore<string[]>([]);

const setCompanyApprovers = async (
  status: string,
  action: "Added" | "Removed"
) => {
  const approvalType = storage.form.approvalType.getState();
  const coType = storage.form.type.getState();
  const template = await createMandatoryCoApprovers(
    status,
    coType,
    approvalType
  )();

  if (!template) return;

  // save previous settings
  if (action === "Added") {
    storage.form.lastApproverList.setState(
      storage.form.approverList.getState()
    );

    storage.lastExternal.setState(
      storage.coExternalNotifyUserEmails.getState()
    );

    storage.lastNotifiers.setState(storage.coInternalNotifyUsers.getState());

    storage.form.lastApprovalType.setState(
      storage.form.approvalType.getState()
    );

    // update current settings
    storage.form.approverList.setState(template?.approvers ?? []);
    storage.coInternalNotifyUsers.setState(template?.notifiers ?? []);
    storage.coExternalNotifyUserEmails.setState(template?.externalUsers);
    storage.form.approvalType.setState(
      (template?.approvalType ?? "") as unknown as EcoDefaultApproval
    );

    mandatoryApprovers.setState(template?.approvers?.map((a) => a.id) ?? []);
    mandatoryNotifiers.setState(template?.notifiers?.map((a) => a.id) ?? []);
    mandatoryExternalUsers.setState(template?.externalUsers ?? []);

    storage.currentMandatoryTemplate.setState({
      open: true,
      type: coType,
      status,
      name: template?.templateName,
      action,
      id: template?.id,
    });
  }

  if (action === "Removed") {
    // reset to previous
    storage.form.approverList.setState(
      storage.form.lastApproverList.getState()
    );

    storage.form.approvalType.setState(
      storage.form.lastApprovalType.getState()
    );

    storage.coExternalNotifyUserEmails.setState(
      storage.lastExternal.getState()
    );

    storage.coInternalNotifyUsers.setState(storage.lastNotifiers.getState());

    mandatoryApprovers.setState([]);

    storage.currentMandatoryTemplate.setState({
      open: false,
      type: "",
      status: "",
      name: "",
      action: "Removed",
      id: "",
    });
  }

  storage.displayMandatoryApproversModal.setState(true);
};

function applyRowApprovers(rows: SuperComponent[]) {
  const currentTemplate = storage.currentMandatoryTemplate.getState();

  const statuses = [
    ...new Set(rows.map((row) => EditorState.getChangeset(row).originalStatus)),
  ].filter((status) => status === "PROTOTYPE" || status === "PRODUCTION");

  // production templates already exist
  if (
    currentTemplate.status === "PRODUCTION" &&
    statuses.includes("PROTOTYPE")
  ) {
    return;
  }

  const statusApplied = statuses.includes("PRODUCTION")
    ? "PRODUCTION"
    : "PROTOTYPE";

  if (statuses.length > 0) {
    setCompanyApprovers(statusApplied, "Added");
  }
}

export async function addRows(rows: SuperComponent[]) {
  EditorState.flags.tableIsLoading.setState(true);
  const ids = rows.map((r) => r.oid ?? "");
  const nextTreeData = await getComponentTree(ids);
  const nextTreeNodes = (nextTreeData.data.componentTreeByIds ?? []).map(
    createTreeNode
  );
  EditorState.tree.setState((prev) => [...prev, ...nextTreeNodes]);
  EditorState.rows.setState((prev) =>
    deduplicateArrays(prev, rows, (r) => r.id)
  );
  EditorState.flags.isReviewed.setState(false);
  EditorState.flags.tableIsLoading.setState(false);
  storage.isValid.setState(false);

  applyRowApprovers(rows);
}

export function handleDCOChange() {
  EditorState.changeset.forEach((value, key) => {
    value.status.setState(value.originalStatus);
    value.legacyNextRevision.setState(value.originalRevision);
  });

  storage.form.type.setState(ChangeOrderType.DCO);
}
