/**
 * @enum {string}
 * @description Represents the available size options for Duro components.
 * This enum is used to standardize size options across different components.
 */
export enum DuroSize {
  XS = "xs", // Tiny size (6px)
  SM = "sm", // Small size (12px)
  MD = "md", // Medium size (16px)
  LG = "lg", // Large size (24px)
  XL = "xl", // Extra large size (32px)
}

/**
 * @constant
 * @type {Record<DuroSize, string>}
 * @description A mapping between `DuroSize` enum values and their corresponding pixel sizes.
 * This mapping is useful for applying consistent sizing across different UI components.
 *
 * @example
 * // Example usage:
 * const smallSize = DuroSizeMap[DuroSize.SM]; // Returns "12px"
 */
export const DuroSizeMap: Record<DuroSize, string> = {
  [DuroSize.XS]: "6px",
  [DuroSize.SM]: "12px",
  [DuroSize.MD]: "16px",
  [DuroSize.LG]: "24px",
  [DuroSize.XL]: "32px",
};
