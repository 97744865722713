import React from "react"

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d={`M11.917 22c-2.76 0-5.102-.977-7.028-2.93C2.963 17.115 2 14.75 2 
        11.971h1.667c0 2.315.796 4.287 2.389 5.917 1.592 1.63 3.546 2.444 5.86 2.444 
        2.353 0 4.343-.824 5.973-2.472 1.63-1.648 2.444-3.648 2.444-6 
        0-2.296-.824-4.236-2.472-5.82-1.648-1.583-3.63-2.374-5.944-2.374-1.26 
        0-2.44.287-3.542.86A9.66 9.66 0 0 0 5.5 6.807h2.917v1.666H2.61V2.694h1.667V5.64a10.57 
        10.57 0 0 1 3.43-2.667A9.449 9.449 0 0 1 11.917 2c1.389 0 2.694.26 3.916.778a10.263 
        10.263 0 0 1 3.209 2.125 9.976 9.976 0 0 1 2.166 3.153c.528 1.203.792 2.5.792 
        3.888a9.773 9.773 0 0 1-.792 3.917 10.054 10.054 0 0 1-2.166 3.195 10.34 10.34 
        0 0 1-3.209 2.152 9.773 9.773 0 0 1-3.916.792zm3.555-5.472-4.278-4.222V6.36h1.667v5.25l3.806 
        3.722-1.195 1.195z`} fill-rule="evenodd"/>
    </svg>
  )
}

export default Icon;
