import { FC } from "react";
import { styled } from "@mui/material";

const ErrorMessageBox = styled("div")({
  padding: "3rem",
});

export const ErrorMessage: FC = ({ children }) => {
  return (
    <ErrorMessageBox>
      <h1>{children}</h1>
    </ErrorMessageBox>
  );
};
