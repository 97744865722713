import {
  ApproversIcon,
  DocumentsIcon,
  NotifiersIcon,
  PrdCmpIcon,
  RevisionIcon,
} from "assets/icons";

import {
  ApproversTab,
  ComponentsTab,
  NotificationsTab,
  DocumentsTab,
  HistoryTab,
} from "../pages/edit/views";

import { ChangeOrder } from "design/models/changeorder";
import { ChangeOrderSidebarClasses } from "../constants";
import { ITabProps } from "common/components/tabs";
import { SxProps } from "@mui/material";

const iconPosition: "start" | "top" | "bottom" | "end" | undefined = "start";

export type ChangeOrderRootElement = React.FC<{ initialState: ChangeOrder }>;

export type ChangeOrderTabConfigItem = {
  id: number;
  tabProps: {
    Icon?: React.ElementType;
    iconPosition?: "bottom" | "end" | "start" | "top";
    isActive?: boolean;
    label?: string;
    value: string;
    customStyles?: ITabProps["customStyles"];
    sx?: SxProps;
  };
  sidebar: ChangeOrderSidebarClasses | null;
  Component: () => JSX.Element;
};

export const TabsConfig: (
  changeorder: ChangeOrder | undefined
) => ChangeOrderTabConfigItem[] = (changeorder) =>
  [
    {
      sidebar: ChangeOrderSidebarClasses.COMPONENTS_SIDEBAR,
      Component: () => <ComponentsTab />,
      tabProps: {
        label: "Products & Components",
        value: "Products & Components",
        Icon: PrdCmpIcon,
        iconPosition: iconPosition,
        sx: { minWidth: "240px" },
      },
    },
    {
      sidebar: ChangeOrderSidebarClasses.APPROVERS_SIDEBAR,
      Component: () => <ApproversTab />,
      tabProps: {
        label: "Approver List",
        value: "Approver List",
        Icon: ApproversIcon,
        iconPosition: iconPosition,
        sx: { minWidth: "100px" },
      },
    },
    {
      sidebar: ChangeOrderSidebarClasses.NOTIFIERS_SIDEBAR,
      Component: () => <NotificationsTab />,
      tabProps: {
        label: "Notification List",
        value: "Notification List",
        Icon: NotifiersIcon,
        iconPosition: iconPosition,
        sx: { minWidth: "100px" },
      },
    },
    {
      sidebar: null,
      Component: () => <DocumentsTab />,
      tabProps: {
        label: "Documents",
        value: "Documents",
        Icon: DocumentsIcon,
        iconPosition: iconPosition,
        sx: { minWidth: "100px" },
      },
    },
    {
      sidebar: null,
      Component: () => <HistoryTab history={changeorder?.history ?? []} />,
      tabProps: {
        label: "History",
        value: "History",
        Icon: RevisionIcon,
        iconPosition: iconPosition,
        sx: { minWidth: "100px" },
      },
    },
  ].map((o, id) => ({ ...o, id }));
