import { Cancel } from "@mui/icons-material";
import { sdk } from "features/changeorders/sdk/editor";

export const NavButtonRemove = {
  id: 2,
  isDisabled: (ids: string[]) => ids.length === 0,
  Icon: () => <Cancel />,
  label: "Remove",
  onClick: (ids: string[]) => {
    const uniqueIds = [...new Set(ids.map((id) => id.split("::").pop() ?? ""))];
    sdk.editor.deleteRowsByTreeIds(uniqueIds);
  },
};
