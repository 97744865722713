import "./index.scss"
import React, { Component }             from "react"
import Utils                            from "../../../../../../modules/utils"
import Schemas                          from "../../../../../../modules/schemas"
import * as Constants from "../../../../../../modules/constants"
import { userById } from "../../../../../../../graphql/query/userQueries"


class CategorySelect extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.getOptions = this.getOptions.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    getOptions()
    {
        let type  = this.props.type
        let cats  = Schemas.categories.list()
        let listA = []
        let listB = []
        let allowedTypes = this.props.allowedTypes
        const { primaryCompany } = userById();

        cats.forEach((category) =>
        {
            if ((allowedTypes && !allowedTypes.includes(category.type)) || category.archived || (this.props.unsupported_categories && this.props.unsupported_categories.includes(category.displayName) || (category.type === "PRD" && primaryCompany.legacyCpnVariantScheme !== "EXTRA-TWO-DIGIT-VARIANT")) ){
                return
            }
            category.type.toLowerCase() === type ? listA.push(category) : listB.push(category)
        })

        return Utils.toOptions([...listA, ...listB], false, this.props.modelName === 'web' && this.props.mcMasterStatus !== 'Connected') //added this new param mcMasterStatus to enable/disable mechanical categories based on the mcMaster availability
    }

    onChange(event)
    {
        event = Utils.getEvent(this.refs.node, event.target.value)
        this.props.onChange && this.props.onChange(event)
    }

    render()
    {
        let classes = (!this.props.value ? "placeholder " : " ") + (this.props.disabled ? "disabled " : " ") + this.props.className
        let markup =
            <div
                ref="node"
                name={this.props.name}
                className={"new-component-category-select " + (this.props.disabled ? "disabled" : "")}
                >
                <select
                    className={classes}
                    value={this.props.value}
                    onChange={this.onChange}
                    data-place={this.props["data-place"] || "right"}
                    data-type={this.props["data-type"] || "error"}
                    data-tip={this.props["data-tip"]}
                    >
                    <option value="">Select</option>
                    {this.getOptions()}
                </select>
            </div>

        return markup
    }
}

export default CategorySelect
