import './index.scss';
import { useState } from 'react';
import InlineIcon from '../../../v1/components/ui/icon/inline-icon';
import LockOpenIcon from '../../../v1/assets/icons/lock-open';
import LockCloseIcon from '../../../v1/assets/icons/lock-close';
import { Switch } from '../../../common/components/inputs';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { colorPalette } from 'components/themes';

interface CompanyConfigToggleProps {
    isToggleEnabled: boolean;
    onUpdate: (newValue: boolean) => void;
    canEdit: boolean;
    tooltipText?: string;
    heading?: string;
    description?: string;
}

const CompanyConfigToggle: React.FC<CompanyConfigToggleProps> = ({
    isToggleEnabled: initialIsToggleEnabled,
    onUpdate,
    canEdit,
    tooltipText = 'Administrator access only',
    heading = 'Toggle Name',
    description = 'Toggle description to explain the purpose.'
}) => {
    const [isToggleEnabled, setToggle] = useState<boolean>(initialIsToggleEnabled);
    let toggleValue = isToggleEnabled ? 'On' : 'Off';

    const changeStateOfToggle = () => {
        const newValue = !isToggleEnabled;
        setToggle(newValue);
        onUpdate(newValue);
    };
    return (
        <Box className="company-config-toggle">
            <Box className={`${!canEdit || !isToggleEnabled ? 'disabled' : ''} toggle-block-first`}>
                <Box className="block-heading">
                    <InlineIcon className="toggle-lock-icon" tooltip={!canEdit ? tooltipText : ''}>
                        {canEdit ? <LockOpenIcon /> : <LockCloseIcon />}
                    </InlineIcon>
                    <Typography variant="body2" className="toggle-heading">
                        {heading}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body2" className="toggle-description">
                        {description}
                   </Typography>
                </Box>
            </Box>
            <Box className={`${!isToggleEnabled ? 'disabled' : ''} toggle-block-second`}>
                <Switch
                    defaultValue={isToggleEnabled}
                    disabled={!canEdit}
                    label={toggleValue}
                    labelStyle={titleStyle}
                    onChange={changeStateOfToggle}
                />
            </Box>
        </Box>
    );
};

const titleStyle = {
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
};

export default CompanyConfigToggle;
