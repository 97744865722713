/**
 * Mixpanel calls fail on browsers with an adblocker; also, not all environments
 * will/should have a mixpanel token. The exported functions in this file are meant
 * to keep client code free of repetitive try/catch's and if (hasMixpanel) checks.
 */

import * as _mixpanel from 'mixpanel-browser'
import { Config } from 'mixpanel-browser'

import { User } from './models'

const hasMixpanel = window.DURO_ENV?.mixpanel

interface IParams {
  [key: string]: any
}

export const mixpanel = {
  init(token: string, params: Partial<Config>) {
    if (hasMixpanel) {
      try {
        _mixpanel.init(token, params)
      } catch { }
    }
  },

  identify(uniqueId: string) {
    if (hasMixpanel) {
      try {
        _mixpanel.identify(uniqueId)
      } catch { }
    }
  },

  setPeople(user: User) {
    if (hasMixpanel) {
      try {
        _mixpanel.people.set({
          "$first_name": user.firstName,
          "$last_name": user.lastName,
          "$email": user.email,
          "$group_id": user.primaryCompany?.id,
          "User ID": user.id,
          "Company Name": user.primaryCompany?.name,
          "Company ID": user.primaryCompany?.id
        })
        _mixpanel.register({
          "$first_name": user.firstName,
          "$last_name": user.lastName,
          "$email": user.email,
          "$group_id": user.primaryCompany?.id,
          "User ID": user.id,
          "Company Name": user.primaryCompany?.name,
          "Company ID": user.primaryCompany?.id,
          "Event Source": "web"
        })
      } catch { }
    }
  },

  track(event: string, params: IParams) {
    if (hasMixpanel) {
      try {
        _mixpanel.track(event, params)
      } catch { }
    }
  }
}

export default mixpanel