import { sdk } from "features/changeorders";
import { sdk as sdkv2 } from "features/changeorders/sdk/editor";
import { ValidateIcon } from "./validateButton";

export const NavButtonValidate = {
  id: 0,
  isDisabled: () => false,
  Icon: () => (
    <ValidateIcon
      isValidationLoading={sdk.storage.caches.isValidationLoading.useStore()[0]}
    />
  ),
  label: "Validate",
  onClick: () =>
    sdkv2.validation.validateEditorItems(sdkv2.state.rows.getState()),
};
