import React, { FC, useCallback, useMemo } from "react";
import {
  Box, IconButton as MUIButton, styled, Toolbar as MUIToolbar, Tooltip, Typography,
} from "@mui/material";
import { colorPalette } from "@duro/themes";
import { useDataTestId } from "common/hooks";

const enum ToolbarSection {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
}

export interface IToolbarActions {
  active?: boolean;
  buttonType?: "button" | "submit" | "reset",
  disabled?: boolean,
  Icon: React.ElementType,
  label?: string,
  onClick: (e: React.MouseEvent) => void,
  styleProps?: IStyleProps,
  tooltip?: string,
}

export interface IToolbarProps {
  actionLabel?: string,
  centerActions: IToolbarActions[],
  leftActions?: IToolbarActions[],
  name?: string,
  rightActions?: IToolbarActions[],
  styleProps?: IStyleProps,
  toolbarMode?: JSX.Element,
}

export interface IStyleProps {
  backgroundColor?: string,
  borderRadius?: string,
  height?: string,
  margin?: string,
  width?: string,
}

export const Toolbar: FC<IToolbarProps> = ({
  actionLabel,
  centerActions,
  leftActions,
  name = "primary",
  rightActions,
  toolbarMode,
}) => {
  const testId = useDataTestId("toolbar", name);
  const actions: Function = useCallback((items: IToolbarActions[], section: ToolbarSection) => (
    items?.map(({
      active = false,
      buttonType = "button",
      disabled,
      Icon,
      label,
      onClick,
      styleProps,
      tooltip,
    }: IToolbarActions, index: number) => {
      const markup = (
        <IconButton
          data-testid={`${testId}-${section}-${label ?? index}`}
          disabled={disabled}
          key={`${index}-action-icon`}
          onClick={onClick}
          sx={styleProps}
          type={buttonType}
          color={active ? "primary" : "default"}
        >
          <Icon sx={{ width: "1.25rem", height: "1.25rem" }} />
          {label && (
            <IconLabel> {label} </IconLabel>
          )}
        </IconButton>
      );
      return tooltip ? <Tooltip title={tooltip}>{markup}</Tooltip> : markup;
    })
  ), [testId]);

  const left = useMemo(() => actions(leftActions, ToolbarSection.LEFT), [actions, leftActions]);
  const center = useMemo(() => actions(centerActions, ToolbarSection.CENTER), [actions, centerActions]);
  const right = useMemo(() => actions(rightActions, ToolbarSection.RIGHT), [actions, rightActions]);

  return (
    <ToolbarWrapper data-testid={testId}>
      {left}
      {toolbarMode}
      <CenterToolbar>
        {actionLabel && (
          <>
            <ActionLabel>
              {actionLabel}
            </ActionLabel>
            <Divider />
          </>
        )}
        {center}
      </CenterToolbar>
      {right}
    </ToolbarWrapper >);
};

const commonStyle = {
  padding: "0 0.5rem",
};

const ActionLabel = styled(Box)({
  ...commonStyle,
});

const CenterToolbar = styled(MUIToolbar)({
  borderBottomLeftRadius: "0",
  borderTopLeftRadius: "0",
  marginLeft: "0",
});

const Divider = styled(Box)({
  border: `solid 0.063rem ${colorPalette.taupeGray}`,
  height: "1rem",
  margin: "0.313rem 0 0.188rem 0",
  width: "0.063rem",
});

const IconButton = styled(MUIButton)(commonStyle);

const IconLabel = styled(Typography)({
  fontSize: "0.875rem",
  marginLeft: "0.5rem",
});

const ToolbarWrapper = styled(Box)({
  alignItems: "center",
  display: "flex",
});
