import "../index.scss";
import {Component}       from "react";
import ImageViewerTile          from "../../../../ui/image-viewer-tile";
import Config                   from "../../../../../modules/config";
import { RevisionField } from "@duro/base/revision-field/";
import CpnField                 from "../../cpn-field";
import { StatusField } from "@duro/base/status-field";
import TileItem                 from "../../tile-item";
import Link                     from "../../../../ui/link";
import Schemas                  from "../../../../../modules/schemas";
import Utils                    from "../../../../../modules/utils";
import { userById } from "graphql/query/userQueries";
import { ModelType, extractCpnRules } from "utils/cpn";

export class ItemOverView extends Component
{
  constructor(props)
  {
    super(props)
  }

  render()
  {
    const user = userById();
    const { useTwoVariableScheme = false } = extractCpnRules(
      user?.activeLibrary,
      ModelType.COMPONENT,
    );
    let { item, alias, isPrdOrCmpViewPage, isProductionInstance } = this.props;
    let markup = (
      <TileItem
        title={`${item.alias === "cmp" ? "Component" : "Product"} overview`}
      >
        <ImageViewerTile
          defaultResolution={Config.defaultResolutions.featureImage}
          images={item.images}
        />
        {(alias === "cmp" || (alias === "prd" && useTwoVariableScheme)) && (
          <div className="inner-info pov diff-category-section">
            <span className="inner-attribute">category</span>
            <span
              className="inner-value"
              data-tip=""
              data-place="top"
              onMouseOver={(e) =>
                Utils.checkToolTipAppearance(e, item.category)
              }
            >
              {Schemas.component.category.getDisplayName(item.category)}
            </span>
          </div>
        )}
        <div className="inner-info pov diff-cpn-section">
          <span className="inner-attribute">cpn</span>
          <span className="inner-value">
            {isProductionInstance ? (
              <Link
                to={`/${
                  item.alias === "prd" ? "product" : "component"
                }/revision/${item.objectRevision}`}
                className="link not-variant"
              >
                {item.cpn}
              </Link>
            ) : (
              <CpnField item={item} cpn={item.cpn} />
            )}
          </span>
        </div>
        <div className="inner-info pov diff-eid-section">
          <span className="inner-attribute">eid</span>
          <span
            className="inner-value eid-info"
            data-tip=""
            data-place="top"
            onMouseOver={(e) => Utils.checkToolTipAppearance(e, item.eid)}
          >
            {Utils.viewRefExist(item)}
          </span>
        </div>
        <div className="inner-info pov diff-revision-section">
          <span className="inner-attribute">revision</span>
          {isProductionInstance ? (
            <span className="inner-value">{item.revision}</span>
          ) : (
            <RevisionField item={item} />
          )}
        </div>
        <div className="inner-info pov diff-status-section">
          <span className="inner-attribute">status</span>
          <StatusField item={item} />
        </div>
        {/* This replace AddInFields component */}
        {item.alias === "cmp" && Utils.isVendorCmp(Utils.getVendor(item)) && (
          <div className="inner-info pov addIn-only-fields">
            <span className="inner-attribute">workflow state</span>
            <span className="inner-value">
              {item.cmpState ? item.cmpState : ""}
            </span>
          </div>
        )}
      </TileItem>
    );
    return markup
  }
}

export default ItemOverView
