import "./index.scss"
import React                        from "react"
import { connect }                  from 'react-redux';
import View                         from "../../../ui/view"
import Details                      from "./modules/details"
import NotificationTable            from "./modules/notification-table"
import Documents                    from "./modules/documents"
import Children                     from "./modules/children"
import prdCmpSrc                    from "../../../../assets/icons/prd-cmp-icon.svg"
import RELEASE                      from '../../../../action-types/release';
import buildAction                  from "../../../../helpers/buildAction"
import documentSrc                  from "../../../../assets/icons/cat-document.svg"
import Icon                         from "../../../ui/icon"
import notificationSrc              from "../../../../assets/icons/send-notification-icon.svg"
import Utils                        from "../../../../modules/utils"
import {Helmet}                     from "react-helmet"
import API                          from "../../../../modules/api";
import ChangeOrderIcon                   from "../../../../assets/icons/triangle-icon";
import VendorPillbox                from "../../component/components/vendor";

export class ViewRelease extends View
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            view : "childrenData"
        }
        this.getReleaseFromApi = this.getReleaseFromApi.bind(this);
        this.imagesWithSrc = [];
    }

    componentDidMount()
    {
        this.getReleaseFromApi(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.match.params.id !== this.props.match.params.id)
        {
            this.getReleaseFromApi(nextProps.match.params.id);
        }
    }

    componentWillUnmount()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(RELEASE.RESET_RELEASE));
    }

    getReleaseFromApi(id)
    {
        const {dispatch} = this.props;
        dispatch(buildAction(RELEASE.FIND_RELEASE, { id }));
    }

    show(viewName)
    {
        this.state.view = viewName;
        this.setState(this.state);
    }

    render()
    {
        let { release } = this.props.release;
        if(!release) return null;
        let {isVendorCmp, vendorLabel} = Utils.getVendorLabel(release);

        let view = this.state.view;

        let markup =
            <div className="view-release app-row">
                <Helmet>
                    <title>{Utils.makeTitle("["+ release.releaseNo + "] "+release.name)}</title>
                </Helmet>
                <div className="header-section">
                    <ul>
                        <VendorPillbox isVendorCmp={isVendorCmp} label={vendorLabel} isComponentLinked={true} />
                    </ul>
                </div>
                <div className="align-block">
                    <Details release={release}/>
                </div>
                   <div className="nav-block">
                        <div className={view === "childrenData" ? "selected" : ""}
                            onClick={() => this.show("childrenData")}
                            >
                            <div className="prd-cmp-holder">
                                <Icon src={prdCmpSrc} />
                                <h2>Components</h2>
                            </div>
                        </div>
​
                    <div
                        className={view === "notificationList" ? "selected" : ""}
                        onClick={() => this.show("notificationList")}
                        >
                        <div className="notification-holder">
                            <Icon
                                className={view === "notificationList" ? "active" : ""}
                                src={notificationSrc}
                            />
                            <h2>Notification List</h2>
                        </div>
​
                    </div>
​
                    <div
                        className={view === "documents" ? "selected" : ""}
                        onClick={() => this.show("documents")}
                        >
                        <div className="documents-holder">
                            <Icon
                                src={documentSrc}
                                className={view === "documents" ? "active" : "deactive"}
                            />
                            <h2>Documents</h2>
                        </div>
​
                    </div>
​
                </div>

                <div className="view-block">
                    {
                        view === "childrenData" &&
                        <Children
                            key={release.releaseNo}
                            list={release.revisions}
                            history={this.props.history}
                            user={this.props.user}
                            imagesWithSrc={this.imagesWithSrc}
                        />
                    }

                    {
                        view === "notificationList" &&
                        <div>
                            <NotificationTable
                                emailList={release.emailList}
                                user={this.props.user}
                                />
                        </div>
                    }

                    {
                        view === "documents" &&
                        <div>
                            <Documents
                                documents={release.documents}
                                user={this.props.user}
                                releaseObject={release}
                                initializePusher={true}
                                />
                        </div>
                    }
                </div>
            </div>
        return markup
    }
}

const mapStateToProps = state => ({
  release: state.release
})

export default connect(mapStateToProps, null)(ViewRelease);
