import "./index.scss"
import React, { Component } from "react"
import Link from "../../link"
import InlineIcon           from "../../../ui/icon/inline-icon.js"
import Logo                 from "../../../../assets/icons/logo"
import config from "../../../../modules/config"

class PublicView extends Component
{
    render()
    {
        let markup = (
            <div className="public-view app-row">
                <div className="branding">
                    <Link to={config.REACT_APP_AUTH_URL}>
                        <div className="company-name">
                            <InlineIcon><Logo /></InlineIcon>
                        </div>
                    </Link>
                </div>
                <div className="navigation">
                    {/* <Link to={publicPaths.signin.pathname}>Sign in</Link> */}
                    <a href="#" onClick={() => window.Calendly.initPopupWidget({ url: 'https://calendly.com/matt-duro/60min?hide_event_type_details=1&hide_gdpr_banner=1&background_color=100e1c&text_color=807F86&primary_color=3cd1b5 ' })}>
                        Schedule a Demo
                    </a>
                </div>
            </div>
        );
        return markup
    }
}

export default PublicView
