import React from "react";
import { styled, Typography } from "@mui/material";
import { colorPalette } from '@duro/themes';
import { StatusValue } from "@duro/utils"
import {SingleStatusField} from "@duro/base/status-field";

/**
 * @description Represents the various statuses a component can have.
 */
export type ComponentStatus =
  | "DESIGN"
  | "PROTOTYPE"
  | "PRODUCTION"
  | "OBSOLETE";

/**
 * @description Type representing the color code corresponding to a component status. Essentially,
 * this type enforces the strings stored in the database to be one of the valid status colors.
 */
export type StatusColor = typeof StatusColors[keyof typeof StatusColors];

/**
 * @description Props (public API) for the `MidPromotionLabel` styled component.
 */
export interface MidPromotionLabelProps {
  /**
  * @description The background color for the label (and css triangle) representing the previous status.
  */
  previousStatus: StatusColor;
  /**
   * @description The background color for the label representing the next status. This color also
   * sets the border color of the previous status element.
   */
  nextStatus: StatusColor;
}

/**
 * @description Props (public API) for the `DuroMidPromotionLabelComponent` component.
 */
export interface MidPromotionProps {
  /**
   * @description The value of the components status before promotion. E.g This is the status
   * the component is moving FROM.
   */
  previousStatus: ComponentStatus;
  /**
   * @description The value of the components status after promotion. E.g This is the status
   * the component is moving TO.
   */
  nextStatus: ComponentStatus;
}

/**
 * @description A mapping of component statuses to their corresponding color hex codes.
 */
export const StatusColors = {
    [StatusValue.DESIGN]: colorPalette.status[StatusValue.DESIGN],
    [StatusValue.PROTOTYPE]: colorPalette.status[StatusValue.PROTOTYPE],
    [StatusValue.PRODUCTION]: colorPalette.status[StatusValue.PRODUCTION],
    [StatusValue.OBSOLETE]: colorPalette.status[StatusValue.OBSOLETE],
};

/**
 * @description Utility function to check if a given string is a valid `ComponentStatus` and then apply type narrowing
 * to it.
 * 
 * @param status - The status string to validate.
 * @returns `true` if the status is valid, otherwise `false`.
 */
const isValidStatus = (status: string): status is StatusValue => {
    return [
        StatusValue.DESIGN,
        StatusValue.PROTOTYPE,
        StatusValue.PRODUCTION,
        StatusValue.OBSOLETE,
    ].includes(status as StatusValue);
};

/**
 * @description Number of characters for the UI to display for each status label.
 */
const LABEL_DISPLAY_CHARS = 4;

/**
 * @description Styled component for the promotion label with an arrow effect.
 *
 * This component displays a label with two parts: the previous status and the next status,
 * with an arrow pointing from the previous status to the next. The part for previous status also
 * has a border that matches the color of the next status.
 *
 * @param props - Props for configuring the appearance of the label.
 * @returns A styled `div` element representing the promotion label.
 */
const StyledPromotionLabel = styled("div")<MidPromotionLabelProps>(
  ({ previousStatus, nextStatus }) => ({
    display: "inline-flex",
    borderRadius: "10rem",
    fontSize: "13px",
    height: "18px",
    maxHeight: "18px",
    "& > span": {
      padding: "0 10px",
      flex: 1, // Makes both spans take up equal space
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative", // Positioning context for the arrow
      height: "auto",
      color: `var(--color, ${colorPalette.jaguar})`, // Text color
    },
    "& > span:first-of-type": {
      borderTopLeftRadius: "10rem",
      borderBottomLeftRadius: "10rem",
      borderTop: `1px solid ${nextStatus}`,
      borderLeft: `1px solid ${nextStatus}`,
      borderBottom: `1px solid ${nextStatus}`,
      background: previousStatus,
    },
    "& > span:last-of-type": {
      borderTopRightRadius: "10rem",
      borderBottomRightRadius: "10rem",
      background: nextStatus,
      marginLeft: "-10px", // Negative margin overlaps the arrow
      "&::before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        borderTop: "8px solid transparent",
        borderBottom: "8px solid transparent",
        borderLeft: `8px solid ${previousStatus}`, // Arrow color
      },
    },
  })
);

/**
 * @description `DuroMidPromotionLabelComponent` is a React component that displays the promotion status of a component.
 *
 * This component shows the previous status and the next status, visually indicating the transition
 * from one status to the other. It includes an arrow pointing from the previous status to the next.
 *
 * @param props - The props for the component, including `previousStatus` and `nextStatus`.
 * @returns A styled label component or an error message if the statuses are invalid.
 */
const DuroMidPromotionLabelComponent: React.FC<MidPromotionProps> = React.memo(
  ({ previousStatus, nextStatus }) => {
    // Validate the statuses
    let fallbackLabel: string | undefined;

    if (!isValidStatus(previousStatus)) {
      fallbackLabel = nextStatus;
    } else if (!isValidStatus(nextStatus)) {
      fallbackLabel = previousStatus;
    } else if (!isValidStatus(previousStatus) && !isValidStatus(nextStatus)) {
      fallbackLabel = 'DESIGN'; // If
    }

    if (fallbackLabel) {
      return <SingleStatusField label={fallbackLabel} />;
    }

    return (
      <StyledPromotionLabel
        previousStatus={StatusColors[previousStatus]}
        nextStatus={StatusColors[nextStatus]}
        role="status"
        aria-live="polite"
      >
        <span aria-label={`Previous status: ${previousStatus}`}>{previousStatus.slice(0, LABEL_DISPLAY_CHARS)}</span>
        <span aria-label={`Next status: ${nextStatus}`}>{nextStatus.slice(0, LABEL_DISPLAY_CHARS)}</span>
      </StyledPromotionLabel>
    );
  }
);

export default DuroMidPromotionLabelComponent;
