import "./index.scss"
import "./../index.scss"
import React, { Component } from "react"
import GroupList            from "./group-list"
import {connect}    from "react-redux";
import USERS                from "../../../../action-types/users"
import buildAction          from "../../../../helpers/buildAction"
import {Helmet}             from "react-helmet"
import Utils                from "../../../../modules/utils"

class Groups extends Component
{

    constructor(props, context)
    {
        super(props, context)
        // this.onModalClose    = this.onModalClose.bind(this)
    }

    componentWillMount(){
      let {dispatch} = this.props
      dispatch(buildAction(USERS.GET_USERS_LIST, { handleLoading: false }))
    }

    render()
    {
        let markup =

            <div className="groups-settings-route">
                <Helmet>
                  <title>{Utils.makeTitle("Settings - Groups")}</title>
                </Helmet>
                <div className="main-block">
                  <div className="heading-block">
                    <h1>Groups</h1>
                  </div>
                    <div className="content-block">
                      <GroupList groupName={"Engineering"}/>
                      <GroupList groupName={"QA"}/>
                      <GroupList groupName={"Procurement"}/>
                      <GroupList groupName={"Supplier"}/>
                      <GroupList groupName={"Management"}/>
                      <GroupList groupName={"Reviewer"}/>
                      <GroupList groupName={"Manufacturer"}/>
                      <GroupList groupName={"Unassigned"} disableActions={true}/>
                    </div>
                </div>
            </div>

        return markup
    }
}

export default connect((store) => store)(Groups)
