import { getChangeOrderByIds } from "graphql/query/changeOrdersQueries";
import { ChangeOrderREST } from "../rest";
import { ChangeOrderSubmitType, getPayload } from "../rest/submit-change-order";
import { getModifiedChangeOrder, storage } from "../storage";
import { getRowsByComponentType, getRowsByProductType } from "../utils/filters";
import { EditorState } from "./state";

// When a change order is submitted, the change order is updated with
// the latest revisions and statuses
const updateRemoteRevisions = async () => {
  const rows = EditorState.rows.getState();

  if (!rows.length) {
    return;
  }

  const seenIds = new Set<string>();

  for (const item of rows) {
    if (seenIds.has(item.id)) {
      continue;
    }

    const itemChanges = EditorState.getChangeset(item);

    const status = itemChanges.status.getState();
    const legacyNextRevision = itemChanges.legacyNextRevision.getState();
    const originalRevision = itemChanges.originalRevision;
    const originalStatus = itemChanges.originalStatus;

    const { oid, alias, lastModified } = item;

    const statusChanged = originalStatus !== status;
    const revChanged = originalRevision !== legacyNextRevision;

    const isModified = statusChanged || revChanged;

    if (!isModified || !oid) {
      continue;
    }

    if (!statusChanged) {
      await ChangeOrderREST.components.postComponentNextRevisionUpdate(
        oid,
        legacyNextRevision,
        alias
      );
    } else {
      const updateNextRevisionPayload = {
        modified: true,
        status,
        revision: legacyNextRevision,
        nextCoRevision: legacyNextRevision,
        lastModified: new Date(lastModified).getTime(),
      };

      await ChangeOrderREST.components.postComponentUpdate(
        oid,
        updateNextRevisionPayload,
        alias
      );

      seenIds.add(item.id);
    }
  }
};

export const submitChangeorder = async (
  status: ChangeOrderSubmitType,
  isNewChangeOrder: boolean
) => {
  if (status === "SAVE_DRAFT" && !!storage.isNameFieldError.getState()) {
    return Promise.reject("Name field is required for draft change orders.");
  }

  if (status === "SUBMIT" && !storage.isValid.getState()) {
    return Promise.reject("Change order is not ready for submission.");
  }

  const userId = localStorage.getItem("__uid");
  const components = getRowsByComponentType(EditorState.rows.getState());
  const products = getRowsByProductType(EditorState.rows.getState());

  let remoteCo = storage.remoteCo?.getState();

  if (isNewChangeOrder) {
    // Create the new Change Order to get the provisioned ID
    const newChangeOrderId = await ChangeOrderREST.changeorder.create(
      userId ?? ""
    );

    // Fetch the Change Order Number associated with the newly created Change Order
    const conIdResponse = await getChangeOrderByIds([newChangeOrderId]);
    const changeorders = conIdResponse.data?.changeOrdersByIds ?? [];
    remoteCo = changeorders[0];
  }

  const payload = getPayload(
    status,
    components,
    products,
    getModifiedChangeOrder(remoteCo),
    userId
  );

  await updateRemoteRevisions();

  await ChangeOrderREST.changeorder.postChangeOrder(payload);

  // Crude redirect to the change order view page,
  // should be handled by the router later.
  window.location.href = `/changeorder/view/${payload._id}`;
};
