import { useCallback } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ArrowDownIcon, ArrowReturnIcon, ArrowUpIcon } from "assets/icons";
import { InputFieldVariants } from "common/constants";

interface IOptions {
  label: string,
  value: string | number,
}

export interface CustomSelectStyleProps {
  minWidth?: string,
}

export interface CustomDropdownProps {
  componentStyles?: CustomSelectStyleProps,
  disabled?: boolean,
  isRequired: boolean,
  label?: string,
  onChange: (e: SelectChangeEvent<string>) => void,
  options: IOptions[],
  placeholder?: string,
  value: IOptions,
  variant?: InputFieldVariants
}

export function CustomSelect(props: CustomDropdownProps) {
  const {
    componentStyles,
    disabled,
    label,
    options,
    value,
    variant,
  } = props;

  const renderInput = useCallback(params => (
    <TextField {...params}
      placeholder="Enter CPN"
      variant={variant}
    />
  ), [variant]);

  const paperComponent = useCallback(({ children }) => (
    <PaperWrapper minWidth={componentStyles?.minWidth}>
      {children}
      <RightContainer>
        <LeftContainer>
          <ArrowUpIcon />
          <ArrowDownIcon />
          Navigate
        </LeftContainer>
        <RightContainer>
          <ArrowReturnIcon />
          Enter to Select
        </RightContainer>
      </RightContainer>
    </PaperWrapper>
  ), [componentStyles?.minWidth]);

  const renderOptions = useCallback((renderProp: React.HTMLAttributes<HTMLLIElement>, option: IOptions) => (
    <RenderOptionStyles {...renderProp}>
      {option.label}
    </RenderOptionStyles>
  ), []);

  return (
    <SelectWrapper>
      <FormControl fullWidth variant={variant} size="small" disabled={disabled}>
        <Label>
          { label }
        </Label>
          <Autocomplete
            disabled={!!value.label}
            freeSolo
            options={options}
            PaperComponent={paperComponent}
            renderInput={renderInput}
            renderOption={renderOptions}
            value={value}
          />
      </FormControl>
    </SelectWrapper>
  );
}

const SelectWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== "componentStyles",
})(({ theme }) => ({
  "& .MuiAutocomplete-root .MuiFilledInput-root": {
    backgroundColor: theme.duro.eventModal.selectOptions.backgroundColor,
    marginTop: "0.5rem",
    padding: "0rem 0.5rem 0rem 0.5rem !important",
  },
}));

const PaperWrapper = styled(Paper, {
  shouldForwardProp: prop => prop !== "minWidth",
})<Pick<CustomSelectStyleProps, "minWidth">>(({ minWidth, theme }) => ({
  backgroundColor: theme.duro.eventModal.selectOptions.backgroundColor,
  backgroundImage: "none",
  minWidth,
  padding: "0.5rem",
}));

const RightContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  marginRight: "0.625rem",
  paddingTop: "0.375rem",
  "& > svg": {
    fontSize: "1.125rem",
    margin: "0 0.375rem",
  },
});

const LeftContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
  "& > svg": {
    fontSize: "1.125rem",
    margin: "0 0.25rem",
  },
});

const RenderOptionStyles = styled(Typography)({
  borderBottom: "0.05rem solid grey",
  padding: "0.5rem",
});

const Label = styled(Typography)({
  fontSize: "smaller",
  marginBottom: "0.5rem",
});
