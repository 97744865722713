import { DatroomsBody } from '../components/DataroomsBody';
import { DataroomsHeader } from '../components/DataroomsHeader';

export const Datarooms = () => {
  return (
    <div>
      <DataroomsHeader />
      <DatroomsBody />
    </div>
  );
};

