export default {
    data: {
        _id: '5a1bcaf63ec59b214e813e3b',
        state: null,
        email: 'info@durolabs.com',
        company: {
            _id: '5a1bcaf53ec59b214e813e3a',
            state: null,
            subscription: {
                payment: {
                rate: 0,
                frequency: 'MONTHLY'
                }
            },
            logo: {
                src: '',
                label: ''
            },
            website: '',
            address: {
                country: '',
                zip: '',
                state: '',
                city: '',
                suite: '',
                street: ''
            },
            name: 'test company',
            shortName: 'testcompany-1511770869224',
            created: 1511770869228
        },
        preferences: {
        favorites: [
            '5a28df36f33fb765b0a52524'
        ]
        },
        sessions: [
            {
                ip: '::1',
                created: 1512467940743
            },
            {
                ip: '::1',
                created: 1512555705777
            }
        ],
        title: '',
        role: 'Administrator',
        owner: true,
        invite: {
            accepted: true
        },
        avatar: {
            key: '104070311748612480.jpg',
            name: 'apple-logo_318-40184.jpg',
            size: '18287',
            mime: 'image/jpeg',
            data: null,
            src: 'https://plm-assets-local.s3-us-west-2.amazonaws.com/104070311748612480.jpg?AWSAccessKeyId=AKIAJOEFJWODVIS7V6TQ&Expires=1512635986&Signature=Xso4pewOtXEhPue3HN%2BCH5Vo8DI%3D',
            label: 'JPEG Image'
        },
        lastName: 'lastName',
        firstName: 'firstName',
        terminated: false,
        created: 1511770870359
    }
}
