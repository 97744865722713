import { getWindow } from "utils/window";
import { AxiosResponse } from "axios";
import { restClient } from "utils/api";

export interface LegacyComponent {
  _id: string;
  vendorInfo: LegacyVendorInfo;
  eid: string;
  modified: boolean;
  images: any[];
  status: string;
  revision: string;
  description: string;
  revisions: string[];
  alias: string;
  cpnVariant: null;
  nextCoRevision: string;
  previousStatus: string;
  previousRevision: string;
  revisionManaged: boolean;
  category: string;
  name: string;
  created: number;
  lastModified: number;
  company: string;
  creator: string;
  cpn: string;
  lastReleaseCmpRev: string;
}

export interface LegacyVendorInfo {
  currentVendor: string[];
  originalVendor: string;
  previousVendor: string[];
  isLinked: boolean;
}

export interface LegacyComponentSearchRequest {
  page: number;
  sort?: string;
  reverse: boolean;
  limit: number;
  lean: string;
  or: QueryByOrOperator;
  without_co: boolean;
  response_type: string;
}

interface QueryByOrOperator {
  cpn: string;
}

export interface LegacyComponentSearchResponse {
  data: {
    count: number;
    results: LegacyComponent[];
  };
  succcess: boolean;
}

function createComponentRequest(cpn: string): LegacyComponentSearchRequest {
  return {
    page: 1,
    reverse: true,
    limit: 1,
    lean: "true",
    or: {
      cpn: `*${cpn}*`,
    },
    sort: "asc",
    without_co: true,
    response_type: "changeOrderEdit",
  };
}

interface GetRevisionBumpRequest {
  defaultBlacklistedRevisions: string[];
  item: LegacyComponent;
  libraryType: string;
  revSchemeType: string;
}

interface GetRevisionBumpResponse {
  success: boolean;
  data: string;
}

const componentCache = new Map<string, LegacyComponent>();
const revisionCache = new Map<string, string>();

function getCached<T>(
  keys: string[],
  cache: Map<string, T>,
  fetchData: () => Promise<T | undefined>
): () => Promise<T | undefined> {
  const key = keys.length > 0 ? keys.join("::") : keys[0];
  const data = cache.get(key);

  return async () => {
    if (keys.some((key) => key == null)) {
      throw new Error("Invalid key provided.");
    }

    if (data) {
      return data;
    } else {
      const results = await fetchData();

      if (results) {
        cache.set(key, results);
      }

      return results;
    }
  };
}

// This function returns a hook that can be used to fetch the revision bump for a
// component based on its CPN and status and is used in some cases when the user edits a product/component
// status in the changeorder table. We need to pull the legacy version of the component
// so that we can pass it back to the revision bump endpoint. This is slightly less performant
// than serializing the already available core-api component, but we do not have all properties
// needed to create the payload for the revision bump via core-api.
// To account for this, component fetches are cached by CPN and revision bump requests are cached
// by CPN and status to reduce API usage while the user is mid-workflow.
export const getNextRevision = async (cpn: string, status: string) => {
  // component.cache { [cpn]: LegacyComponent }
  // ensures that we never pull more than one legacy component
  const getComponent = getCached([cpn], componentCache, async () => {
    const legacyCmp = await restClient.post<
      LegacyComponentSearchRequest,
      AxiosResponse<LegacyComponentSearchResponse>
    >("/search", createComponentRequest(cpn));

    return legacyCmp?.data?.data?.results[0];
  });

  // revision.cache { [cpn::status]: revision }
  // further caching so we don't make the same request when the status
  // is changed to a previously set status
  return getCached([cpn, status], revisionCache, async () => {
    const legacyComponent = await getComponent();
    let remoteRevisionBump: string | undefined;

    if (legacyComponent) {
      const payload = {
        defaultBlacklistedRevisions: getWindow().__defaultBlacklistedRevisions,
        item: legacyComponent,
        libraryType: getWindow().__libraryType,
        revSchemeType: getWindow().__revSchemeType,
      };

      const getRevisionBump = await restClient.post<
        GetRevisionBumpRequest,
        AxiosResponse<GetRevisionBumpResponse>
      >("/changeorder/getRevisionBumpInCo", payload);

      remoteRevisionBump = getRevisionBump?.data?.data;
    } else {
      throw new Error("Component not found, cannot bump revision.");
    }

    return remoteRevisionBump;
  });
};
