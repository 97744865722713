import { DuroModalProps, DuroModal } from "common/components/modals/duroModal";
import { DuroGridBasic } from "common/components/grid/grid-basic";
import { Box } from "@mui/material";
import { AddChildrenModalFooter } from "./addChildrenModalFooter";
import { useComponentTreeQuery } from "graphql/query/componentsQueries";
import { PreviewModalColumnDefinitions } from "./table.config";
import { useWindowSize } from "common/hooks/useWindowSize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SuperComponent } from "design/models/changeorder";
import { sdk } from "features/changeorders/sdk/editor";
import { sdk as sdkv1 } from "features/changeorders/sdk";
import { getNextTreeNodes } from "./tree.paths";
import { PrimaryTableGroupingCell } from "./cells";

import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useTreeView } from "./is.tree.view";
const TABLE_PADDING_Y = 150;
const TABLE_PADDING_X = 50;
const MODAL_SIZE = 0.75;

type ChildDataProps = {
  dataById: Record<string, SuperComponent>;
  selectedIds: string[];
  parentIds: string[];
};

export const AddChildrenModal: React.FC<DuroModalProps & ChildDataProps> = ({
  open,
  onClose,
  ariaLabelledby,
  ariaDescribedby,
  dataById,
  selectedIds,
  parentIds,
}) => {
  const apiRef = useGridApiRef();
  const [treeDataStore, setTreeData] = sdk.state.tree.useStore();
  const [selectedChildren, setSelectedChildren] = useState<string[]>([]);
  const dimension = useWindowSize();

  const { treeData, treeDataByIds, loading } = useComponentTreeQuery(
    selectedIds.map((id) => dataById[id].oid ?? "")
  );

  const { isTreeView, buttons } = useTreeView(apiRef, treeData);

  const modalDimensions = {
    height: dimension.height * MODAL_SIZE,
    width: dimension.width * MODAL_SIZE,
  };

  const tableDimensions = {
    width: modalDimensions.width - TABLE_PADDING_X,
    height: modalDimensions.height - TABLE_PADDING_Y,
  };

  const handleAddChildComponents = async () => {
    const { rows, treeNodes } = getNextTreeNodes(
      Object.entries(treeDataStore).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [k]: v,
        }),
        {}
      ),
      treeDataByIds,
      selectedChildren,
      selectedChildren.map((id) => treeDataByIds[id]?.cpn?.displayValue),
      selectedIds
    );

    await setTreeData((prev) => [...prev, ...Object.values(treeNodes)]);

    await sdk.state.childrenDisplayed.setState((prev) => ({
      ...prev,
      ...parentIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}),
    }));

    await sdk.editor.addRows(rows);
    sdkv1.editor.children.handleDisplayModal(false);
    sdk.state.childrenIds.setState([]);
  };

  const [cpnColDef, ...restCols] = PreviewModalColumnDefinitions;

  const colsWithTreeDefs = [...(!isTreeView ? [cpnColDef] : []), ...restCols];

  const exisitingDataIds = useMemo(
    () => new Set(Object.values(dataById).map((item) => item.id)),
    [dataById]
  );

  const isRowDisabled = useCallback(
    (row: any) => exisitingDataIds.has(row.id),
    [exisitingDataIds]
  );

  return (
    <DuroModal
      open={open}
      onClose={onClose}
      showCloseButton={false}
      header="Include Child Components"
      footer={
        <AddChildrenModalFooter
          onClick={handleAddChildComponents}
          isEnabled={selectedChildren.length > 0}
        />
      }
      ariaLabelledby={ariaLabelledby}
      ariaDescribedby={ariaDescribedby}
      customStyle={modalDimensions}
    >
      <Box sx={tableDimensions}>
        <DuroGridBasic
          apiRef={apiRef}
          getRowId={(row) => row.treeId}
          groupingColDef={{
            headerName: "CPN",
            renderCell: PrimaryTableGroupingCell,
          }}
          useTreeData={isTreeView}
          rows={treeData}
          columns={colsWithTreeDefs}
          sortModel={[{ field: "cpn", sort: "desc" }]}
          pinnedColumns={{ left: ["__check__"] }}
          toolbarItems={[
            buttons.NavButtonTree,
            buttons.NavButtonFlat,
            buttons.NavButtonExpand,
            buttons.NavButtonCollapse,
          ]}
          setSelectedRows={setSelectedChildren}
          loading={loading}
          isRowDisabled={isRowDisabled}
        />
      </Box>
    </DuroModal>
  );
};
