import { SvgIcon } from "@mui/material";

export const VariantsIcon = () => (
  <SvgIcon fontSize="inherit" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path d={`M11.697 14.667H3.212A1.216 1.216 0 0 1 2 13.455V4.97h1.212v8.485h8.485v1.212zM9.576 
      4.97V3.758H8.364V4.97H7.152v1.212h1.212v1.212h1.212V6.182h1.212V4.97H9.576zm1.212 
      3.636H7.152v1.212h3.636V8.606zm-.91-7.273H5.637A1.21 1.21 0 0 0 4.43 2.545l-.006 
      8.485c0 .667.54 1.212 1.206 1.212h6.673c.667 0 1.212-.545 1.212-1.212V4.97L9.88 
      1.333zm2.425 9.697H5.636V2.545h3.74l2.927 2.928v5.557z`} fill-rule="evenodd"/>
</SvgIcon>
);
