import { gql } from "@apollo/client";

export const ChangeOrderInfoFragment = gql`
  fragment changeOrderInfoFragment on ChangeOrder {
    id
    status
    con {
      id
      displayValue
    }
  }
`;
