import { gql } from "@apollo/client";

export const BaseCategoryFragment = gql`
  fragment baseCategoryFragment on Category {
    name
    shortName
    code
    type
    isDefaultCategory
    isAllowedInPartAndAssembly
    specs {
      allowedValues
      id
      isDropDown
      name
      type
    }
  }
`;
export const CompanyCategoriesFragment = gql`
  fragment companyCategoriesFragment on Category {
    ...baseCategoryFragment
  }
  ${BaseCategoryFragment}
`;
