import {
  ClientModulesSchemaValidationResult,
  ValidationRecord,
} from "./validation.types";
import { SuperComponent } from "design/models/changeorder";
import { createExistingChangeOrderValidator } from "./validators/duplicate-co.validator";
import { validateStatus } from "./validators/row-status.validator";
import { validateRevision } from "./validators/revision.validator";
import { validatePrimarySource } from "./validators/source.validator";
import { validateChildrenStatuses } from "./validators/child-status.validator";
import { validateObsoleteChildStatuses } from "./validators/obsolete-children.validator";

function addLog<T extends (...args: any[]) => any>(
  fn: T,
  handleResult: (
    fnName: string,
    result: ReturnType<T>,
    args: Parameters<T>
  ) => void
): T {
  return function (...args: Parameters<T>): ReturnType<T> {
    const result = fn(...args);
    handleResult(fn.name || "anonymous function", result, args);
    return result;
  } as T;
}

window.__DEV_TOOLS = {
  benchmark: false,
};

function withLogs(validators: Validator[]) {
  const start = Date.now();
  return validators.map((validator, index) =>
    addLog(validator, (fnName, result, args) => {
      const first = !index,
        last = index === validators.length - 1;
      first && console.groupCollapsed(args[0].cpn.displayValue);
      console.log(
        `${fnName}: ${result.valid ? "✅" : "❌"}, ${Date.now() - start}ms`
      );

      last && console.info(`Total time: ${Date.now() - start}ms`);
      last && console.groupEnd();
    })
  );
}

type Validator = (item: SuperComponent) => ClientModulesSchemaValidationResult;

export const getValidators = async (items: SuperComponent[]) => {
  const validateMultipleCO = await createExistingChangeOrderValidator(items);

  const validators: Validator[] = [
    validateMultipleCO, // Items cannot be in multiple change orders
    validateRevision, // Revision must be valid according to status
    validateStatus, // Status cannot be DESIGN
    validatePrimarySource, // Primary source must exist
    validateChildrenStatuses, // Child statuses must be greater than or equal to parent status
    validateObsoleteChildStatuses, // Obsolete children must not exist
  ];

  return window.__DEV_TOOLS?.benchmark || process.env.NODE_ENV === "development"
    ? withLogs(validators)
    : validators;
};

type ValidationComponentsOptions = {
  items: SuperComponent[] | SuperComponent;
  enableLogGrouping?: boolean;
  onItemValidate?: (
    itemValidations: ClientModulesSchemaValidationResult[],
    item: SuperComponent
  ) => void;
};

export async function validateComponents(
  {
    items: input,
    enableLogGrouping,
    onItemValidate,
  }: ValidationComponentsOptions = { items: [] }
): Promise<ClientModulesSchemaValidationResult[]> {
  const isArray = Array.isArray(input);
  const items = isArray ? input : [input];
  const validators = await getValidators(items);
  const start = Date.now();

  if (enableLogGrouping) {
    console[items.length > 10 ? "group" : "groupCollapsed"](
      "Validation running..."
    );
  }

  const errors = items.map((item) => {
    const results = validators
      .map((validator) => validator(item))
      .filter((result) => !result.valid);
    onItemValidate?.(results, item);
    return results;
  });

  if (enableLogGrouping) {
    console.groupEnd();
    console.log(
      `>> Completed ${items.length} validations in ${Date.now() - start}ms`
    );
    console.groupEnd();
  }

  return errors.flat(+isArray) as ClientModulesSchemaValidationResult[];
}

export async function validateComponent(item: SuperComponent) {
  const validateMultipleCO = await createExistingChangeOrderValidator([item]);

  return [
    validateMultipleCO(item), // Items cannot be in multiple change orders
    validateRevision(item), // Revision must be valid according to status
    validateStatus(item), // Status cannot be DESIGN
    validatePrimarySource(item), // Primary source must exist
    validateChildrenStatuses(item),
    validateObsoleteChildStatuses(item),
  ].flat();
}

export function isValidComponent(record: ValidationRecord, id: string) {
  return record[id].every((result) => result.valid);
}

export function isValidRecord(record: ValidationRecord) {
  return Object.values(record)
    .flat()
    .every((result) => result.valid);
}
