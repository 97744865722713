import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import { Box, styled } from "@mui/material";
import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowId,
  GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { serialCheckIcon } from "assets/icons";
import {
  AUTOFIT_TOOLBAR_ICON_PROPS,
  COLLAPSE_TOOLBAR_ICON_PROPS,
  EXPAND_TOOLBAR_ICON_PROPS,
  FILTER_TOOLBAR_ICON_PROPS,
  getRowId,
  GRID_CELL_EDIT_CLASS_NAME,
  Grid,
  GroupingColDef,
  REFRESH_TOOLBAR_ICON_PROPS,
  renderEditCell,
  renderEditInputCell,
  ROW_HEIGHT,
  setVisibleNodeExpansionState,
  SortMode,
  ToolBarItem,
  useGridHeight,
} from "common/components/grid";
import { Select } from "common/components/inputs";
import { ToolbarItemType } from "common/constants";
import { BuildRevision } from "build/models";
import { cpnRenderCell, useRevisionRenderCell } from "design/components/grid";
import { ModelType, PageItemType, PageMode } from "@duro/utils";
import { Component } from "design/models";
import { COLUMN_DEFAULTS } from "design/utils/columnDefaults";
import {
  Dispatch, MouseEvent, MutableRefObject, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState,
} from "react";
import { colorPalette } from "@duro/themes";
import { SerialNumberType, SerializationNode, useGridHelpers } from "./useSerializationData";

const GRID_NAME = "serialization";
const GRID_DEFAULT_ROWS: SerializationNode[] = [];

const VALID_FLOAT_REGEX = /^[0-9]*\.?[0-9]*$/;
const NOT_A_FLOAT_ERROR_MESSAGE = "Value should be of type Number";

function updateSelection(
  apiRef: MutableRefObject<GridApiPro>,
  oldModel: GridRowId[],
  newModel: GridRowId[],
): GridRowId[] {
  let updated = [...newModel];

  // If something was unchecked, then make sure to uncheck it's children.
  const removed = oldModel.filter(id => !newModel.includes(id));
  if (removed.length) {
    updated = newModel.filter(mid => !removed.find(rid => String(mid).startsWith(String(rid))));
  }

  // If something was checked, then make sure to check it's children.
  const added = newModel.filter(id => !oldModel.includes(id));
  if (added.length) {
    const childrenAdd = apiRef.current.getAllRowIds().filter(rid =>
      added.find(aid => String(rid).startsWith(String(aid))));

    // The added items also end up in the list, so just make sure add least one child showed up.
    if (childrenAdd.length > 1) {
      updated = [...(new Set([...newModel, ...childrenAdd]))];
    }
  }

  return updated;
}

export interface TableProps {
  apiRef: MutableRefObject<GridApiPro>
  currentItem?: Component | BuildRevision;
  includeChildren: boolean;
  loading: boolean;
  rows: SerializationNode[];
  selectionModel: GridRowId[];
  setRows: Dispatch<SetStateAction<SerializationNode[]>>;
  setSelectionModel: Dispatch<SetStateAction<GridRowId[]>>;
}

const serialIdProps = {
  componentStyle: {
    width: "100%",
    "& fieldset": {
      borderColor: colorPalette.black,
    },
  },
  inputStyle: {
    sx: {
      backgroundColor: colorPalette.black,
      padding: "0.25rem 0.475rem",
    },
  },
  options: [
    { value: SerialNumberType.GENERATED, label: "Auto" },
    { value: SerialNumberType.MANUAL, label: "Manual" },
    { value: SerialNumberType.COMMODITY, label: "Commodity" },
    { value: SerialNumberType.UNIQUE_COMMODITY, label: "Unique Commodity" },
  ],
};

export type CellRendererProps = GridRenderCellParams<any, SerializationNode>;
function useSerialIdRendererCell(onChangeSerialId: (row: SerializationNode, value: SerialNumberType) => void) {
  return useMemo(() => (({ row }: CellRendererProps): ReactNode => (
    <Select
      componentStyles={serialIdProps.componentStyle}
      inputProps={serialIdProps.inputStyle}
      isRequired={false}
      onChange={e => onChangeSerialId(row, e.target.value as SerialNumberType)}
      options={serialIdProps.options}
      value={row.serialNumberType}
    />
  )), [onChangeSerialId]);
}

export function Table(props: TableProps) {
  const {
    apiRef, currentItem, includeChildren, loading, rows, selectionModel, setRows, setSelectionModel,
  } = props;

  const [childLoadingCount, setChildLoadingCount] = useState(0);
  const {
    disableCollapse,
    disableExpand,
    setVisibleRowCount,
    updatePostExpansionChange,
    visibleRowCount,
  } = useGridHelpers({
    apiRef,
    component: currentItem,
    componentLoading: loading,
    setChildLoadingCount,
    setRows,
    setSelectionModel,
  });

  const onChangeSerialId = useCallback((row: SerializationNode, value: SerialNumberType) => {
    setRows(old => old.map(r => (r.item.id === row.item.id ? { ...r, serialNumberType: value } : r)));
    apiRef.current.updateRows([{ _path: row._path, serialNumberType: value }]);
  }, [apiRef, setRows]);

  const onBulkSerialIdOptionClicked = useCallback((e: MouseEvent<HTMLElement>, value: any) => {
    const serialNumberType = value as SerialNumberType;
    setRows(old => old.map(row => {
      if (selectionModel.includes(getRowId(row))) {
        apiRef.current.updateRows([{ _path: row._path, serialNumberType }]);
        return { ...row, serialNumberType };
      }

      return row;
    }));
  }, [apiRef, selectionModel, setRows]);

  const onExpandClicked = useCallback(() => {
    setVisibleNodeExpansionState(apiRef, true);
  }, [apiRef]);

  const onCollapseClicked = useCallback(() => {
    setVisibleNodeExpansionState(apiRef, false);
  }, [apiRef]);

  const onFilterClicked = useCallback(() => {
    // Need to handle the filter onChange.
  }, []);

  const onSerialCheck = useCallback(() => {
    // Need to handle the Serial onChange.
  }, []);

  const onAutoFitClicked = useCallback(() => {
    // Need to handle the AutoFit onChange.
  }, []);

  const onRefreshClicked = useCallback(() => {
    // Need to handle the Refresh onChange.
  }, []);

  const revisionRenderCell = useRevisionRenderCell(
    PageMode.VIEW,
    rows[0]?.item.alias === ModelType.CMP ? PageItemType.COMPONENT : PageItemType.PRODUCT,
  );
  const serialIdRenderCell = useSerialIdRendererCell(onChangeSerialId);

  // Validate the quantity is an integer.
  const validateQuantityCell = useCallback((params: GridPreProcessEditCellProps) => {
    const { value } = params.props;
    const valid = value.length > 0 && VALID_FLOAT_REGEX.test(value);
    return { ...params.props, error: !valid ? NOT_A_FLOAT_ERROR_MESSAGE : null };
  }, []);

  const columnDefs: GridColDef[] = useMemo(() => (
    [
      COLUMN_DEFAULTS.name,
      {
        cellClassName: GRID_CELL_EDIT_CLASS_NAME,
        field: "serialId",
        headerName: "SERIAL ID",
        renderCell: serialIdRenderCell,
        width: 200,
      },
      {
        ...COLUMN_DEFAULTS.revision,
        headerName: "REV",
        renderCell: revisionRenderCell,
      },
      COLUMN_DEFAULTS.status,
      {
        ...COLUMN_DEFAULTS.quantity,
        editable: false,
        headerName: "QTY NEEDED",
      },
      {
        ...COLUMN_DEFAULTS.quantity,
        cellClassName: GRID_CELL_EDIT_CLASS_NAME,
        editable: true,
        field: "qtyToBuild",
        headerName: "QTY TO BUILD",
        preProcessEditCellProps: validateQuantityCell,
        renderCell: renderEditCell,
      },
      COLUMN_DEFAULTS.category,
      {
        cellClassName: GRID_CELL_EDIT_CLASS_NAME,
        editable: true,
        field: "notes",
        headerName: "NOTES",
        renderCell: renderEditCell,
        renderEditCell: renderEditInputCell,
        width: 200,
        valueFormatter: args => (args.value || "Enter note"),
      },
      COLUMN_DEFAULTS.lastUpdated,
    ]
  ), [revisionRenderCell, serialIdRenderCell, validateQuantityCell]);

  const toolbarItems: Array<ToolBarItem> = useMemo(() => ([
    {
      ...FILTER_TOOLBAR_ICON_PROPS,
      onClick: onFilterClicked,
    },
    {
      type: ToolbarItemType.DIVIDER,
    },
    {
      disabled: false,
      Icon: PinOutlinedIcon,
      items: serialIdProps.options,
      label: "Bulk Serial ID",
      onOptionClick: onBulkSerialIdOptionClicked,
      type: ToolbarItemType.OPTIONS,
    },
    {
      type: ToolbarItemType.DIVIDER,
    },
    {
      disabled: true, // disabled till the implementation of the relevant functionality
      Icon: serialCheckIcon,
      label: "Serial Check",
      type: ToolbarItemType.ACTION,
      onClick: onSerialCheck,
    },
    {
      type: ToolbarItemType.DIVIDER,
    },
    {
      ...EXPAND_TOOLBAR_ICON_PROPS,
      disabled: includeChildren ? disableExpand : true,
      onClick: onExpandClicked,
    },
    {
      ...COLLAPSE_TOOLBAR_ICON_PROPS,
      disabled: includeChildren ? disableCollapse : true,
      onClick: onCollapseClicked,
    },
    {
      ...AUTOFIT_TOOLBAR_ICON_PROPS,
      onClick: onAutoFitClicked,
    },
    {
      ...REFRESH_TOOLBAR_ICON_PROPS,
      disabled: true,
      onClick: onRefreshClicked,
    },

  ]), [
    onFilterClicked, onBulkSerialIdOptionClicked, onSerialCheck, includeChildren, disableExpand,
    onExpandClicked, disableCollapse, onCollapseClicked, onAutoFitClicked, onRefreshClicked,
  ]);

  const onSelectionChange = useCallback((_selectionModel: GridSelectionModel, _details: GridCallbackDetails) => {
    if (!includeChildren) return;
    setSelectionModel(old => updateSelection(apiRef, old, _selectionModel));
  }, [apiRef, includeChildren, setSelectionModel]);

  const groupingRenderCell = useCallback((params: CellRendererProps) => (
    <GroupingColDef {...params} showExpandIcon={includeChildren} renderInnerCell={cpnRenderCell} />
  ), [includeChildren]);

  const groupColumn = useMemo(() => ({
    ...COLUMN_DEFAULTS.cpn,
    renderCell: groupingRenderCell,
  }), [groupingRenderCell]);

  const isCellEditable = useCallback((params: GridCellParams<number>) => (
    apiRef.current.isRowSelected(params.id)
  ), [apiRef]);

  // Processes changes made while editing a cell within the grid table.
  const processRowUpdate = useCallback((newRow: SerializationNode, oldRow: SerializationNode) => {
    setRows(old => old.map(row => (row.item.id === oldRow.item.id ? newRow : row)));
    return newRow;
  }, [setRows]);

  const gridHeight = useGridHeight(visibleRowCount, ROW_HEIGHT);

  useEffect(() => {
    if (!rows.length) return;

    const row = apiRef.current.getRow(getRowId(rows[0])) as SerializationNode | undefined;
    if (includeChildren) {
      apiRef.current.updateRows([
        ...((row && [{ ...row, _descendantCount: rows[0]._descendantCount }]) ?? []),
        ...rows.slice(apiRef.current.getRowsCount()),
      ]);
    }
    else if (apiRef.current.getRowsCount() !== 1 && rows.length) {
      if (row) {
        apiRef.current.setRows([{ ...row, _descendantCount: 0 }]);
      }
      else {
        apiRef.current.setRows([{ ...rows[0], _descendantCount: 0 }]);
      }
    }
    setSelectionModel(apiRef.current.getAllRowIds());
    updatePostExpansionChange();
  }, [apiRef, includeChildren, setVisibleRowCount, updatePostExpansionChange, rows, setSelectionModel]);

  return (
    <GridContainer height={gridHeight}>
      <Grid
        apiRef={apiRef}
        columnDefinition={columnDefs}
        data={GRID_DEFAULT_ROWS}
        defaultGroupingExpansionDepth={1}
        deselectedRowClassName="disabled"
        enableCellFocus={true}
        getRowId={getRowId}
        isCellEditable={isCellEditable}
        loading={loading || childLoadingCount !== 0}
        name={GRID_NAME}
        onSelectionChange={onSelectionChange}
        processRowUpdate={processRowUpdate}
        scrollEndThreshold={100}
        selectionModel={selectionModel}
        sortMode={SortMode.CLIENT}
        toolbarItems={toolbarItems}
        totalCount={includeChildren ? `${rows[0]._descendantCount} Children` : ""}
        treeColumnDefinition={groupColumn}
        treeData={true}
      />
    </GridContainer>
  );
}

const GridContainer = styled(Box)<{ height: number }>(({ height }) => ({
  display: "flex",
  flex: 1,
  height: `${height}px`,
  // The grid should max out the height of the dialog at being tall enough to mostly fill the screen.
  maxHeight: "calc(100vh - 28rem)",
}));
