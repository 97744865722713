import Utils from "../../../modules/utils"

export function getInitialState()
{
    let stateObj = {
        component           : null,
        modified            : false,
        isNewObject         : false,
        originalComponentId : null,
        inputs :
        {
            isUploadedThumbnails: true,
            inputChanged        : false,
            name :
            {
                value : "",
                valid : true
            },
            category :
            {
                value : "",
                valid : true
            },
            revision :
            {
                value : "",
                valid : true
            },
            status :
            {
                value : "",
                valid : true
            },
            eid :
            {
                value : "",
                valid : true
            },
            description :
            {
                value : "",
                valid : true
            },
            images :
            {
                value : [],
                valid : true
            },
            documents :
            {
                value : [],
                valid : true
            },
            manufacturers :
            {
                value : [],
                valid : true
            },
            specs :
            [

            ],
            customProperties :
            [

            ],
            customSpecs :[],
            searchComponent :
            {
                results : []
            },
            cpnVariant :
            {
                class : "cpn-variant-field",
                value : "",
                valid : true
            },
            mass :
            {
                value : "",
                valid : true
            },
            unitOfMeasure :
            {
                value : "",
                valid : true
            },
            cpn :
            {
                value : "",
                valid : true
            },
            family:
            {
                value: "",
            },
            suffixCounter :
            {
                value : "",
                valid : true
            },
            suffixCategory: {
                value: ''
            },
            itemType: {
                value: '',
                valid: true
            },
            effectivity: {
                startDate: {
                    class: '',
                    message: '',
                    value: '',
                    valid: true
                },
                endDate: {
                    class: '',
                    message: '',
                    value: '',
                    valid: true
                }
            }
        }
    }
    return Utils.clone(stateObj)

}

let initialState = getInitialState()
export default initialState
