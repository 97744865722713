import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import {
  GridAlignment, GridEditInputCell, GridRenderCellParams, GridRenderEditCellParams,
} from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { colorPalette } from "@duro/themes";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))<{ isError: boolean }>(({ theme, isError }) => ({
  display: !isError ? "none" : undefined, // Hide it when there is no error to display
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: isError ? theme.duro.grid.error.background : undefined,
  },
}));

const EditCellContainer = styled(Box)<{ error: boolean, textAlign?: GridAlignment }>(({
  error,
  textAlign,
  theme,
}) => ({
  alignItems: "center",
  backgroundColor: error ? theme.duro.grid.error.background : colorPalette.white,
  display: "flex",
  flex: 1,
  overflow: "hidden",
  padding: "0 0.5rem",
  justifyContent: textAlign,
  "& input.MuiInputBase-input": {
    padding: "0",
    textAlign,
  },
}));

const ValueContainer = styled(Typography)(() => ({
  color: colorPalette.dark,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export function EditInputCell(props: GridRenderEditCellParams) {
  const { colDef, error } = props;

  const isError = !!error;

  return (
    <StyledTooltip disableHoverListener title={error ?? ""} isError={isError}>
      <EditCellContainer error={isError} textAlign={colDef.align}>
        <GridEditInputCell {...props} error={isError} />
      </EditCellContainer>
    </StyledTooltip>
  );
}

export function renderEditInputCell(params: GridRenderEditCellParams) {
  return <EditInputCell {...params} />;
}

export function EditCell(props: GridRenderCellParams & { children?: React.ReactNode }) {
  const { colDef, children, field, formattedValue } = props;
  const { _validations } = props.row;

  const error = useMemo(() => (_validations?.[field]?.message ?? ""), [_validations, field]);

  return (
    <StyledTooltip title={error} isError={!!error}>
      <EditCellContainer error={!!error} textAlign={colDef.align}>
        {children ?? <ValueContainer variant="body2">{formattedValue}</ValueContainer>}
      </EditCellContainer>
    </StyledTooltip>
  );
}

export function renderEditCell(params: GridRenderCellParams) {
  return <EditCell {...params} />;
}
