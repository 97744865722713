import "./index.scss"
import React, { Component } from "react"
import {connect}          from "react-redux"
import Icon               from "../icon"
import closeSrc           from "../../../assets/icons/close.svg"
import buildAction        from "../../../helpers/buildAction"

class RightSideBarMenu extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            modal :
            {
                open    : false,
                width   : 270,
                minWidth: 270,
                expandable: false
            }
        }
        this.onMouseDown            = this.onMouseDown.bind(this)
        this.onMouseMove            = this.onMouseMove.bind(this)
        this.onMouseUp              = this.onMouseUp.bind(this)
        this.onMouseLeave           = this.onMouseLeave.bind(this)
        this.updateHeight           = this.updateHeight.bind(this)
    }

    componentDidMount()
    {
        this.updateHeight()
    }

    updateHeight()
    {
        // let heightLimit    = document.getElementById("right-list-content").offsetHeight
        // let minHeightLimit = document.getElementsByClassName("right-sidebar-menu")[0].offsetHeight
        // document.getElementsByClassName("right-side-bar-menu")[0].style.height = heightLimit + "px"
        // document.getElementsByClassName("right-side-bar-menu")[0].style.minHeight = minHeightLimit + "px"
    }
    
    onMouseDown(e)
    {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseLeave);
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseUp(e)
    {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        e.preventDefault();
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseMove(e)
    {
        let position = document.body.offsetWidth - e.pageX
        if (position < 500)
        {
            position = position > 404 ? 405 : position
            if (this.state.modal.width !== e.pageX){
                let state = this.state
                state.modal.class    = ""
                state.modal.width    = position
                state.modal.minWidth = position
                this.setState(this.state)
            }
            e.stopPropagation()
            e.preventDefault()
        }
    }

    onMouseLeave(e)
    {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);

        e.preventDefault()
        e.stopPropagation()
        e.preventDefault()
    }

    render()
    {
        let modal  = this.state.modal

        let markup =
            <div
                ref="modalNode"
                className={"modal open right-sidebar-menu " + (this.props.className ? this.props.className : '')}
                style={{width: modal.width+"px", minWidth: modal.minWidth+"px"}}
                >
                <div
                    className="right-sidebar-expand"
                    onMouseDown={(e) => this.onMouseDown(e)}
                >
                    <div className="border"/>
                </div>
                {
                    this.props.onMenuClose ?
                    <div className="close-icon" onClick={this.props.onMenuClose}>
                        <Icon
                            src={closeSrc}
                        />
                    </div> :
                    null
                }
                <div id='right-side_bar-content'>
                    <div className="header-block">
                        <h2>{this.props.heading}</h2>
                    </div>
                    {
                        this.props.children
                    }
                </div>
            </div>

        return markup
    }
}

export default connect((store) => store)(RightSideBarMenu)
