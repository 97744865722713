import "./index.scss"
import React, { Component }             from    "react"

class OnBoardingStepToolTip extends Component
{
    constructor(props, context) {
        super(props, context);
        this.next = this.next.bind(this)
        this.inviteUser = this.inviteUser.bind(this)
    }

    next(event)
    {
        event.stopPropagation();
        this.props.next()
    }

    inviteUser(event)
    {
        event.stopPropagation();
        this.props.gotoInviteUser(event)
    }
    render()
    {
        let markup = null
        markup =
            <div className={"on-boarding-step-tool-tip " + this.props.className}>
                <div className="flexbox-parent">
                    <div className="flexbox-item">
                        <div className="header-top">
                            <p>{this.props.header}</p>
                        </div>
                    </div>
                    <div className="flexbox-item fill-area content flexbox-item-grow">
                        <div className="modal-content">
                            {this.props.children}
                        </div>
                    </div>
                    <div className="flexbox-item">
                        <div className="footer-modal">
                            <span
                                className="invite-link"
                                onClick={this.inviteUser}
                                >
                                INVITE USERS
                            </span>
                            <span
                                className="next"
                                onClick={this.next}
                                >
                                {this.props.nextButtonText}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        return markup
    }
}

export default OnBoardingStepToolTip
