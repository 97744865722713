import { styled, Box, Button, Typography, ButtonProps } from "@mui/material";
import { createLayoutBasic } from "common/layouts/basic";
import { colorPalette } from "components/themes";
import { LoadingSpinner, DuroButton } from "common/components";
import {
  ChangeOrderEditTabs,
  ChangeOrderForm,
  TabsConfig,
  OrderSideBar,
  ApproversSideBar,
  NotificationsSideBar,
  MandatoryApproversPopOver,
  mandatoryNotifiers,
  mandatoryExternalUsers,
} from "../../";
import { ChangeOrderLayoutConfig } from "../../constants";
import { Helmet } from "react-helmet";
import { makeTitle } from "utils/makeTitle";
import { CreateOutlined } from "@mui/icons-material";
import { ButtonVariants } from "common/constants";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { sdk } from "../..";
import { sdk as sdk2 } from "../../sdk/editor";
import { SaveOutlined, SendOutlined } from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import { ValidateButton } from "./validate.button";
import {
  FullscreenTopbar,
  FullscreenWrapper,
} from "features/changeorders/components/fullscreen";
import { DuroTooltip, TooltipVariants } from "common/components/tooltip";
import { ErrorBoundary } from "common/components/error-boundary";
import { resetState } from "features/changeorders/sdk/storage";
import { useChangeOrderLoad } from "features/changeorders/sdk/utils/create-new-co";
import { resetEditorState } from "features/changeorders/sdk/editor/state";
import { mandatoryApprovers } from "features/changeorders/sdk/editor/tree/tree.logic";

const getHtmlTitle = (value = "") => `[${value.toUpperCase()}] Edit`;

const LayoutBasic = createLayoutBasic(ChangeOrderLayoutConfig);

export const useResetStateOnRouteChange = (resetState: () => void) => {
  const history = useHistory();
  const location = useLocation();
  const prevLocationRef = useRef(location);

  useEffect(() => {
    const unlisten = history.listen((newLocation, action) => {
      if (
        (action === "PUSH" || action === "POP") &&
        prevLocationRef.current.pathname !== newLocation.pathname
      ) {
        mandatoryApprovers.resetState();
        mandatoryNotifiers.resetState();
        mandatoryExternalUsers.resetState();
        resetState();
        resetEditorState();
      }
      prevLocationRef.current = newLocation;
    });

    return () => {
      unlisten();
    };
  }, [history, resetState]);

  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);
};

export const EditChangeOrderPage = () => {
  const initialLoad = useRef(false);
  const params = useParams<{ id: string }>();
  useResetStateOnRouteChange(resetState);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const location = useLocation<{ component: string }>();
  const { component: componentId } = location.state ?? {};
  const { loading, error, changeorder } = useChangeOrderLoad(
    params.id,
    componentId
  );

  useEffect(() => {
    if (changeorder && !initialLoad.current && !loading) {
      initialLoad.current = true;
      sdk.changeorder.setInitialChangeOrder(changeorder);
    }
  }, [changeorder, loading]);

  function title() {
    if (changeorder)
      return makeTitle(getHtmlTitle(changeorder.con.displayValue));
    else if (loading) return makeTitle("Loading...");
    return makeTitle("Error");
  }

  const searchParams = new URLSearchParams(location.search);
  const searchTab = searchParams.get("tab");
  const selectedTab = parseInt(searchTab ?? "0");
  const errorMessage = !!error || (!changeorder && !loading);
  const tabs = TabsConfig(changeorder);
  const sidebarPresent = !!tabs[selectedTab].sidebar;

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{title()}</title>
      </Helmet>

      {loading && <LoadingSpinner />}

      {errorMessage && (
        <Box
          display={"flex"}
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box fontSize={"20px"}>
            <p style={{ lineHeight: "28px" }}>
              There was an issue finding this change order.
            </p>
            <p>Please refresh the page or reach out to support.</p>
          </Box>
        </Box>
      )}
      {changeorder && (
        <LayoutBasic.Container sx={{ paddingBottom: "30px" }}>
          <FullscreenTopbar>
            <LayoutBasic.Toolbar
              sx={{ boxShadow: "0 0 1px rgba(0, 0, 0, 0.4)" }}
              sidebarWidth={
                sidebarPresent ? ChangeOrderLayoutConfig.sidebarWidth : 0
              }
            >
              <LayoutToolbarInner>
                <LayoutToolbarLeft>
                  <EditIcon />

                  <StyledTypography>
                    You are Currently in Edit Mode.
                  </StyledTypography>
                </LayoutToolbarLeft>

                <LayoutToolbarRight>
                  <ChangeOrderToolbar />
                </LayoutToolbarRight>
              </LayoutToolbarInner>
            </LayoutBasic.Toolbar>
          </FullscreenTopbar>

          <LayoutBasic.Content
            sx={{ paddingBottom: "500px" }}
            sidebarWidth={
              sidebarPresent ? ChangeOrderLayoutConfig.sidebarWidth : 0
            }
          >
            <LayoutBasic.TopFold>
              <ChangeOrderForm />
            </LayoutBasic.TopFold>
            <LayoutBasic.BottomFold>
              <FullscreenWrapper>
                <BottomFold>
                  <ChangeOrderEditTabs tabs={tabs} />
                </BottomFold>
              </FullscreenWrapper>
            </LayoutBasic.BottomFold>
          </LayoutBasic.Content>

          {sidebarPresent && (
            <LayoutBasic.Sidebar>
              <Box
                width="100%"
                height="99%"
                borderTop={`1px solid ${colorPalette.dark4}`}
                ref={sidebarRef}
              >
                {[OrderSideBar, ApproversSideBar, NotificationsSideBar].map(
                  (Component, index) => (
                    <Box
                      display={selectedTab !== index ? "none" : ""}
                      height="100%"
                      key={index}
                    >
                      <Component selectedTab={selectedTab} />
                    </Box>
                  )
                )}
              </Box>
            </LayoutBasic.Sidebar>
          )}
        </LayoutBasic.Container>
      )}
      <MandatoryApproversPopOver />
    </ErrorBoundary>
  );
};

const ChangeOrderToolbar = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const isNewChangeOrder = params.id === "order";
  const [isValid] = sdk.storage.isValid.useStore();
  const [isNameFieldError] = sdk.storage.isNameFieldError.useStore();
  const [rows] = sdk2.state.rows.useStore();
  const handleGoBack = () =>
    history.push({ pathname: "/search", state: { query: "type:co" } });
  const handleSaveDraft = () =>
    sdk2.submit.submitChangeorder("SAVE_DRAFT", isNewChangeOrder);
  const handleSubmit = () =>
    sdk2.submit.submitChangeorder("SUBMIT", isNewChangeOrder);
  const [isDateError] = sdk.storage.isErpOptionsEffectivityDateError.useStore();

  const tooltipMessageArray = [];

  if (rows.length === 0)
    tooltipMessageArray.push(
      "Please add at least one product/component to validate"
    );
  else if (!isValid)
    tooltipMessageArray.push("Please validate before submitting for approval");

  if (isNameFieldError)
    tooltipMessageArray.push(
      "Please name your Change Order to submit for approval"
    );

  if (isDateError)
    tooltipMessageArray.push("Please select valid Effectivity dates");

  let tooltipMessages = tooltipMessageArray.join("; ");

  return (
    <>
      <Button
        color="secondary"
        variant={ButtonVariants.OUTLINED}
        onClick={handleGoBack}
      >
        Cancel
      </Button>

      <ValidateButton />

      {isNameFieldError || isDateError ? (
        <DuroTooltip
          variant={TooltipVariants.INFO}
          title={
            isNameFieldError
              ? "Please name your Change Order to save draft"
              : isDateError
              ? "Please select valid Effectivity dates"
              : ""
          }
        >
          <ToolbarButton
            disabled={isNameFieldError || isDateError}
            onClick={handleSaveDraft}
          >
            <SaveOutlined />
            Save Draft
          </ToolbarButton>
        </DuroTooltip>
      ) : (
        <ToolbarButton
          onClick={handleSaveDraft}
          disabled={isNameFieldError || isDateError}
        >
          <SaveOutlined />
          Save Draft
        </ToolbarButton>
      )}

      {!isValid || isNameFieldError || rows.length === 0 || isDateError ? (
        <DuroTooltip variant={TooltipVariants.INFO} title={tooltipMessages}>
          <ToolbarButton
            disabled={
              !isValid || isNameFieldError || rows.length === 0 || isDateError
            }
            onClick={handleSubmit}
          >
            <SendOutlined />
            Submit for Approval
          </ToolbarButton>
        </DuroTooltip>
      ) : (
        <ToolbarButton
          disabled={
            !isValid || isNameFieldError || rows.length === 0 || isDateError
          }
          onClick={handleSubmit}
        >
          <SendOutlined />
          Submit for Approval
        </ToolbarButton>
      )}
    </>
  );
};

const ToolbarButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <DuroButton
      {...rest}
      color="secondary"
      sx={{
        "*": {
          height: "1rem",
          width: "1rem",
          fontSize: "1.5rem",
          marginRight: "5px",
        },
      }}
    >
      {children}
    </DuroButton>
  );
};

const BottomFold = styled("div")({
  background: colorPalette.darkGrey,
  minWidth: "100%",
  paddingBottom: "10rem",
});

const StyledTypography = styled(Typography)({
  display: "flex",
  alignItems: "center",
  height: "auto",
  color: colorPalette.black,
  whiteSpace: "nowrap",
  overflowX: "auto",
  fontSize: 14,
  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
});

const LayoutToolbarInner = styled(Box)({
  padding: "5px 44px",
  width: "100%",
  display: "flex",
});

const LayoutToolbarLeft = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
});

const LayoutToolbarRight = styled(Box)({
  display: "flex",
  gap: "10px",
  marginLeft: "auto",
  justifyContent: "flex-end",
  flexShrink: 0,
});

const EditIcon = styled(CreateOutlined)({
  pointerEvents: "none",
  width: 17,
  height: 17,
  marginRight: 10,
  color: "#17171F",
});
