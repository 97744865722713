import { SvgIcon } from "@mui/material";

export const PrdCmpIcon = () => (
  <SvgIcon
    fontSize="inherit" width="24px" height="24px" viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d={`M3.417 20.667c-.39 0-.723-.135-1-.403A1.288 1.288 0 0 1 2 19.306c0-.39.139-.723
      .417-1a1.364 1.364 0 0 1 1-.417c.37 0 .69.139.958.417.269.277.403.61.403 1 0 .37-.134.69
      -.403.958a1.309 1.309 0 0 1-.958.403zm4.139-.556v-1.667H22v1.667H7.556zm-4.14-6.389c-.388 
      0-.722-.134-1-.403A1.319 1.319 0 0 1 2 12.333c0-.389.139-.717.417-.986a1.388 1.388 0 0 1 
      1-.403c.37 0 .69.14.958.417.269.278.403.602.403.972s-.134.695-.403.973a1.288 1.288 0 0 
      1-.958.416zm4.14-.555V11.5H22v1.667H7.556zm-4.167-6.39c-.389 0-.718-.133-.986-.402A1.341 1.341 
      0 0 1 2 5.389c0-.389.134-.718.403-.986C2.67 4.134 3 4 3.389 4s.717.134.986.403c.269.268.403
      .597.403.986s-.134.717-.403.986a1.341 1.341 0 0 1-.986.403zm4.167-.555V4.556H22v1.666H7.556z`}
      fillRule="evenodd">
    </path>
  </SvgIcon>
);
