import {
    takeEvery,
    all,
    cps,
    put,
    takeLatest
     }                     from "redux-saga/effects";
import UI                  from "../../../action-types/ui";
import COMPONENT           from "../../../action-types/component";
import ITEM_ASSEMBLY       from "../../../action-types/item-assembly";
import buildAction         from "../../../helpers/buildAction";
import API                 from "../../../modules/api";
import Utils               from "../../../modules/utils/";
import {privatePaths}      from "../../../app/routes.js";
import Schemas             from "../../../modules/schemas";


export function* findComponentById(action)
{
    let {id} = action.payload
    try
    {
        let component = yield cps(API.components.findById, id)
        if (!component)
        {
            action.payload.history.push({pathname: "/components", state: {query: "type:cmp"}})
        }
        else
        {
            let urlParams  = new URLSearchParams(window.location.search);
            let viewRecent = urlParams.has('viewRecent') ? urlParams.get('viewRecent') : null;
            if(component.revisions.length > 1 && Utils.checkForRestrictedRole(window.__userRole) && !viewRecent)
            {
                let lastReleasedRev = Utils.getLastReleasedRevision(component.revisions);
                action.payload.history.push(`/component/revision/${lastReleasedRev._id}`);
            }
            yield put(buildAction(COMPONENT.RESET_STATES_IN_VIEW_PAGE, {} ));
            let isAssembly = Schemas.component.category.getType(component.category).toLowerCase() === "assembly";
            let documents_length = component.documents.length;
            if(!isAssembly || (component.children && component.children.length == 0)){
                yield put(buildAction(COMPONENT.SET_COMPONENT_CHILDREN_LOADED, true ));
            }
            if(documents_length == 0){
                yield put(buildAction(COMPONENT.SET_COMPONENT_DOCUMENTS_LOADED, true ));
            }

            if (component.variantGroup)
            {
                let variants   = yield cps(API.components.getComponentVariants, component.variantGroup, {})
                component.variants = variants
            }

            const companyResponse = yield cps(API.companies.getCurrent);

            yield put(buildAction(COMPONENT.SET_COMPONENT, {
                ...component,
                cpnId: component.cpnId._id,
                cpnData: component.cpnId,
            }))
            yield put(buildAction(COMPONENT.SET_LOADING, false ))
        }

    }
    catch(err)
    {
        let payload =
                    {
                        type: "errors",
                        errors: err.errors,
                        err: err,
                        closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.componentSearch)
                            }
                    }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
}

export function* findComponentNestedDataById(action)
{
    let {id, name} = action.payload;
    try
    {
        let component = yield cps(API.components.findById, id);
        if (!component)
        {
            action.payload.history.push({ pathname: "/components", state: {query: "type:cmp"}});
        }
        else
        {
            if(name == "documents")
            {
                let {documents} = component;
                yield put(buildAction(COMPONENT.SET_COMPONENT_NESTED_DOCUMENTS, documents));
                yield put(buildAction(COMPONENT.SET_COMPONENT_DOCUMENTS_LOADED, true ));
            }
            else {
                let {children} = component;
                yield put(buildAction(COMPONENT.SET_COMPONENT_NESTED_CHILDREN, children));
                yield put(buildAction(COMPONENT.SET_COMPONENT_CHILDREN_LOADED, true ));
            }

        }

    }
    catch(err)
    {

    }
}

export function* makeDuplicate(action)
{
    let componentId = action.payload.componentId
    const isFreeFormOverride = action.payload.isFreeFormOverride
    try
    {
        const duplicate = yield cps(API.components.createDuplicate, {id: componentId, overrideWithFreeFormCpn: isFreeFormOverride});
        action.payload.history.push({pathname: (`/component/edit/${duplicate}`), showAll: true});
        yield put(buildAction(COMPONENT.RESET_COMPONENT_EDIT_FORM, {}));
        yield put(buildAction(COMPONENT.SET_EDIT_PAGE_MODIFIED, true ));
        yield put(buildAction(COMPONENT.SET_IS_NEW_OBJECT_FLAG, true ));
        yield put(buildAction(COMPONENT.SET_ORIGNAL_COMPONENT_ID, componentId));
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}));
    }
}

export function* markFavourite(action)
{
    try
    {
        let {component, user} = action.payload
        let favs  = user.preferences.favorite.components
        let id    = component._id
        let index = favs.indexOf(id)
        index > -1 ? favs.splice(index, 1) : favs.push(id)
        yield cps(API.users.update, user._id, user)
        yield put(buildAction(COMPONENT.SET_COMPONENT, component))
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

export function* revertChanges(action)
{
    try
    {
        let {componentId, component} = action.payload;
        if(typeof(componentId) === 'object')
        {
            componentId = componentId._id;
        }
        let previousComponent = yield cps(API.componentRevision.findById, componentId);

        try
        {
            let data = getData(component, previousComponent );
            let revised_component = yield cps(API.components.update, component._id, data);
            yield put(buildAction(COMPONENT.RESET_STATES_IN_VIEW_PAGE, {} ));
            yield put(buildAction(COMPONENT.GET_COMPONENT, {id: `${revised_component}?include=children,documents,creator,images`}));

            let payload = {_id: component._id};
            yield put(buildAction(ITEM_ASSEMBLY.GET_LATEST_CHILDREN, payload));

        }
        catch(err)
        {
            let { component } = action.payload;
            let payload =
            {
                type: "errors",
                errors: err.errors,
                err
            };

            if (err.errors[0].message.includes('This Component is in an unresolved Change Order with Status:'))
            {
                payload.errorType = "custom";
                payload.closeCb = () => { Utils.redirectToParentRoute(`/component/view/${component._id}`) };
                payload.confirmBtnText = "OK";
                payload.errorHeading = "Error";
            }

            yield put(buildAction(UI.SHOW_ALERT, payload));
        }
    }
    catch(err)
    {

        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}));
    }
}

export function* createVariant(action)
{
    let componentId = action.payload.componentId
    const isFreeFormOverride = action.payload.isFreeFormOverride
    const data = {};
    if (isFreeFormOverride) {
        data.overrideWithFreeFormCpn = isFreeFormOverride;
    }
    try
    {
        const variantId = yield cps(API.components.createVariant, componentId, data)
        action.payload.history.push({pathname: (`/component/edit/${variantId}`), showAll: true, isVariant: true});
        yield put(buildAction(COMPONENT.RESET_COMPONENT_EDIT_FORM, {}));
        yield put(buildAction(COMPONENT.SET_EDIT_PAGE_MODIFIED, true ));
        yield put(buildAction(COMPONENT.SET_IS_NEW_OBJECT_FLAG, true ));
        yield put(buildAction(COMPONENT.SET_ORIGNAL_COMPONENT_ID, componentId));
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))

    }
}

function  getData(newComponent, originalData)
{
    let component = Utils.clone(newComponent)

    component.name               = originalData.name
    component.revision           = originalData.revision
    component.status             = originalData.status
    component.eid                = originalData.eid
    component.description        = originalData.description
    component.images             = originalData.images
    component.documents          = originalData.documents
    component.children           = originalData.children
    component.manufacturers      = originalData.manufacturers
    component.modified           = false
    component.category           = originalData.category
    component.cpn                = originalData.cpn
    component.specs              = originalData.specs
    component.revertFlag         = true
    component.cpnVariant         = originalData.cpnVariant
    component.previousStatus     = originalData.status
    component.previousRevision   = originalData.revision
    component.mass               = originalData.mass
    component.unitOfMeasure      = originalData.unitOfMeasure
    component.revisionManaged    = originalData.revisionManaged
    component.customSpecs = originalData.customSpecs;
    component.revisions.pop();
    component.cpnId = originalData.cpnId
    component.massStatus = originalData.massStatus;
    return component
}

export default function* (getState)
{
    yield all([
        takeEvery(COMPONENT.GET_COMPONENT, findComponentById),
        takeEvery(COMPONENT.MAKE_DUPLICATE, makeDuplicate),
        takeLatest(COMPONENT.REVERT_CHANGES, revertChanges),
        takeEvery(COMPONENT.MARK_FAVOURITE, markFavourite),
        takeEvery(COMPONENT.GET_COMPONENT_NESTED_DATA, findComponentNestedDataById),
        takeLatest(COMPONENT.CREATE_VARIANT, createVariant),
        ])
}

