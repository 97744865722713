import { Box, Link } from "@mui/material";
import { colorPalette } from "@duro/themes";
import {
  AddComponentsBar,
  LeadingText,
  SearchContainer,
  TableContainer,
  SearchField,
  SidebarWrapper,
  TableActionNavHeader,
} from "features/changeorders/components";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Fragment, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { sdk, ChangeOrderLayoutConfig } from "features/changeorders";
import { styled } from "@mui/material";
import { Grid, ToolBarItem } from "common/components/grid";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { AutofitIcon } from "assets/icons";
import { ButtonVariants, ToolbarItemType } from "common/constants";
import {
  ChangeOrderUser,
  ExternalEmailsNotified,
} from "design/models/changeorder";
import DeleteSrc from "v1/assets/icons/cross-icon";
import { CHANGE_ORDER_APPROVERS } from "graphql/query/changeOrdersQueries";
import { DuroButton } from "common/components";
import {
  DuroVirtualList,
  DuroVirtualColumn,
} from "common/components/duro-vlist";
import { CustomTextArea, FieldTitle } from "common/components/inputs";
import validator from "validator";
import { createStore } from "common/context/store";

export const mandatoryNotifiers = createStore<string[]>([]);
export const mandatoryExternalUsers = createStore<string[]>([]);

export const NotificationsTab = () => {
  const [requiredNotifiers] = mandatoryNotifiers.useStore();
  const [requiredExternalUsers] = mandatoryExternalUsers.useStore();
  const [internalNotifiers, setInternalNotifiers] =
    sdk.storage.coInternalNotifyUsers.useStore();
  const [externalNotifiers, setExternalNotifiers] =
    sdk.storage.coExternalNotifyUserEmails.useStore();
  const selectedNotifiersCount = internalNotifiers.length;
  const selectedExternalNotifiersCount = externalNotifiers.length;

  const handleDeleteAllNotifiers = () => {
    setInternalNotifiers((prev) =>
      prev.filter((i) => requiredNotifiers.includes(i.id))
    );
  };

  const handleDeleteNotifiers = (userId: string) => () => {
    setInternalNotifiers((prev) =>
      prev.filter((notifier) => notifier.id !== userId)
    );
  };

  const handleDeleteAllExternalNotifiers = () => {
    setExternalNotifiers((prev) =>
      prev.filter((i) => requiredExternalUsers.includes(i))
    );
  };

  const handleDeleteExternalNotifiers = (id: string) => () => {
    setExternalNotifiers((prev) => prev.filter((notifier) => notifier !== id));
  };

  const buildExternalNotifiers = (
    emails: string[]
  ): ExternalEmailsNotified[] => {
    return emails.map((email) => ({
      id: email,
      email: email,
    }));
  };

  const notifiersColumns: GridColDef<ChangeOrderUser>[] = [
    {
      field: "user",
      headerName: "USER",
      renderCell({ row }) {
        return `${row.firstName} ${row.lastName}`;
      },
      hideable: true,
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "groups",
      headerName: "GROUPS",
      renderCell({ row }) {
        return row.groups.join(", ");
      },
      hideable: true,
      sortable: false,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "delete",
      headerName: "",
      renderHeader() {
        return (
          <StyledDeleteIcon onClick={handleDeleteAllNotifiers} sx={{ ml: 1 }}>
            <DeleteSrc />
          </StyledDeleteIcon>
        );
      },
      renderCell({ row }) {
        if (requiredNotifiers.includes(row.id)) return <Fragment />;

        return (
          <StyledDeleteIcon onClick={handleDeleteNotifiers(row.id)}>
            <DeleteSrc />
          </StyledDeleteIcon>
        );
      },
      width: 20,
      sortable: false,
    },
  ];

  const externalNotifiersColumns: GridColDef<ExternalEmailsNotified>[] = [
    {
      field: "email",
      headerName: "EMAIL",
      renderCell({ row }) {
        return `${row.email}`;
      },
      hideable: true,
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "delete",
      headerName: "",
      renderHeader() {
        return (
          <StyledDeleteIcon
            onClick={handleDeleteAllExternalNotifiers}
            sx={{ ml: 1 }}
          >
            <DeleteSrc />
          </StyledDeleteIcon>
        );
      },
      renderCell({ row }) {
        if (requiredExternalUsers.includes(row.id)) return <Fragment />;

        return (
          <StyledDeleteIcon onClick={handleDeleteExternalNotifiers(row.id)}>
            <DeleteSrc />
          </StyledDeleteIcon>
        );
      },
      width: 20,
      sortable: false,
    },
  ];

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return (
    <>
      <TableActionNavHeader
        sx={{
          width: "400px",
          lineHeight: "20px",
          whiteSpace: "pre-wrap",
          marginBottom: "24px",
        }}
      >
        Add users to be notified with final change order result.{"\n"}
        Note: Users in your{" "}
        <Link
          onClick={() => {
            searchParams.set("tab", "1");
            history.push({ search: searchParams.toString() });
          }}
          sx={{
            color: colorPalette.lightGreen,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          approver list
        </Link>{" "}
        will automatically be notified.
      </TableActionNavHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Grid
          getRowId={(row) => row.id}
          apiRef={useGridApiRef()}
          name="Notifiers"
          columnDefinition={notifiersColumns}
          data={internalNotifiers}
          enableCellFocus={false}
          toolbarItems={toolbarItems}
          autoHeight
          totalCount={
            `${selectedNotifiersCount} ` +
            (selectedNotifiersCount === 1 ? `User` : `Users`)
          }
          pinnedColumns={{ right: ["delete"] }}
        />
        <Grid
          getRowId={(row) => row.id}
          apiRef={useGridApiRef()}
          name="External Notifiers"
          columnDefinition={externalNotifiersColumns}
          data={buildExternalNotifiers(externalNotifiers)}
          toolbarItems={toolbarItems}
          autoHeight
          totalCount={
            `${selectedExternalNotifiersCount} ` +
            (selectedExternalNotifiersCount === 1 ? `Email` : `Emails`)
          }
          pinnedColumns={{ right: ["delete"] }}
          noRowsOverlayProps={{
            message: "No Emails Added",
          }}
        />
      </Box>
    </>
  );
};

export const NotificationsSideBar = () => {
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [internalNotifiers] = sdk.storage.coInternalNotifyUsers.useStore();
  const [inputExternalNotifiers, setInputExternalNotifiers] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const [text, setText] = useState("");
  const { data, error, loading } = useQuery<{
    userById: {
      primaryCompany: {
        users: ChangeOrderUser[];
      };
    };
  }>(CHANGE_ORDER_APPROVERS, {
    fetchPolicy: "cache-first",
  });

  const userData = data?.userById.primaryCompany.users ?? [];
  const filtered = userData.filter((notifier) =>
    (
      notifier.firstName.toLowerCase() + notifier.lastName.toLowerCase()
    ).includes(text.toLowerCase())
  );
  const invalidEmails = (value: string) => {
    const emails = value.split(",").map((email) => email.trim());
    const invalidEmails = emails.filter((email) => !validator.isEmail(email));
    return invalidEmails.length !== 0;
  };

  const sidebarColumns: DuroVirtualColumn<ChangeOrderUser>[] = [
    {
      field: "firstName",
      headerName: "select all",
      renderCell(row) {
        return `${row.firstName} ${row.lastName}`;
      },
      width: 260,
    },
  ];

  return (
    <SidebarWrapper>
      <AddComponentsBar>
        <LeadingText mt={0}>Add Users to Notify</LeadingText>
        <SearchContainer>
          <SearchField
            placeholder="Enter existing user"
            onChange={(e) => setText(e.target.value)}
            value={text}
            fullWidth
          />
        </SearchContainer>
      </AddComponentsBar>
      {!loading && (
        <TableContainer ref={ref}>
          <DuroVirtualList
            columns={sidebarColumns}
            excluded={internalNotifiers}
            data={filtered}
            loading={loading}
            error={error?.message}
            rowHeight={40}
            totalCount={userData.length}
            totalHeight={window.innerHeight - 440}
            totalWidth={ChangeOrderLayoutConfig.sidebarWidth}
            onSelectionChange={(selectionIds) => {
              const selectedItems = userData.filter((item) =>
                selectionIds.includes(item.id)
              );
              sdk.storage.pendingInternalNotifyUsers.setState(
                () => selectedItems
              );

              setAddButtonDisabled(selectedItems.length === 0);
            }}
            headerSlot={
              <Box
                sx={{
                  flex: "1",
                  paddingRight: "1rem",
                  position: "absolute",
                  right: "0",
                }}
              >
                <DuroButton
                  variant={ButtonVariants.OUTLINED}
                  disabled={
                    sdk.storage.pendingInternalNotifyUsers.getState().length ==
                    0
                  }
                  onClick={() => {
                    sdk.notificationList.addUsersToBeNotified();
                  }}
                >
                  Add
                </DuroButton>
              </Box>
            }
          />
        </TableContainer>
      )}
      <Box
        sx={{
          flex: "0 0 45px",
          padding: "0px 15px 0px 15px",
        }}
      >
        <FieldTitle
          label="Need to notify non-Duro users?"
          sx={{
            color: colorPalette.white,
            fontSize: "0.875rem",
            marginBottom: "1rem",
          }}
        />
        <CustomTextArea
          value={inputExternalNotifiers}
          sx={{
            fontSize: "0.875rem",
            height: "4rem !important",
            backgroundColor: "transparent",
            color: colorPalette.white,
            border: "2px solid #4D4D4D",
          }}
          onChange={(e) => setInputExternalNotifiers(e.target.value)}
          placeholder="Enter emails separated by commas"
        />
        <DuroButton
          variant={ButtonVariants.OUTLINED}
          fullWidth
          sx={{ mt: 1.5, mb: 1 }}
          disabled={invalidEmails(inputExternalNotifiers)}
          onClick={() => {
            sdk.notificationList.addExternalEmailsToBeNotified(
              inputExternalNotifiers
            );
            setInputExternalNotifiers("");
          }}
        >
          Add Emails
        </DuroButton>
      </Box>
    </SidebarWrapper>
  );
};

const toolbarItems: ToolBarItem[] = [
  {
    disabled: true,
    Icon: AutofitIcon,
    label: "Autofit",
    type: ToolbarItemType.ACTION,
  },
];

const StyledDeleteIcon = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  cursor: "pointer",
});
