import { gql, useQuery } from "@apollo/client";
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { ChangeOrderHistoryEvent } from "design/models/changeorder";
import AccountBoxIcon from "@mui/icons-material/AccountCircleOutlined";
import { FC } from "react";
import { Grid } from "common/components/grid";

type UserInfo = {
  firstName: string;
  lastName: string;
  avatar: {
    src: string;
    name: string;
    id: string;
  } | null;
};

function formatTimestamp(isoString: string, locale: string = "en-US"): string {
  const date = new Date(isoString); // Parse the ISO string into a Date object

  // Format the date part as: Oct 16, 2024
  const datePart = date.toLocaleDateString(locale, {
    year: "numeric",
    month: "short", // Use abbreviated month (e.g., Oct)
    day: "2-digit",
  });

  // Format the time part as: 09:13 AM
  const timePart = date.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true, // Use 12-hour format with AM/PM
  });

  return `${datePart} ${timePart}`;
}

export type HistoryCellParams = GridRenderCellParams<
  GridValidRowModel,
  ChangeOrderHistoryEvent
>;

const statusColors: Record<string, string> = {
  PENDING: "#FFFD91",
  APPROVED: "#7AE173",
  REJECTED: "red",
  EDITED: "#9ACCFF",
  CREATED: "#FFFD91",
  SUBMITTED: "#F4A543",
  VIEWED: "#B383DE",
  FINALIZING: "#F4A543",
};

const columns: GridColDef[] = [
  {
    field: "user",
    sortable: false,
    width: 200,
    renderCell({ row }: HistoryCellParams) {
      const { data } = useQuery<{ userById: UserInfo }>(
        gql`
          query UserById($id: ID) {
            userById(id: $id) {
              firstName
              lastName
              avatar {
                src
                name
                id
              }
            }
          }
        `,
        {
          variables: {
            id: row.user.id,
          },
        }
      );

      const user = data?.userById ?? {
        firstName: "",
        lastName: "",
        avatar: null,
      };

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {user.avatar ? (
            <img
              src={user.avatar.src}
              style={{
                width: 22,
                borderRadius: "50%",
              }}
            />
          ) : (
            <AccountBoxIcon />
          )}
          <span style={{ marginLeft: 5 }}>
            {user.firstName} {user.lastName}
          </span>
        </div>
      );
    },
  },
  {
    field: "created",
    sortable: false,
    width: 200,
    renderCell({ row }: HistoryCellParams) {
      return <div>{formatTimestamp(row.created)}</div>;
    },
  },
  {
    field: "action",
    sortable: false,
    width: 115,
    renderCell({ row }: HistoryCellParams) {
      return (
        <div style={{ color: statusColors[row.action] ?? "#fff" }}>
          {row.action}
        </div>
      );
    },
  },
  {
    field: "comment",
    sortable: false,
    width: 240,
    renderCell({ row }: HistoryCellParams) {
      return <div>{row.comment}</div>;
    },
  },
];

export const HistoryTab: FC<{ history: ChangeOrderHistoryEvent[] }> = ({
  history,
}) => {
  return (
    <div style={{ display: "flex", height: 500, width: 875 }}>
      <Grid
        columnDefinition={columns}
        data={history}
        getRowId={(row) => row.created}
        name="History"
        disableCheckbox
      />
    </div>
  );
};
