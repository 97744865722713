import { SvgIcon } from "@mui/material";

export const ApproversIcon = () => (
  <SvgIcon
    fontSize="inherit" width="24px" height="24px" viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path d={`M10.4210526,11.4210526 C12.7473684,11.4210526 14.6315789,9.53684211 14.6315789,
      7.21052632 C14.6315789,4.88421053 12.7473684,3 10.4210526,3 C8.09473684,3 6.21052632,
      4.88421053 6.21052632,7.21052632 C6.21052632,9.53684211 8.09473684,11.4210526 10.4210526,
      11.4210526 Z M10.4210526,5.10526316 C11.5789474,5.10526316 12.5263158,6.05263158
      12.5263158,7.21052632 C12.5263158,8.36842105 11.5789474,9.31578947 10.4210526,9.31578947
      C9.26315789,9.31578947 8.31578947,8.36842105 8.31578947,7.21052632 C8.31578947,6.05263158
      9.26315789,5.10526316 10.4210526,5.10526316 Z M4.10526316,17.7368421 C4.31578947,
      17.0736842 6.81052632,15.9684211 9.32631579,15.6947368 L11.4736842,13.5894737 C11.0631579,
      13.5473684 10.7578947,13.5263158 10.4210526,13.5263158 C7.61052632,13.5263158 2,
      14.9368421 2,17.7368421 L2,19.8421053 L11.4736842,19.8421053 L9.36842105,17.7368421
      L4.10526316,17.7368421 Z M20.5263158,11.9473684 L15.1263158,17.3894737 L12.9473684,15.2
      L11.4736842,16.6842105 L15.1263158,20.3684211 L22,13.4315789 L20.5263158,11.9473684 Z`}
      fillRule="evenodd">
    </path>
  </SvgIcon >
);
