import "./index.scss"
import React, {Component}       from "react"
import Utils                    from "../../../../../modules/utils"
import config                   from "../../../../../modules/config"
import Tooltip                  from 'rc-tooltip'
import 'react-perfect-scrollbar/dist/css/styles.css'

export class RevActionModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            applyBtnClass: "",
            showLoading: false,
            revActionModal: {
                open : false
            },
            showInnerLoading:false,
            list : [],
            actionType: this.props.item.revActionType || "minor",
            nextRevisionInput:
            {
                class: "",
                message: "",
                valid: true,
                value:  this.props.item.nextCoRevision
            },
            itemStatus: this.props.item
        }

        this.openrevActionModal            = this.openrevActionModal.bind(this)
        this.closeRevActionModal           = this.closeRevActionModal.bind(this)
        this.onrevActionModalExternalClick = this.onrevActionModalExternalClick.bind(this)
        this.setMyState                    = this.setMyState
        this.onInputChange                 = this.onInputChange.bind(this)
        this.applyRevAction                = this.applyRevAction.bind(this)
    }

    applyRevAction(event)
    {
        if (this.state.actionType === "custom")
        {
            this.props.applyRevAction(this.state.actionType, this.props.item, this.props.index, this.state.nextRevisionInput.value)
        }
        else
        {
            this.props.applyRevAction(this.state.actionType, this.props.item, this.props.index)
        }
        this.closeRevActionModal()
    }

    onInputChange(event)
    {
        let state = this.state
        let name = event.target.name
        switch(name)
        {
            case "custom-rev-input" :
            {
                let {nextRevisionInput} = state
                let {item} = this.props
                nextRevisionInput.value = event.target.value

                let {validateField} = this.props
                let {validations} = this.props

                if (nextRevisionInput.value === item.revision)
                {
                    nextRevisionInput.class = "invalid"
                    nextRevisionInput.message = "Revision value should be greater than last saved revision"
                    nextRevisionInput.valid = false
                }
                else
                {
                  validateField(nextRevisionInput, validations.component.revision, nextRevisionInput.value, {status: item.status, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true, previousRevision: item.previousRevision})
                }
                state.applyBtnClass = nextRevisionInput.valid ? "" : "disabled"
                break
            }

            case "custom" :
            {
                let {nextRevisionInput} = state
                state.applyBtnClass = nextRevisionInput.valid ? "" : "disabled"
                state.actionType = event.target.value
                break
            }

            default :
            {
                state.applyBtnClass = ""
                state.actionType = event.target.value
            }
        }
        this.setState(state)
    }

    componentDidMount(){
        this._ismounted = true
        this.openrevActionModal()
    }

    componentDidUpdate()
    {
        if (this.props.setRevActionModalPosition)
        {
            this.props.setRevActionModalPosition()
        }
    }

    componentWillUnmount(){
        this._ismounted = false
    }

    setMyState(state = this.state, cb){
        if (this._ismounted){
            this.setState(state, cb)
        }
    }

    openrevActionModal(event)
    {
        setTimeout(() => document.body.addEventListener("click", this.onrevActionModalExternalClick)); 
        let revActionModal  = this.state.revActionModal
        revActionModal.open = true
        this.setMyState(this.state)
    }

    closeRevActionModal()
    {
        document.body.removeEventListener("click", this.onrevActionModalExternalClick)
        let revActionModal  = this.state.revActionModal
        revActionModal.open = false
        this.setMyState(this.state, this.props.closeRevActionModal)
    }

    onrevActionModalExternalClick(event)
    {
        let target     = event.target
        let parent     = this.refs.revActionModalNode
        let isExternal = target !== parent && !Utils.isDescendant(parent, target)
        if(isExternal) this.closeRevActionModal()
    }

    render()
    {
        let markup    = null
        let {revActionModal} = this.state
        let state = this.state
        markup =
            <div ref="revActionModalNode" className={"rev-action-modal" + (revActionModal.open ? " open" : "")}>
                <div className="header-block">
                    <h2>Change Revision Type</h2>
                </div>
                <div className="rev-action-types">
                <div className="custom-radio">
                        {
                            (window.__revSchemeType === config.revisionTypes.alphaNumericXYZ
                                || window.__revSchemeType === config.revisionTypes.alphaBetaNumericXYZ) &&
                            <div className="radio large">
                                <input
                                    type="radio"
                                    value="documentation"
                                    name="document"
                                    checked={state.actionType === "documentation"}
                                    onChange={(e) => this.onInputChange(e)}
                                />
                                <label htmlFor="radio1" />
                                <span htmlFor="radio2">
                                    {window.__revSchemeType === config.revisionTypes.alphaBetaNumericXYZ ? "Document Change" : "Documentation Change"}
                                </span>
                            </div>
                        }
                        <div className="radio large">
                            <input
                                type="radio"
                                value="minor"
                                name="minor"
                                checked={state.actionType === "minor"}
                                onChange={(e) => this.onInputChange(e)}
                            />
                            <label htmlFor="radio2" />
                            <span htmlFor="radio2">
                                {window.__revSchemeType === config.revisionTypes.alphaBetaNumericXYZ ? "Prototype Change" : "Minor Change"}
                            </span>
                        </div>
                        {
                            (window.__revSchemeType !== config.revisionTypes.alphaBetaNumericXYZ || this.state.itemStatus.initialStatus !== "PROTOTYPE") && (
                                <div className="radio large">
                                    <input
                                        type="radio"
                                        value="major"
                                        name="major"
                                        checked={state.actionType === "major"}
                                        onChange={(e) => this.onInputChange(e)}
                                    />
                                    <label htmlFor="radio3" />
                                    <span htmlFor="radio3">
                                        {window.__revSchemeType === config.revisionTypes.alphaBetaNumericXYZ ? "Production Release" : "Major Change"}
                                    </span>
                                </div>
                            )
                        }
                        {
                          this.props.user.data.role === "ADMINISTRATOR" &&
                           <div className="radio large custom-rev-holder">
                               <input
                                   type="radio"
                                   value="custom"
                                   name="custom"
                                   checked={state.actionType === "custom"}
                                   onChange={(e) => this.onInputChange(e)}
                                   />
                             <label htmlFor="radio3" />
                             <span htmlFor="radio3">
                              Custom
                             </span>
                             <span className={`custom-revision-label ${state.actionType === "custom" ? "hidden" : ""}`}>
                              {this.props.item.nextCoRevision}
                             </span>

                              <Tooltip
                                  overlayClassName={`simple-rc-tip error custom-rev-tip ${state.nextRevisionInput.message ? "" : "hidden"}`}
                                  overlay={
                                      <div className={`description-tip ${state.nextRevisionInput.class}`}>
                                          <span>{state.nextRevisionInput.message}</span>
                                      </div>
                                  }
                              >
                              <input
                                  type="text"
                                  value={state.nextRevisionInput.value}
                                  className={`custom-rev-input ${state.nextRevisionInput.class} ${state.actionType !== "custom" ? "hidden" : ""}`}
                                  name="custom-rev-input"
                                  autocomplete="off"
                                  onChange={(event) => this.onInputChange(event)}
                              />
                              </Tooltip>

                           </div>
                       }
                   </div>
                </div>
                <div className="bottom-section">
                        <button
                            type="button"
                            className="cancel-btn"
                            onClick={this.closeRevActionModal}
                            >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className={`apply-btn fill ${state.applyBtnClass}`}
                            onClick={this.applyRevAction}
                            >
                            Apply
                        </button>
                </div>
            </div>
        return markup
    }
}

export default RevActionModal
