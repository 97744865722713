import React          from 'react';
import {Link}       from 'react-router-dom';
import Select, {components} from 'react-select';
import ArrowIcon            from "../../../assets/icons/arrow-icon";
import InlineIcon           from "../icon/inline-icon";
import LinkIcon             from "../../../assets/icons/open-link-icon.js";
import "./index.scss";
import API                  from "../../../modules/api";
import { connect }          from "react-redux";

class CustomReactSelect extends React.Component {

  constructor(props, context)
  {
    super(props);
    this.formatGroupLabel       = this.formatGroupLabel.bind(this);
    this.getCustomStyles        = this.getCustomStyles.bind(this);
    this.createNewCO            = this.createNewCO.bind(this);
  }

  getCustomStyles()
  {
      return {
          option: (provided, state) => ({
              ...provided,
              color: '#18171d',
              padding: "5px 12px",
              backgroundColor: state.isSelected ? 'none' : provided.backgroundColor
          }),
          control: (provided, state) => ({
              ...provided,
                  border: state.menuIsOpen ? "1px solid #fff" : "1px solid #888",
                  borderRadius: 0,
                  boxShadow: 0,
                  minHeight: "30px",
                  backgroundColor: state.menuIsOpen || this.props.value ? "#fff" : "#353540",
                  ":hover": {
                      border: state.menuIsOpen ? "1px solid #fff" : "1px solid #888",
                  },
                  input: {
                      color: state.menuIsOpen ? "#18171d" : "#fff",
                      padding: "0px !important",
                      marginBottom: "0px !important"
                  }
          }),
          indicatorsContainer: (provided, state) => ({
              ...provided,
              ".ui-icon": {
                  width: "10px !important",
                  marginRight: "5px",
                  height: "10px"
              },
              "indicatorContainer": {
                padding: "4px"
              }
          }),
          dropdownIndicator: (provided, state) => ({
              ...provided,
              padding: "4px",
              minHeight: "24px",
              g: {
                  fill: state.selectProps.menuIsOpen || this.props.value ? "#000 !important" : "#fff !important"
              }

          }),
          groupHeading: (provided, state) => ({
              ...provided,
              color: '#888888',
              paddingTop: "0",
              marginBottom: "3px"
          }),
          indicatorSeparator: () => ({
              display:"none"
          }),
          input: (provided, state) => ({
              ...provided,
              color: '#18171d',
          }),
          placeholder: (provided, state) => ({
              ...provided,
              color: state.selectProps.menuIsOpen ? '#18171d' : "#fff",
          }),
          menu: (provided, state) => ({
              ...provided,
              marginTop: "0",
              borderRadius:"0",
              borderTop: '1px solid #000',
          }),
          menuList: (provided, state) => ({
              ...provided,
              paddingTop: this.props.name === 'update-template' ? "10px" : "0"
          }),
          group: (provided, state) => ({
              ...provided,
              paddingTop: "0",
              borderBottom: '1px solid #E2E2E2',
              marginBottom: "14px"
          }),
          singleValue: (provided, state) => ({
              ...provided,
              color: state.selectProps.menuIsOpen || this.props.value ? '#18171d' : "#fff",
          }),
      };
  }

  formatGroupLabel(data)
  {
      let _this = this
      return(<div style={{fontSize: 11}}>
        <span style={{fontSize: 11}}>{data.label}</span>
      </div>)
  }

  createNewCO()
  {
    const userId = this.props.user._id;
    let data =
    {
      "type" : "ECO",
      "name" : "",
      "status" : "DRAFT",
      "resolution" : "NONE",
      "approverList": [{user: userId}]
    }
    API.cos.create(data, (err, response) => {
      if(response)
      {
        window.open(`/changeorder/new/${response}?navigateToSearch=true&tab=approverList`, "_blank");
      }
    })
  }

  render ()
  {
    const DropdownIndicator = props => {
      return (
          <components.DropdownIndicator {...props}>
          <InlineIcon><ArrowIcon /></InlineIcon>
          </components.DropdownIndicator>
      );
    };

    const Menu = (props) => {
      return (
      <div>
        <React.Fragment>
          <components.Menu {...props}>
                <div>{props.children}</div>
                <a target="_blank" className={this.props.noTemplateExists ? "open-link-holder" : ""}>
                  <span className={"link-text"} onClick={this.createNewCO}>Create approver templates
                      <InlineIcon >
                          <LinkIcon/>
                      </InlineIcon>
                  </span>
                </a>
          </components.Menu>
        </React.Fragment>
      </div>
      );
    };
    const componentsData =  this.props.noTemplateExists ? {DropdownIndicator, Menu} : {DropdownIndicator}
    return(
      <React.Fragment>
        <Select
          {...this.props}
          classNamePrefix='duro'
          components={componentsData}
          styles={this.getCustomStyles()}
        />
      </React.Fragment>
    )
  }
}

export default CustomReactSelect;
