export default {
    changeForm: {
      inputs: {
        "types": {
          "value": "mco",
          "valid": true
        },
        "name": {
          "value": "MCO",
          "valid": true
        },
        "description": {
          "value": "Descr",
          "valid": true
        },
        "submit": {
          "class": ""
        }
      },
      list :
      [

      ]
    }
}
