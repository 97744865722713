import { styled, SxProps, Typography } from "@mui/material";
import { FC } from "react";

export interface IFieldTitleProps {
  isRequired?: boolean;
  label: string;
  sx?: SxProps;
}

export const FieldTitle: FC<IFieldTitleProps> = ({ isRequired, label, sx }) => (
  <TitleWrapper sx={sx}>{`${label} ${isRequired ? "*" : ""}`}</TitleWrapper>
);

const TitleWrapper = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "sx",
})<Pick<IFieldTitleProps, "sx">>(({ sx }) => sx as any);
