import { ApolloError } from "@apollo/client";
import { useChangeOrderQuery } from "graphql/query/changeOrdersQueries";
import { useUser } from "graphql/query/userQueries";
import { createDefaultCOState } from "./default-co-state";
import {
  ChangeOrder,
  ChangeOrderERPItemType,
  SuperComponent,
} from "design/models/changeorder";
import { useComponent } from "graphql/query/componentQueries";
import { useComponentTreeQuery } from "graphql/query/componentsQueries";
import { useCompany } from "design/pages/component/hooks";

export const userOptedIntoBeta =
  (localStorage.getItem("changeOrderBetaOptIn") ?? "false") === "true";

type UseNewChangeOrder = {
  error: ApolloError | undefined;
  loading: boolean;
  changeorder: ChangeOrder | undefined;
};

export function useNewChangeOrder(componentId: string = ""): UseNewChangeOrder {
  const { data: user } = useUser();
  const { component, componentLoading } = useComponent({
    id: componentId,
  });

  const { treeData, loading } = useComponentTreeQuery([componentId]);

  const company = useCompany();

  if (!user) {
    throw new Error("User not found.");
  }

  const defaultCOState = createDefaultCOState(user);

  const changeorder = {
    ...defaultCOState,
    erpOptions: {
      effectivity: {
        startDate: "0",
        endDate: "0",
        overrideExisting: false,
        isEnabled: false,
      },
      itemType: {
        isEnabled: false,
        overrideExisting: false,
        value: ChangeOrderERPItemType.NONE,
      },
      additionalPayloadForNotifications: {
        childComponent:
          company?.data?.settings?.configurations.addChildComponentsInCO,
        parentAssemblies:
          company?.data?.settings?.configurations.addParentAssembliesInCO,
      },
    },
    loading,
    tree: treeData,
    treeData: treeData.reduce(
      (acc, item) => ({
        ...acc,
        [item.treeId]: {
          ...item,
          id: item.cpn.displayValue,
        },
      }),
      {}
    ),
    ...(component
      ? { components: [component as unknown as SuperComponent] }
      : {}),
  };

  return {
    loading: componentLoading || loading,
    changeorder,
    error: undefined,
  };
}

export function useChangeOrderLoad(id: string, componentId?: string) {
  if (id === "order") {
    return useNewChangeOrder(componentId);
  } else {
    return useChangeOrderQuery();
  }
}
