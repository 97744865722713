import { SuperComponent } from "design/models/changeorder";
import {
  ClientModulesSchemaValidationResult,
  defaultClientModulesSchemaValidationResult,
  ValidationErrorType,
} from "../validation.types";
import { sdk } from "../../editor";

export function validateObsoleteChildStatuses(
  item: SuperComponent
): ClientModulesSchemaValidationResult {
  const treeDict = sdk.state.treeDict.getState();

  const nodes: SuperComponent[] = [];

  for (const id in treeDict) {
    const treeNode = treeDict[id];

    if (id.includes(item.id) && treeNode.path.at(-1) !== item.id) {
      nodes.push(treeNode);
    }
  }

  for (const node of nodes) {
    const nodeStatus = sdk.state.getChangeset(node).status.getState();

    if (nodeStatus === "OBSOLETE") {
      return {
        valid: false,
        value: item.id,
        message: "One or more child Components has a Status of Obsolete.",
        type: ValidationErrorType.ObsoleteChildren,
        children: [],
      };
    }
  }

  return defaultClientModulesSchemaValidationResult;
}
