import { AddCircleRounded, CancelRounded, Edit } from "@mui/icons-material";
import {
  ClickAwayListener,
  Input,
  Popper,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { SuperComponent } from "design/models/changeorder";
import { ChangesetStorage } from "features/changeorders/sdk";
import { FC, useEffect, useState } from "react";
import { colorPalette } from "@duro/themes";
import { getWindow } from "utils/window";
import { useDebounce } from "use-debounce";
import { sdk } from "features/changeorders/sdk/editor";
import { ClientModulesSchemaValidationResult } from "features/changeorders/sdk/utils/validation.types";
import {
  ChangeRevisionContent,
  getRevisionTypeLabels,
} from "./revision.custom";
import { useUser } from "graphql/query/userQueries";
import schemas, { validateField } from "v1/modules/validations";
import { normalizeRevision } from "features/changeorders/sdk/editor/revisions";

const cancelIconSize = 15;

type LockedRevisionFieldProps = {
  displayValue?: string;
  revisionIsRemovable: boolean;
  onRemove: () => void;
  originalStatus: string;
  modifiedStatus: string;
  previousRevisionValue: string;
  onRevert: () => void;
  showRemoveIcon: boolean;
  item: SuperComponent;
};

export const hasCustomRevisionScheme = () =>
  !["DEFAULT", "NUMERIC-XY", "ALPHA-BETA-AB"].includes(
    getWindow().__revSchemeType
  );

function userCanRevertRevision(item: SuperComponent) {
  const validStatus = ["PROTOTYPE", "PRODUCTION"].includes(item.status);
  return (
    validStatus &&
    !item.modified &&
    item.vendorInfo?.currentVendors?.at(-1) !== "ONSHAPE"
  );
}

const LockedRevisionField: FC<LockedRevisionFieldProps> = ({
  item,
  displayValue,
  revisionIsRemovable,
  onRemove,
  onRevert,
  originalStatus,
  modifiedStatus,
  previousRevisionValue,
  showRemoveIcon,
}) => {
  const userData = useUser();
  const [error, setError] = useState<string | undefined>();
  const revisionTypes = getRevisionTypeLabels({
    initialStatus: originalStatus,
  });

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Set the clicked element as anchor
    setIsPopperOpen((prev) => !prev); // Toggle the popper state
  };

  const handleClose = () => {
    setIsPopperOpen(false); // Close the popper
    setAnchorEl(null); // Remove the anchor
  };

  return (
    <LockedRevisionFieldContainer>
      <DashedBorderContainer>
        <RevisionLabel>
          {displayValue}
          {revisionIsRemovable && (
            <RemoveRevision>
              {showRemoveIcon && (
                <CancelRounded
                  onClick={onRemove}
                  sx={{ width: cancelIconSize, color: colorPalette.green }}
                />
              )}

              {!showRemoveIcon && (
                <AddCircleRounded
                  onClick={onRevert}
                  sx={{ width: cancelIconSize, color: colorPalette.green }}
                />
              )}
            </RemoveRevision>
          )}
        </RevisionLabel>
      </DashedBorderContainer>

      {hasCustomRevisionScheme() && originalStatus === modifiedStatus && (
        <div>
          <div onClick={handleClick}>
            <OpenCustomRevScheme />
          </div>
          <Popper open={isPopperOpen} anchorEl={anchorEl}>
            <ChangeRevisionContent
              revisionTypes={revisionTypes}
              initialRevisionValue={displayValue ?? ""}
              onApply={(revisionType, value) => {
                if (revisionType === "Custom" && value) {
                  sdk.revisions.updateRevision(item, value);
                } else if (window.__revSchemeType && window.__libraryType) {
                  let validationPayload = {
                    status: modifiedStatus,
                    revSchemeType: window.__revSchemeType,
                    libraryType: window.__libraryType,
                    revActionType: revisionType,
                    currentRevision: item.revisionValue,
                  };

                  const nextRevision = normalizeRevision(
                    validationPayload,
                    item.revisionValue
                  );

                  sdk.revisions.updateRevision(item, nextRevision);
                }

                handleClose();
              }}
              onCancel={() => handleClose()}
              allowCustomOverride={userData.data?.role === "ADMINISTRATOR"}
              itemStatus={{ initialStatus: originalStatus }}
              onDocumentationChange={() => {}}
              onMinorChange={() => {}}
              onMajorChange={() => {}}
              errorMessage={error}
              onCustomChange={(value) => {
                const nextInput = validateField(
                  { value },
                  schemas.component.revision,
                  value,
                  {
                    status: modifiedStatus,
                    revSchemeType: window.__revSchemeType,
                    libraryType: window.__libraryType,
                    isClient: true,
                    previousRevision: previousRevisionValue,
                  }
                );

                setError(nextInput.message);
              }}
            />
          </Popper>
        </div>
      )}
    </LockedRevisionFieldContainer>
  );
};

export function isLockedRevision(status: string, previousStatus?: string) {
  return status === "OBSOLETE" && previousStatus === "OBSOLETE";
}

type RevisionInputProps = {
  item: SuperComponent;
  error: ClientModulesSchemaValidationResult;
  warning: boolean;
  modifiedStatus?: string;
  childObsoleteWarning: boolean;
  coType: string;
  changes: ChangesetStorage;
};

export const RevisionInput: FC<RevisionInputProps> = ({
  item,
  error,
  coType,
  changes,
}) => {
  const [showRemoveIcon, setShowRemoveIcon] = useState(true);
  const [text, setText] = useState(item.legacyNextRevision);
  const [textDebounced] = useDebounce(text, 1000);

  useEffect(() => {
    if (textDebounced !== changes.legacyNextRevision) {
      sdk.revisions.updateRevision(item, textDebounced);
    }
  }, [textDebounced]);

  useEffect(() => {
    if (changes.legacyNextRevision !== text) {
      setText(changes.legacyNextRevision);
    }
  }, [changes.legacyNextRevision]);

  const maxLength = hasCustomRevisionScheme() ? 10 : 3;

  // If there is no change in component status since last release,
  // or the status is OBSOLETE, show the next revision input
  const isEditable =
    item.previousStatus !== changes.status &&
    changes.status !== "OBSOLETE" &&
    coType !== "DCO";

  return isEditable ? (
    <Tooltip
      title={error.message}
      content={error.message}
      arrow
      componentsProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color: colorPalette.black,
            },
            [`.${tooltipClasses.tooltip}`]: {
              backgroundColor: colorPalette.black,
            },
          },
        },
      }}
    >
      <StyledRevisionInput
        type="text"
        value={text}
        onChange={(e) => {
          const value = e.target.value;

          if (value.length < maxLength) {
            setText(e.target.value.toUpperCase());
          } else {
            setText(e.target.value.slice(0, 2));
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        isValid={error.valid}
      />
    </Tooltip>
  ) : (
    <LockedRevisionField
      item={item}
      previousRevisionValue={item.previousRevisionValue}
      showRemoveIcon={showRemoveIcon}
      originalStatus={changes.originalStatus}
      modifiedStatus={changes.status}
      displayValue={text}
      revisionIsRemovable={userCanRevertRevision(item) && coType !== "DCO"}
      onRemove={() => {
        sdk.revisions.updateRevision(item, item.previousRevisionValue);
        setShowRemoveIcon(false);
      }}
      onRevert={() => {
        sdk.revisions.updateRevision(item, item.legacyNextRevision);
        setShowRemoveIcon(true);
      }}
    />
  );
};
interface StyledRevisionInputProps {
  isValid: boolean;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

export const StyledRevisionInput = styled(
  ({ isValid, ...rest }: StyledRevisionInputProps) => <Input {...rest} />
)(({ isValid }: { isValid: boolean }) => ({
  width: hasCustomRevisionScheme() ? "61px" : "33px",
  padding: "0 0.5rem",
  ...(isValid === false && {
    backgroundColor: `${colorPalette.errorRed} !important`,
  }),
}));

const RevisionLabel = styled("div")({});

const RemoveRevision = styled("div")({
  position: "absolute",
  bottom: 0,
  right: cancelIconSize * -0.8,
  width: cancelIconSize,
  height: cancelIconSize,
});

const LockedRevisionFieldContainer = styled("div")({
  color: colorPalette.taupeGray,
  display: "flex",
  position: "relative",
});

const DashedBorderContainer = styled("div")({
  border: `1px dashed ${colorPalette.taupeGray}`,
  width: hasCustomRevisionScheme() ? "61px" : "33px",
  padding: "0 0.5rem",
});

const OpenCustomRevScheme = styled(Edit)({
  width: 14,
  marginLeft: "0.5rem",
});
