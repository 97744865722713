
import "./index.scss"
import "./../index.scss"
import React, { Component }                  from "react"
import WebhookModalBox                       from "./webhook-modal-box"
import WebhooksView                          from "./webhooks-view"
import {connect}                             from "react-redux";
import {Helmet}                              from "react-helmet";
import Utils                                 from "../../../../modules/utils"
import { userById } from "graphql/query/userQueries";

class Webhook extends Component
{

    constructor(props, context)
    {
        super(props, context)
        this.showAddNewWebhook  = this.showAddNewWebhook.bind(this)
        this.closeAddNewWebhook = this.closeAddNewWebhook.bind(this)
        this.setWebhooks        = this.setWebhooks.bind(this)
        this.addNewToWebhooks   = this.addNewToWebhooks.bind(this)


        this.state = {
            addNewWebhook: false,
            loadWebhooks: true,
            webhooks: [],
            user: userById(),
        }
    }

    showAddNewWebhook()
    {
        this.state.addNewWebhook = true
        this.setState(this.state)
    }

    closeAddNewWebhook()
    {
        this.state.addNewWebhook = false
        this.setState(this.state)
    }

    setWebhooks(webhooks)
    {
        let state = this.state;
        state.webhooks = webhooks;
        state.loadWebhooks = false;
        this.setState(state);
    }

    addNewToWebhooks(newItem)
    {
        let state = this.state;
        state.webhooks.push(newItem);
        this.setState(state);
    }

    render()
    {
        let markup =

        <div className="webhooks-settings-route">
            <Helmet>
                <title>{Utils.makeTitle("Settings - Webhooks")}</title>
            </Helmet>
            <div className="main-block">
                <div className="content-block">
                    <WebhooksView
                        showAddNewWebhook={this.showAddNewWebhook}
                        user={this.state.user}
                        webhooks={this.state.webhooks}
                        setWebhooks={this.setWebhooks}
                        loadWebhooks={this.state.loadWebhooks}
                    />
                    <div>
                        {
                            this.state.addNewWebhook ?
                            <WebhookModalBox
                                mode={"new"}
                                onClose={this.closeAddNewWebhook}
                                addNewToWebhooks={this.addNewToWebhooks}
                            />
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>

        return markup
    }
}

export default Webhook;
