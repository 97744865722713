export const TEST_DATAROOMS = [
    {
      id: 1,
      title: 'Main Room',
      createdDate: 'Aug 7, 2024',
      createdBy: 'J. Mandelbrot',
      products: 10,
      components: 5000,
    },
    {
      id: 2,
      title: 'Minor Room',
      createdDate: 'Aug 1, 2024',
      createdBy: 'F. Flores',
      products: 11,
      components: 155,
    },
    {
      id: 3,
      title: 'Draft Room',
      createdDate: 'Apr 1, 2024',
      createdBy: 'A. Lincoln',
      products: 11,
      components: 124,
    },
    {
      id: 4,
      title: 'Production Room',
      createdDate: 'Feb 5, 2024',
      createdBy: 'T. Bowers',
      products: 134,
      components: 534232,
    },
    {
      id: 5,
      title: 'Staged Room',
      createdDate: 'Jan 12, 2024',
      createdBy: 'A. Lambert',
      products: 199,
      components: 12,
    }
  ];