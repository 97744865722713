import { useMemo } from "react";
import { Box, Tooltip, styled } from "@mui/material";
import { InputField } from "common/components/inputs";
import { Component, Product } from "design/models";
import { colorPalette } from "@duro/themes";
import ItemSpecsIcon from "v1/assets/icons/item-specs-icon";
import TileItem from "../../tile-item";
import { DuroTextOverflow } from "@duro/overlay";
import DuroPanelTypographyTextStyle from "@duro/typography";

interface CustomSpecInputFieldProps {
  allowedValues: string[];
  defaultValue: string;
  error: ErrorObj;
  index: number;
  isDropDown: boolean;
  onChange: Function;
  value: string;
}

const componentStyle = {
  padding: "0.25rem 0 0 0.25rem",
  width: "7.875rem",
};

const enum CustomSpecsEnum {
  KEY = "KEY",
  VALUE = "VALUE",
}
const CUSTOM_SPECS = "customSpecs";

type ErrorObj = {
  valid: boolean;
  message: string;
};

interface ICustomSpecs {
  allowedValues: string[];
  defaultValue: string;
  isDropDown: boolean;
  key: string;
  message: string;
  valid: boolean;
  value: string;
}

interface ItemCustomSpecsProps {
  item: (Component | Product) & { customSpecs?: ICustomSpecs[] };
  isEditing: boolean;
  onInputChange: Function;
  title: string;
}

const CustomSpecInputField = ({
  allowedValues,
  defaultValue,
  error,
  index,
  isDropDown,
  onChange,
  value,
}: CustomSpecInputFieldProps) => {
  const isRequired = useMemo(
    () => !!allowedValues.length && !allowedValues.includes(""),
    [allowedValues]
  );
  return (
    <Tooltip placement="right" title={error.message ?? ""}>
      {
        // Using HTML select temperarily for the UI consistancy.
        isDropDown ? (
          <select
            name={CUSTOM_SPECS}
            onChange={(event) => onChange(event, index)}
            style={{
              ...componentStyle,
              ...(!error.valid && { backgroundColor: colorPalette.errorRed }),
            }}
            value={value}
          >
            {" "}
            {allowedValues.map((el) => (
              <option key={el} value={el}>
                {el}
              </option>
            ))}
          </select>
        ) : (
          <InputFieldStyle
            error={!error.valid}
            name={CUSTOM_SPECS}
            value={value}
            isRequired={isRequired}
            onChange={(event) => onChange(event, index)}
          />
        )
      }
    </Tooltip>
  );
};

export const ItemCustomSpecs = ({
  item,
  isEditing,
  onInputChange,
  title,
}: ItemCustomSpecsProps) => {
  const { customSpecs } = item;

  if (!customSpecs?.length) return null;
  return (
    <>
      <TileItem title={title} icon={<ItemSpecsIcon />}>
        {customSpecs.map((spec: ICustomSpecs, index: number) => {
          const {
            allowedValues,
            defaultValue,
            isDropDown,
            key,
            message,
            valid,
            value,
          } = spec;
          return (
            <CustomSpecsWrapper key={index}>
              <DuroTextOverflow text={key} type={CustomSpecsEnum.KEY} />
              {isEditing ? (
                <CustomSpecInputField
                  allowedValues={allowedValues}
                  defaultValue={defaultValue}
                  error={{ valid, message }}
                  index={index}
                  isDropDown={isDropDown}
                  onChange={onInputChange}
                  value={value}
                />
              ) : (
                <DuroTextOverflow text={value} type={CustomSpecsEnum.KEY} />
              )}
            </CustomSpecsWrapper>
          );
        })}
      </TileItem>
    </>
  );
};

const CustomSpecsWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "0.1rem",
}));

const InputFieldStyle = styled(InputField)(() => ({
  alignItems: "center",
  display: "flex",
  overflow: "hidden",
  width: "48%",
}));
