import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useGridHeight } from "common/components/grid";
import React from "react";

const StyledGridContainer = styled(Box)<{ height: number }>(({ height }) => ({
  display: "flex",
  flex: 1,
  maxHeight: "calc(100vh - 18rem)",
  [`.MuiDataGrid-pinnedColumns`]: {
    boxShadow: "none",
  },
  [`.MuiDataGrid-row:last-of-type`]: {
    borderBottom: "1px solid  #979797",
  },
}));

export const GridContainer: React.FC<{
  visibleRowCount: number;
  rowHeight: number;
  minHeight?: number;
}> = ({ rowHeight, visibleRowCount, minHeight = 0, children }) => {
  const gridHeight = useGridHeight(visibleRowCount, rowHeight ?? 0);

  return (
    <StyledGridContainer
      height={gridHeight > minHeight ? gridHeight : minHeight}
    >
      {children}
    </StyledGridContainer>
  );
};
