import React, {Component} from "react";

class SingleType extends Component
{
    constructor(props)
    {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange(e)
    {
        let {name, isChecked} = this.props.DocTypes;
        isChecked = e.target.checked;
        this.props.updateDocTypes(name, isChecked);
    }

    render()
    {
        let {name, isChecked} = this.props.DocTypes;
        let markup = <tr name={name}>
                        <td style={{ width: '35px', padding: '6px 20px 6px 0'}}>
                            <div style={{ height: "1rem" }} className="checkbox-holder">
                                <input
                                type="checkbox"
                                name="check-row"
                                className="large"
                                checked={isChecked}
                                onChange={(e)=> this.onInputChange(e)}
                                />
                                <label htmlFor="check-row"
                                    // className={this.checkAllState().class}
                                />
                            </div>
                        </td>
                        <td style={{ textAlign: 'left', padding: '6px 20px 6px 0'}}>
                            <div>
                                {name}
                            </div>
                        </td>
                    </tr>
        return markup;
    }
}

export default SingleType;
