import "./index.scss"
import React, {Component} from "react"
import Tooltip            from 'rc-tooltip';
import Loading            from "../../../../ui/inner-loading"
import Utils              from "../../../../../modules/utils"
import ExtendedTable      from "../../../common/extended-table"
import ProductIcon        from "../../../../../assets/icons/product-icon"
import ChangeOrderIcon    from "../../../../../assets/icons/triangle-icon"
import ComponentIcon      from "../../../../../assets/icons/ComponentIcon"
import TimeStampColumn    from "../../../common/timestamp-column";
import InlineImageViewer  from "../../../common/inline-image-viewer/view";
import Config             from "../../../../../modules/config";
import InlineIcon         from "../../../../ui/icon/inline-icon";
import VariantIcon        from "../../../../../assets/icons/variant-icon";
import VariantModal       from "../../../common/view-action-items/variant-modal"
import Link               from "../../../../ui/link"
import VariantIconHolder  from "../../../common/variants/variant-icon-holder";

export class MixedList extends Component
{
    constructor(props)
    {
        super(props)

        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.searchAll || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.state =
        {

            currentSortItemAscending : ("defaultSortAssending" in viewStyles ? viewStyles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in viewStyles ? viewStyles.defaultSortColumnName : "id"),
            openVariantModal: false,
            primaryVariant: null,
            VariantModal: {
                top: 0,
                left: 0
            },
            headings :
            [
                {
                    key         : "type",
                    displayName : "Type",
                    tooltip     : "Type",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(viewStyles, "type", "width", 79),
                    position    : Utils.getStyleValue(viewStyles, "type", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "type", "visibility", true),
                    disabled    : true
                },

                {
                    key         : "id",
                    displayName : "ID",
                    tooltip     : "ID",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 53,
                    width       : Utils.getStyleValue(viewStyles, "id", "width", 119),
                    position    : Utils.getStyleValue(viewStyles, "id", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "id", "visibility", true),
                    disabled    : true
                },

                {
                    key         : "eid",
                    displayName : "EID",
                    tooltip     : "External ID",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "eid", "width", 113),
                    position    : Utils.getStyleValue(viewStyles, "eid", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "eid", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 77,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 247),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },

                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 124,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 236),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 87,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 138),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastModified",
                    displayName : "Last Modified",
                    tooltip     : "Last Modified",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "lastModified", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "lastModified", "position", 7),
                    visibility  : Utils.getStyleValue(viewStyles, "lastModified", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "images",
                    displayName : "Images",
                    tooltip     : "Images",
                    sortable    : false,
                    ascending   : true,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "images", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "images", "position", 8),
                    visibility  : Utils.getStyleValue(viewStyles, "images", "visibility", true),
                    disabled    : false
                }
            ]
        }
        this.getRows            = this.getRows.bind(this);
        this.onRowClick         = this.onRowClick.bind(this);
        this.setCurrentSortBy   = this.setCurrentSortBy.bind(this);
        this.typeIconTooltip    = this.typeIconTooltip.bind(this);
        this.closeVariantModal  = this.closeVariantModal.bind(this);
    }

    setCurrentSortBy(name, ascending)
    {
        let state = this.state
        state.currentSortItemAscending = ascending
        state.current = name
        this.setState(state)
    }

    onRowClick(link)
    {
        this.props.history.push({pathname: link})
    }

    parseResult(result)
    {
        result = Utils.clone(result)
        let cpn   = result.cpn;
        let path = window.__userRole === 'VENDOR' ? 'revision' : 'view'
        if(cpn)
        {
            cpn   = Utils.getCpn(result);
        }

        switch(result.alias)
        {
            case "prd" :
            {
                result.displayType = "PRODUCT"
                result.displayIcon = <InlineIcon> <ProductIcon/> </InlineIcon>
                result.link        = `/product/${path}/${result._id}`
                result.displayLink = cpn
                break
            }
            case "cmp" :
            {
                result.displayType = "COMPONENT"
                result.displayIcon = <InlineIcon> <ComponentIcon/> </InlineIcon>
                result.link        = `/component/${path}/${result._id}`
                result.displayLink = cpn
                break
            }
            case "co" :
            {
                result.displayType = "CHANGE ORDER"
                result.displayIcon = <InlineIcon> <ChangeOrderIcon/> </InlineIcon>
                result.link        = `/changeorder/view/${result._id}`
                result.displayLink = result.con
                break
            }
            default :
            {
                // noop
            }
        }

        result.eid  = result.eid || ""
        result.date = result.created

        return result
    }

    typeIconTooltip(result)
    {
        result = Utils.clone(result)
        let typeIconTooltip = ""

        switch(result.alias)
        {
            case "prd" :
            {
                typeIconTooltip = "Product"
                break
            }
            case "cmp" :
            {
                typeIconTooltip = "Component"
                break
            }
            case "co" :
            {
                typeIconTooltip = "Change Order"
                break
            }
            default :
                break;
        }

        return typeIconTooltip
    }

    closeVariantModal(e, result)
    {
        let state = this.state;
        state.openVariantModal = false;
        state.primaryVariant = null;
        this.setState(state);
    }

    getRows()
    {
        let rows    = [];
        let props             = this.props;
        let results           = props.results;
        let locationState     = props.location.state;
        let selectedSearchIds = props.preSelectedSearchResults;
        let path = window.__userRole === "VENDOR" ? "revision" : "view"
        results.map((result, i) =>
        {
            let parsedResult = this.parseResult(result);
            let activeClass = locationState && locationState.imported_ids ? locationState.imported_ids.includes(result._id) : false;

            let rowSelected = false;
            selectedSearchIds.forEach((selected_result, i) =>
            {
                if (selected_result._id === result._id)
                {
                    rowSelected = true
                }
            })
            let lastModified        = result.lastModified ? result.lastModified : undefined;
            let lastUpdatedToolTip  = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;
            let typeIconTooltip     = this.typeIconTooltip(result);
            let itemLink            = ''
            if(parsedResult.alias !== 'co')
            {
               itemLink = parsedResult.alias === 'prd' ? `/product/${path}/${parsedResult._id}` : `/component/${path}/${result._id}`
            }

            let cells =
            {
                "type":
                {
                    value        : parsedResult.displayType,
                    tooltip      : parsedResult.displayType,
                    displayValue : <Tooltip
                                        placement={"left"}
                                        overlayClassName={"rc-left-custom-arrow"}
                                        overlay={typeIconTooltip}
                                    >
                                        <div>{parsedResult.displayIcon}</div>
                                    </Tooltip>
                },

                "id":
                {
                    value        : parsedResult.displayLink,
                    tooltip      : parsedResult.displayLink,
                    displayValue :  <span className="link">
                                        {parsedResult.displayLink}
                                    </span>
                },

                "eid":
                {
                    value        : parsedResult.eid,
                    tooltip      : parsedResult.eid,
                    displayValue : parsedResult.eid
                },
                "name":
                {
                    value        : parsedResult.name,
                    tooltip      : parsedResult.name,
                    cellClass    : parsedResult.variantGroup ? 'name-block' : '',
                    displayValue : parsedResult.variantGroup && (parsedResult.alias === 'cmp' || parsedResult.alias === 'prd') ?
                                    <div className='item-name'>
                                        <Link
                                        to={itemLink}
                                        className={`link ${parsedResult.variantGroup ? '' : 'not-variant'}`}>
                                        {parsedResult.name}
                                        </Link>
                                        {
                                            window.__userRole !== 'VENDOR' &&
                                                <VariantIconHolder
                                                    openVariantModal={(primaryVariant, variantModal) => {
                                                        let state = this.state
                                                        state.openVariantModal = true;
                                                        state.primaryVariant = primaryVariant;
                                                        state.VariantModal.top = variantModal.top
                                                        state.VariantModal.left = variantModal.left
                                                        this.setState(state)
                                                    }}
                                                    result={parsedResult}
                                                    leftFomular={(left) => left - 350}
                                                    toolTip={parsedResult.alias === 'prd' ? `Product Variants` : `Component Variants`}
                                                    tableSelector="mixed-list-block extended-table"
                                                 />
                                        }
                                    </div>
                                    :
                                    parsedResult.name,
                },
                "description":
                {
                    value        : parsedResult.description,
                    tooltip      : parsedResult.description,
                    displayValue : parsedResult.description
                },
                "status":
                {
                    value        : parsedResult.status,
                    tooltip      : parsedResult.status,
                    displayValue :
                        <span className={"status-label " + parsedResult.status}>
                            {parsedResult.status}
                        </span>
                },
                "lastModified":
                {
                    value        : lastModified,
                    tooltip      : lastUpdatedToolTip ? `${lastUpdatedToolTip.dateValue} ${lastUpdatedToolTip.timeValue}`: '' ,
                    displayValue : <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} />
                },
                "images" :
                {
                    value       : 0,
                    displayValue: <InlineImageViewer imagesWithSrc={this.props.imagesWithSrc} key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={result.images}/>,
                    notLink     : true,
                    cellClass   : "inline-image-viewer"
                },
                rowSelected     : rowSelected,
                rowClassName    : (activeClass ? "active" : ""),
                object          : result,
                // onClick         : () => this.onRowClick(parsedResult.link),
                rowLink         : parsedResult.link,
                rowId           : result._id
            }
            rows.push(cells);
        })

        return rows;
    }

    render()
    {
        if(this.props.loading)
        {
            return <Loading />
        }
        let headings = this.state.headings;
        let rows = this.getRows();

        let markup  =
                <div>
                    {
                        this.state.openVariantModal && window.__userRole!=='VENDOR' &&
                        <div className="variant-table-holder">
                            <VariantModal
                                openVariantModal={this.state.openVariantModal}
                                closeVariantModal={this.closeVariantModal}
                                objectData={this.state.primaryVariant}
                                variantModalPosition={this.state.VariantModal}
                                history={this.props.history}
                                getDataFromApi={true}
                                fromLibraryTable={true}
                            />
                        </div>
                    }
                    <ExtendedTable
                        wrapperClassName="mixed-list-block"
                        wrapperSelectorClass="mixed-list-view"
                        headings={headings}
                        rows={rows}
                        stylesName="searchAll"
                        allowRowSelect={this.props.allowRowSelect}
                        clearSelectedRows={this.props.clearSelectedRows}
                        onRowSelect={this.props.addToSelectedList}
                        onSelectAllRows={this.props.selectAllResults}
                        currentSortItem={this.state.current}
                        currentSortItemAscending={this.state.currentSortItemAscending}
                        resetLocalState={this.props.resetLocalState}
                        setCurrentSortBy={this.setCurrentSortBy}
                        clearSelectedRowsStore={this.props.clearSelectedRowsStore}
                        scrollPagination={true}
                        includeToolBar={true}
                        tableActionButtons={this.props.getIconsActionsList()}
                        paginationSize={50}
                        resultText={this.props.resultText}
                        footerRow={rows.length === 0 && {
                                dataCellEl: <p>No results match your search.</p>
                            }
                        }
                    />
                </div>

        return markup
    }
}

// export default connect((store) => store)(MixedList)
export default MixedList
