import { Box, styled } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { colorPalette } from "@duro/themes";
import {
  Tab,
  TabList,
  TabContext,
  TabPanel,
  ITabProps,
} from "common/components/tabs/index";
import { ChangeOrderTabConfigItem } from "./tabs.config";
import { ChangeOrderSidebarClasses } from "../constants";
import React, { useCallback, useEffect } from "react";

export function openTabSidebar(tab: ChangeOrderSidebarClasses) {
  const hiddenElements = Object.values(ChangeOrderSidebarClasses).filter(
    (className) => className !== tab
  );

  hiddenElements.forEach((className) => {
    document.querySelector<HTMLDivElement>(`.${className}`)!.style.display =
      "none";
  });

  document.querySelector<HTMLDivElement>(`.${tab}`)!.style.display = "";
}

export type ChangeOrderEditTabsProps = { tabs: ChangeOrderTabConfigItem[] };

export const ChangeOrderEditTabs: React.FC<ChangeOrderEditTabsProps> = ({
  tabs,
}) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedTab = parseInt(searchParams.get("tab") ?? "0");

  return (
    <TabContext sx={{ minHeight: "100%", padding: "0 15px 0 44px" }}>
      <TabList
        onChange={(_, tab) => {
          searchParams.set("tab", tab);
          history.push({ search: searchParams.toString() });
        }}
        value={selectedTab.toString()}
      >
        {tabs.map(({ id, tabProps }) => (
          <Tab
            {...tabProps}
            value={id.toString()}
            isActive={selectedTab === id}
            key={id}
          >
            {tabProps.label}
          </Tab>
        ))}
      </TabList>
      {tabs.map(({ id, Component }) => (
        <Box
          display={selectedTab == id ? "" : "none"}
          key={id}
          minHeight="100%"
          maxWidth="1200px"
        >
          <Component />
        </Box>
      ))}
    </TabContext>
  );
};
