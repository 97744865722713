import "./index.scss";
import Utils from "../../../../../modules/utils";
import { TransactionalModal } from "common/components/modals/transactionalModal";
import { Box } from "@mui/material";

export default function UnlinkWarningModal({ hideModal, label, unlinkComponent }) {
  return (
    <TransactionalModal
      acceptLabel="Unlink"
      cancelLabel="Cancel"
      onAccept={unlinkComponent}
      onCancel={hideModal}
      onClose={hideModal}
      open={open}
      title={`Component created from ${Utils.capitalizeString(label)}`}
    >
      <Box>
        <p>This component can be unlinked from its original source.</p>
        <p>
          WARNING: This permanently unlinks the component from the 3rd party
          application source. This action can not be undone.
        </p>
      </Box>
    </TransactionalModal>
  )
}
