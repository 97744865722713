import { Button } from "@mui/material";
import { ButtonVariants } from "common/constants";

export type NavButtonType = {
  id: number,
  isDisabled: (ids: string[]) => boolean,
  Icon: () => JSX.Element,
  label: string,
  onClick: (ids: string[]) => void,
};

export type NavButtonCallbacks = {
  loading: boolean,
  selectedItems: string[],
  setLoading: (isLoading: boolean) => void,
  setSelectedItems: (items: string[]) => void,
};

export type NavButtonConfig = {
  navItems: NavButtonType[],
  state: NavButtonCallbacks,
};

export type CreateNavButtonPlugin = (config: NavButtonConfig) => JSX.Element[];

export const createNavButtons: CreateNavButtonPlugin = (config) => {
  return config.navItems.map((navItem) => {
    const { isDisabled, id, Icon, label, onClick } = navItem;
    const ids = config.state.selectedItems;
    return (
      <Button
        disabled={isDisabled(ids)}
        key={label}
        onClick={() => onClick(ids)}
        startIcon={Icon ? <Icon /> : null}
        variant={ButtonVariants.TEXT}
      >
        {label}
      </Button>
    );
  });
};
