import "./index.scss";
import React, { Component }      from "react";
import Tooltip                   from "rc-tooltip";
import InlineIcon                from "../../../../../ui/icon/inline-icon.js"
import Utils                     from "../../../../../../modules/utils";

import MechanicalSrc             from "../../../../../../assets/icons/cat-mechanical.js";
import ElectricalSrc             from "../../../../../../assets/icons/cat-electrical.js";
import AssemblySrc               from "../../../../../../assets/icons/cat-assembly.js";
import SoftwareSrc               from "../../../../../../assets/icons/cat-software.js";
import DocumentSrc               from "../../../../../../assets/icons/cat-document.js";
import ServiceSrc                from "../../../../../../assets/icons/cat-service.js";
import MaterialSrc               from "../../../../../../assets/icons/cat-material.js";
import AdditivePartSrc           from "../../../../../../assets/icons/additive-parts.js";
import CostSrc                   from "../../../../../../assets/icons/cat-cost.js";
import GeneralSrc                from "../../../../../../assets/icons/cat-general.js";
import PlumbingSrc               from "../../../../../../assets/icons/cat-plumbing.js";
import ArchitecturalSrc          from "../../../../../../assets/icons/cat-architectural.js";
import StructuralSrc             from "../../../../../../assets/icons/cat-structural.js";
import MaterialMetalIcon         from "../../../../../../assets/icons/cat-material-metal.js";
import MaterialNonMetalIcon      from "../../../../../../assets/icons/cat-material-non-metal.js";
import Link                      from "../../../../../ui/link"

import Schemas                   from "../../../../../../modules/schemas";
import McMasterDisconnected      from "../../../../../ui/mcmaster-disconnected-tooltip";

class CategoryTypes extends Component        
{
    constructor(props, context)
    {
        super(props, context);

        let mainCategories = Schemas.categories.getMainCategoriesNames();
        let iconsArry =
        [
                {
                    name  : window.__companyIs11DigitCpnType() ? "source" : "software",
                    src   : <SoftwareSrc/>,
                    label : window.__companyIs11DigitCpnType() ? "Source" : "Software"
                },
                {
                    name  : window.__companyWithHybridCpnType() ? "documents" : "document",
                    src   : <DocumentSrc/>,
                    label : window.__companyWithHybridCpnType() ? "Documents" : "Document",
                }
        ];

        let iconsArryForWeb =
        [
                {
                    name  : "electrical",
                    src   : <ElectricalSrc/>,
                    label : "Electrical"
                },
        ];

        this.state =
        {
            icons : this.props.type  === "web" ? iconsArryForWeb : iconsArry
        }

        if (mainCategories && mainCategories.includes('OTHER') && this.props.type  !== "web") {
            this.state.icons.unshift({
                    name  : "other",
                    src   : <GeneralSrc/>,
                    label : "Other"
                })
        }

        if (mainCategories && mainCategories.includes('ASSEMBLY') && this.props.type  !== "web") {
            this.state.icons.unshift({
                    name  : "assembly",
                    src   : <AssemblySrc/>,
                    label : "Assembly"
                })
        }

      // Todo: Need to move all category groups to Lookup to make it generic
      const CatGroupLookup = {
        "COMMERCIAL-OFF-THE-SHELF": {
          name: "commercial-off-the-shelf",
          src: <CostSrc />,
          label: "Commercial-Off-The-Shelf"
        },
        "ELECTRONIC HARDWARE": {
          name: "electronic hardware",
          src: <ElectricalSrc />,
          label: "Electronic-Hardware"
        },
        "AVIONICS HARDWARE": {
          name: "avionics hardware",
          src: <MechanicalSrc />,
          label: "Avionics-Hardware"
        },
        "ELECTRICAL PART": {
            name: "electrical part",
            src: <ElectricalSrc />,
            label: "Part",
        },
        "MECHANICAL PARTS": {
          name: "mechanical parts",
          src: <MechanicalSrc />,
          label: "Mechanical-Parts"
        },
        "CABLE HARNESSS ASSEMBLY": {
          name: "cable harnesss assembly",
          src: <PlumbingSrc />,
          label: "Cable-Harnesss-Assembly",
        },
        "PROPULSION HARDWARE": {
          name: "propulsion hardware",
          src: <AdditivePartSrc />,
          label: "Propulsion-Hardware",
        },
      }

      Object.keys(CatGroupLookup).forEach(catGroup => {
        if (mainCategories?.includes(catGroup)) {
            this.props.type !== "web" && catGroup !== "ELECTRICAL PART" && this.state.icons.push(CatGroupLookup[catGroup]);
            if (catGroup === "ELECTRICAL PART")
							this.state.icons.push(CatGroupLookup["ELECTRICAL PART"]);
							const index = this.state.icons.findIndex(icon => icon.name === "electrical");
							if (index > -1) {
								this.state.icons.splice(index, 1);
							}
					}
				});

				if (mainCategories?.includes("PART") && this.props.type !== "web") {
					this.state.icons.push({
						name: "part",
						src: <ElectricalSrc />,
						label: "Part",
					});
				}

        if (window.__companyIs10DigitCpnType())
        {
            this.state.icons.push({
                    name  : "material",
                    src   : <MaterialSrc/>,
                    label : "Material"
                })
        }

        if (mainCategories && mainCategories.includes("MATERIAL METAL") && this.props.type !== "web")
        {
            this.state.icons.push({
                    name  : "material metal",
                    src   : <MaterialMetalIcon/>,
                    label : "Material Metal"
                })
        }
        if (mainCategories && mainCategories.includes("MATERIAL NON-METAL") && this.props.type !== "web")
        {
            this.state.icons.push({
                name  : "material non-metal",
                src   : <MaterialNonMetalIcon/>,
                label : "Material Non-metal"
            })
        }

        if (mainCategories && mainCategories.includes('ELECTRICAL') && this.props.type  !== "web") {
            this.state.icons.unshift({
                    name  : "electrical",
                    src   : <ElectricalSrc/>,
                    label : "Electrical"
                })
        }

        if (mainCategories && mainCategories.includes(window.__companyWithHybridCpnType() && this.props.type  !== "web" ? "CUSTOM" : "MECHANICAL")) {
            this.state.icons.unshift({
                name  : window.__companyWithHybridCpnType() ? "custom" : "mechanical",
                src   : <MechanicalSrc/>,
                label : window.__companyWithHybridCpnType() ? "custom" : "Mechanical"
            })
        }

        if (mainCategories && mainCategories.includes("CONSUMABLE")) {
            this.state.icons.push({
                    name  : "consumable",
                    src   : <MaterialSrc/>,
                    label : "Consumable"
                })
        }

        if (mainCategories && mainCategories.includes(window.__companyWithHybridCpnType() ? "OFF THE SHELF" : "MATERIAL") && this.props.type  !== "web") {
            this.state.icons.push({
                    name  : window.__companyWithHybridCpnType() ? "off the shelf" : "material",
                    src   : <MaterialSrc/>,
                    label : window.__companyWithHybridCpnType() ? "Off The Shelf" : "Material"
                })
        }

        if (mainCategories && mainCategories.includes('MECHANICAL OTS')) {
            this.state.icons.unshift({
                    name  : "mechanical ots",
                    src   : <MechanicalSrc/>,
                    label : "Mechanical OTS"
                })
        }

        if (mainCategories && mainCategories.includes("MECHANICAL MTS")) {
            this.state.icons.unshift({
                   name  : "mechanical mts",
                    src   : <AdditivePartSrc/>,
                    label : "Mechanical MTS"
                })
        }

        if (mainCategories && mainCategories.includes("PACKAGING"))
        {
            this.state.icons.push({
                name  : "packaging",
                src   : <SoftwareSrc/>,
                label : "Packaging"
            })
        }
        if (mainCategories && mainCategories.includes("SERVICES") && this.props.type  !== "web")
        {
            this.state.icons.push({
                name  : "services",
                src   : <ServiceSrc/>,
                label : "Services"
            })
        }

        //Note: Added custom icon for production category
        if (mainCategories && mainCategories.includes("PRODUCTION") && this.props.type  !== "web")
        {
            this.state.icons[3] = {
                name  : "production",
                src   : <MaterialSrc/>,
                label : "Production"
            }
        }

        //Note: Added custom icon for additive category
        if (mainCategories && mainCategories.includes("ADDITIVE PARTS") && this.props.type  !== "web")
        {
            this.state.icons.push({
                name  : "additive parts",
                src   : <AdditivePartSrc/>,
                label : "Additive Parts"
            })
        }

        if (mainCategories && mainCategories.includes("COST") && this.props.type  !== "web")
        {
            this.state.icons.push({
                    name  : "cost",
                    src   : <CostSrc/>,
                    label : "Cost"
            });
        }

        if (mainCategories && mainCategories.includes("GENERAL") && this.props.type  !== "web")
        {
            this.state.icons.push({
                    name  : "general",
                    src   : <GeneralSrc/>,
                    label : "General"
            });
        }

        if (mainCategories && mainCategories.includes("PLUMBING") && this.props.type  !== "web")
        {
            this.state.icons.push({
                    name  : "plumbing",
                    src   : <PlumbingSrc/>,
                    label : "Plumbing"
            });
        }

        if (mainCategories && mainCategories.includes("ARCHITECTURAL") && this.props.type  !== "web")
        {
            this.state.icons.push({
                    name  : "architectural",
                    src   : <ArchitecturalSrc/>,
                    label : "Architectural"
            });
        }

        if (mainCategories && mainCategories.includes("STRUCTURAL") && this.props.type  !== "web")
        {
            this.state.icons.push({
                    name  : "structural",
                    src   : <StructuralSrc/>,
                    label : "Structural"
            });
        }

        if (mainCategories?.includes("FINISHED GOODS") && this.props.type !== "web")
        {
            this.state.icons.push({
                    name  : "finished goods",
                    src   : <GeneralSrc/>,
                    label : "Finished Goods"
            });
        }

        // Added Multi-gear icon for custom category group of IonQ company
        if (mainCategories?.includes("ELECTRO-OPTICAL") && this.props.type !== "web") {
          this.state.icons.push({
            name: "electro-optical",
            src: <GeneralSrc />,
            label: "Electro-Optical",
          });
        }
        
        //TODO: Need to make this module dynamic based on category types, we will do this in separate ticket because it also include some front-end and backend work.
        //Removed Software category icon for bellwether
        if (!window.__companyIs11DigitCpnType() && mainCategories && !mainCategories.includes("SOFTWARE") && this.props.type !== "web") {
            const index = this.state.icons.findIndex(icon => icon.name === 'software');
            this.state.icons.splice(index, 1);
        }


        //Removed Document category type for Room
        if (!window.__companyIs11DigitCpnType() && mainCategories && !mainCategories.includes(window.__companyWithHybridCpnType() ? "DOCUMENTS" : "DOCUMENT") && this.props.type !== "web") {
            const index = this.state.icons.findIndex(icon => icon.name === (window.__companyWithHybridCpnType() ? 'documents' : 'document'));
            this.state.icons.splice(index, 1);
        }

        this.onClick = this.onClick.bind(this)
    }

    onClick(event)
    {
        let value = event.target.getAttribute("name")
        this.props.onChange && this.props.onChange(Utils.getEvent(this.refs.node, value))
    }

    render()
    {
        let blocks = this.state.icons.map((icon, i) =>
        {
            let block =
                //added this tooltip to show approperiate message on mcmaster's availablility/unavailability.
                <Tooltip
                    placement={"top"}
                    overlayClassName={`simple-rc-tip custom-disable-tooltip ${(this.props.type  === "web" && icon.name.includes('mechanical') && this.props.mcMasterStatus !== 'Connected') ? "" : "hidden"}`}
                    overlay={ <McMasterDisconnected/> }
                >
                    <div
                        key={i}
                        name={icon.name}
                        className={`${this.props.value === icon.name ? "selected" : ""} ${this.props.type  === "web" && (i > 1) ? "disabled" : ""} category-nav `}
                        onClick={this.props.type  === "web" && icon.name.includes('mechanical') && this.props.mcMasterStatus !== 'Connected' ? null : this.onClick}
                        >
                        <div
                            className={`${this.props.type  === "web" && icon.name.includes('mechanical') && this.props.mcMasterStatus !== 'Connected' ? "web-mechanical disabled" : ""}`}
                            >
                            <div className={icon.name}>
                            <InlineIcon>
                                {icon.src}
                            </InlineIcon>
                            </div>
                            <label>{icon.label}</label>
                        </div>
                    </div>
                </Tooltip>

            return block
        })

        let markup =
            <div
                ref="node"
                name={this.props.name}
                className={"new-component-category-types " + (this.props.disabled ? "disabled" : "")}
                >
                {blocks}
            </div>

        return markup
    }
}

export default CategoryTypes
