import { styled, Box } from "@mui/material";
import { ChangeOrderEditTable, TableActionNavHeader } from "../../..";
import { sdk } from "features/changeorders/sdk";
import { sdk as sdk2 } from "features/changeorders/sdk/editor";
import { ChangeOrderSimpleTable } from "../../..";
import {
  AddComponentsBar,
  LeadingText,
  SearchContainer,
  SearchField,
  SidebarWrapper,
  TableContainer,
} from "features/changeorders/components/sidebar.styles";

import React, { useState, useMemo, useEffect } from "react";
import { ComponentLink } from "common/components/component-link";
import { colorPalette } from "components/themes";
import {
  useComponentVirtualList,
  useProductVirtualList,
  useVirtualListQueryOptions,
} from "graphql/virtual-list";
import { useDebounce } from "use-debounce";
import { DuroVirtualColumn } from "common/components/duro-vlist";
import { SuperComponent } from "design/models/changeorder";
import { Tab, TabContext, TabList, TabPanel } from "common/components/tabs";
import { RevisionFieldValue } from "features/changeorders/components/tables/changeorder-table/fields";
import { createRow } from "features/changeorders/sdk/editor/preprocessors";

export const ComponentsTab: React.FC = () => {
  return (
    <>
      <TableActionNavHeader sx={{ marginBottom: "1.25rem" }}>
        Add Products and Components to Your Change Order
      </TableActionNavHeader>
      <ChangeOrderEditTable />
    </>
  );
};

const RevisionComponent = ({ item }: any) => {
  const type = sdk.storage.form.type.getState().trim();
  return <RevisionFieldValue coType={type} item={item} />;
};

const columns = (headerName: string): DuroVirtualColumn<SuperComponent>[] => [
  {
    field: "cpn",
    headerName,
    fieldPath: (item: SuperComponent) => item.cpn.displayValue,
    renderCell: (params) => {
      return (
        <StyledCPN>
          <StyledId>
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
                overflow: "hidden",
                width: "78px",
                display: "block",
              }}
            >
              <ComponentLink item={params} />
            </div>
          </StyledId>
          <StyledCpnName>{params.name}</StyledCpnName>
        </StyledCPN>
      );
    },
  },
  {
    field: "revisionValue",
    headerName,
    renderCell: (params) => {
      return <RevisionComponent item={params} />;
    },
  },
];

const pageSize = 100;
interface OrderSideBarProps {
  selectedTab?: number;
}

export const OrderSideBar: React.FC<OrderSideBarProps> = ({
  selectedTab = 0,
}) => {
  const [searchText, setSearchText] = useState("");
  const [textDebounced] = useDebounce(searchText, 1000);
  const [activeTab, setActivetab] = useState(0);

  const handleAddButtonClick = async (rows: SuperComponent[]) => {
    await sdk2.editor.addRows(rows);
  };

  useEffect(() => {
    if (selectedTab == 0) {
      const curTab = activeTab ?? 0;
      setActivetab(curTab);
    }
  }, [selectedTab]);

  const componentsOptions = useVirtualListQueryOptions(
    "q",
    textDebounced,
    pageSize
  );

  const productsOptions = useVirtualListQueryOptions(
    "q",
    textDebounced,
    pageSize
  );

  const componentsData = useComponentVirtualList({
    ...componentsOptions,
    serializeEdges: (edges) => edges.map((edge) => createRow(edge.node)),
  });

  const productsData = useProductVirtualList({
    ...productsOptions,
    serializeEdges: (edges) => edges.map((edge) => createRow(edge.node)),
  });

  const ComponentsTable = useMemo(() => {
    if (selectedTab == 0 && activeTab == 0) {
      return (
        <ChangeOrderSimpleTable
          virtualListData={componentsData}
          pageSize={pageSize}
          columns={columns("Components")}
          name="Components"
          pending={sdk2.state.pendingRows}
          handleAddButtonClick={handleAddButtonClick}
        />
      );
    }
    return null;
  }, [selectedTab, activeTab, componentsData]);

  const ProductsTable = useMemo(() => {
    if (selectedTab == 0 && activeTab == 1) {
      return (
        <ChangeOrderSimpleTable
          pending={sdk2.state.pendingRows}
          virtualListData={productsData}
          pageSize={pageSize}
          columns={columns("Products")}
          name="Products"
          handleAddButtonClick={handleAddButtonClick}
        />
      );
    }
    return null;
  }, [selectedTab, activeTab, productsData]);

  const tabs = [
    {
      Component: ComponentsTable,
      tabProps: {
        label: "Components",
        value: "components",
      },
    },
    {
      Component: ProductsTable,
      tabProps: {
        label: "Products",
        value: "products",
      },
    },
  ].map((o, id) => ({ ...o, id }));

  const handleTabChange = (_: any, newTab: number) => {
    setActivetab(Number(newTab));
  };

  return (
    <SidebarWrapper
      sx={{
        minWidth: "100%",
      }}
    >
      <AddComponentsBar>
        <LeadingText mt={0}>Add Products and Components</LeadingText>
        <SearchContainer>
          <SearchField
            placeholder="Enter a Search Term or Query"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
            fullWidth
          />
        </SearchContainer>
      </AddComponentsBar>
      <TableContainer sx={{ width: "100%", maxHeight: "100%" }}>
        <TabContext
          sx={{
            minHeight: "100%",
            width: "100%",
            padding: "0px",
            margin: "0px",
          }}
        >
          <TabList
            onChange={handleTabChange}
            value={activeTab.toString()}
            sx={{
              padding: "0 1.2rem",
              margin: "0.5rem 0 1rem 0",
            }}
          >
            {tabs.map(({ id, tabProps }) => (
              <StyledTab
                {...tabProps}
                value={id.toString()}
                isActive={activeTab === id}
                key={id}
              >
                {tabProps.label}
              </StyledTab>
            ))}
          </TabList>

          {tabs.map(({ id, Component }) => (
            <Box
              sx={{
                height: `calc(75vh)`,
              }}
              key={id}
              display={activeTab == id ? "" : "none"}
            >
              {Component}
            </Box>
          ))}
        </TabContext>
      </TableContainer>
    </SidebarWrapper>
  );
};

const StyledId = styled("div")({
  minWidth: "80px",
  color: colorPalette.taupeGray,
  fontSize: "0.8.75rem",
  a: {
    pointerEvents: "none",
    color: colorPalette.taupeGray,
  },
});

const StyledCpnName = styled("div")({
  textOverflow: "ellipsis",
  whiteSpace: "noWrap",
  overflow: "hidden",
});

const StyledCPN = styled("div")({
  fontSize: "0.875rem",
  display: "flex",
  paddingLeft: "2px",
  width: "220px",
  overflow: "hidden",
});

const StyledTab = styled(Tab)(({ isActive }) => ({
  borderBottom: `2px solid ${
    isActive ? colorPalette.green : colorPalette.taupeGray
  }`,
  color: isActive ? colorPalette.green : colorPalette.taupeGray,
  borderRadius: "0px",
}));
