/**
 * TODO (PLM-6232): The component/file currently is just wrapping the old functionality used here.
 * It needs to be updated to using MUI components and make it so that the data isn't modified in way
 * that are hard to follow and can have unintended side effects.
 */

import { PageItemType, PageMode } from "@duro/utils";
import $ from "jquery";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import ComponentActions from "v1/action-types/component";
import ProductActions from "v1/action-types/product";
import UiActions from "v1/action-types/ui";
import SourcingEdit from "v1/components/page/common/sourcing/edit";
import SourcingUtils from "v1/components/page/common/sourcing/sourcing-utils";
import SourcingView from "v1/components/page/common/sourcing/view/row-mode";
import Spinner from "v1/components/ui/spinner";
import buildAction from "v1/helpers/buildAction";
import Schemas from "v1/modules/schemas";
import Utils from "v1/modules/utils";
import validations from "v1/modules/validations";
import { TabError, TabValue } from "./constants";
import { useEditObject, useLoadChildren, useViewObject } from "./hooks";

export interface PrimarySource {
  allAreValid?: boolean;
  leadTime: string | { value: string, units: number };
  leadTimeUnit: string;
  manufacturer?: string;
  minQuantity: string;
  mpn?: string;
  primarySourceId?: string;
  rolledUpCost?: boolean;
  uniqueId?: string;
  unitPrice: string;
}

export interface SourcingTabProps {
  activeTab: TabValue;
  id: string;
  isAssembly: boolean;
  isDiffTool: boolean;
  isEditing: boolean;
  isVisible: boolean;
  mode: PageMode;
  pageItemType: PageItemType;
  showSourcingTab: () => void;
  updateErrorCount: (valid: boolean, tab: TabError, amount?: number) => void;
}

function useTargetRev(isDiffTool: boolean) {
  return useSelector((store: any) => {
    if (!isDiffTool) return undefined;

    return {
      children: store.itemDiff?.targetRev?.children,
      manufacturers: store.itemDiff?.targetRev?.manufacturers,
    };
  });
}

function useUserStyles() {
  return useSelector((store: any) => store.user.preferences.styles);
}

function useInputStatusValue(pageItemType: PageItemType) {
  return useSelector((store: any) => {
    switch (pageItemType) {
      case PageItemType.COMPONENT: return store.components?.editPage?.inputs?.status?.value;
      default: return store.products?.editPage?.inputs?.status?.value;
    }
  });
}

function useInputManufacturersValue(pageItemType: PageItemType) {
  return useSelector((store: any) => {
    switch (pageItemType) {
      case PageItemType.COMPONENT: return store.components?.editPage?.inputs?.manufacturers?.value;
      default: return store.products?.editPage?.inputs?.manufacturers?.value;
    }
  });
}

function useCompanyName() {
  return useSelector((store: any) => store.company.name);
}

interface SetPrimarySourceFromApiProps {
  baseObject: any;
  dispatch: Dispatch;
  pageItemType: PageItemType;
  primarySource: PrimarySource;
}

function setPrimarySourceInApi(props: SetPrimarySourceFromApiProps) {
  const { baseObject, dispatch, pageItemType, primarySource } = props;
  if (primarySource) {
    const { primarySourceId, rolledUpCost } = primarySource;
    const doc = SourcingUtils.setPrimarySrc(primarySourceId, rolledUpCost, baseObject);
    const payload = {
      _id: doc._id,
      primarySource: {
        _id: primarySourceId,
        doc,
        rolledUpCost,
        settingPrimarySourceFromViewPage: true,
      },
    };

    switch (pageItemType) {
      case PageItemType.COMPONENT:
        dispatch(buildAction(ComponentActions.SET_PRIMARY_SOURCE, payload));
        break;
      case PageItemType.PRODUCT:
        dispatch(buildAction(ProductActions.SET_PRIMARY_SOURCE, payload));
        break;
      default: // No other page items types need to set primary source.
    }
  }
}

export function SourcingTab(props: SourcingTabProps) {
  const {
    activeTab,
    id,
    isAssembly,
    isDiffTool,
    isEditing,
    isVisible,
    mode,
    pageItemType,
    showSourcingTab,
    updateErrorCount,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const viewObject = useViewObject(pageItemType);
  const editObject = useEditObject(pageItemType);
  const targetRev = useTargetRev(isDiffTool);
  const userStyles = useUserStyles();
  const statusValue = useInputStatusValue(pageItemType);
  const inputManufacturersValue = useInputManufacturersValue(pageItemType);
  const companyName = useCompanyName();
  const areChildrenLoaded = useLoadChildren({ id, mode, pageItemType, skip: !isVisible });

  const [primarySource, setPrimarySource] = useState<PrimarySource | undefined>(undefined);
  const [editPrimarySource, setEditPrimarySource] = useState<PrimarySource | undefined>(undefined);
  const [syncSourcingRowTable, setSyncSourcingRowTable] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(true);
  const [showErrorModalAfterBulk, setShowErrorModalAfterBulk] = useState(true);
  const [syncWithRolledUpCost, setSyncWithRolledUpCost] = useState(false);
  const [allowPrimarySourceIcon, setAllowPrimarySourceIcon] = useState(false);
  const [rolledUpCostAsPrimary, setRolledUpCostAsPrimary] = useState(false);

  const component = useMemo(() => (isEditing ? editObject : viewObject), [editObject, isEditing, viewObject]);

  const manufacturers = useMemo(() => {
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        return isEditing ? editObject.manufacturers : viewObject.manufacturers;
      default:
        return null;
    }
  }, [editObject, isEditing, pageItemType, viewObject]);

  const tabsType = useMemo(() => {
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        return "cmp";
      default:
        return "prd";
    }
  }, [pageItemType]);

  const { clientSchema, schema } = useMemo(() => {
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        return {
          clientSchema: validations.component.manufacturers,
          schema: Schemas.component,
        };
      default:
        return {
          clientSchema: validations.product.manufacturers,
          schema: Schemas.product,
        };
    }
  }, [pageItemType]);

  const onPrimaryRowSelection = useCallback((event, item) => {
    if (event.target.checked) {
      setPrimarySource({
        leadTime: item.quoteLeadTimeValue,
        leadTimeUnit: item.quoteLeadTimeUnits,
        minQuantity: item.quoteMinQuantity,
        primarySourceId: item.quote_id,
        rolledUpCost: false,
        unitPrice: item.quoteUnitPrice,
      });
    }
    else setPrimarySource(undefined);
  }, []);

  const onRolledUpSelectView = useCallback((event, rolledUpCost) => {
    if (Object.keys(rolledUpCost).length > 0 && event.target.checked) {
      setPrimarySource({
        leadTime: rolledUpCost.leadTimeValue,
        leadTimeUnit: rolledUpCost.leadTimeUnit,
        minQuantity: rolledUpCost.rolledUpCost,
        primarySourceId: "dummy",
        rolledUpCost: true,
        unitPrice: rolledUpCost.totalPrice,
      });
    }
    else setPrimarySource(undefined);

    setSyncSourcingRowTable(true);
  }, []);

  const onPrimaryRowDeselection = useCallback(event => {
    const ps = {
      leadTime: "",
      leadTimeUnit: "",
      minQuantity: "",
      primarySourceId: "dummy",
      unitPrice: "",
    };
    setPrimarySource(ps);
    setPrimarySourceInApi({ baseObject: viewObject, dispatch, pageItemType, primarySource: ps });
  }, [dispatch, pageItemType, viewObject]);

  const savePrimarySource = useCallback(() => {
    if (primarySource) {
      setPrimarySourceInApi({ baseObject: viewObject, dispatch, pageItemType, primarySource });
      Utils.setLocalStorageForAssemblyTree(id);
    }
  }, [dispatch, id, pageItemType, viewObject, primarySource]);

  const afterSyncSourcingRowTable = useCallback(() => {
    setSyncSourcingRowTable(false);
  }, []);

  const onChange = useCallback((event: CustomEvent) => {
    const { value, name } = event.target as any;
    const payload = { value, name };
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, payload));
        break;
      case PageItemType.PRODUCT:
        dispatch(buildAction(ProductActions.UPDATE_EDIT_FORM_INPUT_STATE, payload));
        break;
      default: // No other page items types need to edit.
    }
  }, [dispatch, pageItemType]);

  const displayErrorModal = useCallback(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const showModal = urlParams.has("showModal") ? urlParams.get("showModal") : null;

    const payload = {
      closeCb: () => { },
      errors: [{
        // eslint-disable-next-line max-len
        message: "By switching to revision control Duro validation rules are now active. Any errors found must be corrected before you can continue. If you do not wish to correct these errors now, switch back to design mode before saving.",
      }],
      errorType: "custom",
      type: "errors",
    };

    if (showErrorModal && showModal === "false") {
      setShowErrorModal(false);
    }
    else if (!showErrorModalAfterBulk) {
      setShowErrorModalAfterBulk(true);
    }
    else {
      dispatch(buildAction(UiActions.SHOW_ALERT, payload));
    }
  }, [dispatch, history, showErrorModal, showErrorModalAfterBulk]);

  const afterSyncWithRolledUpCost = useCallback(() => setSyncWithRolledUpCost(false), []);

  const onPrimarySourceChange = useCallback((isChecked, quote) => {
    if (isChecked) {
      setPrimarySource({
        ...quote,
        allAreValid: true,
      });
      setAllowPrimarySourceIcon(true);
      setRolledUpCostAsPrimary(false);
    }
    else {
      setPrimarySource({
        leadTime: "",
        leadTimeUnit: "",
        minQuantity: "",
        uniqueId: "dummy_id",
        unitPrice: "",
      });
      setAllowPrimarySourceIcon(false);
    }
  }, []);

  const disablePrimarySourceBtn = useCallback(() => setAllowPrimarySourceIcon(false), []);
  const enablePrimarySourceBtn = useCallback(() => setAllowPrimarySourceIcon(true), []);
  const syncPrimaryQuoteInputWithData = useCallback(quote => setEditPrimarySource(quote), []);

  const onRolledUpSelectEdit = useCallback((event, rolledUpCost) => {
    if (Object.keys(rolledUpCost).length > 0 && event.target.checked) {
      setAllowPrimarySourceIcon(true);
      setRolledUpCostAsPrimary(true);
      setPrimarySource({
        allAreValid: rolledUpCost.allAreValid,
        leadTime: {
          units: rolledUpCost.leadTimeUnit,
          value: rolledUpCost.leadTimeValue,
        },
        leadTimeUnit: rolledUpCost.leadTimeUnit,
        manufacturer: editObject.rolledUpCost?.manufacturer ?? companyName,
        minQuantity: rolledUpCost.minQuantity,
        mpn: editObject.rolledUpCost?.mpn ?? Utils.getCpn(editObject),
        primarySourceId: "dummy",
        rolledUpCost: true,
        unitPrice: rolledUpCost.totalPrice,
      });
    }

    else {
      setAllowPrimarySourceIcon(false);
      setRolledUpCostAsPrimary(false);

      setPrimarySource({
        leadTime: "",
        leadTimeUnit: "",
        minQuantity: "",
        rolledUpCost: false,
        uniqueId: "dummy_id",
        unitPrice: "",
      });
    }
  }, [companyName, editObject]);

  const onRolledUpInputChanged = useCallback((data: any) => {
    // TODO (PLM-6232): Figure out out to fix this...
    editObject.rolledUpCost = { mpn: data.mpn, manufacturer: data.manufacturer };
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE));
        break;
      default:
        dispatch(buildAction(ProductActions.UPDATE_EDIT_FORM_INPUT_STATE));
    }
    setSyncWithRolledUpCost(true);
  }, [dispatch, editObject, pageItemType]);

  const onPrimarySourceDeselect = useCallback(() => {
    // TODO (PLM-6232): This needs to be removed...
    $(".quotes .primary-source-checkbox input:checkbox").prop("checked", false);

    setAllowPrimarySourceIcon(false);
    setRolledUpCostAsPrimary(false);
    setEditPrimarySource(undefined);

    // TODO (PLM-6232): Figure out out to fix this...
    editObject.rolledUpCostAsPrimary = false;
    editObject.primarySource = {
      leadTime: {
        units: "",
        value: "",
      },
      leadTimeUnit: "",
      manufacturer: "",
      minQuantity: "",
      mpn: "",
      uniqueId: "dummy_id",
      unitPrice: "",
    };

    // TODO (PLM-6232): Figure out out to fix this...
    /* eslint-disable no-param-reassign */
    inputManufacturersValue.forEach((man: any) => {
      man.isPrimary = false;
      man.distributors.forEach((dist: any) => {
        dist.isPrimary = false;
        dist.quotes.forEach((quote: any) => {
          quote.isPrimary = false;
          if (quote.inputs) quote.inputs.isChecked.value = false;
        }); // end dist.quotes.forEach((quote, i) =>
      }); // end man.distributors.forEach((dist, i) =>
    }); // end manufacturers.forEach((man, i) =>
    // return data
    /* eslint-enable no-param-reassign */

    switch (pageItemType) {
      case PageItemType.COMPONENT:
        dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE));
        break;
      default:
        dispatch(buildAction(ProductActions.UPDATE_EDIT_FORM_INPUT_STATE));
    }
    setSyncSourcingRowTable(true);
  }, [dispatch, editObject, inputManufacturersValue, pageItemType]);

  const setPrimarySourceInObject = useCallback(() => {
    // TODO (PLM-6232): Figure out out to fix this...
    editObject.primarySource = Utils.clone(primarySource);
    editObject.rolledUpCostAsPrimary = rolledUpCostAsPrimary;

    const quoteUniqueId = primarySource?.uniqueId;

    // TODO (PLM-6232): Figure out out to fix this...
    /* eslint-disable no-param-reassign */
    inputManufacturersValue.forEach((man: any) => {
      man.isPrimary = false;
      if (man.distributors.length > 0) {
        man.distributors.forEach((dist: any) => {
          dist.isPrimary = false;
          if (dist.quotes.length > 0) {
            dist.quotes.forEach((quote: any) => {
              quote.isPrimary = false;

              if (quoteUniqueId === quote.uniqueId && rolledUpCostAsPrimary !== true) {
                man.isPrimary = true;
                dist.isPrimary = true;
                quote.isPrimary = true;
                editObject.primarySource.manufacturer = man.name;
                editObject.primarySource.mpn = man.mpn.key;
              }
            }); // end dist.quotes.forEach((quote, i) =>
          } // if (dist.quotes.length > 0)
        }); // end man.distributors.forEach((dist, i) =>
      } // end if (man.distributors.length > 0)
    }); // end manufacturers.forEach((man, i) =>
    // return data
    /* eslint-enable no-param-reassign */

    switch (pageItemType) {
      case PageItemType.COMPONENT:
        dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE));
        break;
      default:
        dispatch(buildAction(ProductActions.UPDATE_EDIT_FORM_INPUT_STATE));
    }
    setAllowPrimarySourceIcon(false);
    setEditPrimarySource(editObject.primarySource);
    setSyncWithRolledUpCost(true);
  }, [dispatch, editObject, inputManufacturersValue, pageItemType, primarySource, rolledUpCostAsPrimary]);

  const editSku = pageItemType === PageItemType.COMPONENT;
  const category = component?.category;

  if (!areChildrenLoaded) {
    return <Spinner />;
  }

  return (
    <>
      {!isEditing && (
        <SourcingView
          afterSyncSourcingRowTable={afterSyncSourcingRowTable}
          allowPrimarySourceSelection={true}
          children={viewObject.children}
          component={component}
          defaultCurrency={viewObject.defaultCurrency}
          dispatch={dispatch}
          isAssembly={isAssembly}
          isDiffTool={isDiffTool}
          manufacturers={manufacturers}
          mode={mode}
          objectData={viewObject}
          onPrimaryRowDeselection={onPrimaryRowDeselection}
          onPrimaryRowSelection={onPrimaryRowSelection}
          onRolledUpSelect={onRolledUpSelectView}
          primarySource={primarySource}
          setPrimarySource={savePrimarySource}
          syncSourcingRowTable={syncSourcingRowTable}
          tabsType={tabsType}
          targetRevChildren={targetRev?.children}
          targetRevManufacturers={targetRev?.manufacturers}
        />
      )}
      {isEditing && (
        <SourcingEdit
          activeTab={activeTab}
          afterSyncWithRolledUpCost={afterSyncWithRolledUpCost}
          allowPrimarySourceIcon={allowPrimarySourceIcon}
          category={category}
          clientSchema={clientSchema}
          component={component}
          defaultCurrency={editObject.defaultCurrency}
          disablePrimarySourceBtn={disablePrimarySourceBtn}
          dispalyErrorModal={displayErrorModal}
          editSku={editSku}
          enablePrimarySourceBtn={enablePrimarySourceBtn}
          history={history}
          isAssembly={isAssembly}
          itemId={editObject._id}
          manufacturers={manufacturers}
          name="manufacturers"
          objectData={editObject}
          onChange={onChange}
          onPrimarySourceChange={onPrimarySourceChange}
          onPrimarySourceDeselect={onPrimarySourceDeselect}
          onRolledUpInputChanged={onRolledUpInputChanged}
          onRolledUpSelect={onRolledUpSelectEdit}
          primarySource={editPrimarySource ?? editObject.primarySource}
          schema={schema}
          setPrimarySourceInObject={setPrimarySourceInObject}
          showSourcingTab={showSourcingTab}
          status={statusValue}
          syncPrimaryQuoteInputWithData={syncPrimaryQuoteInputWithData}
          syncWithRolledUpCost={syncWithRolledUpCost}
          tabsType={tabsType}
          toggleErrorCount={updateErrorCount}
          userStyles={userStyles}
        />
      )}
    </>
  );
}
