import { createNewChangeOrder } from "./create-change-order";
import { getNextRevision } from "./revisions";
import { postChangeOrder } from "./submit-change-order";
import {
  postComponentNextRevisionUpdate,
  postComponentUpdate,
} from "./update-component";

export const ChangeOrderREST = {
  components: {
    postComponentUpdate,
    postComponentNextRevisionUpdate,
    getNextRevision,
  },
  changeorder: {
    postChangeOrder,
    create: createNewChangeOrder
  },
};
