import Tooltip from "rc-tooltip";
import { useCallback, useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import CoIcon from "../../../../../assets/icons/co";
import CoRevertIcon from "../../../../../assets/icons/co-revert";
import CompareRevIcon from "../../../../../assets/icons/compare-rev.js";
import HelpIcon from "../../../../../assets/icons/help-icon.js";
import API from "../../../../../modules/api";
import InlineIcon from "../../../../ui/icon/inline-icon.js";
import Loading from "../../../../ui/inner-loading";
import Link from "../../../../ui/link";
import ReactTable from "./table";
import { useExternalClick } from "../../../../../modules/hooks";


import "../index-generic.scss";
import "./index.scss";
import { publicPaths } from "v1/app/routes";

/**
 * The Revision History Table for a Product/Component
 * @param {Object} props
 */
const RevisionHistoryTable = ({
    componentId,
    userRole,
    viewType,
    mode,
    addToChangeOrder,
    alias,
    revertBack,
    revModal,
    closeRevModal,
    revList,
    setRevList,
    currentRevId,
    isParentRevId
}) => {
    const showIconsForVendor = mode === "modified" && viewType !== "revision";

    const [loading, setLoading] = useState(false);
    const [targetRevId, setTargetRevId] = useState(null);
    const [sourceRevId, setSourceRevId] = useState(null);
    const [imagesWithSrc] = useState([])

    const modalRef = useRef();
    useExternalClick(modalRef, closeRevModal);

    // TODO: fetch from core-api using hook
    // fetch major revisions from plm-api
    const fetchDataFromApi = useCallback(
        (componentId) => {
            setLoading(true)
            const id = `getByComponentOrProductId/?id=${componentId}&type=${alias}`;
            API["revisions"].findById(id, (err, data) => {
                if (err) {
                    console.log(err);
                } else {
                    setRevList(data);
                    setLoading(false);
                }
            });
        },
      [alias, setRevList],
    )
    

    // fetch major revisions on first load
    useEffect(() => {
        if (revList && !revList.length) {
            fetchDataFromApi(componentId);
        }
    }, [fetchDataFromApi, revList, componentId]);

    // initialize source and target revisions (diff tool)
    useEffect(() => {
        if (revList && revList.length) {
            if (viewType === 'revision') {
                setTargetRevId(revList[revList.length - 1]._id);
                setSourceRevId(currentRevId)
            } else {
                setSourceRevId(revList[revList.length - 1]._id);
                if (revList.length > 1) setTargetRevId(revList[revList.length - 2]._id);
            }
        }
    }, [revList, currentRevId, viewType]); 

    // handle selecting target revision (for diff tool)
    const selectTargetRevision = (newTargetRevId) => {
        setTargetRevId(newTargetRevId === targetRevId ? null : newTargetRevId);
    };

    // helper to find rev in revList
    const findRev = (revId) => {
        for (const rev of revList) {
            if (rev._id === revId) return rev;
            if (rev.subRevisions && rev.subRevisions.length) {
                for (const subRev of rev.subRevisions) {
                    if (subRev._id === revId) return subRev;
                }
            }
        }
    }

    // diff tool
    const compareRevisions = () => {
        const getURL = Object.freeze({
            cmp: "component",
            prd: "product",
            include: 'include=images,documents,assemblyRevision',
            source: swap => `sourceRevId=${swap ? targetRevId : sourceRevId}?${getURL["include"]}`,
            target: swap => `targetRevId=${swap ? sourceRevId : targetRevId}?${getURL["include"]}`,
        });

        let swap = false;
        if (viewType == 'revision') {
            const sourceRev = findRev(sourceRevId);
            const targetRev = findRev(targetRevId);
            if (sourceRev.created < targetRev.created) {
                swap = true;
            }
        }
        window.location = `/${getURL[alias]}/diff/${componentId}?${getURL["source"](swap)}&${getURL["target"](swap)}`;
    }

    // helper to make revision links
    const makeRevisionLink = item => {
        const getURL = Object.freeze({
            cmp: "component",
            prd: "product",
            role: (mostRecentRev) =>
                mostRecentRev &&
                    userRole !== "VENDOR" &&
                    item._id === mostRecentRev._id
                    ? `view/${componentId}`
                    : `revision/${item._id}`,
            restriction: () => (userRole === "SUPPLIER" ? "?viewRecent=true" : ""),
        });

        let mostRecentRev = revList.length > 0 && revList[revList.length - 1];
        return `/${getURL[alias]}/${getURL["role"](mostRecentRev)}${getURL["restriction"]()}`;
    };

    return (
        <div
            ref={modalRef}
            id="rev-history-table"
            className={
                "custom-duro-modal " +
                (mode === "modified" ? "custom-rev-modal" : "") +
                (revModal ? " open" : "")
            }
        >
            <div className="header-block flex-h">
                <div className="item-flex">
                    <h4>History</h4>
                    <Tooltip
                        overlay="Help"
                        placement="top"
                        overlayClassName={"help-container default-styles"}
                    >
                        <div
                            className="help-icon"
                            data-tip="Help"
                            data-for="revision-history-tooltip"
                        >
                            <Link
                                to={publicPaths.docs.compareRevisionsHelp}
                                target="_blank"
                            >
                                <InlineIcon className="co-link-icon">
                                    <HelpIcon />
                                </InlineIcon>
                            </Link>
                        </div>
                    </Tooltip>
                </div>
                <div className="item-flex">
                    {showIconsForVendor && (
                        <div
                            className={"compare-rev-btn flex"}
                            onClick={addToChangeOrder}
                        >
                            <h4>{`${alias === "cmp" ? "Component" : "Product"
                                } has been modified`}</h4>
                        </div>
                    )}

                    {showIconsForVendor && (
                        <div
                            className={"compare-rev-btn flex add-to-co"}
                            onClick={addToChangeOrder}
                        >
                            <InlineIcon>
                                <CoIcon />
                            </InlineIcon>
                            <h4>Add to change order</h4>
                        </div>
                    )}
                    {showIconsForVendor && userRole !== "VENDOR" && (
                        <div
                            className={"compare-rev-btn flex revert-back"}
                            onClick={revertBack}
                        >
                            <InlineIcon>
                                <CoRevertIcon />
                            </InlineIcon>
                            <h4>Revert back</h4>
                        </div>
                    )}

                    <Tooltip
                        overlay="Select a revision to compare"
                        placement="top"
                        overlayClassName={`help-container default-styles ${sourceRevId && targetRevId ? "hidden" : ""
                            }`}
                    >
                        <div
                            className={`compare-rev-btn flex ${sourceRevId && targetRevId ? "active" : ""
                                }`}
                            onClick={targetRevId ? compareRevisions : null}
                        >
                            <InlineIcon>
                                <CompareRevIcon />
                            </InlineIcon>
                            <h4>Compare Revisions</h4>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className="content-block">
                {loading && <Loading />}
                {!loading && revList && revList.length &&
                    <PerfectScrollbar className="custom-table-scroll">
                        <ReactTable
                            revisions={revList}
                            setRevisions={setRevList}
                            sourceRevId={sourceRevId}
                            targetRevId={targetRevId}
                            selectTargetRevision={selectTargetRevision}
                            makeRevisionLink={makeRevisionLink}
                            isParentRevId={isParentRevId}
                            imagesWithSrc={imagesWithSrc}
                        />
                    </PerfectScrollbar>
                }
            </div>
        </div>
    );
};

export default RevisionHistoryTable;