import { Component } from "react";
import Alert from '@mui/material/Alert';
import {Box} from "@mui/material";
import { TransactionalModal } from "common/components/modals/transactionalModal";
import ReactTooltip from 'react-tooltip';
import Utils from "../../../../modules/utils";
import LazyInput from "../../../ui/lazy-input/input.js";
import { FormModal } from "common/components/modals";
import InfoIcon from "@mui/icons-material/Info";

const modalProps = {
  width: "35.313rem",
}
export default class StatusUpdateModalForNonAssembly extends Component {
  render() {
    return <FormModal
        onCancel={this.props.onRejectStatusChange}
        onClose={this.props.onCancel}
        disableSubmit={!this.props.revisionInput.valid}
        onSubmit={this.props.onAcceptStatusChange}
        open={open}
        modalProps={modalProps}
        submitLabel="Continue"
        title={
          this.props.statusFieldPreviousValue === 'DESIGN'
            ? 'Switch to Revision Control'
            : `Moving into ${this.props.newStatusValue.toLowerCase().replace(/\b\w/g, match => match.toUpperCase())}`
        }
      >
        {this.props.statusFieldPreviousValue === 'DESIGN' ? (
          <Box sx={{ marginBottom: "1.25rem" }}>
            This {this.props.item} will now be under revision control. All your
            changes will be tracked going forward. Use Change Orders to control new
            revisions.
          </Box>
        ) : (
          <Box sx={{ marginBottom: "1.25rem" }}>
            <Box>
              This {this.props.item} will now be marked as{' '}
              {Utils.capitalizeString(this.props.newStatusValue)}.
            </Box>
            <Box>This can not be undone.</Box>
          </Box>
        )}
        <Box sx={{
          display: "flex",
          alignItems: "baseline",
          marginBottom: "1.5rem",
        }}
        >
          <Box sx={{ minWidth: "6rem", fontWeight: 500 }}>
            New Revision
          </Box>
          <ReactTooltip id={"input-revision-modal-input"}
            type="light"
            effect="solid"
          >
          </ReactTooltip>
          <Box sx={{ maxWidth: "10rem" }}>
            <LazyInput
              type="text"
              name="revision"
              autoFocus={true}
              value={this.props.revisionInput.value}
              onChange={this.props.revisionInputChange}
              className={this.props.revisionInput.class}
              data-tip={this.props.revisionInput.message}
              data-place="right"
              data-type="error"
              data-for="input-revision-modal-input"
          />
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        alignItems: "baseline",
      }}
      >
        <Box sx={{minWidth: "6rem", fontWeight: 500}}>
          New Status
        </Box>
        <Box>{this.props.newStatusValue}</Box>
      </Box>
      <Box>
        <Alert variant="standard" icon={<InfoIcon fontSize="inherit" />} severity="warning" sx={{
          padding: "0 !important",
          margin: "1rem 0 !important",
          '& .MuiAlert-message': {
            padding: "0 !important",
            margin: "0 !important",
          }
        }}>
          Revision changes are applied after the component is saved. Click ‘Continue’ to proceed and then save
          changes to see the updated revision value.
        </Alert>
      </Box>
    </FormModal>
  }
}
