import "../index.scss";
import React from "react";
import ERPTileIcon from "../../../../../assets/icons/erp-tile-icon";
import InlineIcon from "../../../../ui/icon/inline-icon.js";
import ItemScheduleIcon from "../../../../../assets/icons/item-schedule-icon";
import TimeStampColumn from "../../timestamp-column";
import TileItem from "../../tile-item";

const ERPDates = ({ effectivity }) => {
  const dates = {
    "Start Date": effectivity.startDate,
    "End Date": effectivity.endDate,
  };
  return Object.keys(dates).map((date) => (
    <div className="inner-info erp-info values-to-right diff-effectivity-section">
      <span className="inner-attribute">{date}</span>
      <div className="erp-date erp-custom">
        {dates[date] ? (
          <TimeStampColumn format="date-with-long-format" value={dates[date]} />
        ) : (
          <span className="no-day">Enter date</span>
        )}
        <InlineIcon tooltip="Date" tooltipPlace="top">
          <ItemScheduleIcon />
        </InlineIcon>
      </div>
    </div>
  ));
};

const showEffectivity = (effectivity) => {
  return (
    <React.Fragment>
      <span className={"erp-title"}>Effectivity</span>
      <ERPDates effectivity={effectivity} />
    </React.Fragment>
  );
};

const ItemType = ({ itemType }) => {
  return (
    <React.Fragment>
      <div
        className={
          "erp-section-separator inner-info values-to-right diff-itemType-section"
        }
      >
        <span className={"erp-title"}>Item Type</span>
        <span className={"inner-value"}>{itemType}</span>
      </div>
    </React.Fragment>
  );
};

const ERPView = ({ item }) => {
  const { effectivity, itemType } = item;
  return (
    <TileItem title="ERP" icon={<ERPTileIcon />}>
      <div className="erp">
        {showEffectivity(effectivity)}
        {<ItemType itemType={itemType} />}
      </div>
    </TileItem>
  );
};

export default ERPView;
