import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { colorPalette } from "@duro/themes"; // TODO: move this to @duro/typography

/**
 * Props for the CustomTypography component.
 * NOTE: The optional props are for backwards compatibility with the previous implementation.
 *
 * @property {string} type - The type of typography, which determines the color and text transformation.
 * @property {boolean} [isOverflowed] - Optional. If true, applies styles for overflowed text.
 * @property {boolean} [noWrap] - Optional. If true, disables text wrapping.
 * @property {number} [tabIndex] - Optional. Specifies the tab order of the element.
 */
export type DuroPanelTypographyProps = {
  type: string;
  isOverflowed?: boolean;
  noWrap?: boolean;
  tabIndex?: number;
};

/**
 * Enumeration for custom specifications.
 *
 * @readonly
 * @enum {string}
 */
export const enum CustomSpecsEnum {
  KEY = "KEY",
  VALUE = "VALUE",
}

/**
 * A styled component that renders a Typography element with custom styles based on the provided props.
 *
 * @component
 * @example
 * <TypographyTextStyle type={CustomSpecsEnum.KEY} isOverflowed={false}>Sample Text</TypographyTextStyle>
 *
 * @param {CustomTypographyProps} props - The props for the TypographyTextStyle component.
 * @param {React.Ref<HTMLSpanElement>} ref - The ref to the span element.
 *
 * @returns {JSX.Element} A styled span element with Typography properties.
 */
const DuroPanelTypographyTextStyle = styled(
  React.forwardRef<
    HTMLSpanElement,
    { isOverflowed?: boolean } & DuroPanelTypographyProps
  >(({ isOverflowed, type, ...props }, ref) => (
    <Typography {...props} ref={ref} component="span" />
  ))
)(({ type, isOverflowed = false }) => ({
  alignItems: "center",
  color:
    type === CustomSpecsEnum.KEY ? colorPalette.taupeGray : colorPalette.white,
  cursor: isOverflowed ? "help" : "default",
  fontSize: "0.9rem",
  maxWidth: "96pt",
  overflow: "hidden",
  textTransform: type === CustomSpecsEnum.KEY ? "uppercase" : "inherit",
  whiteSpace: "nowrap",
}));

export default DuroPanelTypographyTextStyle;
