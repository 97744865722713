import "./index.scss";
import React, { Component }         from "react";
import { Popover } from 'react-tiny-popover';
import API                          from "../../../../modules/api";
import LinkIcon                     from "../../../../assets/icons/open-link-icon.js";
import InlineIcon                   from "../../../../components/ui/icon/inline-icon.js";
import Link                         from "../../../../components/ui//link";
import Utils from "v1/modules/utils";

export default class ComponentSuccessPopover extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.onModalExternalClick = this.onModalExternalClick.bind(this);
        this.state =
        {
            newlyCreatedCmp: null,
            isMounte: false
        }
    }

    componentWillMount()
    {
        if (this.props.cmpId)
        {
            let state = this.state;
            API.components.findById(this.props.cmpId, (err, componentData) =>
            {
                let newlyCreatedCmp = componentData;
                state.newlyCreatedCmp = newlyCreatedCmp;
                state.isMounte = true;
                document.body.addEventListener("click", this.onModalExternalClick);

                let _this = this
                setTimeout(function(){ _this.onModalExternalClick()}, 5000);
                this.setState(state);
            });
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if (this.props.cmpId !== nextProps.cmpId && !this.state.newlyCreatedCmp && this.state.isMounte)
        {
            let state = this.state;
            API.components.findById(this.props.cmpId, (err, componentData) =>
            {
                let newlyCreatedCmp = componentData;
                state.newlyCreatedCmp = newlyCreatedCmp;
                document.body.addEventListener("click", this.onModalExternalClick);

                let _this = this
                setTimeout(function(){ _this.onModalExternalClick()}, 5000);
                this.setState(state);
            });
        }
    }

    onModalExternalClick(event)
    {
        document.body.removeEventListener("click", this.onModalExternalClick);
        this.setState({newlyCreatedCmp: null});
    }

    render()
    {
        if (!this.state.newlyCreatedCmp)
        {
            return null;
        }
        let cpnAndName = `${this.state.newlyCreatedCmp.cpn} ${this.state.newlyCreatedCmp.name}`;
        let to  = `/component/view/${this.state.newlyCreatedCmp._id}`;


        let markup =
            <Popover
                padding={60}
                containerClassName={`cmp-success-popover ${this.props.className}`}
                isOpen={true}
                position={"right"}
                onClickOutside={this.props.hideFeaturePopOver ? this.props.hideFeaturePopOver : Utils.noop}
                content={({ position, targetRect, popoverRect }) => (
                    <div className="popover-content-block">
                        <h4 className="">Component successful</h4>
                        <p className="">A new component has been created in your library.</p>
                        <Link
                            to={to}
                            target="_blank"
                            className="open-link-holder"
                            >
                            {cpnAndName}
                            <InlineIcon ><LinkIcon/></InlineIcon>
                        </Link>
                    </div>
                )}
            >
            <div></div>
            </Popover>
        return markup;
    }
}
