import { useMemo } from "react";
import {
  Box, FormControlLabel, FormGroup, Radio, RadioGroup, Typography, styled,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { DuroCheckbox } from "common/components/checkbox";
import { CheckboxItem, ICheckboxesData, IRadioButtonsData, StyledDivider } from ".";
import { commonCheckboxLabelStyles } from "./formatSettingsSection";

export const useGenerateRadioButtonGroups = (section: IRadioButtonsData[]) => {
  const getSectionMarkup = useMemo(() => (
    section.map((item: IRadioButtonsData) => {
      const { title, options, value, handleOnChange } = item;
      return (
        <>
          <FormGroup>
            <SectionLabel>{title}</SectionLabel>
            <RadioGroup
              value={value}
              onChange={handleOnChange}
            >
              {
                options.map((option: string) => (
                  <StyledCustomRadioLabel
                    control={
                      <Radio
                        checkedIcon={<CircleIcon /> }
                        size="small"
                      />
                    }
                    label={
                      <StyledRadio>
                        { option === "ALPHA_NUMERIC" ? makeTitleCase("BOTH") : makeTitleCase(option) }
                      </StyledRadio>
                    }
                    value={option}
                  />
                ))
              }
            </RadioGroup>
          </FormGroup>
          <StyledDivider />
        </>
      );
    })), [section]);

  return getSectionMarkup;
};

export const useGenerateCheckboxesGroup = (section: ICheckboxesData[]) => {
  const getSectionMarkup = useMemo(() => (
    section.map((element: ICheckboxesData) => {
      const { title, itemList } = element;
      return (
        <>
          <FormGroup>
            <SectionLabel>{title}</SectionLabel>
            {
              itemList.map(({ value, label, handleOnChange }: CheckboxItem) => (
                <StyledCheckBox
                    checkboxFontSize={"1.25rem"}
                    checkBoxLabel={label}
                    checked={value}
                    formControlLabelStyles={StyledCustomCheckboxLabel}
                    labelStyles={commonCheckboxLabelStyles}
                    onChange={handleOnChange}
                />
              ))
            }
          </FormGroup>
          <StyledDivider />
        </>
      );
    })), [section]);

  return getSectionMarkup;
};

const makeTitleCase = ((input: string) => input.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()));

export const SectionLabel = styled(Typography)({
  fontWeight: "bold",
  lineHeight: "1rem",
  margin: "0 0 1.5rem 0",
});

export const StyledCustomCheckboxLabel = {
  margin: "0 0 1rem 0",
};

export const StyledCustomRadioLabel = styled(FormControlLabel)({
  height: "1.5rem",
  margin: "0",
  marginBottom: "1rem",
});

export const StyledCheckBox = styled(DuroCheckbox)({
  margin: "0 0.6rem",
});

const StyledRadio = styled(Box)({
  fontSize: "0.875rem",
});
