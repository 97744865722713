//import './AddChildrenModal.css';
import { sdk } from "features/changeorders/sdk";
import { Box } from "@mui/material";
import { DuroButton } from "common/components/button";
import { ButtonVariants } from "common/constants";

interface AddChildrenModalFooterProps {
  onClick: () => void;
  isEnabled: boolean;
}
export const AddChildrenModalFooter: React.FC<AddChildrenModalFooterProps> = ({
  onClick,
  isEnabled,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ position: "absolute", bottom: "0%", right: "0%" }}>
        <DuroButton
          variant={ButtonVariants.OUTLINED}
          sx={{ mr: 1 }}
          disabled={false}
          color="info"
          onClick={() => sdk.storage.displayChildrenModal.setState(false)}
        >
          Cancel
        </DuroButton>
        <DuroButton
          variant={ButtonVariants.OUTLINED}
          sx={{ ml: 1 }}
          disabled={!isEnabled}
          onClick={onClick}
        >
          Include
        </DuroButton>
      </Box>
    </Box>
  );
};
