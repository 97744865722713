import { ButtonProps, CircularProgress, SxProps } from "@mui/material";
import { DuroButton } from "common/components";
import { BuildCircleOutlined } from "@mui/icons-material";
import { useState } from "react";
import { sdk } from "features/changeorders/sdk/editor";

export const ValidateButton = ({
  styles = {},
  ...rest
}: { styles?: SxProps } & ButtonProps) => {
  const [isReviewed] = sdk.state.flags.isReviewed.useStore();
  const [isValidationLoading, setIsValidationLoading] = useState(false);
  const handleValidate = async () => {
    setIsValidationLoading(true);
    await sdk.validation.validateEditorItems(sdk.state.rowsWithTree.getState());
    setIsValidationLoading(false);
  };
  return (
    <DuroButton
      color="secondary"
      sx={styles}
      {...rest}
      onClick={handleValidate}
      disabled={isReviewed}
    >
      {isValidationLoading ? (
        <CircularProgress
          size={17}
          thickness={7}
          sx={{ height: "auto", width: "auto", marginRight: "5px" }}
        />
      ) : (
        <BuildCircleOutlined
          sx={{ height: "1.1rem", width: "1.1rem", marginRight: "5px" }}
        />
      )}{" "}
      Validate
    </DuroButton>
  );
};
