import { Button, ButtonProps } from "@mui/material";
import { ButtonVariants } from "common/constants";

export const DuroButton = (props: ButtonProps) => {
  return (
    <Button variant={ButtonVariants.CONTAINED} {...props}>
      {props.children}
    </Button>
  );
};
