import React, { Component } from "react";
import Tooltip from "rc-tooltip";
import Utils from "../../../../../modules/utils";

export default class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    this.setState({ status: this.props.status });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status !== nextProps.status) {
      this.setState({ status: nextProps.status });
    }
  }

  onInputChange(event, item) {
    const { openValidationModal, validateSourcing, onStatusChange } =
      this.props;
    let value = event.target.value.toUpperCase();
    openValidationModal?.(event);
    this.setState({ status: value });
    const inputItem = onStatusChange(value, item);
    validateSourcing?.([
      { id: inputItem._id, status: inputItem.status, alias: inputItem.alias },
    ]);
  }

  render() {
    const {
      item,
      name,
      className,
      options,
      enableToolTip,
      overlayClassName,
      overlay,
    } = this.props;

    const selectDropDownMarkup = (
      <select
        className={`status-slect ${
          (item && Utils.getVendor(item) === "ONSHAPE") ||
          !Utils.checkRevisionManaged(item)
            ? "disabled"
            : null
        } ${className}`}
        name={name ?? "status"}
        value={this.state.status}
        onChange={(event) => this.onInputChange(event, item)}
      >
        {options}
      </select>
    );

    return (
      <span className={"status-label"}>
        {enableToolTip ? (
          <Tooltip
            placement={"right"}
            overlayClassName={overlayClassName}
            overlay={overlay}
          >
            {selectDropDownMarkup}
          </Tooltip>
        ) : (
          selectDropDownMarkup
        )}
      </span>
    );
  }
}
