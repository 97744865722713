import { useMemo } from "react";
import { CellParams } from "../cell.types";
import { StatusField } from "@duro/base/status-field";

const PreviousStatusRenderCell = ({ row }: CellParams) => {
  return useMemo(() => {
    return <StatusField label={row.previousStatus ?? row.status} />
  }, [row]);
};

export const ModalPreviousStatusCell = {
  renderCell: PreviousStatusRenderCell,
  headerName: "Last Release",
  field: "previousStatus",
  width: 120,
};
