import "./index.scss"
import React, {Component}       from "react";
import { TransactionalModal } from "common/components/modals";


class ItemDeleteModal extends Component
{
    constructor(props){
        super(props)

        this.state = {
          isModalOpen: false
        }
    }

    render()
    {
        const {
            onCloseDeleteModal,
            onDeleteBtnClick,
            headingTxt,
            bodyTxt
        } = this.props;
        const markup = <div className="new-warning-modal delete-warning">
            <TransactionalModal
                onAccept={onDeleteBtnClick}
                onCancel={onCloseDeleteModal}
                onClose={onCloseDeleteModal}
                open={true}
                title={headingTxt}
                variant="danger"
            >
                <div className="warning-text">
                    <p>{bodyTxt}</p>
                </div>
            </TransactionalModal>
        </div>

        return markup;
    }

}

export default ItemDeleteModal;