import { useMemo } from "react";
import { TransactionalModal } from "common/components/modals";
import { TextWrapper } from "../../../../ui/revert-back-modal";
import { Box } from "@mui/material";

const DeleteModal = ({
  hideDeleteModal,
  deleteSelectedItems,
  components,
  products,
}) => {
  const markup = useMemo(() => (
    <div className="delete-items-modal-wrapper">
      <TransactionalModal
        onClose={hideDeleteModal}
        onCancel={hideDeleteModal}
        onAccept={deleteSelectedItems}
        open={true}
        title="Delete the Following Items?"
        variant="danger"
      >
        <TextWrapper searchPage={components?.length || products?.length}>
          All selected items will be deleted. This can't be undone.
        </TextWrapper>
        <Box>
          {components?.map(({ name, cpn, _id }) => (
            <div key={_id + "-deleted-items-list"} className="item-row">
              <span className="muted-text">{cpn + " "}</span>
              <span>{name}</span>
            </div>
          ))}
          {products?.map(({ name, cpn, _id }) => (
            <div key={_id + "-deleted-items-list"} className="item-row">
              <span className="muted-text">{cpn + " "}</span>
              <span>{name}</span>
            </div>
          ))}
        </Box>
      </TransactionalModal>
    </div>
  ), [hideDeleteModal, deleteSelectedItems, components, products]);

  return markup;
};

export default DeleteModal;
