import "./index.scss"
import {Component}           from "react"
import Utils                        from "../../../../../../modules/utils"
import ComponentProductUtils        from "../../../../../../modules/component-product-utils"
import Schemas                      from "../../../../../../modules/schemas"
import { connect }                  from "react-redux"
import ITEM_ASSEMBLY                from "../../../../../../action-types/item-assembly"
import buildAction                  from "../../../../../../helpers/buildAction"
import Link                         from "../../../../../ui/link"
import SourcingUtils                from "../../../sourcing/sourcing-utils"
import ExtendedTable                from "../../../../common/extended-table"
import EditIcon                      from "../../../../../../assets/icons/edit"
import Permissions                  from "../../../../../../modules/schemas/permissions"
import AlertIcon                    from "../../../../../../assets/icons/alert-icon"
import WarningTriangleIcon          from "../../../../../../assets/icons/warning-triangle"
import InlineIcon                   from "../../../../../ui/icon/inline-icon.js"
import TimeStampColumn              from "../../../../common/timestamp-column";
import InlineImageViewer            from "../../../inline-image-viewer/view";
import Config                       from "../../../../../../modules/config";
import { RevisionField } from "@duro/base/revision-field";
import CpnField                     from "../../../../common/cpn-field";
import { StatusField } from "@duro/base/status-field";
import CurrencyField                from "../../../../common/currency-field";
import VariantModal                 from "../../../view-action-items/variant-modal"
import VariantIconHolder            from "../../../variants/variant-icon-holder"
import { getHeadings, HEADINGS }    from "../../../extended-table/helpers"
import MassField                    from "../../../../common/mass-field";

var jsdiff = require('diff');

export class Assembly extends Component
{
    constructor(props, context)
    {
        super(props, context)
        let styles;
        try
        {
            styles = window.__userStyles.styles.assemblyTable || {}
        }
        catch(error) {
            styles = {}
        }

        this.isItemNumberOnly = (this.props.tabsType === "prd") || (this.props.tabsType === "cmp" && Utils.shouldDisplayOnlyItemNumber(this.props.objectData.category, window.__displayRefDesAndItemNumber))

        let refDesField = {}

        if (!this.isItemNumberOnly)
        {
            refDesField = getHeadings(
                [{ ...HEADINGS.refDes, position: 6 }],
                styles
            )[0];
        }

        if (Utils.isRefDesORItemNumberColumn(styles.defaultSortColumnName))
        {
            styles.defaultSortColumnName = "cpn"
        }

        this.state =
        {
            modalCheckbox: 'hidden',
            checkedRows: [],
            modal :
            {
                open : false
            },
            children : this.props.children,

            currentSortItemAscending: ("defaultSortAssending" in styles ? styles.defaultSortAssending : true),
            openVariantModal: false,
            primaryVariant: null,
            permittedVariants: [],
            VariantModal: {
                top: 0,
                left: 0
            },
            current : ("defaultSortColumnName" in styles ? styles.defaultSortColumnName : "cpn"),
            headings: this.createHeadings(styles),
        }

        if (Object.getOwnPropertyNames(refDesField).length > 0)
        {
            this.state.headings.splice(5, 0, refDesField)
        }

        this.onClickHanlder = this.onClickHanlder.bind(this)
        this.getChildLink   = this.getChildLink.bind(this)
        this.getMassIcon    = this.getMassIcon.bind(this)
        this.closeVariantModal    = this.closeVariantModal.bind(this)
        this.setVariantFlags  = this.setVariantFlags.bind(this)
    }

    createHeadings(styles)
    {
        let headings =
        [
            {
                ...HEADINGS.cpn,
                width: 254,
            },
            HEADINGS.name,
            HEADINGS.quantity,
            HEADINGS.images,
            HEADINGS.category,
            HEADINGS.procurement,
            HEADINGS.cmpState,
            HEADINGS.revision,
            { ...HEADINGS.itemNumber, minWidth: 71, width: 120 },
            HEADINGS.eid,
            HEADINGS.status,
            HEADINGS.notes,
            HEADINGS.mass,
            HEADINGS.unitOfMeasure,
            HEADINGS.unitPrice,
            HEADINGS.totalPrice,
            HEADINGS.leadTime,
            HEADINGS.description,
            HEADINGS.mpn,
            HEADINGS.manufacturer,
            HEADINGS.lastUpdated,
        ]

        if(window.__customFields && window.__customFields.wasteFieldEnabled && !this.props.isDiffTool)
        {
            headings.push(
            {
                ...HEADINGS.waste,
                position: styles.waste ? styles.waste.position : 2
            },
            {
                ...HEADINGS.extendedQuantity,
                position: styles.extendedQuantity ? styles.extendedQuantity.position : 3
            },
            {
                ...HEADINGS.extendedCost,
                position: styles.extendedCost ? styles.extendedCost.position : 4
            });
        }
        return getHeadings(headings, styles);
    }

    onClickHanlder(child)
    {
      const {dispatch} = this.props;
      let payload = {_id: child.component._id}
      dispatch(buildAction(ITEM_ASSEMBLY.HIGHLIGHT_SELECTED_ASSEMBLY_IN_NAV, payload))
    }

    closeVariantModal(e, result)
    {
        let state = this.state;
        state.openVariantModal = false;
        state.primaryVariant = null;
        this.setState(state);
    }

    setVariantFlags(result)
    {
        let primaryVariant = this.props.mode === "revision" && result.assemblyRevision ? result.assemblyRevision : result.component;
        let disabledFromVendor = Utils.isVendorCmp(Utils.getVendor(result)) && Utils.isVendorCmp(Utils.getVendor(primaryVariant)) ? true : false;

        !!result && !!result.variants && result.variants.forEach((item) =>
        {
            let existInAssembly = false;
            if(primaryVariant.chilIdsOfthisLevel && primaryVariant.chilIdsOfthisLevel.includes(item.variant))
            {
                existInAssembly = true;
            }

            if(item.variant !== primaryVariant._id && (disabledFromVendor || existInAssembly))
            {
                item.isPermitted = false;
            }
        });
    }

    getChildLink(child)
    {
      let pathname = this.props.mode === "revision" && child.assemblyRevision ? "/component/revision/" + child.assemblyRevision._id : "/component/view/" + child.component._id
      return pathname
    }

    getMassIcon(component)
    {
        let markup = null
        let isAssembly = component && component.category && Schemas.component.category.getType(component.category).toLowerCase() === "assembly"
        if (!isAssembly) return

        if (component.massStatus && component.massStatus.toUpperCase() === "ERROR")
        {
            markup =
                    <InlineIcon
                        tooltip="Missing mass values for one or more children"
                        tooltipPlace="top"
                        className="warningEl"
                        key={"i"+'-mass-error'}
                        >
                        <AlertIcon/>
                    </InlineIcon>
        }
        else if (component.massStatus && component.massStatus.toUpperCase() === "WARNING")
        {
            markup =
                    <InlineIcon
                        tooltip="Manually entered value"
                        tooltipPlace="top"
                        className="warningEl"
                        key={"i"+'-mass-warning'}
                        >
                        <WarningTriangleIcon/>
                    </InlineIcon>
        }

        return markup;
    }

    getRows(children, isRevisionPage)
    {
        let { company } = this.props;
        let rows = children.map((child, i) =>
        {
            let assemblyCmp = this.props.mode === "revision" && child.assemblyRevision ? child.assemblyRevision : child.component
            let cpn      = Utils.getCpn(assemblyCmp);
            let leadTime = assemblyCmp.primarySource.leadTime.value !== null && assemblyCmp.primarySource.leadTime.units && assemblyCmp.primarySource.leadTime.value + " " + assemblyCmp.primarySource.leadTime.units

            let unitPrice = assemblyCmp.primarySource.unitPrice !== null ? assemblyCmp.primarySource.unitPrice : undefined

            let totalPrice = assemblyCmp.primarySource.unitPrice !== null && child.quantity * assemblyCmp.primarySource.unitPrice

            let description = assemblyCmp.description ? assemblyCmp.description : '';
            let procurement = assemblyCmp.procurement ? assemblyCmp.procurement : '';
            let cmpState = assemblyCmp.cmpState ? assemblyCmp.cmpState : '';
            let dateTime    = Utils.dateTimeWithLongFormat(assemblyCmp.lastModified);
            let { mpn, manufacturer } = SourcingUtils.getPrimarySourceMpnAndMfr(assemblyCmp);
            let massPrecision = Utils.getCompanyMassPrecision(company);
            let totalPermitted = 0;

            let { extendedQuantity, extendedCost } = Utils.calculateExtendedQuantityAndCost(child.waste, child.quantity, unitPrice);

            this.setVariantFlags(child);
            !!child && !!child.variants && child.variants.forEach((item) => {
                if(item.isPermitted)
                {
                    totalPermitted++;
                }
            })
            let cells = {

                "cpn" : {
                    value       : cpn,
                    displayValue: <span className='link'><CpnField item={assemblyCmp} cpn={cpn}/></span>,
                    tooltip     : cpn
                },
                "name" : {
                    value       : assemblyCmp.name,
                    displayValue: <span>
                            {assemblyCmp.name}
                            {
                                assemblyCmp.variantGroup && window.__userRole !== 'VENDOR' ?
                                <VariantIconHolder
                                    openVariantModal={(primaryVariant, variantModal) => {
                                        let state = this.state
                                        state.openVariantModal = true;
                                        state.primaryVariant = primaryVariant;
                                        state.primaryVariant = this.props.mode === "revision" && primaryVariant.assemblyRevision ? primaryVariant.assemblyRevision : primaryVariant.component;
                                        state.primaryVariant.parent = {_id:primaryVariant.parent, alias: primaryVariant.parentAlias};
                                        if(Utils.isVendorCmp(Utils.getVendor(primaryVariant)) && Utils.isVendorCmp(Utils.getVendor(state.primaryVariant)))
                                        {
                                            state.primaryVariant.fixedPrimaryByVendor = true;
                                        }
                                        state.permittedVariants = [];
                                        state.permittedVariants = primaryVariant.variants;
                                        state.VariantModal.top = variantModal.top
                                        state.VariantModal.left = variantModal.left
                                        this.setState(state)
                                    }}
                                    result={child}
                                    leftFomular={(left) => left + 30}
                                    toolTip={`${totalPermitted} of ${child.variants.length} Variants`}
                                    tableSelector="assembly-list extended-table"
                                /> : null
                            }
                        </span>,
                    tooltip     : assemblyCmp.name
                },
                "category" : {
                    value       : Schemas.component.category.getDisplayName(assemblyCmp.category),
                    displayValue: Schemas.component.category.getDisplayName(assemblyCmp.category),
                    tooltip     : Schemas.component.category.getDisplayName(assemblyCmp.category)
                },
                "procurement" : {
                    value       : procurement,
                    displayValue: procurement,
                    tooltip     : procurement
                },
                "cmpState" : {
                    value       : cmpState,
                    displayValue: cmpState,
                    tooltip     : cmpState
                },

                "description" : {
                    value       : description,
                    displayValue: description,
                    tooltip     : description
                },

                "mpn":
                {
                    value       : mpn,
                    tooltip     : mpn,
                    displayValue: mpn,

                },

                "manufacturer":
                {
                    value       : manufacturer,
                    tooltip     : manufacturer,
                    displayValue: manufacturer,

                },

                "lastUpdated":
                {
                    value       : assemblyCmp.lastModified,
                    tooltip     : dateTime ? `${dateTime.dateValue} ${dateTime.timeValue}` : '',
                    displayValue: <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={assemblyCmp.lastModified} />
                },

                "revision" : {
                    value       : assemblyCmp.revision,
                    displayValue: <RevisionField item={assemblyCmp} revision={assemblyCmp.revision} showIcon={this.props.mode === "revision" ? assemblyCmp.revisionType === "Modified" : true}/>,
                    tooltip     : "tooltip"
                },
                "eid" : {
                    value       : assemblyCmp.eid,
                    displayValue: assemblyCmp.eid,
                    tooltip     : assemblyCmp.eid
                },
                "unitOfMeasure" : {
                    value       : assemblyCmp.unitOfMeasure,
                    displayValue: assemblyCmp.unitOfMeasure,
                    tooltip     : assemblyCmp.unitOfMeasure
                },
                "quantity" : {
                    value       : child.quantity,
                    displayValue: <span className="quantity">{child.quantity}</span>,
                    tooltip     : child.quantity
                },
                "waste" : {
                    value : child.waste ? child.waste : 0,
                    cellClass : "wasteField",
                    displayValue: (child.waste || child.waste >= 0) && Utils.roundFieldValue(child.waste, 2).roundedValue,
                    tooltip: child.waste ? child.waste : 0
                },
                "extendedQuantity" : {
                    value : extendedQuantity,
                    cellClass : "extended-quantity",
                    displayValue: Utils.roundFieldValue(extendedQuantity, 2).roundedValue,
                    tooltip: extendedQuantity
                },
                "extendedCost" : {
                    value : extendedCost,
                    cellClass : "extended-cost",
                    displayValue: Utils.concatCurrencySymbol(extendedCost, this.props.defaultCurrency),
                    tooltip: Utils.concatCurrencySymbol(extendedCost, this.props.defaultCurrency)
                },
                "status" : {
                    value       : assemblyCmp["status"],
                    displayValue: <StatusField item={assemblyCmp} status={assemblyCmp["status"]}/>,
                    tooltip     : assemblyCmp["status"]
                },
                "notes" : {
                    value       : child.notes,
                    displayValue: <span className="notes">{child.notes}</span>,
                    tooltip     : child.notes
                },
                "unitPrice" : {
                    value       : unitPrice,
                    displayValue: [
                                    SourcingUtils.haveInValidCosting(assemblyCmp) === true &&
                                    <InlineIcon
                                        tooltip="Incomplete Sources"
                                        tooltipPlace="top"
                                        className="warningEl"
                                        key={i+'-unit-price-icon'}
                                        >
                                        <AlertIcon/>
                                    </InlineIcon>,
                                    <div className="price-value" key={i+'-unit-price'}>
                                        {unitPrice && <CurrencyField symbol={this.props.defaultCurrency} value={unitPrice}/>}
                                    </div>
                                ],
                    tooltip     : unitPrice && (this.props.defaultCurrency + Number(unitPrice).toFixed(5) )
                },
                "totalPrice" : {
                    value       : totalPrice,
                    displayValue: <CurrencyField symbol={this.props.defaultCurrency} value={totalPrice}/>,
                    tooltip     : (this.props.defaultCurrency + Number(totalPrice).toFixed(5) )
                },
                "leadTime" : {
                    value       : leadTime ? Utils.stringValueToDays(leadTime.split(' ')[0], leadTime.split(' ')[1]) : -1,
                    displayValue: leadTime,
                    tooltip     : leadTime
                },
                "itemNumber" : {
                    value       : child.itemNumber,
                    displayValue: <span className="itemNumber">{child.itemNumber}</span>,
                    tooltip     : child.itemNumber
                },
                "images" : {
                    value       : 0,
                    displayValue: <InlineImageViewer imagesWithSrc={this.props.imagesWithSrc} key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={assemblyCmp.images}/>,
                    notLink     : true,
                    cellClass   : "inline-image-viewer"
                },
                "mass" : {
                    value       : assemblyCmp.mass,
                    displayValue: [
                                    this.getMassIcon(assemblyCmp),
                                    <div className="price-value" key={i+'-mass'}>
                                        <MassField
                                            mass={assemblyCmp.mass}
                                            massPrecision={massPrecision}
                                        />
                                    </div>
                                ],
                    tooltip     : Utils.getRoundedMass(assemblyCmp.mass, massPrecision)
                },
                onClick         : !assemblyCmp.archived || isRevisionPage ? (e) => this.onClickHanlder(child) : null ,
                rowLink         : !assemblyCmp.archived || isRevisionPage ? this.getChildLink(child) : '#'
            }

            if (!this.isItemNumberOnly)
            {
                cells["refDes"] = {
                    value       : child.refDes,
                    displayValue: <span className="refDes">{child.refDes}</span>,
                    tooltip     : child.refDes
                };
            }

            return cells
        })

        return rows;
    }

    getMatchedAssembly(children, childId)
    {
        let child = null;
        for(let i = 0; i < children.length; i++) {

            if (children[i].assemblyRevision && childId === children[i].assemblyRevision.cpn)
            {
                child = children[i];
                break;
            }
        }
        return {child: child, found: !!child}
    }

    compareAssemblyChildrenAttributes(targetRevChild, sourceRevChild)
    {
        let attributes = ["itemNumber", "quantity", "notes"]
        for(let i = 0; i < attributes.length; i++) {

            let targetString = targetRevChild[attributes[i]] && targetRevChild[attributes[i]].toString() || ""
            let sourceString = sourceRevChild[attributes[i]] && sourceRevChild[attributes[i]].toString() || ""
            if (attributes[i] === "quantity" && targetRevChild[attributes[i]] === 0)
            {
                targetString = `0`
            }
            else if (attributes[i] === "quantity" && sourceRevChild[attributes[i]] === 0)
            {
                sourceString = `0`
            }
            let diffAttribute = jsdiff.diffLines(targetString, sourceString);
            diffAttribute.forEach((part) => {
                if (part.removed)
                {
                    sourceRevChild[`removed${attributes[i].charAt(0).toUpperCase()}${attributes[i].slice(1)}`] = part.value
                }
                else if (part.added)
                {
                    sourceRevChild[`added${attributes[i].charAt(0).toUpperCase()}${attributes[i].slice(1)}`] = part.value
                }
            })
        }
    }

    compareAssemblyChildrenRefDes(targetRevChild, sourceRevChild)
    {
        let attributes = ["refDes"]
        let refDesBlock = null
        for(let i = 0; i < attributes.length; i++) {

            let targetRefDesString = targetRevChild[attributes[i]] && targetRevChild[attributes[i]].toString() || ""
            let sourceRefDesString = sourceRevChild[attributes[i]] && sourceRevChild[attributes[i]].toString() || ""

            sourceRefDesString = sourceRefDesString.replace(/[,:;]/g, ' ').replace(/\s\s+/g, ' ');
            targetRefDesString = targetRefDesString.replace(/[,:;]/g, ' ').replace(/\s\s+/g, ' ');

            let diffRefDes = jsdiff.diffWords(targetRefDesString, sourceRefDesString);
            let counter = 0
            refDesBlock = diffRefDes.map((part, i) => {
                counter++

                if (part.added || part.removed)
                {
                    sourceRevChild.rowClassName = "update"
                }

                let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                let isLastPart = counter === diffRefDes.length

                let value = part.value
                value = value.trim().replace(/ /g, ',')

                let dispalyClass = value ? "" : "hidden"

                value = diffClass === "add" || diffClass === "remove" ? value : isLastPart ? value : `${value},`
                let block  =
                    (diffClass === "add" || diffClass === "remove") && !isLastPart ?
                    <span>
                    <span key={`ref-des-${i}`} className={`ref-des-check ${diffClass} ${dispalyClass}`}>
                        {value}
                    </span>
                    <span className="comma-span">,</span></span> :
                    <span key={`ref-des-${i}`} className={`ref-des-check ${diffClass} ${dispalyClass}`}>
                        {value}
                    </span>
                return block
            })
        }
        sourceRevChild.refDesBlock = refDesBlock
    }

    getRowsForDiffTool(children, isRevisionPage)
    {

        let sourceAndTargetChildren = []
        let sourceAndTargetRevChildrenIds = []

        let sourceRevChildren = this.props.children
        let { targetRevChildren, company } = this.props;

        for(let i = 0; i < sourceRevChildren.length; i++) {
            if (sourceRevChildren[i].assemblyRevision && !sourceAndTargetRevChildrenIds.includes(sourceRevChildren[i].assemblyRevision.cpn))
            {
                sourceAndTargetChildren.push(sourceRevChildren[i])
                sourceAndTargetRevChildrenIds.push(sourceRevChildren[i].assemblyRevision.cpn)
            }
        }

        for(let i = 0; i < targetRevChildren.length; i++) {
            if (targetRevChildren[i].assemblyRevision && !sourceAndTargetRevChildrenIds.includes(targetRevChildren[i].assemblyRevision.cpn))
            {
                sourceAndTargetChildren.push(targetRevChildren[i])
                sourceAndTargetRevChildrenIds.push(targetRevChildren[i].assemblyRevision.cpn)
            }
        }

        for(let i = 0; i < sourceAndTargetChildren.length; i++) {
            let isPresentInTargetRev = false
            let isPresentInSourceRev = false

            isPresentInTargetRev = this.getMatchedAssembly(targetRevChildren, sourceAndTargetChildren[i].assemblyRevision.cpn).found
            isPresentInSourceRev = this.getMatchedAssembly(sourceRevChildren, sourceAndTargetChildren[i].assemblyRevision.cpn).found

            if (!isPresentInSourceRev && isPresentInTargetRev)
            {
                sourceAndTargetChildren[i].rowClassName = "remove"
            }
            else if (isPresentInSourceRev && !isPresentInTargetRev)
            {
                sourceAndTargetChildren[i].rowClassName = "add"
            }

            else if (isPresentInSourceRev && isPresentInTargetRev)
            {
                let sourceRevChild = this.getMatchedAssembly(sourceRevChildren, sourceAndTargetChildren[i].assemblyRevision.cpn).child
                let targetRevChild = this.getMatchedAssembly(targetRevChildren, sourceAndTargetChildren[i].assemblyRevision.cpn).child

                let sourceRevChildrenString = `${sourceRevChild.itemNumber || ""} ${sourceRevChild.notes || ""} ${sourceRevChild.quantity}`
                let targetRevChildrenString = `${targetRevChild.itemNumber || ""} ${targetRevChild.notes || ""} ${targetRevChild.quantity}`

                let diff = jsdiff.diffWords(targetRevChildrenString, sourceRevChildrenString);
                for(let part of diff)
                {
                    if (part.removed || part.added)
                    {
                        sourceAndTargetChildren[i].rowClassName = "update"
                    }
                }

                if (sourceAndTargetChildren[i].rowClassName === "update")
                {
                    //Note: Compare exact attribute changes here i.e qty, itemNumber, notes
                    this.compareAssemblyChildrenAttributes(targetRevChild, sourceRevChild)
                }
                this.compareAssemblyChildrenRefDes(targetRevChild, sourceRevChild)
            }
        }

        let rows = sourceAndTargetChildren.map((child, i) =>
        {
            let assemblyCmp = this.props.mode === "revision" && child.assemblyRevision ? child.assemblyRevision : child.component
            let cpn      = Utils.getCpn(assemblyCmp);
            let leadTime = assemblyCmp.primarySource.leadTime.value !== null && assemblyCmp.primarySource.leadTime.units && assemblyCmp.primarySource.leadTime.value + " " + assemblyCmp.primarySource.leadTime.units

            let unitPrice = assemblyCmp.primarySource.unitPrice !== null ? assemblyCmp.primarySource.unitPrice : undefined

            let totalPrice = assemblyCmp.primarySource.unitPrice !== null && child.quantity * assemblyCmp.primarySource.unitPrice

            let description = assemblyCmp.description ? assemblyCmp.description : '';
            let procurement = assemblyCmp.procurement ? assemblyCmp.procurement : '';
            let cmpState = assemblyCmp.cmpState ? assemblyCmp.cmpState : '';
            let dateTime    = Utils.dateTimeWithLongFormat(assemblyCmp.lastModified);
            let { mpn, manufacturer } = SourcingUtils.getPrimarySourceMpnAndMfr(assemblyCmp);
            let massPrecision = Utils.getCompanyMassPrecision(company);
            let cells = {

                "cpn" : {
                    value       : cpn,
                    displayValue: <span className='link'><CpnField item={assemblyCmp} cpn={cpn}/></span>,
                    tooltip     : cpn
                },
                "name" : {
                    value       : assemblyCmp.name,
                    displayValue: assemblyCmp.name,
                    tooltip     : assemblyCmp.name
                },
                "category" : {
                    value       : Schemas.component.category.getDisplayName(assemblyCmp.category),
                    displayValue: Schemas.component.category.getDisplayName(assemblyCmp.category),
                    tooltip     : Schemas.component.category.getDisplayName(assemblyCmp.category)
                },
                "procurement" : {
                    value       : procurement,
                    displayValue: procurement,
                    tooltip     : procurement
                },
                "cmpState" : {
                    value       : cmpState,
                    displayValue: cmpState,
                    tooltip     : cmpState
                },

                "description" : {
                    value       : description,
                    displayValue: description,
                    tooltip     : description
                },

                "mpn":
                {
                    value       : mpn,
                    tooltip     : mpn,
                    displayValue: mpn,

                },

                "manufacturer":
                {
                    value       : manufacturer,
                    tooltip     : manufacturer,
                    displayValue: manufacturer,

                },

                "lastUpdated":
                {
                    value       : assemblyCmp.lastModified,
                    tooltip     : dateTime ? `${dateTime.dateValue} ${dateTime.timeValue}` : '',
                    displayValue: <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={assemblyCmp.lastModified} />
                },

                "revision" : {
                    value       : assemblyCmp.revision,
                    displayValue: <RevisionField item={assemblyCmp} revision={assemblyCmp.revision} showIcon={this.props.mode !== "revision"}/>,
                    tooltip     : "tooltip"
                },
                "eid" : {
                    value       : assemblyCmp.eid,
                    displayValue: assemblyCmp.eid,
                    tooltip     : assemblyCmp.eid
                },
                "unitOfMeasure" : {
                    value       : assemblyCmp.unitOfMeasure,
                    displayValue: assemblyCmp.unitOfMeasure,
                    tooltip     : assemblyCmp.unitOfMeasure
                },
                "quantity" : {
                    value       : child.quantity,
                    displayValue: child.rowClassName === "update" && (child.addedQuantity === 0 || child.removedQuantity === 0 || child.removedQuantity || child.addedQuantity) ?
                    <div className="diff-update-section">
                        {(child.removedQuantity || child.removedQuantity === 0) && <div className="update-tag remove">{child.removedQuantity}</div>}
                        {(child.addedQuantity || child.addedQuantity === 0) && <div className="update-tag add">{child.addedQuantity}</div>}
                    </div> : child.quantity,
                    tooltip     : child.quantity,
                    cellCustomClass : child.rowClassName === "update" ? "diff-tool" : "",
                },
                "status" : {
                    value       : assemblyCmp["status"],
                    displayValue:   <StatusField item={assemblyCmp} status={assemblyCmp["status"]}/>,
                    tooltip     : assemblyCmp["status"]
                },
                "notes" : {
                    value       : child.notes,
                    displayValue: child.rowClassName === "update" && (child.removedNotes || child.addedNotes) ?
                    <div className="diff-update-section">
                        {child.removedNotes && <div className="update-tag remove">{child.removedNotes}</div>}
                        {child.addedNotes && <div className="update-tag add">{child.addedNotes}</div>}
                    </div> : child.notes,
                    tooltip     : child.notes,
                    cellCustomClass : child.rowClassName === "update" ? "diff-tool" : "",
                },
                "unitPrice" : {
                    value       : unitPrice,
                    displayValue: [
                                    <div className="price-value" key={i+'-unit-price'}>
                                        {unitPrice && <CurrencyField symbol={this.props.defaultCurrency} value={unitPrice}/>}
                                    </div>
                                ],
                    tooltip     : unitPrice && (this.props.defaultCurrency + Number(unitPrice).toFixed(5) )
                },
                "totalPrice" : {
                    value       : totalPrice,
                    displayValue: <CurrencyField symbol={this.props.defaultCurrency} value={totalPrice}/>,
                    tooltip     : (this.props.defaultCurrency + Number(totalPrice).toFixed(5) )
                },
                "leadTime" : {
                    value       : leadTime ? Utils.stringValueToDays(leadTime.split(' ')[0], leadTime.split(' ')[1]) : -1,
                    displayValue: leadTime,
                    tooltip     : leadTime
                },
                "itemNumber" : {
                    value       : child.itemNumber,
                    displayValue: child.rowClassName === "update" && (child.removedItemNumber || child.addedItemNumber) ?
                    <div className="diff-update-section">
                        {child.removedItemNumber && <div className="update-tag remove">{child.removedItemNumber}</div>}
                        {child.addedItemNumber && <div className="update-tag add">{child.addedItemNumber}</div>}
                    </div> : child.itemNumber,
                    tooltip     : child.itemNumber,
                    cellCustomClass : child.rowClassName === "update" ? "diff-tool" : "",
                },
                "images" : {
                    value       : 0,
                    displayValue: <InlineImageViewer imagesWithSrc={this.props.imagesWithSrc} key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={assemblyCmp.images}/>,
                    notLink     : true,
                    cellClass   : "inline-image-viewer"
                },
                "mass" : {
                    value       : assemblyCmp.mass,
                    displayValue: [
                                    <div className="price-value" key={i+'-mass'}>
                                        <MassField
                                            mass={assemblyCmp.mass}
                                            massPrecision={massPrecision}
                                        />
                                    </div>
                                ],
                    tooltip     : Utils.getRoundedMass(assemblyCmp.mass, massPrecision)
                },
                onClick         : !assemblyCmp.archived || isRevisionPage ? (e) => this.onClickHanlder(child) : null ,
                rowLink         : !assemblyCmp.archived || isRevisionPage ? this.getChildLink(child) : '#',
                rowClassName    : child.rowClassName
            }

            if (!this.isItemNumberOnly)
            {
                cells["refDes"] = {
                    value       : child.refDes,
                    displayValue: child.rowClassName === "update" && child.refDesBlock ?
                    child.refDesBlock : child.refDes,
                    tooltip     : child.refDes,
                    cellCustomClass : child.rowClassName === "update" ? "diff-tool" : "",
                };
            }

            return cells
        })

        return rows;
    }

    render()
    {
        let { children, headings } = this.state

        for (let heading of headings)
        {
            if (heading.key === "revision")
            {
                heading.displayName = Utils.getRevisionHeadingDisplayName(this.props.isAnyChildModified);
                break;
            }
        }

        let isRevisionPage = false

        if (this.props.location) isRevisionPage = ComponentProductUtils.isRevisionPage(this.props.location)
        let isInOpenCo = ComponentProductUtils.isInOpenCo(this.props.objectData)

        let rows = []
        if (this.props.isDiffTool)
        {
            rows = this.getRowsForDiffTool(children, isRevisionPage)
        }
        else
        {
            rows = this.getRows(children, isRevisionPage)
        }

        Utils.updateHeadings(headings)

        let markup =
            <div className="assembly-tab-view-common">
                {
                    this.state.openVariantModal && window.__userRole!=='VENDOR' &&
                    <div className="variant-table-holder">
                        <VariantModal
                            openVariantModal={this.state.openVariantModal}
                            closeVariantModal={this.closeVariantModal}
                            objectData={this.state.primaryVariant}
                            updatePrimaryVariant={this.updatePrimaryVariant}
                            permittedVariants={this.state.permittedVariants}
                            variantModalPosition={this.state.VariantModal}
                            history={this.props.history}
                            getDataFromApi={true}
                            compareVariants={null}
                            fromAssemblyTab={true}
                        />
                    </div>
                }
                <ExtendedTable
                    wrapperClassName="assembly-list"
                    wrapperSelectorClass="assembly-list"
                    headings={this.state.headings}
                    rows={rows}
                    stylesName="assemblyTable"
                    borderedTable={true}
                    allowRowSelect={false}
                    includeToolBar={true}
                    tableActionButtons={this.props.getIconsActionsList()}
                    currentSortItem={this.state.current}
                    resultText={`${rows.length} Components`}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    scrollPagination={true}
                    paginationSize={30}
                    footerRow={ !this.props.isDiffTool && !isInOpenCo && Permissions.can("edit", "component", this.props.user.data) && !isRevisionPage ? {
                                bordered: true,
                                dataCellEl: <p>Edit to add</p>,
                                indexCellEl: <div className="add-more-actions">
                                                <Link to={ComponentProductUtils.makeEditLink(this.props.objectData)}>
                                                    <button
                                                        className='add-trigger'
                                                        data-tip='Edit'
                                                        >
                                                        <InlineIcon><EditIcon/></InlineIcon>
                                                    </button>
                                                </Link>
                                             </div>
                            }
                            :
                            rows.length === 0 &&
                            {
                                dataCellEl: <p>No Assembly added</p>
                            }
                        }

                />
            </div>
        return markup
    }

}

export default connect((store) => store)(Assembly)
