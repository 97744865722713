import React, { useCallback } from "react";
import { Icon, styled, Tooltip } from "@mui/material";
import { GridColumnHeaderTitle, GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
  CurrencyField,
  DateTimeFieldFormat,
  ImageField,
  RemoveClickEventHandler,
  RemoveField,
  TimestampField,
} from "common/components/fields";
import { EditCell } from "common/components/grid";
import { DefaultResolution } from "common/constants";
import {
  ChangeOrderResolution,
  ModelType,
  PageItemType,
  PageMode,
  RevisionType,
} from "@duro/utils";
import { AssemblyChild } from "design/models";
import { calculateChildTotalPrice } from "design/utils/component";
import { HeaderRendererProps } from "design/utils/componentGrid";
import { Component } from "design/models/component";
import CoAlert from "v1/assets/icons/co-alert";
import Schemas from "v1/modules/schemas";
import {
  ComponentNameField,
  CpnField,
  MassField,
  PrimaryVariantType,
  UnitPriceField,
} from "../fields";
import { ItemType } from "design/components/grid/searchTable";

import { RevisionField } from "@duro/base/revision-field/";
import { StatusField } from "@duro/base/status-field";

export const enum EditFieldState {
  ERROR = "ERROR",
  NORMAL = "NORMAL",
}

export interface ValidationStatus {
  state: EditFieldState;
  message?: string | null;
}

export interface GridDataTypeValidations {
  valid: boolean;
  itemNumber?: ValidationStatus;
  notes?: ValidationStatus;
  quantity?: ValidationStatus;
  refDes?: ValidationStatus;
  waste?: ValidationStatus;
}

export type GridDataType = AssemblyChild & {
  _childrenLoaded: boolean;
  _descendantCount: number;
  _path: string[];
  _validations?: GridDataTypeValidations;
  item?: ItemType;
};

export type CellRendererProps = GridRenderCellParams<any, GridDataType>;
export type OpenVariantModalType = (pv: PrimaryVariantType) => void;

export function cpnRenderCell({ row }: CellRendererProps) {
  if (!row.item) return "";
  const {
    cpn: { displayValue },
    id,
    alias,
  } = row.item;
  return <CpnField cpn={displayValue} id={id} alias={alias!} />;
}

export function imageRenderCell({ row }: CellRendererProps) {
  if (!row.item) return "";
  return (
    <ImageField
      defaultResolution={DefaultResolution.INLINE_IMAGE}
      imageIds={row.item.imageIds}
    />
  );
}

export function lastUpdatedRenderCell({ row }: CellRendererProps) {
  if (!row.item) return "";
  return (
    <TimestampField
      dateTime={row.item.lastModified}
      format={DateTimeFieldFormat.DATE_TIME_LONG}
    />
  );
}

export function massRenderCell({ row }: CellRendererProps) {
  if (!row.item) return "";
  let isAssembly = true;
  if (row.item.alias === ModelType.CMP) {
    const item = row.item as Component;
    isAssembly =
      !!item.category &&
      Schemas.component.category.getType(item.category).toLowerCase() ===
        "assembly";
  }
  return (
    <MassField
      isAssembly={isAssembly}
      mass={row.item.mass}
      massStatus={row.item.massStatus}
    />
  );
}

export function statusRenderCell({ row }: CellRendererProps) {
  if (!row.item) return "";
  return <StatusField item={row.item} />;
}

export function totalPriceRenderCell({ row }: CellRendererProps) {
  const totalPrice = calculateChildTotalPrice(row);
  return <CurrencyField value={totalPrice} />;
}

export function unitPriceRenderCell({ row }: CellRendererProps) {
  if (!row.item) return "";
  return (
    <UnitPriceField
      incompleteCost={row.item.incompleteCost}
      unitPrice={row.item.primarySource?.unitPrice}
    />
  );
}

export function useComponentNameFieldRenderCell(
  openVariantModal?: OpenVariantModalType,
  hideVariants?: boolean
) {
  return useCallback(
    ({ row }: CellRendererProps) => (
      <ComponentNameField
        hideVariants={hideVariants}
        openVariantModal={openVariantModal}
        primaryVariant={row}
      />
    ),
    [hideVariants, openVariantModal]
  );
}

export function useCpnNameRenderCell(
  openVariantModal: OpenVariantModalType,
  hideVariants?: boolean
) {
  return useCallback(
    ({ row }: CellRendererProps) => {
      if (!row.item) return "";
      const {
        cpn: { displayValue },
        id,
        alias,
      } = row.item;
      return (
        <>
          <CpnField cpn={displayValue} id={id} alias={alias!} />
          <PaddedNameField
            hideVariants={hideVariants}
            openVariantModal={openVariantModal}
            primaryVariant={row}
          />
        </>
      );
    },
    [hideVariants, openVariantModal]
  );
}

export function useRemoveComponentRenderCell(
  onRemove: RemoveClickEventHandler
) {
  return useCallback(
    ({ row }: CellRendererProps) => (
      <RemoveField id={row.item?.id ?? ""} onRemove={onRemove} />
    ),
    [onRemove]
  );
}

export function useRevisionRenderCell(
  mode: PageMode,
  pageItemType: PageItemType
) {
  return useCallback(
    ({ row }: CellRendererProps) => {
      if (!row.item) return "";

      const { co, modified, revisionValue, revisionType } = row.item;
      const allowIconForCO =
        modified &&
        (co?.resolution === ChangeOrderResolution.NONE ||
          co?.resolution === ChangeOrderResolution.REJECTED);
      const isRevisionModified =
        mode !== PageMode.REVISION || revisionType === RevisionType.MODIFIED;
      const showIcon = (isRevisionModified && modified) || allowIconForCO;

      return <RevisionField item={row.item} />;
    },
    [mode, pageItemType]
  );
}

export function useRevisionRenderHeader(isAnyChildModified: boolean) {
  return useCallback(({ colDef }: HeaderRendererProps) => (
    <>
      <GridColumnHeaderTitle
        label={colDef.headerName ?? ""}
        description={colDef.description}
        columnWidth={colDef.width ?? 0}
      />
      {isAnyChildModified && (
        <Tooltip placement="top" title="1 or more child components have been modified">
          <RevisionLargeIcon><CoAlert /></RevisionLargeIcon>
        </Tooltip>
      )}
    </>
  ), [isAnyChildModified]);
}

export function wasteRenderCell(params: CellRendererProps) {
  return params.value ? `${Number(params.value).toFixed(2)}%` : null;
}

export function wasteRenderEditCell(params: CellRendererProps) {
  return (
    <EditCell {...params}>
      {wasteRenderCell(params)}
    </EditCell>
  );
}

const PaddedNameField = styled(ComponentNameField)(() => ({
  paddingLeft: "0.25rem",
}));

const RevisionLargeIcon = styled(Icon)(() => ({
  fontSize: "13px",
  height: "100%",
  paddingTop: "4px",
  "& > svg": {
    fontSize: "13px",
    height: "13px",
    width: "13px",
  },
}));
