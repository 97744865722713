import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d={`M12.205 21.5a2.057 2.057 0 0 0 2.051-2.051h-4.102c0 1.128.923 2.051 2.051 
        2.051zm6.154-6.154v-5.128c0-3.149-1.672-5.785-4.615-6.482v-.698c0-.85-.688-1.538-1.539-1.538s-1.538.687-1.538 
        1.538v.698c-2.934.697-4.616 3.323-4.616 6.482v5.128L4 17.397v1.026h16.41v-1.026l-2.051-2.05zm-2.051 
        1.026H8.103v-6.154c0-2.544 1.548-4.615 4.102-4.615 2.554 0 4.103 2.071 4.103 4.615v6.154z`} fill-rule="evenodd"/>
    </svg>
  );
}

export default Icon;
