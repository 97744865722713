import "../index.scss"
import React                    from "react"
import DatePicker               from "react-datepicker";
import InlineIcon               from "../../../../ui/icon/inline-icon.js"
import ItemScheduleIcon         from "../../../../../assets/icons/item-schedule-icon"
import ERPTileIcon              from "../../../../../assets/icons/erp-tile-icon"
import TileItem                 from "../../tile-item"
import Utils                    from "../../../../../modules/utils"
import TimeStampColumn          from '../../timestamp-column';

const ERPDates = ({ effectivity }) => {
    const dates = { 'Start Date': effectivity.startDate, 'End Date': effectivity.endDate };
  return Object.keys(dates).map(date => (
        <div className="inner-info erp-info values-to-right diff-effectivity-section">
            <span className="inner-attribute">{date}</span>
            <div className="erp-date erp-custom">
                {dates[date]?.value ? <TimeStampColumn format="date-with-long-format" value={dates[date]} /> : <span className="no-day">Enter date</span>}
                <InlineIcon tooltip="Date" tooltipPlace="top">
                    <ItemScheduleIcon />
                </InlineIcon>
            </div>
        </div>
    ))
}

const ItemType = ({itemType, isVendorCmp, onInputChange}) => {

  const erpSelect = !isVendorCmp ?
      <select
        name="itemType"
        value={itemType.value}
        onChange={(event)=> onInputChange(event)}
      >
        {Utils.toOptions(Utils.getItemTypeList())}
      </select> :
      <input
        type="text"
        name="vendor-erp"
        value={itemType.value}
      />
  return <React.Fragment>
    <div className="inner-info values-to-right diff-itemType-section">
      <span className={"erp-title item-type-title"}>Item Type</span>
      {erpSelect}
    </div>
  </React.Fragment>
}

const ERPEdit = ({alias, isVendorCmp, inputs ,onInputChange}) => {
    return (
      <TileItem title="ERP" icon={<ERPTileIcon />} noScroll={true}>
            <div className="erp erp-section">
              <span className={"erp-title"}>Effectivity</span>
              <ERPDates effectivity={inputs.effectivity} />
            </div>
            { alias === "cmp" &&
              <div className="erp erp-section">
                <ItemType itemType={inputs.itemType} isVendorCmp={isVendorCmp} onInputChange={onInputChange}/>
              </div>
            }
    </TileItem>
    )
}

export default ERPEdit
