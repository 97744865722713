import { ValidationTooltipType } from "./types";
import { Pane, TooltipText } from "./tooltip.styles";
import { StatusField } from "@duro/base/status-field";
import { sdk } from "features/changeorders/sdk/editor";

export const ChildrenStatusError: ValidationTooltipType = ({ row }) => {
  const treeNodes = sdk.state.treeDict.getState();
  const [parentId] = row.path;

  const parent = treeNodes[parentId];
  const changeset = sdk.state.getChangeset(parent);

  return (
    <Pane>
      <TooltipText>
        Nested child Components must be at an equal or greater Status value than
        their parent assembly.
      </TooltipText>

      <TooltipText>This Component's parent has Status:</TooltipText>
      <TooltipText>
        <StatusField label={changeset.status.getState()} />
      </TooltipText>
    </Pane>
  );
};
