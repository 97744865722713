import React, {Component} from "react";
import InlineIcon         from "../../../../../ui/icon/inline-icon.js";
import ArrowIcon          from "../../../../../../assets/icons/pagination";
import LazyInput          from "../../../../../ui/lazy-input/input.js";
import DragDropIcon       from "../../../../../../assets/icons/drag-drop.js";
import Tooltip            from 'rc-tooltip'

class SingleRow extends Component
{
    constructor(props)
    {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);
        this.onDragStart   = this.onDragStart.bind(this);
        this.onMouseLeave  = this.onMouseLeave.bind(this);
    }

    onInputChange(e)
    {
        let duroLabel = this.props.duroLabel;
        let {userLabel, isChecked} = this.props.headerSettings;
        let type = "updateUserLabel";
        if(e.target.type === "checkbox")
        {
            isChecked = e.target.checked;
            type      = "updateCheckbox";
        }
        else
        {
            userLabel = e.target.value;
        }
        this.props.updateMapping(duroLabel, userLabel, isChecked, type);
    }

    onDragStart(){
        this.props.onDragStart()
    }

    onMouseLeave(){
        this.props.onMouseLeave()
    }

    render()
    {
        let duroLabel = this.props.duroLabel;
        let {userLabel, isChecked} = this.props.headerSettings;
        let invalid = this.props.headerSettings.invalid;
        let message = this.props.headerSettings.message;
        let disabled = "";
        if(!isChecked)
        {
            disabled = "disabled";
        }
        let userLabelClassName = `header-input ${!invalid ? "" : "error"} ${disabled}`;
        let markup =
                    <tr name={duroLabel} style={{ borderBottom: '0'}}>
                        <td style={{ width: '35px'}}>
                            <div className="checkbox-container" style={{ display: 'flex', alignItems: 'center'}}>
                                <div className="checkbox-holder" style={{ height: '15px'}}>
                                    <input
                                    type="checkbox"
                                    name="check-row"
                                    className="large"
                                    checked={isChecked}
                                    onChange={(e) => this.onInputChange(e)}
                                    />
                                    <label htmlFor="check-row"
                                    />
                                </div>
                                <div className="drag-drop-holder" data-row={"handler"} onMouseUp={this.onMouseLeave} onMouseDown={this.onDragStart} style={{ height: '19px'}}>
                                    <InlineIcon><DragDropIcon/></InlineIcon>
                                </div>
                            </div>
                        </td>
                        <td>
                            <input
                            type="text"
                            readOnly={true}
                            className={`header-input read-only-input ${disabled}`}
                            value={duroLabel}
                            style={{ height: '25px'}}
                            />
                        </td>
                        <td className="arrow-icon-container" style={{ width: '40px'}}>
                            <InlineIcon customStyles={{ width: '15px', height: '15px'}}>
                                <ArrowIcon/>
                            </InlineIcon>
                        </td>
                        <Tooltip
                          overlayClassName={`simple-rc-tip error custom-rev-tip ${invalid ? "" : "hidden"}`}
                          overlay={
                              <div className={`description-tip`}>
                                  <span>{message}</span>
                              </div>
                          }
                        >
                        <td>
                            <LazyInput
                            type="text"
                            className={userLabelClassName}
                            value={userLabel}
                            onChange={(e) => this.onInputChange(e)}
                            style={{ height: '25px'}}
                            />
                        </td>
                        </Tooltip>
                    </tr>
        return markup;
    }
}

export default SingleRow;
