import { useCallback, useMemo } from "react";
import {
  ApolloClient,
  ApolloError,
  FetchPolicy,
  LazyQueryExecFunction,
  NormalizedCacheObject,
  OperationVariables,
  gql,
  useLazyQuery,
  useQuery,
} from "@apollo/client";
import { Component } from "design/models/component";
import {
  ComponentsFragment,
  ComponentsLastReleasedRevisionFragment,
} from "graphql/fragment/componentsFragment";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import { parseSearchString } from "design/utils/search";
import { Role } from "common/constants";
import { useUser } from "./userQueries";
import { Lot } from "build/models";
import { ComponentsLotFragment } from "graphql/fragment/componentFragment";
import { SuperComponent } from "design/models/changeorder";
import { CoBaseComponentRow } from "graphql/fragment/changeOrderFragment";
import { client } from "graphql/apolloClient";
import { createTreeNode } from "features/changeorders/sdk/editor/preprocessors";

export const GET_COMPONENTS_TREE = gql`
  query ComponentTreeByIds($ids: [ID], $depth: Int) {
    componentTreeByIds(ids: $ids, depth: $depth) {
      ${CoBaseComponentRow}
      treeId
      path
    }
  }
`;

export const GET_COMPONENTS = gql`
  query (
    $libraryType: LibraryType
    $orderBy: [ComponentsOrderByInput]
    $pageSize: Int
    $endCursor: String
    $search: SearchFields
  ) {
    components(libraryType: $libraryType, orderBy: $orderBy, search: $search) {
      ...componentsFragment
    }
  }
  ${ComponentsFragment}
`;

export const GET_COMPONENTS_LAST_RELEASE_REVISION = gql`
  query (
    $libraryType: LibraryType
    $orderBy: [ComponentsOrderByInput]
    $pageSize: Int
    $endCursor: String
    $search: SearchFields
  ) {
    components(libraryType: $libraryType, orderBy: $orderBy, search: $search) {
      ...componentsLastReleasedRevisionFragment
    }
  }
  ${ComponentsLastReleasedRevisionFragment}
`;

const GET_COMPONENT_WITH_LOTS = gql`
  query componentsWithLots($ids: [ID!]!) {
    componentsByIds(ids: $ids) {
      ...componentsLotFragment
    }
  }
  ${ComponentsLotFragment}
`;

export type OrderByType = { [field: string]: GridSortDirection }[] | undefined;

export interface IComponentListArgs {
  endCursor?: string;
  fetchPolicy?: FetchPolicy;
  libraryType?: string;
  orderBy?: OrderByType;
  pageSize?: number;
}

export interface ComponentsQueryConnection {
  edges: { node: Component }[];
  pageInfo: {
    hasNextPage: boolean;
    endCursor?: string;
  };
  totalCount: number;
}

export interface ComponentsQueryData {
  components?: {
    connection: ComponentsQueryConnection;
  };
}

export type PaginationCursor = {
  start?: string;
  end?: string;
};

export interface IComponentList {
  role?: Role;
  pageInfo?: ComponentsQueryConnection["pageInfo"];
  components: Component[];
  endCursor?: string;
  error?: ApolloError;
  fetchComponents?: (
    endCursor: string | undefined,
    orderBy: any,
    searchString?: string,
    cursor?: PaginationCursor
  ) => void;
  hasNextPage?: boolean;
  loading?: boolean;
  totalCount: number;
  loadComponents: LazyQueryExecFunction<
    ComponentsQueryData,
    OperationVariables
  >;
}

export interface CmpWithLots {
  build: {
    lots: Lot[];
  };
}

export interface ComponentsWithLotsQueryData {
  componentsByIds?: CmpWithLots[];
}

export const serializeComponentEdges = (
  edges: ComponentsQueryConnection["edges"],
  role?: Role
) => {
  if (!edges) return [];
  return edges
    .map(({ node }) => (role === Role.VENDOR ? node.lastReleaseRevision : node))
    .filter((n) => !!n) as Component[];
};

export const useComponentsQuery = () => {
  const { role } = useUser().data ?? {};

  return {
    query:
      Role.VENDOR === role
        ? GET_COMPONENTS_LAST_RELEASE_REVISION
        : GET_COMPONENTS,
    role,
  };
};

/**
 * Loads up all the components.
 *
 * @param LibraryType Library of which components to be load.
 * @param orderBy order in which components to be sorted.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The components when loaded, errors, and the loading state.
 */
export function useComponentList(args: IComponentListArgs): IComponentList {
  const { fetchPolicy = "network-only", libraryType, pageSize = 30 } = args;
  const { query, role } = useComponentsQuery();

  const [loadComponents, { data, error, loading }] =
    useLazyQuery<ComponentsQueryData>(query, { fetchPolicy });

  const {
    edges,
    totalCount = 0,
    pageInfo,
  } = data?.components?.connection ?? {};

  const fetchComponents = useCallback(
    (endCursor, orderBy, searchString, cursor) => {
      loadComponents({
        variables: {
          ...(cursor?.start && { startCursor: cursor.start }),
          endCursor: endCursor ? endCursor : cursor?.end,
          libraryType,
          orderBy,
          pageSize,
          search: parseSearchString(searchString),
        },
      });
    },
    [libraryType, pageSize, loadComponents]
  );

  const components: Component[] = useMemo(
    () => serializeComponentEdges(edges ?? [], role),
    [edges, role]
  );

  return {
    role,
    pageInfo,
    components,
    endCursor: pageInfo?.endCursor,
    error,
    fetchComponents,
    loadComponents,
    hasNextPage: pageInfo?.hasNextPage,
    loading,
    totalCount,
  };
}

export function useComponentWithLots(fetchPolicy: FetchPolicy = "cache-first") {
  const [getComponentsWithLots, { data, error, loading }] =
    useLazyQuery<ComponentsWithLotsQueryData>(GET_COMPONENT_WITH_LOTS, {
      fetchPolicy,
    });
  const fetchCmpWithLots = useCallback(
    (ids: string[]) => {
      ids?.length &&
        getComponentsWithLots({
          variables: { ids },
        });
    },
    [getComponentsWithLots]
  );

  const updateCmpLotsCache = useCallback(
    (
      client: ApolloClient<NormalizedCacheObject>,
      newLot: Lot,
      ids: string[]
    ) => {
      const prevData = client.readQuery({
        query: GET_COMPONENT_WITH_LOTS,
        variables: { ids },
      });
      const newData = {
        ...(prevData && {
          ...prevData,
          componentsByIds: (prevData.componentsByIds || []).map(
            (cmp: CmpWithLots) => ({
              ...cmp,
              build: {
                ...cmp.build,
                lots: [...(cmp.build.lots || []), newLot],
              },
            })
          ),
        }),
      };
      client.writeQuery({
        query: GET_COMPONENT_WITH_LOTS,
        variables: { ids },
        data: newData,
      });
    },
    []
  );
  const cmpLotsData = useMemo(
    () => data?.componentsByIds?.[0],
    [data?.componentsByIds]
  );
  return {
    fetchCmpWithLots,
    cmpLotsData,
    cmpLotError: error,
    cmpLotLoading: loading,
    updateCmpLotsCache,
  };
}

function reduceComponentTree(
  paths: SuperComponent[]
): Record<string, SuperComponent> {
  return paths.reduce<Record<string, SuperComponent>>((acc, component) => {
    if (component.treeId) {
      acc[component.treeId] = component;
    }
    return acc;
  }, {});
}

export const getComponentTree = (ids: string[], depth = -1) => {
  return client.query<{ componentTreeByIds: SuperComponent[] }>({
    query: GET_COMPONENTS_TREE,
    variables: { ids, depth },
    fetchPolicy: "no-cache",
  });
};

export const useComponentTreeQuery = (ids: string[], depth = -1) => {
  const { data, error, loading } = useQuery<{
    componentTreeByIds: SuperComponent[];
  }>(GET_COMPONENTS_TREE, {
    variables: { ids, depth },
    fetchPolicy: "no-cache",
  });

  const treeData = (data?.componentTreeByIds ?? []).map(createTreeNode);

  return {
    treeData,
    treeDataByIds: reduceComponentTree(treeData),
    error,
    loading,
  };
};
