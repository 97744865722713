import { ROW_HEIGHT } from "common/components/grid";
import { Box, styled } from "@mui/material";
import { ColumnDefinitions } from "./table.config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWindowSize } from "common/hooks/useWindowSize";
import { DuroGridBasic } from "common/components/grid/grid-basic";
import { AddChildrenModal } from "./addChildrenModal";
import { ChangeOrderType, SuperComponent } from "design/models/changeorder";
import { GridApi, useGridApiRef } from "@mui/x-data-grid-pro";
import { PrimaryTableGroupingCell } from "./cells";
import * as NavButtons from "common/components/grid/toolbar-items";
import { useTreeView } from "./is.tree.view";
import { usePrimaryTableFilters } from "./primary-table.filters";
import { sdk } from "features/changeorders/sdk/editor";
import { sdk as sdkv1 } from "features/changeorders/sdk";
import { BulkStatusUpdateModal } from "./bulk.actions.table";

const NavButtonAddChildren = { ...NavButtons.NavButtonIncludeChildren };
const NavButtonBulkActions = { ...NavButtons.NavButtonBulkActions };

export const setAllRowsExpansion = (
  api: GridApi,
  data: { treeId: string }[],
  expand: boolean
) => {
  const allRowIds = data.map((row) => row.treeId);
  allRowIds.forEach((rowId) => {
    api.setRowChildrenExpansion(rowId, expand);
  });
};

const getTreeViewColumnDefs = (isTreeView: boolean) => {
  const [cpnColDef, ...restCols] = ColumnDefinitions;
  return [...(!isTreeView ? [cpnColDef] : []), ...restCols];
};

export const ChangeOrderEditTable = () => {
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [loading] = sdk.state.flags.tableIsLoading.useStore();
  const dimension = useWindowSize();
  const [data] = sdk.state.rows.useStore();
  const [type] = sdkv1.storage.form.type.useStore();

  const apiRef = useGridApiRef();
  const [rowsWithTree] = sdk.state.rowsWithTree.useStore();
  const { isTreeView, buttons } = useTreeView(apiRef, data);
  const {
    FiltersDropdown,
    data: dataFiltered,
    filtersCount: filtersCount,
    setSelectedFilters,
  } = usePrimaryTableFilters({
    data: isTreeView ? rowsWithTree : data,
  });

  const { ChildModal, selectedRows, setSelectedRows, NavButtonAddChildren } =
    useHandleRowChildren(dataFiltered);

  NavButtonBulkActions.onClick = () => {
    setShowBulkActions(() => true);
  };

  NavButtonBulkActions.isDisabled = () => selectedRows.length === 0;

  return (
    <>
      <StyledTableWrapper
        height={dimension.height - 300}
        maxHeight={ROW_HEIGHT * 50}
        paddingRight="30px"
      >
        <DuroGridBasic
          apiRef={apiRef}
          useTreeData={isTreeView}
          getRowId={(row) => row.treeId}
          groupingColDef={{
            headerName: "CPN",
            renderCell: PrimaryTableGroupingCell,
          }}
          rows={dataFiltered}
          loading={loading}
          columns={getTreeViewColumnDefs(isTreeView)}
          sortModel={[{ field: "cpn", sort: "desc" }]}
          pinnedColumns={{
            left: ["__check__", "errorGroup"],
            right: ["delete"],
          }}
          filterMode="client"
          selectionModel={selectedRows}
          setSelectedRows={setSelectedRows}
          toolbarLeftItems={[FiltersDropdown]}
          toolbarItems={[
            ...(type !== ChangeOrderType.DCO ? [NavButtonBulkActions] : []),
            buttons.NavButtonTree,
            buttons.NavButtonFlat,
            buttons.NavButtonExpand,
            buttons.NavButtonCollapse,
            buttons.NavButtonValidate,
            NavButtonAddChildren,
            buttons.NavButtonRemove,
          ]}
          toolbarProps={{
            filtersCount: filtersCount,
            onClearFilters: () => setSelectedFilters([]),
          }}
        />
        <ChildModal />
        {type !== ChangeOrderType.DCO && showBulkActions && (
          <BulkStatusUpdateModal
            open={showBulkActions}
            setClose={() => setShowBulkActions(false)}
            selection={selectedRows}
            selectedRows={[]}
            type={type}
          />
        )}
      </StyledTableWrapper>
    </>
  );
};

// Styled wrapper for table
const StyledTableWrapper = styled(Box)({
  display: "flex",
});

const useHandleRowChildren = (data: SuperComponent[]) => {
  const [includeChildRows, setIncludeChildRows] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [displayChildrenModal] = sdkv1.storage.displayChildrenModal.useStore();
  const [childrenIds] = sdk.state.childrenIds.useStore();

  useEffect(() => {
    sdk.state.childrenIds.setState(includeChildRows);
  }, [includeChildRows]);

  const handleSetIncludedChildren = useCallback(() => {
    setIncludeChildRows(selectedRows);
  }, [selectedRows]);

  NavButtonAddChildren.onClick = handleSetIncludedChildren;

  const dataById = data.reduce((acc, item) => {
    acc[item.treeId] = item;
    return acc;
  }, {} as Record<string, SuperComponent>);

  const childIdsFiltered = childrenIds.filter(
    (id) => dataById[id].path.length === 1
  );

  const ChildModal = useMemo(
    () => () => {
      if (!displayChildrenModal) return null;
      return (
        <AddChildrenModal
          selectedIds={childIdsFiltered}
          parentIds={childrenIds}
          dataById={dataById}
          open={displayChildrenModal}
          onClose={() => sdkv1.editor.children.handleDisplayModal(false)}
          ariaLabelledby="add-children-modal"
          ariaDescribedby="add-children-modal-description"
        />
      );
    },
    [includeChildRows, displayChildrenModal, childrenIds]
  );

  useEffect(() => {
    if (includeChildRows.length > 0) {
      setSelectedRows([]);
      sdkv1.editor.children.handleDisplayModal(true);
    }
  }, [includeChildRows]);

  return {
    setSelectedRows,
    selectedRows,
    NavButtonAddChildren,
    ChildModal,
    dataById,
  };
};
