import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Tooltip } from "@mui/material";
import DuroPanelTypographyTextStyle from "@duro/typography";

/**
 * Props for the DuroTextOverflow component.
 * @interface
 *
 * @param text - The text passed into the DuroTextOverflow popover component.
 * @param type - The type of CustomSpecsEnum passed into the TypographyTextStyle component.
 */
interface DuroTextOverflowProps {
  /** The text to be displayed and potentially truncated. */
  text: string;
  type?: string;
}

/**
 * DuroTextOverflow Component
 * Displays body text. It will truncate text with ellipsis if it overflows its container and shows the
 * full text in a tooltip.
 *
 * @description
 * Default state of the component is not overflowed.
 *
 * @param {DuroTextOverflowProps} props - The props for the component.
 * @returns {React.ReactElement} The rendered component.
 */
const DuroTextOverflow: React.FC<DuroTextOverflowProps> = ({
  text,
  type = "",
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const [visibleText, setVisibleText] = useState(text);
  const TEXT_LENGTH_THRESHOLD = 15; // Defined threshold for text length.

  /**
   * Measures the width of the text and sets the overflow state.
   */
  const measureText = useCallback(() => {
    const spanElement = textRef.current;
    if (spanElement) {
      const containerWidth = spanElement.clientWidth;
      const textWidth = spanElement.scrollWidth;
      const isOverflow =
        containerWidth === 0 && textWidth === 0
          ? spanElement.innerHTML.length > TEXT_LENGTH_THRESHOLD
          : textWidth > containerWidth;

      setIsOverflowed(isOverflow);
    }
  }, [text]);

  // Effect to measure text on initial render and whenever the text changes.
  useEffect(() => {
    measureText();
  }, [text, measureText]);

  return (
    <Tooltip title={isOverflowed ? text : ""} placement="top-start">
      <DuroPanelTypographyTextStyle
        type={type}
        ref={textRef}
        isOverflowed={isOverflowed}
        noWrap={isOverflowed}
        tabIndex={isOverflowed ? 0 : -1}
        aria-describedby={isOverflowed ? `tooltip-${text}` : undefined}
      >
        {visibleText}
      </DuroPanelTypographyTextStyle>
    </Tooltip>
  );
};

export default React.memo(DuroTextOverflow);
