import "./index.scss"
import React, { useState, useEffect, useRef } from "react"
import Utils from "../../../../../modules/utils"

const FilterMenu = ({ openMenu, toggleOpenMenu, headings, headingsSelect }) => {
  const [openFilter, setOpenFilter] = useState(false)
  const [filters, setFilters] = useState([])
  const modalNode = useRef(null)

  const handleChange = (e, i) => {
    filters[i].visibility = e.target.checked
    headingsSelect(filters)
  }

  useEffect(() => {
    setOpenFilter(openMenu)

    function handleClickOutside(e) {
      let target = e.target
      let parent = modalNode.current
      let isExternal = target !== parent && !Utils.isDescendant(parent, target)

      if (isExternal) {
        document.body.removeEventListener("click", handleClickOutside, { capture: true });
        toggleOpenMenu(false)
      }
    }

    document.body.addEventListener("click", handleClickOutside, { capture: true });
  }, [openMenu, toggleOpenMenu])

  useEffect(() => {
    setFilters(headings)
  }, [headings])

  return (
    <div id="checkbox-menu" className={openFilter ? 'show-menu' : null}>
      <div ref={modalNode} className={`modal${openFilter ? ' open' : ''}`}>
        <label className="light-grey">Filter Table Results</label>
        <ul>
          {
            headings.map((item, i) => (
              <li
                key={`itemFilter-${i}`}
              >
                <input
                  type="checkbox"
                  name={item.key}
                  onChange={e => handleChange(e, i)}
                  checked={item.visibility ? 'checked' : ''}
                />
                <label htmlFor={item.key} />
                <span className="light-grey">{ item.name }</span>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default FilterMenu