import { ChangeEvent, FC, useCallback, useContext, useMemo } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CircleIcon from "@mui/icons-material/Circle";
import ErrorIcon from "@mui/icons-material/Error";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { DuroCheckbox } from "common/components/checkbox";
import { InputField } from "common/components/inputs";
import { ButtonVariants } from "common/constants";
import { colorPalette } from "@duro/themes";
import { CadSettingsModalContext, IFindAndReplaceFieldsData } from "./cadSettingsModalContextProvider";

const inputFieldStyle = {
  style: {
    maxHeight: "2.5rem",
  },
};

const inputFieldsWidth = {
  width: "32rem",
};

const matchCheckboxLabelStyles = {
  margin: "0",
  paddingRight: "1.5rem",
};

export const commonCheckboxLabelStyles = {
  fontSize: "0.875rem",
};

const labelStyling = { color: colorPalette.taupeGray };

const customFieldRegex = /^[A-Za-z0-9()_\-\s,.]*(?:\{(?:CPN|EID|CATEGORY|REV|STATUS)\}[A-Za-z0-9()_\-\s,.]*)+$/;
export const FileNameFormatSettings: FC = () => {
  const { getFileNameFormatSettingsData, setFileNameFormatSettingsData } = useContext(CadSettingsModalContext);
  const {
    currentType,
    customScheme: {
      customFieldError,
      customSchemeData,
    },
    findAndReplaceFields: { findAndReplaceFieldsData },
  } = useMemo(() => (getFileNameFormatSettingsData()), [getFileNameFormatSettingsData]);

  const onCustomSchemeDataChange = useCallback(event => {
    const { value } = event.currentTarget;
    setFileNameFormatSettingsData({
      customScheme: {
        customFieldError: value && !customFieldRegex.test(value),
        customSchemeData: value,
      },
    });
  }, [setFileNameFormatSettingsData]);

  const customNamingSchemeControl = useMemo(() => (
    <Box>
      <CustomNamingSchemeHeading>Custom Naming Scheme</CustomNamingSchemeHeading>
      <CustomNamingSchemeSubHeading>
        Create your own custom scheme using supported characters and keywords.
        Note: keywords should be entered with the {"{ }"}.
      </CustomNamingSchemeSubHeading>
      <InputFieldWrapper>
        <CustomNamingSchemeField>
          <InputField
            componentStyles={inputFieldsWidth}
            disabled={currentType !== "CUSTOM"}
            error={customFieldError}
            InputProps={{
              endAdornment: customFieldError ? <ErrorIcon /> : <></>,
            }}
            inputStyles={inputFieldStyle}
            onChange={onCustomSchemeDataChange}
            value={customSchemeData}
          />
          <TextStyle>.ext</TextStyle>
        </CustomNamingSchemeField>
        <TextUnderField>
          Keywords: {"{CPN}"} , {"{REV}"} , {"{EID}"} , {"{STATUS}"} , {"{CATEGORY}"} <br />
          Supported characters for a file name are letters, numbers, spaces, and ( ) _ - , .
        </TextUnderField>
      </InputFieldWrapper>
    </Box>
  ), [currentType, customFieldError, onCustomSchemeDataChange, customSchemeData]);

  const onFileFormatChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setFileNameFormatSettingsData({ currentType: value });
  }, [setFileNameFormatSettingsData]);

  const onFindAndReplaceChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { id } = event.currentTarget;
    const value = event.currentTarget?.type === "checkbox"
      ? event.currentTarget?.checked : event.currentTarget?.value;
    const splittedId = id.split("-");
    const index = parseInt(splittedId[0], 10);
    const updatedData = findAndReplaceFieldsData.map((item: IFindAndReplaceFieldsData, i: number) => {
      if (i === index) {
        return {
          ...item,
          [splittedId[1]]: value,
        };
      }
      return item;
    });
    setFileNameFormatSettingsData({ findAndReplaceFields: { findAndReplaceFieldsData: updatedData } });
  }, [findAndReplaceFieldsData, setFileNameFormatSettingsData]);

  const onRemove = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    const { id } = event.currentTarget;
    setFileNameFormatSettingsData(
      {
        findAndReplaceFields: {
          findAndReplaceFieldsData: findAndReplaceFieldsData.filter(
            (_: IFindAndReplaceFieldsData, index: number) => index !== parseInt(id, 10),
          ),
        },
      },
    );
  }, [findAndReplaceFieldsData, setFileNameFormatSettingsData]);

  const onAddAnother = useCallback(() => {
    setFileNameFormatSettingsData(
      {
        findAndReplaceFields: {
          findAndReplaceFieldsData: [...findAndReplaceFieldsData, {
            find: "",
            matchCase: false,
            replace: "",
            useRegex: false,
          }],
        },
      },
    );
  }, [findAndReplaceFieldsData, setFileNameFormatSettingsData]);

  const findAndReplaceFields = useMemo(() => {
    const isSectionEnabled = currentType === "FIND_AND_REPLACE";
    return findAndReplaceFieldsData.map(({
      find,
      matchCase,
      replace,
      useRegex,
    }: IFindAndReplaceFieldsData, index: number) => (
      <Box key={index}>
        {
          index === 0 && (
            <Typography>Find and Replace Characters</Typography>
          )
        }
        <FindFieldWrapper>
          <FindField>
            <InputField
              componentStyles={inputFieldsWidth}
              disabled={!isSectionEnabled}
              id={`${index}-find`}
              inputStyles={inputFieldStyle}
              label={"Find"}
              labelStyling={labelStyling}
              onChange={onFindAndReplaceChange}
              placeholder="Enter text to find"
              value={find}
            />
          </FindField>
          {
            findAndReplaceFieldsData.length > 1 && index > 0 && (
              <IconButton disabled={!isSectionEnabled} onClick={onRemove} id={`${index}`}>
                <HighlightOffRoundedIcon />
              </IconButton>
            )
          }
        </FindFieldWrapper>
        <Box>
          <InputFieldWrapper>
            <InputField
              componentStyles={inputFieldsWidth}
              disabled={!isSectionEnabled}
              id={`${index}-replace`}
              inputStyles={inputFieldStyle}
              label={"Replace"}
              labelStyling={labelStyling}
              onChange={onFindAndReplaceChange}
              placeholder={"Enter text to replace"}
              value={replace}
            />
            </InputFieldWrapper>
          <CustomFormGroup>
            <Box>
              <StyledCheckbox
                checkboxFontSize={"1.25rem"}
                checkBoxLabel={"Match case"}
                checked={matchCase}
                disabled={!isSectionEnabled}
                formControlLabelStyles={matchCheckboxLabelStyles}
                id={`${index}-matchCase`}
                labelStyles={commonCheckboxLabelStyles}
                onChange={onFindAndReplaceChange}
                />
              <StyledCheckbox
                checkboxFontSize={"1.25rem"}
                checkBoxLabel={"Use regular expressions"}
                checked={useRegex}
                disabled={!isSectionEnabled}
                id={`${index}-useRegex`}
                labelStyles={commonCheckboxLabelStyles}
                onChange={onFindAndReplaceChange}
                />
            </Box>
            <HelpIconWrapper>
              <Link href="#" underline="none">help?</Link>
            </HelpIconWrapper>
          </CustomFormGroup>
        </Box>
        {
          index === findAndReplaceFieldsData.length - 1 && (
            <AddButtonWrapper>
              <Button
                disabled={!isSectionEnabled}
                onClick={onAddAnother}
                startIcon={<AddCircleOutlineRoundedIcon />}
                variant={ButtonVariants.TEXT}
              >
                Add Another
              </Button>
            </AddButtonWrapper>
          )
        }
      </Box>
    ));
  }, [currentType, findAndReplaceFieldsData, onAddAnother, onFindAndReplaceChange, onRemove]);

  return (
    <FileNameSettingSection>
      <TopSectionHeading>
        Release File Name Format Settings
      </TopSectionHeading>
      <RadioGroup
        aria-labelledby="release-file-name-format-settings"
        name="radio-buttons-group"
        onChange={onFileFormatChange}
        value={currentType}
      >
        <StyledFormControlLabel
          checked={currentType === "STANDARD"}
          control={<Radio size="small" checkedIcon={<CircleIcon />} />}
          label={"Standard Naming Scheme (Default)"}
          value={"STANDARD"}
        />
        <StyledFormControlLabel
          checked={currentType === "CUSTOM"}
          control={<Radio size="small" checkedIcon={<CircleIcon />} />}
          label={customNamingSchemeControl}
          value={"CUSTOM"}
        />
        <StyledFormControlLabel
          checked={currentType === "FIND_AND_REPLACE"}
          control={<Radio size="small" checkedIcon={<CircleIcon />} />}
          label={findAndReplaceFields}
          value={"FIND_AND_REPLACE"}
        />
      </RadioGroup>
    </FileNameSettingSection>
  );
};

const TopSectionHeading = styled(Typography)({
  fontWeight: "600",
  marginBottom: "1rem",
});

const FileNameSettingSection = styled(Box)({
  marginBottom: "1rem",
});

const CustomNamingSchemeHeading = styled(Typography)({
  marginBottom: "0.5rem",
});

const CustomNamingSchemeSubHeading = styled(Typography)({
  fontSize: "0.875rem",
  marginBottom: "1rem",
});

const InputFieldWrapper = styled(Box)({
  marginBottom: "1rem",
});

const CustomFormGroup = styled(FormGroup)({
  flexDirection: "row",
  margin: "1rem 0",
});

export const StyledFormControlLabel = styled(FormControlLabel)(({ checked }) => ({
  marginBottom: "0.5rem",
  marginLeft: "0",
  marginTop: "0.5rem",
  marginRight: "0",
  paddingLeft: "2.5rem",
  position: "relative",
  "& .MuiRadio-root": {
    left: 0,
    position: "absolute",
    top: "-0.5rem",
  },
  "& .MuiFormControlLabel-label": {
    flex: 1,
  },
  ".MuiFormControlLabel-label": !checked && {
    color: colorPalette.lightGrey,
  },
}));

const StyledCheckbox = styled(DuroCheckbox)({
  marginRight: "0.5rem",
});

const HelpIconWrapper = styled(Box)({
  display: "none", // Need to hide this icon until we've made decision related to this.
  marginLeft: "0.5rem",
});

const AddButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "1.5rem",
});

const TextStyle = styled(Box)({
  marginLeft: "0.313rem",
});

const CustomNamingSchemeField = styled(Box)({
  alignItems: "flex-end",
  display: "flex",
  marginBottom: "1rem",
});

const FindField = styled(Box)({
  marginTop: "0.5rem",
});

const FindFieldWrapper = styled(Box)({
  alignItems: "flex-end",
  display: "flex",
  marginBottom: "1rem",
});

const TextUnderField = styled(Box)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
});
