import "./index.scss"
import React                        from "react"
import { connect }                  from 'react-redux';
import buildAction                  from '../../../../helpers/buildAction';
import ModalBox                     from "../../../ui/modal-box/"
import Tooltip                      from 'rc-tooltip'
import Link from "../../../ui/link"
import Utils                        from "../../../../modules/utils"
import {Helmet}                     from "react-helmet"
import PerfectScrollbar             from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css'
import USER_FORM from "../../../../action-types/user_form"
import LibraryModal                 from "../library-modal"
import EllipsisTooltip              from "../../common/ellipsis-tooltip";

export class AddUserModal extends React.Component
{
    constructor(props, context) {
        super(props, context);

        this.state = {
            invitedUsers: [],
            showSandboxModal: false
        }
    }

    onInputChange = (event) => {
      const {dispatch} = this.props;
      Utils.persistCursor(event, event.target.value)
      dispatch(buildAction(USER_FORM.UPDATE_INPUT_ON_CHANGE, event.target))
    }

    onSubmit = (event) => {
        const {dispatch} = this.props;
        event.preventDefault()
        Utils.blur()
        let data = Utils.fromInputsToValues({...this.props.userForm.inputs})

        let payload = { data }
        dispatch(buildAction(USER_FORM.SUBMIT_INVITE_USER, payload))
        let state   = this.state
        let inputs  = this.props.userForm.inputs
        state.invitedUsers.push({email: inputs.email.value, role: inputs.role.value})
        this.setState(state);
        dispatch(buildAction(USER_FORM.RESET_FORM))
    }

    skipTutorial = (event) => {
        this.props.skipTutorial()
    }

    getSampleProduct = () => {
        this.props.getSampleProduct("drone")
    }

    getRows = () => {
        if (this.props.invitedUsersList && this.props.invitedUsersList.length > this.state.invitedUsers.length)
        {
            let state = this.state;
            state.invitedUsers = this.props.invitedUsersList;
            this.setState(state)
        }


        let rows = this.state.invitedUsers.map((invitedUser, i) =>
        {
            let row =
                <div key={`${i}-new-users-list`} className="user-list">
                    <div className="email">
                        <EllipsisTooltip
                            index={i}
                            innerClasses={"email-content"}
                            title={invitedUser.email}>
                                {invitedUser.email}
                        </EllipsisTooltip>
                    </div>
                    <div className="role">
                      {this.capitalizeFirstLetter(invitedUser.role)}
                    </div>
                    <div className="status">
                      Invited
                    </div>
                </div>
            return row

        })

        return rows
    }

    capitalizeFirstLetter(string) {
        if (!string) return
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    openSandboxModal = (event) => {
        event.stopPropagation();
        this.setState({ showSandboxModal: true });
    }

    closeSandbocModal = () => {
        this.setState({ showSandboxModal: false })
    }

    render() {
        let inputs = this.props.userForm.inputs
        let invitedUsersRows = this.getRows()
        let markup = null
        let allowedUserRoles = this.props.companySettings && this.props.companySettings.allowedUserRoles

        if (this.state.showSandboxModal)
        {
            markup = <LibraryModal
                skipTutorial={this.skipTutorial}
                getSampleProduct={this.getSampleProduct}
                onBack={this.closeSandbocModal}
                invitedUsersList={this.state.invitedUsers}
            />
        }
        else
        {
            markup =
                <div className="add-users-modal-holder">
                    <Helmet>
                        <title>{Utils.makeTitle("Dashboard")}</title>
                    </Helmet>
                    <ModalBox hideCrossIcon={true} onClose={this.skipTutorial} className="add-users-modal">
                        <div className="library-contianer">
                            <div className="library-content-holder">
                                <div className="heading-holder">
                                    <h1>Add Users</h1>
                                    <p>Add users to your company account</p>
                                </div>

                                <div className="library-holder" >

                                    <form className="new-user-form-block" onSubmit={this.onSubmit}>
                                        <div className="names-block">
                                            <div className="col-item">
                                                <div className="input-holder">
                                                    <label>
                                                        <input
                                                            id="firstName"
                                                            type="text"
                                                            name="firstName"
                                                            className={(inputs.firstName.value ? "shrink " : "") + inputs.firstName.class}
                                                            value={inputs.firstName.value}
                                                            onChange={this.onInputChange}
                                                        />
                                                        <div className="label-text">FIRST NAME*</div>
                                                        <div className="ui-message">{inputs.firstName.message}</div>
                                                    </label>
                                                </div>

                                                <div className="input-holder">
                                                    <label>
                                                        <input
                                                            id="lastName"
                                                            type="text"
                                                            name="lastName"
                                                            className={(inputs.lastName.value ? "shrink " : "") + inputs.lastName.class}
                                                            value={inputs.lastName.value}
                                                            onChange={this.onInputChange}
                                                            placeholder=""

                                                        />
                                                        <div className="label-text">LAST NAME*</div>
                                                        <div className="ui-message">{inputs.lastName.message}</div>
                                                   </label>
                                               </div>
                                            </div>

                                            <div className="col-item">
                                                <div className="input-holder">
                                                    <label>
                                                        <input
                                                            id="email"
                                                            type="text"
                                                            name="email"
                                                            className={(inputs.email.value ? "shrink " : "") + inputs.email.class}
                                                            value={inputs.email.value}
                                                            onChange={this.onInputChange}
                                                            placeholder=""

                                                        />
                                                        <div className="label-text">Email*</div>
                                                        <div className="ui-message">{inputs.email.message}</div>
                                                   </label>
                                                </div>

                                                <div className="input-holder">
                                                    <Tooltip
                                                        overlay={inputs.role.message}
                                                        overlayClassName={"error" + (inputs.role.message.length > 0 ? "" : " hidden")}
                                                        getTooltipContainer={() => document.querySelector("#routes")}
                                                    >
                                                        <div className="select-role">
                                                            <label className="role-label">Role *</label>
                                                            <select
                                                                name="role"
                                                                className={inputs.role.class}
                                                                value={inputs.role.value}
                                                                onChange={this.onInputChange}
                                                                data-tip={inputs.role.message}
                                                                data-place="right"
                                                                data-type="error"
                                                            >
                                                            { Utils.displayUserRoleOptions(allowedUserRoles) }
                                                            </select>
                                                        </div>

                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-holder">
                                             <button type="submit" className={inputs.submit.class + " tutorial-btn"}>Add User</button>
                                        </div>

                                    <div className={"user-record " + (this.state.invitedUsers.length > 0 ? "user-record-display" : "")}>
                                        <PerfectScrollbar className="add-user-scroll">
                                            {invitedUsersRows}
                                        </PerfectScrollbar>
                                    </div>
                                      <div className="footer-btn">
                                        <div className="btn-holder">
                                           <Link
                                                className="create-account-link"
                                                replace={false}
                                                    onClick={this.openSandboxModal}
                                                to="/dashboard"
                                            >
                                                Skip This Step
                                            </Link>
                                            <button
                                                className="tutorial-btn btn-next no-margin-b"
                                                    onClick={this.openSandboxModal}
                                            >
                                                Next
                                            </button>
                                        </div>
                                          <span className="skip-details">
                                            You may add or update users in account settings
                                          </span>
                                        </div>

                                  </form>
                                </div>
                            </div>
                        </div>
                    </ModalBox>
                </div>
        }

        return (
            <div>
                <Helmet>
                    <title>{Utils.makeTitle('Dashboard')}</title>
                </Helmet>
                {markup}
            </div>
        );
    }
}

export default connect((store) => ({
    userForm: store.userForm,
    user: store.user,
}))(AddUserModal);
