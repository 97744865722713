import "./index.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useDuroFlag } from "common/components/launchDarkly";
import MandatoryApproverComp from "./mandatory-approver";
import { CompanyConfigToggle } from "@duro/forms";
import Utils from "../../../../modules/utils";
import API from "../../../../modules/api";
import Loading from "../../../ui/inner-loading";
import { Integrations } from "./integrations";
import { AccountSettings } from "./accountSettings";
import { permissions as Permissions } from "v1/modules/schemas";
import buildAction from "v1/helpers/buildAction";
import { useDispatch } from "react-redux";
import COMPANY from "v1/action-types/company";
const Configurations = (props) => {
  const { "display-cad-pdm-sections": displayCadPdmSections } = useDuroFlag();
  const user = useSelector((store) => store.user.data);
  const [company, setCompany] = useState({});
  const [templateMappings, setTemplateMappings] = useState({});
  const [isSetupStage, setIsSetupStage] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [templatesFetched, setTemplatesFetched] = useState(false);
  const dispatch = useDispatch();

  const finalizeSetupStage = useCallback(
    (mappings) => {
      let isSetup = true;
      let oldMappings = { ...templateMappings };
      if (!!mappings) {
        let updatedRules = Object.keys(mappings);
        updatedRules.forEach((rule) => {
          oldMappings[rule] = mappings[rule];
        });
      }

      isSetup =
        oldMappings &&
        Object.values(oldMappings).every((value) => value === null);
      setIsSetupStage(isSetup);
      if (Object.values(mappings || {}).length)
        setTemplateMappings(oldMappings);
    },
    [templateMappings]
  );

  const getCoTemplate = () => {
    API.services.getCoTemplate((err, response) => {
      if (response) {
        const companyTemplates = response.filter((template) => {
          return template.isPublic === true;
        });
        setTemplates(companyTemplates);
        setTemplatesFetched(true);
      }
    }, true);
  };

  const afterTemplateMappingCreation = useCallback((err, res) => {
    if (res) {
      getCompanyCoTemplateMappings();
    }
  }, []);

  useEffect(() => {
    API.companies.getCurrent((err, response) => {
      if (response) {
        setCompany({ _id: response._id, settings: response.settings });

        if (response && !response.settings.coTemplateMappings)
          API.services.createCoTemplateMappings(
            { company: company._id },
            afterTemplateMappingCreation
          );
        else getCompanyCoTemplateMappings();
      }
    });
  }, [afterTemplateMappingCreation, company._id]);

  const getCompanyCoTemplateMappings = () => {
    API.services.getCompanyCoTemplateMappings((err, res) => {
      if (res) setTemplateMappings(res);
    });
  };

  useEffect(() => {
    if (Object.keys(templateMappings).length) getCoTemplate();
  }, [templateMappings]);

  useEffect(() => {
    if (templatesFetched) {
      finalizeSetupStage();
      setShowLoading(false);
    }
  }, [finalizeSetupStage, templatesFetched]);

  const createConfigUpdateHandler = (configKey, successCallback) => {
    return (newValue) => {
      const data = {
        settings: { configurations: { [configKey]: newValue } },
      };
      dispatch(buildAction(COMPANY.SET_COMPANY_STATE, data));
      API.companies.update(company._id, data, (err) => {
        if (!err && successCallback) {
          successCallback(newValue);
        } else if (err) {
          console.log(err);
        }
      });
    };
  };

  // Create specific handlers using the higher-order function
  const handleMandatoryCommentUpdate = createConfigUpdateHandler(
    "coCommentMandatoryEnabled",
    (newValue) => {
      window.__isCommentMandatory = newValue;
    }
  );

  const handleParentAssembliesInCO = createConfigUpdateHandler(
    "addParentAssembliesInCO"
  );

  const handleChildComponentsInCO = createConfigUpdateHandler(
    "addChildComponentsInCO"
  );

  return showLoading ? (
    <Loading />
  ) : (
    <div className="configuration-settings-route app-row">
      <Helmet>
        <title>{Utils.makeTitle("Settings - Configuration")}</title>
      </Helmet>
      <div className="main-block">
        <div className="content-block">
          <h1>Configuration</h1>
          <div className="sub-heading">
            <h2>Change Orders</h2>
          </div>
          <MandatoryApproverComp
            user={user}
            company={company}
            templateMappings={templateMappings}
            isSetupStage={isSetupStage}
            finalizeSetupStage={finalizeSetupStage}
            templates={templates}
          />

          <CompanyConfigToggle
            heading="Mandatory Comments"
            description="Requires users to enter a comment in order to submit their decision on a Change Order."
            isToggleEnabled={
              company.settings.configurations.coCommentMandatoryEnabled
            }
            onUpdate={handleMandatoryCommentUpdate}
            canEdit={Permissions.can("edit", "configurations", user)}
            tooltipText="Administrator access only"
          />

          <CompanyConfigToggle
            heading="Add Parent Assemblies to webhook"
            description="This will add all released Parent assemblies to a closed Change Order webhook payload."
            isToggleEnabled={
              company.settings.configurations.addParentAssembliesInCO
            }
            onUpdate={handleParentAssembliesInCO}
            canEdit={Permissions.can("edit", "configurations", user)}
            tooltipText="Administrator access only"
          />
          <CompanyConfigToggle
            heading="Add Child Assemblies to webhook"
            description="This will add all released Child components to a closed Change Order webhook payload"
            isToggleEnabled={
              company.settings.configurations.addChildComponentsInCO
            }
            onUpdate={handleChildComponentsInCO}
            canEdit={Permissions.can("edit", "configurations", user)}
            tooltipText="Administrator access only"
          />
          {displayCadPdmSections && (
            <>
              <div className="sub-heading">
                <h2>Integrations</h2>
              </div>
              <Integrations />
              <div className="sub-heading">
                <h2>Account Settings</h2>
              </div>
              <AccountSettings user={user} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Configurations;
