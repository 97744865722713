import {
  Box,
  DialogContent,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { sdk } from "features/changeorders/sdk";
import { useEffect, useState } from "react";

interface ChangeOrderApprovalTemplate { 
  id: string;
  templateName: string;
  isPublic: boolean;
}

export type TemplateListProps = {
  templates: ChangeOrderApprovalTemplate[];
};

export type GenericListProps = {
  listName: string;
  templates: ChangeOrderApprovalTemplate[];
  displayModal: (id: string) => void;
};

export const GenericList: React.FC<GenericListProps> = ({ listName, templates, displayModal }) => {
  return (
    <>
      <Typography
        variant="h3"
        style={{
          color: "#888",
          fontWeight: "700",
          borderBottom: "1px solid #888",
          padding: "8px 0",
        }}
        >
        {listName}
      </Typography>
      <List sx={{ background: "transparent" }}>
        {templates.map(({ id, templateName }) => (
          <ListItem
            key={id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px 0",
              borderBottom: "1px solid #888",
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.05)",
              },
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            <Typography sx={{ fontSize: "1rem" }} noWrap>
              {templateName}
            </Typography>
            <CancelIcon
              color="info"
              fontSize="small"
              onClick={() => displayModal(id)}
              sx={{
                cursor: "pointer",
                color: "primary.main",
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  )
};

export const TemplateList: React.FC<TemplateListProps> = ({ templates }) => {
  const [templateList, setTemplateList] = useState(templates);
  const [pendingTemplatesToDelete] = sdk.storage.pendingTemplatesToDelete.useStore();

  useEffect(() => {
    setTemplateList(() => {
      return templateList.filter((template) => !pendingTemplatesToDelete.has(template.id));
    });
  }, [pendingTemplatesToDelete]);

  const showConfirmationModal = (id: string) => {
    sdk.storage.confirmTemplateToDelete.setState(id);
    sdk.approvers.displayDeleteTemplateModal(true);
  }

  const companyTemplates = templateList.filter((template) => template.isPublic === true);
  const privateTemplates = templateList.filter((template) => template.isPublic !== true);

  return (
    <Box sx={{ padding: "6px 0" }}>
      <DialogContent sx={{ maxHeight: "60vh", overflowY: "auto" }}> 
        <GenericList listName="PRIVATE TEMPLATES" templates={privateTemplates} displayModal={showConfirmationModal} />
        <GenericList listName="COMPANY TEMPLATES" templates={companyTemplates} displayModal={showConfirmationModal} />
      </DialogContent>
    </Box>
  );
};
