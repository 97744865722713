import "../index.scss"
import React, { Component }         from    "react"
import stepsData                    from    "./stepsData"
import fullBomTreeSrc               from "../../../../assets/images/full-bom-tree.png"
import firstProductSrc              from "../../../../assets/images/first-product.png"
import importExistingSrc            from "../../../../assets/images/import-existing.png"
import createFromVendorSrc          from "../../../../assets/images/create-from-vendor.png"
import submitCoSrc                  from "../../../../assets/images/full-bom-tree.png"
import exportFinalSrc               from "../../../../assets/images/export-final.png"

class ToolTipContent extends Component
{
    constructor(props, context) {
        super(props, context);
        this.getSrcImage = this.getSrcImage.bind(this)
        this.onImgClick = this.onImgClick.bind(this);
    }

    getSrcImage(key)
    {
        let imageSrc = fullBomTreeSrc
        if (key === "full_bom_assembly_tree") imageSrc = fullBomTreeSrc
        else if(key === "first_product") imageSrc = firstProductSrc
        else if(key === "import_an_existing_spreadsheet") imageSrc = importExistingSrc
        else if(key === "component_from_vendor") imageSrc = createFromVendorSrc
        else if(key === "change_order") imageSrc = submitCoSrc
        else if(key === "export") imageSrc = exportFinalSrc
        return imageSrc
    }

    onImgClick(event) {
        event.stopPropagation();
        this.props.onClick(this.props.stepNumber);
    }

    render()
    {
        let step = stepsData.steps[this.props.stepNumber]
        let markup = null
        markup =
            <div>
                <div className="heading-holder">
                   {step.h1 && <h1>{step.h1}</h1>}
                </div>
                {step.p1 && <p>{step.p1}</p>}
                <div className="image-holder">
                    <img
                        src={this.getSrcImage(step.key)}
                        onClick={this.onImgClick}
                        alt=""
                    />
                </div>
                {step.p2 && <p>{step.p2}</p>}
                {step.p3 && <p>{step.p3}</p>}
            </div>

        return markup
    }
}

export default ToolTipContent
