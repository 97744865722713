
import "./index.scss"
import React, {Component}     from "react"
import Config   from "../../../modules/config"
import Icon     from "../../ui/icon"
import largeSrc from "../../../assets/icons/upload-l.svg"
import smallSrc from "../../../assets/icons/upload-s.svg"
import smallSrc2  from "../../../assets/icons/upload-s2.svg"

import DropZone from "react-dropzone"

class FileDrop extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.onChange = this.onChange.bind(this)
        this.onDropRejected = this.onDropRejected.bind(this);
    }
    onChange(files)
    {
        let clazz = this.refs.drop.node.className
        let reject = clazz.indexOf("reject-files") > -1
        if(files.length < 1 || reject) return
        this.props.onChange && this.props.onChange(files)
    }

    onDropRejected(files)
    {
        const maxFileSize = this.props.maxFileSize || Config.MAX_FILE_SIZE;
        let maxFileSizeInMB = maxFileSize/1000000;
        this.props.onDropRejected && this.props.onDropRejected(files, maxFileSizeInMB);
    }

    render()
    {
        const maxFileSize = this.props.maxFileSize || Config.MAX_FILE_SIZE;
        let maxFileSizeInMB = maxFileSize/1000000;
        let markup =

            <div className={"file-drop-block " + (this.props.collapsed ? "collapsed" : "")}>
                 <DropZone
                        ref="drop"
                        className="drop-zone"
                        activeClassName="accept-files"
                        rejectClassName="reject-files"
                        onDrop={this.onChange}
                        accept={this.props.accept}
                        maxSize={maxFileSize}
                        onDropRejected={this.onDropRejected}
                        >
                        <div className="collapsed-block">
                            <Icon src={smallSrc2} />
                            <div>
                                <span>{`Drag ${this.props.isDocument ? "Documents" : "Images"} to Upload, or`}</span>
                                <a>Browse</a>
                            </div>
                        </div>
                        <div className="modal"></div>
                    </DropZone>
            </div>

        return markup
    }
}

export default FileDrop
