import "./index.scss";
import React, {Component}   from "react";
import config               from "../../../modules/config";
import ExportNotification   from "./modules/export-module";
import Pusher               from "pusher-js";

export class Notification extends Component
{
    constructor(props)
    {
        super(props);
        this.pusher = null;
        this.state = {
            notifications : [],
            displayNotificationModal: true,
            jobId: "",
            tabId: ""
        }
        this.toggleNotificationModal   = this.toggleNotificationModal.bind(this);
    }

    componentDidMount()
    {
        this.pusher  = new Pusher(config.PUSHER_KEY_ID, {
            cluster: config.PUSHER_CLUSTER,
            encrypted: true
        });
        let userId = this.props.user._id;
        let channel = this.pusher.subscribe(userId);
        let _this   = this;

        let state = this.state;

        channel.bind('exportNotification', data =>
        {
           if (window.__tabId === data.tabId)
           {
                let exportObject = {jobId: "", jobType: ""};
                exportObject.jobId = data.jobId;
                exportObject.jobType = data.jobType;
                state.jobId = data.jobId;
                state.tabId = data.tabId;
                state.notifications.push(exportObject);
                _this.setState(state);
           }
        });
    }

    componentWillUnmount()
    {
        this.pusher.unsubscribe(this.props.user._id);
    }

    toggleNotificationModal(modalName, modalValue)
    {
        this.setState({[modalName]: modalValue});
    }

    render()
    {
        let markup = null;
        for(let notification of this.state.notifications){
            if (notification.jobType === "EXPORT")
            {
                {
                    this.state.displayNotificationModal ?
                    markup =
                    <ExportNotification
                    toggleNotificationModal = {this.toggleNotificationModal}
                    user={this.props.user}
                    pusher={this.pusher}
                    notifications={this.notifications}
                    jobId={this.state.jobId}
                    tabId={this.state.tabId}
                    />
                    : null
                }
            }
        }
        return markup;
    }
}

export default Notification
