import { Box, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';

import { Room } from "../types";

export const DataroomItem = ({ room }: { room: Room }) => {
    return (
        <Box 
            sx={{ 
              backgroundColor: '#353540',
              color: '#FFFFFF', 
              borderRadius: '10px', 
              overflow: 'hidden', 
              display: 'flex', 
              flexDirection: 'column' 
            }}
        >
            <Box 
                component="img"
                sx={{ 
                    height: '180px', 
                    width: '100%', 
                    objectFit: 'cover' 
                }}
                src="https://placehold.co/600x400"
                alt="Dataroom Image"
            />
            <Box sx={{ padding: '8px', flexGrow: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 600 }} >
                    {room.title}
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography variant="body2" color="textSecondary">
                        Created
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                        {room.createdDate}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography variant="body2" color="textSecondary">
                        Created by
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                        {room.createdBy}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={1}>
                    <Typography variant="body2" color="textSecondary">
                        Products
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                        {room.products}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2} mt={1}>
                    <Typography variant="body2" color="textSecondary">
                        Components
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                        {room.components}
                    </Typography>
                </Box>
                <Box display="flex" >
                    <Box display="flex" alignItems="center" sx={{ color: '#FFFFFF99' }} mr={2}>
                        <SettingsIcon />
                        <Typography variant="body2" ml={1}>Settings</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ color: '#FFFFFF99' }}>
                        <PeopleIcon />
                        <Typography variant="body2" ml={1}>Access</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
