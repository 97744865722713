import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { ComponentLink } from "common/components/component-link";
import {
  CustomGroupingCellElement,
  getCustomGroupingCellProps,
} from "common/components/grid/grid-basic";
import { colorPalette } from "components/themes";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

export const StyledGroupCell = styled(Box)`
  a {
    width: 4.188rem;
    height: 1.188rem;
    margin: 0.438rem 5.322rem 0.375rem 0.275rem;
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colorPalette.green} !important;
  }
`;

const iconStyles = {
  width: "1.5rem",
  height: "1.5rem",
};

export const PrimaryTableGroupingCell: CustomGroupingCellElement = (props) => {
  const { params, childrenCount } = getCustomGroupingCellProps(props);
  const isExpanded = params.rowNode?.childrenExpanded;

  const handleToggle = () => {
    if (params.api) {
      params.api.setRowChildrenExpansion(params.id, !isExpanded);
    }
  };

  const parentOffset = childrenCount > 0 ? 10 : 0;

  return (
    <StyledGroupCell
      display="flex"
      alignItems="center"
      style={{ marginLeft: params.rowNode.depth * 30 - parentOffset }}
    >
      {childrenCount > 0 && (
        <span
          onClick={handleToggle}
          style={{ display: "flex", transform: `translate(5px, -1px)` }}
        >
          {isExpanded ? (
            <ArrowDropDown sx={iconStyles} />
          ) : (
            <ArrowRight sx={iconStyles} />
          )}
        </span>
      )}
      <ComponentLink item={params.row} />
    </StyledGroupCell>
  );
};
