import submitFormData   from './submitFormData.js';
import userData         from './userData.js';

export function gethistoryObject(){
    const history = {
        length: 20,
        scrollRestoration: "auto"
    }
    return history
}
export function getDataObject(){
    let { inputs, list } = submitFormData.changeForm

    let children = list.map((child) =>
    {
        return child._id
    })

    let data =
    {
        type        : inputs.types.value,
        name        : inputs.name.value,
        description : inputs.description.value,
        children    : [],
        resolution  : "NONE",
        status      : "DRAFT",
        history     :
        [
            {
                action : "CREATED",
                user   : userData.data._id
            }
        ]
    }
    return data
}

let payload = {
    data: getDataObject(),
    history: gethistoryObject()
}
export default payload
