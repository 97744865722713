import "./index.scss"
import React            from "react"
import {Link}           from "react-router-dom"
import {Helmet}         from "react-helmet"
import Utils            from "../../../../modules/utils"

class Terms extends React.Component
{
    render()
    {
        let markup =
            <div className="terms-route app-row">
                <Helmet>
                    <title>{Utils.makeTitle("Legal - Terms of Use")}</title>
                </Helmet>
                <div className="main-block">

                    <div className="nav-block">
                        <div>TERMS of USE</div>
                        <Link to="/legal/policy">PRIVACY POLICY</Link>
                    </div>

                    <div className="content-block">

                        <h1>Terms of Use</h1>
                        <h3>Last Modified on February 20, 2020</h3>

                        <p>PLEASE READ THIS AGREEMENT CAREFULLY; THIS IS A BINDING CONTRACT.</p>

                        <p>
                            Welcome to the Duro Labs, Inc. website (the “<em>Site</em>”). Your access and use of the Site, and the features, products and services provided by Duro Labs, Inc. (“<em>Duro Labs</em>,” “<em>we</em>,” “<em>us</em>,” or “<em>our</em>”) through the Site, but excluding any software, products or services provided by Duro Labs under a separate written agreement, (individually and collectively, the “<em>Service</em>”) is subject to the terms and conditions in this Terms of Use (this "<em>ToU</em>”).
                        </p>

                        <p>
                            You must agree to this ToU in order to use the Service. If you use the Service, or click to accept or agree to this ToU if presented to you in a user interface for the Service, we will understand this as your acceptance of this ToU and your agreement to all of its terms and conditions. By accepting this ToU or using the Service, you represent and warrant that you have the legal capacity to enter a contract in the jurisdiction where you reside. If you do not accept this ToU, then you may not use the Service.
                        </p>

                        <h2 className="section">1. ToU Updates.</h2>
                        <p>
                            Duro Labs may update this ToU at any time, and Duro Labs will post a link to the updated version of this ToU on the Site. You understand and agree that you will be deemed to have accepted the updated ToU if you use the Service after the updated ToU is posted. If at any point you do not agree to any portion of this ToU then in effect, you must immediately stop using the Service.
                        </p>

                        <h2 className="section">2. Provision of the Service.</h2>
                        <p>
                            You are responsible for any Internet connection and telecommunications fees and charges that you incur when accessing the Service. You acknowledge and agree that Duro Labs may make changes to the Service at any time without notifying you in advance.
                        </p>

                        <h2 className="section">3. Duro PLM Application.</h2>
                        <p>
                            The following terms and conditions apply to your access and use of Duro Labs’s proprietary software application referred to as the “Duro PLM Application” (the “<em>Application</em>”): 
                        </p>

                        <p>
                            3.1 SaaS Services.
                        </p>

                            <p className="indent">
                                a) Grant of License.  Duro Labs will provide the person or entity associated with an Account (as defined below) (“<em>Licensee</em>”) and Licensee’s employees, agents and independent contractors who are authorized by Licensee (each, an “<em>Authorized User</em>”), access to and use of the Application via a web browser or desktop application (the “<em>SaaS Services</em>”).  Subject to Licensee’s and each Authorized User’s compliance with all of the terms and conditions of this ToU, Duro Labs hereby grants to Licensee and its Authorized Users a non-exclusive, non-sublicensable and non-transferable, limited license to (i) access and use the SaaS Services, solely in connection with Licensee’s own internal business use and benefit in accordance with the Documentation (as defined below); and (ii) copy any written materials delivered by Duro Labs to Licensee from time to time, in any medium, relating to the use and operation of the SaaS Services (the “<em>Documentation</em>”) and distribute such copies within Licensee’s enterprise, only as reasonably necessary to use the SaaS Services in accordance with this Section 3.1(a).
                            </p>

                            <p className="indent">
                                b) Reservation of Rights.  This ToU grants Licensee only the right to use the SaaS Services as set forth herein and does not convey or transfer title or ownership of the Application and/or SaaS Services to Licensee.  All rights not expressly granted herein are reserved by Duro Labs, and no other licenses are granted herein by implication, estoppel or otherwise.
                            </p>

                            <p className="indent">
                                c) Restrictions.  Licensee may not use the SaaS Services, the Application or the Documentation, except as expressly set forth in Section 3.1(a).  Licensee shall not, and shall not permit any third party to, (i) copy, modify, duplicate, create derivative works of the SaaS Services, the Application or the Documentation and/or any component thereof; (ii) access, use, distribute, sell, transfer, encumber, sublicense, rent, loan, lend or lease the SaaS Services or the Application, and/or any component thereof to any third party; (iii) reverse engineer, decompile, disassemble or otherwise attempt to discover or directly access the source code or any underlying ideas or algorithms of any portions of the SaaS Services, the Application, the Documentation and/or any component thereof, except to the extent permitted by applicable law; (iv) permit use any of the SaaS Services or the Application by any person who is not an Authorized User; (v) provide temporary access to the SaaS Services to any other party; or (vi) use the SaaS Services or the Application to provide any timesharing service, service bureau, network or any other services for revenue generating purposes.
                            </p>

                            <p className="indent">
                                d) Cooperation.  Licensee shall cause its systems administrator and Authorized Users to comply with the terms and conditions of this Section 3.1.  Licensee shall cooperate with Duro Labs, and shall render all reasonable assistance requested by Duro Labs in preventing and identifying any use of or access to the SaaS Services, the Application or the Documentation, by Licensee personnel or anyone else, in violation of the terms and restrictions of this Section 3.1.
                            </p>

                            <p className="indent">
                                e) Modification of SaaS Services.  Duro Labs may periodically modify the features, components and functionality of the SaaS Services from time to time; provided, however, that Duro Labs will use commercially reasonable efforts to provide Licensee with at least twenty-four (24) hours advance notice of any modification or maintenance of the SaaS Services that is expected to materially affect Licensee’s use of, or ability to access, the SaaS Services.  Duro Labs shall have no liability for, or any obligations due to, any changes in Licensee’s hardware, systems or other software which may be necessary to use or access the SaaS Services due to a modification of the SaaS Services provided by Duro Labs.
                            </p>

                            <p className="indent">
                                f) New Versions.  Duro Labs reserves the right to create, at its sole discretion, new versions of the Application.  Duro Labs shall have no obligation to make available to Licensee new versions, releases or updates of the Application and shall have no obligation to provide, at no additional expense to Licensee, major product enhancements and/or new features that Duro Labs markets separately to other customers for an additional fee; provided that, Duro Labs may, in its sole discretion, elect to provide such enhancements or features on a case-by-case basis at no cost.  Duro Labs shall have no liability for, or any obligations due to, any changes in Licensee’s hardware, systems or other software which may be necessary to use or access the SaaS Services due to new versions or updates provided by Duro Labs.
                            </p>

                            <p className="indent">
                                g) No Maintenance or Support.  Except as otherwise provided in a separate written agreement executed by Duro Labs and Licensee, Duro Labs shall have no obligation to render any maintenance or support services with respect to the SaaS Services or the Application including, but not limited to, training, data conversion, program modification and enhancement, etc.
                            </p>

                        <p>
                            3.2 Licensee Obligations.
                        </p>

                            <p className="indent">
                                a) Licensee Equipment.  Other than the SaaS Services provided by Duro Labs, Licensee is responsible for all other services, equipment and facilities (including, without limitation, all hardware, telecommunications equipment, connectivity, cabling and software) required to access the SaaS Services.  Licensee shall be responsible for procuring all licenses of third party software necessary for Licensee’s use of the SaaS Services.
                            </p>

                            <p className="indent">
                                b) Security; Authentication.  Licensee shall cooperate with Duro Labs, and shall render all reasonable assistance requested by Duro Labs in preventing and identifying any unauthorized use of or access to the SaaS Services and/or the Application.  Licensee will follow reasonable authentication procedures provided by Duro Labs from time to time regarding access to the SaaS Services.  Notwithstanding the generality of the foregoing, Licensee agrees that Licensee will (i) retain sole responsibility for the security and use of Licensee’s and its Authorized Users’ access credentials and (ii) employ reasonable screening and security procedures necessary to securely administer the distribution and use of all access credentials and protect against any unauthorized access to or use of the SaaS Services.
                            </p>

                            <p className="indent">
                                c) Consumer Protection and Privacy.  Licensee shall comply with (i) all applicable laws and regulations relating to (A) the privacy of users of the SaaS Services and the Application, including without limitation providing appropriate notices to and obtaining appropriate consents from any individuals to whom Licensee Data (as defined below) relates; (B) consumer protection, marketing, promotion, and text messaging, email, and other communications; and (C) the use, collection, retention, storage, security, disclosure, transfer, disposal, and other processing of any Personally Identifiable Information (as defined below); and (ii) the terms of the Duro Labs online privacy policy available at http://www.durolabs.co/.
                            </p>

                        <p>
                            3.3 Accounts.
                        </p>

                            <p className="indent">
                                a) Account Registration. To access the Application, you must have an account. You can create an account by completing the registration process (an "<em>Account</em>"). You may be required to provide information about yourself as part of the registration process or your continued use of the Application. You agree that any registration information that you submit to Duro Labs will be correct, accurate and up to date.
                            </p>

                            <p className="indent">
                                b) Account Security. Maintaining Account security is very important. You are solely responsible for maintaining the confidentiality of your Account password. You agree to notify Duro Labs immediately if you become aware of any unauthorized use of your password or of your Account.
                            </p>

                            <p className="indent">
                                c) Account Sharing or Transfers. Accounts are registered to you personally and may not be sold, traded, gifted or otherwise transferred at any time under any circumstances. You may not share your Account with, or disclose your password to, anyone else.
                            </p>

                            <p className="indent">
                                d) Cancellation by You. You have the right to cancel your Account at any time by contacting us via email at info@durolabs.co. Cancellation will be effective on the last day of the period for which the most recent Fee has been paid.
                            </p>

                            <p className="indent">
                                e) Termination by Duro Labs. Duro Labs may at any time terminate your Account if:

                                <p className="indent">
                                    i) Duro Labs determines that you are (A) in breach of or otherwise acting inconsistently with this ToU or (B) engaging in fraudulent or illegal activities or other conduct that may result in liability to Duro Labs;
                                </p>

                                <p className="indent">
                                    ii) Duro Labs determines it is required by law to terminate your Account; or
                                </p>
                                <p className="indent">
                                    iii) Duro Labs decides to stop providing the SaaS Services or critical portions of the SaaS Services.
                                </p>
                            </p>

                            <p className="indent">
                                f) Effect of Account Termination or Cancellation. Upon cancellation or any termination of your Account, all rights and licenses granted to Licensee hereunder shall immediately terminate and revert to Duro Labs and Licensee shall cease all use and refrain from all further use of the Application and the SaaS Services, except that Licensee shall continue to have access through the SaaS Services to data contained in the Application for a period of three (3) months following cancellation or termination.  Duro Labs shall not incur any liability whatsoever for any damage, loss or expenses of any kind that Licensee may suffer or incur arising from or incident to any termination of this Agreement by Duro Labs that complies with this Agreement, whether or not Duro Labs is aware of any such damage, loss or expenses.
                            </p>


                        <p>
                            3.4 Fees and Payments.
                        </p>

                            <p className="indent">
                                a) Subscription Fee.  In consideration of the SaaS Services provided by Duro Labs hereunder, you shall pay to Duro Labs a non-refundable subscription fee, payable either on a monthly or annual basis (unless otherwise agreed to through a mutually executed agreement between you and Duro Labs) (the “<em>Fee</em>”).  Duro Labs retains the right to modify the pricing of the SaaS Services including, without limitation, modification of any tier structures, Authorized Users, or Fees, at any time although Duro Labs will provide you prior notice of pricing changes.
                            </p>

                            <p className="indent">
                                b) Payment.  The first payment of the Fee shall be charged at the time the Account is created or, if applicable, an initial trial period has ended.  Each subsequent payment of the Fee shall be charged on the first day of the next billing period (monthly or annual) in which the SaaS Services will be provided.
                            </p>

                            <p className="indent">
                                c) Taxes.  The Fee includes, and you shall be responsible for the payment of, any applicable sales or use taxes or any value added or similar taxes payable with respect to the Application, the SaaS Services, or otherwise arising out of or in connection with this Agreement, other than taxes levied or imposed based upon Duro Labs’s personal property ownership or net income.
                            </p>

                        <p>
                            3.5 Submitted Information.
                        </p>

                            <p className="indent">
                                a) Duro Labs does not claim ownership of any information or materials you submit or make available through the SaaS Services (“<em>Submitted Information</em>”) which allows you to upload, display, store, edit, and share information.  With respect to such Submitted Information, you grant Duro Labs a perpetual, irrevocable, non-terminable, transferrable, worldwide, royalty-free, sublicensable, fully paid-up, non-exclusive and transferable license to (i) use, reproduce, display, and publicly perform Submitted Information or any part of Submitted Information in connection with providing the SaaS Services to Authorized Users in any and all media formats and through any and all media channels and (ii) use, reproduce and modify the Submitted Information and combine portions of the Submitted Information in which identifying characteristics of Licensee have been removed with other information and data collected by Duro Labs through the SaaS Services and perform analytics of this combined information for purposes relating to Duro Labs’ (and its successors’ and affiliates’) business. You also hereby grant each Authorized User a non-exclusive license to access your Submitted Information through the SaaS Services while the Submitted Information is made available through the SaaS Services. You hereby represent, warrant and covenant that any Submitted Information you provide does not include anything to which you do not have the full right to grant the license specified in this Section 3.5(a).
                            </p>

                            <p className="indent">
                                b) You acknowledge and agree that: (i) you are solely responsible for, and Duro Labs has no responsibility to you or any third party for any Submitted Information that you create, submit, post or publish through the SaaS Services; and (b) Duro Labs is not responsible for any Submitted Information that you may have access to through your use of the SaaS Services and all Submitted Information is the responsibility of the person from whom such Submitted Information originated.
                            </p>

                            <p className="indent">
                                c) You acknowledge that the Submitted Information is your sole responsibility. You are entirely responsible for the Submitted Information and agree, under no circumstances, will Duro Labs be liable in any way for Submitted Information including, but not limited to, any errors or omissions in any Submitted Information, or any loss or damage of any kind incurred as a result of the use or distribution of any Submitted Information transmitted or otherwise made available via the SaaS Services.
                            </p>

                        <p>
                            3.6 Data and Ownership.
                        </p>

                            <p className="indent">
                                a) Licensee Data.  As between Duro Labs and Licensee, Licensee shall exclusively own all rights, title and interest in and to all data and information submitted by Licensee (or its Authorized Users) in connection with its or their use of the SaaS Services and the Application (collectively, “<em>Licensee Data</em>”) and all intellectual property and proprietary rights anywhere in the world (“<em>Proprietary Rights</em>”) therein.  Duro Labs shall (i) acquire no rights in any Licensee Data contained in or used with the SaaS Services or the Application by virtue of this Agreement, and (ii) process Licensee Data only to provide the SaaS Services and the Application, or as otherwise instructed by Licensee, or as may be required or permitted by applicable law.
                            </p>

                            <p className="indent">
                                b) Duro Labs Data.  Duro Labs collects data and information regarding use of the SaaS Services and the Application (“<em>Duro Labs Data</em>”). You acknowledge and agree that (i) Duro Labs Data (as between you and Duro Labs), excluding any information that is linked to an identified or identifiable person (“<em>Personally Identifiable Information</em>”), is the sole and exclusive property of Duro Labs; and (ii) Duro Labs shall be entitled to use, reproduce, adapt, combine with other data, edit, re-format, generate, store, disclose and exploit any and all Duro Labs Data for any purpose.
                            </p>

                            <p className="indent">
                                c) Duro Labs Intellectual Property.  As between you and Duro Labs, the SaaS Services, the Application and the Documentation and all Proprietary Rights embodied therein (collectively, “<em>Duro Labs Intellectual Property</em>”) shall remain the sole and exclusive property of Duro Labs.  In addition, Duro Labs shall own any and all information, data and feedback concerning use or operation of the Application, and/or the SaaS Services and any and all modifications, design changes, features and improvements to the Application and/or the SaaS Services suggested by you or Licensee, or any of Licensee’s officers, directors, employees or agents, or any Authorized User (collectively, “<em>Feedback</em>”) and Duro Labs shall have the right to use, in any manner and for any purpose whatsoever, any and all Feedback.  You agree to assign and do hereby irrevocably assign to Duro Labs, and its successor and assigns, all rights, title and interest that you may acquire in and to any and all Duro Labs Intellectual Property and Feedback.  This Agreement shall not grant you or Licensee any ownership right or title of any kind in or to any Duro Labs Intellectual Property or Feedback.
                            </p>

                        <p>
                            3.7 Limited Warranty.  Duro Labs warrants to Licensee that the SaaS Services and the Application, when operated in accordance with the Documentation, will substantially perform the functions described in the Documentation.  These warranties extend only to Licensee.  EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION 3.7, THE SAAS SERVICES, THE APPLICATION, THE DOCUMENTATION AND ALL OTHER SERVICES AND PRODUCTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, WARRANTIES OR CONDITIONS OF SATISFACTORY QUALITY, CONDITION OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.  DURO LABS DOES NOT WARRANT THAT THE SAAS SERVICES WILL MEET LICENSEE’S REQUIREMENTS OR, EXCEPT AS PROVIDED IN HEREIN, THAT THE APPLICATION OR THE SAAS SERVICES WILL OPERATE UNINTERRUPTED, TIMELY, COMPLETELY SECURELY OR ERROR-FREE, OR THAT ANY ERROR IN THE SOFTWARE OR THE SAAS SERVICES WILL BE CORRECTED, OR THAT THE LICENSEE DATA STORED WITHIN THE APPLICATION OR SAAS SERVICES WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED.
                        </p>


                        <h2 className="section">4. Restrictions and Conditions of Use.</h2>
                        <p>
                            4.1. Accessing the Service. You agree not to access, or attempt to access, the Service by any means other than through the user interface provided through the Site. You specifically agree not to access, or attempt to access, the Service through any automated means (including, without limitation, through the use of scripts, bots, spiders or web crawlers).
                        </p>
                        <p>
                            4.2. No Violation of Laws. You agree that you will not, in connection with your use of the Service, violate any applicable law, ordinance, rule, regulation or treaty. Without limiting the foregoing, you agree that you will not make available through the Service any material or information that infringes any copyright, trademark, patent, trade secret, or other right of any party (including rights of privacy or publicity).
                        </p>

                        <p>
                            4.3. Use Restrictions. You may not connect to or use the Service in any way that is not expressly permitted by this ToU.
                        </p>

                            <p className="indent">
                                a.  You may not: (i) remove any proprietary notices from  the Service; (ii) cause, permit or authorize the modification, creation of derivative works, translation, reverse engineering, decompiling, disassembling or hacking of  the Service; (iii) sell, assign, rent, lease, act as a service bureau, or grant rights in the Service including, without limitation, through sublicense, to any other person or entity without the prior written consent of Duro Labs; or (iv) make any false, misleading or deceptive statement or representation regarding Duro Labs and the Service.
                            </p>
                            <p className="indent">
                                b.  Without limiting the foregoing, you agree that you will not: (i) institute, assist, or become involved in any type of attack including, without limitation, denial of service attacks, upon the Service (or any servers, systems or networks connected to the Service) or otherwise attempt to obstruct, disrupt or interfere with the operation of the Service or any other person’s or entity’s use of the Service (or any servers, systems or networks connected to the Service); (ii) attempt to gain unauthorized access to the Service, accounts registered to other users, or any servers, systems or networks connected to the Service; (iii) use  the Service for any commercial purpose unless consistent with this ToU and the intended use of the same, or for the benefit of any third party, or charge any person or entity, or receive any compensation, for such person’s or entity’s use of the Service, unless you are specifically authorized to do so in a separate written agreement with Duro Labs; (iv) use the Service to develop, generate, transmit or store information that is defamatory, harmful, abusive, obscene or hateful; or (v) use the Service to engage in any activity that violates any applicable law, ordinance, rule, regulation or treaty.
                            </p>
                            <p className="indent">
                                c.  Without limiting the foregoing, you agree that you will not use the Service for any other unlawful, prohibited, abnormal or unusual activity as determined by Duro Labs in its sole discretion.
                            </p>

                        <p>
                            4.4. No Data Mining or Harmful Code.

                            You agree that you will not (a) obtain or attempt to obtain any information from the Service including, without limitation, email addresses of other account holders or other software data; (b) intercept, examine or otherwise observe any proprietary communications protocol used by the Service, whether through the use of a network analyzer, packet sniffer or other device; or (c) use any type of bot, spider, virus, clock, timer, counter, worm, software lock, drop dead device, Trojan horse routine, trap door, time bomb or any other codes, instructions or third party software that is designed to provide a means of surreptitious or unauthorized access to, or distort, delete, damage or disassemble, the Service.
                        </p>

                        <p>4.5. Violation of this ToU.

                            You acknowledge and agree that you are solely responsible, and Duro Labs has no responsibility or liability to you or any other person or entity, for any breach by you of this ToU or for the consequences of any such breach. Duro Labs may at its option, terminate its relationship with you, or may suspend your account immediately if it determines you are using the Service contrary to the restrictions found in this Section 4 or any other terms of this ToU.
                        </p>

                        <h2 className="section">5. Integration with Third Party Websites.</h2>
                        <p>
                            The Service may enable you to interface through an API with third party websites to obtain data or information relevant to your business. Duro Labs provides these integrations with other websites as a convenience. These websites are not under the control of Duro Labs and Duro Labs is not responsible for the data or information provided by these websites. Such integrations do not imply Duro Labs’ endorsement of any such website and Duro Labs disclaims all liability with regard to the data and information obtained from such websites.
                        </p>

                        <h2 className="section">6. Intellectual Property.</h2>
                        <p>
                            6.1. Trademarks. The Duro Labs name and logo are trademarks and service marks of Duro Labs. Unless permitted in a separate written agreement with Duro Labs, you do not have the right to use any of Duro Labs’ trademarks, service marks or logos and your unauthorized use of any of these may be a violation of federal and state trademark laws.
                        </p>
                        <p>
                            6.2. Ownership. You acknowledge and agree that Duro Labs, or its licensors, owns all right, title and interest in and to the Service, including all intellectual property, industrial property and proprietary rights recognized anywhere in the world at any time and that the Service is protected by U.S. and international copyright laws. Further, you acknowledge that the Service may contain information that Duro Labs has designated as confidential and you agree not to disclose such information without Duro Labs’ prior written consent.
                        </p>

                        <h2 className="section">7. Privacy Policy.</h2>
                        <p>
                            By accepting this ToU or using the Service, you represent that you have read and consent to our Privacy Policy in addition to this ToU. Duro Labs may revise the Privacy Policy at any time, and a link to the new versions will be posted on the Site. If at any point you do not agree to any portion of the Privacy Policy, you must immediately stop using the Service. By using the Service, you agree to the then-current versions of the ToU and Privacy Policy.
                        </p>

                        <h2 className="section">8. Location.</h2>
                        <p>
                            The Service is operated by Duro Labs in the United States. If you choose to access the Service from a location outside of the United States, you do so on your own initiative and you are responsible for compliance with applicable local laws.
                        </p>


                        <h2 className="section">9. DISCLAIMER OF WARRANTIES.</h2>
                        <p>
                            9.1. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK AND THAT THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                        </p>
                        <p>
                            9.2. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DURO LABS EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS INCLUDING, WITHOUT LIMITATION, WARRANTIES AND CONDITIONS OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE.
                        </p>
                        <p>
                            9.3. DURO LABS MAKES NO WARRANTY AS TO THE ACCURACY, COMPLETENESS OR RELIABILITY OF ANY MATERIALS, INFORMATION OR DATA AVAILABLE THROUGH, OR THE PERFORMANCE OF THE SERVICE.
                        </p>
                        <p>
                            9.4. DURO LABS DOES NOT REPRESENT OR WARRANT THAT (a) YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; (b) THAT OPERATION OF THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (c) YOUR USE OF THE SERVICE WILL MEET YOUR REQUIREMENTS; (d) DEFECTS IN THE OPERATION OF THE SERVICE WILL BE CORRECTED; OR (e) THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                        </p>
                        <p>
                            9.5. YOU ACKNOWLEDGE AND AGREE THAT ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR OTHER DEVICE OR ANY LOSS OF DATA RESULTING FROM DOWNLOADING OR OBTAINING SUCH MATERIAL.
                        </p>
                        <p>
                            Because some states or jurisdictions do not allow the disclaimer of implied warranties, the foregoing disclaimers may not apply to you.
                        </p>

                        <h2 className="section">10. LIMITATION OF LIABILITY; SOLE AND EXCLUSIVE REMEDY.</h2>
                        <p>10.1.  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DURO LABS, AND ITS AFFILIATES, LICENSORS AND BUSINESS PARTNERS (COLLECTIVELY, THE “RELATED PARTIES”), SHALL NOT BE LIABLE TO YOU, UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY, FOR:
                        </p>
                            <p className="indent">
                                a.  ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES, INCLUDING LOSS OF PROFITS, USE, DATA OR GOODWILL, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE SERVICE, EVEN IF DURO LABS OR THE RELATED PARTIES HAVE BEEN ADVISED OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES OR DAMAGES;
                            </p>
                            <p className="indent">
                                b.  THE COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY; OR
                            </p>
                            <p className="indent">
                                c.  THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE ANY MATERIALS, INFORMATION OR DATA MAINTAINED BY OR THROUGH YOUR USE OF THE SERVICE.
                            </p>

                        <p>
                            10.2.  WITHOUT LIMITING THE FOREGOING, IN NO CASE SHALL THE LIABILITY OF DURO LABS OR ANY OF THE RELATED PARTIES EXCEED THE GREATER OF (a) THE AGGREGATE AMOUNT PAID BY LICENSEE OR (b) ONE HUNDRED DOLLARS ($100).
                        </p>
                        <p>
                            Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, the liability of Duro Labs and the Related Parties shall be limited to the fullest extent permitted by law.
                        </p>

                        <h2 className="section">11. Indemnification.</h2>
                        <p>
                            You agree to defend, indemnify and hold Duro Labs and the Related Parties harmless from and against any and all claims, demands, liabilities damages and losses including, without limitation, reasonable attorneys’ fees, resulting from or arising out of (a) your use of the Service; or (b) your breach of this ToU or any other policies that Duro Labs may issue for the Service from time to time.
                        </p>

                        <h2 className="section">12. Governing Law; Jurisdiction.</h2>
                        <p>
                            This ToU is governed by California law, without regard to conflict of laws principles. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. You and Duro Labs agree that the state and federal courts located in the County of Los Angeles, California will have exclusive jurisdiction of all disputes arising out of or related to this ToU or your use of the Site or the Service and agree to submit to the personal jurisdiction and venue of these courts. Notwithstanding the foregoing, Duro Labs shall be allowed to apply for equitable remedies (including injunctions) in any jurisdiction.
                        </p>

                        <h2 className="section">13. General.</h2>
                        <p>
                            13.1. ToU Revisions. This ToU may only be revised in a writing signed by Duro Labs, or published by Duro Labs on the Site.
                        </p>
                        <p>
                            13.2. No Partnership. You agree that no joint venture, partnership, employment, or agency relationship exists between you and Duro Labs as a result of this ToU or your use of the Service.
                        </p>
                        <p>
                            13.3. Assignment. Duro Labs may assign its rights under this ToU to any person or entity without your consent. The rights granted to you under this ToU may not be assigned without Duro Labs’ prior written consent, and any attempted unauthorized assignment by you shall be null and void.
                        </p>
                        <p>
                            13.4. Severability. If any part of this ToU is determined to be invalid or unenforceable, then that portion shall be severed, and the remainder of the ToU shall be given full force and effect.
                        </p>
                        <p>
                            13.5. Attorneys’ Fees. In the event any litigation or arbitration is brought by either party in connection with this ToU the prevailing party shall be entitled to recover from the other party all the reasonable costs, attorneys’ fees and other expenses incurred by such prevailing party in the litigation.
                        </p>
                        <p>
                            13.6. No Waiver. Our failure to enforce any provision of this ToU shall in no way be construed to be a present or future waiver of such provision, nor in any way affect our right to enforce the same provision at a later time. An express waiver by Duro Labs of any provision, condition or requirement of this ToU shall not be understood as a waiver of your obligation to comply with the same provision, condition or requirement at a later time.
                        </p>
                        <p>
                            13.7. Notices. All notices given by you or required under this ToU shall be in writing and sent to info@durolabs.co.
                        </p>
                        <p>
                            13.8. U.S. Government Rights. If you are, or are entering into this ToU on behalf of, any agency or instrumentality of the United States Government, all software provided by Duro Labs is “commercial computer software” and “commercial computer software documentation,” and pursuant to FAR 12.212 or DFARS 227.7202, and their successors, as applicable, use, reproduction, and disclosure of such software are governed by the terms of this ToU.
                        </p>
                        <p>
                            13.9. Export Administration. You must comply with all export laws and regulations of the United States or any other country (“Export Controls”) and you shall not export, direct or transfer any portion of the Service, or any direct product thereof, to any destination, person or entity restricted or prohibited by the Export Controls.
                        </p>
                        <p>
                            13.10. Equitable Remedies. You acknowledge and agree that Duro Labs would be irreparably damaged if the terms of this ToU were not specifically enforced, and therefore you agree that we shall be entitled, without bond, other security, or proof of damages, to appropriate equitable remedies with respect to breaches of this ToU, in addition to such other remedies as we may otherwise have available to us under applicable laws.
                        </p>
                        <p>
                            13.11. Entire Agreement. This ToU, including the documents referenced in this ToU, constitutes the entire agreement between you and Duro Labs with respect to the Service and between Licensee and Duro Labs with respect to the SaaS Services and the Application and supersedes any and all prior agreements relating to the same subject matter.
                        </p>
                    </div>
                </div>
            </div>

        return markup
    }
}

export default Terms
