import React, { Component }      from "react"

class ProgressCirlce  extends Component
{
    render()
    {
        const circleStyle = {
          strokeDasharray: this.props.progressSoFar ? this.props.progressSoFar : 1000 ,
          strokeDashoffset: 1000
        };

        const svgStyle = {
            transform: 'rotate(-90deg)'
        }

        return (
            <svg id="progress-icon-svg" width="16px" height="17px" viewBox="0 0 15 15" style={svgStyle} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="3-documents" transform="translate(-1354.000000, -725.000000)">
                        <g id="TABLE" transform="translate(314.000000, 645.000000)">
                            <g id="SPINNER" transform="translate(1040.000000, 80.000000)">
                                <g transform="translate(0.833333, -2.500000)" id="Oval">
                                    <circle stroke="#888888" cx="6.66666667" cy="10" r="6.66666667"></circle>
                                    <circle className="fill-bar" style={circleStyle} stroke="#3CD1B5" strokeWidth="3" cx="6.66666667" cy="10" r="5.83333333"></circle>
                                    <ellipse stroke="#3CD1B5" strokeWidth="2" cx="-0.416666667" cy="-121.666667" rx="5.41666667" ry="5.83333333"></ellipse>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )

    }
}

export default ProgressCirlce;

