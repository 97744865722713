import { DuroModal, DuroModalProps } from "common/components/modals/duroModal";
import { ButtonFooter } from "../buttonFooter.component";
import { Typography, Box } from "@mui/material";
import { SaveAsNewTemplate } from "./save-new-template.component";
import { sdk } from "../../../sdk";
import { useState } from "react";
import { useCreateApproverTemplate } from "../../../hooks/use-create-approver-template";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_CHANGE_ORDER_APPROVERS_TEMPLATES,
  UPDATE_CHANGE_ORDER_APPROVERS_TEMPLATE,
} from "graphql/query/changeOrdersQueries";
import { TemplateDropdown } from "../dropdown/templates-dropdown.component";
import { ChangeOrderUser } from "design/models/changeorder";

export const useUpdateApproverTemplate = () => {
  const [updateTemplateMutation, { loading, error }] = useMutation(
    UPDATE_CHANGE_ORDER_APPROVERS_TEMPLATE
  );

  const updateTemplate = async (templateData: {
    id: string;
    isPublic: boolean;
    approvalType: string;
    approversList: string[];
    notifiersList: string[];
    externalUsers: string[];
  }) => {
    try {
      const { data } = await updateTemplateMutation({
        variables: { input: templateData },
      });
      return data.updateApproverTemplate;
    } catch (err) {
      console.error("Error updating template:", err);
      throw err;
    }
  };

  return { updateTemplate, loading, error };
};

export const SaveUpdateTemplatesModal: React.FC<DuroModalProps> = ({
  open,
  onClose,
  ariaLabelledby,
  ariaDescribedby,
  refetch,
}) => {
  const [newTemplateName, setNewTemplateName] = useState(""); // save as new template
  const [selectedTemplate, setSelectedTemplate] = useState(""); // save to existing template
  const [isPublic, setIsPublic] = useState(false);
  const [approvalType, setApprovalType] = useState("");
  const [approversList, setApproversList] = useState<ChangeOrderUser[]>([]);

  const {
    createTemplate,
    loading: createLoading,
    error: createError,
  } = useCreateApproverTemplate();
  const { data: templateData, loading: templateLoading } = useQuery(
    GET_CHANGE_ORDER_APPROVERS_TEMPLATES
  );
  const {
    updateTemplate,
    loading: updateLoading,
    error: updateError,
  } = useUpdateApproverTemplate();

  const handleSaveTemplate = async () => {
    const approvalType = sdk.storage.form.approvalType.getState();
    const approvers = sdk.storage.form.approverList.getState();
    const coInternalNotifyUsers = sdk.storage.coInternalNotifyUsers.getState();
    const coExternalNotifyUserEmails =
      sdk.storage.coExternalNotifyUserEmails.getState();

    if (selectedTemplate) {
      // Update existing template
      await updateTemplate({
        id: selectedTemplate,
        isPublic: isPublic,
        approvalType: approvalType,
        approversList: approversList.map(({ id }) => id),
        notifiersList: coInternalNotifyUsers.map(({ id }) => id),
        externalUsers: coExternalNotifyUserEmails,
      });
    } else {
      // Create new template
      await createTemplate({
        templateName: newTemplateName,
        isPublic,
        approvalType,
        approversList: approvers.map(({ id }) => id),
        notifiersList: coInternalNotifyUsers.map(({ id }) => id),
        externalUsers: coExternalNotifyUserEmails,
      });
    }
    if (refetch) await refetch();
    onClose();
  };

  const isLoading = createLoading || updateLoading;
  const error = createError || updateError;

  return (
    <DuroModal
      header="Save or Update Templates"
      open={open}
      onClose={onClose}
      showCloseButton={false}
      footer={
        <ButtonFooter
          onCancelClick={onClose}
          onConfirmClick={handleSaveTemplate}
          cancelDisabled={isLoading}
          confirmDisabled={isLoading || (!newTemplateName && !selectedTemplate)}
          cancelButtonText="Cancel"
          confirmButtonText={selectedTemplate ? "Update" : "Save"}
          confirmColor="primary"
          cancelColor="info"
        />
      }
      ariaLabelledby={ariaLabelledby}
      ariaDescribedby={ariaDescribedby}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {templateLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Typography>Save to an Existing Template</Typography>
            <TemplateDropdown
              data={templateData.changeOrderApprovalTemplates}
              onSelect={(templateId: string) => {
                setSelectedTemplate(templateId);
                setApprovalType(sdk.storage.form.approvalType.getState());
                setApproversList(sdk.storage.form.approverList.getState());
              }}
            />
          </>
        )}
        <Typography variant="body2" sx={{ fontStyle: "italic" }}>
          OR
        </Typography>
        <SaveAsNewTemplate
          templateName={newTemplateName}
          setTemplateName={setNewTemplateName}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
        />
        {error && <Typography color="error">{error.message}</Typography>}
      </Box>
    </DuroModal>
  );
};
