import { SvgIcon } from "@mui/material";

export const SourcingIcon = () => (
  <SvgIcon fontSize="inherit" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path d={`M7.533 14.437a6.307 6.307 0 0 1-2.428-.607 6.663 6.663 0 0 1-1.968-1.418 6.609 
      6.609 0 0 1-1.32-2.038 6.38 6.38 0 0 1-.484-2.48c0-.908.173-1.76.517-2.559a6.625 6.625 
      0 0 1 1.402-2.083A6.625 6.625 0 0 1 5.335 1.85a6.388 6.388 0 0 1 2.558-.517c1.63 
      0 3.045.514 4.248 1.542 1.203 1.028 1.94 2.318 2.214 3.87h-1a5.573 5.573 0 0 
      0-3.379-4.083v.295c0 .383-.131.716-.393 1a1.265 1.265 0 0 1-.968.427H7.188V5.81a.57.57 
      0 0 1-.221.455.762.762 0 0 1-.5.184H5.105v1.443H6.91v2.05H5.811L2.465 6.598a4.599 4.599 
      0 0 0-.115.65c-.022.215-.033.43-.033.645 0 1.476.498 2.75 1.493 3.822.995 1.071 2.236 
      1.65 3.723 1.738v.984zm6.428-.426-2.197-2.198a2.74 2.74 0 0 1-.746.377 2.63 2.63 0 0 
      1-.821.131 2.698 2.698 0 0 1-1.98-.813A2.688 2.688 0 0 1 7.4 9.53c0-.775.272-1.433.814-1.974a2.694 
      2.694 0 0 1 1.976-.812c.775 0 1.433.272 1.975.816.54.543.811 1.203.811 1.98 
      0 .28-.046.553-.139.82a3.635 3.635 0 0 1-.369.763l2.198 2.181-.706.706zm-3.774-2.674c.505 
      0 .932-.174 1.282-.522.35-.348.524-.775.524-1.28 0-.504-.174-.93-.522-1.28a1.736 1.736 0 
      0 0-1.28-.526c-.504 0-.93.175-1.28.523-.35.348-.526.775-.526 1.28 0 .504.175.93.523 
      1.28.348.35.775.525 1.28.525z`} fill-rule="evenodd"/>
  </SvgIcon>
);
