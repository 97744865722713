import { Box, Typography, styled } from "@mui/material";
import { TileContentDirection } from "./consts";
import { colorPalette } from "@duro/themes";

export const TileContentLabelStyles = styled(Typography)({
  color: colorPalette.spanishGray,
  display: "inline-block",
  flexShrink: 0,
  fontSize: "0.875rem",
  marginBottom: 0,
  paddingRight: "0.5rem",
});

export function TileContentLabel({ label }: { label?: string }) {
  if (!label) return null;

  return (<TileContentLabelStyles>{label}:</TileContentLabelStyles>);
}

export const TileContentWrapper = styled(Box)<{ direction?: TileContentDirection }>(({
  direction = TileContentDirection.ROW,
}) => ({
  alignItems: direction === TileContentDirection.COLUMN ? "flex-start" : "center",
  display: "flex",
  flexDirection: direction,
  justifyContent: "space-between",
  marginBottom: "0.5rem",
  overflow: "hidden",
}));
