import { BookmarkIcon, FilterIcon, SerializationIcon, SwitchWorkspaceIcon } from "assets/icons";
import { BuildPageWrapper } from "build/components/pageWrapper";
import { SerializationModal } from "build/components/serializationModal";
import { SourceType } from "build/models";
import { BreadCrumbIcon, useBreadCrumb } from "common/components/breadcrumb";
import { StatusField } from "@duro/base/status-field";
import {
  TileConfiguration, TileContentConfigProps, TileContentType, TileContentWrapper, TileValueFormat, TilesGrid,
} from "common/components/tile";
import { useToasts } from "common/components/toasts";
import { IToolbarActions } from "common/components/toolbar";
import { ModelType } from "@duro/utils";
import { useUpdateProductions } from "graphql/mutation/productionMutations";
import { useProductionWithRevisions } from "graphql/query/productionQueries";
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface BuildProductionRouteParams {
  productionId: string
}

export function BuildProduction() {
  const history = useHistory();
  const { productionId } = useParams<BuildProductionRouteParams>();
  const { enqueueToast } = useToasts();
  const [displaySerializationModal, setDisplaySerializationModal] = useState(false);

  const updateProductions = useUpdateProductions();
  const { production, productionError, productionLoading } = useProductionWithRevisions(productionId);

  useEffect(() => {
    if (productionError?.message) {
      enqueueToast({
        body: `Lot ID ${productionId}`,
        message: "Failed to load the Production",
        persist: true,
        variant: "error",
      });
    }
  }, [enqueueToast, productionError?.message, productionId]);

  const breadcrumbLabel = useMemo(() => (
    production?.primaryRevision?.name ? `${production?.cpn} - ${production?.primaryRevision?.name}` : ""
  ), [production?.cpn, production?.primaryRevision?.name]);

  const breadCrumbOptions = useBreadCrumb({
    icon: BreadCrumbIcon.PRODUCTS,
    label: breadcrumbLabel,
    url: history.location.pathname,
  });

  const toggleSerializationModal = useCallback(() => (
    setDisplaySerializationModal((displayModal: boolean) => !displayModal)
  ), []);

  const onSubmitSerializationModal = useCallback(() => {
    setDisplaySerializationModal(false);
  }, []);

  const onViewInDesignClicked: MouseEventHandler = useCallback(e => {
    let url = "";

    switch (production?.source.type) {
      case SourceType.DURO_COMPONENT:
        url = `/component/view/${production.source.extId}`;
        break;
      case SourceType.DURO_PRODUCT:
        url = `/product/view/${production.source.extId}`;
        break;
      default:
        url = production?.source.url ?? "";
    }

    if (url) history.push(url);
  }, [history, production?.source]);

  const isBookmarked = production?.isBookmarked;
  const onBookmarkClicked: MouseEventHandler = useCallback(() => {
    updateProductions([{ id: productionId, isBookmarked: !isBookmarked }])
      .catch(e =>
        enqueueToast({
          body: e.message,
          message: "Updating bookmarked status failed.",
          persist: true,
          variant: "error",
        }));
  }, [enqueueToast, isBookmarked, productionId, updateProductions]);

  const onFilterClicked: MouseEventHandler = useCallback(e => {
    // Call update production to toggle the bookmarked state of the production.
    // This will place the anchor CPN associated with the lot on the Productions Dashboard
  }, []);

  const toolbarActionItems: IToolbarActions[] = useMemo(() => ([
    {
      Icon: SwitchWorkspaceIcon,
      onClick: onViewInDesignClicked,
      label: "View in Design",
    },
    {
      active: isBookmarked,
      Icon: BookmarkIcon,
      onClick: onBookmarkClicked,
      label: "Bookmark",
    },
    {
      disabled: true,
      Icon: FilterIcon,
      onClick: onFilterClicked,
      label: "Filters",
    },
    {
      Icon: SerializationIcon,
      label: "Serialize",
      onClick: () => toggleSerializationModal(),
    },
  ]), [isBookmarked, onBookmarkClicked, onFilterClicked, onViewInDesignClicked, toggleSerializationModal]);

  // ToDo: Use the build information to serialize, instead of design information
  const getPayloadForSerialization = useMemo(() => ({
    alias: production?.primaryRevision?.source?.type === SourceType.DURO_PRODUCT_REVISION
      ? ModelType.PRD : ModelType.CMP,
    id: production?.primaryRevision?.source?.extId,
  }), [production?.primaryRevision?.source]);

  const tilesData = useMemo(() => production?.revisions?.map(revision => ({
    revision,
    lotsLink: `/build/lots?revId=${revision.id}`,
    instancesLink: `build/instances?revId=${revision.id}`,
  })) ?? [], [production?.revisions]);

  return (
    <BuildPageWrapper
      breadCrumbOptions={breadCrumbOptions}
      isLoading={productionLoading}
      helmetTitle="Build Production"
      toolbarProps={{
        toolbarItems: toolbarActionItems,
      }}
    >
      <TilesGrid config={tileConfig} tilesData={tilesData} />
      {displaySerializationModal && (
        <SerializationModal
          itemToBeSerialized={getPayloadForSerialization}
          onCancel={toggleSerializationModal}
          onClose={toggleSerializationModal}
          onSubmitCallback={onSubmitSerializationModal}
          open={displaySerializationModal}
          title="Serialize and Create New Lot"
        />
      )}
    </BuildPageWrapper>
  );
}

function RevisionTileHeader({ data }: TileContentConfigProps) {
  return (
    <TileContentWrapper>
      Revision:
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flex: 1,
          paddingLeft: "0.25rem",
        }}
      >{data?.revision?.revisionValue} <StatusField label={data?.revision?.status} /></span>
    </TileContentWrapper>
  );
}

// TODO: Get this configuration coming from the server.
const tileConfig: TileConfiguration = {
  content: [
    {
      component: RevisionTileHeader,
      type: TileContentType.CUSTOM,
    },
    {
      type: TileContentType.DIVIDER,
    },
    {
      type: TileContentType.LINK_IF_VALUE,
      label: "Lots",
      label2Path: "revision.lotCount",
      valuePath: "lotsLink",
    },
    {
      type: TileContentType.LINK_IF_VALUE,
      label: "Instances",
      label2Path: "revision.instanceCount",
      valuePath: "instancesLink",
    },
    {
      type: TileContentType.TEXT,
      label: "Last Modified",
      valuePath: "revision.lastModifiedDate",
      valueFormat: TileValueFormat.DATE,
    },
  ],
  imageAlt: "Revision's Primary Image",
  imageIdPath: "revision.images.0.id",
};
