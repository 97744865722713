import React, { useMemo } from 'react';
import { TransactionalModal } from 'common/components/modals';
import { Box,styled } from '@mui/material';

const RevertBackModal = (props) => {
    const {
        components,
        products,
        onCancel,
        onConfirm,
    } = props;

    const mappedComponents = useMemo(() => components?.map(({ name, cpn, _id }) => (
        <div key={_id + "-deleted-items-list"} className="item-row">
            <span className="muted-text">{cpn + " "}</span>
            <span>{name}</span>
        </div>
    )), [components]);

    const mappedProducts = useMemo(() => products?.map(({ name, cpn, _id }) => (
        <div key={_id + "-deleted-items-list"} className="item-row">
            <span className="muted-text">{cpn + " "}</span>
            <span>{name}</span>
        </div>
    )), [products]);

    return (
        <TransactionalModal
            onClose={onCancel}
            onCancel={onCancel}
            onAccept={onConfirm}
            open={true}
            title="Are You Sure You Want to Revert Back?"
        >
            <TextWrapper searchPage={ mappedComponents?.length || mappedProducts?.length}>
                All modified changes will be lost and will revert back to its last Revision.
                This can't be undone.
            </TextWrapper>
            <Box>
                {mappedComponents}
                {mappedProducts}
            </Box>
        </TransactionalModal>
    )
}

export default RevertBackModal

export const TextWrapper = styled(Box)(({ searchPage }) => ({
    marginBottom: searchPage ? "1.5rem" : "0rem",
}))
