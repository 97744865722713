import { gql } from "@apollo/client";
import {
  BaseProductFragment,
  ProductFragment,
  ProductLastReleasedRevisionFragment,
} from "./productFragment";

// TODO: Endless scrolling is pending
export const ProductsFragment = gql`
  fragment productsFragment on Products {
    connection(after: $endCursor, first: $pageSize) {
      edges {
        node {
          ...baseProductFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${BaseProductFragment}
`;

export const ProductsLastReleasedRevisionFragment = gql`
  fragment productsLastReleasedRevisionFragment on Products {
    connection(after: $endCursor, first: $pageSize) {
      edges {
        node {
          ...productLastReleasedRevisionFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${ProductLastReleasedRevisionFragment}
`;

