import "./index.scss"
import React, { Component }             from "react"
import { connect }                      from 'react-redux';
import ChangeOrderForm                  from "../modules/co-form"
import buildAction                      from "../../../../helpers/buildAction"
import CO                               from '../../../../action-types/changeorder';
import Utils                            from "../../../../modules/utils";
import {Helmet}                         from "react-helmet";

export class EditChangeOrder extends Component
{

    constructor(props)
    {
        super(props);
        this.autoSaveChangeOrder = this.autoSaveChangeOrder.bind(this);
    }


    componentWillMount()
    {
        const {dispatch} = this.props;
        const id = this.props.match.params.id;
        let payload = { id, history: this.props.history, editMode: true , currentUserId: this.props.user.data._id, currentUserRole:this.props.user.data.role};
        dispatch(buildAction(CO.RESET_CO_FORM));
        dispatch(buildAction(CO.FIND_CO, payload ));
        window.addEventListener('beforeunload', this.showWarning);
    }

    showWarning(e)
    {
        var message = 'Changes you have made might not be saved.';
        e.returnValue = message;
        return message;
    }

    autoSaveChangeOrder()
    {
        const {dispatch} = this.props;
        let newPageState = this.props.changeorders.changeForm
        let {co} = this.props.changeorders.changeForm
        dispatch(buildAction(CO.AUTO_SAVE_IN_DRAFT, {co, newPageState}))
    }

    componentWillUnmount()
    {
        this.autoSaveChangeOrder();
        window.removeEventListener('beforeunload', this.showWarning);
    }

    render()
    {
        let {changeForm} = this.props.changeorders
        let { co }  = changeForm
        if(!co)
        {
            return null;
        }

        let { inputs, list, searchResults } = this.props.changeorders.changeForm

        let markup =
            <div>
                <Helmet>
                    <title>{Utils.makeTitle("["+ co.con + "] (EDIT) "+co.name)}</title>
                </Helmet>
                <ChangeOrderForm
                    mode="edit"
                    co={co}
                    changeorders={this.props.changeorders}
                    changeForm={changeForm}
                    inputs={inputs}
                    list={list}
                    searchResults={searchResults}
                    history={this.props.history}
                    dispatch={this.props.dispatch}
                    user={this.props.user}
                    users={this.props.users}
                    tabsType="cmp"
                />
            </div>
        return markup
    }
}

const mapDispatchToProps = dispatch => {
  return {dispatch}
};

const mapStateToProps = state => ({
    changeorders: state.changeorders,
    user: state.user,
    users: state.users
})

export default connect(mapStateToProps, mapDispatchToProps)(EditChangeOrder)
