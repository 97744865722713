import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Checkbox,
  ListItem,
  Box,
  SxProps,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import { CheckboxFilledIcon } from "assets/icons";

import { FixedSizeList, ListChildComponentProps } from "react-window";
import { GridValidRowModel } from "@mui/x-data-grid-pro";
import { debounce } from "lodash";

import styled from "@emotion/styled";

export type Data<T> = T[];
export type Excluded<T> = T[];

// Default function to get row ID
const defaultGetRowId = <T extends GridValidRowModel>(row: T): string =>
  row?.id;

export function isDisabled<T extends GridValidRowModel>(
  id: string,
  data: Data<T>,
  excluded: Excluded<T>
): boolean {
  return (
    excluded.some((item) => item.id === id) &&
    data.some((item) => item.id === id)
  );
}

export function isChecked<T extends GridValidRowModel>(
  id: string,
  data: Data<T>,
  excluded: Excluded<T>,
  selected: string[]
): boolean {
  return (
    (excluded.some((item) => item.id === id) &&
      data.some((item) => item.id === id)) ||
    selected.includes(id)
  );
}

export function handleCheckboxClickPure(
  id: string,
  selected: string[]
): string[] {
  if (selected.includes(id)) {
    return selected.filter((item) => item !== id);
  } else {
    return [...selected, id];
  }
}

export function addSelectedToExcludedPure<T extends GridValidRowModel>(
  selected: string[],
  excluded: Excluded<T>,
  data: Data<T>
): Excluded<T> {
  const selectedItems = selected.map(
    (id) => data.find((item) => item.id === id)!
  );
  return [...excluded, ...selectedItems];
}

export function getCheckboxPropsPure<T extends GridValidRowModel>(
  id: string,
  data: Data<T>,
  excluded: Excluded<T>,
  selected: string[],
  onClick: (id: string) => void
) {
  return {
    disabled: isDisabled(id, data, excluded),
    checked: isChecked(id, data, excluded, selected),
    onClick: () => onClick(id),
  };
}

export function isIndeterminate(
  checkedNodes: string[],
  allNodes: string[]
): boolean {
  const checkedCount = checkedNodes.length;
  return checkedCount > 0 && checkedCount < allNodes.length;
}

export interface DuroVirtualColumn<T> {
  field: keyof T;
  headerName: string;
  fieldPath?: (row: T) => any;
  renderCell: (row: T) => React.ReactNode;
  width?: number; // Width of the column in pixels
}

export interface DuroVirtualListProps<T extends GridValidRowModel> {
  data: Data<T>;
  excluded: Excluded<T>;
  columns: DuroVirtualColumn<T>[];
  listStyle?: React.CSSProperties;
  loadMoreData?: () => void; // Callback to load more data
  hasNextPage?: boolean; // Indicator if there are more pages to load
  rowHeight?: number; // Height of each row
  totalHeight?: number; // Total height of the list
  totalWidth?: number; // Total width of the list
  onSelectionChange: (selection: string[], data: T[]) => void; // Callback for selection changes
  getRowId?: (row: T) => string; // Function to get the row ID, optional with default
  checkboxStyles?: SxProps;
  columnStyles?: SxProps;
  loading?: boolean; // Parent controlled loading state
  totalCount?: number;
  error?: string;
  name?: string;
  handleAddButtonClick?: () => void;
  headerSlot?: React.ReactNode;
}

export const DuroVirtualList = <T extends GridValidRowModel>({
  data: externalData,
  excluded: externalExcluded,
  columns,
  listStyle,
  loadMoreData,
  hasNextPage,
  rowHeight = 46, // Default row height
  totalWidth = 500, // Default total width
  totalHeight = 500, // Default total height
  onSelectionChange,
  getRowId = defaultGetRowId, // Use default function if not provided
  columnStyles = {
    paddingLeft: "15px",
  },
  checkboxStyles = {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loading = false,
  error,
  headerSlot,
  totalCount
}: DuroVirtualListProps<T>) => {
  const [data, setData] = useState<Data<T>>(externalData);
  const [excluded, setExcluded] = useState<Excluded<T>>(externalExcluded);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<T[]>([]);
  const [lastSelectedIndex, setLastSelectedIndex] =
    useState<number | null>(null);

  const dataMapped: Record<string, T> = data.reduce(
    (acc, item) => ({ ...acc, [getRowId(item)]: item }),
    {}
  );

  useEffect(() => {
    setData(externalData);
  }, [externalData]);

  useEffect(() => {
    setExcluded(externalExcluded);
    setSelected((prevSelected) =>
      prevSelected.filter((id) => !isDisabled(id, data, externalExcluded))
    );
  }, [externalExcluded, data]);

  const debouncedSelectionChange = useMemo(
    () => debounce(onSelectionChange, 300),
    [onSelectionChange]
  );

  useEffect(() => {
    debouncedSelectionChange(
      selected,
      selected.map((id) => dataMapped[id])
    );
  }, [selected, selectedData, debouncedSelectionChange]);

  const handleCheckboxHeaderClick = () => {
    const selectableData = data.filter(
      (item) => !isDisabled(getRowId(item), data, excluded)
    );

    const selectableIds = selectableData.map(getRowId);

    if (selected.length === selectableIds.length) {
      setSelected([]);
      setSelectedData([]);
    } else {
      setSelected(selectableIds);
      setSelectedData(selectableData);
    }
  };

  const handleCheckboxClick = useCallback(
    (id: string, index: number) => {
      const nextSelected = handleCheckboxClickPure(id, selected);
      setSelected(() => nextSelected);

      const nextSelectedData = nextSelected.map((id) => dataMapped[id]);

      setSelectedData(() => nextSelectedData);
      setLastSelectedIndex(index); // Reset last selected index to current index
    },
    [selected, data]
  );

  const handleShiftClick = useCallback(
    (index: number): void => {
      const currentId = getRowId(data[index]);

      if (lastSelectedIndex === null) {
        setSelected((prevSelected) => [...prevSelected, currentId]);
      } else {
        const start = Math.min(lastSelectedIndex, index);
        const end = Math.max(lastSelectedIndex, index);
        const range = Array.from(
          { length: end - start + 1 },
          (_, i) => start + i
        ).filter((i) => !isDisabled(getRowId(data[i]), data, excluded));

        setSelected((prevSelected) => {
          const allSelected = range.every((i) =>
            prevSelected.includes(getRowId(data[i]))
          );

          if (allSelected) {
            // Deselect the range
            return prevSelected.filter(
              (id) =>
                !range.includes(data.findIndex((item) => item.id === id)) ||
                id === currentId
            );
          } else {
            // Select the range
            return [
              ...new Set([
                ...prevSelected,
                ...range.map((i) => getRowId(data[i])),
              ]),
            ];
          }
        });
      }
      setLastSelectedIndex(index);
    },
    [lastSelectedIndex, data, excluded, getRowId]
  );

  const allDisabledAndChecked = data.every(
    (item) =>
      isDisabled(getRowId(item), data, excluded) &&
      isChecked(getRowId(item), data, excluded, selected)
  );

  const allChecked = data.every((item) =>
    isChecked(getRowId(item), data, excluded, selected)
  );

  const selectableIds = data
    .filter((item) => !isDisabled(getRowId(item), data, excluded))
    .map((item) => getRowId(item));
  const indeterminate = isIndeterminate(selected, selectableIds);

  const SelectAllFormControlLabel = styled(FormControlLabel)({
    margin: "0px",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "0.875rem",
    "& .MuiFormControlLabel-label": {
      fontSize: "14px",
    },
  });

  const Header = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: totalWidth,
        position: "sticky",
        top: 0,
        height: 35,
      }}
    >
      <Box sx={columnStyles}>
        <SelectAllFormControlLabel
          control={
            <Checkbox
              sx={checkboxStyles}
              disabled={allDisabledAndChecked}
              checked={allChecked || allDisabledAndChecked}
              checkedIcon={<CheckboxFilledIcon />}
              indeterminate={indeterminate}
              onChange={handleCheckboxHeaderClick}
            />
          }
          label="Select All"
        />
      </Box>
      {headerSlot}
    </Box>
  );

  const Row = ({ index, style }: ListChildComponentProps) => {
    if (index >= data.length) {
      return (
        <ListItem component="div" disablePadding style={style}>
          <CircularProgress size={24} />
        </ListItem>
      );
    }

    const item = data[index];

    return (
      <ListItem
        key={getRowId(item)}
        component="div"
        disablePadding
        style={style}
        sx={{
          border: `solid 1px rgba(136, 136, 136, 0.38)`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Box sx={columnStyles}>
            <Checkbox
              sx={checkboxStyles}
              checkedIcon={<CheckboxFilledIcon />}
              {...getCheckboxPropsPure(
                getRowId(item),
                data,
                excluded,
                selected,
                (id) => handleCheckboxClick(id, index)
              )}
              onClick={(event) => {
                if (event.shiftKey) {
                  handleShiftClick(index);
                } else {
                  handleCheckboxClick(getRowId(item), index);
                }
              }}
            />
          </Box>
          {columns.map((column) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flext-start",
                flex: `0 0 ${column.width}px`,
                marginLeft: "5px",
                fontSize: "0.875rem",
              }}
              key={String(column.field)}
            >
              {column.fieldPath
                ? column.renderCell(item)
                : column.renderCell(item)}
            </Box>
          ))}
        </Box>
      </ListItem>
    );
  };

  const handleItemsRendered = ({
    visibleStopIndex,
  }: {
    visibleStopIndex: number;
  }) => {
    if (visibleStopIndex >= data.length - 1 && hasNextPage && loadMoreData) {
      loadMoreData();
    }
  };

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <Header />

      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          marginTop: "12px",
          ...listStyle,
        }}
      >
        {data.length === 0 && totalCount === 0 && !loading ? (
          <Box
            sx={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              "& span": {
                width: "75%",
                textAlign: "center",
              },
            }}
          >
            <span>{error ? error : "No results found"}</span>
          </Box>
        ) : (
          <div style={{ position: "relative", height: "100%" }}>
            <FixedSizeList
              height={totalHeight}
              itemCount={data.length}
              itemSize={rowHeight}
              width={totalWidth}
              onItemsRendered={handleItemsRendered}
              style={{ overflowX: "hidden" }}
            >
              {Row}
            </FixedSizeList>
            {loading && (
              <Box
                sx={{
                  background: "rgba(18, 18, 18, 0.38)",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                  overflow: "hidden",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        )}
      </Box>
    </div>
  );
};
