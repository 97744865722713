import { Pane, TooltipText } from "./tooltip.styles";
import { ValidationTooltipType } from "./types";

export const InvalidStatusError: ValidationTooltipType = ({ row }) => {
  const alias = row.alias?.toLowerCase() === "prd" ? "Product" : "Component";

  return (
    <Pane>
      <TooltipText>
        {alias} must not have a Status of Design to submit in a Change Order for
        approval.
      </TooltipText>
    </Pane>
  );
};
