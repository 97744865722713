import { useMutation } from "@apollo/client";
import { CREATE_CHANGE_ORDER_APPROVERS_TEMPLATE } from "graphql/query/changeOrdersQueries";

export const useCreateApproverTemplate = () => {
  const [createTemplateMutation, { loading, error }] = useMutation(
    CREATE_CHANGE_ORDER_APPROVERS_TEMPLATE
  );

  const createTemplate = async ({
    templateName,
    isPublic,
    approvalType,
    approversList,
    notifiersList,
    externalUsers,
  }: {
    templateName: string;
    isPublic: boolean;
    approvalType: string;
    approversList: string[];
    notifiersList: string[];
    externalUsers: string[];
  }) => {
    try {
      const result = await createTemplateMutation({
        variables: {
          input: {
            templateName,
            isPublic,
            approvalType,
            approversList,
            notifiersList,
            externalUsers,
          },
        },
      });
      return result.data.createChangeOrderApprovalTemplate;
    } catch (err) {
      console.error("Error creating template:", err);
      throw err;
    }
  };

  return { createTemplate, loading, error };
};
