import {
  FC,
  ReactNode,
  useState,
  ChangeEvent,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import {
  Box,
  BoxProps,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useFamily } from "graphql/query/familyQueries";
import {
  ICompanyCategoriesList,
  getCategory,
} from "graphql/query/categoryQueries";
import { userById } from "graphql/query/userQueries";
import { colorPalette } from "@duro/themes";

interface FamilyCategory {
  category: string;
  name: string;
}

interface FamilySuffixProps {
  getFamilyValue: (value: string) => void;
  getSelectedRadioOption: (value: string) => void;
  getSuffixValue: (event: ChangeEvent<{ value: string }>) => void;
  getFamilyCategoryValue: (value: string) => void;
  familyCategories: FamilyCategory[];
  importTileTitle?: string;
  mpnField?: ReactNode;
  setEnableFreeFormOverride: (value: boolean) => void;
  isAllowedFreeFormOverride: boolean;
  alias: string;
  counterLength: number;
  familyCounterLength: number;
}

export const FamilySuffixComponent: FC<FamilySuffixProps> = ({
  getFamilyValue,
  getSelectedRadioOption,
  getSuffixValue,
  getFamilyCategoryValue,
  familyCategories,
  importTileTitle,
  mpnField,
  setEnableFreeFormOverride,
  isAllowedFreeFormOverride,
  alias,
  counterLength,
  familyCounterLength
}) => {
  const companyId = userById()?.primaryCompany?.id;
  const { loading: familyLoading, data: familyData } = useFamily();
  const [categoriesData, setCategoriesData] =
    useState<ICompanyCategoriesList | null>(null);

  const [selectedFamily, setSelectedFamily] = useState<string>("");
  const [selectedFamilyCategoryValue, setSelectedFamilyCategoryValue] =
    useState<string>("");
  const [selectedSuffix, setSelectedSuffix] = useState<string>("");
  const [radioButtonValue, setRadioButtonValue] =
    useState<string>("existingFamily");

  useEffect(() => {
    if (!categoriesData) {
      getCategory({ companyId }).then((response) => {
        setCategoriesData(response);
      });
    }
  }, [categoriesData, companyId]);

  const handleFamilyChange = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      const { value } = event.target;
      setSelectedFamily(value);
      getFamilyValue(value);
    },
    [getFamilyValue]
  );

  const handleFamilyCategoryChange = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      const { value } = event.target;
      setSelectedFamilyCategoryValue(value);
      getFamilyCategoryValue(value);
    },
    [getFamilyCategoryValue]
  );

  const handleSuffixChange = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      const { value } = event.target;
      setSelectedSuffix(value);
      getSuffixValue(event);

      if (radioButtonValue === "newFamily" && familyCategories.length === 1) { 
        const singleCategory = familyCategories[0].category;
        setSelectedFamilyCategoryValue(singleCategory);
        getFamilyCategoryValue(singleCategory);
      }
    },
    [getSuffixValue, getFamilyCategoryValue, setSelectedFamilyCategoryValue, radioButtonValue]
  );

  const handleOptionChange = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      const { value } = event.target;
      setRadioButtonValue(value);
      getSelectedRadioOption(value);

      // Clear values when radio button is changed
      getFamilyValue("");
      setSelectedFamilyCategoryValue("");
      setEnableFreeFormOverride(value === "freeForm");
    },
    [
      getFamilyValue,
      getSelectedRadioOption,
      getFamilyCategoryValue,
      setEnableFreeFormOverride,
    ]
  );

  const suffixList = useMemo(
    () =>
      categoriesData
        ? [
            ...new Set(
              categoriesData?.categories
                ?.slice()
                .map((item) => ({ category: `${item.code?.charAt(0)}${'X'.repeat(counterLength)} : ${item.name}`, type: item.type }))
                .filter((item) => {
                  if (alias === "prd") {
                    return item.type.toLowerCase() === "assembly";
                  } else if (alias !== "prd" && importTileTitle) {
                    return item.type.toLowerCase() === "electrical";
                  }
                  return true;
                })
            ),
          ].sort((a, b) => a.category.localeCompare(b.category))
        : [],
    [categoriesData]
  );

  const suffixOptions = useMemo(
    () =>
      suffixList.map((option, index) => (
        <option key={index} value={option.category}>
          {option.category}
        </option>
      )),
    [suffixList]
  );

  const familyOptions = useMemo(
    () =>
      !familyLoading
        ? [
            ...new Set(
              (familyData?.families || []).map((item: any) => (
                <option key={item.value} value={item.value}>
                  {item.value}
                </option>
              ))
            ),
          ].sort((a: any, b: any) => {
              if (a.props.value < b.props.value) return -1;
              if (a.props.value > b.props.value) return 1;
              return 0;
          })
        : [],
    [familyLoading, familyData]
  );

  const familyCategoryOptions = useMemo(
    () =>
      familyCategories.length > 0
        ? [
            ...new Set(
              familyCategories.map((item: FamilyCategory) => (
                <option key={item.category} value={item.category}>
                  {item.category}{'X'.repeat(familyCounterLength)}: {item.name}
                </option>
              ))
            ),
          ]
        : [
            <option key="no-category" value="">
              No family categories
            </option>,
          ],
    [familyCategories]
  );

  return (
    <ProductFamilyBox alias={alias}>
      {alias === "cmp" && <TitleWrapper>
        <SpanStyle>{importTileTitle ?? "CHOOSE FAMILY AND SUFFIX"}</SpanStyle>
      </TitleWrapper>}

      <Tile alias={alias}>
        <TopSection>
          <FamilyHeading alias={alias}>Choose Family*</FamilyHeading>
            <FormControl component="fieldset">
              <RadioGroupWrapper
                aria-label="options"
                name="options"
                onChange={handleOptionChange}
                value={radioButtonValue}
              >
                <FormControlLabel
                  value="existingFamily"
                  control={<Radio checkedIcon={<CircleIcon />} size="small" />}
                  label={<StyledRadioText>Existing Family</StyledRadioText>}
                />
                <FormControlLabel
                  value="newFamily"
                  control={<Radio checkedIcon={<CircleIcon />} size="small" />}
                  label={<StyledRadioText>New Family</StyledRadioText>}
                />

                {/* Hiding Free Form option due to none of our existing customers requesting for products with Free Form Override for SpaceX CPN. This feature and functionality can be re-enabled and developed further in the future if requested */}
                {isAllowedFreeFormOverride && alias !== "prd" && (
                  <RadioStyle
                    value="freeForm"
                    control={
                      <Radio checkedIcon={<CircleIcon />} size="small" />
                    }
                    label={<StyledRadioText>Free Form</StyledRadioText>}
                  />
                )}
              </RadioGroupWrapper>
            </FormControl>
        </TopSection>

        {radioButtonValue === "existingFamily" && (
          <Box>
            {/* Need to replace with the new dropdown design */}
            <FamilyDropDownForm>
              <select
                name="family"
                onChange={handleFamilyChange}
                style={selectStyles}
                value={selectedFamily}
              >
                <option value="">Select</option>
                {familyOptions}
              </select>
            </FamilyDropDownForm>
          </Box>
        )}

        {radioButtonValue === "newFamily" && familyCategories.length > 1 && (
          <Box>
            <FamilyCategoryDropDownForm>
              <select
                name="familycategory"
                onChange={handleFamilyCategoryChange}
                style={selectStyles}
                value={selectedFamilyCategoryValue}
              >
                <option value="">Select</option>
                {familyCategoryOptions}
              </select>
            </FamilyCategoryDropDownForm>
          </Box>
        )}

        {radioButtonValue === "newFamily" && familyCategories.length === 1 && (
          <FamilyTypeMessage familyCategories={familyCategories} radioButtonValue={radioButtonValue}>
            {familyCategoryOptions}
          </FamilyTypeMessage>
        )}

        {radioButtonValue === "freeForm" && isAllowedFreeFormOverride && (
          <FamilyTypeMessage familyCategories={familyCategories} radioButtonValue={radioButtonValue}>
            A temporary editable CPN will be generated upon component creation
          </FamilyTypeMessage>
        )}

        <Box>
          {/* Need to replace with the new dropdown design */}
          <SuffixDropDownForm>
            <StyledText alias={alias}>Choose Suffix*</StyledText>
            <select
              name="suffix"
              onChange={handleSuffixChange}
              style={selectStyles}
              value={selectedSuffix}
            >
              <option value="">Select</option>
              {suffixOptions}
            </select>
          </SuffixDropDownForm>
        </Box>
        {mpnField && <MpnWrapper>{mpnField}</MpnWrapper>}
      </Tile>
    </ProductFamilyBox>
  );
};
export default FamilySuffixComponent;

export const TitleWrapper = styled(Box)({
  backgroundColor: "#41414A",
  borderTopLeftRadius: "0.5rem",
  borderTopRightRadius: "0.5rem",
  padding: "0.625rem 1.5625rem",
});

interface AliasProps extends BoxProps {
  alias?: string;
}

interface FamilyCategoryProps extends BoxProps {
  familyCategories: FamilyCategory[];
  radioButtonValue: string;
}

export const MpnWrapper = styled(Box)({
  color: "#888888",
  fontSize: "0.875rem",
  marginTop: "1.5rem",
});

export const StyledText = styled(Box)<AliasProps>(({ alias }) => ({
  color: alias === "cmp" ? colorPalette.taupeGray : colorPalette.white,
  fontSize: "0.875rem",
  marginBottom: "0.5rem",
  textTransform: alias === "cmp" ? "uppercase" : "capitalize",
}));

export const FamilyHeading = styled(Box)<AliasProps>(({ alias }) => ({
  color: alias === "cmp" ? colorPalette.taupeGray : colorPalette.white,
  fontSize: "0.875rem",
  paddingTop: "0.125rem",
  textTransform: alias === "cmp" ? "uppercase" : "capitalize",
}));

export const StyledRadioText = styled(Typography)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
});

export const SpanStyle = styled(Typography)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
  fontWeight: "bold",
});
export const TitleBoxStyle = styled(Box)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
  textTransform: "uppercase",
});

export const Tile = styled(Box)<AliasProps>(({ alias }) => ({
  backgroundColor: alias === "cmp" ? colorPalette.darkGrey : "transparent",
  borderBottomLeftRadius: alias === "cmp" ? "0.5rem" : "0",
  borderBottomRightRadius: alias === "cmp" ? "0.5rem" : "0",
  marginBottom: alias === "cmp" ? "1.875rem" : "0",
  padding: alias === "cmp" ? "0.9375rem 1.5625rem 1.5625rem 1.5625rem" : "0",
}));

export const TopSection = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "nowrap",
  width: "100%",
});

export const RadioStyle = styled(FormControlLabel)({
  marginRight: "0",
});
export const FamilyDropDownForm = styled(FormControl)({
  display: "flex",
  marginBottom: "1.5rem",
  width: "100%",
});

export const FamilyCategoryDropDownForm = styled(FormControl)({
  display: "flex",
  marginBottom: "1.5rem",
  width: "100%",
});

export const RadioGroupWrapper = styled(RadioGroup)({
  color: colorPalette.taupeGray,
  flexDirection: "row",
  justifyContent: "end",
});
export const TextFieldWrapper = styled(Box)({
  border: `1px dashed ${colorPalette.taupeGray}`,
  color: colorPalette.taupeGray,
  display: "flex",
  fontStyle: "italic",
  marginBottom: "1.2rem",
});
export const TextField = styled(Typography)({
  fontSize: "0.875rem",
  margin: "0.375rem",
});
export const SuffixTitle = styled(Box)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
  textTransform: "uppercase",
});
export const SuffixDropDownForm = styled(FormControl)({
  display: "flex",
  width: "100%",
});

export const ProductFamilyBox = styled(Box)<AliasProps>(({ alias }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "stretch",
  height: "auto",
  padding: 0,
  margin: 0,
  fontSize: "13px",
}));

const selectStyles = {
  padding: "0.375rem 0.625rem",
  width: "100%",
};

export const FamilyTypeMessage = styled(Box)<FamilyCategoryProps>(({familyCategories, radioButtonValue}) => ({
  border: `1px dashed ${colorPalette.taupeGray}`,
  color: familyCategories.length === 1 && radioButtonValue === "newFamily" ? colorPalette.white : colorPalette.taupeGray,
  marginBottom: "1.25rem",
  fontStyle: familyCategories.length === 1 && radioButtonValue === "newFamily" ? "normal" : "italic",
  display: "flex",
  alignItems: "center",
  height: "auto",
  padding: "0.375rem 0.625rem",
  fontSize: "13px",
}));
