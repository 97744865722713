/**
 * TODO (PLM-6281): The component/file currently is just wrapping the old functionality used here.
 * It needs to be updated to using MUI components and make it so that the data isn't modified in way
 * that are hard to follow and can have unintended side effects.
 */

import { PageItemType } from "@duro/utils";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Variants from "v1/components/page/common/variants/view";
import Utils from "v1/modules/utils";
import { useComponentVariantContext } from "./componentModal";
import { useCompany, useViewObject } from "./hooks";

export interface VariantsTabProps {
  isEditing: boolean;
  pageItemType: PageItemType;
}

export function VariantsTab(props: VariantsTabProps) {
  const { isEditing, pageItemType } = props;
  const history = useHistory();

  const [variants, setVariants] = useState<any[]>([]);

  const viewObject = useViewObject(pageItemType);
  const company = useCompany();
  const { getVariantDataFromApi, reloadVariantData } = useComponentVariantContext();

  const massPrecision = useMemo(() => Utils.getCompanyMassPrecision(company), [company]);

  return (
    <>
      {!isEditing && (
        <Variants
          defaultCurrency={viewObject.defaultCurrency}
          getDataFromApi={getVariantDataFromApi}
          history={history}
          massPrecision={massPrecision}
          objectData={viewObject}
          saveVariantsInParent={setVariants}
          updateVariantsTab={reloadVariantData}
          variants={variants}
        />
      )}
    </>
  );
}
