import { useDuroFlag } from "common/components/launchDarkly";
import { EditChangeOrderPage } from "../../../../../features/changeorders";
import StableChangeOrderEdit from "./index";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
// User has clicked the "Opt In" button, and we have stored that in local storage
export const userOptedIntoBeta = () =>
  (localStorage.getItem("changeOrderBetaOptIn") ?? "false") === "true";

const EditChangeOrder = (props) => {
  // Check if the LaunchDarkly flag is enabled
  const history = useHistory();
  const { changeOrderBeta: isLaunchDarklyFlagEnabled } = useDuroFlag();
  useEffect(() => {
    if (!userOptedIntoBeta() && isLaunchDarklyFlagEnabled) {
      history.push({
        search: "",
      });
    }
  }, []);

  if (!isLaunchDarklyFlagEnabled) {
    return <StableChangeOrderEdit {...props} />;
  }

  return (
    <>
      {userOptedIntoBeta() ? (
        <EditChangeOrderPage {...props} />
      ) : (
        <StableChangeOrderEdit {...props} />
      )}
    </>
  );
};

export default (props) => <EditChangeOrder {...props} />;
