import { gql } from "@apollo/client";
import { ComponentFragment, ComponentRevisionFragment } from "./componentFragment";
import { CreatedLotFragment } from "./lotFragment";

export const BaseProductFragment = gql`
  fragment baseProductFragment on Product {
    alias
    categories
    cpn {
      displayValue
      id
    }
    description
    eid
    id
    imageIds
    lastModified
    modified
    name
    previousStatus
    status
    legacyNextRevision
    previousRevisionValue
    revisionValue
    variantGroup {
      id
      type
    }
  }
`;

export const BaseProductRevisionFragment = gql`
  fragment baseProductRevisionFragment on ProductRevision {
    alias
    categories
    cpn {
      displayValue
      id
    }
    description
    eid
    id
    imageIds
    lastModified
    modified
    name
    status
    revisionValue
    variantGroup {
      id
      type
    }
  }
`;

export const ProductFragment = gql`
  fragment productFragment on Product {
    ...baseProductFragment
    children {
      quantity
    }
    incompleteCost
    mass
    massStatus
    previousStatus
    previousRevisionValue
    primarySource {
      leadTimeDays
      manufacturer
      mpn
      unitPrice
    }
    procurement
  }
  ${BaseProductFragment}
`;

export const ProductRevisionFragment = gql`
  fragment productRevisionFragment on ProductRevision {
    ...baseProductRevisionFragment
    children {
      quantity
    }
    incompleteCost
    mass
    massStatus
    parent {
      id
    }
    previousStatus
    previousRevisionValue
    primarySource {
      leadTimeDays
      manufacturer
      mpn
      unitPrice
    }
    procurement
  }
  ${BaseProductRevisionFragment}
`;

export const ProductWithChildrenFragment = gql`
  fragment productWithChildrenFragment on Product {
    ...productFragment
    children {
      component {
        ...componentFragment
      }
      itemNumber
      notes
      quantity
      refDes
      variants {
        isPermitted
        isPrimary
        variant {
          id
        }
      }
      waste
    }
  }
  ${ComponentFragment}
  ${ProductFragment}
`;

export const ProductRevisionWithChildrenFragment = gql`
  fragment productRevisionWithChildrenFragment on ProductRevision {
    ...productRevisionFragment
    children {
      assemblyRevision {
        ...componentRevisionFragment
      }
      itemNumber
      notes
      quantity
      refDes
      variants {
        isPermitted
        isPrimary
        variant {
          id
        }
      }
      waste
    }
  }
  ${ComponentRevisionFragment}
  ${ProductRevisionFragment}
`;

export const ProductLastReleasedRevisionFragment = gql`
  fragment productLastReleasedRevisionFragment on Product {
    lastReleaseRevision {
      ...baseProductRevisionFragment
    }
  }
  ${BaseProductRevisionFragment}
`;

export const ProductsLotFragment = gql`
  fragment productsLotFragment on Product {
    build {
      lots {
        ...createdLotFragment
      }
    }
    cpn {
      displayValue
    }
    id
  }
  ${CreatedLotFragment}
`;

export const ProductRevisionsLotFragment = gql`
  fragment productRevisionsLotFragment on ProductRevision {
    build {
      lots {
        ...createdLotFragment
      }
    }
    cpn {
      displayValue
    }
    id
  }
  ${CreatedLotFragment}
`;
