import {
  EcoDefaultApproval,
  ChangeOrderPLMApi,
  ChangeOrderUser,
} from "design/models/changeorder";
import { removeKey } from "utils/removeKeys";
import { SuperComponent as LegacySuperComponent } from "design/models/changeorder";
import { AxiosResponse } from "axios";
import { restClient } from "utils/api";

export interface LegacyChangeOrderSubmitPayload {
  _id: string;
  alias: string;
  approvalType: string;
  archived: boolean;
  coExternalNotifyUsers: string[];
  coInternalNotifyUsers: string[];
  company: string;
  conId: string;
  created: number;
  creator: string;
  description: string;
  isParentInclude: boolean;
  lastModified: number;
  library: string;
  name: string;
  resolution: string;
  status: string;
  type: string;
  con: string;
  approverList: LegacyApproverList[];
  children: LegacyChildren;
  documents: LegacyDocument[];
  history: LegacyHistoryEvent;
  childRevision: LegacyChildRevision;
  erpOptions: LegacyERPOptions;
  exportBundleKey: string;
  skipWebhook: boolean;
  unChangedChildren: LegacyChildren;
  refRelease: null;
}

export interface LegacyApproverList {
  user: string;
  invitedAt: number;
}

export interface LegacyChildRevision {
  componentRevision: any[];
  productRevision: any[];
}

export interface LegacyChildren {
  products: string[];
  components: string[];
}

export interface LegacyDocument {
  file: string;
  specs: LegacySpecs;
}

export interface LegacySpecs {
  type: string;
  revision: string;
  status: string;
}

export interface LegacyERPOptions {
  effectivity: LegacyEffectivity;
  itemType: LegacyItemType;
  additionalPayloadForNotifications: LegacyAdditionalPayloadForNotifications | null;
}

export interface LegacyAdditionalPayloadForNotifications {
  childComponent: boolean;
  parentAssemblies: boolean;
}

export interface LegacyEffectivity {
  isEnabled: boolean;
  startDate: number;
  endDate: number;
  overrideExisting: boolean;
}

export interface LegacyItemType {
  isEnabled: boolean;
  value: string;
  overrideExisting: boolean;
}

export interface LegacyHistoryEvent {
  action: string;
  user: string;
}

// @TODO: Remove when POST to plm-api is replaced with core-api mutation
export function reKeyLegacyCOFields(field: string | EcoDefaultApproval) {
  if (field === EcoDefaultApproval.FIRST_IN) return "First-In";
  if (field === EcoDefaultApproval.MAJORITY) return "Majority";
  if (field === EcoDefaultApproval.UNANIMOUS) return "Unanimous";
  if (field === "CO") return "co";
  return field;
}

type LegacyChangeOrderSubmissionResponse = Promise<
  AxiosResponse<{ data: string | null; success: boolean }>
>;

export async function postChangeOrder(payload: LegacyChangeOrderSubmitPayload) {
  return restClient.post(
    `/changeorders/${payload?._id}`,
    payload
  ) as LegacyChangeOrderSubmissionResponse;
}

function getUpdatedCon(type: string, con: string) {
  return `${type.toLowerCase()}-${con.split("-")[1]}`;
}

export type ChangeOrderSubmitType = "SUBMIT" | "SAVE_DRAFT";
// Used to convert core-api ChangeOrder to plm-api ChangeOrder
// and can be removed once updated to utilize the core-api
// updateChangeOrder mutation
export function getPayload(
  status: ChangeOrderSubmitType,
  components: LegacySuperComponent[],
  products: LegacySuperComponent[],
  co: ChangeOrderPLMApi,
  userId?: string | null
) {
  const isSubmitting = status === "SUBMIT";

  const innerPayload: LegacyChangeOrderSubmitPayload = {
    _id: co.id,
    alias: reKeyLegacyCOFields(co.alias),
    approvalType: reKeyLegacyCOFields(co.approvalType),
    archived: co.archived,
    coExternalNotifyUsers: co.coExternalNotifyUserEmails,
    coInternalNotifyUsers: co.coInternalNotifyUsers.map((i) => i.id),
    company: co.company.id,
    conId: co.con.id,
    created: new Date(co.created).getTime(),
    creator: co.creator.id,
    description: co.description,
    isParentInclude: co.isParentInclude,
    lastModified: new Date(co.lastModified).getTime(),
    library: co.library.id,
    name: co.name,
    resolution: isSubmitting ? "UNRESOLVED" : "NONE",
    status: isSubmitting ? "OPEN" : "DRAFT",
    type: co.type.toUpperCase(),
    con: getUpdatedCon(co.type, co.con.displayValue),
    approverList: co.approvers.map((approver) => ({
      user: approver.id,
      invitedAt: new Date().getTime(),
    })),
    documents: co.documentLinks.map((document) => ({
      specs: {
        revision: document.specs.revision ?? "",
        status: document.specs.status ?? "",
        type: document.specs.type.replace(/_/g, " ") ?? "",
        lastModified: new Date().getTime(),
      },
      file: document?.document?.id ?? "",
    })),
    history: {
      user: userId ?? "",
      action: isSubmitting ? "SUBMITTED" : "EDITED",
    },
    childRevision: {
      componentRevision: [],
      productRevision: [],
    },
    erpOptions: co.erpOptions,
    exportBundleKey: co.exportBundleKey ?? "",
    skipWebhook: false,
    unChangedChildren: {
      products: [],
      components: [],
    },
    refRelease: null,
    children: {
      components: [
        ...new Set(components.map((component) => component.oid)),
      ] as string[],
      products: [
        ...new Set(products.map((product) => product.oid)),
      ] as string[],
    },
  };

  const payload = removeKey(
    innerPayload,
    "__typename"
  ) as LegacyChangeOrderSubmitPayload;

  if (payload?.erpOptions?.itemType?.value === "NONE") {
    payload.erpOptions.itemType.value = "";
  }

  return payload;
}
