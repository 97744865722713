import { useState, useEffect } from "react";

type Listener<T> = (state: T) => void;

export class Store<T> {
  private state: T;
  private initialState: T;
  private listeners: Listener<T>[] = [];
  private callback: ((state: T) => void) | null = null;

  constructor(initialState: T, callback?: (state: T) => void) {
    this.state = initialState;
    this.initialState = initialState;
    if (callback) {
      this.callback = callback;
    }
  }

  getState(): T {
    return this.state;
  }

  async setState(newState: Partial<T> | T | ((prevState: T) => Partial<T> | T)): Promise<void> {
    if (typeof newState === "function") {
      this.state = (newState as (prevState: T) => T)(this.state);
    } else if (Array.isArray(newState)) {
      this.state = [...newState] as unknown as T;
    } else if (typeof newState === "object" && newState !== null) {
      this.state = { ...this.state, ...newState };
    } else {
      this.state = newState as T;
    }
    await this.notify();
  }

  async resetState(): Promise<void> {
    this.state = this.initialState;
    await this.notify();
  }

  subscribe(listener: Listener<T>): () => void {
    this.listeners.push(listener);
    return () => {
      this.unsubscribe(listener);
    };
  }

  unsubscribe(listener: Listener<T>): void {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  private async notify(): Promise<void> {
    this.listeners.forEach((listener) => listener(this.state));
    if (this.callback) {
      this.callback(this.state);
    }
  }

  useStore(): [T, (newState: Partial<T> | ((prevState: T) => T)) => Promise<void>, () => Promise<void>] {
    const [state, setState] = useState<T>(this.state);

    useEffect(() => {
      const listener = (newState: T) => setState(newState);
      const unsubscribe = this.subscribe(listener);
      return unsubscribe;
    }, []);

    return [
      state,
      async (newState: Partial<T> | ((prevState: T) => T)) => {
        await this.setState(newState);
        setState(this.state);
      },
      this.resetState.bind(this),
    ];
  }
}

export const createStore = <T,>(initialState: T, callback?: (state: T) => void): Store<T> => {
  return new Store(initialState, callback);
};
