import schemas from "v1/modules/validations";
import { SuperComponent } from "design/models/changeorder";
import { getWindow } from "utils/window";
import { ChangeOrderREST } from "../rest";

type ClientModulesSchemaValidationPayload = {
  status: string;
  revSchemeType: string;
  libraryType: string;
  isClient: boolean;
  previousRevision: string;
  revisionBump: boolean;
};

const normalizeRevision = (
  opts: ClientModulesSchemaValidationPayload,
  value: string
) => schemas.component.revision.normalize(opts, value).revision;

export const getPreviousRevisionValue = (item: SuperComponent) => {
  if (item.previousStatus === "DESIGN" && item.previousRevisionValue === "") {
    return "1";
  }
  return item.previousRevisionValue
    ? item.previousRevisionValue
    : item.revisionValue;
};

export async function getNextRevisionOnStatusChange(input: SuperComponent) {
  const originalStatus = getOriginalStatus(input);
  getOriginalRevision(input);
  const item = { ...input };
  const Window = getWindow();

  const previousRevision = getPreviousRevisionValue(item);

  const opts = {
    status: item.status,
    revSchemeType: Window.__revSchemeType,
    libraryType: Window.__libraryType,
    defaultBlacklistedRevisions: Window.__defaultBlacklistedRevisions,
    currentRevision: item.revisionValue,
    isClient: true,
    previousRevision,
    revisionBump: false,
  };

  const requiresRevisionBump =
    !item.modified &&
    item.status === originalStatus &&
    item.status === item.previousStatus;

  const requiresRevertedRevision =
    item.modified &&
    item.previousStatus === item.status &&
    item.status !== originalStatus;

  if (requiresRevisionBump) {
    const getBumpedRevision = await ChangeOrderREST.components.getNextRevision(
      item?.cpn?.displayValue,
      item?.status
    );
    const bumpedRevision = await getBumpedRevision();

    if (bumpedRevision) {
      item.legacyNextRevision = bumpedRevision;
      opts.revisionBump = true;
    }
  } else if (requiresRevertedRevision) {
    item.legacyNextRevision = item.previousRevisionValue;
  } else {
    item.legacyNextRevision = item.revisionValue;
  }

  return normalizeRevision(opts, item.legacyNextRevision) as string;
}

const statusCache = new Map<string, string>();
const revisionCache = new Map<string, string>();

export function clearCoEditCaches() {
  statusCache.clear();
  revisionCache.clear();
}

export function getOriginalStatus(item: SuperComponent) {
  const cachedStatus = statusCache.get(item.id);
  if (cachedStatus) return cachedStatus;
  statusCache.set(item.id, item.status);
  return item.status;
}

export function getOriginalRevision(item: SuperComponent) {
  const cachedRev = revisionCache.get(item.id);
  if (cachedRev) return cachedRev;
  revisionCache.set(item.id, item.legacyNextRevision);
  return item.legacyNextRevision;
}

export function getUserModifiedRevision(
  item: SuperComponent,
  revision: string
) {
  return {
    ...item,
    legacyNextRevision: revision,
  };
}
