import { gql } from "@apollo/client";


export const UserFragmentBasic = gql`
  fragment userInfoFragment on User {
    id
    email
    groups
    firstName
    lastName
  }
`;


export const CadAndPdmSettingsFragment = gql`
  fragment cadSettingsFragment on Library {
    cad {
      settings {
        activeReleaseNamingScheme
        allowedDesignRevisionTypes
        customReleaseNamingScheme
        findAndReplaceReleaseNamingScheme {
          find
          matchCase
          replace
          useRegex
        }
        overwriteFilenameWithCpn
        procurementDefault
        setEidToFilename
      }
      pdm {
        type
      }
    }
  }
`;

// Base User fragment without any relations to prevent circular dependencies
export const UserFragment = gql`
  fragment userFragment on User {
    id
    activeLibrary {
      id
      cpnRules {
        isAllowedFreeFormOverride
        isAllowedTwoVariableSuffixEdit
        base {
          counterDelimiter
          counterLength
          hideVariantStart
          prefix
          prefixDelimiter
          prefixLength
          useCategoryPrefix
          variantLength
          variantStart
          useTwoVariableScheme
          familyCounterLength
          familyCounterStart
          familyCounterDelimiter
        }
        component {
          counterDelimiter
          counterLength
          hideVariantStart
          prefix
          prefixDelimiter
          prefixLength
          useCategoryPrefix
          variantLength
          variantStart
        }
        product {
          prefix
          useCategoryPrefix
        }
      }
      familyCategories {
        name
        category
      }
      type
    }
    archived
    avatar {
      id
      src
    }
    created
    email
    firstName
    groups
    id
    integrations
    invite {
      accepted
      from
    }
    isSetupCompleted
    lastLogin
    lastName
    logins {
      loginAt
    }
    onboarding {
      companyLibraryNotification
      completedSteps
      currentStep
      displaySampleProduct
      dummyProductType
      onBoardingNotification
      personalLibraryNotification
      quickStartGuideNotification
      status
    }
    preferences {
      favorite {
        components {
          id
          name
        }
      }
    }
    primaryCompany {
      id
      legacyCpnVariantScheme
      name
      settings {
        configurations {
          isConfigurationsEnabled
        }
        isBuildEnabled
      }
      subdomain
    }
    role
    status
    styles
    timezoneOffset
    title
    verified
  }
`;

export const AllEnabledUsersFragment = gql`
  fragment allEnabledUsersFragment on User {
    id
    primaryCompany {
      users(status: ENABLED) {
        _id: id
        id
        avatar {
          id
          name
          src
        }
        email
        firstName
        groups
        lastName
        role
        status
        styles
      }
    }
  }
`;
