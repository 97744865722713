import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d={`M10.421 11.421a4.21 4.21 0 0 0 4.21-4.21A4.21 4.21 0 0 0 10.422 3a4.21 
      4.21 0 0 0-4.21 4.21 4.21 4.21 0 0 0 4.21 4.211zm0-6.316c1.158 0 2.105.948 2.105 
      2.106a2.111 2.111 0 0 1-2.105 2.105A2.111 2.111 0 0 1 8.316 7.21c0-1.158.947-2.106 
      2.105-2.106zM4.105 17.737c.21-.663 2.706-1.769 5.221-2.042l2.148-2.106a9.832 9.832 
      0 0 0-1.053-.063c-2.81 0-8.421 1.41-8.421 4.21v2.106h9.474l-2.106-2.105H4.105zm16.421-5.79-5.4 
      5.442-2.179-2.189-1.473 1.484 3.652 3.684L22 13.432l-1.474-1.485z`} fill-rule="evenodd"/>
    </svg>
  );
}

export default Icon;
