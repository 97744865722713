import { Box, Typography, styled } from "@mui/material";
import { colorPalette } from "components/themes";

export const TableActionNavHeader = styled(Typography)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
  padding: 1,
});

export const TableActionNav = styled(Box)({
  py: 2,
  height: 40,
  display: "flex",
  gridGap: 20,
  border: `1px solid ${colorPalette.taupeGray}`,

  borderRadius: 8,
  justifyContent: "center",
  alignItems: "center",
  marginTop: 20,
  marginBottom: 20,
  fontSize: "14px",
  gap: "10px",
  width: "475px",
});

export const TableActionNavItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  fontSize: "0.875rem",
  ["& label"]: {
    fontSize: "0.875rem",
    padding: "10px",
    margin: 0,
  },
});
