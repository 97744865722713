
import React, { useCallback, useState, useEffect, useMemo, Fragment } from 'react';
import ReactTooltip                       from 'react-tooltip';
import Utils 							  from '../../../../modules/utils';
import validations, {validateField}       from "../../../../modules/validations";
import LazyInput                          from "../../../ui/lazy-input/input.js";
import { FormModal } from 'common/components/modals';
import { Box } from '@mui/material';

const modalProps = {
	width: "35.313rem",
}

const UpdateRevisionNonAssembly = ({revisionInput, onContinue, component, toogleSaveAsRevisionModal, newStatusValue}) => {

	const [revisionComment, setRevisionComment]     = useState("")

	const [revisionObject, setRevisionObject] = useState({
		value: revisionInput.value,
		valid: revisionInput.valid,
		class: "",
		message: "",
  	});

	const revisionInputChange = useCallback(event => {
        const value = event.target.value
        const {revisions} = component
        const libraryType = window.__libraryType
        const revSchemeType = window.__revSchemeType
        const isClient = true
        const isSaveAsRev = true
        const revValue = value.toUpperCase()
        const previousRevision = Utils.getPreviousRevision(component);
				const previousStatus = Utils.getPreviousStatus(component);
        const validationPayload = {
            status: newStatusValue ? newStatusValue : component.status,
            revisions,
            libraryType,
            revSchemeType,
            isClient,
            isSaveAsRev,
            previousRevision,
						previousStatus,
            defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
        }
        validateField(revisionInput, validations.component.revision, revValue, validationPayload)
        setRevisionObject(revisionInput);
	}, [component, newStatusValue, revisionInput]);

	const disableSaveAsRevisionContinueButton = useCallback(() => {
		setRevisionObject({ valid: false });
	}, []);

	const aliasingObject = useMemo(() => component && component.alias === 'cmp' ? 'Component' : 'Product', [component]);

	const handleFormSubmit = useCallback((event) => {
		onContinue(revisionObject, revisionComment, event);
		toogleSaveAsRevisionModal(false);
	}, [onContinue, revisionObject, revisionComment, toogleSaveAsRevisionModal]);

    useEffect(() => {
    	revisionInputChange({target:{value: revisionInput.value}})
	}, [revisionInputChange, revisionInput.value])

	return (
		<Fragment>
			<div className={"confirm-revision-changed-modal"}>
	            <FormModal
					disableSubmit={!!revisionObject.message}
					modalProps={modalProps}
					onCancel={() => {toogleSaveAsRevisionModal(false)}}
					onClose={() => {toogleSaveAsRevisionModal(false)}}
					onSubmit={handleFormSubmit}
					open={true}
					title={"Save New Revision"}
				>
	            <div className="warning-text">
	                <Box sx={{ marginBottom: "1.25rem" }}>You are saving a new revision on this {aliasingObject}.</Box>
	                <div>
	                    <Box sx={{
							display: "flex",
    						alignItems: "baseline",
    						marginBottom: "1rem",
							}}
						>
	                        <Box sx= {{ minWidth: "6rem"}}>
	                            New Revision
	                        </Box>
	                        <ReactTooltip id={"input-revision-modal-input"}
	                            type="light"
	                            effect="solid"
	                            >
	                        </ReactTooltip>
							<Box sx={{ maxWidth: "5rem" }}>
								<LazyInput
									type="text"
									name="revision"
									autoFocus={true}
									value={revisionObject.value}
									onChange={revisionInputChange}
									disableContinueButton={disableSaveAsRevisionContinueButton}
									className={revisionObject.class}
									data-tip={revisionObject.message}
									data-place="right"
									data-type="error"
									data-for='input-revision-modal-input'
								/>
							</Box>
	                    </Box>
						<Box sx={{
							display: "flex",
    						alignItems: "baseline",
						}}
						>
							<Box sx= {{ minWidth: "6rem"}}>
	                            Comment
	                        </Box>
	                        <input
	                            type="text"
	                            name="comment"
	                            value={revisionComment}
	                            onChange={(e)=> {setRevisionComment(e.target.value)}}
	                            className="revision-comment"
	                        />
						</Box>
	                </div>
	            </div>
	            </FormModal>
	        </div>
		</Fragment>
	)
}

export default UpdateRevisionNonAssembly
