import "./index.scss"
import React, { Component }         from "react"
import buildAction                  from "../../../../../helpers/buildAction"
import CO                           from '../../../../../action-types/changeorder';
import { connect }                  from 'react-redux';
import USERS                        from "../../../../../action-types/users"
import Types                        from "../types"
import List                         from "../list"
import UserNotificationTable        from "../user-notification-table"
import ApproverListTable            from "../approver-list-table"
import COSearchMenu                 from "../co-search-menu"
import AddUsersList                 from "../add-users-list"
import EditIcon                     from "../../../../../assets/icons/edit"
import PrdCmpIcon                   from "../../../../../assets/icons/prd-cmp-icon"
import RevsIcon                     from "../../../../../assets/icons/revs"
import NotificationIcon             from "../../../../../assets/icons/send-notification-icon"
import ApproverIcon                 from "../../../../../assets/icons/approver-icon"
import InlineIcon                   from "../../../../ui/icon/inline-icon.js"
import HistoryTable                 from "../history-table"
import LazyInput                    from "../../../../ui/lazy-input/input.js"
import TextArea                     from "../../../../ui/lazy-input/textarea.js"
import HelpIcon                     from "../../../../../assets/icons/help-icon.js";
import Link                         from "../../../../ui/link";
import documentSrc                  from "../../../../../assets/icons/cat-document.svg";
import Icon                         from "../../../../ui/icon";
import Documents                    from "../../../common/documents/edit";
import Schemas                      from "../../../../../modules/schemas";
import validations                  from "../../../../../modules/validations";
import Tooltip                      from "rc-tooltip"
import API                          from "../../../../../modules/api"
import Utils                        from "../../../../../modules/utils";
import CoTemplatePopover            from "../co-template-popover";
import MandatoryApproverNotifier    from "../mandatory-approver-notifier";
import URLSearchParams              from "url-search-params";
import ErpOptions                   from "../../../common/erp-options";
import WarningModal                 from "../../../../ui/warning-modal";
import { EditTabsContextProvider } from "design/pages/common/editTabsContextProvider";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { ButtonWrapper, CustomButton } from "design/pages/component/componentHeader";
import { ButtonVariants } from "common/constants";
import mixpanel from "common/mixpanel";

export class ChangeOrderForm extends Component
{
    constructor(props)
    {
        super(props)
        let coInternalNotifyUsers = this.props.co && this.props.co.coInternalNotifyUsers || []
        let coExternalNotifyUsers = this.props.co && this.props.co.coExternalNotifyUsers || []
        let defaultCOApprovalType = this.props?.ldClient?.allFlags()?.["defaultCOApprovalType"];
        let coNewEditState = this.props.co && this.props.co.history.length === 1
        let approvalType = coNewEditState ? defaultCOApprovalType : this.props.co && this.props.co.approvalType
        let approverList          = []
        this.loadTemplate = {}

        if (this.props.co)
        {
            this.props.co.approverList.forEach((approver) => {
                approverList.push(approver.user)
            })
        }

        let disabledUserIdz = []
        let disabledApproverIdz = []

        if (this.props.co)
        {
            this.props.co.coInternalNotifyUsers.forEach((user) => disabledUserIdz.push(user._id))
            this.props.co.approverList.forEach((approver) => disabledApproverIdz.push(approver.user._id))
        }

        this.state =
        {
            view                  : this.getCurrentView(),
            coInternalNotifyUsers : coInternalNotifyUsers,
            disabledUserIdz       : disabledUserIdz,
            coExternalNotifyUsers : coExternalNotifyUsers,
            addNewLyCreatedUserId : null,
            listName              : null,
            approverList          : approverList,
            disabledApproverIdz   : disabledApproverIdz,
            approvalType,
            documents             : this.props?.co?.documents ?? [],
            isDocumentsUploaded   : true,
            templates             : [],
            enableSave            : !!((approverList.length - 1) || coInternalNotifyUsers.length || coExternalNotifyUsers.length),
            showCoToolTip         : false,
            selectedTemplateName  : {},
            coTemplatesMappings   : [],
            showCoTemplatePopover : false,
            initialTemplateModal  : false,
            currentCoRule         : "",
            previousCoRule        : "",
            action                :"ADDED",
            previousTemplate      : {},
            currentTemplate       : {},
            firstPopOver          : false,
            usersLoaded           : false,
            overrideWarningModalTitle : '', 
            overrideWarningModalDescription : '',
            showOverrideWarningModal: false,
            showOneApprovalType: this.showOneApprovalType(props?.ldClient?.allFlags()),
            isMandatoryTemplate   : false,
            mandatoryExternalUsers: []
        }
        this.imagesWithSrc = []

        this.onInputChange               = this.onInputChange.bind(this)
        this.onKeyPress                  = this.onKeyPress.bind(this)
        this.onSubmit                    = this.onSubmit.bind(this)
        this.getParentAssemblies         = this.getParentAssemblies.bind(this)
        this.parentAssemblyHandler       = this.parentAssemblyHandler.bind(this)
        this.syncSearchInputsWithList    = this.syncSearchInputsWithList.bind(this)
        this.show                        = this.show.bind(this)
        this.syncWidthOfRightSideBar     = this.syncWidthOfRightSideBar.bind(this)
        this.onAddUsers                  = this.onAddUsers.bind(this)
        this.onRemoveUsers               = this.onRemoveUsers.bind(this)
        this.onRemoveExternalUsers       = this.onRemoveExternalUsers.bind(this)
        this.onAddExternalUserEmails     = this.onAddExternalUserEmails.bind(this)
        this.addUserAfterNewUserCreation = this.addUserAfterNewUserCreation.bind(this)
        this.onCancel                    = this.onCancel.bind(this)
        this.onAddApprover               = this.onAddApprover.bind(this)
        this.onRemoveApprover            = this.onRemoveApprover.bind(this)
        this.onApprovalType              = this.onApprovalType.bind(this)
        this.add                         = this.add.bind(this)
        this.childrenAssemblyHandler     = this.childrenAssemblyHandler.bind(this)
        this.getChildrenAssemblies       = this.getChildrenAssemblies.bind(this)
        this.getSpecificChildrenAssemblies       = this.getSpecificChildrenAssemblies.bind(this);
        this.onChange                    = this.onChange.bind(this);
        this.showError                   = this.showError.bind(this);
        this.imagesWithSrc               = [];
        this.approvalTypeFromTemplate    = this.approvalTypeFromTemplate.bind(this);
        this.updateListsTemplates        = this.updateListsTemplates.bind(this);
        this.setSelectedTemplateName     = this.setSelectedTemplateName.bind(this);
        this.displayCoTooltip            = this.displayCoTooltip.bind(this);
        this.getChangeOrderTemplates     = this.getChangeOrderTemplates.bind(this);
        this.setCoTemplates              = this.setCoTemplates.bind(this);
        this.enableSaveButton            = this.enableSaveButton.bind(this);
        this.loadCoTemplates             = this.loadCoTemplates.bind(this);
        this.getCompanyCoTemplatesMappings    = this.getCompanyCoTemplatesMappings.bind(this)
        this.hidePopOver = this.hidePopOver.bind(this)
        this.onInitialModalCancel = this.onInitialModalCancel.bind(this);
        this.setPreviousData  = this.setPreviousData.bind(this);
        this.mandatoryUsers       = this.mandatoryUsers.bind(this);
    }

    showOneApprovalType(props) {
        if (!props) return false;
        const { disableMajorityApproval, disableFirstInApproval, disableUnanimousApproval } = props;
        return [!disableMajorityApproval, !disableFirstInApproval, !disableUnanimousApproval].filter(flag => flag).length === 1;
    }

    getCurrentView()
    {
        let urlParams = new URLSearchParams(window.location.search);
        return urlParams.has("tab") ? urlParams.get("tab") : "childrenData";
    }

    loadCoTemplates(list, coType) {
        let {templates, coTemplatesMappings, selectedTemplateName, firstPopOver, previousTemplate} = this.state;
        let { user } = this.props;

        if(!list.length && this.loadTemplate && !!Object.keys(this.loadTemplate).length) {
            this.loadTemplate = {};
            return
        }
        let statusList = list.map((item)=> item.status).filter((item, index, array) => {
            return ["PRODUCTION","PROTOTYPE"].includes(item) && array.indexOf(item) === index;
        });
        const currentCoRule = Utils.getCoRuleCombination(statusList, coType);
        let previousTemp = this.loadTemplate;
        const currentTemplate = Utils.templateToBeLoaded(currentCoRule, templates, coTemplatesMappings);
        if(previousTemp !== currentTemplate) {
            const selectedTemplateName = {
                label: currentTemplate ? currentTemplate.templateName : `Default Template`,
                value: currentTemplate ? currentTemplate._id : null
            }
            this.setState({selectedTemplateName, currentCoRule})
            if(user.data.initiallyCoRuleApplied) {
                if(firstPopOver && !currentTemplate) {
                    this.setState(
                      {
                        showCoTemplatePopover: true,
                        action: "REMOVED",
                        firstPopOver: false,
                        isMandatoryTemplate: false,
                        mandatoryExternalUsers: []
                      },
                      () => {
                        this.mandatoryUsers();
                      }
                    );
                }
                else if(!firstPopOver && currentTemplate) {
                    this.setState(
                      {
                        showCoTemplatePopover: true,
                        action: "ADDED",
                        firstPopOver: true,
                        currentTemplate,
                        currentCoRule,
                        isMandatoryTemplate: true,
                        mandatoryExternalUsers: [...currentTemplate.externalUsers],
                      },
                      () => {
                        this.mandatoryUsers(currentTemplate);
                      }
                    );

                }
                else if(firstPopOver && currentTemplate) {
                    this.setState({showCoTemplatePopover: true, action:"UPDATED", currentTemplate, currentCoRule}, () => {
                        this.mandatoryUsers(currentTemplate)
                    });
                }
            }
            this.loadTemplate = currentTemplate;
            const isMandatoryTemplate = Object.values(coTemplatesMappings).includes(selectedTemplateName.value);
            currentTemplate?.approvalType &&
              this.setState({
                approvalType: currentTemplate.approvalType,
              });
        }

        if(currentTemplate && !user.data.initiallyCoRuleApplied) {
            user.data.initiallyCoRuleApplied = true;
            this.setState({initialTemplateModal: true, currentTemplate, firstPopOver:true, currentCoRule}, () => {
                this.setInitialTemplateModal();
                this.mandatoryUsers(currentTemplate);
            });
        }
    }

    setInitialTemplateModal() {
        API.users.setInitiallyCoRuleApplied({initiallyCoRuleApplied:true})
    }

    onInitialModalCancel() {
        this.setState({initialTemplateModal: false})
    }

    setPreviousData() {
        const { action, currentTemplate, previousTemplate, currentCoRule, previousCoRule } = this.state;
        const template = action !== "REMOVED" ? currentTemplate : previousTemplate;
        const coRule = action !== "REMOVED" ? currentCoRule : previousCoRule;
        if(template && coRule) this.setState({previousTemplate:template, previousCoRule:coRule})
    }

    onCancel()
    {
        let {mode, co, dispatch, history} = this.props;
        if(mode === "edit") {
           history.push("/changeorder/view/" + co._id);
        }
        else {
            let urlParams = new URLSearchParams(window.location.search);
            let navigation= urlParams.has('navigateToSearch') ? !urlParams.get('navigateToSearch') === 'true': true;
            dispatch(buildAction(CO.DELETE_CO, {id: co._id, history, donotNavigateToSearch: navigation}));
            history.goBack();
        }
    }

    onApprovalType(event)
    {
        mixpanel.track('ChangeOrder', {
            approvalType: event.target.name,
            co: this.props.co._id,
            operation: 'Set Approval Type',
            "Change Order UI Version": "v1"
        })
        this.setState({ approvalType: event.target.name, enableSave: true})
    }

    approvalTypeFromTemplate(approvalType){
        this.setState({ approvalType: approvalType })
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch(buildAction(USERS.GET_USERS_LIST, {fetchEnabledUsers: true}));
    }

    componentDidUpdate(prevProps) {
        this.syncWidthOfRightSideBar()
        if(!this.state.usersLoaded && this.props.users.users.length)
        {
            const { dispatch } = this.props;
            dispatch(buildAction(USERS.SET_USERS_LOADING, false));
            this.setState({usersLoaded: true});
        }
    }

    componentDidMount(){
        if(this.props.list.length > 0)
        {
            const allowMultipleCo = this.props?.ldClient?.allFlags()?.["allow-multiple-co"];
            this.props.dispatch(buildAction(CO.GET_CHANGE_ORDER_OF_CHILDREN, { allowMultipleCo }));
            this.props.dispatch(buildAction(CO.GET_CHANGE_ORDER_LIST_DATA));
        }
        let scrollbarContainer = document.getElementsByClassName("scrollbar-container")[0]
        window.addEventListener("resize", this.syncWidthOfRightSideBar);
        this.syncWidthOfRightSideBar()
        this.getChangeOrderTemplates()
        this.getCompanyCoTemplatesMappings()
    }

    componentWillUnmount() {
        let scrollbarContainer = document.getElementsByClassName("scrollbar-container")[0]
        window.removeEventListener("resize", this.syncWidthOfRightSideBar);
    }

    getChangeOrderTemplates() {
        API.services.getCoTemplate((err, response) => {
            if(response) this.setState({templates: response})
        })
    }

    getCompanyCoTemplatesMappings() {
        API.services.getCompanyCoTemplateMappings((err, res) => {
            if(res) this.setState({coTemplatesMappings: res})
        })
    }
    
    setCoTemplates(templates) {
        this.setState({templates: templates})
    }

    syncWidthOfRightSideBar()
    {
        let topBannerElHeight;
        let changeOrderFormHeight;
        let scrollbarContainerHeight;

        let rightSideBarEl = document.getElementsByClassName("right-search-menu")[0]
        if (rightSideBarEl)
        {
            rightSideBarEl.style.height = '0px'
        }

        // let routesEl = document.getElementById('routes')
        let scrollbarContainer = document.getElementsByClassName("scrollbar-container")[0]
        if (scrollbarContainer)
            scrollbarContainerHeight = scrollbarContainer.clientHeight
        // let topBannerEl    = document.getElementsByClassName("banner-block")[0]
        // if (topBannerEl)
        // {
        //     topBannerElHeight    = topBannerEl.clientHeight
        // }

        if (document.getElementsByClassName("new-changeorder-route")[0])
            changeOrderFormHeight = document.getElementsByClassName("new-changeorder-route")[0].clientHeight

        let scrollElHeight = scrollbarContainerHeight// - topBannerElHeight
        let formHeight = changeOrderFormHeight

        let selectedHeight;
        if (scrollElHeight > formHeight)
        {
            rightSideBarEl.style.height = (scrollElHeight) + "px"
            selectedHeight = (scrollElHeight) + "px"
        }
        else
        {
            selectedHeight = (changeOrderFormHeight) + "px"
        }

        if (rightSideBarEl){
            rightSideBarEl.style.height = selectedHeight
        }


        let rightSearchBars = document.querySelectorAll(".right-sidebar-menu")
        if (rightSearchBars)
        {
            rightSearchBars.forEach((rightSearchBar) =>
            {
                rightSearchBar.style.height = selectedHeight
                rightSearchBar.style.minHeight = selectedHeight
            })
        }
    }

    show(viewName)
    {
        this.setState({ view: viewName, showCoTemplatePopover: false }, window.onresize)
    }

    addUserAfterNewUserCreation(userId, listName)
    {
        let state = this.state
        state.addNewLyCreatedUserId = userId
        state.listName = listName
        this.setState(state)
    }

    componentWillReceiveProps(nextProps)
    {
        let state = this.state
        if (state.addNewLyCreatedUserId !== null)
        {
            let users = []
            let user = this.props.users.users.forEach((user) =>
            {
                if (state.listName === "notification" && state.addNewLyCreatedUserId === user._id)
                {
                    users.push(user)
                }
                else if (state.listName === "approver" && state.addNewLyCreatedUserId === user._id && user.role !== "REVIEWER")
                {
                    users.push(user)
                }
            })

            if (users.length > 0)
            {
                if (state.listName === "notification")
                {
                    this.onAddUsers(users)
                }
                else if (state.listName === "approver")
                {
                    this.onAddApprover(users)
                }
            }
        }
    }

    onAddUsers(users)
    {
        let disabledUserIdz = []
        let state = this.state
        state.addNewLyCreatedUserId = null
        state.listName = null
        state.coInternalNotifyUsers = [...state.coInternalNotifyUsers, ...users]
        users.forEach((user) => disabledUserIdz.push(user._id))
        state.disabledUserIdz = disabledUserIdz
        state.enableSave = true
        this.setState(state)
    }

    onAddApprover(approvers)
    {
        let disabledApproverIdz = []
        let state = this.state
        state.addNewLyCreatedUserId = null
        state.listName = null
        state.approverList = [...state.approverList, ...approvers]
        approvers.forEach((approver) => disabledApproverIdz.push(approver._id))
        state.disabledApproverIdz = disabledApproverIdz
        state.enableSave = true
        for (const approver in approvers) {
            mixpanel.track('ChangeOrder', {
                approver: approver._id,
                co: this.props.co._id,
                operation: 'Add Approver',
                "Change Order UI Version": "v1"
            })
        }
        this.setState(state)
    }

    onAddExternalUserEmails(emails)
    {
        let state = this.state
        emails.forEach((email) =>
        {
            if (!state.coExternalNotifyUsers.includes(email.trim())) state.coExternalNotifyUsers.push(email.trim())
        })
        state.enableSave = true
        this.setState(state)
    }

    onRemoveUsers(users)
    {
        let disabledUserIdz = []
        let state = this.state
        state.coInternalNotifyUsers = users
        users.forEach((user) => disabledUserIdz.push(user._id))
        state.disabledUserIdz = disabledUserIdz
        this.setState(state)
    }

    onRemoveApprover(approvers) {
        let disabledApproverIdz = []
        let state = this.state
        state.approverList = approvers
        approvers.forEach((approver) => disabledApproverIdz.push(approver._id))
        state.disabledApproverIdz = disabledApproverIdz
        this.setState(state)
    }

    onRemoveExternalUsers(emails)
    {
        let state = this.state
        state.coExternalNotifyUsers = emails
        this.setState(state)
    }

    updateListsTemplates(approvers, notifiers, emails){ 
        this.onRemoveApprover(approvers);
        this.onRemoveUsers(notifiers);
        this.onRemoveExternalUsers(emails);
        this.setState({enableSave: false})
    }

    mandatoryUsers(currentTemplate={}) {
        let {co, users} = this.props;
        users = users.users;
        let listOfApprovers = []
        let listOfNotifiers = []
        let listOfExternals = []
        let cloneUsers = users && users.map((user) => ({ ...user,irremovable : false }))
        const creator = cloneUsers.find((user) => user._id === co.creator)
        if(Object.keys(currentTemplate).length) {
            listOfApprovers = Utils.getUpdatedLists(cloneUsers, currentTemplate.approversList);
            listOfNotifiers = Utils.getUpdatedLists(cloneUsers, currentTemplate.notifiersList);
            listOfExternals = currentTemplate.externalUsers;
        }
        if(creator && !listOfApprovers.includes(creator)) {
            creator.irremovable = true;
            listOfApprovers.push(creator)
        }
        this.updateListsTemplates(listOfApprovers, listOfNotifiers, listOfExternals)
    }

    enableSaveButton(setSaveButton){
        this.setState({enableSave: setSaveButton})
    }

    setSelectedTemplateName(templateName){
      this.setState({
        selectedTemplateName: templateName,
        showCoToolTip: false,
        isMandatoryTemplate: false,
      });
    }

    onInputChange(event, runValidation)
    {
      console.log({event, runValidation})
        const { dispatch } = this.props;
        let co = this.props.co;
        dispatch(buildAction(CO.UPDATE_INPUT_ON_CHANGE, ({target: event.target, co, runValidation}) ))
    }

    setErpOptions = () => { 
        const { effectivity, itemType, additionalPayloadForNotifications } = this.props.changeForm.inputs.erpOptions;
        mixpanel.track('ChangeOrder', {
            co: this.props.co._id,
            effectivityEnabled: effectivity.isEnabled.checked,
            effectivityStartDate: effectivity.startDate.value,
            effectivityEndDate: effectivity.endDate.value,
            effectivityOverrideExisting: effectivity.overrideExisting.checked,
            itemTypeIsEnabled: itemType.isEnabled.checked,
            itemTypeValue: itemType.value.value,
            itemTypeOverrideExisting: itemType.overrideExisting.checked,
            operation: 'Set ERP Options',
            "Change Order UI Version": "v1"
        })
        return {
            effectivity : {
                isEnabled : effectivity.isEnabled.checked,
                startDate : effectivity.startDate.value,
                endDate   : effectivity.endDate.value,
                overrideExisting : effectivity.overrideExisting.checked,
            },
            itemType : {
                isEnabled : itemType.isEnabled.checked,
                value : itemType.value.value,
                overrideExisting : itemType.overrideExisting.checked,
            },
            additionalPayloadForNotifications: {
                childComponent: additionalPayloadForNotifications.childComponent.checked,
                parentAssemblies: additionalPayloadForNotifications.parentAssemblies.checked,
            }    
        }
    }  

    getData()
    {
        let data = {}
        let mode = this.props.mode
        let { inputs, list, search } = this.props.changeForm
        let changeOrderFromStore = this.props.co

        if (mode === "edit")
        {
            data = this.props.co
            data.type = inputs.types.value
            data.con = inputs.types.value + "-" + data.con.split("-")[1]
            data.name = inputs.name.value
            data.description = inputs.description.value
            data.children = list
        }
        else
        {
            data = this.props.co
            data.type = inputs.types.value
            data.con = inputs.types.value + "-" + data.con.split("-")[1]
            data.name = inputs.name.value
            data.description = inputs.description.value
            data.children = list
            data.status = "DRAFT"
            data.resolution = "NONE"
        }
        data.history = {action : "EDITED", user : this.props.user.data._id}
        data.coInternalNotifyUsers = this.state.disabledUserIdz
        data.coExternalNotifyUsers = this.state.coExternalNotifyUsers
        data.approvalType = this.state.approvalType
        data.lastModified = changeOrderFromStore.lastModified
        data.isParentInclude = search.parentAssemblyFlag
        data.erpOptions = this.setErpOptions();

        data.documents = this.state.documents;
        let approverList = []
        this.state.disabledApproverIdz.forEach((id) => approverList.push({user: id}))
        data.approverList = approverList
        return data
    }

    onKeyPress(event) {
        if (event.target.type != 'textarea' && event.which === 13 /* Enter */) {
          event.preventDefault();
        }
    }

    hidePopOver() {
        this.setState({showCoTemplatePopover: false});
    }

    submitForApproval = (event) => {
        const { title, description, showOverrideWarningModal } = Utils.ErpOverrideModal(this.props.changeForm.inputs.erpOptions);
        if (showOverrideWarningModal)
            return this.setState({ showOverrideWarningModal, overrideWarningModalTitle: title, overrideWarningModalDescription: description});
        this.onSubmit(event);
    }

    onSubmit(event)
    {
        event.preventDefault()
        const {dispatch} = this.props
        let { inputs } = this.props.changeForm

        if(inputs.submit.class === "disabled" && event.target.name !== "draft") return;

        let payload =
        {
            data: this.getData(),
            history: this.props.history
        }

        if (event.target.name === "submitted" && inputs.submit.class === "disabled") return
        else if (event.target.name === "submitted")
        {
            payload.data.status = "OPEN";
            payload.data.resolution = "UNRESOLVED";
            payload.data.history    =
            {
                action : "SUBMITTED",
                user   : this.props.user.data._id
            }
            payload.data.approverList.forEach((approver) => approver.invitedAt = Date.now())
        }
        if (this.props.mode === "edit")
        {
            payload.editMode = true
            if (event.target.name === "draft" && payload.data.resolution === "REJECTED")
            {
                payload.data.status = "DRAFT";
                payload.data.resolution = "NONE";
            }
        }
        payload.cb = this.showError;
        dispatch(buildAction(CO.SUBMIT_CHANGE_ORDER_FORM, payload))
    }

    showError(data)
    {
        data.forEach((item) => {
            let index = this.props.list.findIndex((cmp) => cmp._id === item._id);
            if(index !== -1)
            {
                this.props.list[index]['lastModifiedError'] = true;
            }
        })
    }

    add(search)
    {
        const { dispatch } = this.props;
        const allowMultipleCo = this.props?.ldClient?.allFlags()?.["allow-multiple-co"];
        dispatch(buildAction(CO.UPDATE_CO_LIST, { ...search, allowMultipleCo }))
    }

    getParentAssemblies()
    {
        const {dispatch} = this.props
        dispatch(buildAction(CO.GET_PARENT_ASSEMBLIES))
    }

    getChildrenAssemblies()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(CO.GET_CHILDREN_ASSEMBLIES));
    }

    getSpecificChildrenAssemblies(item)
    {
        const {dispatch} = this.props;
        dispatch(buildAction(CO.GET_CHILDREN_ASSEMBLIES, {items: [{ id: item._id, type: item.alias }]}));
    }

    parentAssemblyHandler()
    {
        let search = this.props.changeForm.search
        search.parentAssemblyFlag = !search.parentAssemblyFlag
        if (search.parentAssemblyFlag) this.getParentAssemblies()
        mixpanel.track('ChangeOrder', {
            co: this.props.co._id,
            flag: search.parentAssemblyFlag,
            operation: 'Include Parent Assemblies',
            "Change Order UI Version": "v1"
        })
        const { dispatch } = this.props;
        dispatch(buildAction(CO.SET_CO_STATE))
    }

    childrenAssemblyHandler(){
        let search = this.props.changeForm.search;
        search.childrenAssemblyFlag = !search.childrenAssemblyFlag;
        if (search.childrenAssemblyFlag) this.getChildrenAssemblies();
        mixpanel.track('ChangeOrder', {
            co: this.props.co._id,
            flag: search.childrenAssemblyFlag,
            operation: 'Include Child Assemblies',
            "Change Order UI Version": "v1"
        })

        const { dispatch } = this.props;
        dispatch(buildAction(CO.SET_CO_STATE));
    }

    syncSearchInputsWithList(item)
    {
        let inputs = this.props.changeForm.search.inputs
        inputs.forEach((input) => {
            let isSatisfied = false;
            if (Array.isArray(item))
            {
                isSatisfied = item.find(({cpn}) => input.cpn === cpn)
            }
            else
            {
                isSatisfied = input.cpn === item.cpn
            }

            if (isSatisfied)
            {
                input.checked = false
                input.isAddedInList = false
            }
        })
        const { dispatch } = this.props;
        dispatch(buildAction(CO.SET_CO_STATE))
    }

    onChange(event, i)
    {
        let state = this.state;
        state.documents = i.documents;
        state.isDocumentsUploaded = i.isUploadedDocuments;
        this.setState({isDocumentsUploaded: i.isUploadedDocuments});
        if(i.isUploadedDocuments)
        {
            this.setState(state);
        }
    }

    displayCoTooltip(){
        if(this.state.selectedTemplateName) return
        this.setState({showCoToolTip: !this.state.showCoToolTip})
    }

    onCancelOverrideModal = () => {
        this.setState({ showOverrideWarningModal: false });
    }

    onConfirmOverrideModal = () => {
        const { dispatch } = this.props
        const payload = {
            data: { ...this.getData(), status: "OPEN", resolution: "UNRESOLVED" },
            history: { ...this.props.history, action: "SUBMITTED", user: this.props.user.data._id },
            cb: this.showError,
        }
        payload.data.approverList.forEach((approver) => approver.invitedAt = Date.now());
        dispatch(buildAction(CO.SUBMIT_CHANGE_ORDER_FORM, payload));
    }

    render()
    {
        let {inputs, list, searchResults, co, mode, changeForm, tabsType, user, loading, dispatch} = this.props;
        let { action, currentTemplate, previousTemplate, currentCoRule, previousCoRule, view, isDocumentsUploaded, approverList, coInternalNotifyUsers, 
            coExternalNotifyUsers, showCoTemplatePopover, usersLoaded, showOverrideWarningModal, overrideWarningModalTitle, overrideWarningModalDescription } = this.state;

        let showLoading = changeForm.search.parentsLoading || changeForm.search.childrenLoading || changeForm.validationRunning && list.length > 0 ;
        if (!changeForm || !usersLoaded)
            return null
        let popOverTemplate = action !== "REMOVED" ? currentTemplate : previousTemplate;
        let popOverCoRule = action !== "REMOVED" ? currentCoRule : previousCoRule;
        let documentsProps =
        {
            name: "documents",
            onChange: this.onChange,
            documents: co ? co.documents: "",
            clientSchema: tabsType === "prd" ? validations.product.documents : validations.component.documents,
            documentSchema: tabsType === "prd" ? Schemas.product.documents : Schemas.component.documents,
        }
        let isButtonEnabled = isDocumentsUploaded ? inputs.submit.class : 'disabled';

        //checking missing sourcing error in child list and disabling submit button
        let sourcingMissing =  list.filter(function (el) { return el.sourcingError === true; });
        if(sourcingMissing.length) isButtonEnabled = 'disabled'
        const selectedUsers = { 
            approverList,
            coInternalNotifyUsers,
            coExternalNotifyUsers
            }
        const coTemplateTooltipText = "Create templates of approvers and notifiers. Build your list then hit the save icon to store as a preset."

        const disableMajorityApproval= this.props?.ldClient?.allFlags()?.["disableMajorityApproval"];
        const disableFirstInApproval = this.props?.ldClient?.allFlags()?.["disableFirstInApproval"];
        const disableUnanimousApproval = this.props?.ldClient?.allFlags()?.["disableUnanimousApproval"];
        
        const disableOtherOptions = this.state.isMandatoryTemplate;
        const isMandatoryType = (type) => this.state.isMandatoryTemplate && this.state.approvalType === type;
        const unanimousIsMandatory = isMandatoryType("Unanimous");
        const majorityIsMandatory = isMandatoryType("Majority");
        const firstInIsMandatory = isMandatoryType("First-In");

        let markup = (
          <div className="change-order-form">
            <div className="banner-block">
              <div className="holder">
                <div>
                  <InlineIcon>
                    <EditIcon />
                  </InlineIcon>
                  <div className="edit-text">
                    You are Currently in Edit Mode.
                  </div>
                </div>
                <ButtonWrapper>
                  <CustomButton
                    color="secondary"
                    onClick={this.onCancel}
                    variant={ButtonVariants.OUTLINED}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    color="secondary"
                    disabled={
                      !(inputs.enableDraft === true && isDocumentsUploaded)
                    }
                    name="draft"
                    onClick={this.onSubmit}
                    onKeyPress={this.onKeyPress}
                    type="submit"
                    variant={ButtonVariants.CONTAINED}
                  >
                    Save Draft
                  </CustomButton>
                  <CustomButton
                    color="secondary"
                    disabled={isButtonEnabled === "disabled"}
                    name="submitted"
                    onClick={this.submitForApproval}
                    onKeyPress={this.onKeyPress}
                    variant={ButtonVariants.CONTAINED}
                  >
                    Submit for Approval
                  </CustomButton>
                </ButtonWrapper>
              </div>
            </div>
            <div className="new-changeorder-route app-row edit-component-route edit-changeorder-route-block">
              <div className="main-block">
                <div className="content-block">
                  <form>
                    <div className="content-inner-block">
                      {this.state.showCoToolTip && (
                        <Tooltip>
                          <div className="tool-tip-co">
                            {coTemplateTooltipText}
                          </div>
                        </Tooltip>
                      )}
                      <div className="heading-holder flex">
                        <div>
                          {mode === "edit" ? (
                            <h1>{co.name}</h1>
                          ) : (
                            <h1>New Change Order</h1>
                          )}
                        </div>
                        {mode === "new" && (
                          <div data-tip="Help" className="help-icon-container">
                            <Link
                              to={{
                                pathname:
                                  "https://duro.zendesk.com/hc/en-us/articles/360029600032-Change-Orders",
                              }}
                              target="_blank"
                            >
                              <InlineIcon>
                                <HelpIcon />
                              </InlineIcon>
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="fields-block">
                        <form>
                          <div className="fields-block-holder">
                            <label className="muted-text" htmlFor="types">
                              {mode === "edit"
                                ? "Category Type:"
                                : "Start by choosing a category type:*"}
                            </label>

                            <Types
                              name="types"
                              list={list}
                              onChange={this.onInputChange}
                              value={inputs.types.value}
                              disabled={inputs.types.disabled}
                              loadCoTemplates={this.loadCoTemplates}
                            />

                            <label htmlFor="name" className="input-label">
                              Name*
                            </label>

                            <LazyInput
                              name="name"
                              placeholder="Enter name"
                              value={inputs.name.value}
                              onChange={this.onInputChange}
                              className={inputs.name.class}
                              data-tip={inputs.name.message}
                              data-place="right"
                              data-type="error"
                            />

                            <label
                              htmlFor="description"
                              className="input-label"
                            >
                              Description
                            </label>

                            <TextArea
                              name="description"
                              placeholder="Enter Description (Optional)"
                              value={inputs.description.value}
                              onChange={this.onInputChange}
                              className={inputs.description.class}
                              data-tip={inputs.description.message}
                              data-place="right"
                              data-type="error"
                            />
                          </div>
                          <ErpOptions
                            onInputChange={this.onInputChange}
                            erpOptions={inputs.erpOptions}
                          />
                        </form>
                      </div>
                    </div>
                    <EditTabsContextProvider currentItem={co}>
                      <div className="tabs-block-holder">
                        <div className="rev-block nav-block">
                          <div
                            className={
                              view === "childrenData" ? "selected" : ""
                            }
                            onClick={() => this.show("childrenData")}
                          >
                            <div className="prd-cmp-holder">
                              <InlineIcon>
                                <PrdCmpIcon />
                              </InlineIcon>
                              <h2>Products & Components</h2>
                            </div>
                          </div>

                          <div
                            className={
                              view === "approverList" ? "selected" : ""
                            }
                            onClick={() => this.show("approverList")}
                          >
                            <div className="notification-holder">
                              <InlineIcon>
                                <ApproverIcon />
                              </InlineIcon>
                              <h2>Approver List</h2>
                            </div>
                          </div>

                          <div
                            className={
                              view === "notificationList" ? "selected" : ""
                            }
                            onClick={() => this.show("notificationList")}
                          >
                            <div className="notification-holder">
                              <InlineIcon>
                                <NotificationIcon />
                              </InlineIcon>
                              <h2>Notification List</h2>
                            </div>
                          </div>
                          <div
                            className={view === "documents" ? "selected" : ""}
                            name="documents"
                            onClick={() => this.show("documents")}
                          >
                            <div className="documents-holder">
                              <Icon
                                src={documentSrc}
                                className={
                                  view === "documents" ? "" : "deactive"
                                }
                              />
                              <h2>Documents</h2>
                            </div>
                          </div>
                          {mode == "edit" && (
                            <div
                              className={view === "history" ? "selected" : ""}
                              onClick={() => this.show("history")}
                            >
                              <div>
                                <InlineIcon className={"active"}>
                                  <RevsIcon />
                                </InlineIcon>
                                <h2>History</h2>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="view-block tabs-block">
                          {view === "childrenData" && (
                            <div
                              className={
                                showLoading ? "disable-pointer-events" : ""
                              }
                            >
                              <section className="check-parent-assembly-holder">
                                <div className="prd-cmp-text-holder">
                                  <p>
                                    Add Products and Components to Your Change
                                    Order
                                  </p>
                                </div>
                                <div className="assemblies-block">
                                  <div className="item-label">
                                    <input
                                      type="checkbox"
                                      name="check-parent-assembly"
                                      data-tip="Add parent assemblies to Change Order for all listed Components"
                                      data-place="top"
                                      checked={
                                        changeForm.search.parentAssemblyFlag
                                      }
                                      onClick={this.parentAssemblyHandler}
                                    />
                                    <label htmlFor="check-parent-assembly" />
                                    <div className="label-heading">
                                      Include Parent Assemblies
                                    </div>

                                    <input
                                      type="checkbox"
                                      name="include-children"
                                      data-tip="Add children assemblies to Change Order for all listed Components"
                                      data-place="top"
                                      checked={
                                        changeForm.search.childrenAssemblyFlag
                                      }
                                      onClick={this.childrenAssemblyHandler}
                                    />
                                    <label htmlFor="include-children" />
                                    <div className="label-heading">
                                      Include Children Components
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <List
                                list={list}
                                coId={co && co._id}
                                getSpecificChildrenAssemblies={
                                  this.getSpecificChildrenAssemblies
                                }
                                onChange={this.onInputChange}
                                searchResults={searchResults}
                                syncSearchInputsWithList={
                                  this.syncSearchInputsWithList
                                }
                                name="list"
                                showLoading={showLoading}
                                user={user}
                                dispatch={dispatch}
                                imagesWithSrc={this.imagesWithSrc}
                                refRelease={co && co.refRelease}
                                coType={inputs.types.value}
                                loadCoTemplates={this.loadCoTemplates}
                              />
                            </div>
                          )}

                          {view === "approverList" && (
                            <div>
                              <section className="co-approver-list">
                                <div className="approver-holder">
                                  <p>
                                    Add Users to Approve or Reject This Change
                                    Order.
                                  </p>
                                </div>
                                <div className="approval-type-block">
                                  <div
                                    className={
                                      this.state.showOneApprovalType
                                        ? "one-approval-type"
                                        : "item-label"
                                    }
                                  >
                                    <div className="label-heading">
                                      Approval type:
                                    </div>

                                    {!disableUnanimousApproval && (
                                      <div
                                        className={`type-holder ${
                                          unanimousIsMandatory
                                            ? "selected"
                                            : disableOtherOptions
                                            ? "disabled"
                                            : ""
                                        }`}
                                      >
                                        <input
                                          type="checkbox"
                                          name="Unanimous"
                                          checked={
                                            this.state.approvalType ===
                                            "Unanimous"
                                          }
                                          data-tip=""
                                          data-place="top"
                                          onChange={(e) =>
                                            this.onApprovalType(e)
                                          }
                                          disabled={disableOtherOptions}
                                        />
                                        <label htmlFor="Unanimous" />
                                        <span>Unanimous</span>
                                      </div>
                                    )}

                                    {!disableMajorityApproval && (
                                      <div
                                        className={`type-holder ${
                                          majorityIsMandatory
                                            ? "selected"
                                            : disableOtherOptions
                                            ? "disabled"
                                            : ""
                                        }`}
                                      >
                                        <input
                                          type="checkbox"
                                          name="Majority"
                                          checked={
                                            this.state.approvalType ===
                                            "Majority"
                                          }
                                          data-tip=""
                                          data-place="top"
                                          onChange={(e) =>
                                            this.onApprovalType(e)
                                          }
                                          disabled={disableOtherOptions}
                                        />
                                        <label htmlFor="Majority" />
                                        <span>Majority</span>
                                      </div>
                                    )}

                                    {!disableFirstInApproval && (
                                      <div
                                        className={`type-holder ${
                                          firstInIsMandatory
                                            ? "selected"
                                            : disableOtherOptions
                                            ? "disabled"
                                            : ""
                                        }`}
                                      >
                                        <input
                                          type="checkbox"
                                          name="First-In"
                                          checked={
                                            this.state.approvalType ===
                                            "First-In"
                                          }
                                          data-tip=""
                                          data-place="top"
                                          onChange={(e) =>
                                            this.onApprovalType(e)
                                          }
                                          disabled={disableOtherOptions}
                                        />
                                        <label htmlFor="First-In" />
                                        <span>First-In</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <ApproverListTable
                                  enableSaveButton={this.enableSaveButton}
                                  coApproverList={approverList}
                                  onRemoveApprover={this.onRemoveApprover}
                                  user={user}
                                  co={co}
                                  imagesWithSrc={this.imagesWithSrc}
                                />
                              </section>
                            </div>
                          )}

                          {view === "notificationList" && (
                            <div>
                              <section className="co-internal-notification-users">
                                <div className="notify-holder">
                                  <p>
                                    Add users to be notified with final change
                                    order result.
                                  </p>
                                  {approverList.length > 0 && (
                                    <p>
                                      Note: Users in your{" "}
                                      <span
                                        className="approver-list-link"
                                        onClick={() =>
                                          this.show("approverList")
                                        }
                                      >
                                        approver list
                                      </span>{" "}
                                      will automatically be notified.
                                    </p>
                                  )}
                                </div>
                                <UserNotificationTable
                                  {...this.props}
                                  enableSaveButton={this.enableSaveButton}
                                  coInternalNotifyUsers={
                                    this.state.coInternalNotifyUsers
                                  }
                                  user={user}
                                  co={co}
                                  onRemoveUsers={this.onRemoveUsers}
                                  coExternalNotifyUsers={
                                    this.state.coExternalNotifyUsers
                                  }
                                  onRemoveExternalUsers={
                                    this.onRemoveExternalUsers
                                  }
                                  imagesWithSrc={this.imagesWithSrc}
                                  isMandatoryTemplate={
                                    this.state.isMandatoryTemplate
                                  }
                                  mandatoryExternalUsers={
                                    this.state.mandatoryExternalUsers
                                  }
                                />
                              </section>
                            </div>
                          )}

                          {
                            <div>
                              <section
                                className={`co-documents ${
                                  view === "documents" ? "" : "hidden"
                                }`}
                              >
                                <div className="doc-holder">
                                  <p>
                                    Add supporting documents to this change
                                    order. Documents attached to the<br></br>{" "}
                                    listed{" "}
                                    <span
                                      className="products-and-components-link"
                                      onClick={() => this.show("childrenData")}
                                    >
                                      Products and Components
                                    </span>{" "}
                                    will automatically be included in this
                                    change order.
                                  </p>
                                </div>
                                <Documents
                                  {...documentsProps}
                                  cmptype="co"
                                  coId={this.props.co._id}
                                  styleName="coDocumentsTable"
                                />
                              </section>
                            </div>
                          }

                          {mode === "edit" && view === "history" && (
                            <HistoryTable
                              co={co}
                              user={user}
                              imagesWithSrc={this.imagesWithSrc}
                            />
                          )}
                        </div>
                      </div>
                    </EditTabsContextProvider>
                  </form>
                </div>
              </div>

              <div className={view !== "childrenData" ? "hidden" : ""}>
                <COSearchMenu
                  list={list}
                  coId={co && co._id}
                  mode={mode}
                  add={this.add}
                  refRelease={co && co.refRelease}
                />
              </div>
              {view === "approverList" && (
                <div className={view !== "approverList" ? "hidden" : ""}>
                  <AddUsersList
                    usersList={this.props.users.users}
                    user={user}
                    onAddUsers={this.onAddApprover}
                    disabledUserIdz={this.state.disabledApproverIdz}
                    heading="Add Approvers to Change Order"
                    addUserAfterNewUserCreation={
                      this.addUserAfterNewUserCreation
                    }
                    displayMailBlock={false}
                    listName="approver"
                    permittedUsers={[
                      "SUPER ADMINISTRATOR",
                      "ADMINISTRATOR",
                      "USER",
                      "APPROVER",
                      "SUPPLIER",
                      "VENDOR",
                    ]}
                    templates={this.state.templates}
                    approvalTypeFromTemplate={this.approvalTypeFromTemplate}
                    approvalType={this.state.approvalType}
                    selectedUsers={selectedUsers}
                    updateListsTemplates={this.updateListsTemplates}
                    setSelectedTemplateName={this.setSelectedTemplateName}
                    selectedTemplateName={this.state.selectedTemplateName}
                    enableSave={this.state.enableSave}
                    enableSaveButton={this.enableSaveButton}
                    displayCoTooltip={this.displayCoTooltip}
                    setCoTemplates={this.setCoTemplates}
                    loadTemplate={this.loadTemplate}
                    coTemplatesMappings={this.state.coTemplatesMappings}
                  />
                </div>
              )}

              {view === "notificationList" && (
                <div className={view !== "notificationList" ? "hidden" : ""}>
                  <AddUsersList
                    usersList={this.props.users.users}
                    user={user}
                    onAddUsers={this.onAddUsers}
                    disabledUserIdz={this.state.disabledUserIdz}
                    onAddExternalUserEmails={this.onAddExternalUserEmails}
                    addUserAfterNewUserCreation={
                      this.addUserAfterNewUserCreation
                    }
                    heading="Add Users to Notify"
                    displayMailBlock={true}
                    listName="notification"
                    permittedUsers={[
                      "SUPER ADMINISTRATOR",
                      "ADMINISTRATOR",
                      "USER",
                      "APPROVER",
                      "REVIEWER",
                      "SUPPLIER",
                      "VENDOR",
                    ]}
                    templates={this.state.templates}
                    approvalTypeFromTemplate={this.approvalTypeFromTemplate}
                    approvalType={this.state.approvalType}
                    selectedUsers={selectedUsers}
                    updateListsTemplates={this.updateListsTemplates}
                    setSelectedTemplateName={this.setSelectedTemplateName}
                    selectedTemplateName={this.state.selectedTemplateName}
                    enableSave={this.state.enableSave}
                    enableSaveButton={this.enableSaveButton}
                    displayCoTooltip={this.displayCoTooltip}
                    setCoTemplates={this.setCoTemplates}
                    loadTemplate={this.loadTemplate}
                    coTemplatesMappings={this.state.coTemplatesMappings}
                  />
                </div>
              )}
              {showCoTemplatePopover && !loading && (
                <CoTemplatePopover
                  isOpen={showCoTemplatePopover}
                  hidePopOver={this.hidePopOver}
                  template={popOverTemplate}
                  action={action}
                  coRule={popOverCoRule}
                  setPreviousData={this.setPreviousData}
                />
              )}
              {this.state.initialTemplateModal && (
                <MandatoryApproverNotifier
                  template={currentTemplate}
                  onModalCancel={this.onInitialModalCancel}
                  coRule={currentCoRule}
                  setPreviousData={this.setPreviousData}
                />
              )}
              {showOverrideWarningModal && (
                <div className="OverrideWarningModal">
                  <WarningModal
                    okButtonText={"Continue"}
                    onCancel={this.onCancelOverrideModal}
                    onConfirm={this.onConfirmOverrideModal}
                    isErrorIcon={true}
                    title={overrideWarningModalTitle}
                    description={overrideWarningModalDescription}
                  />
                </div>
              )}
            </div>
          </div>
        );

        return markup
    }
}

const mapStateToProps = state => ({
    loading: state.ui.loading,
});
export default connect(mapStateToProps)(withLDConsumer({ clientOnly: true })(ChangeOrderForm));

