import "./index.scss"
import React, {Component}   from "react"
import PRODUCT              from "../../../../../action-types/product"
import buildAction          from "../../../../../helpers/buildAction"
import Link                 from "../../../../ui/link"
import Utils                from "../../../../../modules/utils"
import Icon                 from "../../../../ui/icon"
import coSrc                from "../../../../../assets/icons/co.svg"
import ItemDeleteModal      from "../../../common/view-action-items/prod-co-cpn-delete-modal"
import WarningModal         from "../../../../ui/warning-modal";
import EllipsisTooltip      from "../../../common/ellipsis-tooltip"
import CTAIconToolbar       from "../../../common/cta-icon-toolbar"


class Actions extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            revModal :
            {
                open : false
            },
            openVariantModal: false,
            deleteModal: false,
            modifiedRevision: false,
            showEditWarningWhileInCO: false,
        }

        this.openRevModal = this.openRevModal.bind(this)
        this.closeRevModal = this.closeRevModal.bind(this)
        this.onRevModalExternalClick = this.onRevModalExternalClick.bind(this)
        this.gotoRevision = this.gotoRevision.bind(this)
        this.redirect = this.redirect.bind(this)
        this.openVariantModal = this.openVariantModal.bind(this);
        this.closeVariantModal= this.closeVariantModal.bind(this);
        this.createVariant    = this.createVariant.bind(this);
        this.makeDuplicate    = this.makeDuplicate.bind(this);
        this.toggleFavorite   = this.toggleFavorite.bind(this)
        this.addToChangeOrder = this.addToChangeOrder.bind(this)
        this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this)
        this.closeDeleteModal = this.closeDeleteModal.bind(this)
        this.deleteProduct    = this.deleteProduct.bind(this)
        this.showEditWarningWhileInCO = this.showEditWarningWhileInCO.bind(this)
        this.onRejectEditWhileInCO    = this.onRejectEditWhileInCO.bind(this)
        this.export           = this.export.bind(this);
    }

    redirect(path, state)
    {
        document.body.removeEventListener("click", this.onRevModalExternalClick)
        this.props.history.push({pathname: path, state: state})
    }

    openRevModal(event)
    {
        document.body.addEventListener("click", this.onRevModalExternalClick)
        let state = this.state
        state.revModal.open = true
        if(event && event.target.name === "modified-revision") state.modifiedRevision = true
        this.setState(this.state)
    }

    closeRevModal()
    {
        document.body.removeEventListener("click", this.onRevModalExternalClick)
        let state  = this.state
        state.revModal.open = false
        state.modifiedRevision = false
        this.setState(state)
    }

    onRevModalExternalClick(event)
    {
        if (this.refs.revTableNode)
        {
            let target     = event.target
            let parent     = this.refs.revTableNode.refs.revModalNode
            let isExternal = target !== parent && !Utils.isDescendant(parent, target)
            if(isExternal) this.closeRevModal()
        }
    }

    gotoRevision(id, isMostRecent=false)
    {
        if (isMostRecent)
        {
            this.redirect(`/product/view/${id}${ window.__userRole === "SUPPLIER" ? '?viewRecent=true' : ''}`)
        }
        else
        {
            this.redirect(`/product/revision/${id}`)
        }
    }

    openVariantModal()
    {
        this.setState({ openVariantModal: true });
    }

    closeVariantModal()
    {
        this.setState({ openVariantModal: false });
    }

    createVariant(productId)
    {
        const { dispatch, history } = this.props;
        let payload = {productId, history }
        dispatch(buildAction(PRODUCT.CREATE_VARIANT, payload))
    }

    makeDuplicate()
    {
        let { product, dispatch, history } = this.props;
        dispatch(buildAction(PRODUCT.DUPLICATE, { productId: product._id, history }));
    }

    toggleFavorite(user)
    {
        const {product, dispatch} = this.props
        dispatch(buildAction(PRODUCT.TOGGLE_FAVORITE, { user: user, product }));
    }

    onDeleteClickHandler(event)
    {
        this.setState({ deleteModal: true });
    }

    closeDeleteModal()
    {
        this.setState({ deleteModal: false });
    }

    deleteProduct()
    {
        this.setState({ deleteModal: false }, this.props.deleteProduct())
    }

    addToChangeOrder(event)
    {
        event.preventDefault();
        let data =
        {
            children : [ this.props.product ]
        }
        this.props.addToChangeOrder(data);
    }

    showEditWarningWhileInCO()
    {
        this.setState({showEditWarningWhileInCO: true});
    }

    onRejectEditWhileInCO()
    {
        this.setState({showEditWarningWhileInCO: false});
    }

    export(event)
    {
       event.preventDefault()
       let data =
       {
           data :
           [
               this.props.revision
           ],
           mode: 'revision'
       }
       this.props.history.push("/export", data)
    }

    render()
    {
        let { product, revision, user } = this.props;
        user = user && user.data ? user.data : null;
        let releasedRev = Utils.getLastReleasedRevision(product.revisions)

        let disabledRevisionId = null
        let oldRevision  = product.revisions.slice(-1)[0]
        if (oldRevision && product.modified === false)
            disabledRevisionId = oldRevision._id

        let productEditLink = `/product/edit/${product._id}`;
        let userRole = window.__userRole;
        let isRestrictedRole = Utils.checkForRestrictedRole(userRole);

        let favs = user.preferences.favorite.products
        let markup =
            <div className="actions-block">

                {
                    (releasedRev._id ===  revision._id && user && isRestrictedRole) ? null :
                    disabledRevisionId !== revision._id &&
                    <div className="banner-block">
                        <div className="app-row">
                            <div>
                                <Icon src={coSrc} />
                                <div>
                                    Currently viewing Revision
                                    <span> {revision.revision} </span>
                                    from
                                    <span> {Utils.dateInUSFormat(revision.created)}</span>
                                </div>
                            </div>
                            <div>
                                <Link to={`${isRestrictedRole ? `/product/revision/${releasedRev._id}` : `/product/view/${product._id}`}`}>
                                    <button>{`${isRestrictedRole ? 'VIEW LATEST RELEASE' : 'VIEW MOST RECENT'}`}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }

                <div className={`nav-block app-row ${releasedRev._id ===  revision._id && user && user.role === "SUPPLIER" ? 'released-revision' : ''}`}>
                    {/* <div>
                        Products / <CpnField item={product} cpn={product.cpn}/>
                    </div> */}
                    <EllipsisTooltip
                        classes={"ellipsis-tooltip tooltip-no-width"}
                        title={revision.name}
                        innerClasses={"truncate-name"}
                    >
                        { revision.name }
                    </EllipsisTooltip>
                    <CTAIconToolbar
                        { ...this.props }
                        user={user}
                        item={product}
                        itemName="product"
                        itemEditLink={productEditLink}
                        disabledRevisionId={disabledRevisionId}
                        gotoRevision={this.gotoRevision}
                    />
                </div>
                {
                    this.state.deleteModal &&
                    <ItemDeleteModal
                        onCloseDeleteModal={this.closeDeleteModal}
                        onDeleteBtnClick={this.deleteProduct}
                        headingTxt="Delete Product?"
                        bodyTxt="Deleting a Product permanently removes all data
                        including attachments. This can’t be undone."
                    />
                }
                {
                    this.state.showEditWarningWhileInCO &&
                        <WarningModal
                            onCancel={this.onRejectEditWhileInCO}
                            linkTo={productEditLink}
                            isWarningIcon={true}
                            title={"Warning"}
                            description={"This product is currently in an unresolved Change Order. Any edits could impact the intention of the Change Order."}
                        />
                }
            </div>
        return markup
    }
}

export default Actions


