import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, Icon, Typography, styled } from "@mui/material";
import { CustomContentProps, useSnackbar } from "notistack";
import { ReactNode, forwardRef, useCallback, useMemo } from "react";
import { EnqueueToastArgs } from "./useToasts";

export const DefaultToast = forwardRef<HTMLDivElement, CustomContentProps & EnqueueToastArgs>((props, ref) => {
  const {
    body, className, customIcon, hideIconVariant, iconVariant, id, message, showSpinner, style, variant,
  } = props;

  const { closeSnackbar } = useSnackbar();

  const icon: ReactNode | undefined = hideIconVariant ? undefined : iconVariant[variant];

  const onClose = useCallback(() => closeSnackbar(id), [closeSnackbar, id]);

  const iconColor = useMemo(() => {
    switch (variant) {
      case "error":
      case "warning":
        return variant;
      default: return "primary";
    }
  }, [variant]);

  return (
    <Content key={id} className={className} style={style} ref={ref}>
      <Header addBottomMargin={!!body}>
        {showSpinner && <CircularProgress size={"1rem"} thickness={8} />}
        {icon && <IconContainer color={iconColor}>{icon}</IconContainer>}
        {customIcon && <IconContainer color={iconColor}>{customIcon}</IconContainer>}
        <Message>{message}</Message>
        <CloseIcon onClick={onClose} fontSize="medium" />
      </Header>
      {body && <div>{body}</div>}
    </Content>
  );
});

const Content = styled(Box)(({ theme }) => ({
  backgroundColor: theme.duro.toasts.backgroundColor,
  borderRadius: "0.25rem",
  display: "flex",
  flexDirection: "column",
  padding: "0.5rem",
}));

const Header = styled(Box, {
  shouldForwardProp: propName => propName !== "addBottomMargin",
})<{ addBottomMargin: boolean }>(({ addBottomMargin }) => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  marginBottom: addBottomMargin ? "0.25rem" : undefined,
  "& :not(:last-child)": {
    marginRight: "0.25rem",
  },
}));

const IconContainer = styled(Icon)(() => ({
  alignItems: "center",
  display: "flex",
}));

const Message = styled(Typography)(() => ({
  flex: 1,
  fontSize: "0.875rem",
  whiteSpace: "nowrap",
}));
