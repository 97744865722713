import React, {Component} from "react";
import "./index.scss";
import { connect }        from "react-redux";
import ProjectsModal      from "./projects-modal";
import WorkspacesModal    from "./workspaces-modal";
import ReviewScreen       from "./review-screen-modal";
import ComponentsView     from "./components-view-modal";
import ModalBox           from "../../../../ui/modal-box";
import API                from "../../../../../modules/api";
import Utils              from "../../../../../modules/utils";
import UI                 from '../../../../../action-types/ui';
import * as Constants     from "../../../../../modules/constants";
import buildAction        from "../../../../../helpers/buildAction";

class ValispaceModal extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            step : "workspaces-list",
            selectedResults: {},
            selectedWorkspace: {},
            selectedProject: {}
        }
        this.onClose      = this.onClose.bind(this);
        this.setStep      = this.setStep.bind(this);
        this.showAlert    = this.showAlert.bind(this);
        this.setImagesData = this.setImagesData.bind(this);
        this.setDocumentsData = this.setDocumentsData.bind(this);
        this.importComponents = this.importComponents.bind(this);
        this.renderReviewScreen  = this.renderReviewScreen.bind(this);
        this.renderProjectsModal = this.renderProjectsModal.bind(this);
        this.renderValispaceData = this.renderValispaceData.bind(this);
        this.renderComponentsModal = this.renderComponentsModal.bind(this);
    }

    onClose()
    {
        this.props.onClose("displayValispaceImport", false);
    }

    setStep(step) {
        this.setState({ step });
    }

    renderProjectsModal(selectedWorkspace) {
        const state = {
            step: "projects-list",
            selectedWorkspace: {
                id: selectedWorkspace.id,
                name: selectedWorkspace.name
            }
        };
        this.setState(state);
    }

    renderComponentsModal(selectedProject) {
        this.setState({
            step: "components-list",
            selectedProject
        });
    }

    renderReviewScreen(selectedResults) {
        this.setState({
            step: "review-screen",
            selectedResults
        });
    }

    renderValispaceData()
    {
        let markup = "";
        let state = this.state;
        switch(state.step)
        {
            case "workspaces-list" :
            {
                markup = <WorkspacesModal
                    name="workspaces-modal"
                    onClose={this.onClose}
                    renderProjectsModal={this.renderProjectsModal}
                    selectedWorkspace={state.selectedWorkspace}
                    headerText = {this.props.toggleModalOnComponentViewPage ? "Step 1 of 3: Select a Workspace" : "Step 1 of 4: Select a Workspace"}
                />
                break;
            }
            case "projects-list" :
            {
                markup = <ProjectsModal
                    name="projects-modal"
                    onClose={this.onClose}
                    setStep={this.setStep}
                    selectedWorkspace={state.selectedWorkspace}
                    selectedProject={state.selectedProject}
                    renderComponentsModal={this.renderComponentsModal}
                    headerText = {this.props.toggleModalOnComponentViewPage ? "Step 2 of 3: Select a Project" : "Step 2 of 4: Select a Project"}

                />
                break;
            }
            case "components-list" :
            {
                markup = <ComponentsView
                    name="components-view-screen"
                    onClose={this.onClose}
                    import={this.import}
                    setStep={this.setStep}
                    selectedResults={state.selectedResults}
                    selectedWorkspace={state.selectedWorkspace}
                    selectedProject={state.selectedProject}
                    renderReviewScreen={this.renderReviewScreen}
                    toggleModalOnComponentViewPage = {this.props.toggleModalOnComponentViewPage ? this.props.toggleModalOnComponentViewPage : false}
                    component ={this.props.component ? this.props.component : null}
                    showAlert={this.showAlert}
                    heading = {this.props.toggleModalOnComponentViewPage ? "Select a Component" : "Select Components"}
                    buttonText = {this.props.toggleModalOnComponentViewPage ? "Map" : "Continue"}
                    headerText = {this.props.toggleModalOnComponentViewPage ? "Step 3 of 3: Select a Component" : "Step 3 of 4: Select Components"}
                />
                break;
            }
            case "review-screen" :
            {
                markup = <ReviewScreen
                    name="review-screen"
                    data={state.selectedResults}
                    onClose={this.onClose}
                    selectedWorkspace={state.selectedWorkspace}
                    selectedProject={state.selectedProject}
                    importComponents={this.importComponents}
                    setStep={this.setStep}                    
                />
            }
        }
        return markup;
    }

    importComponents(inputs)
    {
        let components = [];
        let state = this.state;
        inputs.forEach((result, i) => {
            if(!result.toBeUpdated)
            {
                let data =
                {
                    revision    : Constants.default_revision,
                    status      : Constants.default_status,
                    eid         : "",
                    description : result.description,
                    images      : this.setImagesData(result.image),
                    documents   : this.setDocumentsData(result.documents),
                    manufacturers: [],
                    specs       : [],
                    children    : [],
                    category    : result.category,
                    name        : result.name,
                    vendor      : "Valispace",
                    vendorId    : result.id,
                    fileImport  : true,
                    projectId   : state.selectedProject.id,
                    parentId    : result.parent
                }
                components.push(data);
            }
            else
            {
                result.images = this.setImagesData(result.image);
                result.documents = this.setDocumentsData(result.documents);
                result['fileImport'] = true;
                result.parentId = result.parent;
                result.vendor   = "Valispace";
                result.children = result.children ? result.children : [];
                delete result['cpn'];
                components.push(result);
            }
        })

        let payload = {components: components, responseKeys: ["_id", "cpn"]};
        API.integrations.createWithData(payload, (err, data) => {
            if(err)
            {
                return;
            }
            this.showAlert("modal", "Components imported successfully");
            this.props.onDoneFromCmpHeaderNav("displayValispaceImport");
        });
    }

    showAlert(type, value, closeCb)
    {
        const {dispatch} = this.props
        if(type === "modal")
        {
            dispatch(buildAction(UI.SHOW_ALERT, {type: "modal", text: value, closeCb}))
        }

        if(type === "errors")
        {
            dispatch(buildAction(UI.SHOW_ALERT, {type: "errors", errors: value, err: new Error(value[0])}))
        }
    }

    setImagesData(image)
    {
        let images = [];
        if(image)
        {
            let name = image.split('?')[0].split('/').pop();
            images.push({name: name, src: image});
        }
        return images;
    }

    setDocumentsData(docs)
    {
        let documents = [];
        if(docs)
        {
            docs.forEach((doc) => {
                let extension = "";
                if(doc.extension)
                {
                    extension = doc.extension;
                }
                else
                {
                    const name = doc.download_url.split('?')[0].split('/').pop();
                    if(name.indexOf('.') > 0)
                    {
                        extension = name.split('.').pop();
                        extension = `.${extension}`;
                    }
                }
                documents.push({name: `${doc.name}${extension}`, src: doc.download_url, specs: {type: "Specification"}, mimetype: doc.mimetype ? doc.mimetype : Utils.getMimeType(extension)});
            })
        }
        return documents;
    }

    render()
    {
        let state = this.state;

        let markup =<div className="valispace-modal">
                    <ModalBox onClose={this.onClose} className={`${this.state.step} custom-modal`} bodyClass="valispace-component-bd">
                    {
                        this.renderValispaceData()
                    }
                    </ModalBox>
                    </div>
        return markup;
    }
}

export default connect((store) => store)(ValispaceModal)
