// Button to fetch and API key

import { FunctionComponent, useCallback, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ButtonVariants } from "common/constants";
import { client } from "graphql/apolloClient";
import { getApiToken } from "graphql/query/userQueries";

export interface IApiTokenProps {
}

export const ApiToken: FunctionComponent<IApiTokenProps> = (props: IApiTokenProps) => {
  const [token, setToken] = useState("");
  const onApiKeyClick = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    const { data } = await getApiToken(client);
    setToken(data?.token);
  }, [setToken]);

  return (
    <Box style={containerStyle}>
      <Button
        data-testid="get-api-token"
        variant={ButtonVariants.OUTLINED}
        onClick={onApiKeyClick}
      >
        Get API Key
      </Button>
      <Typography sx={tokenStyle} data-testid="api-token">
        {token}
      </Typography>
    </Box>
  );
};

// Styles
const containerStyle = {
  width: "80%",
};
const tokenStyle = {
  marginTop: "1rem",
  wordWrap: "break-word",
};
