import { Box } from "@mui/system";
import { DuroButton } from "common/components";
import { ButtonVariants } from "common/constants";

export interface ButtonFooterProps {
  onCancelClick: () => void;
  onConfirmClick: () => void;
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
  cancelColor: "info" | "inherit" | "primary" | "secondary" | "success" | "error" | "warning";
  confirmColor: "info" | "inherit" | "primary" | "secondary" | "success" | "error" | "warning";
}

export const ButtonFooter: React.FC<ButtonFooterProps> = ({
  onCancelClick,
  onConfirmClick,
  cancelDisabled = false,
  confirmDisabled = false,
  cancelButtonText,
  confirmButtonText,
  cancelColor = "info",
  confirmColor = "info",
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ position: "absolute", bottom: "0%", right: "0%" }}>
        <DuroButton
          variant={ButtonVariants.OUTLINED}
          sx={{mr: 1}}
          disabled={cancelDisabled}
          onClick={onCancelClick}
          color={cancelColor}
        >
          {cancelButtonText}
        </DuroButton>
        <DuroButton
          variant={ButtonVariants.OUTLINED}
          sx={{mr: 1}}
          disabled={confirmDisabled}
          onClick={onConfirmClick}
          color={confirmColor}
        >
          {confirmButtonText}
        </DuroButton>
      </Box>
    </Box>
  );
}