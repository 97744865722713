import { useMemo } from "react";
import { ApolloClient, FetchPolicy, gql, NormalizedCacheObject, useQuery } from "@apollo/client";
import { Company } from "common/models/company";
import { CompanyFragment } from "graphql/fragment/companyFragment";
import { getSubErrors } from "v1/modules/utils/errors";

export const GET_COMPANY = gql`
  query($id: ID) {
    companiesByIds(ids: [$id]) {
      ...companyFragment
    }
  }
  ${CompanyFragment}
`;

export const GET_PRIMARY_COMPANY = gql`
  query {
    userById {
      id
      primaryCompany {
        ...companyFragment
      }
    }
  }
  ${CompanyFragment}
`;

export async function getCompany(
  client: ApolloClient<NormalizedCacheObject>,
  id = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  try {
    const { data, errors, loading } = await client.query({
      query: GET_COMPANY,
      variables: { id },
      fetchPolicy,
    });
    const company = data?.companiesByIds?.[0] ?? {};
    return { data: company, errors, loading };
  }
  catch (e: any) {
    return {
      data: null,
      errors: getSubErrors(e),
      loading: false,
    };
  }
}

export function useCompany(
  id: string | null = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error, loading } = useQuery<{ companiesByIds: Company[] }>(GET_COMPANY, {
    variables: { id },
    fetchPolicy,
    skip: !id,
  });

  const company = useMemo(() => data?.companiesByIds?.[0], [data]);

  return { data: company, error, loading };
}
export function useCategory(
  libraryId: string | null = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error, loading } = useQuery<{ companiesByIds: Company[] }>(GET_COMPANY, {
    variables: { libraryId },
    fetchPolicy,
    skip: !libraryId,
  });

  const company = useMemo(() => data?.companiesByIds?.[0], [data]);

  return { data: company, error, loading };
}

export function usePrimaryCompany(fetchPolicy: FetchPolicy = "cache-first") {
  const { data, error, loading } = useQuery<{ userById: { primaryCompany: Company } }>(GET_PRIMARY_COMPANY, {
    fetchPolicy,
  });

  const company = useMemo(() => data?.userById?.primaryCompany, [data]);

  return { data: company, error, loading };
}
