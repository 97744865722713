import React, {Component} from "react";
import "./index.scss";
import SingleProject      from "./single-project";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import Spinner            from "../../../../../ui/spinner";
import API                from "../../../../../../modules/api";
import Utils              from "../../../../../../modules/utils";
import HeaderBanner       from "../../../../common/header-banner";

export default class ProjectsModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            loading: true,
            projects: [],
            isContinueEnabled: false,
            selectedProject: {}
        }
        this.backButton  = this.backButton.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.changeProject  = this.changeProject.bind(this);
        this.viewComponents = this.viewComponents.bind(this);
        this.renderProjects = this.renderProjects.bind(this);
    }

    componentDidMount()
    {
        this.getProjects();
    }

    getProjects()
    {
        let state = this.state;
        let data  = {workspaceId: this.props.selectedWorkspace.id};
        API.integrations.getValispaceProjects(data, (err, response) =>
        {
            if(err)
            {
                return;
            }
            state.projects = response;
            state.selectedProject.id = this.props.selectedProject.id ? this.props.selectedProject.id : response[0].id;
            state.selectedProject.name = this.props.selectedProject.name ? this.props.selectedProject.name : response[0].name;
            state.loading    = false;
            state.isContinueEnabled = true;
            this.setState(state);
        })
    }

    viewComponents()
    {
        this.props.renderComponentsModal(this.state.selectedProject);
    }

    renderProjects()
    {
        let state = this.state;
        let markup = [];
        state.projects.forEach((project) => {
            markup.push(<SingleProject project={project} selectedProject={state.selectedProject} changeProject={this.changeProject}/>);
        })
        return markup;
    }

    changeProject(value)
    {
        let state = this.state;
        state.selectedProject.id = value;
        this.setState(state);
    }

    backButton()
    {
        this.props.setStep("workspaces-list");
    }

    render()
    {
        let state = this.state;
        let workspaceName = this.props.selectedWorkspace.name;
        let continueButtonClass = `btn-continue ${state.isContinueEnabled ? "" : "disabled" }`;
        let { headerText }  = this.props;

        let markup =
                <div className="project-list">
                        <header className="valispace-modal-header">
                            <HeaderBanner text={headerText}/>
                            <div className="btn-container">
                                <button
                                        className="btn-back"
                                        onClick={this.backButton}>
                                        Back
                                </button>
                                <button
                                    className={continueButtonClass}
                                    onClick={this.viewComponents}>
                                    Continue
                                </button>
                            </div>
                        </header>
                        <div className="content-wrapper">
                            <div className="modal-heading">
                                <h1> Select a Project </h1>
                            </div>
                            {
                                state.loading ?
                                <Spinner /> :
                                <div className="details-wrapper">
                                    <div className="details-heading">
                                        <h3>VALISPACE <span>WORKSPACE PROJECTS</span></h3>
                                    </div>
                                    <div className="list-area">
                                        <h3> <span>Workspace: </span> {workspaceName} </h3>
                                        <PerfectScrollbar className="table-wrapper-scroll">
                                            <table className="projects-table" id="projects-table">
                                                <tbody>
                                                    {this.renderProjects()}
                                                </tbody>
                                            </table>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            }
                        </div>
                </div>
        return markup
    }
}
