// NOTE-1:
//Validate missing sources if previousStatus status is DESIGN and Current Status != DESIGN
//Since now user can set status and revision through bulk status update, therefore we are running validation
//For this particular user case.


import { takeEvery, takeLatest, all, cps, put, select, call }   from "redux-saga/effects"
import buildAction                                              from "../../../helpers/buildAction"
import API                                                      from "../../../modules/api"
import CO                                                       from '../../../action-types/changeorder'
import UI                                                       from "../../../action-types/ui"
import Utils                                                    from "../../../modules/utils"
import { getNewPageState }                                      from "./selector"
import validations, { validateField, schemas }                  from '../../../modules/validations'
import
{
    getChangeOrderObjectState
}    from '../common/selector.js'

import config from "../../../modules/config"


export function* submitForm(action)
{
    yield put(buildAction(CO.DISABLE_FORM, true))
    yield put(buildAction(UI.LOAD_START))
    let itemsWithError = [];

    try
    {
        let coId = null
        let type = action.payload.data.type
        let products = []
        let components = []
        let data = action.payload.data;
        let unChangedChildren = action.payload.data.unChangedChildren;
        let children = data.children
        let errors = []

        if (action.payload.current_page !== "view")
        {
            for (let item of children)
            {
                let unChanged = false;
                if(!unChangedChildren.hasOwnProperty('components')) unChangedChildren.components = [];
                if(!unChangedChildren.hasOwnProperty('products')) unChangedChildren.products = [];

                let unChangedChildrenList = item.alias === 'cmp' ? unChangedChildren.components :
                                            item.alias === 'prd' ? unChangedChildren.products : []

                if(!item.sourcingError && type.toUpperCase() !== 'DCO' && (item.status !== item.initialStatus || (item.nextCoRevision !== item.nextRevisionInput.value &&
                    ((item.hasOwnProperty("showRevision") && item.showRevision) || (!item.hasOwnProperty("showRevision")))) || item.forceUpdate))
                {
                    let revision = item.nextRevisionInput.value;
                    let nextCoRevision = item.nextRevisionInput.value;
                    let payload = {status: item.status, revision, modified: true, nextCoRevision, lastModified: item.lastModified};

                    if (item.status == item.initialStatus || item.forceUpdate)
                    {
                        if (item.alias === 'cmp') yield cps(API.components.setNextRevision, item._id, {nextCoRevision: item.nextRevisionInput.value});
                        if (item.alias === 'prd') yield cps(API.products.setNextRevision, item._id, {nextCoRevision: item.nextRevisionInput.value});
                    }
                    else
                    {
                        try
                        {
                            if (item.alias === 'cmp') yield cps(API.components.update, item._id, payload);
                            if (item.alias === 'prd') yield cps(API.products.update, item._id, payload);
                            Utils.resetLocalStorageForAssemblyTree();
                        }
                        catch(err)
                        {
                            itemsWithError.push({_id: item._id});
                        }
                    }
                }
                else if(item.hasOwnProperty("showRevision") && !item.showRevision)
                {
                    if(!unChangedChildrenList.includes(item._id)) unChangedChildrenList.push(item._id);
                    unChanged = true;
                }

                let index = unChangedChildrenList.indexOf(item._id);
                if (index !== -1 && !unChanged) unChangedChildrenList.splice(index, 1);
                if (item.alias === 'cmp') components.push(item._id);
                if (item.alias === 'prd') products.push(item._id);
            }
            data.children = {products: products, components: components}
            if(itemsWithError.length > 0)
                throw "Modified Error";
        }

        if (data.resolution === "APPROVED" && !action.payload.isCloseAction)
        {
            coId = yield cps(API.cos.approve, data._id, data)
            if (!coId)
            {
                yield* checkError(data);
                return
            }
        }
        else if (data.resolution === "REJECTED" && !action.payload.isCloseAction)
        {
            coId = yield cps(API.cos.reject, data._id, data)
            if (!coId)
            {
                yield* checkError(data);
                return
            }
        }
        else
        {
            coId = yield cps(API.cos.update, data._id, data)
            if(!coId && data._id)
            {
                yield* checkError(data);
                return
            }
        }

        yield put(buildAction(CO.RESET_CO_FORM, {}))

        if (action.payload.data.archived)
        {
            action.payload.history.push({pathname: "/search", state: {query: "type:co"}})
        }
        else
        {
            yield put(buildAction(CO.SET_CO_OBJECT, null))
            let co = yield cps(API.cos.findById, coId)
            yield put(buildAction(CO.SET_CO_OBJECT, co))
            action.payload.history.push("/changeorder/view/" + coId)
        }
    }
    catch(err)
    {
        const errorMessage = {
            401: 'Account failed to trigger request to Onshape. Please check Onshape permissions with your Onshape company administrator and try again.',
            408: 'Request to Onshape timed out. Please check your Onshape connectivity and try again.',
        };

        if(err === 'Modified Error')
        {
            let data = action.payload.data;
            data.status = "DRAFT";
            let co = yield cps(API.cos.findById, data._id);
            yield put(buildAction(CO.SET_CO_OBJECT, co));
            let payloadData =
            {
                type: "confirm",
                errorType: "warning",
                heading: "Warning",
                confirmButtonText: "OK",
                text: '<span className="save-warning-text">Some Products or Components have been modified since they were added to this Change Order.<br/> More details can be found in the Products and Components table.</span>'
            }
            yield put(buildAction(UI.SHOW_ALERT, payloadData));
            let cb = action.payload.cb;
            if(cb)
                cb(itemsWithError);
        }
        else if (err.errors && err.errors[0].message.includes('Request failed')) {
            let message = errorMessage[err.errors[0].status] || `Failed to trigger request on Onshape. Please check your Onshape connectivity and try again.`;
            let payloadData = {
                type: "errors",
                heading: "Warning",
                confirmButtonText: "OK",
                errors: [{
                        message: `Change Order cannot be closed. ${message}.`
                    }],
            };
            yield put(buildAction(UI.SHOW_ALERT, payloadData));
        }
        else {
            yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors || [err], err: err}));
        }
    }
    yield put(buildAction(UI.LOAD_END));
}

export function* checkError(data)
{
    const changeOrder = yield cps(API.cos.findById, data._id)
    yield put(buildAction(UI.LOAD_END))
    yield put(buildAction(CO.SET_CO_OBJECT, changeOrder))

    let errorsPayload =
    {
        type: "errors",
        errorType: "custom",
        donotThrowError: true,
        modalClass: 'changeorder-warning',
        errors: [{message: "Someone has recently modified this Change Order. Please refresh the page to get the latest changes."}]
    }
    yield put(buildAction(UI.SHOW_ALERT, errorsPayload))
}

export function* createCoWithDefaultData(action)
{
    yield put(buildAction(CO.RESET_CO_FORM))
    yield put(buildAction(CO.REST_CO_VIEW_STATE))
    yield put(buildAction(CO.SET_CO_OBJECT, null))

    try
    {
        let data =
        {
            "type" : "ECO",
            "name" : "",
            "status" : "DRAFT",
            "resolution" : "NONE",
            "approverList": [{user: action.payload.authorId}]
        }

        if (action.payload.addChildren)
        {
            data.children = action.payload.data.children
        }

        let coId = yield cps(API.cos.create, data)
        action.payload.history.push("/changeorder/new/"+ coId)

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors || [err], err: err}))
    }
}

export function* updateInputOnChange(action) {
    let target = action.payload.target
    let name   = target.name
    let value    = target.value
    let newPageState = yield select(getNewPageState)
    // let co = Utils.clone(action.payload.co)
    switch(name)
    {
        case "types" :
        {
            newPageState.inputs.types.value = value
            newPageState.inputs.types.valid = value.length > 0
            break
        }

      case "name" :
      {
            validateField(newPageState.inputs.name, validations.changeOrder.name, value)
            break
      }

      case "description" :
      {
            validateField(newPageState.inputs.description, validations.changeOrder.description, value)
            break
      }

      case "list" :
      {
            newPageState.list = value
            newPageState.listValid = target.listValid
            break
      }
      case "ChildComponentFlag":
      {
            newPageState.inputs.erpOptions.additionalPayloadForNotifications.childComponent.checked = target.checked;
            newPageState.co.erpOptions.additionalPayloadForNotifications.childComponent = target.checked;
            break;
      }
      case "ParentAssemblyFlag":
      {
                newPageState.inputs.erpOptions.additionalPayloadForNotifications.parentAssemblies.checked = target.checked;
                newPageState.co.erpOptions.additionalPayloadForNotifications.parentAssemblies=target.checked;
                break;
      }

			case "isEffectivityEnabled": {
						let startDate = newPageState.inputs.erpOptions.effectivity.startDate;
						let endDate = newPageState.inputs.erpOptions.effectivity.endDate;    
						if (endDate.value || startDate.value)  {
								validateField(endDate, validations.changeOrder.erpOptions.effectivity.endDate, endDate.value, { startDate: startDate.value, isEffectivityEnabled: target.checked })
								validateField(startDate, validations.changeOrder.erpOptions.effectivity.startDate, startDate.value, { endDate: endDate.value, isEffectivityEnabled: target.checked })
						}
						newPageState.inputs.erpOptions.effectivity.isEnabled.checked = target.checked;
						break;
			}

			case "isEffectivityOverrideEnabled" : {
						newPageState.inputs.erpOptions.effectivity.overrideExisting.checked = target.checked;
						break;
			}

			case "isItemTypeEnabled": {
						newPageState.inputs.erpOptions.itemType.isEnabled.checked = target.checked;
						break;
			}

			case "isItemTypeOverrideEnabled" : {
						newPageState.inputs.erpOptions.itemType.overrideExisting.checked = target.checked;
						break;
			}
			case "startDate" : {
						let isEffectivityEnabled = newPageState.inputs.erpOptions.effectivity.isEnabled.checked;
						let startDate = newPageState.inputs.erpOptions.effectivity.startDate
						let endDate = newPageState.inputs.erpOptions.effectivity.endDate 
						validateField(startDate, validations.changeOrder.erpOptions.effectivity.startDate, value, {endDate: endDate.value, isEffectivityEnabled})
						validateField(endDate, validations.changeOrder.erpOptions.effectivity.endDate, endDate.value, {startDate: startDate.value, isEffectivityEnabled})
						newPageState.inputs.erpOptions.effectivity.startDate.value = value;
						break;
			}

			case "endDate" : {
						let isEffectivityEnabled = newPageState.inputs.erpOptions.effectivity.isEnabled.checked;
						let startDate = newPageState.inputs.erpOptions.effectivity.startDate
						let endDate = newPageState.inputs.erpOptions.effectivity.endDate        
						validateField(endDate, validations.changeOrder.erpOptions.effectivity.endDate, value, {startDate: startDate.value, isEffectivityEnabled})
						validateField(startDate, validations.changeOrder.erpOptions.effectivity.startDate, startDate.value, {endDate: endDate.value, isEffectivityEnabled})
						newPageState.inputs.erpOptions.effectivity.endDate.value = value;
						break;
			}

			case "selectItemType" : {
						newPageState.inputs.erpOptions.itemType.value.value = value;
						break;
			}

      default :
      {
            // noop
        }
    }

    newPageState.inputs.submit.class = Utils.isValidated(newPageState.inputs) && newPageState.list.length > 0 && newPageState.listValid
    ? ""
    : "disabled"


    let isValidInputs = Utils.isValidated(newPageState.inputs)

    newPageState.inputs.enableDraft = ( newPageState.inputs.submit.class === 'disabled' && newPageState.listValid === true && isValidInputs || isValidInputs ? true : !(newPageState.inputs.submit.class === 'disabled'))

    if(action.payload.runValidation)
    {
        yield put(buildAction(CO.VALIDATE_CHANGE_ORDER_LIST, { newPageState }));
    }
    else
    {
        yield put(buildAction(CO.UPDATE_FORM_STATE, newPageState));
    }
}

export function* deleteChangeOrder(action)
{
    try
    {
        let res = yield cps(API.cos.delete, action.payload.id)
        if (!action.payload.donotNavigateToSearch === true)
            action.payload.history.push({pathname: "/search", state: {query: "type:co"}})
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

export function* autoSaveInDraft(action)
{
    try
    {
        // yield call(Utils.delay)
        let co = action.payload.co
        let newPageState = action.payload.newPageState

        let path = !window.location.pathname.match("/changeorder/view")

        if (newPageState && co && co.status === "DRAFT" && co.resolution === "NONE" && path && newPageState.inputs.enableDraft)
        {
            if (newPageState.inputs.types.valid)
            {
                co.type = newPageState.inputs.types.value
                co.con = newPageState.inputs.types.value + "-" + co.con.split("-")[1]
            }
            if (newPageState.inputs.name.valid) co.name = newPageState.inputs.name.value
            if (newPageState.inputs.description.valid) co.description = newPageState.inputs.description.value

            let products = []
            let components = []

            newPageState.list.forEach((item) => {
                if (item.alias === "prd")
                {
                   products.push(item._id)
                }
                else
                {
                   components.push(item._id)
                }
            })
            co.children.products = products
            co.children.components = components
            yield call(API.cos.autoSave, co._id, co)
        }
        yield put(buildAction(CO.RESET_CO_FORM))
        yield put(buildAction(CO.REST_CO_VIEW_STATE))
        yield put(buildAction(CO.SET_CO_OBJECT, null))
    }
    catch(err)
    {
        if (err && err.errors && err.errors[0].message === "already updated")
        {
            let errorsPayload =
            {
                type: "errors",
                errorType: "custom",
                donotThrowError: true,
                modalClass: 'changeorder-warning',
                errors: [{message: "Someone has recently modified this Change Order. Please refresh the page to get the latest changes."}]
            }
            yield put(buildAction(UI.SHOW_ALERT, errorsPayload))
            return
        }

        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

function syncAfterGettingParentAssemblies(list, searchInputs)
{
    for(let i=0 ; i < list.length; i++)
    {
        for(let j=0; j<searchInputs.length; j++)
        {
            if (list[i].cpn === searchInputs[j].cpn)
            {
                searchInputs[j].checked = true
                searchInputs[j].isAddedInList = true
            }
        }
    }
}

function disableFormSubmission(co, newPageState)
{
    if(co && co.length>0)
    {
        newPageState.listValid = false;
        newPageState.inputs.submit.class = "disabled";
    }
}

export function* getParentAssemblies(action)
{
    let newPageState = yield select(getNewPageState);
    let idz = [];
    let parentAssemblies = {parents: [], count: 0};
    let parents = [];

    newPageState.list.forEach((item) => {
        idz.push(item._id);
    })

    if (idz.length > 0)
    {
        yield put(buildAction(CO.SET_LOADING, {parentsLoading: true}));
        parentAssemblies = yield cps(API.cos.getParentAssemblies, { items: idz })

        let componentIds = [];
        let productIds = [];
        let componentIdsWithStatus = [];
        let productIdsWithStatus = [];

        parentAssemblies.parents.forEach((item) =>
        {
           if(item.obj && item.obj.alias === "cmp")
           {
                componentIds.push(item.obj._id);
                if (item.obj.previousStatus === "DESIGN" && item.obj.status !== "DESIGN") //See NOTE-1 above
                {
                    componentIdsWithStatus.push({id: item.obj._id, status: item.obj.status, alias: item.obj.alias});
                }
           }
           else if(item.obj && item.obj.alias === "prd")
           {
                productIds.push(item.obj._id);

                if (item.obj.previousStatus === "DESIGN" && item.obj.status !== "DESIGN") //See NOTE-1 above
                {
                    productIdsWithStatus.push({id: item.obj._id, status: item.obj.status, alias: item.obj.alias});
                }
           }
        })

        let componentsHavingCO = [];
        let productsHavingCO = [];
        let componentsHavingMissingSources = []
        let productsHavingMissingSources = []
        let res = {};

        if(componentIds.length > 0) res = yield cps(API.components.coExist, { items: componentIds })
        if(res && res.data) componentsHavingCO = res.data.componentsCO;

        res = {};
        if(productIds.length > 0) res = yield cps(API.products.coExist, { items: productIds });
        if(res && res.data) productsHavingCO = res.data.productsCO;


        if(componentIdsWithStatus.length > 0) res = yield cps(API.components.validateManufacturers, componentIdsWithStatus)
        if(res && res.data) componentsHavingMissingSources = res.data;


        if(productIdsWithStatus.length > 0) res = yield cps(API.products.validateManufacturers, productIdsWithStatus)
        if(res && res.data) productsHavingMissingSources = res.data;


        parentAssemblies.parents.forEach((item) =>
        {
            let co;
            if(item.obj.alias === "cmp" && componentsHavingCO.hasOwnProperty(item.obj._id))
            {
                co = excludeCurrentCO(componentsHavingCO[item.obj._id], newPageState.co._id);
            }
            else if(item.obj.alias === "prd" && productsHavingCO.hasOwnProperty(item.obj._id))
            {
                co = excludeCurrentCO(productsHavingCO[item.obj._id], newPageState.co._id);
            }
            disableFormSubmission(co, newPageState);
            item.obj.initialStatus = item.obj.status;
            item.obj.showRevision = true;
            item.obj.co = co;

            if(item.obj.alias === "cmp" && componentsHavingMissingSources.hasOwnProperty(item.obj._id) && componentsHavingMissingSources[item.obj._id].hasOwnProperty("error"))
            {
                item.obj.sourcingError = componentsHavingMissingSources[item.obj._id].error;
            }
            else if(item.obj.alias === "prd" && productsHavingMissingSources.hasOwnProperty(item.obj._id) && productsHavingMissingSources[item.obj._id].hasOwnProperty("error"))
            {
                item.obj.sourcingError = productsHavingMissingSources[item.obj._id].error;
            }

            parents.push(item.obj);
        })

        parentAssemblies.parents = setList(newPageState.unChangedChildren, parents);

        newPageState.list = [...newPageState.list, ...parentAssemblies.parents]
        syncAfterGettingParentAssemblies(newPageState.list, newPageState.search.inputs)
        let payload = {list: newPageState.list, inputs: newPageState.search.inputs, parentsLoading: false}
        yield put(buildAction(CO.SET_PARENT_ASSEMBLIES, payload));
    }
    newPageState = yield select(getNewPageState);
    if(!newPageState.search.childrenLoading)
    {
        yield put(buildAction(CO.GET_CHANGE_ORDER_LIST_DATA));
    }
}

export function* getChildrenAssemblies(action)
{
    let newPageState = yield select(getNewPageState);
    let items = [];
    let childrenAssemblies = {children: [], count: 0};
    let children = [];
    let excludedIds = [];
    if(action.payload && action.payload.items)
    {
        items = action.payload.items;
        newPageState.list.forEach((item) => {
            if(items[0]._id !== item._id) excludedIds.push(item._id);
        })
    }
    else
    {
        newPageState.list.forEach((item) => {
            let isAssembly = false;
            if(item.alias === "cmp")
            {
                let categoryType = "";
                if(item.category)
                {
                    categoryType = schemas.categories.getType2(item.category, window.__categories);
                }
                else
                {
                    let cat = schemas.categories.findByCode2(item.cpn.split("-")[0], window.__categories);
                    if(cat) categoryType = cat.type;
                }
                const isAllowedInPartAndAssembly = window.__categories.find(category => category.name === item.category).isAllowedInPartAndAssembly;
                if(categoryType == "ASSEMBLY" || isAllowedInPartAndAssembly ) isAssembly = true;
            }

            if(isAssembly || item.alias === "prd") items.push({ id: item._id, type: item.alias });
            else excludedIds.push(item._id);
        })
    }

    if(items.length > 0)
    {
        yield put(buildAction(CO.SET_LOADING, {childrenLoading: true}));
        childrenAssemblies = yield cps(API.cos.getChildrenAssemblies, { items, excludedIds});

        let componentsHavingCO = {};
        let componentsHavingMissingSources = {}
        let res = {};
        let componentIds = [];
        let componentIdsWithStatus = [];
        childrenAssemblies.children.forEach((item) => {
            componentIds.push(item.obj._id);
            if (item.obj.previousStatus === "DESIGN" && item.obj.status !== "DESIGN") //See NOTE-1 above
            {
                componentIdsWithStatus.push({id: item.obj._id, status: item.obj.status, alias: item.obj.alias, cpn: item.obj.cpn});
            }
        });

        if(componentIds.length > 0) res = yield cps(API.components.coExist, { items: componentIds });
        if(res && res.data) componentsHavingCO = res.data.componentsCO;

        if(componentIdsWithStatus.length > 0) res = yield cps(API.components.validateManufacturers, componentIdsWithStatus)
        if(res && res.data) componentsHavingMissingSources = res.data;

        childrenAssemblies.children.forEach((item) => {
            let co;
            if(componentsHavingCO.hasOwnProperty(item.obj._id))
            {
                co = excludeCurrentCO(componentsHavingCO[item.obj._id], newPageState.co._id);
            }

            if(componentsHavingMissingSources.hasOwnProperty(item.obj._id) && componentsHavingMissingSources[item.obj._id].hasOwnProperty("error"))
            {
                item.obj.sourcingError = componentsHavingMissingSources[item.obj._id].error;
            }

            disableFormSubmission(co, newPageState);
            item.obj.co = co;
            item.obj.initialStatus = item.status;
            item.obj.showRevision = true;
            children.push(item.obj);
        })

        childrenAssemblies.children = setList(newPageState.unChangedChildren, children);

        newPageState.list = [...newPageState.list, ...childrenAssemblies.children];
        syncAfterGettingParentAssemblies(newPageState.list, newPageState.search.inputs);
        let payload = {list: newPageState.list, inputs: newPageState.search.inputs, childrenLoading: false};
        yield put(buildAction(CO.SET_PARENT_ASSEMBLIES, payload));

        newPageState = yield select(getNewPageState);
        if(!newPageState.search.parentsLoading)
        {
            yield put(buildAction(CO.GET_CHANGE_ORDER_LIST_DATA));
        }
    }
    else if(!newPageState.search.parentsLoading && action.runValidation)
    {
        yield put(buildAction(CO.GET_CHANGE_ORDER_LIST_DATA));
    }
}

function setItem(item, unChangedChildren, unChangedChildrenHasComponents, unChangedChildrenHasProducts)
{
   item.nextRevisionInput = {
            value: item.nextCoRevision,
            message: '',
            valid: true
       };
   item.initialStatus = item.status;
    if (item.previousStatus === item.status && window.__libraryType === "GENERAL" && window.__revSchemeType === config.revisionTypes.alphaNumericXYZ)
    {
        item.revActionType = item.revActionType ? item.revActionType : "documentation"
    }
    else if (item.previousStatus === item.status && window.__libraryType === "GENERAL" && window.__revSchemeType === config.revisionTypes.alphaNumericXY)
    {
        item.revActionType = item.revActionType ? item.revActionType : "minor"
    }
   if(!item.hasOwnProperty("showRevision"))
   {
    if(item.alias === "cmp" && unChangedChildrenHasComponents) item.showRevision = unChangedChildren.components.includes(item._id) ? false : true;
    if(item.alias === "prd" && unChangedChildrenHasProducts) item.showRevision = unChangedChildren.products.includes(item._id) ? false : true;
   }
}

function setList(unChangedChildren, list)
{
    unChangedChildren = unChangedChildren ? unChangedChildren : {};
    let unChangedChildrenHasComponents = unChangedChildren.hasOwnProperty("components");
    let unChangedChildrenHasProducts = unChangedChildren.hasOwnProperty("products");
    list.forEach((item) => {
       setItem(item, unChangedChildren, unChangedChildrenHasComponents, unChangedChildrenHasProducts);
    })
    return list;
}

function excludeCurrentCO(coList, currentCoId)
{
    if(coList && coList.length > 0) return coList.filter(function(coObject) { return coObject._id != currentCoId; });
    return [];
}

export function* updateCoList(action)
{
    let newPageState = yield select(getNewPageState)
    // yield put(buildAction(UI.LOAD_START))
    try
    {
        let search = action.payload
        let selectionList = search.selections
        yield put(buildAction(UI.VALIDATION_START))
        let productIds = []
        let componentIds = []
        let componentIdsWithStatus = [];
        let productIdsWithStatus = [];

        let array = [];

        selectionList.forEach((item) =>
        {
            if(item && item.alias === "cmp")
            {
                componentIds.push(item._id);
                componentIdsWithStatus.push({id: item._id, status: item.status, alias: item.alias});
            }
            else if(item && item.alias === "prd")
            {
                productIds.push(item._id);
                productIdsWithStatus.push({id: item._id, status: item.status, alias: item.alias});
            }
        })

        let componentsHavingCO = [];
        let productsHavingCO = [];
        let componentsHavingMissingSources = []
        let productsHavingMissingSources = []
        let res = {};

        if(componentIds.length > 0) res = yield cps(API.components.coExist, { items: componentIds })
        if(res && res.data) componentsHavingCO = res.data.componentsCO;

        res = {};
        if(productIds.length > 0) res = yield cps(API.products.coExist, { items: productIds });
        if(res && res.data) productsHavingCO = res.data.productsCO;

        if(componentIdsWithStatus.length > 0) res = yield cps(API.components.validateManufacturers, componentIdsWithStatus)
        if(res && res.data) componentsHavingMissingSources = res.data;

        if(productIdsWithStatus.length > 0) res = yield cps(API.products.validateManufacturers, productIdsWithStatus)
        if(res && res.data) productsHavingMissingSources = res.data;


        selectionList.forEach((item) =>
        {
            let co;
            if(item.alias === "cmp" && componentsHavingCO.hasOwnProperty(item._id))
            {
                co = excludeCurrentCO(componentsHavingCO[item._id], newPageState.co._id);
            }
            else if(item.alias === "prd" && productsHavingCO.hasOwnProperty(item._id))
            {
                co = excludeCurrentCO(productsHavingCO[item._id], newPageState.co._id);
            }
            newPageState.allowMultipleCo = search.allowMultipleCo;
            disableFormSubmission(co, newPageState);
            item.co = co;
            item.initialStatus = item.status;
            item.showRevision = true;

            if(item.alias === "cmp")
            {
                item = Utils.setManufacturersValidationInfo(item, componentsHavingMissingSources)
            }

            if(item.alias === "prd")
            {
                item = Utils.setManufacturersValidationInfo(item, productsHavingMissingSources)
            }
            array.push(item);
        })
        array = setList(newPageState.unChangedChildren, array);

        newPageState.list = [...newPageState.list, ...array]
        newPageState.search.selections = []
        newPageState.search.selectAllFlag = false;
        let validateChangeOrderComponents = true;

        if (newPageState.search.parentAssemblyFlag)
        {
            validateChangeOrderComponents = false;
            yield put(buildAction(CO.GET_PARENT_ASSEMBLIES))
        }
        if (newPageState.search.childrenAssemblyFlag)
        {
            validateChangeOrderComponents = false;
            yield put(buildAction(CO.GET_CHILDREN_ASSEMBLIES, {runValidation: true}))
        }
        if(validateChangeOrderComponents) yield put(buildAction(CO.GET_CHANGE_ORDER_LIST_DATA));
        yield put(buildAction(CO.SET_CO_STATE))
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
    yield put(buildAction(UI.VALIDATION_END))
}

export function* getChangeOrderListData(action)
{
    let newPageState = yield select(getNewPageState)
    let items = [];
    let children = [];

    newPageState.list.forEach((item) => {
        let categoryType = "";
        if(item.alias === "cmp")
        {
            if(item.category)
            {
                categoryType = schemas.categories.getType2(item.category, window.__categories);
            }
            else
            {
                let cat = schemas.categories.findByCode2(item.cpn.split("-")[0], window.__categories);
                if(cat) categoryType = cat.type;
            }
        }
        const isAllowedInPartAndAssembly = window.__categories.find(category => category.name === item.category)?.isAllowedInPartAndAssembly;
        if(categoryType == "ASSEMBLY" || isAllowedInPartAndAssembly || item.alias === "prd") items.push({ id: item._id, type: item.alias });
    })

    if(items.length>0)
    {
        yield put(buildAction(CO.SET_LOADING, {validationRunning: true}));
        let {childrenData, parentsData} = yield cps(API.cos.getChildrenIdAndStatus, { items });
        yield put(buildAction(CO.VALIDATE_CHANGE_ORDER_LIST, {childrenData, parentsData}));
    }
    else
    {
        yield put(buildAction(CO.VALIDATE_CHANGE_ORDER_LIST, {}));
    }

}

export function* getChangeOrderOfChildren(action)
{
    let newPageState = yield select(getNewPageState);
    let productIds = [];
    let componentIds = [];
    let componentIdsWithStatus = [];
    let productIdsWithStatus = [];
    newPageState.allowMultipleCo = action.payload.allowMultipleCo;

    let componentsHavingCO = [];
    let productsHavingCO = [];
    let componentsHavingMissingSources = {}
    let productsHavingMissingSources = {}

    let res = {};

    let list = newPageState.list;
    list.forEach((item) =>
    {
       if(item && item.alias === "cmp")
       {
           componentIds.push(item._id);
            if (item.previousStatus === "DESIGN" && item.status !== "DESIGN") //See NOTE-1 above
            {
                componentIdsWithStatus.push({id: item._id, status: item.status, alias: item.alias});
            }
       }
       else if(item && item.alias === "prd")
       {
            productIds.push(item._id);
            if (item.previousStatus === "DESIGN" && item.status !== "DESIGN") //See NOTE-1 above
            {
                productIdsWithStatus.push({id: item._id, status: item.status, alias: item.alias});
            }
       }
    })
    if(componentIds.length > 0) res = yield cps(API.components.coExist, { items: componentIds })
    if(res && res.data) componentsHavingCO = res.data.componentsCO;

    res = {};
    if(productIds.length > 0) res = yield cps(API.products.coExist, { items: productIds });
    if(res && res.data) productsHavingCO = res.data.productsCO;


    if(componentIdsWithStatus.length > 0) res = yield cps(API.components.validateManufacturers, componentIdsWithStatus)
    if(res && res.data) componentsHavingMissingSources = res.data;

    if(productIdsWithStatus.length > 0) res = yield cps(API.products.validateManufacturers, productIdsWithStatus)
    if(res && res.data) productsHavingMissingSources = res.data;

    list.forEach((item) =>
    {
        let co;
        if(item.alias === "cmp" && componentsHavingCO.hasOwnProperty(item._id))
        {
            co = excludeCurrentCO(componentsHavingCO[item._id], newPageState.co._id);
        }
        else if(item.alias === "prd" && productsHavingCO.hasOwnProperty(item._id))
        {
            co = excludeCurrentCO(productsHavingCO[item._id], newPageState.co._id);
        }

        if(item.alias === "cmp" && componentsHavingMissingSources.hasOwnProperty(item._id))
        {
            item.sourcingError = componentsHavingMissingSources[item._id].error;
        }

        if(item.alias === "prd" && productsHavingMissingSources.hasOwnProperty(item._id))
        {
            item.sourcingError = productsHavingMissingSources[item._id].error;
        }

        !action.payload.allowMultipleCo && disableFormSubmission(co, newPageState);
        item.co = co;
    })
    let payload = {list};
    yield put(buildAction(CO.SET_CHANGE_ORDER_OF_CHILDREN, payload));
}

export default function*(getState)
{
    yield all([
        takeEvery(CO.SUBMIT_CHANGE_ORDER_FORM, submitForm),
        takeLatest(CO.UPDATE_INPUT_ON_CHANGE, updateInputOnChange),
        takeLatest(CO.GET_PARENT_ASSEMBLIES, getParentAssemblies),
        takeLatest(CO.GET_CHILDREN_ASSEMBLIES, getChildrenAssemblies),
        takeLatest(CO.GET_CHANGE_ORDER_OF_CHILDREN, getChangeOrderOfChildren),
        takeLatest(CO.GET_CHANGE_ORDER_LIST_DATA, getChangeOrderListData),
        takeLatest(CO.UPDATE_CO_LIST, updateCoList),
        takeLatest(CO.CREATE_CO_WITH_DEFAULT_DATA, createCoWithDefaultData),
        takeLatest(CO.DELETE_CO, deleteChangeOrder),
        takeLatest(CO.AUTO_SAVE_IN_DRAFT, autoSaveInDraft),
    ]);
}
