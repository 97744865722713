import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, InputAdornment, styled } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { CustomDuroCheckbox } from "common/components/checkbox";
import { DateTimePicker, Switch } from "common/components/inputs";
import { InputFieldVariants } from "common/constants";
import { colorPalette } from "@duro/themes";
import moment from "moment";
import mixpanel from "common/mixpanel";
import { sdk } from "features/changeorders/sdk";
import { useEffect, useState } from "react";
import "./effectivity.styles.scss";

interface IStyledProps {
  isEffectivityEnabled: boolean;
}

const checkboxLabelStyles = { fontSize: "0.75rem", marginLeft: "0.40rem" };
const datePickerCalenderStyle = { placement: "bottom-start" };

const datePickerCustomStyle = { display: "block", alignItems: "flex-start" };
const datePickerRenderProps = (error: boolean) => ({
  placeholder: "mm/dd/yyyy",
  style: {
    boxSizing: "border-box",
    padding: "1.15rem 1.1rem",
    background: error ? colorPalette.errorRed : undefined,
  },
  variant: InputFieldVariants.FILLED,
});

const datePickerInputWrapperProps = {
  style: {
    backgroundColor: colorPalette.gray,
    display: "grid",
    gridTemplateColumns: "80fr 20fr",
    alignItems: "center",
    justifyContent: "center",
  },
  disableUnderline: true,
};
const switchLabelStyles = {
  color: colorPalette.silver,
  fontSize: "0.8rem",
  width: "6rem",
};

export const ERPEffectivity: React.FC = () => {
  const { effectivity } = sdk.storage.form.erpOptions;
  const coId = sdk.storage.coId.getState();
  const [
    { startDate, endDate, overrideExisting, isEnabled },
    setErpEffectivity,
  ] = effectivity.useStore();

  const [isDateError] = sdk.storage.isErpOptionsEffectivityDateError.useStore();
  const [startDateError, setStartDateError] = useState(false);

  const [endDateError, setEndDateError] = useState(false);

  useEffect(() => {
    validateDates(startDate, endDate);
  }, [isEnabled]);

  const validateDates = (startDate: number | null, endDate: number | null) => {
    if (!isEnabled) {
      setStartDateError(false);
      setEndDateError(false);
      return;
    }

    const hasValidStart = startDate && moment(startDate).isValid();
    const hasValidEnd = endDate && moment(endDate).isValid();

    setStartDateError(
      Boolean(
        !hasValidStart || (hasValidStart && hasValidEnd && startDate > endDate)
      )
    );
    setEndDateError(
      Boolean(
        !hasValidEnd || (hasValidStart && hasValidEnd && endDate < startDate)
      )
    );

    sdk.storage.isErpOptionsEffectivityDateError.setState(
      !hasValidStart ||
        !hasValidEnd ||
        (hasValidStart && hasValidEnd && startDate > endDate)
    );
  };

  const handleStartDateChange = (unixTimestamp: number | null) => {
    mixpanel.track("Change Order", {
      operation: "Set Effectivity Start",
      "Change Order UI Version": "v2",
      "Change Order": coId,
      "Effectivity Start Date": unixTimestamp,
    });

    setErpEffectivity((prev) => ({
      ...prev,
      startDate: !unixTimestamp ? 0 : unixTimestamp,
    }));

    validateDates(unixTimestamp, endDate);
  };

  const handleEndDateChange = (unixTimestamp: number | null) => {
    mixpanel.track("Change Order", {
      operation: "Set Effectivity End",
      "Change Order UI Version": "v2",
      "Change Order": coId,
      "Effectivity End Date": unixTimestamp,
    });

    setErpEffectivity((prev) => ({
      ...prev,
      endDate: !unixTimestamp ? 0 : unixTimestamp,
    }));

    validateDates(startDate, unixTimestamp);
  };

  const getStartDateErrorMessage = () => {
    if (!isEnabled || !startDateError) return undefined;
    if (!startDate || !moment(startDate).isValid())
      return "Please enter valid Start Date";
    return "Start Date must be before End Date";
  };

  const getEndDateErrorMessage = () => {
    if (!isEnabled || !endDateError) return undefined;
    if (!endDate || !moment(endDate).isValid())
      return "Please enter valid End Date";
    return "End Date must be after Start Date";
  };

  return (
    <CustomEffectivityComponent className="co-erp-options">
      <CustomSwitch>
        <Switch
          defaultValue={isEnabled}
          label={"Set Effectivity: "}
          labelStyle={switchLabelStyles}
          labelPosition={"start"}
          onChange={() => {
            mixpanel.track("Change Order", {
              "Change Order": coId,
              "Effectivity Enabled": !isEnabled,
              operation: "Set Effectivity Enabled/Disabled",
              "Change Order UI Version": "v2",
            });
            setErpEffectivity((prev) => ({
              ...prev,
              isEnabled: !prev.isEnabled,
            }));
          }}
        />
      </CustomSwitch>
      <Box
        display="grid"
        gridTemplateColumns="50fr 50fr"
        gap="5px"
        sx={{
          "> div": {
            width: "100%",
          },
        }}
      >
        <DatePickerField isEffectivityEnabled={isEnabled}>
          <DateTimePicker
            {...{
              defaultValue:
                startDate && moment(startDate).isValid()
                  ? moment(startDate).valueOf()
                  : moment().valueOf(),
              label: "Start Date",
              maxDate:
                endDate && moment(endDate).isValid()
                  ? moment(endDate).valueOf()
                  : moment().valueOf(),

              onChange: handleStartDateChange,
            }}
            calendarStyle={datePickerCalenderStyle}
            disabled={!isEnabled}
            EnclosingTag={MuiDatePicker}
            inputFormat={"MMM D, YYYY"}
            inputWrapperProps={datePickerInputWrapperProps}
            isRequired={true}
            openPickerIcon={CalendarMonthIcon}
            renderInputProps={{
              ...datePickerRenderProps(startDateError),
              setError: startDateError,
              errorMessage: getStartDateErrorMessage(),
            }}
          />
        </DatePickerField>

        <DatePickerField isEffectivityEnabled={isEnabled}>
          <DateTimePicker
            {...{
              defaultValue:
                endDate && moment(endDate).isValid()
                  ? moment(endDate).valueOf()
                  : moment().valueOf(),
              label: "End Date",
              maxDate: moment().add(100, "year").valueOf(),
              minDate:
                startDate && moment(startDate).isValid()
                  ? moment(startDate).valueOf()
                  : moment().valueOf(),

              onChange: handleEndDateChange,
            }}
            calendarStyle={datePickerCalenderStyle}
            customStyle={datePickerCustomStyle}
            disabled={!isEnabled}
            EnclosingTag={MuiDatePicker}
            inputFormat={"MMM D, YYYY"}
            inputWrapperProps={datePickerInputWrapperProps}
            isRequired={true}
            openPickerIcon={CalendarMonthIcon}
            renderInputProps={{
              ...datePickerRenderProps(endDateError),
              setError: endDateError,
              errorMessage: getEndDateErrorMessage(),
            }}
          />
        </DatePickerField>
      </Box>
      <CustomCheckbox>
        <CustomDuroCheckbox
          checkboxFontSize="1rem"
          checkBoxLabel={"Override"}
          checked={overrideExisting}
          disabled={!isEnabled}
          labelStyles={checkboxLabelStyles}
          onChange={() =>
            setErpEffectivity((prev) => ({
              ...prev,
              overrideExisting: !prev.overrideExisting,
            }))
          }
        />
      </CustomCheckbox>
    </CustomEffectivityComponent>
  );
};

const CustomCheckbox = styled(Box)({
  marginTop: "0.6rem",
});

const CustomEffectivityComponent = styled(Box)({
  alignItems: "center",
  display: "flex",
});

const CustomSwitch = styled(Box)({
  marginTop: "1rem",
});

const DatePickerField = styled(Box)<IStyledProps>(
  ({ isEffectivityEnabled }) => ({
    color: isEffectivityEnabled ? colorPalette.white : colorPalette.disabled,
    paddingRight: "0.25rem",
    width: "30%",
  })
);
