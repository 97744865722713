import { connect }                      from 'react-redux';
import React, { Component }             from    "react"
import Tooltip                          from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'

class CustomBlueToolTip extends Component
{
    render()
    {
        let {displayOnBoardingNav, steps, currentEnv, activeStepIndex} = this.props.on_boarding
        let markup = this.props.notAllowedForStep === activeStepIndex ? this.props.children
            :
            <Tooltip
                placement={this.props.placement}
                visible={ currentEnv === "SANDBOX" && displayOnBoardingNav && steps[activeStepIndex].showToolTip}
                overlayClassName={"on-boarding-tool-tip " + (this.props.customClazz)}
                getTooltipContainer={() => document.querySelector("#routes")}
                overlay={<div><p><span>{steps[activeStepIndex].tooltipText}</span></p></div>}
            >
            {this.props.children}
            </Tooltip>
        return markup

    }
}

export default connect((store) => ({
    on_boarding: store.on_boarding
}))(CustomBlueToolTip)

// export default connect((store) => store)(CustomBlueToolTip)
