import { Box } from "@mui/material";
import { colorPalette } from "components/themes";
import { DuroButton } from "common/components";
import { ChangeOrderLayoutConfig } from "features/changeorders/constants";
import { ButtonVariants } from "common/constants";
import { sdk } from "features/changeorders/sdk";
import { ValidateButton } from "../pages/edit/validate.button";

export const FullscreenWrapper: React.FC = ({ children }) => {
  const [isFullscreen, setIsFullscreen] =
    sdk.storage.isFullscreenView.useStore();

  if (!isFullscreen) return <>{children}</>;

  return (
    <Box
      sx={{
        backgroundColor: colorPalette.darkGrey,
        position: "fixed",
        top: 0,
        zIndex: 9999,
        left: 0,
        right: 0,
        bottom: 0,
        minWidth: "100%",
        minHeight: "100%",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${colorPalette.dark4}`,
          borderLeft: `1px solid ${colorPalette.dark4}`,
          position: "absolute",
          top: 0,
          right: 0,
          width: ChangeOrderLayoutConfig.sidebarWidth,
          height: 120,
          zIndex: 10000,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <ValidateButton variant={ButtonVariants.OUTLINED} color="primary" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 10,
          right: ChangeOrderLayoutConfig.sidebarWidth,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const FullscreenTopbar: React.FC = ({ children }) => {
  const [isFullscreen, setIsFullscreen] =
    sdk.storage.isFullscreenView.useStore();

  if (!isFullscreen) return <>{children}</>;

  return (
    <Box
      sx={{
        "> div:first-of-type": {
          position: "fixed",
          width: "100vw",
          height: 68,
          zIndex: 10000,
        },
      }}
    >
      {children}
    </Box>
  );
};
