import { styled } from "@mui/material";
import { Box } from "@mui/material";

/**
 * @interface DuroInlineLayoutProps
 * @description
 * Interface defining the properties for the `DuroInlineLayout` component.
 * These properties allow customization of the flexbox layout behavior
 * in the inline-flex container.
 *
 */
interface DuroInlineLayoutProps {
  /**
   * @description
   * Defines how the flex container's items should wrap onto multiple lines.
   * Defaults to `"wrap"`.
   *
   * @type {string}
   * @default "wrap"
   * @memberof DuroInlineLayoutProps
   * @example
   * // Examples of acceptable values:
   * wrap="nowrap" // Items will not wrap.
   * wrap="wrap-reverse" // Items will wrap in the reverse direction.
   */
  wrap?: string;

  /**
   * @description
   * Aligns the flex lines within the container when there is extra space
   * along the cross-axis (vertical axis in row direction).
   * Defaults to `"flex-start"`.
   *
   * @type {string}
   * @default "flex-start"
   * @memberof DuroInlineLayoutProps
   * @example
   * // Examples of acceptable values:
   * alignContent="center" // Lines are centered in the container.
   * alignContent="space-between" // Lines are evenly distributed in the container.
   */
  alignContent?: string;

  /**
   * @description
   * Aligns flex items along the cross-axis (vertically in row direction).
   * Defaults to `"flex-start"`.
   *
   * @type {string}
   * @default "flex-start"
   * @memberof DuroInlineLayoutProps
   * @example
   * // Examples of acceptable values:
   * alignItems="center" // Items are centered along the cross-axis.
   * alignItems="stretch" // Items are stretched to fill the container.
   */
  alignItems?: string;

  /**
   * @description
   * Specifies the gap between flex items within the container.
   * Defaults to `"0.5rem"`.
   *
   * @type {string}
   * @default "0.5rem"
   * @memberof DuroInlineLayoutProps
   * @example
   * // Examples of acceptable values:
   * gap="1rem" // 1rem gap between items.
   * gap="10px" // 10px gap between items.
   */
  gap?: string;

  /**
   * Specifies the minimum height of the flex container.
   * Defaults to `"0"`.
   *
   * @type {string}
   * @default "0"
   * @memberof DuroInlineLayoutProps
   * @example
   * // Examples of acceptable values:
   * minHeight="100px" // Container has a minimum height of 100px.
   * minHeight="50vh" // Container has a minimum height of 50% of the viewport height.
   */
  minHeight?: string;
}

/**
 * @description
 * `DuroInlineLayout` is a styled component that provides a customizable
 * inline-flex layout container. It leverages the power of the Flexbox layout model
 * while maintaining the flexibility to be easily adapted via props.
 *
 * This component is ideal for scenarios where you need an inline-flex container
 * with configurable wrapping, alignment, and spacing properties.
 *
 * @component
 * @example
 * // Example usage of DuroInlineLayout
 * <DuroInlineLayout
 *   wrap="wrap-reverse"
 *   alignContent="center"
 *   alignItems="stretch"
 *   gap="1rem"
 *   width="50%"
 *   minHeight="100px"
 * >
 *   <ChildComponent />
 *   <AnotherChildComponent />
 * </DuroInlineLayout>
 */
const DuroInlineLayout = styled(Box)<DuroInlineLayoutProps>`
   box-sizing: border-box;
   align-content: ${({ alignContent }) => alignContent || "flex-start"};
   align-items: ${({ alignItems }) => alignItems || "flex-start"};
   display: inline-flex;
   flex-flow: ${({ wrap }) => wrap || "wrap"};
   gap: ${({ gap }) => gap || "0.5rem"};
   margin-block: initial;
   margin-inline: initial;
   min-height: ${({ minHeight }) => minHeight || "0"};
   flex-grow: 1; // This component must expand to fill parent on x axis to work as generic layout container
`;

export default DuroInlineLayout;

const InlineLayoutTop = styled("div")({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: "0.25rem",
  alignItems: "center",
  ".align-top": {
    alignSelf: "flex-start",
    marginTop: "3px",
  },
});
