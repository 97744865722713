import { SuperComponent } from "design/models/changeorder";
import { FC } from "react";
import { Link } from "react-router-dom";

export const ComponentLink: FC<{
  item: SuperComponent;
}> = ({ item: { cpn, alias, oid }, children }) => {
  const route = alias?.toUpperCase() === "PRD" ? "product" : "component";
  const display =
    !cpn?.displayValue || cpn?.displayValue.includes("undefined")
      ? "—"
      : cpn.displayValue;
  return (
    <Link
      style={{ fontSize: "inherit" }}
      to={`/${route}/view/${oid}`}
      target="_blank"
    >
      {display}
      {children}
    </Link>
  );
};
