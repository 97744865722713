import { SuperComponent } from "design/models/changeorder";

export function findParents(
  flatData: Record<string, SuperComponent>,
  targetId: string,
  mapper: (item: SuperComponent) => SuperComponent = (item) => item
) {
  const filteredData: Record<string, SuperComponent> = {};

  for (const key in flatData) {
    if (flatData[key].treeId.endsWith(targetId)) {
      const item = mapper(flatData[key]);
      const keyedParents = item.treeId.replace(`::${targetId}`, "");
      filteredData[keyedParents] = item;
    }
  }

  return filteredData;
}

export function filterPath(
  item: SuperComponent,
  included: string[]
): SuperComponent {
  const path = item.path.filter((id) => included.includes(id));
  const treeId = path.join("::");
  return {
    ...item,
    path,
    treeId,
  };
}

export function getNextTreeNodes(
  treeDataStore: Record<string, SuperComponent>,
  treeDataByIds: Record<string, SuperComponent>,
  selectedChildren: string[],
  selectedCPNs: string[],
  selectedIds: string[] = []
) {
  let rows: SuperComponent[] = [];
  let treeNodes: Record<string, SuperComponent> = {};

  const allSelectedNodes = [...selectedCPNs, ...selectedIds].flatMap((i) =>
    i.split("::")
  );

  for (const id of selectedChildren) {
    const item = treeDataByIds[id];
    const nextItem = filterPath(item, allSelectedNodes);
    const parents = findParents(treeDataStore, id, (item) =>
      filterPath(item, allSelectedNodes)
    );

    rows.push(nextItem);

    treeNodes = {
      ...treeNodes,
      ...parents,
    };
  }

  return {
    rows,
    treeNodes,
  };
}


export function filterAndDeduplicatePaths(
  arrays: SuperComponent[],
  validIds: string[]
): SuperComponent[] {
  // Step 1: Filter paths to only include valid IDs
  const filtered = arrays
    .map((arr) => {
      const filteredPath = arr.path.filter((id) => validIds.includes(id));
      return { ...arr, path: filteredPath, treeId: filteredPath.join("::") };
    })
    .filter((arr) => arr.path.length > 0); // Remove any empty paths resulting from filtering

  // Step 2: Remove duplicates and subpaths
  const uniquePaths = new Set<string>();
  return filtered.filter((arr, i) => {
    const pathString = arr.path.join(",");

    // Check if this path is a subpath of another longer path
    const isSubpath = filtered.some(
      (otherArr, j) =>
        i !== j &&
        otherArr.path.length > arr.path.length &&
        otherArr.path
          .slice(-arr.path.length)
          .every((val, index) => val === arr.path[index])
    );

    // If the path is a subpath or a duplicate, filter it out
    if (isSubpath || uniquePaths.has(pathString)) {
      return false;
    } else {
      uniquePaths.add(pathString);
      return true;
    }
  });
}
