import Link               from "../../../../ui/link"
import {Component} from "react"
import Loading            from "../../../../ui/inner-loading"
import Utils              from "../../../../../modules/utils"
import PlusIcon           from "../../../../../assets/icons/plus.js"
import InlineIcon         from "../../../../ui/icon/inline-icon.js"
import ExtendedTablePaginated from "../../../common/extended-table/extended-table-paginated"
import {connect}                    from "react-redux"
import Permissions        from "../../../../../modules/schemas/permissions"
import TimeStampColumn    from "../../../common/timestamp-column";
import InlineImageViewer  from "../../../common/inline-image-viewer/view";
import Config             from "../../../../../modules/config";
import CpnField           from "../../../common/cpn-field";
import VariantModal       from "../../../common/view-action-items/variant-modal"
import VariantIconHolder  from "../../../common/variants/variant-icon-holder"
import { RevisionField } from "@duro/base/revision-field/";
import { StatusField } from "@duro/base/status-field";

export class ProductList extends Component
{
    constructor(props)
    {
        super(props)

        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.searchProducts || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.state =
        {
            currentSortItemAscending: true,
            current : "cpn",
            preSelectedSearchResults: this.props.preSelectedSearchResults,
            openVariantModal: false,
            primaryVariant: null,
            VariantModal: {
                top: 0,
                left: 0
            },
            headings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "Customer Part Number",
                    sortable    : true,
                    minWidth    : 66,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "eid",
                    displayName : "EID",
                    tooltip     : "External ID",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "eid", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "eid", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "eid", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 56,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 201),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "images",
                    displayName : "Images",
                    tooltip     : "Images",
                    sortable    : false,
                    ascending   : true,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "images", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "images", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "images", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 122,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 347),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : 97,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 87,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastModified",
                    displayName : "Last Modified",
                    tooltip     : "Last Modified",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "lastModified", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "lastModified", "position", 7),
                    visibility  : Utils.getStyleValue(viewStyles, "lastModified", "visibility", true),
                    disabled    : false
                }
            ]
        }

        this.getRows    = this.getRows.bind(this);
        this.setCurrentSortBy = this.setCurrentSortBy.bind(this);
        this.closeVariantModal= this.closeVariantModal.bind(this);
    }

    setCurrentSortBy(name, ascending)
    {
        let state = this.state
        state.currentSortItemAscending = ascending
        state.current = name
        this.setState(state)
    }

    componentWillReceiveProps(nextProps)
    {
       if (this.props.preSelectedSearchResults !== nextProps.preSelectedSearchResults)
       {
            let state = this.state
            state.preSelectedSearchResults = nextProps.preSelectedSearchResults
            this.setState(state)
       }
    }

    closeVariantModal(e, result)
    {
        let state = this.state;
        state.openVariantModal = false;
        state.primaryVariant = null;
        this.setState(state);
    }

    getRows()
    {
        let rows = [];
        let props = this.props
        let results = props.results;
        let selectedSearchIds = this.state.preSelectedSearchResults;
        let path = window.__userRole === "VENDOR" ? "revision" : "view"

        results.map((result, i) =>
        {
            let rowSelected = false
            selectedSearchIds.forEach((selected_result, i) =>
            {
                if (selected_result._id === result._id)
                {
                    rowSelected = true
                }
            })
            const CPN = Utils.haveVariantScheme(props.company, props.user.data.currentEnv) ? (result.cpn + '-' + result.cpnVariant) : result.cpn
            let lastModified        = result.lastModified ? result.lastModified : undefined;
            let lastUpdatedToolTip  = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;
            let cells =
            {
                "cpn":
                {
                    value        : CPN,
                    displayValue :
                        <span className="link">
                            <CpnField item={result} cpn={CPN} />
                        </span>,
                    tooltip : CPN
                },
                "eid":
                {
                    value        : result.eid,
                    displayValue : result.eid,
                    tooltip      : result.eid

                },
                "name":
                {
                    value        : result.name,
                    tooltip      : result.name,
                    displayValue : result.variantGroup ?
                                    <div className='item-name'>
                                        <Link
                                        to={`/product/${path}/${result._id}`}
                                        className={`link ${result.variantGroup ? '' : 'not-variant'}`}>
                                        {result.name}
                                        </Link>
                                        {
                                            window.__userRole !== 'VENDOR' &&
                                                <VariantIconHolder
                                                    openVariantModal={(primaryVariant, variantModal) => {
                                                        let state = this.state
                                                        state.openVariantModal = true;
                                                        state.primaryVariant = primaryVariant;
                                                        state.VariantModal.top = variantModal.top
                                                        state.VariantModal.left = variantModal.left
                                                        this.setState(state)
                                                    }}
                                                    result={result}
                                                    leftFomular={(left) => left - 250}
                                                    toolTip="Product Variants"
                                                    tableSelector="prd-list-block extended-table"
                                                 />
                                        }
                                    </div>
                                    : result.name,
                    cellClass    : result.variantGroup ? 'name-block' : ''
                },
                "description":
                {
                    value        : result.description,
                    displayValue : result.description,
                    tooltip      : result.description
                },
                "revision":
                {
                    value        : result.revision,
                    displayValue : <RevisionField item={result} />,
                    tooltip       : result.revision
                },
                "status":
                {
                    value        : result.status,
                    displayValue : <StatusField item={result} />,
                    tooltip       : result.status

                },
                "lastModified":
                {
                    value        : lastModified,
                    tooltip      : lastUpdatedToolTip ? `${lastUpdatedToolTip.dateValue} ${lastUpdatedToolTip.timeValue}`: '' ,
                    displayValue : <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} />
                },
                "images" :
                {
                    value       : 0,
                    displayValue: <InlineImageViewer imagesWithSrc={props.imagesWithSrc} key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={result.images}/>,
                    notLink     : true,
                    cellClass   : "inline-image-viewer"
                },
                rowSelected     : rowSelected,
                rowLink         : `/product/${path}/${result._id}`,
                object          : result,
                rowId           : result._id
            }
            rows.push(cells);
        })

        return rows;
    }

    render()
    {
        if(this.props.loading)
        {
            return <Loading />
        }

        let headings = this.state.headings
        let rows = this.getRows();
        let _this = this

        let markup  =
                <div>
                    {
                        this.state.openVariantModal && window.__userRole!=='VENDOR' &&
                        <div className="variant-table-holder">
                            <VariantModal
                                openVariantModal={this.state.openVariantModal}
                                closeVariantModal={this.closeVariantModal}
                                objectData={this.state.primaryVariant}
                                variantModalPosition={this.state.VariantModal}
                                history={this.props.history}
                                getDataFromApi={true}
                                fromLibraryTable={true}
                            />
                        </div>
                    }
                    <ExtendedTablePaginated
                        wrapperClassName="prd-list-block"
                        wrapperSelectorClass="prd-list-view"
                        headings={headings}
                        rows={rows}
                        stylesName="searchProducts"
                        allowRowSelect={this.props.allowRowSelect}
                        clearSelectedRows={this.props.clearSelectedRows}
                        onRowSelect={this.props.addToSelectedList}
                        onSelectAllRows={this.props.selectAllResults}
                        currentSortItem={this.state.current}
                        currentSortItemAscending={this.state.currentSortItemAscending}
                        resetLocalState={this.props.resetLocalState}
                        setCurrentSortBy={this.setCurrentSortBy}
                        clearSelectedRowsStore={this.props.clearSelectedRowsStore}
                        scrollPagination={true}
                        includeToolBar={true}
                        tableActionButtons={this.props.getIconsActionsList()}
                        paginationSize={150}
                        resultText={this.props.resultText}
                        footerRow={ Permissions.can("create", "product", this.props.user.data) ? {
                                bordered: true,
                                dataCellEl: <p>Add Products</p>,
                                indexCellEl: Permissions.can("create", "product", this.props.user.data) &&
                                            <div className="add-more-actions">
                                                <Link to="/product/new">
                                                    <button className='add-trigger'
                                                            data-tip={"Add..."}
                                                        >
                                                        <InlineIcon className="plus-icon">
                                                            <PlusIcon/>
                                                        </InlineIcon>
                                                    </button>
                                                </Link>
                                             </div>
                            }
                            :
                            rows.length === 0 &&
                            {
                                dataCellEl: <p>No Products</p>
                            }
                        }
                    />
                </div>

        return markup
    }
}

export default connect((store) => store)(ProductList)
// export default ProductList
