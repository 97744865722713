import "./index.scss";
import React      from "react";
import Icon       from "../../../ui/icon";
import ImportIcon from "../../../../assets/icons/import-icon-black.svg";

const HeaderBanner = (props) =>
{
    let {text} = props;

    return (
        <div className="header-wrapper">
            <Icon src={ImportIcon} />
            <div className="header-heading">
                <h3>{text}</h3>
            </div>
        </div>
    )
}

export default HeaderBanner;
