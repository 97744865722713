const checkCoExist = (component) => {
  if (component.co) {
    if (Array.isArray(component.co) && !component.co.length) {
      return false;
    } if (Array.isArray(component.co) && component.co.length) {
      return true;
    }
    return true;
  }
  return false;
};
export default checkCoExist;
