import { SvgIcon } from "@mui/material";

export const AssemblyIcon = () => (
  <SvgIcon fontSize="inherit" width="24px" height="24px" viewBox="0 0 24 24">
    <path d={`M3.273 22v-2.547h3.44l-2.87-9.807c-.579-.27-1.03-.636-1.355-1.097C2.163 
    8.089 2 7.479 2 6.72c0-.849.312-1.585.935-2.208.623-.623 1.359-.934 2.208-.934.794 
    0 1.454.212 1.977.636.524.425.876.99 1.057 1.694h3.955V4.309a.78.78 0 0 1 .244-.569.78.78 
    0 0 1 .569-.244c.199 0 .366.077.501.23.136.154.203.33.203.529l2.032-1.95a1.102 1.102 0 0 
    1 1.246-.19l4.281 2.059c.163.09.275.216.339.379a.614.614 0 0 1-.041.515c-.09.18-.221.289-.393.325a.704.704 
    0 0 1-.501-.081l-4.2-2.032-2.654 2.682V7.37l2.655 2.79 4.199-2.059a.704.704 0 0 
    1 .515-.068c.162.045.289.158.379.339.09.18.104.361.04.542a.702.702 0 0 1-.365.406l-4.145 1.95a1.5 1.5 
    0 0 1-.732.177.909.909 0 0 1-.623-.285l-2.032-1.95c0 .253-.067.442-.203.569a.707.707 0 0 1-.501.19.78.78 
    0 0 1-.569-.245.78.78 0 0 1-.244-.568V7.533h-4.01c0 .217-.058.438-.175.664-.118.226-.258.41-.42.555l5.553 
    10.701h4.281V22H3.273zm1.87-13.763a1.47 1.47 0 0 0 1.083-.433A1.47 1.47 0 0 0 6.66 6.72a1.47 1.47 0 0 
    0-.434-1.083 1.47 1.47 0 0 0-1.083-.434 1.47 1.47 0 0 0-1.084.434 1.47 1.47 0 0 0-.434 1.083c0 
    .434.145.795.434 1.084.289.289.65.433 1.084.433zm3.359 11.216h2.763l-5.12-9.942a1.032 1.032 0 0 
    1-.27.108 9.847 9.847 0 0 1-.299.081l2.926 9.753z`} fill-rule="evenodd"/>
  </SvgIcon>
);
