import * as editor from "./tree/tree.logic";
import { EditorState as state } from "./state";
import * as preprocessors from "./preprocessors";
import * as util from "./util";
import * as revisions from "./revisions";
import * as status from "./status";
import * as validation from "./validation";
import * as submit from "./submit";

export type { Modifications } from "./state";

export const sdk = {
  editor,
  state,
  util,
  preprocessors,
  revisions,
  status,
  validation,
  submit,
};
