
import Utils from "../../utils"
import Schemas from "../../schemas"

const DocumentAPI = {
  findById: (id, cb) => {
    Utils.get("/documents/" + id, (err, res) => {
      if (err) {
        return cb(err);
      }

      cb(null, res.data);
    });
  },

  create: (doc, cb) => {
    // set default document revision values
    if (!doc.specs.revision) {
      doc.specs.revision = Schemas.product.revision.normalize(
        null,
        doc.specs.status
      );
    }

    //create document
    Utils.uploadFile("/documents", doc, (err, res) => {
      if (err) {
        return cb(err);
      }

      cb(null, res.data);
    });
  },

  update: (id, data, cb) => {
    Utils.post("/documents/" + id, data, (err, res) => {
      if (err) {
        return cb(err);
      }

      cb(null, res.data);
    });
  },

  regenerateDocuments: (id, data, cb) => {
    Utils.post(`/documents/regenerate/${id}`, data, (err, res) => {
      if (err) {
        return cb(err);
      }

      cb(null, res.data);
    });
  },

  presignedURLForPart: (data, cb) => {
    Utils.post("/documents/presigned-for-part", data, (err, res) => {
        if (err) {
        return cb(err);
      }
      cb(null, res.presignedURL);
    });
  },

  abortMultipartUpload: (data, cb) => {
    Utils.post("/documents/abort-upload", data, (err, res) => {
      if (err) {
        return cb(err);
      }

      cb(null, res);
    });
  },

  completeMultipartUpload: (data, cb) => {
    Utils.post("/documents/complete-upload", data, (err, res) => {
      if (err) {
        return cb(err);
      }

      cb(null, res.fileURl);
    });
  },
};

export default DocumentAPI
